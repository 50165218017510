import { ButtonElement } from '@/react/components'

import closeIcon from '/src/assets/icons/X Gray.svg'
import { css } from '@emotion/react'
import { useEffect, useRef } from 'react'

export const ConfirmationModal = ({
  email,
  handleLinkResend,
  setModalVisable,
}) => {
  const modalRef = useRef(null)

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setModalVisable(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div css={baseStyle} className="modal__wrapper" ref={modalRef}>
      <img
        src={closeIcon}
        alt="cross-icon"
        className="close__icon"
        onClick={() => {
          setModalVisable(false)
        }}
      />
      <div className="modal__info">
        <p className="modal__message">
          Click OK to resend the draft link to:{' '}
          <p className="message__email">{email}</p>
        </p>
        <ButtonElement
          text="OK"
          buttonType="submit"
          functionToTrigger={() => {
            handleLinkResend()
          }}
        />
      </div>
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  backgroundColor: 'white',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  right: '40px',
  marginTop: '-27px',
  zIndex: 1,
  '.close__icon': {
    position: 'absolute',
    right: '4px',
    top: '5px',
    width: '13px',
    height: '13px',
    cursor: 'pointer',
  },
  '.modal__message': {
    color: '#666',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    marginTop: '10px',
    marginBottom: '10px',
    maxWidth: '241px',
    textAlign: 'center',
  },
  '.message__email': {
    margin: 0,
    fontWeight: 600,
  },
  '.modal__info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '17px',
  },
})
