angular
  .module('ccs')
  .controller(
    'CategoryCustomCtrl',
    function (
      $scope,
      $location,
      $state,
      $stateParams,
      Api,
      ngDialog,
      app,
      CurrentUser,
      $log,
    ) {
      $log.debug('CategoryCustomCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = $scope.search ? true : false

      $scope.iter = []
      if ($stateParams.previousPageNumber) {
        $scope.categoryPageNum = Number($stateParams.previousPageNumber)
      } else {
        $scope.pageNumber = $scope.pageNumber ? $scope.pageNumber : 1
      }
      $scope.reverse = false
      $scope.order = 'name'
      $scope.app = app

      if (!CurrentUser.is('supervisor')) {
        $scope.tableHeaders = [
          {
            key: 'name',
            display: 'Custom ' + $scope.app.category + ' Name',
            sortable: true,
          },
          { key: 'edit', display: 'Edit/View ' + app.projects, centered: true },
          { key: 'delete', display: 'Delete', centered: true },
        ]
      } else {
        $scope.tableHeaders = [
          {
            key: 'name',
            display: 'Custom ' + $scope.app.category + ' Name',
            sortable: true,
          },
          { key: 'edit', display: 'Edit/View ' + app.projects, centered: true },
        ]
      }

      function query() {
        let q = {
          application: app.id,
          client: CurrentUser.getClientId(),
          page: $scope.pageNumber || $scope.categoryPageNum,
          custom: 1,
          admin_is_active: 'True',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        if ($scope.search) q.search = $scope.search

        return q
      }

      var getCustomCategories = function getCustomCategories() {
        Api.Categories.get(query(), function (resp) {
          $scope.iter = resp.results
          $scope.totalItems = resp.count
          $scope.pageNumber = $scope.pageNumber || $scope.categoryPageNum
        })

        $state.transitionTo(
          'app.categories.custom',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          {
            notify: false,
          },
        )
      }

      var createCustomCategory = function createCustomCategory(data) {
        Api.Categories.post(
          angular.extend(
            {
              application: app.id,
              client: CurrentUser.getClientId(),
            },
            data,
          ),
          function (resp) {
            resp.projects = []
            $scope.iter.push(resp)
          },
        )
      }

      getCustomCategories()

      $scope.del = (category) => {
        Api.Categories.delete(category, (resp) => {
          getCustomCategories()
        })
      }

      $scope.changePage = getCustomCategories

      $scope.add = function () {
        ngDialog.open({
          template: 'app/views/category_custom_form.html',
          className: 'ngdialog-theme-default custom-content',
          scope: $scope,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.category = {}
            $scope.create = function () {
              createCustomCategory($scope.category)
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.updateCategoryName = function (categoryEditing) {
        categoryEditing.isEditing = false
        Api.Categories.patch(
          { id: categoryEditing.id, name: categoryEditing.name },
          function (resp) {
            categoryEditing.id = resp.id
          },
        )
      }

      $scope.editCategoryName = function (category) {
        category.isEditing = true
        $scope.editingCategoryWithName = category.name
      }

      $scope.cancelCategoryNameEdit = function (category) {
        category.name = $scope.editingCategoryWithName
        category.isEditing = false
        $scope.editingCategoryWithName = ''
      }
    },
  )
