angular.module('ccs').service('BatchReportService', function () {
  var ids = []
  var allow_blank_parties_observed = false
  var finalize_ids = []
  var parties_observed = []
  var selected_parties_observed = []
  var party_observed_asc = null

  return {
    getIds,
    isPresent,
    changeChecked,
    getAllowBlankPartiesObserved,
    toggleAllowBlankPartiesObserved,
    changeFinalize,
    isPresentFinalize,
    getFinalizeIds,
    allPartiesObserved,
    togglePartyObserved,
    isPresentPartyObserved,
    checkedPartiesObserved,
    clear,
    setPartyObservedAsc,
    getPartyObservedAsc,
  }

  function getAllowBlankPartiesObserved() {
    return allow_blank_parties_observed
  }

  function toggleAllowBlankPartiesObserved() {
    if (allow_blank_parties_observed == false) {
      allow_blank_parties_observed = true
    } else {
      allow_blank_parties_observed = false
    }
  }

  function clear() {
    ids = []
    allow_blank_parties_observed = false
    finalize_ids = []
    selected_parties_observed = []
    parties_observed = []
  }

  function setPartyObservedAsc(value) {
    party_observed_asc = value
    if (party_observed_asc == true) {
      parties_observed = parties_observed.sort()
    } else {
      parties_observed = parties_observed.sort()
      parties_observed = parties_observed.reverse()
    }
  }

  function getPartyObservedAsc() {
    return party_observed_asc
  }

  function togglePartyObserved(value) {
    if (parties_observed.indexOf(value) >= 0) {
      //Do nothing, it's already added.
    } else {
      parties_observed.push(value)
    }

    if (selected_parties_observed.indexOf(value) >= 0) {
      selected_parties_observed.splice(
        selected_parties_observed.indexOf(value),
        1,
      )
    } else {
      selected_parties_observed.push(value)
    }
  }

  function isPresentPartyObserved(value) {
    return selected_parties_observed.indexOf(value) >= 0
  }

  function allPartiesObserved(value) {
    return parties_observed
  }

  function checkedPartiesObserved(value) {
    return selected_parties_observed
  }

  //Used on the finalize reports
  function changeFinalize(value) {
    if (finalize_ids.indexOf(value) >= 0) {
      finalize_ids.splice(finalize_ids.indexOf(value), 1)
    } else {
      finalize_ids.push(value)
    }
  }

  //Used on the finalize reports
  function isPresentFinalize(value) {
    return finalize_ids.indexOf(value) >= 0
  }

  //Used on the finalize reports
  function getFinalizeIds() {
    return finalize_ids
  }

  //Used on the finalize reports
  function getIds() {
    return ids
  }

  //Used on the observation list
  function changeChecked(value) {
    if (ids.indexOf(value) >= 0) {
      ids.splice(ids.indexOf(value), 1)
      finalize_ids.splice(finalize_ids.indexOf(value), 1)
    } else {
      ids.push(value)
      finalize_ids.push(value)
    }
  }

  //Used on the observation list
  function isPresent(value) {
    return ids.indexOf(value) >= 0
  }
})
