import * as React from 'react'
import { css } from '@emotion/react'
import { UniversalAlert } from '@/react/components'

export const NoteElement = ({ noteText }) => {
  const baseStyle = getBaseStyle()
  const [popUp, setPopUp] = React.useState(null)

  function callback() {
    setPopUp(
      <UniversalAlert
        text={noteText}
        setAlert={setPopUp}
        submitButtonText={'OK'}
      />,
    )
  }

  return (
    <div css={baseStyle}>
      <div className="alert-and-bg-holder">{popUp}</div>
      <img
        src="/assets/icons/question_unanswered.png"
        className="note-question-mark note-icon-width"
        onClick={callback}
      />
    </div>
  )
}

function getBaseStyle() {
  return css({
    marginLeft: 10,
    '.alert-and-bg-holder': {},
  })
}
