import * as Icons from '../../webpack.icons.js'

angular
  .module('ccs')
  .controller(
    'ProgramContentCtrl',
    function ($scope, Api, $state, $stateParams, $sce, Notification, $log) {
      $log.debug('ProgramContentCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = $scope.search ? true : false
      $scope.jobId = $stateParams.jobId
      $scope.contentId = $stateParams.contentId
      $scope.programId = $stateParams.programId
      $scope.disabled_btn = false

      $scope.pageSize = 20
      $scope.maxSize = 10 //Number of pager buttons to show
      $scope.trustSrc = (src) => $sce.trustAsResourceUrl(src)

      $scope.loadIcon = (icon) => Icons.icons[icon]

      function getTrainingTopics() {
        Api.getWithoutDeletedParam(
          `jobs/${$scope.programId}`,
          { user_filter: true, order: 'order' },
          (resp) => {
            $scope.topics = resp.training
          },
          (error) => {
            Notification.danger(
              JSON.stringify(error && error.detail ? error.detail : error),
            )
          },
        )
      }

      getTrainingTopics()

      function getProgramContent() {
        Api.TopicContent.byID(
          $scope.contentId,
          (resp) => {
            $scope.content = resp
            if ($scope.content.content_type === 'pdf') {
              $scope.content.attachment_info &&
                ($scope.content_url =
                  'https://docs.google.com/viewerng/viewer?url=' +
                  $scope.content.attachment_info.file +
                  '&embedded=true')
            }
            if ($scope.content.content_type === 'ppt') {
              $scope.content.attachment_info &&
                ($scope.content_url =
                  'https://docs.google.com/viewerng/viewer?url=' +
                  $scope.content.attachment_info.file +
                  '&embedded=true')
            }
            if ($scope.content.content_type === 'video') {
              $scope.content.attachment_info &&
                ($scope.content_url = $scope.content.attachment_info.file)
            }
          },
          (error) => {
            Notification.danger(
              JSON.stringify(error && error.detail ? error.detail : error),
            )
          },
        )
      }

      getProgramContent()

      $scope.done = function () {
        $scope.disabled_btn = true
        Api.patch(
          `jobs/${$scope.programId}`,
          {
            user_filter: true,
            content_completed: parseInt($scope.contentId),
          },
          (resp) => {
            if (resp) {
              $scope.disabled_btn = false
              $state.go('trainings.topics', {
                jobId: $scope.programId,
                topicId: $scope.content.topic,
              })
            }
          },
        )
      }
    },
  )
