import * as React from 'react'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'

import { useAngularServices, PageSwitcher, Search } from '@/react/components'
import { ArchiveTableHeaders, ArchiveTableList } from './components'
import { singleSDSInterface } from '@screens/SafetyDataSheets/components/types'

const PAGE_LENGTH = 20

interface List {
  count: number
  next: string
  previous: string
  results: singleSDSInterface[]
}

// @ts-ignore
export function ArchiveTable({ changeIsProjectSelect }: any) {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const [page, setPage] = React.useState(stateService.params.pageNumber || 1)
  const order = stateService.params.order || '-date_updated'
  const [search, setSearch] = React.useState(stateService.params.search || '')
  const application = stateService.params.app

  const [list, setList] = React.useState<List>({
    count: 0,
    next: '',
    previous: '',
    results: [],
  })
  React.useEffect(async () => {
    const { data } = await Api.SDSForm.get({
      page,
      order,
      deleted: true,
      page_size: PAGE_LENGTH,
      application,
      search,
    })
    setList(data)
  }, [])

  return (
    <div css={base}>
      <Search
        search={search}
        searchCallback={setSearch}
        pageCallback={setPage}
      />
      <div className="table-base">
        <table className={'dual-color-table'}>
          <ArchiveTableHeaders />
          <ArchiveTableList
            rows={list}
            changeIsProjectSelect={changeIsProjectSelect}
          />
        </table>
      </div>
      <PageSwitcher
        pageLength={PAGE_LENGTH}
        listCount={list.count}
        currentPage={page}
        currentListRoute={'app.safety_data_sheets_archive'}
      />
    </div>
  )
}

const base = css({
  overflow: 'hidden',
  backgroundColor: 'white',
  paddingTop: '20px',
  width: '100%',
  marginTop: '1px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  paddingLeft: '25px',
  paddingRight: '25px',
  overflowY: 'hidden',
  thead: {
    borderBottom: '2px solid rgb(198, 198, 198)',
  },
  '.table-base': {
    paddingTop: '10px',
    paddingBottom: '10px',
    overflowX: 'scroll',
    width: '100%',
  },
  table: {
    minWidth: '100%',
  },
  th: {
    textAlign: 'center',
    height: '40px',
    paddingRight: '5px',
    paddingLeft: '5px',
    whiteSpace: 'normal',
    width: '20px',
  },
  '.nameColumn': {
    textAlign: 'left',
  },
  tr: {
    backgroundColor: 'white',
    height: '37px',
    whiteSpace: 'nowrap',
  },
  td: {
    whiteSpace: 'nowrap',
    paddingRight: '10px',
    paddingLeft: '10px',
    height: '37px',
    maxHeight: '37px',
    width: 'auto',
    '.various-block': {
      position: 'relative',
      span: {
        zIndex: 100,
      },
      '.hover-block': {
        zIndex: 1000,
        display: 'none',
        padding: '10px',
        borderBottomStyle: 'solid',
        borderStyle: 'solid',
        borderColor: '#e7eaec',
        position: 'absolute',
        left: 0,
        top: 0,
        backgroundColor: 'white',
      },
    },
    '.various-block:hover': {
      '.hover-block': {
        display: 'block',
      },
    },
  },
  '.startingColumn': {
    backgroundColor: 'inherit',
    left: 0,
    position: 'sticky',
    zIndex: 7000,
  },
})
