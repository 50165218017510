import * as React from 'react'
import { AddFileIcon, AddImageIcon } from '@/react/componentAssets'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { generateUUID } from '@/utils'
import { flatMap, without } from 'lodash'
import { getTranslatedString } from '@/utils'
import {
  getStartingValue,
  SingleFile,
  SingleFileWithLoading,
  NoteElement,
} from './index'
import { useRouter } from '@/react/hooks'

export const FilesFields = ({
  fieldData,
  formMemory,
  possibleGroupData,
  promisesToDeleteFiles,
  onChange,
}) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [elementList, setElementList] = React.useState([])
  const [error, setError] = React.useState(false)
  const [isEmpty, setEmpty] = React.useState(false)

  const baseStyle = getBaseStyle(variableColor)

  function removeFunction(id, client_object_key = '') {
    const field = possibleGroupData
      ? formMemory[fieldData.id][possibleGroupData]
      : formMemory[fieldData.id]
    if (fieldData.type === 'files') {
      onChange({
        value: without([...field.localAnswer.files], id),
        possibleGroupData,
        id: fieldData.id,
        fieldType: 'fileReplace',
      })
    } else {
      onChange({
        value: without([...field.localAnswer.photos], id),
        possibleGroupData,
        id: fieldData.id,
        fieldType: 'photoReplace',
      })
      if (stateService.current.name === 'assigned.external_form') {
        promisesToDeleteFiles({
          id: client_object_key,
          type: 'image',
          page: 'external',
        })
      } else {
        promisesToDeleteFiles({
          id: id,
          type: 'image',
          page: 'internal',
        })
      }
    }
    if (fieldData.required) {
      const filesFromMemory = getStartingValue(
        possibleGroupData,
        formMemory,
        fieldData,
        fieldData.type,
        [],
      )
      setEmpty(!filesFromMemory.length)
    }
  }

  function addFileIdToMemory(id) {
    onChange({
      value: id,
      possibleGroupData,
      id: fieldData.id,
      fieldType: 'fileAdd',
    })
  }

  async function addFile(value, noUpload, fromFileComponent = false) {
    if (error) setError(false)
    setEmpty(false)
    const promiseList = []

    if (!noUpload) {
      flatMap(value.target.files).forEach((file) => {
        const blob = new Blob([file], {
          type: file.type,
        })
        const fileOfBlob = new File(
          [blob],
          fieldData.type === 'files' ? file.name : generateUUID(),
        )
        promiseList.push(Api.uploadImage(fileOfBlob, {}))
      })
    }
    const responseArray = noUpload ? value : await Promise.all(promiseList)
    const elementArray = []

    if (fieldData.type !== 'files') {
      responseArray.forEach((fileResponse) => {
        const splitUrl =
          fieldData.type === 'files'
            ? fileResponse.data.name
            : fileResponse.data.image.split('/')
        if (!fileResponse.data.deleted) {
          elementArray.push(
            <SingleFile
              fileUrl={fileResponse.data.image}
              removeFunction={() => {
                removeFunction(
                  fileResponse.data.id,
                  fileResponse.data.client_object_key,
                )
              }}
              id={fileResponse.data.id}
              fileName={splitUrl[splitUrl.length - 1]}
              key={elementList.length}
            />,
          )
        }
      })
    } else {
      responseArray.forEach((fileResponse) => {
        if (!fileResponse.data.deleted) {
          elementArray.push(
            <SingleFileWithLoading
              file={fileResponse}
              removeFunction={() => {
                removeFunction(
                  fileResponse.data.id,
                  fileResponse.data.client_object_key,
                )
              }}
              key={elementList.length}
              addToMemoryFunction={addFileIdToMemory}
              promisesToDeleteFiles={promisesToDeleteFiles}
              noSend={true}
            />,
          )
        }
      })
    }

    if (fieldData.type === 'files') {
      responseArray.map((fileResponse) => {
        onChange({
          value: fileResponse.data.id,
          possibleGroupData,
          id: fieldData.id,
          fieldType: 'fileAdd',
        })
      })
    } else {
      responseArray.map((fileResponse) => {
        onChange({
          value: fileResponse.data.id,
          possibleGroupData,
          id: fieldData.id,
          fieldType: 'photoAdd',
        })
      })
    }
    if (!fromFileComponent) setElementList([...elementList, ...elementArray])
  }

  const inputRef = React.useRef()

  function addFileWithLoading(value) {
    if (error) setError(false)
    setEmpty(false)

    const elementArray = []

    flatMap(value.target.files).forEach((file, index) => {
      elementArray.push(
        <SingleFileWithLoading
          file={file}
          removeFunction={removeFunction}
          addToMemoryFunction={addFileIdToMemory}
          promisesToDeleteFiles={promisesToDeleteFiles}
        />,
      )
    })

    inputRef.current.value = ''
    setElementList([...elementList, ...elementArray])
  }

  async function startingFiles() {
    const startingValue = getStartingValue(
      possibleGroupData,
      formMemory,
      fieldData,
      fieldData.type,
      [],
      true,
    ).map((file) => {
      return { data: file }
    })
    if (startingValue.length) {
      addFile(startingValue, true)
    }
  }

  React.useEffect(() => {
    startingFiles()
  }, [])

  React.useEffect(() => {
    setEmpty(fieldData.required && elementList.length === 0)
  }, [elementList])

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="header label-holder">
        <label css={css({ color: !error ? 'inherit' : 'red' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>

      <label className="file-input">
        {fieldData.type === 'files' ? (
          <AddFileIcon
            height={'auto'}
            width={'30px'}
            color={isEmpty ? '#C80404' : variableColor}
          />
        ) : (
          <AddImageIcon
            height={'auto'}
            width={'30px'}
            color={isEmpty ? '#C80404' : variableColor}
          />
        )}
        <input
          multiple
          type="file"
          onChange={(value) => {
            fieldData.type === 'files'
              ? addFileWithLoading(value)
              : addFile(value, false)
          }}
          ref={inputRef}
          accept={
            fieldData.type === 'files'
              ? '.xls, .mp3, .csv, .doc, .docx, .ppt, .pdf, .txt, .mov, .mp4, .wav, .pptx, .xlsx, .xlxs, .gif'
              : 'image/png, image/gif, image/jpeg'
          }
        />
      </label>
      <div className="photoBlock">{elementList}</div>
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    marginBottom: 15,
    width: '100%',
    '.file-input': {
      img: {
        width: 30,
      },
      cursor: 'pointer',
      color: variableColor + ' !important',
      input: { display: 'none' },
    },
    '.photoBlock': {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
    },
    '.btn-default-border': {
      ':hover': {
        color: 'white !important',
      },
    },
  })
}
