type Props = {
  color: string
}

export const ThinCheckmark = ({ color }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M22 0H0V22H22V0ZM8.55556 17.1111L2.44444 11L4.16778 9.27667L8.55556 13.6522L17.8322 4.37556L19.5556 6.11111L8.55556 17.1111Z"
        fill={color}
      />
    </svg>
  )
}
