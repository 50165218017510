import { ImageWithPreview, useAngularServices } from '@/react/components'
import * as React from 'react'
import { css } from '@emotion/react'
import { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { Alert } from '@screens/ToolboxTalks/components/Alert'
import StringUtil from '@/utils/autolinks'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const ViewExternalPage = () => {
  const { $rootScope, Api, Notification } = useAngularServices()
  const clientObjectKey = $rootScope.$stateParams.key

  const [toolboxData, setToolboxData] = useState(null)
  const [isSynchronized, setIsSynchronized] = useState(null)
  const [error, setError] = useState(null)
  const [notFoundError, setNotFoundError] = useState(false)

  const isToolboxSynchronised = () => {
    Api.get(`shares/toolbox/${clientObjectKey}`, {}, (res) => {
      setIsSynchronized(res.synchronised)
      if (res.synchronised) {
        setError(null)
      }
    })
  }

  const isSafari = checkIsSafari()

  const intervalRef = useRef(null)

  const waitForToolboxSync = () => {
    intervalRef.current = setInterval(() => {
      if (isSynchronized) {
        getToolboxData()
        clearInterval(intervalRef.current)
      } else {
        isToolboxSynchronised()
      }
    }, 1000)
  }

  useEffect(() => {
    if (!isSynchronized && notFoundError) {
      waitForToolboxSync()
    } else {
      clearInterval(intervalRef.current)
    }
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isSynchronized])

  const getToolboxData = async () => {
    const { data: res } = await Api.get(`shares/toolbox/${clientObjectKey}`, {})
    if (res.client?.general_settings?.colors.secondary_color) {
      document.body.style = `background: ${res.client.general_settings.colors.secondary_color};`
    } else {
      document.body.style = `background: rgb(57, 129, 205)`
    }
    if (res.message) {
      setError(res.message)
      Notification.error(res.message)
    } else {
      setToolboxData(res)
      setIsSynchronized(res.synchronised)
      if (!res.synchronised) {
        setError(
          'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.',
        )
      }
    }
  }

  useEffect(() => {
    getToolboxData()
  }, [])

  if (!toolboxData || error) return null

  return (
    <section css={wrapperStyle}>
      {error && <Alert text={error} type="danger" />}
      <main css={baseStyle(isSafari)}>
        <div className="view-internal-base-page">
          <div className="header-base">
            <div className="logo-holder">
              <img
                className="logo"
                src={toolboxData.company?.logo_url}
                alt="logo"
              />
            </div>
            <div className="info-text-block-wrapper">
              <div className="sub-header">
                <div className="page-stat-title">TOPIC:</div>
                <div className="page-stat-desc page-stat-border-bottom">
                  {toolboxData.topic.name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">PROJECT:</div>
                <div className="page-stat-desc page-stat-border-bottom">
                  {toolboxData.project.name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">PERFORMED BY:</div>
                <div className="page-stat-desc">
                  {toolboxData.user.first_name} {toolboxData.user.last_name}
                </div>
              </div>
              <div className="sub-header">
                <div className="page-stat-title">CREATED:</div>
                <div className="page-stat-desc">
                  {moment(toolboxData.date_created).format('MM/DD/YY - hh:mmA')}
                </div>
              </div>
            </div>
          </div>
          {toolboxData.topic?.summary?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">SUMMARY</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData.topic?.summary,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.topic?.importance?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">WHY IS IT IMPORTANT?</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData.topic?.importance,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.topic?.consider?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">THINGS TO CONSIDER</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData.topic?.consider,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.topic?.discussion?.length > 0 && (
            <div className="content-block">
              <h3 className="content-block-title">DISCUSSION ITEMS</h3>
              <div
                className="content-block-text"
                style={{ whiteSpace: 'pre-wrap' }}
                dangerouslySetInnerHTML={{
                  __html: StringUtil.parseHyperlinks(
                    toolboxData.topic?.discussion,
                  ),
                }}
              ></div>
            </div>
          )}
          {toolboxData.notes?.length > 0 &&
            toolboxData.notes.some((note) => !note.deleted) && (
              <div className="content-block">
                <h3 className="content-block-title">NOTES</h3>
                <ul>
                  {toolboxData.notes
                    .filter((note) => !note.deleted)
                    .map((note) => {
                      return (
                        <li key={note.id} className="note-item">
                          <div
                            style={{ whiteSpace: 'pre-wrap', lineHeight: 1 }}
                          >
                            <span className="gray">
                              {moment(toolboxData.date_created).format(
                                'MM/DD/YY - hh:mmA',
                              )}{' '}
                              {note.user.first_name} {note.user.last_name}{' '}
                              -&nbsp;
                            </span>
                            <span>{note.text}</span>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {toolboxData.photos?.length > 0 &&
            toolboxData.photos.some((photo) => !photo.deleted) && (
              <div className="content-block">
                <h3 className="content-block-title">PHOTOS</h3>
                <ul className="photo-holder">
                  {toolboxData.photos
                    .filter((photo) => !photo.deleted)
                    .map((photo) => {
                      return (
                        <li key={photo.id} className="photo-item">
                          <ImageWithPreview
                            photo={photo}
                            photoIdx={photo.id}
                            onlyImage={true}
                          />
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {toolboxData.signatures?.length > 0 &&
            toolboxData.signatures.some((signature) => !signature.deleted) && (
              <div className="content-block">
                <h3 className="content-block-title">SIGNATURES</h3>
                <ul className="signature-holder">
                  {toolboxData.signatures
                    .filter((signature) => !signature.deleted)
                    .map((signature) => {
                      return (
                        <li
                          key={signature.image.id}
                          className="signature-item-holder"
                        >
                          <div className="signature-item">
                            <img
                              className="s-img"
                              src={signature.image.image}
                              alt="signature"
                            />
                            <div className="signature-info">
                              <div className="signature-name">
                                {signature.printed_name &&
                                  signature.printed_name}
                              </div>
                              <div className="signature-company">
                                {signature.company_name}
                              </div>
                            </div>
                          </div>
                        </li>
                      )
                    })}
                </ul>
              </div>
            )}
          {toolboxData.disclaimer?.length > 0 && (
            <div
              className="content-block-text"
              style={{ marginTop: '35px', whiteSpace: 'pre-wrap' }}
              dangerouslySetInnerHTML={{
                __html: StringUtil.parseHyperlinks(toolboxData.disclaimer),
              }}
            ></div>
          )}
        </div>
      </main>
    </section>
  )
}

const wrapperStyle = css({
  paddingBottom: '25px',
  '@media (max-width: 799px)': {
    paddingLeft: '15px',
  },
})

const baseStyle = (isSafari: boolean) =>
  css({
    width: '100%',
    backgroundColor: 'white',
    position: 'relative',
    marginTop: '2vw',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    '.header-base': {
      display: 'block',
      '.logo-holder': {
        display: 'inline-flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 145,
        width: 145,
        marginRight: 39,
        verticalAlign: 'top',
        '.logo': {
          width: 'auto',
          maxHeight: '100%',
          maxWidth: '100%',
          height: 'auto',
        },
      },
      '.info-text-block-wrapper': {
        verticalAlign: 'top',
        display: 'inline-block',
        width: 'calc(90% - 145px)',
        '.sub-header': {
          display: 'inline-block',
          width: '50%',
          paddingBottom: 15,
          '@media (max-width: 553px)': {
            display: 'block',
          },
        },
      },
    },
    '.photo-holder': {
      display: isSafari ? 'block' : 'flex',
      pageBreakInside: 'auto',
      breakInside: 'auto',
      flexWrap: 'wrap',
      '.photo-item': {
        pageBreakBefore: 'auto',
        breakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakAfter: 'auto',
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
        display: 'inline-block',
        paddingBottom: 15,
        paddingRight: 15,
        width: 'calc(33.3333% - 30px)',
      },
    },
    '.signature-holder': {
      display: isSafari ? 'block' : 'flex',
      pageBreakInside: 'auto',
      breakInside: 'auto',
      flexWrap: 'wrap',
      '.signature-item-holder': {
        pageBreakBefore: 'auto',
        breakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakAfter: 'auto',
        pageBreakInside: 'avoid',
        breakInside: 'avoid',
        display: 'inline-block',
        paddingBottom: 10,
        paddingRight: 10,
        '.signature-item': {
          display: 'inline-block',
          width: 135,
          '.s-img': {
            width: '100%',
            border: '1.5px solid lightgray',
          },
        },
      },
    },
    '.view-internal-base-page': {
      padding: '25px',
    },
    '.info-text-block-wrapper': {
      alignItems: 'flex-start',
    },
    '.stat-block': {
      width: 'calc(50% - 24px)',
    },
    '.content-block': {
      padding: '15px 0 0',
      paddingTop: '25px',
      borderTop: '1px solid #f2f2f2',
    },
    '.content-block-title': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#3980CE',
      lineHeight: '19px',
    },
    '.content-block-text': {
      color: '#7F8183',
      fontSize: '13px',
      lineHeight: '17.7px',
    },
    '.page-stat-title': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#a9a9a9',
      lineHeight: '19px',
      paddingBottom: '4px',
    },
    '.page-stat-desc': {
      fontSize: '14px',
      fontWeight: 700,
      color: '#3980CE',
      lineHeight: '19px',
    },
    '.page-stat-border-bottom': {
      padding: '0 0 20px',
      '@media (min-width: 554px)': {
        borderBottom: '1px solid #eee',
      },
    },
    '.gray': {
      color: '#959595',
    },
    '.page-title': {
      fontSize: '24px',
      color: '#686A6C',
      textAlign: 'center',
      margin: '10px 0 0',
      lineHeight: '33px',
    },
    '.page-title-project': {
      fontSize: '12px',
      color: '#676A6C',
      lineHeight: '15px',
      textAlign: 'center',
    },
    ul: {
      listStyleType: 'none',
      padding: '0',
    },
    li: {
      padding: '0',
    },
    '.note-item': {
      padding: '3px 0',
    },
    '.photo-item': {
      width: 'calc(33.3333% - 15px)',
    },
    '.singleImage': {
      marginTop: '0',
      marginRight: '0',
      width: 'auto',
    },
    '.signature-item-block': {
      maxWidth: '135px',
    },
    '.signature-img-w': {
      position: 'relative',
    },
    '.signature-info': {
      gap: '5px',
      maxWidth: '135px',
      paddingTop: '10px',
      fontSize: '9px',
      display: 'flex',
      flexDirection: 'column',
    },
    '.signature-name': {
      color: '#000',
    },
    '.signature-company': {
      color: '#808080',
    },
    '.img-w': {
      height: '69px',
      overflow: 'hidden',
    },
    '.img': {
      maxWidth: '135px',
      height: '100%',
      border: '1.5px solid lightgray',
    },
    '.button-wrapper': {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '25px 0 0',
    },
    '@media print': {
      '.button-print': {
        display: 'none',
      },
    },
  })
