import * as React from 'react'
import { NavBar } from '@/react/screens/TrainingsPages/LMSCommonComponents/NavBar'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { VideoIcon } from '@/react/componentAssets/VideoIcon'
import { useRouter } from '@/react/hooks'
import { useState, useEffect } from 'react'
import { UISref } from '@/react/components/UISref'
import { PdfTrainingsIcon } from '@/react/componentAssets/PdfTrainingsIcon'
import { PptIcon } from '@/react/componentAssets/PptIcon'
import {
  PdfViewerComponent,
  Toolbar,
  Magnification,
  Navigation,
  LinkAnnotation,
  BookmarkView,
  ThumbnailView,
  Print,
  TextSelection,
  Annotation,
  TextSearch,
  FormFields,
  FormDesigner,
  Inject,
} from '@syncfusion/ej2-react-pdfviewer'
import './pdf-viewer.css'

import { registerLicense } from '@syncfusion/ej2-base'

registerLicense(
  'Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxed3RcRGZcVER1VkQ=',
)

export const ViewContent = () => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor: string =
    CurrentUser.getClientSettings().web_primary_color
  const baseStyle = getBaseStyle(variableColor)
  const { stateService } = useRouter()
  const [contentData, setContentData] = useState({})

  useEffect(() => {
    const getContentData = async () => {
      const contentId = stateService.params.programId
      const { data: contentFromServer } = await Api.get(
        `training_content/${contentId}`,
      )
      setContentData(contentFromServer)
    }
    getContentData()
  }, [])

  const { attachment_info } = contentData

  const handleAnswerSubmit = async () => {
    const jobId = stateService.params.jobId

    try {
      await Api.patch(`jobs/${jobId}`, {
        content_completed: contentData?.id,
        user_filter: true,
      })
      stateService.go('trainings.topics', {
        jobId: stateService.params.jobId,
        search: contentData?.id,
      })
    } catch {
      throw Error()
    }
  }

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      {contentData?.content_type === 'pdf' ||
      contentData?.content_type === 'ppt' ? (
        <div className="content__wrapper">
          <div className="page__title">
            {contentData?.content_type === 'pdf' ? (
              <PdfTrainingsIcon color={variableColor} />
            ) : (
              <PptIcon color={variableColor} />
            )}
            <p className="page__text">{contentData?.name}</p>
          </div>

          <div className="file__wrapper">
            {contentData?.content_type === 'pdf' ? (
              <>
                {attachment_info.file && (
                  <PdfViewerComponent
                    id="container"
                    documentPath={
                      'https://corsproxy.io/?' +
                      encodeURIComponent(attachment_info.file)
                    }
                    resourceUrl="https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib"
                    style={{ height: '340px' }}
                  >
                    <Inject
                      services={[
                        Toolbar,
                        Magnification,
                        Navigation,
                        ThumbnailView,
                        Print,
                        TextSelection,
                        TextSearch,
                      ]}
                    />
                  </PdfViewerComponent>
                )}
              </>
            ) : (
              <>
                {attachment_info.file && (
                  <iframe
                    className="file"
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${attachment_info.file}`}
                  ></iframe>
                )}
              </>
            )}
          </div>
          <div className="button-row">
            <UISref
              to={'trainings.topics'}
              params={{
                jobId: stateService.params.jobId,
                search: contentData?.id,
              }}
            >
              <div className="button">Back</div>
            </UISref>

            <ButtonElement
              text={'Done'}
              buttonType={'submit'}
              disabled={contentData?.content_completed}
              functionToTrigger={handleAnswerSubmit}
            />
          </div>
        </div>
      ) : (
        <div className="content__wrapper">
          <div className="page__title">
            <VideoIcon color={variableColor} />
            <p className="page__text">{contentData?.name}</p>
          </div>

          <div className="video">
            {attachment_info?.file && (
              <video controls>
                <source src={`${attachment_info?.file}`} />
              </video>
            )}
          </div>
          <div className="button-row">
            <UISref
              to={'trainings.topics'}
              params={{
                jobId: stateService.params.jobId,
                search: contentData?.id,
              }}
            >
              <div className="button">Back</div>
            </UISref>

            <ButtonElement
              text={'Done'}
              buttonType={'submit'}
              disabled={contentData?.content_completed}
              functionToTrigger={handleAnswerSubmit}
            />
          </div>
        </div>
      )}
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    '.content__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      background: '#fff',
      flexDirection: 'column',
      width: '99%',
    },
    '.page__title': {
      display: 'flex',
      gap: '10px',
      marginTop: '15px',
      marginLeft: '15px',
      marginBottom: '10px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: '-0.12px',
      alignItems: 'center',
    },
    '.page__text': {
      margin: 0,
    },
    '.button-row': {
      display: 'flex',
      justifyContent: 'space-between',
      marginInline: '20px',
      marginBottom: '17px',
      marginTop: '15px',
    },
    '.video': {
      width: '472px',
      height: '314px',
      alignSelf: 'center',
    },
    video: {
      height: '314px !important',
    },
    '.file': {
      height: '500px',
      width: '100%',
      margin: 0,
    },
    '.e-pv-open-document-container': {
      display: 'none',
    },
    '.e-pv-open-separator-container': {
      display: 'none',
    },
    '.e-pv-comment-container': {
      display: 'none',
    },
    '#container_bookmark': {
      display: 'none',
    },
    '.e-pv-annotation-container': {
      display: 'none',
    },
    '.e-pv-formdesigner-container': {
      display: 'none',
    },
    '.e-pv-submit': {
      display: 'none',
    },
    '.e-pv-comment-separator-container': {
      display: 'none',
    },
    '.button': {
      border: `1px solid ${variableColor}`,
      background: '#fff',
      padding: '4px',
      cursor: 'pointer',
      paddingInline: '25px',
      textAlign: 'center',
      fontSize: '12px',
      letterSpacing: '-0.12px',
      color: variableColor,
    },
    '@media(max-width: 800px)': {
      '.content__wrapper': {
        width: '100%',
      },
      '.file': {
        height: '300px',
        width: '300px',
      },
      '.video': {
        display: 'flex',
        justifyContent: 'center',
        width: '313px !important',
      },
      video: {
        height: '208px !important',
      },
    },
  })
}
