import { BinDeleteIcon } from '@/react/componentAssets'
import { css } from '@emotion/react'
import { Field, FieldArray, useFormikContext } from 'formik'
import { TextInputStyle } from '@screens/components'
import { useAngularServices } from '@/react/components'
import { NestedField } from './NestedField'
import manageIcon from '@/assets/icons/manage-icon.svg'
import classNames from 'classnames'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { useState } from 'react'
import { generateUUID } from '@/utils'
import { byOrder } from '@/utils/sortsAndFilter'

export const NestedOption = ({ option, qIndex, oIndex, handleDelete }) => {
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const { setFieldValue, setFieldTouched } = useFormikContext()

  const { name, weight } = option

  const addNestedField = () => {
    if (option.nested_fields.length) {
      setFieldValue(
        `questionsList[${qIndex}].nested_options[${oIndex}].nested_fields`,
        [
          ...option.nested_fields,
          {
            name: '',
            field_type: '',
            belongs_to_option: oIndex,
            order: option.nested_fields.length + 1,
            note: '',
            id: generateUUID(),
          },
        ],
      )
    } else {
      setFieldValue(
        `questionsList[${qIndex}].nested_options[${oIndex}].nested_fields`,
        [
          {
            name: '',
            field_type: '',
            belongs_to_option: oIndex,
            order: 1,
            note: '',
            id: generateUUID(),
          },
        ],
      )
    }
  }

  const handleWeightChange = (event) => {
    const newValue = event.target.value
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 1000) {
      setFieldValue(
        `questionsList[${qIndex}].nested_options[${oIndex}].weight`,
        newValue,
      )
    }
  }

  const baseStyle = getBaseStyle(variableColor)

  return (
    <Draggable key={option.id} draggableId={'' + option.id} index={oIndex}>
      {(provided) => (
        <div ref={provided.innerRef} {...provided.draggableProps}>
          <div className="option__wrapper" css={baseStyle}>
            <img
              src={manageIcon}
              alt="manage-icon"
              className="option__manage"
              {...provided.dragHandleProps}
            />
            <Field
              type="text"
              as={FormikAutoExpandedTextArea}
              name={`questionsList[${qIndex}].nested_options[${oIndex}].name`}
              className={classNames('option__input', {
                error: !name,
              })}
              placeholder="Enter Option"
              value={name}
            />
            <Field
              type="text"
              name={`questionsList[${qIndex}].nested_options[${oIndex}].weight`}
              className={classNames('weight__input', {
                error: weight === '',
              })}
              value={weight}
              placeholder="Weight"
              onChange={handleWeightChange}
            />
            <div className="delete__wrapper">
              {isDeleteModalOpen ? (
                <ConfirmationModal
                  action={'Delete'}
                  handleClick={() => {
                    handleDelete(oIndex)
                    setFieldTouched('any', true)
                    setDeleteModalOpen(false)
                  }}
                  handleClose={() => {
                    setDeleteModalOpen(false)
                  }}
                />
              ) : null}
              <BinDeleteIcon
                color={variableColor}
                height="22px"
                width="17px"
                onClick={() => {
                  setDeleteModalOpen(true)
                }}
              />
            </div>
          </div>
          <p
            className="nested__button"
            onClick={addNestedField}
            style={{
              cursor: 'pointer',
              color: variableColor,
              fontSize: '12px',
              margin: 0,
              marginLeft: '210px',
              width: 'fit-content',
            }}
          >
            + Nested Field
          </p>
          {option?.nested_fields?.length ? (
            <FieldArray
              name={`questionsList[${qIndex}].nested_options.[${oIndex}].nested_fields`}
              render={(arrayHelpers) => {
                async function handleOnDragEnd(result) {
                  if (!result.destination || !option?.nested_fields) return

                  const items = Array.from(option.nested_fields)
                  const [reorderedItem] = items.splice(result.source.index, 1)
                  items.splice(result.destination.index, 0, reorderedItem)
                  items.forEach((item, idx) => {
                    item.order = idx + 1
                  })

                  await setFieldValue(
                    `questionsList[${qIndex}].nested_options.[${oIndex}].nested_fields`,
                    items,
                  )
                }

                return (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="nestedOptionsFields">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {option?.nested_fields
                            ?.sort(byOrder)
                            .map((field, fieldIndex) => (
                              <NestedField
                                key={fieldIndex}
                                field={field}
                                questionIndex={qIndex}
                                optionIndex={oIndex}
                                fieldIndex={fieldIndex}
                                handleDelete={arrayHelpers.remove}
                              />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )
              }}
            />
          ) : null}
        </div>
      )}
    </Draggable>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    display: 'flex',
    gap: '10px',
    alignItems: 'flex-start',
    paddingLeft: '172px',
    paddingTop: '13px',
    paddingBottom: '13px',
    '.option__manage': {
      marginTop: '10px',
    },
    '.option__input': {
      ...TextInputStyle,
      minWidth: '400px',
      background: '#FAFAFA',
      height: '32px',
      fontSize: '12px',

      '::placeholder': {
        color: '#B1B1B1',
      },
    },
    '.weight__input': {
      ...TextInputStyle,
      width: '73px',
      background: '#FAFAFA',
      height: '32px',
      fontSize: '12px',
      textAlign: 'center',

      '::placeholder': {
        color: '#B1B1B1',
      },
    },
    '.delete__wrapper': {
      position: 'relative',
      marginTop: '5px',
    },
  })
