angular.module('ccs').factory('Procore', function ($http, Configuration, Api) {
  const REDIRECT_URI = Configuration.ClientHost + '/procore/redirect_url'
  const CLIENT_ID = Configuration.ProcoreClientID
  const API_URL = Configuration.ProcoreApiURL

  function getAuthLink() {
    return (
      API_URL +
      '/oauth/authorize?' +
      'client_id=' +
      CLIENT_ID +
      '&response_type=code' +
      '&redirect_uri=' +
      REDIRECT_URI
    )
  }

  function getAuthToken(code, cb) {
    Api.get('procore/auth_token', { code }, cb)
  }

  function destroyTokens(cb) {
    Api.get('procore/destroy_tokens', {}, cb)
  }

  function getCompanies(cb, errorCb) {
    Api.get('procore/companies', {}, cb, errorCb)
  }

  function getProjects(companyID, cb, errorCb) {
    Api.get('procore/projects', { company_id: companyID }, cb, errorCb)
  }

  function importProjects(projects, cb, errorCb) {
    Api.post('procore/projects/import', { projects }, cb, errorCb)
  }

  return {
    clientID: CLIENT_ID,
    redirectURI: REDIRECT_URI,
    apiURL: API_URL,
    getAuthLink,
    getAuthToken,
    destroyTokens,
    getCompanies,
    getProjects,
    importProjects,
  }
})
