import moment from 'moment'

angular.module('ccs').service('metadataService', function (CurrentUser) {
  return {
    setFieldMeta: setFieldMeta,
  }

  function setFieldMeta(object, key) {
    if (!object || !key) return

    const metadata = {
      last_updated: moment().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
      last_updated_by_user_id: CurrentUser.getId(),
    }

    if (object.fieldmeta) {
      object.fieldmeta[key] = metadata
    } else {
      object.fieldmeta = {}
      object.fieldmeta[key] = metadata
    }
  }
})
