import * as React from 'react'
import moment from 'moment'
import { css } from '@emotion/react'
import { makeMonthData } from '@components/CalendarDatePickerHelpers'

import { TextInputStyle } from '@screens/components'
import { SingleDay } from '@components/SingleDay'
import { useAngularServices } from '@components/ReactAngularContext'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'

export const CalendarDatePicker = ({
  month: initialMonth,
  year: initialYear,
  selectNumber,
  callback,
  selectionWidth = 'week',
  top = '',
}) => {
  const [month, setMonth] = React.useState<number>(initialMonth)
  const [year, setYear] = React.useState<number>(initialYear)

  const { CurrentUser } = useAngularServices()
  const variableColor: string =
    CurrentUser.getClientSettings().web_primary_color

  const { allVisibleDays: monthData } = makeMonthData(month, year)

  if (!monthData) return null

  function nextMonth(next = true) {
    const monthToSet = next
      ? moment(0).year(year).month(month).add(1, 'M')
      : moment(0).year(year).month(month).subtract(1, 'M')
    setYear(moment(monthToSet).year())
    setMonth(moment(monthToSet).month())
  }

  const baseStyle = getBaseStyle(variableColor, top)

  return (
    <section css={baseStyle}>
      <div className="month-selector">
        <div
          className="arrow-button"
          onClick={() => {
            nextMonth(false)
          }}
        >
          <div className="fa fa-chevron-left" />
        </div>
        <label className="month-year">
          {moment(0).year(year).month(month).format('MMM YYYY').toUpperCase()}
        </label>
        <div
          className="arrow-button"
          onClick={() => {
            nextMonth()
          }}
        >
          <div className="fa fa-chevron-right" />
        </div>
      </div>
      <div className="month">
        <div className="day-of-week calendar-cell">M</div>
        <div className="day-of-week calendar-cell">T</div>
        <div className="day-of-week calendar-cell">W</div>
        <div className="day-of-week calendar-cell">T</div>
        <div className="day-of-week calendar-cell">F</div>
        <div className="day-of-week calendar-cell">S</div>
        <div className="day-of-week calendar-cell">S</div>
        {monthData.map((day, index) => (
          <SingleDay
            hoverColor={percentageShadeBlendColor(0.95, variableColor)}
            day={day}
            key={index}
            selectNumber={selectNumber}
            callback={callback}
            selectionWidth={selectionWidth}
          />
        ))}
      </div>
    </section>
  )
}
function getBaseStyle(variableColor: string, top: string) {
  return css({
    zIndex: 1500,
    right: '-16px',
    top: !top ? '-375px' : top,
    position: 'absolute',
    boxShadow: '0 0 1px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 20%)',
    backgroundColor: 'white',
    width: 330,
    padding: 15,
    '@media(max-width: 340px)': {
      width: 240,
      right: '-10px',
    },
    '.month': {
      flexWrap: 'wrap',
      display: 'flex',
      width: '100%',
      '.calendar-cell': {
        width: '14.8%',
        height: 46,
        lineHeight: '46px',
        textAlign: 'center',
        margin: -1,
      },
      '.day': {
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'rgb(229, 230, 231)',
        cursor: 'pointer',
        ':hover': {
          backgroundColor: percentageShadeBlendColor(0.95, variableColor),
        },
      },
      '.activeDay': {
        backgroundColor: variableColor,
        color: 'white',
        ':hover': {
          backgroundColor: variableColor,
        },
      },
      '.current-m-false': {
        color: 'rgb(150,150,150)',
      },
    },
    '.month-selector': {
      display: 'flex',
      justifyContent: 'space-between',
      '.month-year': {
        fontSize: 20,
        height: 30,
      },
      '.arrow-button': {
        height: 30,
        width: 30,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        div: {
          fontSize: 12,
          height: 12,
        },
        ...TextInputStyle,
      },
    },
  })
}
