import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { trimFileName } from '@/utils/download'
import { useRouter } from '@/react/hooks'

const FILE_SIZE_LIMIT = 50 * 1024 * 1024
const FILE_NAME_LENGTH_LIMIT = 64

export function SingleFileWithLoading({
  file,
  removeFunction,
  addToMemoryFunction,
  noSend,
  promisesToDeleteFiles,
}) {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const [fileName, setFileName] = React.useState(
    noSend ? file.data.name : file.name,
  )
  const [uploadedFile, setUploadedFile] = React.useState(null)
  const [isDeleted, setIsDeleted] = React.useState(false)
  const [isLoaded, setIsLoaded] = React.useState(noSend)
  const [isLoading, setIsLoading] = React.useState(!noSend)
  const [sizeError, setSizeError] = React.useState(false)
  const [nameError, setNameError] = React.useState(false)
  const [sendError, setSendError] = React.useState(false)

  function deleteFile() {
    if (sizeError || sendError || nameError) {
      setIsDeleted(true)
    } else if (isLoading) {
    } else {
      if (stateService.current.name === 'assigned.external_form') {
        if (noSend) {
          promisesToDeleteFiles({
            id: file.data.client_object_key,
            type: 'file',
            page: 'external',
          })
          removeFunction(file.data.id, file.data.client_object_key)
        } else {
          promisesToDeleteFiles({
            id: uploadedFile.data.client_object_key,
            type: 'file',
            page: 'external',
          })
          removeFunction(
            uploadedFile.data.id,
            uploadedFile.data.client_object_key,
          )
        }
      } else {
        if (noSend) {
          promisesToDeleteFiles({
            id: file.data.id,
            type: 'file',
            page: 'internal',
          })
          removeFunction(file.data.id)
        } else {
          promisesToDeleteFiles({
            id: uploadedFile.data.id,
            type: 'file',
            page: 'internal',
          })
          removeFunction(uploadedFile.data.id)
        }
      }
      setIsDeleted(true)
    }
  }

  async function fileUpload(file) {
    if (!noSend) {
      if (file.size > FILE_SIZE_LIMIT) {
        setIsLoading(false)
        setSizeError(true)
        setIsLoaded(true)
      } else if (file.name.length >= FILE_NAME_LENGTH_LIMIT) {
        setIsLoading(false)
        setNameError(true)
        setIsLoaded(true)
      } else {
        const fileResponse = await Api.uploadFile(file, {})
        setUploadedFile(fileResponse)
        setIsLoaded(true)
        setIsLoading(false)
        addToMemoryFunction(fileResponse.data.id)
      }
    }
  }

  React.useEffect(() => {
    fileUpload(file)
  }, [])

  if (isDeleted) return null

  const baseStyle = getBaseStyles()

  return (
    <div css={baseStyle} className="body-for-uploaded one-file">
      {isLoaded ? <div className="loaded-check" /> : null}
      <div
        className="file-name-base-block"
        ng-class="{'error-due-to-size': file.state === 'failedDueSize' || file.state === 'uploadFailed',
          'loading-state': file.state === 'uploadingFile'}"
      >
        <span className="file-icon" />
        <p className="file-name">{trimFileName(fileName, 8)}</p>
        {sizeError ? <p className="file-error">(File exceeds 50MB)</p> : null}
        {nameError ? <p className="file-error">(Filename too long)</p> : null}
        {sendError ? (
          <a className="retry-button-base">
            <img
              src="/assets/icons/Icons_Refresh.svg"
              alt="RETRY"
              className="retry-button"
            />
          </a>
        ) : null}
        {isLoading ? null : (
          <a className="remove-file-cross-base" onClick={deleteFile}>
            {sendError || sizeError ? (
              <img
                src="/assets/icons/X Red.svg"
                alt="X"
                className="remove-file-cross-red"
              />
            ) : (
              <img
                alt="X"
                src="/assets/icons/X Gray.svg"
                className="remove-file-cross"
              />
            )}
          </a>
        )}
        {!isLoading ? null : (
          <span className="fa fa-spinner variable-color-text fa-spin spinner" />
        )}
        {fileName?.length > 11 ? (
          <div className="hover-body-for-files">{fileName}</div>
        ) : null}
      </div>
    </div>
  )
}
function getBaseStyles() {
  return css({
    marginTop: '10px',
    marginRight: '10px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
    position: 'relative',
    '.loaded-check': {
      backgroundImage: 'url("../../assets/icons/Check.svg")',
      marginRight: '10px',
      height: '20px',
      width: '20px',
    },
    '.file-name-base-block': {
      height: '32px',
      paddingLeft: '5px',
      paddingRight: '5px',
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#F7F7F7',
      border: '1px solid #999',
      '.file-icon': {
        padding: '0',
        height: '20px',
        width: '20px',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url("../../assets/icons/Card Icon.svg")',
      },
      '.file-name': {
        height: '18px',
        margin: '0',
        width: '100px',
        whiteSpace: 'nowrap',
      },
      '.file-error': {
        height: '18px',
        color: '#C01C20',
        fontStyle: 'italic',
        margin: '0',
        width: '122px',
      },
      '.remove-file-cross-base': {
        padding: '0',
        height: '20px',
        width: '20px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '.remove-file-cross': {
          padding: 'inherit',
          height: '15px',
          width: '15px',
        },
        '.remove-file-cross-red': {
          padding: 'inherit',
          height: '20px',
          width: '20px',
        },
      },
      '.retry-button-base': {
        padding: '0',
        height: '32px',
        width: '32px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '.retry-button': { padding: 'inherit', height: '20px', width: '20px' },
      },
      '.spinner': { height: '20px', width: '20px' },
    },
    '.error-due-to-size': {
      backgroundColor: '#FFECEB',
      borderColor: '#C01C20',
    },
    '.hover-body-for-files': {
      position: 'absolute',
      left: '-20px',
      bottom: 'calc(100% + 5px)',
      display: 'none',
      border: '1px solid #999',
      backgroundColor: 'white',
      padding: '5px',
    },
  })
}
