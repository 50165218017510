angular.module('ccs').filter('orderBySub', orderBySubFilter)

// Works as 'orderBy' filter supporting nested object properties
// Usage example: ng-repeat="item in userData.jobs | orderBySub:'training.training_topic'"
// or
// ng-repeat="item in userData.jobs | orderBySub:'training.training_topic':true" for reverse sort
function orderBySubFilter() {
  return function (items, field, reverse) {
    const byString = function (o, s) {
      const fields = s
        .replace(/\[(\w+)\]/g, '.$1')
        .replace(/^\./, '')
        .split('.')
      for (let i = 0, n = fields.length; i < n; ++i) {
        const k = fields[i]
        if (k in o) {
          o = o[k]
        } else {
          return
        }
      }
      return o
    }

    items.sort(function (a, b) {
      if (byString(a, field) > byString(b, field)) return reverse ? -1 : 1
      if (byString(a, field) < byString(b, field)) return reverse ? 1 : -1
      return 0
    })
    return items
  }
}
