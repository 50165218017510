import {
  ApplicationConfig,
  GenericOption,
  ReportAnswer,
  ReportQuestion,
} from '@/react/types'
import { Dispatch, SetStateAction } from 'react'
import * as React from 'react'
import classNames from 'classnames'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { SingleIdQuestionsElement } from '@screens/Observations/CreateEditPage/components/SingleIdQuestionsElement'
import { percentageShadeBlendColor } from '@/utils'

type CategoryProps = {
  category: { name: string; id: number }
  questions: ReportQuestion[]
  ANSWER: ReportAnswer
  globalPartyObserved: GenericOption[]
  setPartyObservedOptions: Dispatch<SetStateAction<GenericOption[]>>
  partyObservedOptions: GenericOption[]
  app: ApplicationConfig
  saveButtonPressed: number
  memoryState: {
    [key: string]: ReportAnswer[]
  }
  setMemoryState: Dispatch<
    SetStateAction<{ [key: string]: ReportAnswer[] } | null>
  >
  index: number
}

export const Category = ({
  category,
  questions,
  memoryState,
  setMemoryState,
  ANSWER,
  globalPartyObserved,
  partyObservedOptions,
  setPartyObservedOptions,
  app,
  saveButtonPressed,
}: CategoryProps) => {
  const [openToggle, setOpenToggle] = React.useState<boolean>(false)
  const [categoryQuestions] = React.useState<ReportQuestion[]>(
    questions.filter((question) => {
      return question.category === category.id
    }),
  )

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  if (categoryQuestions.length === 0 || !categoryQuestions) return null

  return (
    <div css={baseStyle}>
      <div
        className="fullWidth header-category-holder"
        css={css({
          backgroundColor: percentageShadeBlendColor(0.5, variableColor),
        })}
        onClick={() => {
          setOpenToggle(!openToggle)
        }}
      >
        <div className="category-header">
          <span
            className={classNames('', {
              'cut-text': !openToggle,
            })}
          >
            {category.name}
          </span>
        </div>
        <div className="toggle-holder">
          <div
            css={[
              css({
                fontSize: 20,
                color: variableColor,
                transform:
                  'rotate(' + (openToggle ? 90 : -90) + 'deg) scale(1,2)',
              }),
            ]}
          >
            {'>'}
          </div>
        </div>
      </div>
      <section
        css={css({
          display: openToggle ? 'block' : 'none',
          paddingTop: 20,
        })}
      >
        {categoryQuestions.map((question) => (
          <SingleIdQuestionsElement
            key={question.id}
            globalPartyObserved={globalPartyObserved}
            partyObservedOptions={partyObservedOptions}
            setPartyObservedOptions={setPartyObservedOptions}
            questionInfo={question}
            memoryState={memoryState}
            setMemoryState={setMemoryState}
            ANSWER={ANSWER}
            app={app}
            saveButtonPressed={saveButtonPressed}
          />
        ))}
      </section>
    </div>
  )
}

const baseStyle = css({
  marginBottom: 10,
  '.fullWidth': {
    width: '100%',
  },
  '.header-category-holder': {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 57,
    '.category-header': {
      paddingTop: 10,
      paddingBottom: 10,
      width: 'calc(100% - 50px)',
      paddingLeft: 15,
      paddingRight: 30,
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
      display: 'flex',
      alignItems: 'center',
      fontSize: 15,
      fontWeight: 600,
      '.cut-text': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    '.right-header-part': {
      width: 300,
      display: 'flex',
      justifyContent: 'right',
    },
    '.select-answer-holder': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minWidth: 180,
      '.select-answer': {
        height: 34,
        borderColor: '#e5e6e7',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '0',
      },
    },
  },
  '.toggle-holder': {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
  },
})
