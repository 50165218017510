import { ViewIcon } from '@/react/componentAssets'
import { css } from '@emotion/react'
import classNames from 'classnames'

export const DesktopView = ({ jobs, color }) => {
  const baseStyle = getBaseStyle()
  return (
    <div css={baseStyle} className="table__wrapper">
      <table className="documentation__table">
        <thead>
          <tr className="table__header">
            <th className="topic__cell">Training Topic</th>
            <th className="trained__cell">Trained</th>
            <th className="expiring__cell">Expiring</th>
            <th className="trainer__cell">Trained By</th>
            <th className="trade__cell">Trade</th>
            <th className="view__cell">View</th>
          </tr>
        </thead>
        <tbody>
          {jobs?.map((job, index) => {
            const { training, certificate } = job
            const {
              training_topic,
              date_trained,
              date_training_expires,
              trained_by,
              trade,
            } = training

            return (
              <tr
                className={classNames('documentation__row', {
                  dark: index % 2 === 0,
                })}
                key={job.id}
              >
                <td className="topic__cell">{training_topic}</td>
                <td>{date_trained}</td>
                <td>{date_training_expires}</td>
                <td>{trained_by} </td>
                <td>{trade}</td>
                <td className="view__cell">
                  {certificate?.file && (
                    <a href={certificate.file} target="_blank">
                      <ViewIcon height="18px" color={color} />
                    </a>
                  )}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

function getBaseStyle() {
  return css({
    marginInline: '37px',
    '.documentation__table': {
      marginTop: '27px',
    },
    '.table__wrapper': {
      marginInline: '37px',
    },
    '.table__header': {
      borderBottom: '1px solid #EEEFF3',
    },
    '.topic__cell': {
      width: '200px',
      padding: '10px',
    },
    '.trained__cell': {
      width: '100px',
    },
    '.expiring__cell': {
      width: '100px',
    },
    '.trainer__cell': {
      width: '150px',
    },
    '.trade__cell': {
      width: '120px',
    },
    '.view__cell': {
      width: '70px',
      textAlign: 'center',
    },
    '.table__row': {
      color: '#575757',
      height: '32px',
    },
  })
}
