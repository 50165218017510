import * as React from 'react'
import { TextInputStyle } from '@screens/components'
import { debounce } from 'lodash'
import classNames from 'classnames'
import { validateEmail } from '@/utils/uniqueValidation'

type TextInputTypes = {
  valueCallback: (string) => void
  noDelayValueCallback?: (string) => void
  height?: number | string
  width?: number | string
  maxWidth?: number | string
  defaultValue: number | string
  placeholder?: string
  waitTime: number
  textarea?: boolean
  error: boolean
  startUpCase?: boolean
  autoFocus?: boolean
  textLimit: number
  textLimitDisplay?: boolean
  allowEmptyStart?: boolean
  checkAsEmail?: boolean
  onlyNumbers?: boolean
}

export const TextInput = ({
  valueCallback,
  noDelayValueCallback = () => {},
  height = 30,
  width = 'auto',
  maxWidth = '100%',
  defaultValue = 0,
  placeholder = '',
  waitTime = 0,
  textarea = false,
  error = false,
  startUpCase = false,
  autoFocus = false,
  textLimit = Infinity,
  textLimitDisplay = false,
  allowEmptyStart = true,
  checkAsEmail = false,
  onlyNumbers = false,
}: TextInputTypes) => {
  const [multiLineFieldRef, setMultiLineFieldRef] = React.useState(null)
  const [value, setValue] = React.useState(
    defaultValue === 0 ? '' : defaultValue,
  )
  const [validAsEmail, setValidAsEmail] = React.useState(false)

  const variableStyle = {
    width: width,
    display: 'flex',
    maxWidth: maxWidth,
    flexDirection: 'column',
    input: {
      ...TextInputStyle,
      height: height,
      minHeight: height,
      minWidth: width,
      width: width,
      maxWidth: '100%',
    },
    '.error__input': {
      border: '1px solid #C80404',
    },
    textarea: {
      ...TextInputStyle,
      minHeight: height,
      minWidth: width,
      width: width,
      maxWidth: '100%',
    },
    '.char-count-body': {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }

  const capitalizeWords = (str) => {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1)
    })
  }

  const debouncedOnChange = React.useMemo(
    () => debounce(valueCallback, waitTime),
    [valueCallback, waitTime],
  )

  function onChange(env) {
    let value = env.target.value
    if (allowEmptyStart) value = value.trimStart()
    if (onlyNumbers) value = value.replace(/^\D+/g, '')
    noDelayValueCallback(value)
    debouncedOnChange(value)
    startUpCase
      ? startUpCase
        ? setValue(capitalizeWords(value))
        : setValue(value)
      : setValue(value)
    if (!allowEmptyStart) {
      multiLineFieldRef.value = value
    }
    if (checkAsEmail) {
      setValidAsEmail(validateEmail(value))
    }
  }

  if (multiLineFieldRef) {
    multiLineFieldRef.style.height = `${multiLineFieldRef.scrollHeight}px`
  }

  return (
    <div css={variableStyle}>
      {!textarea ? (
        <input
          type="text"
          placeholder={placeholder}
          onChange={onChange}
          ref={(el) => setMultiLineFieldRef(el)}
          autoFocus={autoFocus}
          className={classNames({ error__input: error })}
          maxLength={textLimit}
          value={value}
        />
      ) : (
        <textarea
          style={{ width: '100%', height: height }}
          onChange={onChange}
          ref={(el) => setMultiLineFieldRef(el)}
          autoFocus={autoFocus}
          className={classNames({ error__input: error })}
          maxLength={textLimit}
          value={value}
        />
      )}
      {!textLimitDisplay && !checkAsEmail ? null : (
        <div className="char-count-body">
          <span>
            {checkAsEmail && !validAsEmail ? 'Invalid email address' : null}
          </span>
          <span>
            {!textLimitDisplay
              ? null
              : textLimit - (typeof value === 'string' ? value.length : 0)}
          </span>
        </div>
      )}
    </div>
  )
}
