import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function companyNewsConfig($stateProvider) {
  $stateProvider
    .state('app.company_news.add', {
      template: '<company-news-create-edit-component/>',
      url: '/add-company-news?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.writeOnlyViewResolver,
      },
    })
    .state('app.company_news.edit', {
      template: '<company-news-create-edit-component/>',
      url: '/company_news/:newsID/edit?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.writeOnlyViewResolver,
        ...resolvers.adminOnlyViewResolver,
      },
    })
  // .state('app.company_news.add', {
  //   controller: 'CompanyNewsFormCtrl',
  //   templateUrl: 'app/views/news_form.html',
  //   url: '/add-company-news?previousPageNumber&previousOrder&previousReverse&previousSearch',
  //   resolve: {
  //     news: function () {
  //       return null
  //     },
  //     ...resolvers.appResolver,
  //     ...resolvers.writeOnlyViewResolver,
  //   },
  // })
  // .state('app.company_news.edit', {
  //   controller: 'CompanyNewsFormCtrl',
  //   templateUrl: 'app/views/news_form.html',
  //   url: '/company_news/:newsID/edit?previousPageNumber&previousOrder&previousReverse&previousSearch',
  //   resolve: {
  //     /** @ngInject */
  //     news: function (Api, $stateParams, $q) {
  //       const deferred = $q.defer()
  //       //noinspection JSUnresolvedVariable
  //       Api.CompanyNews.byID($stateParams.newsID, function (resp) {
  //         deferred.resolve(resp)
  //       })
  //
  //       return deferred.promise
  //     },
  //     ...resolvers.appResolver,
  //     ...resolvers.writeOnlyViewResolver,
  //   },
  // })
}
