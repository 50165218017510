angular.module('ccs').directive('confirmalert', confirmAlert)

function confirmAlert() {
  return {
    link: function (scope, element, attr) {
      var msg = attr.confirmText || 'Are you sure?'
      var clickAction = attr.onConfirm
      element.bind('click', function () {
        if (window.confirm(msg)) {
          scope.$eval(clickAction)
        }
      })
    },
  }
}
