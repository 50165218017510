import { css } from '@emotion/react'
import ZurichLogo from '@/assets/images/zurich-logo.png'
import Logo from '@/assets/images/rtrs-logo.png'
import { useEffect, useState } from 'react'
import { useAngularServices } from '@/react/components'
import classNames from 'classnames'

export const RequestInvite = () => {
  const { Configuration, Api, Notification } = useAngularServices()
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')
  const [message, setMessage] = useState('')
  const [isZurich, setIsZurich] = useState(false)

  useEffect(() => {
    if (
      Configuration.appEnv === 'zurich-dev' ||
      Configuration.appEnv === 'zurich-production' ||
      Configuration.appEnv === 'zurich-master'
    ) {
      setIsZurich(true)
    }
  }, [])

  const handleRequest = async () => {
    if (username) {
      try {
        await Api.post('users/reset_password', {
          username,
        })
        setMessage('New link successfully sent!')
      } catch {
        Notification.clearNotification()
        setError(
          'Your username is not active. Please contact your Administrator.',
        )
      }
    }
  }

  const baseStyle = getBaseStyles(isZurich, error, message)

  return (
    <div css={baseStyle}>
      <div className="login__wrapper">
        <div className="login__body">
          <div className="login__logo">
            <img className="logo" src={!isZurich ? Logo : ZurichLogo} alt="" />
          </div>
          <div className="message__block">
            <p className="login__message">
              <strong>This password link is no longer active.</strong>
            </p>
            <p className="login__message">
              To request a new link, enter your username
            </p>
            {error && <p className="error__message">{error}</p>}
            {message && <p className="success__message"> {message}</p>}
          </div>
          <input
            type="text"
            className="login__input"
            placeholder="Username"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value)
              setMessage('')
              setError('')
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleRequest()
              }
            }}
          />
          <div
            className={classNames('login__button', {
              disabled: !username || message,
            })}
            onClick={handleRequest}
          >
            Email new link
          </div>
          <p className="footer__message">
            If you don’t know your username, contact your administrator to
            resend a link.
          </p>
          <p className="login__copyright">© Copyright 2024</p>
        </div>
      </div>
    </div>
  )
}

function getBaseStyles(isZurich, error, message) {
  return css({
    '.login__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: isZurich ? '#2167ae' : 'white',
      width: '100%',
      height: '100vh',
      backgroundImage: !isZurich
        ? `url(../assets/images/login-background.png)`
        : '',
      backgroundSize: !isZurich ? 'cover' : '',
      backgroundPosition: !isZurich ? 'center' : '',
      paddingBottom: '20vh',
    },
    '@media (min-height: 1010px)': {
      '.login__wrapper': {
        paddingBottom: '32vh',
        paddingTop: '15vh',
      },
    },
    '.message__block': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginBottom: !error && !message ? '34px' : '7px',
      textAlign: 'center',
    },
    '.error__message': {
      fontSize: '12px',
      color: '#BD2828',
      margin: 0,
    },
    '.success__message': {
      color: '#0B9208',
      fontSize: '12px',
      margin: 0,
    },
    '.login__message': {
      margin: 0,
      color: '#515253',
      fontSize: '15px',
    },
    '.login__body': {
      boxSizing: 'border-box',
      marginTop: '100px',
      width: '542px',
      background: 'rgba(217, 217, 217, 0.8)',
      borderRadius: '20px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    '.login__logo': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.logo': {
      width: !isZurich ? '204px' : 'auto',
      height: !isZurich ? '204px' : 'auto',
    },
    '.login__input': {
      background: '#ffffff',
      borderRadius: '25px',
      fontSize: '12px',
      height: '40px',
      padding: '0px 79px 0px 25px',
      color: '#515253',
      border: '0.5px solid #2167AE',
      width: '333px',
      outline: 'none',
      '&::placeholder': {
        color: '#999999',
      },
    },
    '.login__button': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: '10px',
      cursor: 'pointer',
      fontSize: '12px',
      color: '#fff',
      fontWeight: 400,
      width: '333px',
      height: '30px',
      background: '#3980CE',
      borderRadius: '25px',
    },
    '.disabled': {
      background: 'grey',
      cursor: 'default',
    },
    '.footer__message': {
      color: '#515253',
      fontSize: '13px',
      fontWeight: 400,
      marginTop: '30px',
      marginBottom: '30px',
    },
    '.login__copyright': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '11px',
      textAlign: 'center',
      color: '#68696D',
      width: '303',
      marginBottom: '22px',
    },
  })
}
