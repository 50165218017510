import * as Icons from '../../webpack.icons.js'

angular
  .module('ccs')
  .controller(
    'TrainingTopicFormCtrl',
    function (
      $scope,
      $rootScope,
      Api,
      $state,
      $stateParams,
      ngDialog,
      uiPermissionService,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('TrainingTopicFormCtrl')

      $scope.loadIcon = (icon) => Icons.icons[icon]

      //For for restoring pagination
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'order'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = $scope.search ? true : false
      $scope.maxSize = 10 //Number of pager buttons to show
      $scope.selectedTopic = false

      $scope.pageSize = 20
      $scope.itemIndex
      $scope.userIs = CurrentUser.is
      //UI Permissions
      $scope.permissionToManageTraining =
        uiPermissionService.getPermissionToManageTraining()

      $scope.serial = 1
      $scope.$watch('pageNumber', function () {
        $scope.serial =
          $scope.pageNumber * $scope.pageSize - ($scope.pageSize - 1)
      })
      $scope.topics = {}
      $scope.trainingID = $stateParams.trainingID || $stateParams.jobId

      function getTrainingTopicById(id) {
        Api.get(
          `trainings/${id}`,
          {},
          (resp) => {
            $scope.trainingTopic = resp
          },
          (error) => {
            Notification.danger(
              JSON.stringify(error && error.detail ? error.detail : error),
            )
          },
        )
      }

      function trainingTopicsQuery() {
        let q = {
          training: $stateParams.trainingID,
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          tz_offset: new Date().getTimezoneOffset(),
        }
        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      function getTrainingTopics(id) {
        if (id) {
          getTrainingTopicById(id)

          Api.Topics.get(
            trainingTopicsQuery(),
            function (resp) {
              $scope.topics = resp
              $scope.itemIndex = 1
              resp.results.map(function (item, v) {
                item.content_items.map(function (content, k) {
                  if (content.content_type === 'quiz') {
                    item.quiz_exist = true
                  }
                })
              })
            },
            (error) => {
              Notification.danger(
                JSON.stringify(error && error.detail ? error.detail : error),
              )
            },
          )
        }
        if ($stateParams.jobId) {
          Api.getWithoutDeletedParam(
            `jobs/${$stateParams.jobId}`,
            {
              user_filter: true,
              order: $scope.order,
            },
            (resp) => {
              $scope.topics = resp.training
              resp.training.topics.map(function (item, v) {
                /////////////////New functional for show/hide Quiz///////////////
                var count = item.content_items.length
                if (Math.round((100 / count) * (count - 1)) <= item.status) {
                  item.showQuiz = true
                } else {
                  item.showQuiz = false
                }
              })
            },
            (error) => {
              Notification.danger(
                JSON.stringify(error && error.detail ? error.detail : error),
              )
            },
          )
        }
      }

      getTrainingTopics($stateParams.trainingID)

      $scope.delete = function (topicId, contentId) {
        Api.TopicContent.delete({ id: contentId }, (resp) => {
          getTrainingTopics($stateParams.trainingID)
        })
      }

      $scope.$on('removeStep', function () {
        getTrainingTopics($stateParams.trainingID)
      })

      $scope.$on('updateTopic', function () {
        getTrainingTopics($stateParams.trainingID)
      })

      $scope.updateTopic = (id) => {
        getTrainingTopics(id)
      }

      $scope.idToUpdate = $stateParams.trainingID

      $scope.checkCompletionCode = function (
        content_id,
        name,
        desc,
        status,
        event,
      ) {
        event.preventDefault()
        ngDialog.open({
          template: 'app/views/check_completion_code_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.completion_code = null
            $scope.content_name = name
            $scope.content_description = desc
            $scope.content_status = status
            $scope.codeError = null

            function getCodeQuery() {
              return {
                code: $scope.completion_code,
              }
            }

            $scope.save = function () {
              Api.get(
                `code/${content_id}`,
                getCodeQuery(),
                function (resp) {
                  if (resp) {
                    Api.patch(
                      `jobs/${$stateParams.jobId}`,
                      {
                        user_filter: true,
                        content_completed: resp.id,
                      },
                      () => {
                        location.reload()
                        $scope.closeThisDialog()
                      },
                    )
                  }
                },
                function (error) {
                  $scope.codeError = error.error
                  $scope.completion_code = null
                },
              )
            }
            $scope.close = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.addNewTopic = function (topics) {
        ngDialog.open({
          template: 'app/views/add_new_topic_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function ($scope) {
            $scope.maxOrder = topics.length + 1
            $scope.topic = {
              name: null,
              order: topics.length + 1 || null,
              training: $stateParams.trainingID,
            }
            $scope.disableButton = false

            $scope.save = function () {
              $scope.disableButton = true
              Api.Topic.post($scope.topic, function (resp) {
                $scope.closeThisDialog()
                getTrainingTopics($stateParams.trainingID)
              })
            }
            $scope.close = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.addNewTopicContent = function (
        _topicId,
        _topicQuizExist,
        _topicHoExist,
        _editTopic,
        _topicOrder,
        _maxOrder,
      ) {
        ngDialog.open({
          template: 'app/views/add_new_topic_content_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          controller: 'TrainingTopicContentFileCtrl',
          resolve: {
            topicContent: function topicContent() {
              return {
                topic: _topicId,
                attachment: null,
                attachment_info: null,
                content_type: null,
                name: null,
                description: null,
                completion_code: null,
                quiz_name: null,
                order: _topicOrder || 1,
              }
            },
            quizExist: () => _topicQuizExist,
            hoExist: () => _topicHoExist,
            file: () => null,
            editTopic: () => _editTopic,
            topicOrder: () => _topicOrder,
            maxOrder: () => ($scope.maxOrderValue = _maxOrder || 1),
          },
        })
      }

      $scope.editTopicContent = function (data) {
        ngDialog.open({
          template: 'app/views/add_new_topic_content_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          controller: 'TrainingTopicContentFileCtrl',
          resolve: {
            topicContent: () => data.topicContent,
            file: () => data.file,
            quizExist: () => data.quizExist,
            hoExist: () => data.hoExist,
            editTopic: () => data.editTopic,
            topicOrder: () => data.topicOrder,
            maxOrder: () => ($scope.maxOrderValue = data.maxOrder),
          },
        })
      }

      $scope.openPreviousTopic = function () {
        let topic = document.querySelector(
          '[topic-id="' + $stateParams.topicId + '"]',
        )
        if (topic && !$scope.selectedTopic) {
          topic.click()
          $scope.selectedTopic = true
        }
      }

      $scope.gotoQuizPage = function (
        content,
        trainingID,
        topicId,
        pageNumber,
      ) {
        $state.go(
          'trainings.quiz.edit',
          {
            contentId: content.id,
            trainingID: trainingID,
            topicId: topicId,
            topicOrder: content.order,
            quizName: content.name,
            previousPageNumber: pageNumber,
          },
          {
            reload: true,
          },
        )
      }
    },
  )
