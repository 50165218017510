import { css } from '@emotion/react'
import * as React from 'react'

export const VisualPlugElements = ({ activityHeadersRefs, tabData }) => {
  if (!activityHeadersRefs) return null

  return (
    <td className="single-column">
      <div className="fake-row dark-row" />
      <div className="fake-row third-darker-row" />
      <div className="fake-row" />
      <div className="fake-row third-darker-row" />
      <div className="fake-row" />
      <div className="fake-row  dark-row" />
      {tabData.costCodes.map((costCode, idx) => {
        if ((idx + 1) % 2 === 0)
          return (
            <div
              css={css({
                height: activityHeadersRefs[idx]
                  ? activityHeadersRefs[idx].clientHeight + 'px !important'
                  : 60,
              })}
              key={idx + '-costCodeHolder'}
              className="fake-row activity-row third-darker-row"
            />
          )
        return (
          <div
            css={css({
              height: activityHeadersRefs[idx]
                ? activityHeadersRefs[idx].clientHeight + 'px !important'
                : 60,
            })}
            key={idx + '-costCodeHolder'}
            className="fake-row activity-row"
          />
        )
      })}
    </td>
  )
}
