import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import viewIcon from '@/assets/icons/view-icon.svg'
import addIcon from '@/assets/icons/add-icon.svg'
import uploadIcon from '@/assets/icons/upload-icon.svg'
import { useRouter, useSref } from '@/react/hooks'
import classNames from 'classnames'
import { UISref } from '@/react/components/UISref'
import deleteIcon from '@/assets/icons/delete-nav-icon.svg'
import assignIcon from '@/assets/icons/assign-icon.svg'
import groupIcon from '@/assets/icons/lms-group.svg'
import { useAngularServices } from '@/react/components'

// @ts-ignore
export const ToolboxTemplatesNavBar = ({}) => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  useEffect(() => {
    if (
      CurrentUser.getRole() !== 'client_admin' &&
      CurrentUser.getRole() !== 'supervisor'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'app.toolboxes.toolbox_templates'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name ===
                  'app.toolboxes.toolbox_templates' ||
                stateService.current.name ===
                  'app.toolboxes.toolbox_templates_view' ||
                stateService.current.name === 'app.toolboxes.edit',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>
        <UISref to={'app.toolboxes.add'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'app.toolboxes.add',
            })}
          >
            <img className="nav__icon" src={addIcon} alt="view nav__icon" />
            <p>Create</p>
          </a>
        </UISref>

        <UISref to={'app.toolboxes.project_to_toolbox'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name ===
                  'app.toolboxes.toolbox_to_project' ||
                stateService.current.name ===
                  'app.toolboxes.project_to_toolbox' ||
                stateService.current.name === 'app.toolboxes.assign_projects' ||
                stateService.current.name === 'app.toolboxes.assign',
            })}
          >
            <img className="nav__icon" src={assignIcon} alt="add nav__icon" />
            <p>Assign</p>
          </a>
        </UISref>
        {CurrentUser.getRole() !== 'supervisor' && (
          <UISref to={'app.toolboxes.groups'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name === 'app.toolboxes.groups' ||
                  stateService.current.name === 'app.toolboxes.group_assign',
              })}
            >
              <img className="nav__icon" src={groupIcon} alt="add nav__icon" />
              <p>Group</p>
            </a>
          </UISref>
        )}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  marginLeft: '5px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.nav__icon': {
      marginLeft: '25px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: '10px',
      marginRight: '25px',
      marginBottom: '0px',
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '25px',
  },
})
