import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormDownloadReportDataCtrl',
    function (
      $scope,
      $state,
      Api,
      ngDialog,
      app,
      $stateParams,
      CurrentUser,
      $log,
    ) {
      $log.debug('FormDownloadReportDataCtrl')

      $scope.reports = []
      $scope.mobileForms = []
      $scope.appMobileFormDisplay = pluralize.singular(
        app.app_mobile_form_display,
      )

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'date_created'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = $scope.search ? true : false

      $scope.pageSize = 20
      $scope.selectedForm = ''
      $scope.disabled = true
      $scope.totalItems = $scope.pageNumber * $scope.pageSize

      $scope.changePage = function () {
        $state.go(
          'app.forms.reports_export_list',
          {
            app: app.id,
            formId: $scope.selectedForm,
            startDate: formatDate($scope.start_date),
            endDate: formatDate($scope.end_date),
          },
          { reload: true },
        )
      }

      function query() {
        let q = {
          client_id: CurrentUser.getClientId(),
          application: app.id,
          search: $scope.searchExecuted ? $scope.search : null,
          deleted: null,
        }

        return q
      }

      function getMobileForms() {
        Api.ExportMobileFormsList.get(query(), function (resp) {
          $scope.mobileForms = resp
          $scope.disabled = false

          if ($scope.mobileForms) {
            $scope.mobileForms.map(function (item) {
              item.date_created = item.date_created.split(' ').join(' - ')
            })
          }
        })
      }

      getMobileForms()

      $scope.popup = {
        startPopup: false,
        endPopup: false,
      }

      $scope.startOptions = {
        formatYear: 'yy',
        datepickerMode: 'day',
      }

      $scope.endOptions = {
        formatYear: 'yy',
        datepickerMode: 'day',
        minDate: new Date(),
      }

      $scope.$watch(
        'start_date',
        function () {
          $scope.endOptions.minDate = $scope.start_date
          if ($scope.end_date < $scope.start_date) {
            $scope.end_date = null
          }
        },
        true,
      )

      $scope.openStart = function () {
        $scope.popup.startPopup = true
      }

      $scope.openEnd = function () {
        $scope.popup.endPopup = true
      }

      function formatDate(date) {
        if (!date) return null
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      $scope.goBack = function () {
        $state.go('app.forms.reports', {
          previousSearch: $scope.searchExecuted ? $scope.search : null,
          previousOrder: $scope.order,
          previousReverse: $scope.reverse,
          previousPageNumber: $scope.pageNumber,
        })
      }
    },
  )
