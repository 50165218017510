angular.module('ccs').directive('dndNodragInput', dndNodragInput)

/* https://github.com/marceljuenemann/angular-drag-and-drop-lists/issues/242 */
function dndNodragInput() {
  return function (scope, element) {
    element.on('focus', function () {
      element.attr('draggable', 'false')
      element.parents('[draggable="true"]').attr('draggable', 'false')
    })

    element.on('blur', function () {
      element.attr('draggable', 'true')
      element.parents('[draggable="false"]').attr('draggable', 'true')
    })
  }
}
