import { EnvolopeIcon } from '@/react/componentAssets/EnvelopeIcon'
import { useAngularServices } from '@/react/components'
import { ConfirmationEmailModal } from '@/react/components/ConfirmationEmailModal'
import { checkIsSafari } from '@/utils/checkIsSafari'
import { css } from '@emotion/react'
import classNames from 'classnames'
import moment from 'moment'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const DraftRow = ({ assessment, search, index }) => {
  const [hoveredField, setHoveredField] = useState(null)
  const [isModalVisable, setModalVisable] = useState(false)
  const { CurrentUser, Api } = useAngularServices()

  const { external_contact, template_name, date_created, id } = assessment
  const { email, full_name, company_name } = external_contact

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const handleLinkResend = async () => {
    await Api.get(`assessment/assessments/${id}/resend_draft_email`, {})

    setModalVisable(false)
  }

  const isSafari = checkIsSafari()
  const styles = getStyles(isSafari)

  return (
    <tr
      className={classNames('table__row', {
        dark: index % 2 === 0,
      })}
      css={styles}
    >
      <td
        className="assessment__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'template',
            text: assessment?.template_name,
            max__length: 24,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={template_name}
        />
      </td>
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name',
            text: full_name,
            max__length: 19,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={full_name}
        />
      </td>
      <td
        className="email__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'email',
            text: email,
            max__length: 24,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={email}
        />
      </td>
      <td
        className="company__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'company_name',
            text: company_name,
            max__length: 22,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={company_name}
        />
      </td>
      <td className="centered__cell">
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={moment(date_created).format('MM/DD/YYYY')}
        />
      </td>
      <td
        className="envelope__cell"
        onClick={() => {
          setModalVisable(true)
        }}
      >
        <EnvolopeIcon color={variableColor} />
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
      {isModalVisable ? (
        <ConfirmationEmailModal
          email={email}
          handleLinkResend={handleLinkResend}
          handleClose={() => {
            setModalVisable(false)
          }}
        />
      ) : null}
    </tr>
  )
}

const getStyles = (isSafari) =>
  css({
    '@media(max-width: 1190px)': {
      '.modal__wrapper': {
        right: isSafari ? '15px' : '5px',
      },
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      marginTop: '30px',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '500px',
    },
    '.template': {
      left: 0,
    },
    '.name': {
      left: '180px',
    },
    '.email': {
      left: '320px',
    },
    '.company_name': {
      left: '500px',
    },
  })
