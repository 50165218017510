export const nonAdminRedirects = (CurrentUser, stateService: any, app) => {
  const role = CurrentUser.getRole()
  switch (role) {
    case 'trainee':
      stateService.go('trainings.documentation', {})
      break
    case 'client_user':
      stateService.go('app.company_news.list', { app: app })
      break
    case 'read_only':
      stateService.go('trainings.program', {})
      break
    case 'supervisor':
      stateService.go('app.company_news.list', { app: app })
      break
    case 'trainee_manager':
      stateService.go('trainings.documentation', {})
      break
  }
}
