import { validateFileName } from '@/utils/fileValidation'

angular
  .module('ccs')
  .controller(
    'FileCabinetFileCtrl',
    function (
      $scope,
      $rootScope,
      Api,
      cabinet,
      file,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('FileCabinetFileCtrl')

      //initialize myDatePicker
      if (file) {
        if (file.expiration_date) {
          //get it into a format that will work with the date picker
          $scope.myDatePicker = new Date(file.expiration_date)
        } else {
          $scope.myDatePicker = null
        }
      }

      var editing = !!file
      var fileChanged = false
      var pdfDetected = 0
      $scope.cabinet = cabinet

      $scope.file = file || { client: CurrentUser.getClientId() }
      var isClickable = true
      $scope.isClickable

      $scope.dzConfig = {
        parallelUploads: 1,
        acceptedFiles: 'application/pdf',
        url: '#',
        maxFiles: 1,
        maxFilesize: 100,
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        init: function () {
          //if we are editing, we need to restore the existing file as a MockFile
          if (editing === true) {
            $scope.$apply(function () {
              $scope.isClickable = isClickable
            })

            //get the filename to render it on the DropZone preview.
            var filename_only = $scope.file.file.file.split(/[/]+/).pop()
            var dz = this

            //the size isn't in the database, set it arbitrarily then remove it from the DOM later.
            var mockFile = { name: filename_only, size: 1337 }

            dz.emit('addedfile', mockFile)
            dz.files.push(mockFile)
            dz.emit('complete', mockFile)

            //Remove the size information, since it is not stored on the server
            var fileSizeElement =
              mockFile.previewElement.querySelector('.dz-size')
            fileSizeElement.parentNode.removeChild(fileSizeElement)
          } else {
            //do nothing
          }

          //For the backfilled mockfiles
          //https://github.com/enyo/dropzone/issues/279
          this.on('addedfile', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
        },
      }

      $scope.dzAddedFile = function (file) {
        var isPdf = file.type === 'application/pdf'
        var errors = validateFileName(
          file.name.substring(0, file.name.lastIndexOf('.')),
        )
        if (isPdf && errors === false) {
          $scope.file.file = file
          $scope.file.title = $scope.file.title
            ? $scope.file.title
            : file.name.substring(0, file.name.lastIndexOf('.')) || file.name
          pdfDetected = 1
          fileChanged = true
          isClickable = true
        } else {
          pdfDetected = -1
          fileChanged = false
          isClickable = false
          document.querySelectorAll('.dz-error-mark')[0].style.opacity = '1'
          var errorDisplay = document.querySelectorAll('.dz-error-message')[0]
          errorDisplay.style.opacity = '1'
          if (!isPdf) errors.push('PDF documents only please.')
          $(errorDisplay).text(errors.join('\n'))
        }

        $scope.$apply(function () {
          $scope.isClickable = isClickable
        })
      }

      $scope.dzRemovedFile = function (file) {
        fileChanged = !fileChanged
        pdfDetected -= 1

        if (this.files.length == 0) {
          isClickable = false
        } else {
          isClickable = true
        }

        $scope.$apply(function () {
          $scope.isClickable = isClickable
        })
      }

      function getAllProjects(cb, projects, page) {
        projects = projects || []
        page = page || 1

        Api.Projects.get(
          {
            client: CurrentUser.getClientId(),
            is_active: 'True',
            page_size: 250,
            page,
          },
          (resp) => {
            projects = projects.concat(resp.results)
            if (resp.next) return getAllProjects(cb, projects, ++page)
            cb(projects)
          },
        )
      }

      if (cabinet.has_project) {
        getAllProjects(function (projects) {
          $scope.projects = projects
        })
      }

      function update(data) {
        $('.dz-progress').css('display', 'block')
        Api.CabinetFiles.patch(data, function (resp) {
          if (resp) {
            $scope.isClickable = false
            Notification.success('File successfully updated')
            $('.dz-progress').css('display', 'none')
            $scope.closeThisDialog()
            $rootScope.$broadcast('updateCabinetsBroadcast', resp)
          } else {
            $scope.isClickable = true
            Notification.warning(
              'Unable to upload file. Please try again later.',
            )
            $('.dz-progress').css('display', 'none')
          }
        })
      }

      function create(data, newFileData) {
        $('.dz-progress').css('display', 'block')
        Api.CabinetFiles.post(data, function (resp) {
          if (resp) {
            $scope.isClickable = false
            resp.file = newFileData
            cabinet.data.items.push(resp)
            Notification.success('File successfully uploaded')
            $('.dz-progress').css('display', 'none')
            $scope.closeThisDialog()
          } else {
            $scope.isClickable = true
            Notification.warning(
              'Unable to upload file. Please try again later.',
            )
            $('.dz-progress').css('display', 'none')
          }
        })
      }

      //$scope.addFile is the submit action on the form.
      $scope.addFile = function () {
        $scope.isClickable = false
        $('.dz-progress').css('display', 'block')
        //preprocess the date picker to convert it to a date storable by the API
        if ($scope.myDatePicker) {
          //put it in a string that can be stored in the database
          var expd =
            ('0' + ($scope.myDatePicker.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + $scope.myDatePicker.getDate()).slice(-2) +
            '/' +
            $scope.myDatePicker.getFullYear()
          if (file) {
            //update the file date on the page behind the popup if we
            //actually update it.
            file.expiration_date = expd
          }
        } else {
          var expd = null
        }

        var updateData = {
          cabinet: cabinet.id,
          title: $scope.file.title,
          person: $scope.file.person || '',
          contractor: $scope.file.contractor,
          project: $scope.file.project ? $scope.file.project.id : null,
          expiration_date: expd,
          client: CurrentUser.getClientId(),
        }

        if (editing) updateData.id = $scope.file.id
        var fileTitleErrors = validateFileName($scope.file.title)

        if (fileChanged && pdfDetected >= 0 && fileTitleErrors === false) {
          Api.uploadFile($scope.file.file, {}, function (data) {
            updateData.file = data.id
            if (editing) {
              $scope.file.file = data
              return update({ ...updateData, project: updateData.project })
            }

            return create(updateData, data)
          })
        } else {
          if (editing) {
            return update({ ...updateData, project: updateData.project })
          }

          if (fileTitleErrors.length > 0) {
            $scope.isClickable = true
            $('.dz-progress').css('display', 'none')
            Notification.danger(fileTitleErrors.join('\n'))
          } else Notification.danger('No PDF Attached.')
        }
      }

      //Begin stuff for date picker//
      $scope.endPopup = {
        opened: false,
      }

      $scope.startOptions = {
        formatYear: 'yy',
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.openEnd = function () {
        $scope.endPopup.opened = true
      }
      //End stuff for date picker//
    },
  )
