import { objToStringParams } from '../../../utils/paramUtilities'

angular
  .module('ccs')
  .controller(
    'ProjectsListCtrl',
    function (
      $scope,
      Api,
      $stateParams,
      $state,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('ProjectsListCtrl')

      $scope.getClientSettings = CurrentUser.getClientSettings

      $scope.projects = []
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageNumber * $scope.pageSize }
      $scope.reverse = true //for default 'Newest to Oldest' order

      $scope.tableHeaders = [
        {
          key: 'name',
          display: CurrentUser.getClientSettings().project_language + ' Name',
          sortable: true,
        },
        { key: 'number', display: 'Number', sortable: true },
        { key: 'edit', display: 'Edit', centered: true },
        {
          key: 'is_active',
          display: 'Inactive/Active',
          sortable: true,
          centered: true,
        },
        { key: 'client_name', display: 'Client Name', sortable: true },
        { key: 'users', display: 'Users', centered: true },
        {
          key: 'd',
          display: 'Delete ' + CurrentUser.getClientSettings().project_language,
          centered: true,
        },
      ]

      $scope.totalUsers = 0
      $scope.userIs = CurrentUser.is
      $scope.isClientOrAdmin = CurrentUser.isClientOrAdmin

      Api.Users.get(null, function (resp) {
        $scope.totalUsers = resp.count
      })

      function fetchAll(params) {
        Api.Projects.get(objToStringParams(params) || '', function (response) {
          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })

        $state.transitionTo(
          'admin.projects.list',
          {
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      function query() {
        return {
          page: $scope.pageNumber,
          order: $scope.reverse ? $scope.order : '-' + $scope.order,
          search: $scope.searchExecuted ? $scope.search : null,
        }
      }

      fetchAll(query())

      $scope.deleteProject = function (project) {
        Api.Projects.patch(
          { id: project.id, deleted: true, is_active: false },
          function () {
            fetchAll(query())
          },
        )
      }

      $scope.getSorted = function () {
        fetchAll(query())
      }

      $scope.changePage = function () {
        $scope.getSorted()
      }

      $scope.toggleActive = function (lesson) {
        Api.Projects.patch(
          { id: lesson.id, is_active: lesson.is_active },
          function (resp) {
            if (!resp) {
              Notification.danger(
                'Unable to toggle Lesson. Please try again later.',
              )
              lesson.is_active = !lesson.is_active
            }
          },
        )
      }

      $scope.uploadBulkProjects = function () {
        $state.go(
          'admin.projects.bulk_upload',
          {
            previousPageNumber: $scope.pageNumber,
            previousOrder: $scope.order,
            previousReverse: $scope.reverse,
          },
          { reload: true },
        )
      }
    },
  )
