import * as React from 'react'
import {
  TableRow,
  useAngularServices,
  getFilterParam,
  getHoursAndMinutes,
} from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { encodeString } from '@/utils/additionalEncodeLogic'

export const CurrentPageTableBody = ({
  page,
  search,
  order,
  setCount,
  advancedFilters,
}) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const [list, setList] = React.useState<object[][]>([])

  async function fetchData() {
    const listRenderSettings: object[][] = []
    const params = {
      page: page,
      order: order,
      current: true,
      application: stateService.params.app,
    }
    if (search) params.search = search

    let stringParams = ''

    Object.entries(params).forEach((param) => {
      stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
    })

    Object.entries(advancedFilters).forEach((param) => {
      if (param[1].type && param[1].string) {
        stringParams +=
          getFilterParam(
            param[0],
            param[1].type,
            param[1].string,
            param[1].secondType,
            param[1].secondString,
          ) + '&'
      }
    })

    const [{ data }] = await Promise.all([
      Api.get('timecards', stringParams.slice(0, -1)),
    ])
    data.results.forEach((timeCard, idx: number) => {
      listRenderSettings.push([
        {
          object: (
            <div css={css({ textAlign: 'left' })}>
              {timeCard.supervisor.first_name} {timeCard.supervisor.last_name}
            </div>
          ),
          width: '20%',
        },
        {
          object: (
            <div css={css({ textAlign: 'left' })}>
              {timeCard.user.first_name} {timeCard.user.last_name}
            </div>
          ),
          width: '20%',
        },
        {
          object: (
            <div css={css({ textAlign: 'left' })}>{timeCard.status_value}</div>
          ),
          width: '20%',
        },
        {
          object: (
            <div css={css({ textAlign: 'left' })}>
              {timeCard.is_meal_missed ? 'Yes' : null}
            </div>
          ),
          width: '25%',
        },
        {
          object: (
            <div css={css({ textAlign: 'left' })}>
              {typeof timeCard.hours !== 'string'
                ? getHoursAndMinutes(timeCard.hours)
                : timeCard.hours}
            </div>
          ),
          width: '15%',
        },
      ])
    })
    setCount(data.count)
    setList(listRenderSettings)
  }

  React.useEffect(() => {
    fetchData()
  }, [page, search, order, advancedFilters])

  if (!list.length) return null

  return (
    <tbody>
      {list.map((timeCard, idx) => {
        return (
          <TableRow
            key={idx}
            columns={timeCard}
            additionalStyles={css({ td: { paddingLeft: 5, height: 32 } })}
          />
        )
      })}
    </tbody>
  )
}
