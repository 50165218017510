import * as React from 'react'
import { css } from '@emotion/react'

import { default as ReactSelect } from 'react-select'
import { TextInputStyle } from '@screens/components'

import { updateQueryParams } from '@/utils/updateQueryParams'
import {
  ButtonElement,
  dropdownFiledTheme,
  dropdownOptionStyles,
} from '@components/index'
import { useAngularServices } from '@components/ReactAngularContext'
import { FilterIcon } from '@/react/componentAssets'
import { encodeString } from '@/utils/additionalEncodeLogic'

const optionsGet = {
  contains: { value: 'contains', label: 'Contains' },
  notContains: { value: 'notContains', label: 'Not Contains' },
  equals: { value: 'equals', label: 'Equals' },
  notEquals: { value: 'notEquals', label: 'Not Equals' },
  startsWith: { value: 'startsWith', label: 'Starts With' },
  blank: { value: 'blank', label: 'Blank' },
  notBlank: { value: 'notBlank', label: 'Not Blank' },
}

const options = [
  { value: 'contains', label: 'Contains' },
  { value: 'notContains', label: 'Not Contains' },
  { value: 'equals', label: 'Equals' },
  { value: 'notEquals', label: 'Not Equals' },
  { value: 'startsWith', label: 'Starts With' },
  // { value: 'blank', label: 'Blank' },
  // { value: 'notBlank', label: 'Not Blank' },
]

type Props = {
  fieldName: string
  updateUrl?: boolean
  additionalFunction?: () => void
}

export const AdvancedFilter: React.FC<Props> = ({
  fieldName,
  advancedFilters,
  setAdvancedFilters,
  updateUrl = true,
  additionalFunction = () => {},
}: Props) => {
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [visibleAlert, setVisibleAlert] = React.useState<boolean>(false)
  const [filterType, setFilterType] = React.useState(
    optionsGet[advancedFilters[fieldName]?.type],
  )
  const [filterString, setFilterString] = React.useState(
    advancedFilters[fieldName]?.string,
  )
  const [secondFilterType, setSecondFilterType] = React.useState(
    optionsGet[advancedFilters[fieldName]?.secondType],
  )
  const [secondFilterString, setSecondFilterString] = React.useState(
    advancedFilters[fieldName]?.secondString,
  )
  const [stringOneChanged, setStringOneChanged] = React.useState(false)
  const [stringTwoChanged, setStringTwoChanged] = React.useState(false)
  const [dropdownOneChanged, setDropdownOneChanged] = React.useState(false)
  const [dropdownTwoChanged, setDropdownTwoChanged] = React.useState(false)

  function onUnfocusedLogic(
    fieldName,
    filterType,
    filterString,
    secondFilterType,
    secondFilterString,
  ) {
    if (!filterType) return
    if (
      !(
        dropdownOneChanged ||
        stringOneChanged ||
        dropdownTwoChanged ||
        stringTwoChanged
      )
    )
      return

    const filterSettings = {
      type: filterType.value,
      string: filterString,
      secondType:
        filterString && filterType && secondFilterType && secondFilterString
          ? secondFilterType.value
          : '',
      secondString:
        filterString && filterType && secondFilterType && secondFilterString
          ? secondFilterString
          : '',
    }
    if (!(secondFilterType && secondFilterString)) {
      setSecondFilterString('')
      setSecondFilterType(null)
    }

    const queryParams = {
      pageNumber: 1,
    }
    const newAdvancedFiltersState = { ...advancedFilters }
    newAdvancedFiltersState[fieldName] = filterSettings

    queryParams[fieldName] = JSON.stringify(filterSettings)
    if (updateUrl) updateQueryParams(queryParams, true)
    setAdvancedFilters(newAdvancedFiltersState)
  }

  function clear(fieldName) {
    const filterSettings = {
      type: null,
      string: '',
      secondType: null,
      secondString: '',
    }
    setFilterString('')
    setFilterType(null)
    setSecondFilterString('')
    setSecondFilterType(null)

    const queryParams = {
      pageNumber: 1,
    }
    const newAdvancedFiltersState = { ...advancedFilters }
    newAdvancedFiltersState[fieldName] = filterSettings

    queryParams[fieldName] = JSON.stringify(filterSettings)
    if (updateUrl) updateQueryParams(queryParams, true)
    setAdvancedFilters(newAdvancedFiltersState)
  }

  const inputRef = React.useRef()
  const secondInputRef = React.useRef()
  const secondSelectRef = React.useRef()

  window.addEventListener('scroll', () => {
    if (visibleAlert) {
      setFilterType(optionsGet[advancedFilters[fieldName]?.type])
      setSecondFilterType(optionsGet[advancedFilters[fieldName]?.secondType])
      setFilterString(advancedFilters[fieldName]?.string)
      setSecondFilterString(advancedFilters[fieldName]?.secondString)
      setStringOneChanged(false)
      setStringTwoChanged(false)
      setDropdownOneChanged(false)
      setDropdownTwoChanged(false)
      setVisibleAlert(false)
    }
  })

  return (
    <div css={baseStyle}>
      <div
        className="alert-button-opening"
        onClick={() => {
          setVisibleAlert(true)
        }}
      >
        {!advancedFilters[fieldName]?.string ? (
          [
            <div key="1-line" className="line" />,
            <div key="2-line" className="line" />,
            <div key="3-line" className="line" />,
          ]
        ) : (
          <div className="filtered-icon">
            <FilterIcon height={'16px'} color={variableColor} />
          </div>
        )}
      </div>
      {visibleAlert ? (
        <div
          className="alert-background"
          onClick={() => {
            onUnfocusedLogic(
              fieldName,
              filterType,
              filterString,
              secondFilterType,
              secondFilterString,
            )
            setVisibleAlert(false)
          }}
        />
      ) : null}
      {visibleAlert ? (
        <div className="alert-holder">
          <div className="alert-block">
            <ReactSelect
              defaultValue={
                optionsGet[advancedFilters[fieldName]?.type] || filterType
              }
              options={options}
              onChange={(value) => {
                setFilterType(value)
                setDropdownOneChanged(
                  value !== advancedFilters[fieldName]?.type,
                )
              }}
              styles={dropdownOptionStyles(variableColor)}
              theme={(theme) => dropdownFiledTheme(theme, variableColor)}
            />
            <input
              ref={inputRef}
              defaultValue={advancedFilters[fieldName]?.string || filterString}
              type="text"
              onChange={(value) => {
                setFilterString(value.target.value)
                setStringOneChanged(
                  value !== advancedFilters[fieldName]?.string,
                )
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  additionalFunction()
                  onUnfocusedLogic(
                    fieldName,
                    filterType,
                    filterString,
                    secondFilterType,
                    secondFilterString,
                  )
                  setVisibleAlert(false)
                }
              }}
            />
            {filterType && filterString
              ? [
                  <div key="checkbox-row" className="checkbox-row">
                    <label>OR</label>
                  </div>,
                  <ReactSelect
                    key="second-select"
                    ref={secondSelectRef}
                    defaultValue={
                      secondFilterType ||
                      optionsGet[advancedFilters[fieldName]?.secondType]
                    }
                    options={options}
                    onChange={(value) => {
                      setSecondFilterType(value)
                      setDropdownTwoChanged(
                        value !== advancedFilters[fieldName]?.secondType,
                      )
                    }}
                    styles={dropdownOptionStyles(variableColor)}
                    theme={(theme) => dropdownFiledTheme(theme, variableColor)}
                  />,
                  <input
                    key="second-input"
                    ref={secondInputRef}
                    defaultValue={
                      secondFilterString ||
                      advancedFilters[fieldName]?.secondString
                    }
                    type="text"
                    onChange={(value) => {
                      setSecondFilterString(value.target.value)
                      setStringTwoChanged(
                        value !== advancedFilters[fieldName]?.secondString,
                      )
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        additionalFunction()
                        onUnfocusedLogic(
                          fieldName,
                          filterType,
                          filterString,
                          secondFilterType,
                          secondFilterString,
                        )
                        setVisibleAlert(false)
                      }
                    }}
                  />,
                ]
              : null}

            <div className="button-row">
              <ButtonElement
                text={'Clear'}
                functionToTrigger={() => {
                  additionalFunction()
                  clear(fieldName)
                  setVisibleAlert(false)
                }}
                width={'50%'}
                fontSize={'15px'}
                additionalStyles={css({ padding: 0 })}
              />
              <ButtonElement
                text={'Apply'}
                functionToTrigger={() => {
                  additionalFunction()
                  onUnfocusedLogic(
                    fieldName,
                    filterType,
                    filterString,
                    secondFilterType,
                    secondFilterString,
                  )
                  setVisibleAlert(false)
                }}
                buttonType={'submit'}
                width={'50%'}
                fontSize={'15px'}
                additionalStyles={css({ padding: 0 })}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  )
}

const baseStyle = css({
  '.alert-button-opening': {
    paddingTop: 1,
    marginLeft: 10,
    cursor: 'pointer',
    '.line': {
      width: 16,
      transition: '500ms',
    },
    '.filtered-icon': {
      width: 16,
      img: {
        width: 16,
      },
    },
  },
  '.alert-holder': {
    width: 0,
    height: 0,
    position: 'relative',
    '.alert-block': {
      textAlign: 'left',
      position: 'absolute',
      padding: 10,
      boxShadow: '0 0 1px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 20%)',
      right: -70,
      width: 170,
      backgroundColor: 'white',
      zIndex: 1500,
      input: {
        width: '100%',
        ...TextInputStyle,
        marginTop: 10,
      },
      '.checkbox-row': {
        display: 'flex',
        paddingTop: 10,
        paddingBottom: 10,
        label: {
          marginBottom: 0,
        },
      },
    },
  },
  '.alert-background': {
    left: 0,
    top: 0,
    position: 'absolute',
    width: '100vw',
    height: '100vh',
    zIndex: 1000,
  },
  '.button-row': {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-between',
  },
})

export function getFilterParam(
  fieldName: string,
  filterType: string,
  filterString: string,
  filterTypeTwo: string,
  filterStringTwo: string,
) {
  let filterStringToReturn = ''
  if (!fieldName) return filterStringToReturn
  switch (filterType) {
    case 'contains':
      filterStringToReturn =
        'ilike(' + fieldName + ',"*' + encodeString(filterString) + '*")'
      break
    case 'notContains':
      filterStringToReturn =
        'not(ilike(' + fieldName + ',"*' + encodeString(filterString) + '*"))'
      break
    case 'equals':
      filterStringToReturn =
        'ilike(' + fieldName + ',"' + encodeString(filterString) + '")'
      break
    case 'notEquals':
      filterStringToReturn =
        'not(ilike(' + fieldName + ',"*' + encodeString(filterString) + '*"))'
      break
    case 'startsWith':
      filterStringToReturn =
        'ilike(' + fieldName + ',"' + encodeString(filterString) + '*")'
      break
    case 'blank':
      filterStringToReturn = 'ilike(' + fieldName + ',empty())'
      break
    case 'notBlank':
      filterStringToReturn = 'not(ilike(' + fieldName + ',empty()))'
      break
  }
  if (filterTypeTwo && filterStringTwo) {
    switch (filterTypeTwo) {
      case 'contains':
        filterStringToReturn =
          'or(' +
          filterStringToReturn +
          ',ilike(' +
          fieldName +
          ',"*' +
          encodeString(filterStringTwo) +
          '*"))'
        break
      case 'notContains':
        filterStringToReturn =
          'or(' +
          filterStringToReturn +
          ',not(ilike(' +
          fieldName +
          ',"*' +
          encodeString(filterStringTwo) +
          '*")))'
        break
      case 'equals':
        filterStringToReturn =
          'or(' +
          filterStringToReturn +
          ',ilike(' +
          fieldName +
          ',"' +
          encodeString(filterStringTwo) +
          '"))'
        break
      case 'notEquals':
        filterStringToReturn =
          'or(' +
          filterStringToReturn +
          ',not(ilike(' +
          fieldName +
          ',"*' +
          encodeString(filterStringTwo) +
          '*")))'
        break
      case 'startsWith':
        filterStringToReturn =
          'or(' +
          filterStringToReturn +
          ',ilike(' +
          fieldName +
          ',"' +
          encodeString(filterStringTwo) +
          '*"))'
        break
      case 'blank':
        filterStringToReturn =
          'or(' + filterStringToReturn + ',ilike(' + fieldName + ',empty()))'
        break
      case 'notBlank':
        filterStringToReturn =
          'or(' +
          filterStringToReturn +
          ',not(ilike(' +
          fieldName +
          ',empty())))'
        break
    }
  }
  return filterStringToReturn
}
