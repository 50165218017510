angular
  .module('ccs')
  .controller(
    'CompanyNewsListCtrl',
    function (
      $scope,
      $stateParams,
      Api,
      app,
      $state,
      uiPermissionService,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('CompanyNewsListCtrl')

      $scope.app = app

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'start_date'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.reverse = true //for default 'Newest to Oldest' order

      //pageSize Used in the view
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageNumber * $scope.pageSize }

      $scope.showAddNews = uiPermissionService.getShowAddNews()
      $scope.showDeleteNews = uiPermissionService.getShowDeleteNews()
      $scope.showEditNews = uiPermissionService.getShowEditNews()
      $scope.showHideNews = uiPermissionService.getShowHideNews()

      function fetchAll(params) {
        Api.CompanyNews.get(params || '', function (response) {
          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })
      }

      $scope.tableHeaders = [
        { key: 'title', display: 'Topic Title', sortable: true },
        { key: 'description', display: 'Description', sortable: true },
        { key: 'start_date', display: 'Start Date', sortable: true },
        { key: 'end_date', display: 'End Date', sortable: true },
        { key: 'projects', display: app.projects },
        {
          key: 'edit',
          display: 'Edit',
          edit: true,
          centered: true,
          hidden: !$scope.showEditNews,
        },
        {
          key: 'remove',
          display: 'Delete',
          edit: true,
          centered: true,
          hidden: !$scope.showDeleteNews,
        },
        { key: 'share', display: 'Share', centered: true },
        {
          key: 'is_active',
          display: 'Show/Hide',
          edit: true,
          hidden: !$scope.showHideNews,
          sortable: true,
        },
      ]

      Api.Projects.get({ is_active: 'True' }, function (resp) {
        $scope.projectCount = resp.count
      })

      function getParams() {
        let q = {
          client: CurrentUser.getClientId(),
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.searchExecuted ? $scope.search : null,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      fetchAll(getParams())

      $scope.getSorted = function () {
        fetchAll(getParams())
      }

      $scope.changePage = function () {
        $scope.getSorted()

        $state.transitionTo(
          'app.company_news.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.toggleActive = function (news) {
        var projids = news.projects.map((item) => {
          return item.id
        })
        Api.CompanyNews.patch(
          {
            client: news.client.id,
            id: news.id,
            is_active: news.is_active,
            projects: projids,
          },
          function (resp) {
            if (!resp) {
              Notification.danger(
                'Unable to toggle News item. Please try again later.',
              )
              news.is_active = !news.is_active
            }
          },
        )
      }

      $scope.delete = (topic) => {
        Api.CompanyNews.patch(
          {
            id: topic.id,
            deleted: true,
            is_active: false,
            projects: topic.projects.map((p) => p.id),
          },
          (resp) => {
            $scope.changePage()
          },
        )
      }
    },
  )
