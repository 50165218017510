import * as React from 'react'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'

import { QuestionsList, MapWithMarkers } from './templateParts'
import {
  BottomObservationNavigation,
  Signatures,
  TableSummary,
  TableTemplateHeader,
} from '../components'
import { flatMap } from 'lodash'

export function TemplateBaseFour({
  baseStyle,
  baseObservationData,
  processedObservationData,
  app,
  viewNaQuestions,
}: any) {
  const { stateService } = useRouter()

  const list = flatMap({
    ...processedObservationData.no,
    ...processedObservationData.pr,
    ...processedObservationData.pa,
    ...processedObservationData.cls,
    ...processedObservationData.yes,
    ...processedObservationData['n/a'],
  })

  const lists = {
    no: Object.values(processedObservationData.no),
    pr: Object.values(processedObservationData.pr),
    pa: Object.values(processedObservationData.pa),
    cls: Object.values(processedObservationData.cls),
    yes: Object.values(processedObservationData.yes),
    'n/a': Object.values(processedObservationData['n/a']),
  }

  return (
    <section css={baseStyle}>
      <div id="to__print">
        {baseObservationData ? (
          <TableTemplateHeader headerData={baseObservationData} app={app} />
        ) : null}
        {baseObservationData && processedObservationData ? (
          <TableSummary
            headerData={baseObservationData}
            lists={lists}
            app={app}
          />
        ) : null}
        <div css={notForPrint}>
          <MapWithMarkers list={list} viewNaQuestions={viewNaQuestions} />
        </div>
        <div css={forPrint}>
          <MapWithMarkers
            list={list}
            viewNaQuestions={viewNaQuestions}
            width={577}
            height={175}
          />
        </div>
        {processedObservationData ? (
          <QuestionsList
            list={list}
            app={app}
            theme={baseObservationData.client.report_settings.theme}
            viewNaQuestions={viewNaQuestions}
          />
        ) : null}
        {baseObservationData.signatures.length > 0 ? (
          <Signatures signatures={baseObservationData.signatures} />
        ) : null}
        <div className="disclaimer">
          {baseObservationData.client.general_settings.disclaimer}
        </div>
      </div>
      {stateService.params.key ? null : (
        <BottomObservationNavigation
          deleted={baseObservationData.deleted}
          observationData={baseObservationData}
        />
      )}
    </section>
  )
}

const forPrint = css({
  height: 0,
  visibility: 'hidden',
  '@media print': {
    height: 'auto',
    visibility: 'visible',
  },
})
const notForPrint = css({
  display: 'block',
  '@media print': {
    display: 'none',
  },
})
