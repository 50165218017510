angular
  .module('ccs')
  .controller(
    'CategoryAssignProjectsCtrl',
    function ($scope, $stateParams, Api, app, $state, CurrentUser, $log) {
      $log.debug('CategoryAssignProjectsCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.data = { items: [], total: 0 }
      $scope.breadcrumbs = [{ title: app.name }]
      $scope.pageNumber = 1
      $scope.reverse = false
      $scope.order = 'name'
      $scope.app = app

      const categoryId = parseInt($stateParams.category, 10)
      Api.Categories.byID(categoryId, (resp) => {
        $scope.category = resp
      })

      $scope.changePage = () => {
        getProjects()
      }

      $scope.tableHeaders = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'active_for_category',
          display: 'Inactive/Active',
          sortable: true,
          toggleAllBtn: true
        },
      ]

      $scope.toggleAll = () => {
        Api.post(
          `categories/${categoryId}/assign_all_projects`,
          {
            id: categoryId,
            application: app.id,
            on: $scope.active,
            search: $scope.search,
            assigned: CurrentUser.is('supervisor'),
          },
          (resp) => {
            getProjects()
          },
        )
      }

      $scope.toggleActive = (project) => {
        if (project.active_for_category) {
          project.appProject.categories.push(categoryId)
        } else {
          project.appProject.categories = project.appProject.categories.filter(
            (c) => c != categoryId,
          )
        }

        if (project.appProject.id) {
          Api.AppProjects.patch(
            {
              id: project.appProject.id,
              categories: project.appProject.categories,
            },
            (resp) => {},
          )
        } else {
          Api.AppProjects.post(
            {
              app: app.id,
              project: project.id,
            },
            (resp) => {
              Api.AppProjects.patch(
                { id: resp.id, categories: project.appProject.categories },
                (resp) => {},
              )
            },
          )
        }
      }

      function projectQuery() {
        let query = {
          application: app.id,
          client: CurrentUser.getClientId(),
          search_fields: 'name',
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_for_category: categoryId,
          assigned: CurrentUser.is('supervisor'),
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return query
      }

      function appProjectQuery(projects) {
        return {
          projects: projects.map((p) => p.id).join(),
          app: app.id,
          only_categories: true,
        }
      }

      function getProjects() {
        Api.Projects.get(projectQuery(), function (resp) {
          $scope.data.total = resp.count
          const projects = resp.results

          Api.AppProjects.get(appProjectQuery(projects), function (resp) {
            const appProjects = resp.results

            $scope.data.items = projects.map(function (project) {
              project.active_for_category = !!appProjects.filter(
                (appProject) => {
                  return (
                    appProject.project.id == project.id &&
                    appProject.categories.indexOf(categoryId) > -1
                  )
                },
              ).length

              if (
                appProjects.filter((ap) => ap.project.id == project.id).length
              ) {
                project.appProject = appProjects.filter(
                  (ap) => ap.project.id == project.id,
                )[0]
              } else {
                project.appProject = { categories: [] }
              }

              return project
            })
          })
        })
      }

      $scope.changePage()

      $scope.goBack = () => {
        $state.go('app.categories.list', {
          app: app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }
    },
  )
