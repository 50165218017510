import { useAngularServices } from '@/react/components'
import StringUtil from '@/utils/autolinks'
import { css } from '@emotion/react'

type Props = {
  title: string
  text: string
}

export const InfoBlock = ({ title, text }: Props) => {
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const baseStyle = getBaseStyle(variableColor)

  return (
    <div className="info__block" css={baseStyle}>
      <h3 className="info__title">{title}</h3>
      <p
        className="info__text"
        dangerouslySetInnerHTML={{
          __html: StringUtil.parseHyperlinks(text),
        }}
      />
    </div>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    paddingInline: '26px',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid #EEEFF3',
    '.info__title': {
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '17px',
      color: variableColor,
      margin: 0,
    },
    '.info__text': {
      fontSize: '12px',
      lineHeight: '17px',
      color: '#7F8183',
      margin: 0,
      whiteSpace: 'pre-wrap',
    },
  })
