import * as React from 'react'
import classNames from 'classnames'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'

export type SortMode = 'name' | '-name' | 'is_active' | '-is_active'

type Props = {
  projectName: string
  sortMode: SortMode
  setSortMode: (newValue: SortMode) => void
  allProjectsAreToggled: boolean
  onUntogglePageClick: void
  onTogglePageClick: void
  onUntoggleAllClick: void
  onToggleAllClick: void
  currentPageProjectsAreToggled: boolean
  search: string
}
export const SortModeHeader = ({
  projectName,
  sortMode,
  setSortMode,
  onUntogglePageClick,
  onTogglePageClick,
  onUntoggleAllClick,
  currentPageProjectsAreToggled,
  allProjectsAreToggled,
  onToggleAllClick,
  search,
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const onNameClick = () => {
    if (sortMode === 'name') setSortMode('-name')
    else setSortMode('name')
  }

  const onToggleClick = () => {
    if (sortMode === 'is_active') setSortMode('-is_active')
    else setSortMode('is_active')
  }

  const arrowDirection = sortMode.startsWith('-')
    ? 'fa-angle-up'
    : 'fa-angle-down'

  const arrowStyle = css({
    color: variableColor,
  })

  const arrowEl = (
    <span
      css={arrowStyle}
      className={classNames('header-sort-arrow', 'fa', arrowDirection)}
    />
  )

  const toggleBtn = css({
    position: 'absolute',
    top: '-30px',
    '.toggle-button': {
      backgroundColor: '#fff',
      color: variableColor,
      border: `1px solid ${variableColor}`,
      minWidth: '75px',
      width: 'fit-content',
      height: '28px',
      padding: '0 25px',
      fontSize: '12px',
      boxSizing: 'border-box',
      fontWeight: 400,
      fontFamily: "'Open Sans', Arial, sans-serif",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

  return (
    <thead>
      <tr>
        <th onClick={onNameClick}>
          {projectName} Name {['name', '-name'].includes(sortMode) && arrowEl}
        </th>
        <th className="toggle-header">
          <span onClick={onToggleClick}>Inactive/Active</span>{' '}
          {['is_active', '-is_active'].includes(sortMode) && arrowEl}
          <div className="toggle-btn-pos" css={toggleBtn}>
            {search ? null : allProjectsAreToggled ? (
              <button
                secondary-btn
                className="button toggle-button"
                onClick={onUntoggleAllClick}
              >
                Untoggle All
              </button>
            ) : (
              <button
                secondary-btn
                className="button toggle-button"
                onClick={onToggleAllClick}
              >
                Toggle All
              </button>
            )}
            {!search ? null : currentPageProjectsAreToggled ? (
              <button
                secondary-btn
                className="button toggle-button"
                onClick={onUntogglePageClick}
              >
                Untoggle Page
              </button>
            ) : (
              <button
                secondary-btn
                className="button toggle-button"
                onClick={onTogglePageClick}
              >
                Toggle Page
              </button>
            )}
          </div>
        </th>
      </tr>
    </thead>
  )
}

const arrowStyle = css({
  color: '#3780CD',
})
