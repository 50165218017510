import { resolvers } from '../resolvers'

/** @ngInject */
export function appsConfig($stateProvider) {
  $stateProvider
    .state('app', {
      abstract: true,
      url: '/app/:app',
      templateUrl: 'app/views/common/content.html',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.appsResolver,
      },
    })
    .state('app.file_cabinet', {
      controller: 'FileCabinetCtrl',
      templateUrl: 'app/views/cabinet.html',
      title: 'File Cabinet Administration',
      url: '/file_cabinet',
      resolve: resolvers.appResolver,
    })
    .state('app.notifications', {
      controller: 'NotificationsCtrl',
      templateUrl: 'app/views/notification_list.html',
      url: '/notifications',
      resolve: resolvers.appResolver,
    })
    .state('app.data_visualization', {
      controller: 'DataVizCtrl',
      templateUrl: 'app/views/data_visualization.html',
      url: '/data-visualization',
      resolve: resolvers.appResolver,
    })
}
