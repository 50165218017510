export const delayLoop = (fn, delay, done) => {
  return (x, i, arr) => {
    setTimeout(() => {
      fn(x)
      if (done && i === arr.length - 1) done()
    }, i * delay)
  }
}

export function generateUUID() {
  let d = new Date().getTime()
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
  })
}

export function getObservationDisplayDate(date) {
  const d = new Date(date)
  return `${d.getUTCFullYear()}/${d.getMonth() + 1}/${d.getDate()}`
}

export function removeA(arr) {
  let what,
    a = arguments,
    L = a.length,
    ax
  while (L > 1 && arr.length) {
    what = a[--L]
    while ((ax = arr.indexOf(what)) !== -1) {
      arr.splice(ax, 1)
    }
  }
  return arr
}

export function getRandomInt(max) {
  return Math.floor(Math.random() * max)
}

export function hexToRGBA(hex) {
  hex = hex.replace(/^#/, '')
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, 0.3)`
}

export function hexToRGBAWithOpacity(hex, opacity) {
  hex = hex.replace(/^#/, '')
  const r = parseInt(hex.slice(0, 2), 16)
  const g = parseInt(hex.slice(2, 4), 16)
  const b = parseInt(hex.slice(4, 6), 16)
  return `rgba(${r}, ${g}, ${b}, ${opacity})`
}
