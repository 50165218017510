window.$ = window.jQuery = require('jquery')
import angular from 'angular'
import ngSanitize from 'angular-sanitize'
import uiselect from 'ui-select'
import ngAnimate from 'angular-animate'
import ngCookies from 'angular-cookies'
import ngDialog from 'ng-dialog'
import ngTouch from 'angular-touch'
import ngMessages from 'angular-messages'
import ngAria from 'angular-aria'
import ngResource from 'angular-resource'
import dndLists from 'angular-drag-and-drop-lists'
import uirouter from '@uirouter/angularjs'
import uibootstrap from 'angular-ui-bootstrap'
import uimask from 'angular-ui-mask'
import ngclipboard from 'ngclipboard'
import xeditable from 'angular-xeditable'
import ngFileSaver from 'angular-file-saver'
import angularAsyncAwait from 'angular-async-await'
import d3 from 'd3'
import ngDropzone from './angular-dropzone'
import 'angular-nvd3'
import 'pace-progress'
import 'metismenu'
import NgSwitchery from './switchery/ng-switchery.js'
import infiniteScroll from './infinite-scroll.js'

export default angular
  .module('ccs', [
    'ui.select',
    'ngAnimate',
    'ngCookies',
    'ngDialog',
    'ngTouch',
    'ngSanitize',
    'ngMessages',
    'ngAria',
    'ngResource',
    'dndLists',
    'ui.router',
    'ui.bootstrap',
    'ui.mask',
    'nvd3',
    'ngclipboard',
    'xeditable',
    'ngFileSaver',
    'ngDropzone',
    'NgSwitchery',
    'infinite-scroll',
    angularAsyncAwait.name,
  ])
  .config(whiteListExternalSites)
  .config(bootstrapConfig)
  .run(runBlock)

function bootstrapConfig($compileProvider, $locationProvider, $logProvider) {
  $locationProvider.html5Mode(true).hashPrefix('!')

  const envDebugEnabled = process.env.BUILD_ENV !== 'production'
  let urlDebugEnabled = false
  let urlDebugDisabledOverride = false

  // Just for safety on old IE
  if (window.URLSearchParams) {
    const params = new window.URLSearchParams(document.location.search)
    urlDebugEnabled = params.get('debugEnabled') === 'true'
    urlDebugDisabledOverride = params.get('debugEnabled') === 'false'
  }

  // $log.debug() works by default for any environment (except for Production for safety reasons)
  // $log.debug() will work for production if ?debugEnabled=true URL search param value is used
  // $log.debug() will NOT work for any environment if ?debugEnabled=false URL search param value is used
  $logProvider.debugEnabled(
    (envDebugEnabled || urlDebugEnabled) && !urlDebugDisabledOverride,
  )
}

function whiteListExternalSites($sceDelegateProvider) {
  // Set white list
  $sceDelegateProvider.resourceUrlWhitelist([
    'self', // All local URLs
    /^https?:\/\/s3.amazonaws.com/, // AWS
    /^https?:\/\/(cdn\.)?procore.com/, // Procore
  ])
}

function runBlock(
  $rootScope,
  $state,
  $stateParams,
  $anchorScroll,
  $log,
  editableThemes,
  editableOptions,
  Configuration,
) {
  // Makes $state and $stateParams available directly in templates
  $rootScope.$state = $state
  $rootScope.$stateParams = $stateParams

  editableThemes.bs3.inputClass = 'input-sm'
  editableThemes.bs3.buttonsClass = 'btn-sm'
  editableOptions.theme = 'bs3'

  $rootScope.$on('$locationChangeSuccess', function () {
    //Always scroll to the top of the page
    //https://stackoverflow.com/questions/21055952/changing-route-doesnt-scroll-to-top-in-the-new-page
    $anchorScroll()
  })

  if (window.Sentry && Configuration.SentryHost) {
    $log.debug('Initializing Sentry for ', Configuration.SentryEnv)
    window.Sentry.init({
      dsn: Configuration.SentryHost,
      integrations: [new window.Sentry.Integrations.Angular()],
      debug: false,
    })
  } else {
    $log.debug('Skipping Sentry initialization.')
  }

  $log.debug('runBlock end')
}

// Then define the init function for starting up the application
angular.element(document).ready(init)

function init() {
  // Fixing facebook bug with redirect
  if (window.location.hash && window.location.hash === '#_=_') {
    if (window.history && history.pushState) {
      window.history.pushState('', document.title, window.location.pathname)
    } else {
      // Prevent scrolling by storing the page's current scroll offset
      const scroll = {
        top: document.body.scrollTop,
        left: document.body.scrollLeft,
      }
      window.location.hash = ''
      // Restore the scroll offset, should be flicker free
      document.body.scrollTop = scroll.top
      document.body.scrollLeft = scroll.left
    }
  }
}
