import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'BatchReportFinalizeCtrl',
    function (
      $scope,
      Api,
      app,
      $stateParams,
      BatchReportService,
      $state,
      $log,
    ) {
      $log.debug('BatchReportFinalizeCtrl')

      $scope.f_id_string = $stateParams.f_id
      $scope.f_id = $scope.f_id_string.split(',').map((x) => +x)

      $scope.reverse = true
      $scope.app = app
      $scope.order = 'date_created'
      $scope.appProjectDisplay = pluralize.singular(app.projects_display)
      $scope.appObservationPartyDisplay = pluralize.singular(
        app.observation_party_observed,
      )
      $scope.tableHeaders = [
        { key: 'selected', display: '', sortable: false },
        {
          key: 'project_name',
          display: $scope.appProjectDisplay + ' Name',
          sortable: true,
        },
        { key: 'category__name', display: app.category, sortable: true },
        { key: 'user__first_name', display: 'Full Name', sortable: true },
        { key: 'user__company_name', display: 'Company', sortable: true },
        { key: 'date_created', display: 'Date Created', sortable: true },
        { key: 'view', display: 'View', centered: true },
      ]

      if ($scope.f_id === 0) {
        $state.go('app.observations.list', { app: app.id })
      }

      $scope.changePage = function () {
        getObservations((observations) => {
          $scope.observations = observations
        })
      }

      function getObservations(cb, observations, page) {
        observations = observations || []
        page = page || 1
        var order = null
        if ($scope.order)
          order = $scope.reverse ? '-' + $scope.order : $scope.order
        Api.Observations.get(
          {
            page: page,
            observations: $scope.f_id.join(),
            order: order,
          },
          (resp) => {
            observations = observations.concat(resp.results)
            if (resp.next) return getObservations(cb, observations, ++page)
            return cb(observations)
          },
        )
      }

      if ($scope.f_id.length > 0) {
        //Make sure there are observations passed or all of the will be retrieved
        getObservations((observations) => {
          $scope.observations = observations
        })
      }

      $scope.search = ''
      $scope.parties_observed = []
      $scope.selected_parties_observed = []
      $scope.checked_parties_observed =
        BatchReportService.checkedPartiesObserved()
      $scope.all_parties_observed = BatchReportService.allPartiesObserved()

      function getUniquePartiesObserved() {
        Api.post(
          `answers/unique_party_observed`,
          {
            observations: $scope.f_id,
            search: $scope.search,
          },
          (resp) => {
            $scope.parties_observed = resp
          },
        )
      }

      $scope.selectPartyObserved = function () {
        BatchReportService.togglePartyObserved($scope.party_observed)
        $scope.checked_parties_observed =
          BatchReportService.checkedPartiesObserved()
        $scope.all_parties_observed = BatchReportService.allPartiesObserved()
      }

      getUniquePartiesObserved()

      $scope.changeChecked = function (p) {
        BatchReportService.togglePartyObserved(p)
        $scope.checked_parties_observed =
          BatchReportService.checkedPartiesObserved()
        $scope.all_parties_observed = BatchReportService.allPartiesObserved()
      }

      $scope.getChecked = function (p) {
        return BatchReportService.isPresentPartyObserved(p)
      }

      $scope.refresh = function (p) {
        $scope.search = p
        getUniquePartiesObserved()
      }

      function objectifyPartiesObserved() {
        var parties_observed = []
        for (var i = 0; i < $scope.checked_parties_observed.length; i++) {
          var party_observed = {}
          party_observed['name'] = $scope.checked_parties_observed[i]
          parties_observed.push(party_observed)
        }
        return parties_observed
      }

      $scope.saveReport = function () {
        Api.BatchReports.post(
          {
            allow_no_party_observed:
              BatchReportService.getAllowBlankPartiesObserved(),
            application: app.id,
            observations: BatchReportService.getFinalizeIds(),
            parties_observed: objectifyPartiesObserved(),
          },
          function () {
            BatchReportService.clear()
            $state.go('app.batch_reports.list', { app: app.id })
          },
        )
      }

      $scope.changeAllowBlankPartiesObserved =
        BatchReportService.toggleAllowBlankPartiesObserved
      $scope.getAllowBlankPartiesObserved =
        BatchReportService.getAllowBlankPartiesObserved
      $scope.changeFinalize = BatchReportService.changeFinalize
      $scope.isPresentFinalize = BatchReportService.isPresentFinalize

      $scope.party_observed_asc = BatchReportService.getPartyObservedAsc()

      // @TODO: $scope.party_observed_asc = null won't work, potential bug
      $scope.sort_parties_observed = function () {
        if (
          ($scope.party_observed_asc =
            null || $scope.party_observed_asc == false)
        ) {
          $scope.party_observed_asc = true
        } else {
          $scope.party_observed_asc = false
        }
        BatchReportService.setPartyObservedAsc($scope.party_observed_asc)
      }
    },
  )
