angular
  .module('ccs')
  .controller(
    'TrainingListCtrl',
    function (
      $scope,
      $rootScope,
      Api,
      ngDialog,
      $state,
      $stateParams,
      CurrentUser,
      $log,
    ) {
      $log.debug('TrainingListCtrl')

      $scope.userIs = CurrentUser.is
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.years = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      $scope.months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
      $scope.days = [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
      ]

      $scope.pageSize = 20

      $scope.serial = 1
      $scope.$watch('pageNumber', function () {
        $scope.serial =
          $scope.pageNumber * $scope.pageSize - ($scope.pageSize - 1)
      })

      if ($rootScope.permissionEditOrDeletTrainingDocumentation) {
        $scope.tableHeaders = [
          { key: 'name', display: 'Name', sortable: true },
          { key: 'employee_id', display: 'Employee ID', sortable: true },
          { key: 'company', display: 'Company', sortable: true },
          { key: 'training_topic', display: 'Training Topic', sortable: true },
          { key: 'date_trained', display: 'Date Trained', sortable: true },
          {
            key: 'date_training_expires',
            display: 'Date Expiring',
            sortable: true,
          },
          { key: 'trained_by', display: 'Trained By', sortable: true },
          { key: 'trade', display: 'Trade', sortable: true },
          {
            key: 'download',
            display: 'Download',
            sortable: false,
            centered: true,
          },
          { key: 'edit', display: 'Edit', sortable: false, centered: true },
          {
            key: 'delete',
            display: 'Delete',
            sortable: false,
            edit: false,
            centered: true,
          },
        ]
      } else {
        $scope.tableHeaders = [
          { key: 'name', display: 'Name', sortable: true },
          { key: 'employee_id', display: 'Employee ID', sortable: true },
          { key: 'company', display: 'Company', sortable: true },
          { key: 'training_topic', display: 'Training Topic', sortable: true },
          { key: 'date_trained', display: 'Date Trained', sortable: true },
          {
            key: 'date_training_expires',
            display: 'Date Expiring',
            sortable: true,
          },
          { key: 'trained_by', display: 'Trained By', sortable: true },
          { key: 'trade', display: 'Trade', sortable: true },
          {
            key: 'download',
            display: 'Download',
            sortable: false,
            centered: true,
          },
        ]
      }

      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //total is really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageSize * $scope.pageNumber }

      function getTrainings() {
        if ($state.current.name === 'trainings.documentation') {
          Api.TrainingsDocumentation.getWithoutDeletedParam(
            query(),
            function (response) {
              $scope.data = {
                items: response.results,
                total: response.count,
              }
            },
          )
        } else {
          Api.Trainings.get(query(), function (response) {
            if (response && response.results) {
              $scope.data = {
                items: response.results,
                total: response.count,
              }
            }
          })
        }
      }

      getTrainings()

      $scope.status = { opened: false }

      $scope.openDatePickerCreate = function ($event) {
        $scope.status.opened = true
      }

      $scope.dateOptions = {
        formatYear: 'yy',
        datepickerMode: 'day',
      }

      function formatDate(date) {
        if (!date) return null
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      $scope.addNewTraning = function () {
        ngDialog.open({
          template: 'app/views/add_new_traning_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function ($scope) {
            $scope.training = {
              name: null,
              expiration_duration: null,
              expiration_time_unit: null,
              years: null,
              months: null,
              days: null,
              trade: null,
            }
            $scope.disableButton = false

            $scope.durations = ['days', 'months', 'years']

            Object.keys($scope.training).map((key) => {
              if ($scope.training[key] == null) delete $scope.training[key]
            })

            $scope.save = function () {
              $scope.disableButton = true
              Api.Trainings.post($scope.training, function (resp) {
                if (resp) {
                  if (
                    $scope.training.expiration_duration &&
                    $scope.training.expiration_time_unit
                  ) {
                    $scope.setExpirationDuration(
                      resp.id,
                      $scope.training.expiration_duration,
                      $scope.training.expiration_time_unit,
                    )
                    $scope.close()
                  } else {
                    getTrainings()
                    $scope.close()
                  }
                }
              })
            }

            $scope.close = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.setExpirationDuration = function (
        training_id,
        expiration_duration,
        expiration_time_unit,
      ) {
        Api.put(
          'trainings/' + training_id + '/set_expiration/',
          {
            expiration_time_unit: expiration_time_unit,
            expiration_duration: expiration_duration,
          },
          function (resp) {
            if (resp) {
              getTrainings()
            }
          },
        )
      }

      $scope.editTraining = function (training) {
        ngDialog.open({
          template: 'app/views/add_new_traning_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function ($scope) {
            $scope.training = angular.copy(training)
            $scope.durations = ['days', 'months', 'years']
            $scope.disableButton = false

            $scope.save = function () {
              $scope.disableButton = true
              $scope.training.years = parseInt($scope.training.years)
              $scope.training.months = parseInt($scope.training.months)
              $scope.training.days = parseInt($scope.training.days)

              if ($scope.training.has_assigned_users) {
                Api.TrainingCopy.post(
                  { training: $scope.training.id },
                  function (resp) {
                    if (resp) {
                      $scope.training.id = resp.id
                      $scope.editTrainingData()
                    }
                  },
                )
              } else {
                $scope.editTrainingData()
              }
            }

            $scope.close = function () {
              $scope.closeThisDialog()
            }

            $scope.editTrainingData = function () {
              Api.Trainings.patch($scope.training, function (resp) {
                if (
                  $scope.training.expiration_duration &&
                  $scope.training.expiration_time_unit
                ) {
                  $scope.setExpirationDuration(
                    resp.id,
                    $scope.training.expiration_duration,
                    $scope.training.expiration_time_unit,
                  )
                  $scope.close()
                } else {
                  getTrainings()
                  $scope.close()
                }
              })
            }
          },
        })
      }

      $scope.isTrainingHasAssignedUsers = function (event, training) {
        event.preventDefault()
        if (training.has_assigned_users) {
          Api.TrainingCopy.post({ training: training.id }, function (resp) {
            if (resp) {
              $scope.goToTopicsPage(resp.id)
            }
          })
        } else {
          $scope.goToTopicsPage(training.id)
        }
      }

      $scope.copyTraining = function (training) {
        ngDialog.open({
          template: 'app/views/copy_training_job_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function ($scope) {
            $scope.disableButton = false
            $scope.save = function () {
              $scope.disableButton = true
              Api.TrainingNewCopy.post(
                { training: training.id },
                function (resp) {
                  if (resp) {
                    $scope.close()
                    getTrainings()
                  }
                },
              )
            }

            $scope.close = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.goToTopicsPage = function (id) {
        $state.go('trainings.topic', {
          trainingID: id,
          previousPageNumber: $scope.pageNumber,
          previousOrder: $scope.order,
          previousReverse: $scope.reverse,
          previousSearch: $scope.searchExecuted ? $scope.search : null,
        })
      }

      $scope.delete = function (training) {
        Api.Trainings.delete(training, (resp) => {
          getTrainings()
        })
      }
      $scope.deleteTrainingsDocumentation = function (training) {
        if (training.api_version === 'v1') {
          Api.TrainingsDocumentationV1.delete(training.training, (resp) => {
            getTrainings()
          })
        } else {
          Api.TrainingsDocumentation.delete(training.training, (resp) => {
            getTrainings()
          })
        }
      }

      function query() {
        let q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          tz_offset: new Date().getTimezoneOffset(),
          table: true,
          app: $scope.$id,
        }
        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      $scope.changePage = function () {
        switch ($state.current.name) {
          case 'trainings.documentation':
          case 'trainings.list':
          case 'trainings.manage':
            $state.transitionTo(
              $state.current.name,
              {
                pageNumber: $scope.pageNumber,
                order: $scope.order,
                reverse: $scope.reverse,
                search: $scope.searchExecuted ? $scope.search : null,
              },
              {
                notify: false,
              },
            )
            break
        }

        getTrainings()
      }

      $scope.addFile = function () {
        $state.go('trainings.import', {
          previousPageNumber: $scope.pageNumber,
          previousOrder: $scope.order,
          previousReverse: $scope.reverse,
        })
      }
    },
  )
