import * as React from 'react'
import { css } from '@emotion/react'
import { ImageWithPreview } from '@/react/components'
import { getTranslatedString } from '@/utils'

export const ImageReferenceField = ({ fieldData }) => {
  const baseStyle = getBaseStyle()
  return (
    <section css={baseStyle}>
      <div className="label-holder">
        <label>{getTranslatedString(fieldData.name)}:</label>
      </div>
      <ImageWithPreview
        photo={fieldData.image_reference_info}
        photoIdx={1}
        onlyImage={true}
      />
    </section>
  )
}

function getBaseStyle() {
  return css({
    marginBottom: 15,
    img: {
      width: 200,
    },
  })
}
