import * as React from 'react'

type Props = {
  id: string
  name: string
  isToggled: boolean
  onToggle: (id: string, value: boolean) => void
}
export const ProjectRow = ({ id, name, isToggled, onToggle }: Props) => {
  return (
    <tr>
      <td>{name}</td>
      <td>
        <label className="switch">
          <input
            className="projectCheckbox"
            type="checkbox"
            checked={isToggled}
            onChange={(evt) => onToggle(id, evt.target.checked)}
            name={id}
          />
          <span className="slider round" />
        </label>
      </td>
    </tr>
  )
}
