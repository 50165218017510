angular
  .module('ccs')
  .directive('numbersonly', numbersOnly)
  .directive('numbersadnsomechars', numbersAdnSomeChars)
  .directive('ordernumbersonly', orderNumbersOnly)

function numbersOnly() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      min: '=',
      max: '=',
    },
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          var transformedInput = parseInt(text.replace(/[^0-9]/g, ''))

          if (transformedInput !== text) {
            if (transformedInput >= scope.max) {
              transformedInput = scope.max
            } else if (transformedInput < scope.min) {
              transformedInput = scope.min
            }
            ngModelCtrl.$setViewValue(transformedInput.toString())
            ngModelCtrl.$render()
          }
          return transformedInput
        }
        return undefined
      }

      ngModelCtrl.$parsers.push(fromUser)
    },
  }
}

function numbersAdnSomeChars() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      min: '=',
      max: '=',
    },
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          text = text.toString()
          var transformedInput = text
            .replace(/[^0-9,-.]/g, '')
            .replace(/,/g, '.')
          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput)
            ngModelCtrl.$render()
          }
          return transformedInput
        }
        return undefined
      }

      ngModelCtrl.$parsers.push(fromUser)
    },
  }
}

function orderNumbersOnly() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      min: '=',
      max: '=',
    },
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          var transformedInput = parseInt(text.replace(/[^0-9]/g, ''))

          if (transformedInput !== text) {
            if (transformedInput >= scope.max) {
              transformedInput = scope.max
            }
            if (transformedInput <= scope.min) {
              transformedInput = scope.min
            }
            ngModelCtrl.$setViewValue(transformedInput.toString())
            ngModelCtrl.$render()
          }
          return transformedInput
        }
        return undefined
      }

      element.bind('blur', function () {
        scope.$apply(attr.ngBlur)
      })
      ngModelCtrl.$parsers.push(fromUser)
    },
  }
}
