import { UISref } from '@/react/components/UISref'
import { css } from '@emotion/react'
import classNames from 'classnames'
import logoIcon from '@/assets/icons/logo-nav-icon.svg'
import infoIcon from '@/assets/icons/info-nav-icon.svg'
import { useRouter } from '@/react/hooks'

export const ConfigNavBar = () => {
  const { stateService } = useRouter()

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'admin.company.logo'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.company.logo',
            })}
          >
            <img className="nav__icon" src={logoIcon} alt="view nav__icon" />
            <p>Logo</p>
          </a>
        </UISref>
        <UISref to={'admin.company.billing'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.company.billing',
            })}
          >
            <img className="nav__icon" src={infoIcon} alt="view nav__icon" />
            <p>Company Information</p>
          </a>
        </UISref>
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '2vw',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginRight: '15px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginRight: '0 !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.nav__icon': {
      marginLeft: '15px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: '5px',
      marginRight: '15px',
      marginBottom: '0px',
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
