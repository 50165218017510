const ROLES = {
  client_admin: 'Administrator',
  supervisor: 'Supervisor',
  client_user: 'Employee',
  read_only: 'Field Employee',
  trainee_manager: 'Training Manager',
  trainee: 'Trainee',
  recipient: 'Recipient',
}

const SUPERVISOR_APPROVED_ROLES = {
  supervisor: 'Supervisor',
  client_user: 'Employee',
  read_only: 'Field Employee',
  trainee_manager: 'Training Manager',
  trainee: 'Trainee',
}

angular
  .module('ccs')
  .controller(
    'UsersPermissionsCtrl',
    function ($scope, $state, Api, CurrentUser, $log) {
      $log.debug('UsersPermissionsCtrl')

      $scope.pageNumber = 1
      $scope.reverse = false
      $scope.totalItems = 0
      $scope.users = []
      $scope.order = 'first_name'

      $scope.breadcrumbs = [{ title: 'Administration' }, { title: 'Users' }]

      $scope.ROLES = CurrentUser.is('supervisor')
        ? SUPERVISOR_APPROVED_ROLES
        : ROLES

      function getUsers() {
        Api.UsersV2.get(
          {
            page: $scope.pageNumber,
            search: $scope.search,
            order: $scope.order
              ? $scope.reverse
                ? '-' + $scope.order
                : $scope.order
              : '',
          },
          (resp) => {
            $scope.totalItems = resp.count
            $scope.users = resp.results
          },
        )
      }

      $scope.changePage = () => {
        getUsers()
      }

      $scope.handleRoleChange = (user) => {
        Api.UsersV2.patch(
          {
            id: user.id,
            role: user.role,
          },
          (resp) => {},
        )
      }

      getUsers()
    },
  )
