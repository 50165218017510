import { css } from '@emotion/react'
import ZurichExternalLogo from '@/assets/images/zurich-external-logo.png'
import { AssessmentResults } from './components/AssessmentResults'
import { useAngularServices } from '@/react/components'
import { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'

export const FinalAssessment = () => {
  const { Api, Notification } = useAngularServices()
  const { stateService } = useRouter()
  const [assessment, setAssessment] = useState(null)

  useEffect(() => {
    const getAssessmentData = async () => {
      try {
        const { data } = await Api.getWithoutDeletedParam(
          `assessment/external_assessments/${stateService.params.client_object_key}`,
          {
            final_view: true,
          },
        )
        setAssessment(data)
      } catch (error) {
        Notification.clearNotification()
        setAssessment(error?.data)
      }
    }

    getAssessmentData()
  }, [])

  const variableColor =
    assessment?.client_settings?.general_settings?.colors.secondary_color ||
    '#2167AE'

  const styles = getStyles(variableColor, assessment?.error)

  return (
    <div className="external__wrapper" css={styles}>
      <div className="logo__wrapper">
        {assessment?.template_name ? (
          <img
            className="logo"
            src={assessment?.client_settings?.company?.logo_url || ''}
            alt="Company Logo"
          />
        ) : null}
      </div>
      <div className="results_block">
        {assessment && <AssessmentResults assessment={assessment} />}
      </div>
    </div>
  )
}

const getStyles = (variableColor, error) =>
  css({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    paddingBottom: '150px',
    background: variableColor,
    height: error ? '100vh' : 'fit-content',
    '.logo__wrapper': {
      marginTop: '38px',
      marginBottom: '50px',
      maxWidth: '300px',
      maxHeight: '150px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
    },
    '.pace-running': {
      background: variableColor,
    },
    '.logo': {
      maxWidth: '100%',
      maxHeight: '150px',
      height: 'auto',
      width: 'auto',
    },
  })
