import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  color?: string
  onClick?: () => void
  className?: string
}

export const PdfTrainingsIcon = ({
  color,
  onClick = () => {},
  className,
}: Props) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      css={[
        css({
          display: 'block',
          color: color,
          width: '16px',
          height: '16px',
        }),
      ]}
      onClick={onClick}
    >
      <path
        d="M5.6 8.4H6.4V6.8H7.2C7.42667 6.8 7.6168 6.7232 7.7704 6.5696C7.924 6.416 8.00053 6.22613 8 6V5.2C8 4.97333 7.9232 4.7832 7.7696 4.6296C7.616 4.476 7.42613 4.39947 7.2 4.4H5.6V8.4ZM6.4 6V5.2H7.2V6H6.4ZM8.8 8.4H10.4C10.6267 8.4 10.8168 8.3232 10.9704 8.1696C11.124 8.016 11.2005 7.82613 11.2 7.6V5.2C11.2 4.97333 11.1232 4.7832 10.9696 4.6296C10.816 4.476 10.6261 4.39947 10.4 4.4H8.8V8.4ZM9.6 7.6V5.2H10.4V7.6H9.6ZM12 8.4H12.8V6.8H13.6V6H12.8V5.2H13.6V4.4H12V8.4ZM4.8 12.8C4.36 12.8 3.9832 12.6432 3.6696 12.3296C3.356 12.016 3.19947 11.6395 3.2 11.2V1.6C3.2 1.16 3.3568 0.783201 3.6704 0.469601C3.984 0.156001 4.36053 -0.000531975 4.8 1.35823e-06H14.4C14.84 1.35823e-06 15.2168 0.156801 15.5304 0.470401C15.844 0.784001 16.0005 1.16053 16 1.6V11.2C16 11.64 15.8432 12.0168 15.5296 12.3304C15.216 12.644 14.8395 12.8005 14.4 12.8H4.8ZM1.6 16C1.16 16 0.783201 15.8432 0.469601 15.5296C0.156001 15.216 -0.000531975 14.8395 1.35823e-06 14.4V3.2H1.6V14.4H12.8V16H1.6Z"
        style={{ fill: color }}
      />
    </svg>
  )
}
