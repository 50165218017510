import * as React from 'react'
import { css } from '@emotion/react'
import { ButtonElement, useAngularServices } from '@/react/components'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useRouter } from '@/react/hooks'

export const ChangeLanguagePopUp = ({ language, setPopUp }) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const languageData = JSON.parse(localStorage.getItem('language-data'))
  const untranslatedCash = JSON.parse(localStorage.getItem('untranslated-cash'))
  const locallyStoredColor = localStorage.getItem('variable-color')
  const variableColor = locallyStoredColor ? locallyStoredColor : '#3981CD'

  async function changeLanguageLogic(value) {
    const filteredListToTranslate = []
    const updatingLanguageData = languageData
    updatingLanguageData.selected = value.value
    if (languageData.selected !== 'origin') {
      untranslatedCash.forEach((stringToTranslate: string) => {
        if (!languageData[languageData.selected]) {
          languageData[languageData.selected] = {}
        }
        if (!languageData[languageData.selected][stringToTranslate]) {
          filteredListToTranslate.push(stringToTranslate)
        }
      })
      let translatedData = []
      if (filteredListToTranslate.length === 0) {
        try {
          const getTranslatedData = await Api.post('translation', {
            contents: filteredListToTranslate,
            target: value.value,
          })
          translatedData = getTranslatedData.data
          localStorage.setItem('currentlyTranslatable', 'true')
        } catch (e) {
          console.log(e)
          localStorage.setItem('currentlyTranslatable', 'false')
        }
      }
      updatingLanguageData[languageData.selected] = {
        ...updatingLanguageData[languageData.selected],
        ...translatedData,
      }
    }
    await localStorage.setItem('language-data', JSON.stringify(languageData))
    setTimeout(() => {
      setPopUp(null)
      stateService.reload()
    }, 1000)
  }

  const baseStyle = getBaseStyle(variableColor)

  return (
    <section css={baseStyle}>
      <div className="bg-holder">
        <div
          className="background"
          onClick={() => {
            setPopUp(null)
          }}
        />
      </div>
      <div className="alert">
        <div
          className="close-row"
          css={css({
            justifyContent: 'right',
          })}
        >
          <img
            src={closeIcon}
            alt="close"
            css={css({
              height: 25,
              cursor: 'pointer',
            })}
            onClick={() => {
              setPopUp(null)
            }}
          />
        </div>
        <div
          className="single-alert-row"
          css={css({
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: 20,
            fontSize: 16,
            fontWeight: 600,
          })}
        >
          Translate page?
        </div>
        <div
          className="single-alert-row"
          css={css({ justifyContent: 'right' })}
        >
          <ButtonElement
            buttonType={'submit'}
            text={'OK'}
            functionToTrigger={() => {
              changeLanguageLogic(language)
            }}
            width={'40%'}
            fontSize={'16px'}
            possibleVariableColor={variableColor}
          />
        </div>
      </div>
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    position: 'fixed',
    display: 'flex',
    right: 0,
    top: 0,
    width: '100vw',
    height: '100vh',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    '.alert': {
      position: 'static',
      backgroundColor: 'white',
      padding: 5,
      paddingBottom: 20,
      height: 'fit-content',
      width: 252,
      zIndex: 1000,
      '.single-alert-row': {
        justifyContent: 'center',
        width: '100%',
        display: 'flex',
      },
      '.close-row': {
        justifyContent: 'rigth',
        width: '100%',
        display: 'flex',
        img: {
          height: 18,
        },
      },
    },
    '.bg-holder': {
      width: 0,
      height: 0,
      '.background': {
        position: 'fixed',
        display: 'flex',
        right: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
        zIndex: 400,
      },
    },
  })
}
