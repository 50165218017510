angular.module('ccs').directive('appfilter', appFilter)

function appFilter(Api, CurrentUser) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/app_filter.html',
    scope: {
      app: '=',
    },
    link: function ($scope) {
      function getApps(search) {
        const s = search || ''

        function getAllApps(s, cb, apps, page) {
          apps = apps || []
          page = page || 1

          Api.Apps.get(
            {
              search: search,
              page: page,
            },
            (resp) => {
              apps = apps.concat(resp.results)
              if (resp.next) return getAllApps(s, cb, apps, ++page)
              cb(apps)
            },
          )
        }

        function getAllAppClients(apps, cb, filteredApps, page) {
          filteredApps = filteredApps || []
          page = page || 1

          const query = {
            client: CurrentUser.getClientId(),
            apps: apps.map((a) => a.id).join(),
            is_active: 'True',
            page: page,
          }

          Api.AppClients.get(query, function (resp) {
            // Store results for processing
            const appClients = resp.results

            // Filter the apps by matching the app id to any active client - app allowances
            var filtered = apps.filter(function (app) {
              return appClients.filter((ac) => ac.app == app.id).length > 0
            })
            filteredApps = filteredApps.concat(filtered)
            if (resp.next)
              return getAllAppClients(apps, cb, filteredApps, ++page)
            cb(filteredApps)
          })
        }

        getAllApps(s, function (apps) {
          getAllAppClients(apps, function (filteredApps) {
            $scope.apps = filteredApps
          })
        })
      }

      $scope.selected = (app) => {
        $scope.$parent.app = app
        $scope.$parent.changePage()
      }

      $scope.refreshApps = (search) => {
        getApps(search)
      }
    },
  }
}
