import { _extends } from '@/utils'

angular
  .module('ccs')
  .directive('sharebutton', shareButton)
  .directive('infobutton', infoButton)
  .directive('downloadqrcodebutton', downloadQRCodeButton)
  .directive('assignprocedurebutton', assignProcedureButton)

function shareButton() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/share_button.html',
    scope: {
      text: '@',
    },
  }
}

function infoButton() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/info_button.html',
    scope: {
      app: '=',
      text: '@',
    },
    controller: function ($scope) {
      $scope.infoTemplate =
        $scope.text === 'settings'
          ? "'app/views/common/info_settings_popover.html'"
          : "'app/views/common/info_qr-code_popover.html'"
      $scope.cancel = function () {
        $scope.isOpen = false
        setTimeout(function () {
          $scope.$apply()
        }, 0)
      }
    },
  }
}

function downloadQRCodeButton() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/download_QRCode_button.html',
    scope: {
      app: '=',
      isOpen: '=',
      showFirstStep: '=',
      formId: '=',
    },
    controller: function ($scope, Api, CurrentUser) {
      $scope.report = {
        project: null,
        form: $scope.formId,
      }
      $scope.projects = []
      $scope.qrCodeLink = ''
      $scope.showFirstStep = true

      //Infinite Scroll Begin
      $scope.infiniteScroll = {}
      $scope.infiniteScroll.numToAdd = 20
      $scope.infiniteScroll.currentProjectItems = 20
      $scope.infiniteScroll.currentFormItems = 20

      $scope.addMoreProjects = function () {
        $scope.infiniteScroll.currentProjectItems +=
          $scope.infiniteScroll.numToAdd
      }

      //Infinite Scroll End

      function getProjects(page) {
        var pageNumber = page || 1
        Api.Projects.get(
          {
            client: CurrentUser.getClientId(),
            page: pageNumber,
            is_active: 'True',
            form: $scope.formId,
          },
          function (resp) {
            $scope.projects = $scope.projects.concat(resp.results)
            if (resp.next) {
              getProjects(pageNumber + 1)
            }
            // isProjects
            $scope.projects.length > 0
              ? ($scope.isProjects = true)
              : ($scope.isProjects = false)
          },
        )
      }

      function getQRCodeFile(client_object_key) {
        if (!client_object_key) return
        Api.postImage(
          `external_reports/${client_object_key}/get_qr_code`,
          {},
          (resp) => {
            if (resp) {
              var blob = new Blob([resp], { type: 'application/pdf' })
              downloadBlob(blob, 'qr_code.pdf')
            }
          },
        )
      }

      function getQRCodeLink(client_object_key) {
        if (!client_object_key) return
        Api.post(
          `external_reports/${client_object_key}/get_url`,
          {},
          (resp) => {
            if (resp) {
              $scope.qrCodeLink = resp.url
              $scope.showFirstStep = false
            }
          },
        )
      }

      function downloadBlob(blob, filename) {
        //trick to download store a file having its URL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          // for IE
          window.navigator.msSaveOrOpenBlob(blob, filename)
        } else {
          // for Non-IE (chrome, firefox etc.)
          var fileURL = URL.createObjectURL(blob)
          var a = document.createElement('a')
          a.href = fileURL
          a.download = filename || 'download'
          document.getElementById('qr_code_link').appendChild(a) //create the link "a"
          a.click() //click the link "a"
          document.getElementById('qr_code_link').removeChild(a) //remove the link "a"
        }
      }

      $scope.save = function () {
        $scope.isQRCodeExists = true
        Api.Reports.post(
          _extends({}, $scope.report, {
            application: $scope.app.id,
            synchronised: true,
            is_external: true,
          }),
          function (resp) {
            if (resp) {
              getQRCodeFile(resp.client_object_key)
              getQRCodeLink(resp.client_object_key)
            }
          },
        )
      }

      $scope.cancel = function () {
        $scope.isOpen = false
        $scope.isQRCodeExists = false
        $scope.qrCodeLink = ''
        $scope.showFirstStep = true
        $scope.report = {
          project: null,
          form: $scope.formId,
        }
        setTimeout(function () {
          $scope.$apply()
        }, 0)
      }

      getProjects()
    },
  }
}

function assignProcedureButton() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/assign_procedure_button.html',
    scope: {
      app: '=',
      isOpen: '=',
      permissionToggleAllProjectsToAllProcedures: '=',
      addAllProceduresToNewProjects: '=',
    },
    controller: function ($scope, Api) {
      $scope.assignProcedurePopover = {
        templateUrl: 'app/views/common/assign_procedure_popover.html',
        isOpen: false,
      }

      $scope.assignAll = function () {
        $scope.isLoading = true
        Api.post(
          'client_procedures/assign_all_projects_to_all',
          {
            on: !$scope.addAllProceduresToNewProjects,
            app: $scope.app.id,
          },
          () => {
            $scope.addAllProceduresToNewProjects =
              !$scope.addAllProceduresToNewProjects
            $scope.isLoading = false
            $scope.cancel()
          },
        )
      }

      $scope.cancel = function () {
        $scope.assignProcedurePopover.isOpen = false
        setTimeout(function () {
          $scope.$apply()
        }, 0)
      }
    },
  }
}
