angular.module('ccs').directive('minimalizaSidebar', minimalizaSidebar)

/**
 * minimalizaSidebar - Directive for minimalize sidebar
 */
function minimalizaSidebar() {
  return {
    restrict: 'A',
    template:
      '<a class="navbar-minimalize minimalize-styl-2" href="" ng-click="minimalize()"><img src="assets/icons/menu-buttons-bars.svg" alt="|||"></a>',
    controller: function ($scope) {
      $scope.minimalize = function () {
        $('body').toggleClass('mini-navbar')
        if (
          !$('body').hasClass('mini-navbar') ||
          $('body').hasClass('body-small')
        ) {
          // Hide menu in order to smoothly turn on when maximize menu
          $('#side-menu').hide()
          // For smoothly turn on menu
          setTimeout(function () {
            $('#side-menu').fadeIn(400)
          }, 200)
        } else if ($('body').hasClass('fixed-sidebar')) {
          $('#side-menu').hide()
          setTimeout(function () {
            $('#side-menu').fadeIn(400)
          }, 100)
        } else {
          // Remove all inline style from jquery fadeIn function to reset menu state
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          })
          $('#side-menu').removeAttr('style')
        }
      }
    },
  }
}
