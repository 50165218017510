import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormList',
    function (
      $scope,
      Api,
      app,
      $stateParams,
      $state,
      uiPermissionService,
      CurrentUser,
      $log,
      $uibModal,
    ) {
      $log.debug('FormList')

      $scope.titleForm = app.app_mobile_form_display
      $scope.titleFormPlural = pluralize.plural(app.app_mobile_form_display)
      $scope.titleProjectPlural = pluralize.plural(app.projects_display)
      $scope.titleProject = app.projects_display
      $scope.titleFormList = pluralize.singular(app.app_mobile_form_display)
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null

      //pageSize Used in the view
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.totalItems = $scope.pageNumber * $scope.pageSize

      $scope.forms = []
      $scope.app = app

      $scope.permissionEditDeleteForm =
        uiPermissionService.getPermissionEditDeleteForm()
      $scope.adminRole = uiPermissionService.getPermissionViewAdministration()

      if ($scope.permissionEditDeleteForm && !$scope.adminRole) {
        $scope.tableHeaders = [
          { key: 'name', display: 'Name', sortable: true },
          { key: 'edit', display: 'Edit', centered: true },
          { key: 'projects', display: app.projects, centered: true },
          { key: 'delete', display: 'Delete', sortable: false, centered: true },
        ]
      } else if ($scope.permissionEditDeleteForm && $scope.adminRole) {
        $scope.tableHeaders = [
          { key: 'name', display: 'Name', sortable: true },
          { key: 'edit', display: 'Edit', centered: true },
          { key: 'projects', display: app.projects, centered: true },
          { key: 'copy', display: 'Copy', centered: true },
          { key: 'delete', display: 'Delete', sortable: false, centered: true },
        ]
      } else {
        $scope.tableHeaders = [
          { key: 'name', display: 'Name', sortable: true },
          { key: 'projects', display: app.projects, centered: true },
        ]
      }

      $scope.changePage = () => {
        getForms()
        $state.transitionTo(
          'app.forms.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.deleteForm = (form) => {
        Api.MobileForms.patch(
          { id: form.id, deleted: true, is_active: false },
          () => {
            getForms()
          },
        )
      }

      $scope.copyAlert = (form) => {
        $scope.modal_title = 'Copy Form'
        $scope.confirm_only = true
        var modalInstance = $uibModal.open({
          templateUrl: 'app/views/common/flexible_modal.html',
          scope: $scope,
          backdrop: true,
          bindToController: true,
          size: 'custom-size-copy-reports',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.dismiss = function () {
              modalInstance.close()
            }
            $scope.save = async function () {
              await Api.post('mobile_forms/' + form.id + '/duplicate', {})
              getForms()
              modalInstance.close()
            }
          },
        })
      }

      function query() {
        let q = {
          page: $scope.pageNumber,
          client_id: CurrentUser.getClientId(),
          application: app.id,
          search: $scope.searchExecuted ? $scope.search : null,
          is_active: 'True',
          annotate_app_project_count: true,
          serializer: 'index',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      function getForms() {
        Api.MobileForms.get(query(), (resp) => {
          $scope.totalItems = resp.count
          $scope.forms = resp.results
        })
      }

      getForms()
    },
  )
