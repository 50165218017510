angular.module('ccs').directive('issueseverityfilter', issueSeverityFilter)

function issueSeverityFilter() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/issue_severity_filter.html',
    link: function ($scope) {
      $scope.severities = {
        1: 'Low',
        2: 'Medium',
        3: 'High',
        any: 'Any',
      }

      $scope.severity = 'any'

      $scope.selected = () => {
        $scope.pageNumber = 1
        $scope.changePage()
      }
    },
  }
}
