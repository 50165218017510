import * as React from 'react'
import { css } from '@emotion/react'

import { singleHeaderTypes } from '@screens/components'
import { useRouter } from '@/react/hooks'
import { parseInt } from 'lodash'
import {
  TableHeaders,
  Search,
  PageSwitcher,
  useAngularServices,
  AdvancedFilter,
  getFilterParam,
} from '@/react/components'
import { TableRow } from './TableRow'
import { encodeString } from '@/utils/additionalEncodeLogic'

const PAGE_LENGTH = 20

export const ReloadableElements = () => {
  const { stateService } = useRouter()
  const { Api } = useAngularServices()

  const [count, setCount] = React.useState<number>(0)
  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [tableData, setTableData] = React.useState([])
  const [order, setOrder] = React.useState(stateService.params.order || 'name')
  const [search, setSearch] = React.useState(stateService.params.search || '')
  const [advancedFilters, setAdvancedFilters] = React.useState(
    getFilterStates(stateService),
  )

  React.useEffect(() => {
    fetchData()
  }, [page, search, order, advancedFilters])

  function fetchData() {
    const params = {
      page: page,
    }

    if (search) params.search = search
    if (order) params.order = order

    let stringParams = ''

    Object.entries(params).forEach((param) => {
      stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
    })

    Object.entries(advancedFilters).forEach((param) => {
      if (param[1].type && param[1].string) {
        stringParams +=
          getFilterParam(
            param[0],
            param[1].type,
            param[1].string,
            param[1].secondType,
            param[1].secondString,
          ) + '&'
      }
    })

    Api.CostCodes.get(stringParams.slice(0, -1), (resp) => {
      setTableData(resp.results)
      setCount(resp.count)
    })
  }

  const fitContent = {
    p: {
      width: 'fit-content',
    },
    '.header-base': {
      width: 'fit-content',
    },
  }

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'cost_codes',
      name: 'Cost Code Name',
      listLocation: 'admin.cost_codes_list',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        textAlign: 'left',
        height: '30px',
        minWidth: '250px',
        width: '250px',
        maxWidth: '250px',
        paddingLeft: '12px',
        ...fitContent,
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
        />
      ),
    },
    {
      className: '',
      name: 'Cost Code Number',
      listLocation: 'admin.cost_codes_list',
      type: 'costcode_code',
      filterHeader: true,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        height: '30px',
        textAlign: 'center',
        minWidth: '180px',
        width: '180px',
        maxWidth: '180px',
        ...fitContent,
        '.header-base': {
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'center',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'costcode_code'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
        />
      ),
    },
    {
      className: 'project',
      name: 'Project',
      listLocation: 'admin.cost_codes_list',
      type: 'project_display',
      filterHeader: true,
      additionalStyles: {
        borderBottom: '1.5px solid #B3B3B3',
        textAlign: 'left',
        minWidth: '150px',
        width: '150px',
        maxWidth: '150px',
        ...fitContent,
        height: '30px',
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'project_display'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
          updateUrl={false}
        />
      ),
    },
    {
      className: 'active',
      name: 'Active',
      listLocation: 'admin.cost_codes_list',
      type: 'is_active',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'center',
        minWidth: '90px',
        width: '90px',
        maxWidth: '90px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
      },
    },
    {
      className: 'delete',
      name: 'Delete',
      listLocation: 'admin.cost_codes_list',
      type: 'delete',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'center',
        minWidth: '90px',
        width: '90px',
        maxWidth: '90px',
        height: '30px',
        borderBottom: '1.5px solid #B3B3B3',
      },
    },
  ]

  return (
    <section css={baseStyle}>
      <div className="search-base">
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
        />
      </div>
      <div className="table__wrapper">
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            popUpLocation={false}
            callback={setOrder}
          />
          <tbody>
            {tableData.length
              ? tableData.map((row, index) => (
                  <TableRow
                    key={row.id}
                    fetchData={fetchData}
                    rowData={row}
                    index={index}
                  />
                ))
              : null}
          </tbody>
        </table>
      </div>
      <div className="page-switcher-base">
        <PageSwitcher
          pageLength={PAGE_LENGTH}
          listCount={count}
          currentPage={page}
          callback={setPage}
        />
      </div>
    </section>
  )
}

function getFilterStates(stateService) {
  const filtersToReturn = {
    name: {},
    costcode_code: {},
    project_display: {},
  }
  if (stateService.name) {
    filtersToReturn.name = JSON.parse(stateService.params.name)
  }
  if (stateService.params.costcode_code) {
    filtersToReturn.costcode_code = JSON.parse(
      stateService.params.costcode_code,
    )
  }
  if (stateService.params.project_display) {
    filtersToReturn.project_display = JSON.parse(
      stateService.params.project_display,
    )
  }

  return filtersToReturn
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  paddingInline: '20px',
  marginLeft: 1,
  paddingBottom: '20px',
  width: 'fit-content',
  table: {
    width: 'fit-content',
    tbody: {
      position: 'relative',
      tr: {
        td: {
          textAlign: 'center',
          padding: '0 5px',
          img: {
            height: '18px',
            cursor: 'pointer',
          },
        },
        '.textAlignLeft': {
          textAlign: 'left',
        },
      },
    },
  },
  td: {
    height: '40px',
  },
  '.search-base': {
    paddingTop: '20px',
    paddingBottom: '15px',
  },
  '.page-switcher-base': {
    marginLeft: '20px',
  },
  '.dual-color-table': {
    marginTop: 0,
  },
  '.tablePadding': {
    paddingLeft: '12px',
  },
  '.checkbox-container': {
    display: 'inline-block',
    position: 'relative',
    cursor: 'pointer',
    paddingLeft: '15px',
    margin: 0,
  },
  '.checkmark': {
    height: '22px',
    width: '22px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #E6E6E6',
    background: '#fff',
    svg: {
      display: 'none',
    },
  },
  '.checkmark::after': {
    display: 'none !important',
  },
  'input:checked ~ .checkmark': {
    img: {
      height: '22px',
      width: '22px',
      display: 'block',
      background: 'white',
    },
    svg: {
      height: '22px',
      width: '22px',
      display: 'block',
      background: 'white',
    },
  },
  '.table__row': {
    color: '#575757',
    height: '38px',
  },
  '.dark': {
    background: '#F2F3F5',
    borderBottom: '1px solid #E7EAEC',
    borderTop: '1px solid #E7EAEC',
  },
  '#search': {
    width: '264px',
  },
  '@media(max-width: 1030px)': {
    width: '100%',
    '.table__wrapper': {
      overflowX: 'scroll',
    },
  },
})
