angular
  .module('ccs')
  .controller(
    'SettingsGeneralCtrl',
    function (
      $scope,
      $rootScope,
      Api,
      CurrentUser,
      Notification,
      $uibModal,
      $log,
      $sce,
    ) {
      $log.debug('SettingsGeneralCtrl')

      $scope.dropdown = {}
      $scope.color_dropdown = {}
      $scope.urls_dropdown = {}
      $scope.hide_old_trainings = false

      $scope.openToolTip = function () {
        $scope.alertModalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content download-alert-width',
          templateUrl: 'app/views/alert_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.title = ''
            $scope.message =
              'Turning on this setting will hide older trainings on the employee public training page (QR Code) when a new training topic with the same name has been uploaded to the same employee.'
            $scope.textAlignment = 'center'
            $scope.textWeight = 'bold'
          },
        })

        $scope.close = function () {
          $scope.alertModalInstance.close()
        }
      }

      function setup() {
        $scope.mobile_apps = $rootScope.mobile_apps.map((ma) => {
          ma.select_label =
            ma.projects_display +
            ', ' +
            ma.observation_party_observed +
            ' (' +
            ma.name +
            ')'
          if (ma.id == $scope.settings.project_language_source) {
            $scope.dropdown.selected_app = ma
          }
          return ma
        })
        $scope.mobile_apps = $rootScope.mobile_apps.map((ma) => {
          ma.select_color_data =
            "<div style='display: flex; align-items: center;'><div style=' display: inline-block; background-color:" +
            ma.secondary_color +
            "; width: 30px; height: 30px; margin-right: 20px; border: solid white 2px'></div><span>" +
            ma.name +
            '</span></div>'
          ma.select_color_data_html = $sce.trustAsHtml(ma.select_color_data)
          if (ma.id == $scope.settings.color_source) {
            $scope.color_dropdown.selected_color = ma
          }
          return ma
        })
        $scope.mobile_apps = $rootScope.mobile_apps.map((ma) => {
          ma.select_urls_data = ma.name
          if (ma.id == $scope.settings.app_urls_source) {
            $scope.urls_dropdown.selected_urls = ma
          }
          return ma
        })
        if ($scope.mobile_apps.length === 1) {
          $scope.dropdown.selected_app = $scope.mobile_apps[0]
          $scope.color_dropdown.selected_color = $scope.mobile_apps[0]
          $scope.urls_dropdown.selected_urls = $scope.mobile_apps[0]
        }
      }

      Api.GeneralSettings.get({}, function (resp) {
        if (resp.results.length) {
          $scope.settings = resp.results[0]
          $scope.hide_old_trainings = resp.results[0].hide_old_trainings
          setup()
        } else {
          Api.GeneralSettings.post(
            {
              client: CurrentUser.getClientId(),
            },
            function (resp) {
              $scope.settings = resp
              setup()
            },
          )
        }
      })

      $scope.update = function () {
        if (
          $scope.dropdown.selected_app &&
          $scope.color_dropdown.selected_color
        ) {
          $scope.settings.color_source = $scope.color_dropdown.selected_color.id
          $scope.settings.app_urls_source =
            $scope.urls_dropdown.selected_urls.id
          $scope.settings.project_language_source =
            $scope.dropdown.selected_app.id
          $scope.settings.hide_old_trainings = $scope.hide_old_trainings
          Api.GeneralSettings.patch(
            $scope.settings,
            (resp) => {
              if (resp) {
                CurrentUser.setClientSettings(resp)
                Notification.success('Settings updated')
              }
            },
            (error) => {
              Notification.danger('Something gone wrong with sending ', error)
            },
          )
        } else {
          if ($scope.mobile_apps.length) {
            Notification.danger(
              'Please select project language and project color',
            )
          } else {
            $scope.settings.hide_old_trainings = $scope.hide_old_trainings
            Api.GeneralSettings.patch(
              $scope.settings,
              (resp) => {
                if (resp) {
                  CurrentUser.setClientSettings(resp)
                  Notification.success('Settings updated')
                }
              },
              (error) => {
                Notification.danger('Something gone wrong with sending ', error)
              },
            )
          }
        }
      }
    },
  )
