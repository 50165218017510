import { UISref } from '@/react/components/UISref'
import { css } from '@emotion/react'
import classNames from 'classnames'
import viewIcon from '@/assets/icons/view-icon.svg'
import uploadIcon from '@/assets/icons/upload-icon.svg'
import addIcon from '@/assets/icons/add-icon.svg'
import { useRouter } from '@/react/hooks'
import React, { useEffect } from 'react'
import { useAngularServices } from '@/react/components'

export const CostCodesNavBar = () => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  const role = CurrentUser.getRole()
  const isTabVisible = role !== 'supervisor' && role !== 'client_user'

  useEffect(() => {
    if (
      role === 'read_only' ||
      role === 'trainee_manager' ||
      role === 'trainee'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'admin.cost_codes_list'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.cost_codes_list',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view icon" />
            <p>View</p>
          </a>
        </UISref>
        <UISref to={'admin.cost_codes_create'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.cost_codes_create',
            })}
          >
            <img className="nav__icon" src={addIcon} alt="add icon" />
            <p>Add</p>
          </a>
        </UISref>
        <UISref to={'admin.cost_codes_bulk'}>
          <a
            className={classNames('tab', {
              active: stateService.current.name === 'admin.cost_codes_bulk',
            })}
          >
            <img className="nav__icon" src={uploadIcon} alt="add icon" />
            <p>Upload</p>
          </a>
        </UISref>
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    '.nav__icon': {
      marginLeft: '25px',
      marginRight: '10px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: 0,
      marginRight: '25px',
      marginBottom: 0,
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
