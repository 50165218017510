import moment from 'moment'

export async function processTimeCards(timecards, rowData, Api) {
  const timeCardPerWeekDay = {
    1: {},
    2: {},
    3: {},
    4: {},
    5: {},
    6: {},
    0: {},
  }

  const activitiesCostCodeIds = []

  timecards.forEach((timeCard) => {
    if (timeCard.time_in) {
      timeCardPerWeekDay[moment.utc(timeCard.time_in).day()] = timeCard
    } else if (timeCard.time_out) {
      timeCardPerWeekDay[moment.utc(timeCard.time_out).day()] = timeCard
    } else {
      timeCardPerWeekDay[moment.utc(timeCard.meal_in).day()] = timeCard
    }
    timeCard.activities.forEach((activity) => {
      if (!activitiesCostCodeIds.includes(activity.costcode))
        activitiesCostCodeIds.push(activity.costcode)
    })
  })

  const { data } =
    activitiesCostCodeIds.length !== 0
      ? await Api.getWithoutDeletedParam('cost_codes', {
          ids: activitiesCostCodeIds.toString(),
        })
      : { data: { results: [] } }
  const costCodes = data.results

  return { timeCardPerWeekDay, costCodes }
}
