angular
  .module('ccs')
  .controller(
    'QuestionsFormCtrl',
    function (
      $scope,
      $stateParams,
      $state,
      Api,
      app,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('QuestionsFormCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.app = app

      function getCategoryList(noSet) {
        Api.Categories.get(
          {
            application: app.id,
            order: 'name',
            is_active: 'True',
            page_size: 250,
            client: CurrentUser.getClientId(),
            custom: 'True',
          },
          function (resp) {
            $scope.categories = resp.results
            if ($stateParams.question) {
              editing = true
              Api.Questions.byID($stateParams.question, function (resp) {
                $scope.question = resp
                $scope.categories.forEach(function (cat) {
                  if (cat.id === $scope.question.category) {
                    $scope.cat = cat
                  }
                })
              })
            }
          },
        )
      }

      getCategoryList()

      var editing = false

      $scope.submit = function (e) {
        $scope.saving = true
        var question = {
          ...$scope.question,
          category: $scope.cat.id,
        }
        updateQuestion(question)
      }

      function updateQuestion(question) {
        if (editing) {
          Api.Questions.patch(question, updateHandler)
        } else {
          Api.Questions.post(question, updateHandler)
        }
      }

      function updateHandler(resp) {
        if (resp) {
          if (editing) {
            redirect()
          } else {
            //we add [ $scope.cat.id ] because we need a custom category for sorting.
            Api.ClientAppQuestions.post(
              {
                question: resp.id,
                is_active: true,
                application: app.id,
                client: CurrentUser.getClientId(),
                custom_categories: [$scope.cat.id],
              },
              function (resp) {
                var action = editing ? 'Updated!' : 'Created!'
                Notification.success('Question Successfully ' + action)
                redirect()
              },
            )
          }
        }
      }

      function redirect() {
        $state.go('app.questions.my', {
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          app: app.id,
          search: $scope.previousSearch,
        })
      }
    },
  )
