angular
  .module('ccs')
  .controller(
    'QuestionsMyCtrl',
    function ($scope, $stateParams, $state, Api, app, CurrentUser, $log) {
      $log.debug('QuestionsMyCtrl')

      $scope.pageSize = 20
      //Populate the search box
      $scope.query = $stateParams.search ? $stateParams.search : ''
      //The last executed query is used for highlighting (i.e. so highliting won't change until the user invokes search)
      $scope.lastExecutedQuery = $stateParams.search ? $stateParams.search : ''
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      //temporarily set the total number of questions to page size * page number (to initialize the uib-pagination before records are retreived )
      $scope.questionTotal = $scope.pageNumber * $scope.pageSize
      $scope.app = app
      $scope.isClientOrAdmin = CurrentUser.isClientOrAdmin

      function getCategoryList(noSet) {
        Api.Categories.get(
          {
            application: app.id,
            order: 'name',
            is_active: 'True',
            page_size: 250,
            client: CurrentUser.getClientId(),
            custom: 'True',
          },
          function (resp) {
            $scope.categories = resp.results
            $scope.categoriesParam = $scope.categories
              .map(function (q) {
                return q.id
              })
              .join()
            if (resp.results.length) {
              getQuestions()
            }
          },
        )
      }

      function getQuestions() {
        Api.Questions.get(
          {
            page: $scope.pageNumber,
            application: app.id,
            order: 'order',
            is_active: 'True',
            searchmy: $scope.query,
            client: CurrentUser.getClientId(),
            categories: $scope.categoriesParam,
            my: 'True',
            app_questions: 'True',
          },
          function (resp) {
            $scope.questions = resp.results
            $scope.questionCount = resp.count
            $scope.questionTotal = $scope.questionCount

            $scope.questions = $scope.questions.map(function (q) {
              $scope.categories.forEach(function (cat) {
                if (cat.id === q.category) {
                  q.category = cat
                }
              })
              return q
            })
          },
        )
        updateAddressBar()
      }

      getCategoryList()

      $scope.delete = function (question) {
        Api.Questions.delete(question, (resp) => {
          getQuestions()
        })
      }

      $scope.changePage = function () {
        getQuestions()
      }

      $scope.search = function (query) {
        $scope.lastExecutedQuery = query
        $scope.pageNumber = 1
        //Update the URL in the bar
        getQuestions($scope.category)
      }

      $scope.clearSearch = function (query) {
        $scope.lastExecutedQuery = ''
        $scope.query = ''
        getQuestions()
      }

      function updateAddressBar() {
        $state.transitionTo(
          'app.questions.my',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.lastExecutedQuery,
          },
          { notify: false },
        )
      }
    },
  )
