import * as React from 'react'
import { useAngularServices } from '@/react/components'
import { getBase64Image } from '@/utils/ImageAndPrintProcessing'

export function SingleSignature({ signature, idx }: any) {
  const [imageReplaced, setImageReplaced] = React.useState<boolean>(false)
  const [imageUrl, setImageUrl] = React.useState<string>(
    signature.image.image || signature.image.ulr,
  )

  const { Api } = useAngularServices()

  const getNewImage = async () => {
    if (!imageReplaced) {
      const image = await getBase64Image(Api, signature.image.id)
      setImageUrl(image)
      setImageReplaced(true)
    }
  }

  React.useEffect(() => {
    if (!imageReplaced && !signature.deleted) {
      getNewImage()
    }
  })

  if (signature.deleted) return null

  return (
    <div className="signature" key={idx}>
      <div className="signature-img-w img-w">
        <img src={imageUrl} />
      </div>
      <p className="signature-name">{signature.printed_name}</p>
    </div>
  )
}
