import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function adminConfig($stateProvider) {
  $stateProvider
    .state('true_admin', {
      abstract: true,
      templateUrl: 'app/views/common/content.html',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.home', {
      template: '<admin-home></admin-home>',
      url: '/admin/home',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.admin_table', {
      template: '<admin-table></admin-table>',
      url: '/admin/admins',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.admin_add', {
      template: '<admin-create-edit-admin></admin-create-edit-admin>',
      url: '/admin/add_admin',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.admin_edit', {
      template: '<admin-create-edit-admin></admin-create-edit-admin>',
      url: '/admin/edit_admin/:id',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.admin_clients', {
      template: '<admin-clients-table></admin-clients-table>',
      url: '/admin/clients',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.admin_clients_add', {
      template: '<admin-create-edit-admin></admin-create-edit-admin>',
      url: '/admin/clients_add',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.admin_clients_edit', {
      template: '<admin-create-edit-admin></admin-create-edit-admin>',
      url: '/admin/clients_edit/:id',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.create_app', {
      template: '<admin-create-edit-app></admin-create-edit-app>',
      url: '/admin/create_app',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.risk_dashboard', {
      template: '<admin-switcher-tables></admin-switcher-tables>',
      url: '/admin/risk_dashboard',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.lessons_learned', {
      template: '<admin-switcher-tables></admin-switcher-tables>',
      url: '/admin/lessons_learned',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.training', {
      template: '<admin-switcher-tables></admin-switcher-tables>',
      url: '/admin/training',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.assessment', {
      template: '<admin-switcher-tables></admin-switcher-tables>',
      url: '/admin/assessment',
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.edit_app', {
      template: '<admin-create-edit-app></admin-create-edit-app>',
      url: '/admin/edit_app/:app',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_config', {
      template: '<admin-app-config></admin-app-config>',
      url: '/admin/app/:app/config',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_clients', {
      template: '<admin-app-clients></admin-app-clients>',
      url: '/admin/app/:app/clients',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_mobile_forms', {
      template: '<admin-app-mobile-forms></admin-app-mobile-forms>',
      url: '/admin/app/:app/mobile_forms',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_news', {
      template: '<admin-app-news></admin-app-news>',
      url: '/admin/app/:app/news',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_procedures', {
      template: '<admin-app-procedures></admin-app-procedures>',
      url: '/admin/app/:app/procedures',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_process_flow', {
      template: '<admin-app-process-flow></admin-app-process-flow>',
      url: '/admin/app/:app/process_flow',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_questions', {
      template: '<admin-app-questions></admin-app-questions>',
      url: '/admin/app/:app/toolbox_talks',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
    .state('true_admin.app_toolbox_talks', {
      template: '<admin-app-toolbox-talks></admin-app-toolbox-talks>',
      url: '/admin/app/:app/questions',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
    })
    .state('true_admin.app_training', {
      template: '<admin-app-training></admin-app-training>',
      url: '/admin/app/:app/training',
      params: {
        app: {
          value: '',
          dynamic: true,
        },
      },
      resolve: resolvers.fullAdminOnlyViewResolver,
    })
}
