import {
  ButtonElement,
  CommonPageHeader,
  dropdownFiledTheme,
  dropdownOptionStyles,
  FloatingWindowHorizontalNavigation,
  TextInput,
  useAngularServices,
} from '@/react/components'
import React, { useEffect } from 'react'
import { css } from '@emotion/react'
import { getAdminNavigationData } from '@screens/Admin/utils/commonVariables'
import { useRouter } from '@/react/hooks'
import { DataWrap } from '@/react/types'
import { adminUser } from '@screens/Admin/utils/commomTypes'
import { CountriesForSelect } from '@/utils/countriesRelated'
import { default as ReactSelect } from 'react-select'
import { getSelectOption } from '@/utils/data/selectOptionFinder'

export const CreateEditAdmin = () => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()

  const [user, setUser] = React.useState<adminUser | null>(null)
  const [email, setEmail] = React.useState<string>('')
  const [username, setUsername] = React.useState<string>('')
  const [firstName, setFirstName] = React.useState<string>('')
  const [lastName, setLastName] = React.useState<string>('')
  const [company, setCompany] = React.useState<string>('')
  const [phone, setPhone] = React.useState<string>('')
  const [street, setStreet] = React.useState<string>('')
  const [town, setTown] = React.useState<string>('')
  const [state, setState] = React.useState<string>('')
  const [zip, setZip] = React.useState<string>('')
  const [country, setCountry] = React.useState<{
    value: string
    label: string
  } | null>(null)
  const [requestFinished, setRequestFinished] = React.useState<boolean>(false)

  const { Api } = useAngularServices()

  const adminPages = ['true_admin.admin_edit', 'true_admin.admin_add'].includes(
    stateService.current.name,
  )

  useEffect(() => {
    const getTemplates = async () => {
      const { data: result }: DataWrap<adminUser> = await Api.get(
        'users/' + stateService.params.id,
        {},
      )

      setUser(result)

      setEmail(result.email)
      setUsername(result.username)
      setFirstName(result.first_name)
      setLastName(result.last_name)
      if (!adminPages) {
        setCompany(result.company_name)
        setPhone(result.phone)
        setTown(result.town)
        setState(result.state)
        setZip(result.zip)
        if (result.country)
          setCountry(getSelectOption(result.country, CountriesForSelect, true))
      }
      setRequestFinished(true)
    }
    if (stateService.params.id) getTemplates()
  }, [])

  const sendUser = async () => {
    if (adminPages) {
      if (stateService.params.id) {
        const { data: result }: DataWrap<adminUser> = await Api.patch(
          'users/' + stateService.params.id,
          {
            id: stateService.params.id,
            username,
            email,
            first_name: firstName,
            last_name: lastName,
          },
        )
        if (!result?.details) {
          stateService.go('true_admin.admin_table', {})
        }
      } else {
        const { data: result }: DataWrap<adminUser> = await Api.post('users', {
          username,
          email,
          first_name: firstName,
          projects: [],
          role: 'admin',
          last_name: lastName,
        })
        if (!result?.details) {
          stateService.go('true_admin.admin_table', {})
        }
      }
    } else {
      const sendObj = {
        user: {
          role: 'client_admin',
          username,
          email,
          first_name: firstName,
          last_name: lastName,
          phone,
          street,
          town,
          state,
          country: country?.label,
          zip,
          company_name: company,
        },
        company: {
          name: company,
        },
        sic_code: '',
      }
      if (stateService.params.id) {
        const { data: result }: DataWrap<adminUser> = await Api.patch(
          'clients/' + user?.client.id,
          sendObj,
        )
        if (!result?.details) {
          stateService.go('true_admin.admin_clients', {})
        }
      } else {
        const { data: result }: DataWrap<adminUser> = await Api.post(
          'clients',
          sendObj,
        )
        if (!result?.details) {
          stateService.go('true_admin.admin_clients', {})
        }
      }
    }
  }

  const canBeSaved = (): boolean | number => {
    return (
      (email.length &&
        username.length &&
        firstName.length &&
        lastName.length &&
        adminPages) ||
      company.length
    )
  }

  if (stateService.params.id && !requestFinished) return null

  return (
    <div className="manage__assessments" css={baseStyle}>
      <CommonPageHeader
        headerText={
          (stateService.params.id ? 'Edit ' : 'Add ') +
          (adminPages ? 'Admin' : 'Client')
        }
      />
      <FloatingWindowHorizontalNavigation
        navSettings={getAdminNavigationData(
          adminPages ? 'add_superadmin' : 'add_client',
        )}
      />
      <section className="form__section">
        <label className="main-label">Email</label>
        <TextInput
          waitTime={200}
          width={'100%'}
          height={36}
          placeholder={''}
          defaultValue={email}
          valueCallback={setEmail}
          error={!email.length}
          textLimit={255}
          textLimitDisplay={true}
          checkAsEmail={true}
        />
        <label className="main-label">Username</label>
        <TextInput
          waitTime={200}
          width={'100%'}
          height={36}
          placeholder={''}
          defaultValue={username}
          valueCallback={setUsername}
          error={!username.length}
          textLimit={255}
          textLimitDisplay={true}
        />
        <label className="main-label">First Name</label>
        <TextInput
          waitTime={200}
          width={'100%'}
          height={36}
          placeholder={''}
          defaultValue={firstName}
          valueCallback={setFirstName}
          error={!firstName.length}
          textLimit={255}
          textLimitDisplay={true}
        />
        <label className="main-label">Last Name</label>
        <TextInput
          waitTime={200}
          width={'100%'}
          height={36}
          placeholder={''}
          defaultValue={lastName}
          valueCallback={setLastName}
          error={!lastName.length}
          textLimit={255}
          textLimitDisplay={true}
        />
        {adminPages
          ? null
          : [
              <label className="main-label">Company</label>,
              <TextInput
                waitTime={200}
                width={'100%'}
                height={36}
                placeholder={''}
                defaultValue={company}
                valueCallback={setCompany}
                error={!company.length}
                textLimit={255}
                textLimitDisplay={true}
              />,
              <label className="main-label">Phone</label>,
              <TextInput
                waitTime={200}
                width={'100%'}
                height={36}
                placeholder={'xxx-xxx-xxxx'}
                defaultValue={phone}
                valueCallback={setPhone}
                textLimit={20}
                textLimitDisplay={true}
                onlyNumbers={true}
              />,
              <label className="main-label">Street</label>,
              <TextInput
                waitTime={200}
                width={'100%'}
                height={36}
                placeholder={''}
                defaultValue={street}
                valueCallback={setStreet}
                textLimit={255}
                textLimitDisplay={true}
              />,
              <label className="main-label">Town</label>,
              <TextInput
                waitTime={200}
                width={'100%'}
                height={36}
                placeholder={''}
                defaultValue={town}
                valueCallback={setTown}
                textLimit={255}
                textLimitDisplay={true}
              />,
              <label className="main-label">State</label>,
              <TextInput
                waitTime={200}
                width={'100%'}
                height={36}
                placeholder={''}
                defaultValue={state}
                valueCallback={setState}
                textLimit={255}
                textLimitDisplay={true}
              />,
              <label className="main-label">Country</label>,
              <ReactSelect
                defaultValue={country}
                options={CountriesForSelect}
                onChange={setCountry}
                styles={dropdownOptionStyles(variableColor)}
                theme={(theme) => dropdownFiledTheme(theme, variableColor)}
              />,
              <label className="main-label zip-label">Zip</label>,
              <TextInput
                waitTime={200}
                width={'100%'}
                height={36}
                placeholder={''}
                defaultValue={zip}
                valueCallback={setZip}
                textLimit={60}
                textLimitDisplay={true}
                onlyNumbers={true}
              />,
            ]}
        <div className="button__row">
          <ButtonElement
            text={'Save'}
            functionToTrigger={sendUser}
            disabled={!canBeSaved()}
          />
        </div>
      </section>
    </div>
  )
}

const baseStyle = css({
  paddingBottom: 200,
  '.form__section': {
    background: '#fff',
    paddingInline: 20,
    paddingBottom: 10,
    paddingTop: 10,
    width: 800,
  },
  '.zip-label': {
    marginTop: 20,
    paddingLeft: 5,
  },
  '@media(max-width: 1150px)': {
    '.manage__section': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
})
