angular.module('ccs').directive('uiselectfield', uiSelectField)

function uiSelectField() {
  return {
    restrict: 'A',
    require: ['uiSelect', 'ngModel'],
    link: function (scope, element, attrs, ctrls) {
      var $select, ngModel
      $select = ctrls[0]
      ngModel = ctrls[1]
      return ngModel.$parsers.push(function (value) {
        if (value[attrs.uiselectfield]) {
          return value[attrs.uiselectfield]
        } else {
          return value
        }
      })
    },
  }
}
