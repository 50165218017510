import { ButtonElement } from '@/react/components'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'
import { useState } from 'react'

export const ExternalLinkModal = ({ handleExternalLink, handleClose }) => {
  const [displayText, setDisplayText] = useState('')
  return (
    <div className="modal__wrapper" css={styles}>
      <img
        src={closeIcon}
        alt="cross-icon"
        className="close__icon"
        onClick={() => {
          handleClose()
        }}
      />
      <div className="modal__info">
        <p className="modal__title">Dynamic Link</p>
        <div className="inputs__wrapper">
          <div className="single__input">
            <label className="input__label" htmlFor="">
              Display Text
            </label>
            <input
              className="input"
              type="text"
              onChange={(e) => {
                setDisplayText(e.target.value)
              }}
            />
          </div>
        </div>
        <ButtonElement
          buttonType="submit"
          text="Hyperlink"
          functionToTrigger={() => {
            handleExternalLink(displayText)
            handleClose()
          }}
        />
      </div>
    </div>
  )
}

const styles = css({
  background: '#fff',
  position: 'absolute',
  zIndex: 99999999999,
  boxShadow: '0px 4px 7px 0px rgba(0, 0, 0, 0.25)',
  padding: '25px',
  left: '200px',
  '.modal__info': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'center',
  },
  '.close__icon': {
    height: '12px',
    width: '12px',
    position: 'absolute',
    right: '10px',
    top: '8px',
    cursor: 'pointer',
  },
  '.inputs__wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  '.single__input': {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  '.input__label': {
    fontSize: '14px',
    fontWeight: 600,
    color: '#68696D',
  },
  '.input': {
    ...TextInputStyle,
    height: '30px',
    width: '312px',
    '::placeholder': {
      color: '#CDCCCC',
    },
  },
})
