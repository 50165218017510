import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import deleteCross from '/src/assets/icons/X Red.svg'

type Props = {
  possibleText?: string
  deleteButton: boolean
  functionToTrigger: void
  height?: string
  fontSize?: string
  width?: string
  additionalStyle?: SerializedStyles
  additionalBaseStyle?: SerializedStyles
  disabled?: boolean
}

export const BesideInputButton: React.FC<Props> = ({
  possibleText = '',
  deleteButton,
  height = '34px',
  width = '34px',
  fontSize = '34px',
  functionToTrigger,
  additionalStyle = css({}),
  additionalBaseStyle = css({}),
}: Props) => {
  return (
    <div
      css={[
        baseStyle,
        css({
          height: height,
          width: width,
        }),
        additionalBaseStyle,
      ]}
      onClick={functionToTrigger}
      className="beside-input-button"
    >
      {deleteButton ? (
        <img src={deleteCross} css={[cancelCross]} />
      ) : (
        <p
          css={[
            textStyle,
            css({
              fontSize: fontSize,
              lineHeight: fontSize,
              margin: 0,
            }),
            additionalStyle,
          ]}
        >
          {possibleText}
        </p>
      )}
    </div>
  )
}

const baseStyle = css({
  textAlign: 'center',
  borderColor: '#e5e6e7',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '0',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})
const cancelCross = css({
  color: 'red',
  margin: 0,
  width: 25,
  height: 25,
})
const textStyle = css({
  fontSize: 20,
})
