angular.module('ccs').controller('UserDashboard', function ($scope, Api, $log) {
  $log.debug('UserDashboard')

  $scope.pageNubmer = 1

  function query() {
    let q = {
      after_date: $scope.startDate,
      before_date: $scope.endDate,
      order: $scope.reverse ? '-' + $scope.order : $scope.order,
      division: $scope.division,
      page_size: 10,
      search: $scope.search,
      page: $scope.pageNumber || 1,
    }

    q.application = $scope.app ? $scope.app.id : null
    q.project = $scope.project ? $scope.project.id : null

    return q
  }

  function getData() {
    Api.get('users/sort_and_rank', query(), (resp) => {
      $scope.users = resp.results.map((u) => {
        return {
          ...u,
          avg_duration: new Date(u.avg_duration * 1000)
            .toISOString()
            .substr(11, 8),
        }
      })
      $scope.totalItems = resp.count
    })
  }

  $scope.changePage = getData

  getData()
})
