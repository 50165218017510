import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormProjectAssign',
    function ($scope, Api, $state, $stateParams, app, CurrentUser, $log) {
      $log.debug('FormProjectAssign')

      $scope.titleFormSingular = pluralize.singular(app.app_mobile_form_display)
      $scope.appProject = null
      $scope.mobileForms = []
      $scope.formSets = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name'
      $scope.app = app
      $scope.titleAssignProjectToForm = app.app_mobile_form_display

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.breadcrumbs = [
        {
          title: app.name,
        },
        {
          title: 'Assign ' + $scope.titleFormSingular,
          url: 'app.forms.assign',
        },
      ]

      $scope.tableHeaders = [
        { key: 'name', display: $scope.titleFormSingular, sortable: true },
        {
          key: 'active_for_project',
          display: 'Inactive/Active',
          sortable: true,
          toggleAllBtn: true
        },
      ]

      var projectId = $stateParams.project

      function mobileFormQuery() {
        let q = {
          application: app.id,
          client_id: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_active_app_project: $scope.appProject.id,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      function getMobileForms() {
        Api.MobileForms.get(mobileFormQuery(), function (resp) {
          $scope.totalItems = resp.count
          $scope.mobileForms = resp.results.map(function (mobileForm) {
            mobileForm.active_for_project = false
            if ($scope.appProject.active_mobile_forms) {
              $scope.appProject.active_mobile_forms.forEach(function (mf) {
                if (mobileForm.id == mf) {
                  mobileForm.active_for_project = true
                }
              })
            }
            return mobileForm
          })
        })
      }

      function getFormSets(page) {
        Api.FormSets.get(
          {
            application: app.id,
            page: page,
            search: $scope.search,
            order: $scope.reverse ? '-' + $scope.order : $scope.order,
          },
          (resp) => {
            const sets = resp.results.map((s) => {
              s.is_active = false
              if (s.app_projects.indexOf($scope.appProject.id) != -1) {
                s.is_active = true
              }
              return s
            })

            $scope.formSets = $scope.formSets.concat(sets)

            if (resp.next) getFormSets(++page)
          },
        )
      }

      function getAppProjects() {
        Api.AppProjects.get(
          {
            app: app.id,
            project: projectId,
          },
          function (resp) {
            if (resp.results.length) {
              $scope.appProject = resp.results[0]
              getMobileForms()
              getFormSets(1)
            } else {
              Api.AppProjects.post(
                {
                  app: app.id,
                  project: projectId,
                  client: CurrentUser.getClientId(),
                },
                function (resp) {
                  $scope.appProject = resp
                  getMobileForms()
                  getFormSets(1)
                },
              )
            }
          },
        )
      }

      getAppProjects()

      $scope.changePage = () => {
        getMobileForms()
        $scope.formSets = []
        getFormSets(1)
      }

      $scope.toggleAll = () => {
        Api.post(
          `app_projects/${$scope.appProject.id}/assign_all_mobile_forms`,
          {
            id: $scope.appProject.id,
            on: $scope.active,
            search: $scope.search,
          },
          () => {
            $scope.formSets = []
            getAppProjects()
          },
        )
      }

      $scope.toggleSet = (set) => {
        if (set.is_active) {
          set.app_projects = set.app_projects.concat($scope.appProject.id)
        } else {
          set.app_projects = set.app_projects.filter(
            (app_project) => app_project != $scope.appProject.id,
          )
        }
        Api.FormSets.patch(
          {
            id: set.id,
            app_projects: set.app_projects,
          },
          (resp) => {
            $scope.formSets = []
            getAppProjects()
          },
        )
      }

      $scope.toggleActive = function (mobileForm) {
        if (mobileForm.active_for_project) {
          $scope.appProject.mobile_forms.push(mobileForm.id)
        } else {
          $scope.appProject.mobile_forms =
            $scope.appProject.mobile_forms.filter(function (mf) {
              return mf != mobileForm.id
            })
        }

        Api.AppProjects.patch(
          {
            id: $scope.appProject.id,
            mobile_forms: $scope.appProject.mobile_forms,
          },
          function (resp) {
            $scope.appProject = resp
            $scope.formSets = []
            getFormSets(1)
          },
        )
      }

      //Restore pagination values.
      $scope.backToSearch = function () {
        $state.go('app.forms.assign', {
          app: app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }
    },
  )
