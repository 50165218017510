angular
  .module('ccs')
  .controller(
    'TrainingGroupListCtrl',
    function ($scope, Api, $state, $stateParams, ngDialog, CurrentUser, $log) {
      $log.debug('TrainingGroupListCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.employeesList = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name'
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1

      $scope.pageSize = 20

      function query() {
        let q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          is_active: 'True',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      var getTrainingGroupList = function getProcedures() {
        Api.TrainingGroupList.get(query(), function (resp) {
          $scope.trainingGroupList = resp.results.map(function (group) {
            group.isEditing = false
            return group
          })

          $scope.totalItems = resp.count
        })
      }

      getTrainingGroupList()

      $scope.changePage = function () {
        getTrainingGroupList()
      }

      $scope.addNewTrainingGroup = function () {
        // var $scp = this.$new(true);
        ngDialog.open({
          template: 'app/views/add_new_training_group_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function ($scope) {
            $scope.trainingGroup = {
              name: null,
            }

            $scope.save = function () {
              Api.TrainingGroupList.post(
                {
                  name: $scope.trainingGroup.name,
                  client: CurrentUser.getClientId(),
                },
                function (resp) {
                  $scope.closeThisDialog()
                  getTrainingGroupList()
                },
              )
            }

            $scope.close = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.updateTrainingGroupName = function (groupEditing) {
        groupEditing.isEditing = false
        Api.patch(`training_sets/${groupEditing.id}/?deleted=False`, {
          name: groupEditing.name,
        })
      }

      $scope.editTrainingGroupName = function (group) {
        group.isEditing = true
        $scope.editingTrainingGroupWithName = group.name
      }

      $scope.cancelTrainingGroupNameEdit = function (group) {
        group.name = $scope.editingTrainingGroupWithName
        group.isEditing = false
        $scope.editingTrainingGroupWithName = ''
      }

      $scope.delete = function (training) {
        Api.TrainingGroupList.delete(training, (resp) => {
          getTrainingGroupList()
        })
      }
    },
  )
