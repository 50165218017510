import React from 'react'
import { css } from '@emotion/react'
import { ButtonElement, useAngularServices } from '@/react/components'

export function PopUp({
  functionToTrigger,
  setState,
  popUpText,
  popUpHeader,
  popUpInputHeader,
  activeInput,
  id,
}: any) {
  function acceptActions() {
    if (activeInput) {
      const note = document.getElementById('popUpInput').value
      functionToTrigger(id, note)
    } else {
      functionToTrigger(id)
    }
    setState(false)
  }

  return (
    <div css={popUpBase}>
      <div css={popUp}>
        <h3 className={'popup-header'}>{popUpHeader}</h3>
        <h4 className={'popup-text'}>{popUpText}</h4>
        {activeInput ? (
          <h3 className={'popup-header'}>{popUpInputHeader}</h3>
        ) : (
          ''
        )}
        {activeInput ? (
          <input css={inputStyle} type="text" id="popUpInput" />
        ) : (
          ''
        )}
        <div className="button-body">
          <ButtonElement
            text={'Cancel'}
            minWidth={'150px'}
            functionToTrigger={() => {
              setState(false)
            }}
            additionalStyles={css({ marginRight: 20 })}
          />
          <ButtonElement
            text={'OK'}
            minWidth={'150px'}
            buttonType={'submit'}
            functionToTrigger={acceptActions}
          />
        </div>
      </div>
    </div>
  )
}

const popUpBase = css({
  zIndex: 8000,
  width: '100%',
  height: '100%',
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  left: 0,
  top: 0,
  backgroundColor: 'rgba(0,0,0, 0.5)',
})
const popUp = css({
  zIndex: 9000,
  width: '450px',
  backgroundColor: 'white',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.popup-header': {
    marginTop: '45px',
    fontWeight: 'bold',
  },
  '.popup-text': {
    marginTop: '25px',
  },
  '.button-body': {
    marginTop: '35px',
    marginBottom: '35px',
    display: 'flex',
    justifyContent: 'space-between',
  },
})
const inputStyle = css({
  color: 'rgb(103, 106, 108)',
  backgroundColor: 'rgb(255, 255, 255)',
  borderColor: 'rgb(229, 230, 231)',
  borderRadius: 0,
  borderStyle: 'solid',
  paddingBottom: '6px',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingTop: '6px',
  height: '40px',
  width: '325px',
})
