import * as React from 'react'
import { css } from '@emotion/react'

import { getPartyObservedNavigationSettings } from '../components'
import {
  useAngularServices,
  FloatingWindowHorizontalNavigation,
  CommonPageHeader,
} from '@/react/components'
import { ReloadableElements } from '@screens/Observations/PartyObservedList/components'
import { PartyObservedNavBar } from '../components/PartyObservedNavBar'

export const PartyObservedList = ({}) => {
  const { CurrentUser } = useAngularServices()

  const userSettings = CurrentUser.getClientSettings()
  const [POVariableName] = React.useState(userSettings.party_observed_language)

  return (
    <section>
      <CommonPageHeader headerText={POVariableName} />
      <div
        className="party__wrapper"
        css={{
          marginLeft: '5px',
        }}
      >
        <PartyObservedNavBar />
        <ReloadableElements POVariableName={POVariableName} />
        <div css={bottomMargin} />
      </div>
    </section>
  )
}

const bottomMargin = css({
  height: 100,
})
