import * as React from 'react'
import { useAngularServices } from '@components/index'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'

import pluralize from 'pluralize'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'
import { getBase64Image } from '@/utils/ImageAndPrintProcessing'

export function TemplateHeader({ headerData, app }: any) {
  const { CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const user = CurrentUser.getClientCompany()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const variableProjectsName = pluralize.singular(app.projects_display)
  const variableCategoryName = app.observations_categories

  const clientInfoStyle = getClientInfoStyle(variableColor)
  const reportHeader = getReportHeader(variableColor)

  const [imageReplaced, setImageReplaced] = React.useState<boolean>(false)
  const [imageUrl, setImageUrl] = React.useState<string>(
    user.logo_url ? user.logo_url : headerData.client.company.logo_url,
  )

  const { Api } = useAngularServices()

  const getNewImage = async () => {
    const image = await getBase64Image(
      Api,
      user.logo ? user.logo : headerData.client.company.logo,
    )
    setImageUrl(image)
    setImageReplaced(true)
  }

  React.useEffect(() => {
    if (!imageReplaced) {
      getNewImage()
    }
  })

  return (
    <section css={headerBaseStyle}>
      {stateService.params.key
        ? null
        : [
            <div css={reportHeader}>
              {headerData.batch ? (
                <h2>Observation Report</h2>
              ) : (
                <h2>{app.observation} Report</h2>
              )}
            </div>,
            <div css={css({ height: 15, width: '100%' })}></div>,
          ]}
      <div css={clientInfoStyle}>
        <div className="image-block">
          <img src={imageUrl} alt="image" className="image" />
        </div>
        <div className="header-fields">
          <div className="header-field">
            <label>{variableProjectsName.toUpperCase()} NAME: </label>
            <span className="blue-info-text">{headerData.project.name}</span>
            <hr />
          </div>
          <div className="header-field">
            <label>{variableProjectsName.toUpperCase()} ADDRESS:</label>
            <span className="blue-info-text">{headerData.project.address}</span>
            <hr />
          </div>
          <div className="header-field">
            <label>TITLE: </label>
            <span className="blue-info-text">
              {headerData.title ||
                (headerData.categories.length === 1
                  ? headerData.categories[0].name
                  : 'Various')}
            </span>
            <hr />
          </div>
          <div className="header-field">
            <label>PERFORMED BY: </label>
            {headerData.author_name ? (
              <span className="blue-info-text"> {headerData.author_name}</span>
            ) : (
              <span className="blue-info-text">
                {headerData.user.first_name} {headerData.user.last_name}
              </span>
            )}
            <hr />
          </div>
          <div className="header-field">
            <label>PERFORMED:</label>
            <span className="blue-info-text">
              {new Date(
                headerData.date_performed || headerData.date_created,
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            </span>
            <hr />
          </div>
          {headerData.project.contractors ? (
            <div className="header-field">
              <label>CONTRACTOR: </label>
              <span className="blue-info-text">
                {headerData.project.contractor}
              </span>
              <hr />
            </div>
          ) : null}
        </div>
      </div>
    </section>
  )
}

const headerBaseStyle = css({
  width: '100%',
})

function getClientInfoStyle(variableColor) {
  return css({
    width: '100%',
    display: 'inline-block',
    height: '175px',
    '.image-block': {
      verticalAlign: 'top',
      width: '30%',
      height: 175,
      display: 'inline-block',
      textAlign: 'center',
      img: {
        maxWidth: '100%',
        maxHeight: '100%',
      },
    },
    '.header-fields': {
      borderLeftStyle: 'solid',
      borderLeftWidth: '1px',
      borderLeftColor: '#eeeeee',
      width: '70%',
      display: 'inline-block',
      paddingLeft: '1%',
      '.header-field': {
        display: 'inline-block',
        width: '30%',
        marginRight: '3%',
        '@media (max-width: 650px)': { width: '40%' },
        '@media print': { width: '30%' },
        label: {
          display: 'block',
          color: '#A9A9A9 !important',
          fontStyle: 'italic',
          fontWeight: 'bold',
        },
        span: {
          display: 'block',
          fontWeight: 'bold',
          overflowWrap: 'break-word',
        },
        '.orange-info-text': {
          color: 'orange !important',
        },
        '.blue-info-text': {
          color: variableColor + ' !important',
        },
      },
    },
  })
}

function getReportHeader(variableColor) {
  return css({
    width: '100%',
    backgroundColor:
      percentageShadeBlendColor(0.8, variableColor) + ' !important',
    textAlign: 'center',
    padding: 5,
    h2: {
      margin: 0,
    },
  })
}
