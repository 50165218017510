import * as React from 'react'
import { css } from '@emotion/react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import { SubTableDetailEditPopUp } from '@screens/Timecard/Timesheet/components/SubTableDetailEditPopup'
import moment from 'moment'
import { getHoursAndMinutes, useAngularServices } from '@/react/components'
import deleteLogo from '/src/assets/icons/delete-icon.svg'
import { DeleteTimeCardPopUp } from './DeleteTimeCardPopUp'

export const SingleTimeCard = ({
  timeCard,
  costCodes,
  setReload,
  weekDayDate,
  rowData,
  editableWeek,
  activityHeadersRefs,
  setForceUpdateStage,
}) => {
  const [popUp, setPopUp] = React.useState<ReactJSXElement>(null)
  const [deletePopUpId, setDeletePopUpId] = React.useState(0)

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const activities = {}

  if (timeCard.id) {
    timeCard.activities.forEach((activity) => {
      activities[activity.costcode] = activity
    })
  }

  React.useEffect(() => {}, [])

  function setNeededPopup(timePoint, activityData = {}, optionalHeader = null) {
    if (!editableWeek) return
    setPopUp(
      <SubTableDetailEditPopUp
        setPopUp={setPopUp}
        setReload={setReload}
        setForceUpdateStage={setForceUpdateStage}
        timecard={timeCard}
        timePoint={timePoint}
        weekDay={weekDayDate}
        rowData={rowData}
        activityData={activityData}
        optionalHeader={optionalHeader}
      />,
    )
  }

  const baseStyle = css({
    '.delete-icon': {
      cursor: 'pointer',
      height: '10px',
      marginLeft: '3px',
      textAlign: 'center',
    },
    '.active-part': {
      color: editableWeek ? variableColor : '#676a6c',
      cursor: editableWeek ? 'pointer' : 'default',
    },
    '.dark-row': {
      color: 'white',
      cursor: 'default',
      textAlign: 'center',
      width: '100%',
    },
    '.fake-row': {
      textAlign: 'center',
    },
    '.pop-up-holder': {
      height: 0,
      width: 0,
    },
    '.total-time': {
      width: '100%',
    },
  })

  const totalActivityTime = combineActivityTime(activities)

  const handleDeletePopUpVisability = (id: number) => {
    setDeletePopUpId(id)
  }

  if (!activityHeadersRefs) return null
  return (
    <div css={baseStyle}>
      <div className="fake-row dark-row">
        <div className="pop-up-holder">{popUp}</div>
        <div
          onClick={() => {
            if (!timeCard.time_in || timeCard.status === 'n') {
              setNeededPopup('full_day_report')
            }
          }}
          css={
            timeCard.time_in && timeCard.status !== 'n'
              ? css({})
              : css({ cursor: 'pointer', color: 'white' })
          }
          className="total-time"
        >
          {timeCard.hours && timeCard.time_out
            ? getHoursAndMinutes(timeCard.hours)
            : timeCard.time_in && timeCard.status !== 'n'
            ? ''
            : '--'}

          {timeCard.hours && timeCard.time_out && (
            <img
              src={deleteLogo}
              alt="delete"
              className="delete-icon"
              onClick={() => {
                handleDeletePopUpVisability(timeCard.id)
              }}
            />
          )}
        </div>
      </div>
      {deletePopUpId === timeCard.id && (
        <DeleteTimeCardPopUp
          timecardId={timeCard.id}
          handlePopUpVisability={handleDeletePopUpVisability}
          setReload={setReload}
        />
      )}
      <div
        className={
          'fake-row third-darker-row ' +
          (timeCard.time_in && timeCard.status !== 'n' ? 'active-part' : '')
        }
        onClick={() => {
          setNeededPopup('time_in')
        }}
      >
        {timeCard.time_in && timeCard.status !== 'n'
          ? moment.utc(timeCard.time_in).format('hh:mm A')
          : '--'}
      </div>
      <div
        className={
          'fake-row' +
          (timeCard.time_in && timeCard.status !== 'n' ? ' active-part' : '')
        }
        onClick={() => {
          timeCard.time_in && timeCard.status !== 'n'
            ? setNeededPopup('meal_in')
            : null
        }}
      >
        {timeCard.meal_in && timeCard.status !== 'n'
          ? moment.utc(timeCard.meal_in).format('hh:mm A')
          : '--'}
      </div>
      <div
        className={
          'fake-row third-darker-row' + (timeCard.meal_in ? ' active-part' : '')
        }
        onClick={() => {
          timeCard.meal_in && timeCard.status !== 'n'
            ? setNeededPopup('meal_out')
            : null
        }}
      >
        {timeCard.meal_out && timeCard.status !== 'n'
          ? getHoursAndMinutes(
              (moment.utc(timeCard.meal_out).valueOf() -
                moment.utc(timeCard.meal_in).valueOf()) /
                1000,
            )
          : '--'}
      </div>
      <div
        className={
          'fake-row' +
          (timeCard.time_in && timeCard.status !== 'n' ? ' active-part' : '')
        }
        onClick={() => {
          timeCard.time_in && timeCard.status !== 'n'
            ? setNeededPopup('time_out')
            : null
        }}
      >
        {timeCard.time_out && timeCard.status !== 'n'
          ? moment.utc(timeCard.time_out).format('hh:mm A')
          : '--'}
      </div>
      <div className="fake-row dark-row">
        <div className="total-time">
          {totalActivityTime ? getHoursAndMinutes(totalActivityTime) : '--'}
        </div>
      </div>
      {costCodes.map((costCode, idx) => {
        if ((idx + 1) % 2 === 0)
          return (
            <div
              css={css({
                height: activityHeadersRefs[idx]
                  ? activityHeadersRefs[idx].clientHeight + 'px !important'
                  : 60,
                lineHeight: activityHeadersRefs[idx]
                  ? activityHeadersRefs[idx].clientHeight + 'px !important'
                  : 60,
              })}
              key={idx + '-costCode'}
              className={
                'fake-row activity-row third-darker-row' +
                (activities[costCode.id] ? ' active-part' : '')
              }
              onClick={() => {
                setNeededPopup(
                  'activity',
                  activities[costCode.id] || { costCodeFullData: costCode },
                  <div>
                    <div className=" activity-header">
                      <h3>
                        {costCode.project_number} {costCode.project.name}
                      </h3>
                    </div>
                    <div className=" activity-header">
                      <h3>
                        {costCode.costcode_code}{' '}
                        {costCode.name.length >= 30
                          ? costCode.name.substring(0, 30) + '...'
                          : costCode.name}
                      </h3>
                    </div>
                  </div>,
                )
              }}
            >
              {activities[costCode.id]?.duration
                ? getHoursAndMinutes(activities[costCode.id].duration)
                : '--'}
            </div>
          )
        return (
          <div
            css={css({
              height: activityHeadersRefs[idx]
                ? activityHeadersRefs[idx].clientHeight + 'px !important'
                : 60,
              lineHeight: activityHeadersRefs[idx]
                ? activityHeadersRefs[idx].clientHeight + 'px !important'
                : 60,
            })}
            key={idx + '-costCode'}
            className={
              'fake-row activity-row' +
              (activities[costCode.id] ? ' active-part' : '')
            }
            onClick={() => {
              setNeededPopup(
                'activity',
                activities[costCode.id] || { costCodeFullData: costCode },
                <div>
                  <div className="activity-header">
                    <h3>
                      {costCode.project_number} {costCode.project.name}
                    </h3>
                  </div>
                  <div className="activity-header">
                    <h3>
                      {costCode.costcode_code}{' '}
                      {costCode.name.length >= 30
                        ? costCode.name.substring(0, 30) + '...'
                        : costCode.name}
                    </h3>
                  </div>
                </div>,
              )
            }}
          >
            {activities[costCode.id]?.duration
              ? getHoursAndMinutes(activities[costCode.id].duration)
              : '--'}
          </div>
        )
      })}
    </div>
  )
}

function combineActivityTime(activities) {
  let totalTime = 0
  Object.values(activities).forEach((activity) => {
    totalTime += Number(activity.duration)
  })
  return totalTime
}
