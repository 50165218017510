import { css } from '@emotion/react'

export const SectionHeader = ({ title }) => {
  return <div css={baseStyle}>{title}</div>
}

const baseStyle = css({
  background: '#EDF6FA',
  paddingLeft: '10px',
  fontSize: '20px',
  fontWeight: 700,
  fontFamily: [
    'Open Sans',
    'Helvetica Neue',
    'Helvetica',
    'Arial',
    'sans-serif',
  ],
  '@media print': {
    background: '#EDF6FA !important',
  },
})
