import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  color?: string
  onClick?: () => void
  className?: string
}

export const HandsOnModalIcon = ({
  color,
  onClick = () => {},
  className,
}: Props) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      css={[
        css({
          display: 'block',
          color: color,
          width: '16px',
          height: '16px',
        }),
      ]}
      onClick={onClick}
    >
      <path
        d="M4.8 12.8C4.36 12.8 3.9832 12.6432 3.6696 12.3296C3.356 12.016 3.19947 11.6395 3.2 11.2V1.6C3.2 1.16 3.3568 0.783201 3.6704 0.469601C3.984 0.156001 4.36053 -0.000531975 4.8 1.35823e-06H14.4C14.84 1.35823e-06 15.2168 0.156801 15.5304 0.470401C15.844 0.784001 16.0005 1.16053 16 1.6V11.2C16 11.64 15.8432 12.0168 15.5296 12.3304C15.216 12.644 14.8395 12.8005 14.4 12.8H4.8ZM1.6 16C1.16 16 0.783201 15.8432 0.469601 15.5296C0.156001 15.216 -0.000531975 14.8395 1.35823e-06 14.4V3.2H1.6V14.4H12.8V16H1.6Z"
        fill={color}
      />
      <line x1="5" y1="3.5" x2="14.5" y2="3.5" stroke="white" />
      <line x1="5" y1="6.5" x2="14.5" y2="6.5" stroke="white" />
      <line x1="5" y1="9.5" x2="14.5" y2="9.5" stroke="white" />
    </svg>
  )
}
