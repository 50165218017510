import pluralize from 'pluralize'
import moment from 'moment'
import { random, times } from 'lodash'

import {
  validateFileName,
  saveBase64AsFile,
  trimFileName,
  _extends,
  isCorrectFile,
} from '@/utils'
import { UniversalAlert } from '../../../react/components'
import * as React from 'react'

angular
  .module('ccs')
  .controller(
    'FormReportDetailCtrl',
    function (
      $scope,
      $stateParams,
      $state,
      Api,
      app,
      editing,
      $uibModal,
      ngDialog,
      metadataService,
      filterService,
      PreviousState,
      CurrentUser,
      Notification,
      $timeout,
      $log,
    ) {
      $log.debug('FormReportDetailCtrl')

      $scope.titleMobileForm = pluralize.singular(app.app_mobile_form_display)
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? !!JSON.parse(String($stateParams.previousReverse).toLowerCase())
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'date_created'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.backTo = $stateParams.backTo ? $stateParams.backTo : 'reports'
      $scope.metadataService = metadataService
      $scope.filterService = filterService
      $scope.oldReportData = {}
      $scope.getClientCompany = CurrentUser.getClientCompany

      var reportId = $stateParams.report
      $scope.app = app

      $scope.editing = editing
      $scope.disabledBtn = false
      $scope.isOpen = false
      $scope.areFilesInvalid = false
      $scope.loadingDeletedFiles = {}
      $scope.popup = {
        opened: false,
      }

      $scope.retryFiles = {}

      $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.signatureSketchKeys = {
        signature: 'signature',
        sketch: 'sketch',
      }

      $scope.selectedItems = {}

      $scope.deletedPhotos = {}

      $scope.deletedFiles = {}

      $scope.downloadFileInReport = function (idParam) {
        Api.getFileDataForDownload(
          idParam,
          (response) => {
            saveBase64AsFile(
              response.content,
              response.filename,
              response.content_type,
            )
          },
          (error) => {
            Notification.danger(error)
          },
        )
      }

      $scope.setDeletedPhotos = function (obj, key) {
        // key = photos | signatures | sketches
        if (!obj || !key) {
          return false
        }

        if (!$scope.deletedPhotos[key]) {
          $scope.deletedPhotos[key] = []
        }
        $scope.deletedPhotos[key].push(obj)
      }

      $scope.setDeletedFiles = function (obj, key = 'files') {
        // key = files
        if (obj && key) {
          if (!$scope.deletedFiles[key]) {
            $scope.deletedFiles[key] = []
          }
          $scope.deletedFiles[key].push(obj)
        }
      }

      $scope.selectedMultiAnswers = []
      $scope.setSelectedMultiAnswers = function (field) {
        var obj = {
          reportfield: field.answer.id,
          fieldoption: field.answer.selectedOption.id,
          deleted: field.answer.selectedOption.deleted,
          field: field.id,
          set_id: field.answer.set_id,
        }

        if (!$scope.selectedMultiAnswers.length) {
          $scope.selectedMultiAnswers.push(obj)
          return
        }

        var spliced = false

        for (var i = 0; i < $scope.selectedMultiAnswers.length; i++) {
          var option = $scope.selectedMultiAnswers[i]
          if (
            option.field == obj.field &&
            option.set_id == obj.set_id &&
            option.fieldoption == obj.fieldoption
          ) {
            $scope.selectedMultiAnswers.splice(i, 1)
            spliced = true
          }
        }

        if (!spliced) {
          $scope.selectedMultiAnswers.push(obj)
        }

        delete field.answer.selectedOption
      }

      $scope.displayTime = (dateString) => {
        return moment(dateString).format('hh:mm A')
      }

      $scope.displayDate = (dateString) => {
        const date = new Date(dateString)
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      }

      $scope.open = function () {
        $scope.popup.opened = true
      }

      $scope.hstep = 1
      $scope.mstep = 1

      $scope.breadcrumbs = [
        {
          title: app.name,
        },
      ]

      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo
        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      $scope.trimFileName = trimFileName

      const FILE_SIZE_LIMIT = 50 * 1024 * 1024

      $scope.addFile = function (input) {
        if (!input.value || !input.files.length) return
        $scope.areFilesInvalid = true
        for (const [key, file] of Object.entries(input.files)) {
          if (Number(key) < 10) {
            loadAndCheckLogic(file)
          }
        }

        function loadAndCheckLogic(file) {
          if (isCorrectFile(file.type)) {
            var errors = validateFileName(
              file.name.substring(0, file.name.lastIndexOf('.')),
            )
            if (errors.length > 0) {
              Notification.danger(errors.join('\n'))
              setTimeout(function () {
                $scope.$apply()
              }, 0)
            } else if (file.size < FILE_SIZE_LIMIT) {
              let onLoadDeleteId = random(1, 100000)
              let indexToSwitch = renderFiles(
                'files',
                {
                  name: file.name,
                  state: 'uploadingFile',
                  onLoadDeleteId: onLoadDeleteId,
                },
                input.attributes['data-path'].value,
                input.id,
              )
              Api.uploadFile(
                file,
                { onLoadDeleteId },
                (response) => {
                  if (
                    !$scope.loadingDeletedFiles[response.in_loading_delete_id]
                  ) {
                    renderFiles(
                      'files',
                      response,
                      input.attributes['data-path'].value,
                      input.id,
                      indexToSwitch,
                    )
                    input.value = null
                  }
                  if (typeof $scope.close === 'function') $scope.close()
                },
                (error) => {
                  const retryFileId = '' + input.id + file.lastModified
                  $scope.retryFiles[retryFileId] = file
                  Notification.danger(error[0])
                  renderFiles(
                    'files',
                    {
                      name: file.name,
                      state: 'uploadFailed',
                      indexToRetry: indexToSwitch,
                      retryFileId: retryFileId,
                      path: input.attributes['data-path'].value,
                      inputId: input.id,
                      onLoadDeleteId: onLoadDeleteId,
                    },
                    input.attributes['data-path'].value,
                    input.id,
                    indexToSwitch,
                  )
                },
              )
            } else if (file.size > FILE_SIZE_LIMIT) {
              renderFiles(
                'files',
                {
                  name: file.name,
                  state: 'failedDueSize',
                },
                input.attributes['data-path'].value,
                input.id,
              )
              setTimeout(function () {
                $scope.$apply()
              }, 0)
            }
          } else {
            Notification.danger('Invalid file file format.')
            setTimeout(function () {
              $scope.$apply()
            }, 0)
          }
        }
      }

      $scope.retryFileAdd = function (
        file,
        indexToSwitch,
        path,
        inputId,
        retryFileId,
      ) {
        let onLoadDeleteId = random(1, 100000)
        renderFiles(
          'files',
          {
            name: file.name,
            state: 'uploadingFile',
            onLoadDeleteId: onLoadDeleteId,
          },
          path,
          inputId,
          indexToSwitch,
        )
        Api.uploadFile(
          file,
          { onLoadDeleteId },
          (response) => {
            renderFiles('files', response, path, inputId, indexToSwitch)
          },
          (error) => {
            renderFiles(
              'files',
              {
                name: file.name,
                state: 'uploadFailed',
                indexToRetry: indexToSwitch,
                retryFileId: retryFileId,
                path: path,
                inputId: inputId,
              },
              path,
              inputId,
              indexToSwitch,
            )
          },
        )
      }

      function renderFiles(fieldType, resp, pathData, inputId, indexToPlaceIn) {
        var fields = []
        var path = pathData.split('_')
        let indexOfPlacement
        if (indexToPlaceIn) indexOfPlacement = indexToPlaceIn

        if (path[0] === 'headers' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.headers[path[1]].fields
        }
        if (path[0] === 'others' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.others[0].fields
        }

        fields.forEach(function (field) {
          if (path[2] === 'group') {
            if (field.group) {
              field.group.fields.forEach(function (group_field) {
                if (
                  path[4] !== 'answer-options' &&
                  (group_field.id == inputId ||
                    group_field.tempId == inputId) &&
                  group_field.answer.set_id == parseInt(path[3])
                ) {
                  const groupFieldDirection = group_field.answer[fieldType]
                  if (indexToPlaceIn || indexToPlaceIn === 0) {
                    groupFieldDirection[indexToPlaceIn] = resp
                  } else {
                    indexOfPlacement = groupFieldDirection.length
                    groupFieldDirection.push(resp)
                  }
                }
                if (
                  path[4] === 'answer-options' &&
                  group_field.answer.set_id == parseInt(path[3]) &&
                  group_field.type === 'nested_option'
                ) {
                  var nested_field =
                    group_field.answer_options[path[5]].nested_fields[path[7]]
                  const nestedFieldDirection = nested_field.answer[fieldType]
                  if (indexToPlaceIn || indexToPlaceIn === 0) {
                    nestedFieldDirection[indexToPlaceIn] = resp
                  } else {
                    indexOfPlacement = nestedFieldDirection.length
                    nested_field && nestedFieldDirection.push(resp)
                  }
                }
              })
            }
          } else if (
            path[2] === 'answer-options' &&
            field.type === 'nested_option'
          ) {
            if (field.answer_options) {
              var nested_field =
                field.answer_options[path[3]].nested_fields[path[5]]

              if (nested_field?.id == inputId) {
                const nestedFieldDirection = nested_field.answer[fieldType]

                if (indexToPlaceIn || indexToPlaceIn === 0) {
                  nestedFieldDirection[indexToPlaceIn] = resp
                } else {
                  indexOfPlacement = nestedFieldDirection.length
                  nested_field && nestedFieldDirection.push(resp)
                }
              }
            }
          } else {
            if (field.id == inputId) {
              const fieldDirection = field.answer[fieldType]
              if (indexToPlaceIn || indexToPlaceIn === 0) {
                fieldDirection[indexToPlaceIn] = resp
              } else {
                indexOfPlacement = fieldDirection.length
                fieldDirection.push(resp)
              }
            }
          }
        })
        $timeout(() => {
          $scope.areFilesInvalid = checkFilesStatus('one-file')
        }, 500)
        return indexOfPlacement
      }

      function checkFilesStatus(checkedElementClass) {
        return [...document.getElementsByClassName(checkedElementClass)].some(
          (file) => file.dataset.isInState,
        )
      }

      $scope.print = function () {
        window.print()
      }

      $scope.reduceReportTitle = function (form) {
        if (!form) return
        return form.fields.filter(({ type }) => type !== 'report_title')
      }

      $scope.isNotEmpty = false

      $scope.isMobileFormChanged = function (newForm) {
        var count = 0
        newForm.collapsedSections.others.map(function (other) {
          other.fields.map(function (field) {
            if (
              !!isAnswerValid(field.answer) === true &&
              field.type !== 'report_title'
            ) {
              count++
            }
            if (field.type === 'nested_option') {
              field.answer_options.map(function (answer_option) {
                if (answer_option.nested_fields) {
                  answer_option.nested_fields.map(function (nested_field) {
                    if (!!isAnswerValid(nested_field.answer) === true) {
                      count++
                    }
                  })
                }
              })
            }
            if (field.group) {
              if (field.type === 'nested_option') {
                field.answer_options.map(function (answer_option) {
                  if (answer_option.nested_fields) {
                    answer_option.nested_fields.map(function (nested_field) {
                      if (!!isAnswerValid(nested_field.answer) === true) {
                        count++
                      }
                    })
                  }
                })
              }
              field.group.fields.map(function (item) {
                if (!!isAnswerValid(item.answer) === true) {
                  count++
                }
                if (item.type === 'nested_option') {
                  item.answer_options.map(function (answer_option) {
                    if (answer_option.nested_fields) {
                      answer_option.nested_fields.map(function (nested_field) {
                        if (!!isAnswerValid(nested_field.answer) === true) {
                          count++
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        })
        newForm.collapsedSections.headers.map(function (header) {
          header.fields.map(function (field) {
            if (!!isAnswerValid(field.answer) === true) {
              count++
            }
            if (field.type === 'nested_option') {
              field.answer_options.map(function (answer_option) {
                if (answer_option.nested_fields) {
                  answer_option.nested_fields.map(function (nested_field) {
                    if (!!isAnswerValid(nested_field.answer) === true) {
                      count++
                    }
                  })
                }
              })
            }
            if (field.group) {
              if (field.type === 'nested_option') {
                field.answer_options.map(function (answer_option) {
                  if (answer_option.nested_fields) {
                    answer_option.nested_fields.map(function (nested_field) {
                      if (!!isAnswerValid(nested_field.answer) === true) {
                        count++
                      }
                    })
                  }
                })
              }
              field.group.fields.map(function (item) {
                if (!!isAnswerValid(item.answer) === true) {
                  count++
                }
                if (item.type === 'nested_option') {
                  item.answer_options.map(function (answer_option) {
                    if (answer_option.nested_fields) {
                      answer_option.nested_fields.map(function (nested_field) {
                        if (!!isAnswerValid(nested_field.answer) === true) {
                          count++
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        })
        return !!count
      }

      $scope.$watch(
        'report.form',
        function (newValue) {
          if ($scope.report && $scope.report.form) {
            $scope.isNotEmpty =
              $scope.isMobileFormChanged(newValue) ||
              (newValue.fields[0].answer.text &&
                newValue.fields[0].answer.text !== $scope.defaultTitle)
            if ($scope.isNotEmpty) {
              $scope.disabledBtn = false
            }
          }
        },
        true,
      )
      var compareFieldBySetId = function (qOne, qTwo) {
        if (qOne.answer.set_id < qTwo.answer.set_id) {
          return -1
        }
        if (qOne.answer.set_id > qTwo.answer.set_id) {
          return 1
        }
        return 0
      }

      $scope.synchronised

      function getReportDataById() {
        Api.getWithoutDeletedParam(
          'reports',
          {
            id: reportId,
            no_fields: true,
          },
          function (response) {
            const dataToParse = response.results[0]
            let fieldsGet = []

            Api.get(
              'report_fields',
              {
                report: dataToParse.id,
                page: 1,
              },
              async (fieldsPage) => {
                fieldsGet = fieldsPage.results
                const amountOfPages = Math.ceil(
                  fieldsPage.count / fieldsPage.results.length - 1,
                )
                let otherPages = []

                if (amountOfPages > 0) {
                  const promises = times(amountOfPages, (i) => {
                    return Api.get('report_fields', {
                      report: dataToParse.id,
                      page: i + 2,
                    })
                  })
                  otherPages = (await Promise.all(promises))
                    .map(({ data }) => data.results)
                    .flat()
                }
                dataToParse.fields = [...fieldsGet, ...otherPages]
                parseReportData(dataToParse)
                $scope.$apply()
              },
            )
          },
        )
      }

      $scope.orderByMultiOption = function (qOne, qTwo) {
        if (qOne.fieldoption_obj.order < qTwo.fieldoption_obj.order) {
          return -1
        }
        if (qOne.fieldoption_obj.order > qTwo.fieldoption_obj.order) {
          return 1
        }
        return 0
      }

      function parseReportData(resp) {
        $scope.synchronised = resp.synchronised
        $scope.error = !$scope.synchronised
          ? 'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.'
          : null
        $scope.synchronised === false &&
          waitForReportSync(resp.client_object_key)
        var group_sets = {},
          pointer_group_sets
        $scope.origin_group = {}
        var origin_group_id, origin_pointer_group

        var answers = {}

        for (var i = 0; i < resp.fields.length; i++) {
          answers[resp.fields[i].field] = answers[resp.fields[i].field] || {}
          answers[resp.fields[i].field][resp.fields[i].set_id] = resp.fields[i]
        }

        $scope.report = _extends({}, resp, {
          form: _extends({}, resp.form, {
            fields: resp.form.fields.map(function (field, index) {
              field.isCollapsed = false
              field.hideLines = false
              field.limitTo = 0
              field.answer = {
                photos: [],
                files: [],
                signatures: [],
                sketches: [],
                multi_answers: [],
                multi_answers_junction: [],
                set_id: 0,
                field: field.id,
                text: '',
                chosen_answer: null,
                option: {},
                time: null,
                date: null,
              }

              if (
                $state.current.name === 'app.forms.reports_view' &&
                index > 0 &&
                resp.form.fields[index].type === 'header' &&
                resp.form.fields[index - 1].type === 'text'
              ) {
                resp.form.fields[index - 1].fullReportWidth = true
              }

              if (
                $state.current.name === 'app.forms.reports_view' &&
                index > 0 &&
                resp.form.fields[index].type === 'header' &&
                !resp.form.fields[index - 1].group &&
                resp.form.fields[index - 1].type === 'nested_option'
              ) {
                const nestedFields =
                  resp.form.fields[index - 1].answer?.option?.nested_fields

                if (
                  nestedFields?.length &&
                  nestedFields[nestedFields.length - 1].type === 'text'
                ) {
                  nestedFields[nestedFields.length - 1].fullReportWidth = true
                }
              }

              if (
                $state.current.name === 'app.forms.reports_view' &&
                index > 0 &&
                resp.form.fields[index].type === 'header' &&
                resp.form.fields[index - 1].group &&
                resp.form.fields[index - 1].type === 'nested_option'
              ) {
                const nestedFields =
                  answers[resp.form.fields[index - 1]?.id]?.[0]?.option
                    ?.nested_fields

                if (
                  nestedFields?.length &&
                  nestedFields[nestedFields.length - 1].type === 'text'
                ) {
                  nestedFields[nestedFields.length - 1].fullReportWidth = true
                }
              }

              if (field.type === 'report_title') {
                field.required = false
              }
              if (field.type === 'options_multi') {
                $scope.selectedItems[field.answer.set_id] =
                  $scope.selectedItems[field.answer.set_id] || {}
                $scope.selectedItems[field.answer.set_id][field.id] = []
              }

              if (field.group) {
                origin_group_id = field.group
                if (!$scope.origin_group[origin_group_id]) {
                  $scope.origin_group[origin_group_id] = {
                    group: {
                      title: field.group_info.name,
                      fields: [],
                    },
                  }
                  origin_pointer_group = $scope.origin_group[
                    origin_group_id
                  ].group.fields = []
                }
                origin_pointer_group.push(field)
              }

              field.answer.dateOpen = false

              if (field.type === 'nested_option') {
                field.answer_options.map(function (answer_option) {
                  answer_option.isAnswer = false
                  answer_option.isSelected = false

                  if (answer_option.field == field.answer.field) {
                    answer_option.isAnswer = true
                  }

                  if (answer_option.id == field.answer.chosen_answer) {
                    field.answer.option = answer_option
                  }

                  answer_option.nested_fields.map(function (option) {
                    if (option.type === 'options_multi') {
                      $scope.selectedItems[field.answer.set_id] =
                        $scope.selectedItems[field.answer.set_id] || {}
                      $scope.selectedItems[field.answer.set_id][option.id] = []
                    }
                    option.isCollapsed = false
                    option.limitTo = 0
                    option.answer = {
                      photos: [],
                      files: [],
                      signatures: [],
                      sketches: [],
                      multi_answers: [],
                      multi_answers_junction: [],
                      set_id: 0,
                      field: option.id,
                      text: '',
                      chosen_answer: null,
                      option: {},
                      time: null,
                      date: null,
                    }
                    option.answer.dateOpen = false
                  })
                })
              }

              var set_id

              if (field.group) {
                group_id = field.group
                if (!group_sets[group_id]) {
                  group_sets[group_id] = {
                    fields: [],
                  }
                  pointer_group_sets = group_sets[group_id].fields = []
                }
              }

              if (field.group && field.type === 'nested_option') {
                var answersBySetId = answers[field.id]

                if (!answersBySetId) {
                  return field
                }

                for (var setId in answersBySetId) {
                  var answer = answersBySetId[setId]

                  var copy = angular.copy(field)

                  copy.answer_options.map(function (answer_option) {
                    if (!answer_option.nested_fields.length) {
                      answer_option.isAnswer = true
                    }

                    if (answer_option.id == answer.chosen_answer) {
                      copy.answer.chosen_answer = answer.chosen_answer
                      copy.answer.option = answer_option
                      answer.option = answer_option
                    }

                    answer_option.nested_fields.map(function (option) {
                      resp.fields.forEach(function (nested_answer) {
                        if (
                          nested_answer.field === option.id &&
                          nested_answer.set_id === parseInt(setId)
                        ) {
                          option.answer = nested_answer
                          isAnswerValid(nested_answer) &&
                            (answer_option.isAnswer = true)
                          copy.hideLines = true

                          if (option.type === 'time' && option.answer.time) {
                            var currentDate = new Date()
                            var splitTime = option.answer.time.split(':')
                            currentDate.setHours(splitTime[0])
                            currentDate.setMinutes(splitTime[1])
                            option.answer.time = currentDate
                          }
                          if (option.type === 'date' && option.answer.date) {
                            option.answer.date = new Date(option.answer.date)
                          }
                          if (
                            option.type === 'address' &&
                            option.answer.map_snapshot
                          ) {
                            option.answer.map_snapshot_info =
                              option.answer.map_snapshot
                            option.answer.map_snapshot =
                              option.answer.map_snapshot.id
                          }
                          if (
                            option.type === 'options_multi' &&
                            option.answer.multi_answers.length > 0
                          ) {
                            $scope.selectedItems[parseInt(setId)] =
                              $scope.selectedItems[parseInt(setId)] || {}
                            $scope.selectedItems[parseInt(setId)][option.id] =
                              []
                            option.answer_options.forEach(function (item) {
                              item.isSelected = false
                              option.answer.multi_answers_junction
                                .sort($scope.orderByMultiOption)
                                .forEach(function (selectedItem) {
                                  if (
                                    item.name ===
                                      selectedItem.fieldoption_obj.name &&
                                    !selectedItem.deleted
                                  ) {
                                    item.isSelected = true
                                    $scope.selectedItems[parseInt(setId)][
                                      option.id
                                    ].push(item)
                                  }
                                })
                            })
                          }
                        }
                      })
                    })
                    if (
                      $state.current.name === 'app.forms.reports_view' &&
                      answer_option.nested_fields.length
                    ) {
                      const fakeField = {
                        answer_options: [],
                        answer: {
                          files: [],
                          multi_answers: [],
                          multi_answers_junction: [],
                          photos: [],
                          signatures: [],
                          sketches: [],
                        },
                        type: 'fake_field',
                      }
                      answer_option.nested_fields.unshift(fakeField)
                    }
                  })

                  pointer_group_sets.push(copy)
                  pointer_group_sets[pointer_group_sets.length - 1].answer =
                    answer
                  pointer_group_sets[pointer_group_sets.length - 1].set_id =
                    answer.set_id

                  pointer_group_sets =
                    pointer_group_sets.sort(compareFieldBySetId)
                }
              }

              resp.fields.forEach(function (answer) {
                if (field.group) {
                  if (
                    answer.field === field.id &&
                    field.type !== 'nested_option'
                  ) {
                    field.hideLines = true
                    var field_group = angular.copy(field)
                    pointer_group_sets.push(field_group)
                    pointer_group_sets[pointer_group_sets.length - 1].answer =
                      answer
                    pointer_group_sets[pointer_group_sets.length - 1].set_id =
                      answer.set_id
                    pointer_group_sets[
                      pointer_group_sets.length - 1
                    ].answer.set_id = answer.set_id
                    if (
                      pointer_group_sets[pointer_group_sets.length - 1].type ===
                        'time' &&
                      pointer_group_sets[pointer_group_sets.length - 1].answer
                        .time
                    ) {
                      var currentDate = new Date()
                      var splitTime =
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.time.split(':')
                      currentDate.setHours(splitTime[0])
                      currentDate.setMinutes(splitTime[1])
                      pointer_group_sets[
                        pointer_group_sets.length - 1
                      ].answer.time = currentDate
                    }
                    if (
                      pointer_group_sets[pointer_group_sets.length - 1].type ===
                        'date' &&
                      pointer_group_sets[pointer_group_sets.length - 1].answer
                        .date
                    ) {
                      pointer_group_sets[
                        pointer_group_sets.length - 1
                      ].answer.date = new Date(
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.date,
                      )
                    }
                    if (
                      pointer_group_sets[pointer_group_sets.length - 1].type ===
                        'address' &&
                      pointer_group_sets[pointer_group_sets.length - 1].answer
                        .map_snapshot
                    ) {
                      pointer_group_sets[
                        pointer_group_sets.length - 1
                      ].answer.map_snapshot_info =
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.map_snapshot
                      pointer_group_sets[
                        pointer_group_sets.length - 1
                      ].answer.map_snapshot =
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.map_snapshot.id
                    }
                    if (
                      pointer_group_sets[pointer_group_sets.length - 1].type ===
                        'options_multi' &&
                      pointer_group_sets[pointer_group_sets.length - 1].answer
                        .multi_answers.length > 0
                    ) {
                      $scope.selectedItems[
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.set_id
                      ] =
                        $scope.selectedItems[
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .answer.set_id
                        ] || {}
                      $scope.selectedItems[
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.set_id
                      ][pointer_group_sets[pointer_group_sets.length - 1].id] =
                        []
                      pointer_group_sets[
                        pointer_group_sets.length - 1
                      ].answer_options.forEach(function (item) {
                        item.isSelected = false
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.multi_answers_junction
                          .sort($scope.orderByMultiOption)
                          .forEach(function (selectedItem) {
                            if (
                              item.name === selectedItem.fieldoption_obj.name &&
                              !selectedItem.deleted
                            ) {
                              item.isSelected = true
                              $scope.selectedItems[
                                pointer_group_sets[
                                  pointer_group_sets.length - 1
                                ].answer.set_id
                              ][
                                pointer_group_sets[
                                  pointer_group_sets.length - 1
                                ].id
                              ].push(item)
                            }
                          })
                      })
                    }
                    set_id = answer.set_id
                    pointer_group_sets =
                      pointer_group_sets.sort(compareFieldBySetId)
                  }

                  return field
                }

                if (field.type !== 'nested_option' && !field.group) {
                  if (answer.field === field.id) {
                    field.answer = answer
                    field.hideLines = true
                    if (field.type === 'time' && field.answer.time) {
                      var currentDate = new Date()
                      var splitTime = field.answer.time.split(':')
                      currentDate.setHours(splitTime[0])
                      currentDate.setMinutes(splitTime[1])
                      field.answer.time = currentDate
                    }
                    if (field.type === 'date' && field.answer.date) {
                      field.answer.date = new Date(field.answer.date)
                    }
                    if (field.type === 'address' && field.answer.map_snapshot) {
                      field.answer.map_snapshot_info = field.answer.map_snapshot
                      field.answer.map_snapshot = field.answer.map_snapshot.id
                    }
                    if (
                      field.type === 'options_multi' &&
                      field.answer.multi_answers.length > 0
                    ) {
                      $scope.selectedItems[field.answer.set_id] =
                        $scope.selectedItems[field.answer.set_id] || {}
                      $scope.selectedItems[field.answer.set_id][field.id] = []
                      field.answer_options.forEach(function (item) {
                        item.isSelected = false
                        field.answer.multi_answers_junction
                          .sort($scope.orderByMultiOption)
                          .forEach(function (selectedItem) {
                            if (
                              item.name === selectedItem.fieldoption_obj.name &&
                              !selectedItem.deleted
                            ) {
                              item.isSelected = true
                              $scope.selectedItems[field.answer.set_id][
                                field.id
                              ].push(item)
                            }
                          })
                      })
                    }
                  }

                  return field
                }

                if (field.type === 'nested_option' && !field.group) {
                  field.answer_options.map(function (answer_option) {
                    if (!answer_option.nested_fields.length) {
                      resp.fields.forEach(function (answer_for_nested_option) {
                        if (answer_for_nested_option.field === field.id) {
                          answer_option.isAnswer = true
                          field.answer = answer_for_nested_option
                        }
                      })
                    }

                    if (answer_option.id == field.answer.chosen_answer) {
                      field.answer.option = answer_option
                    }

                    answer_option.nested_fields.map(function (option) {
                      resp.fields.forEach(function (nested_answer) {
                        if (nested_answer.field === field.id) {
                          field.answer = nested_answer
                        }
                        if (nested_answer.field === option.id) {
                          option.answer = nested_answer
                          field.hideLines = true
                          isAnswerValid(nested_answer) &&
                            (answer_option.isAnswer = true)
                          if (option.type === 'time' && option.answer.time) {
                            if (!option.answer.currentDate) {
                              var currentDate = new Date()
                              var splitTime = option.answer.time.split(':')
                              currentDate.setHours(splitTime[0])
                              currentDate.setMinutes(splitTime[1])
                              option.answer.time = currentDate
                              option.answer.currentDate = true
                            }
                          }
                          if (option.type === 'date' && option.answer.date) {
                            option.answer.date = new Date(option.answer.date)
                          }
                          if (
                            option.type === 'address' &&
                            option.answer.map_snapshot
                          ) {
                            option.answer.map_snapshot_info =
                              option.answer.map_snapshot
                            option.answer.map_snapshot =
                              option.answer.map_snapshot.id
                          }
                          if (
                            option.type === 'options_multi' &&
                            option.answer.multi_answers.length > 0
                          ) {
                            $scope.selectedItems[field.answer.set_id][
                              option.id
                            ] = []
                            option.answer_options.forEach(function (item) {
                              item.isSelected = false
                              option.answer.multi_answers_junction
                                .sort($scope.orderByMultiOption)
                                .forEach(function (selectedItem) {
                                  if (
                                    item.name ===
                                      selectedItem.fieldoption_obj.name &&
                                    !selectedItem.deleted
                                  ) {
                                    item.isSelected = true
                                    $scope.selectedItems[field.answer.set_id][
                                      option.id
                                    ].push(item)
                                  }
                                })
                            })
                          }
                        }
                      })
                    })
                  })
                }
              })

              return field
            }),
          }),
        })

        var group_with_all_sets = {}

        for (var k in group_sets) {
          if (
            $state.current.name === 'app.forms.reports_view' &&
            $scope.origin_group[k].group.fields
          ) {
            const groupFields = $scope.origin_group[k].group.fields
            let countGroupFields = groupFields.length

            for (let i = 0; i < groupFields.length; i++) {
              if (
                groupFields[i].type === 'photos' ||
                groupFields[i].type === 'signatures' ||
                groupFields[i].type === 'sketches'
              ) {
                countGroupFields++
              }
            }
            const fakeField = {
              answer_options: [],
              answer: {
                files: [],
                multi_answers: [],
                multi_answers_junction: [],
                photos: [],
                signatures: [],
                sketches: [],
              },
              type: 'fake_field',
            }
            if (countGroupFields % 2 !== 0) {
              $scope.origin_group[k].group.fields.push(fakeField)
            }
          }
          var current_set_id = 0
          if (!group_with_all_sets[k]) {
            group_with_all_sets[k] = {
              fields: [],
            }
            pointer_group_sets = group_with_all_sets[k].fields = angular.copy(
              $scope.origin_group[k].group.fields,
            )
          }

          if (!group_sets[k].fields.length > 0) {
            Array.prototype.push.apply(
              group_with_all_sets[k].fields,
              group_sets[k].fields,
            )
          } else {
            group_sets[k].fields.map(function (field) {
              if (current_set_id !== field.answer.set_id) {
                var copied_origin_group = angular.copy(
                  $scope.origin_group[k].group.fields,
                )
                copied_origin_group.map(function (copy) {
                  copy.set_id = current_set_id + 1
                  copy.answer.set_id = current_set_id + 1
                })
                Array.prototype.push.apply(
                  group_with_all_sets[k].fields,
                  copied_origin_group,
                )
              }
              current_set_id = field.answer.set_id
            })
          }
        }

        $scope.report.form.collapsedSections = {
          headers: [],
          others: [],
        }
        $scope.report.form.collapsedSections.others[0] = {}
        $scope.report.form.collapsedSections.others[0].fields = []

        var pointer = ($scope.report.form.collapsedSections.others[0].fields =
          [])
        var path = ''

        var group_obj = {}
        var pointer_group
        var group_id = null
        for (var i = 0; i < $scope.report.form.fields.length; i++) {
          var field = $scope.report.form.fields[i]
          if (
            $scope.report.form.fields[i] &&
            $scope.report.form.fields[i].type &&
            $scope.report.form.fields[i].type == 'header'
          ) {
            $scope.report.form.collapsedSections.headers.push(
              $scope.report.form.fields[i],
            )
            var index = $scope.report.form.collapsedSections.headers.length - 1
            pointer = $scope.report.form.collapsedSections.headers[
              index
            ].fields = []
            path = 'headers_' + index
            continue
          }

          if (field.group) {
            group_id = field.group
            if (!group_obj[group_id]) {
              pointer.push(field)
              group_obj[group_id] = {
                group: {
                  title: field.group_info.name,
                  fields: [],
                },
              }
              pointer_group = group_obj[group_id].group.fields = []
            }
            if (group_sets[group_id]) {
              group_with_all_sets[group_id].fields.map(function (item) {
                for (var k = 0; k < group_sets[group_id].fields.length; k++) {
                  if (
                    item.answer.field === group_sets[group_id].fields[k].id &&
                    item.answer.set_id ===
                      group_sets[group_id].fields[k].answer.set_id
                  ) {
                    item.answer = group_sets[group_id].fields[k].answer
                    item.hideLines = group_sets[group_id].fields[k].hideLines
                    item.answer_options.length > 0 &&
                      (item.answer_options =
                        group_sets[group_id].fields[k].answer_options)
                  }
                }
              })
              group_obj[group_id] = {
                group: {
                  title: field.group_info.name,
                  fields: group_with_all_sets[group_id].fields,
                },
              }
            } else {
              pointer_group.push(field)
            }
            group_obj[group_id].group.fields.map(function (field) {
              field.path = path
                ? path + '_group_' + field.answer.set_id
                : 'others_' + i + '_group_' + field.answer.set_id
              if (field.type === 'nested_option') {
                field.answer_options.map(function (answer_option, i) {
                  answer_option.path = field.path + '_answer-options_' + i
                  answer_option.nested_fields.map(function (nested_field, i) {
                    nested_field.path =
                      answer_option.path + '_nested-fields_' + i
                  })
                })
              }
            })
            pointer[pointer.length - 1] = group_obj[group_id]
          } else {
            pointer.push(field)
          }

          pointer[pointer.length - 1].path = path || 'others_' + i

          if (field.type === 'nested_option' && !field.group) {
            field.answer_options.map(function (answer_option, i) {
              var group_str = answer_option.group
                ? '_group_' + answer_option.answer.set_id
                : ''
              answer_option.path =
                pointer[pointer.length - 1].path +
                '_answer-options_' +
                i +
                group_str
              answer_option.nested_fields.map(function (nested_field, i) {
                nested_field.path =
                  answer_option.path + '_nested-fields_' + i + group_str
              })
            })
          }
        }
        $scope.defaultTitle =
          $scope.report.form.name +
          ' - ' +
          moment($scope.report.date_created).format('MM/DD/YYYY')
        $scope.report.form.fields[0].answer.text = $scope.report.form.fields[0]
          .answer.text
          ? $scope.report.form.fields[0].answer.text
          : $scope.defaultTitle
      }

      function isReportSynchronised(client_object_key) {
        if (
          $state.current.name === 'app.forms.reports_edit' ||
          $state.current.name === 'app.forms.reports_view'
        ) {
          Api.get(
            'shares/report/' + client_object_key + '/synchronized',
            {},
            function (resp) {
              $scope.synchronised = resp.synchronised
              $scope.synchronised && ($scope.error = null)
            },
          )
        }
      }

      function getReport() {
        if ($state.current.name === 'app.forms.reports_create') {
          const newReport = JSON.parse(sessionStorage.getItem('report'))
          parseReportData(newReport)
        } else {
          getReportDataById()
          sessionStorage.removeItem('report')
        }
      }

      getReport()

      function waitForReportSync(client_object_key) {
        var interval = setInterval(function () {
          isReportSynchronised(client_object_key)
          if ($scope.synchronised) {
            getReportDataById()
            clearInterval(interval)
          }
        }, 1000)
      }

      Api.GeneralSettings.get({}, function (resp) {
        if (resp.results.length) {
          $scope.settings = resp.results[0]
        }
      })

      function formatDate(date) {
        if (!date) return null
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      function isAnswerValid(answer) {
        if (!answer) return
        return (
          answer.text ||
          answer.date ||
          answer.time ||
          answer.signatures.length > 0 ||
          answer.sketches.length > 0 ||
          answer.photos.length > 0 ||
          answer.files.length > 0 ||
          answer.multi_answers.length > 0
        )
      }

      $scope.goBack = function () {
        if (
          PreviousState.Name == '' ||
          PreviousState.Name == 'app.forms.reports'
        ) {
          $state.go(
            'app.forms.reports',
            {
              app: app.id,
              pageNumber: $scope.previousPageNumber,
              reverse: $scope.previousReverse,
              order: $scope.previousOrder,
              search: $scope.previousSearch,
            },
            { reload: true },
          )
        } else {
          if (PreviousState.Name == 'app.forms.reports_export_list') {
            $state.go(
              'app.forms.reports_export_list',
              {
                app: app.id,
                pageNumber: $scope.previousPageNumber,
                reverse: $scope.previousReverse,
                order: $scope.previousOrder,
                search: $scope.previousSearch,
                formId: $stateParams.formId,
                startDate: formatDate($stateParams.startDate),
                endDate: formatDate($stateParams.endDate),
              },
              { reload: true },
            )
          } else if (PreviousState.Name == 'app.forms.deleted_reports') {
            //This is for deleted reports
            $state.go(
              'app.forms.deleted_reports',
              {
                app: app.id,
                pageNumber: $scope.previousPageNumber,
                reverse: $scope.previousReverse,
                order: $scope.previousOrder,
                search: $scope.previousSearch,
              },
              { reload: true },
            )
          } else if ('app.forms.draft_reports_list') {
            //This is for draft reports
            $state.go(
              'app.forms.draft_reports_list',
              {
                app: app.id,
                pageNumber: $scope.previousPageNumber,
                reverse: $scope.previousReverse,
                order: $scope.previousOrder,
                search: $scope.previousSearch,
              },
              { reload: true },
            )
          }
        }
        sessionStorage.removeItem('report')
      }

      $scope.showDeleteAlert = function (
        title,
        message,
        deleteFileData,
        fieldData,
        isExternal,
      ) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content download-alert-width',
          templateUrl: 'app/views/alert_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.title = title
            $scope.message = message
            $scope.textAlignment = 'center'
            $scope.dualOption = true
            $scope.deleteFileData = deleteFileData
            $scope.fieldData = fieldData
            $scope.isExternalReport = isExternal
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }

      $scope.firefoxPrintCheck = function () {
        const isFirefox = typeof InstallTrigger !== 'undefined'
        if (!isFirefox) {
          print()
        } else {
          $scope.showPrintAlert()
        }
      }

      $scope.showPrintAlert = function () {
        $scope.modal_title =
          'Print to PDF on Firefox may result in large files sizes.'
        $scope.confirm_only = true
        let modalInstance = $uibModal.open({
          templateUrl: 'app/views/common/flexible_modal.html',
          scope: $scope,
          bindToController: true,
          size: 'custom-size-form-reports',
          /** @ngInject */
          controller: function ($scope) {
            $scope.dismiss = function () {
              modalInstance.close()
            }
            $scope.save = function () {
              print()
            }
          },
        })
      }
    },
  )
