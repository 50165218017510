export const ColoredArrowUp = ({
  variableColor,
  onClick,
  height = '8px',
  width = '11px',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M10.6027 8L11.6436 6.77978L5.82183 0L0.000101089 6.78663L1.04096 8L5.82183 2.42674L10.6027 8Z"
        fill={variableColor}
      />
    </svg>
  )
}
