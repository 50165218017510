import * as React from 'react'
import { TextInput, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { getTranslatedString } from '@/utils'

export const ContactFields = ({ updateContact, possibleStartingData }) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [firstNameError, setFirstNameError] = React.useState(false)
  const [lastNameError, setLastNameError] = React.useState(false)
  const [companyNameError, setCompanyNameError] = React.useState(false)
  const [emailError, setEmailError] = React.useState(false)

  React.useEffect(() => {
    updateContact(
      possibleStartingData.length
        ? possibleStartingData[0].external_contact.first_name
        : '',
      possibleStartingData.length
        ? possibleStartingData[0].external_contact.last_name
        : '',
      possibleStartingData.length
        ? possibleStartingData[0].external_contact.company_name
        : '',
      possibleStartingData.length
        ? possibleStartingData[0].external_contact.email
        : '',
    )
  }, [])

  React.useEffect(() => {
    if (!possibleStartingData[0]?.external_contact?.first_name) {
      setFirstNameError(true)
    }
    if (!possibleStartingData[0]?.external_contact?.last_name) {
      setLastNameError(true)
    }
    if (!possibleStartingData[0]?.external_contact?.company_name) {
      setCompanyNameError(true)
    }
    if (!possibleStartingData[0]?.external_contact?.email) {
      setEmailError(true)
    }
  }, [])

  return (
    <section css={baseStyle}>
      <label>{getTranslatedString('Required Information:')}</label>
      <div className="first-row">
        <TextInput
          waitTime={200}
          width={'100%'}
          placeholder={getTranslatedString('First name')}
          defaultValue={
            possibleStartingData.length
              ? possibleStartingData[0].external_contact.first_name
              : ''
          }
          valueCallback={(value) => {
            updateContact(value, null, null, null)
            setFirstNameError(!value)
          }}
          error={firstNameError}
          allowEmptyStart={false}
        />
        <TextInput
          waitTime={200}
          width={'100%'}
          placeholder={getTranslatedString('Last name')}
          defaultValue={
            possibleStartingData.length
              ? possibleStartingData[0].external_contact.last_name
              : ''
          }
          valueCallback={(value) => {
            updateContact(null, value, null, null)
            setLastNameError(!value)
          }}
          error={lastNameError}
          allowEmptyStart={false}
        />
        <TextInput
          waitTime={200}
          width={'100%'}
          placeholder={getTranslatedString('Email')}
          defaultValue={
            possibleStartingData.length
              ? possibleStartingData[0].external_contact.email
              : ''
          }
          valueCallback={(value) => {
            updateContact(null, null, null, value)
            setEmailError(!value)
          }}
          error={emailError}
          allowEmptyStart={false}
        />
      </div>
      <div className="second-row">
        <TextInput
          width={'100%'}
          waitTime={200}
          placeholder={getTranslatedString('Company name')}
          defaultValue={
            possibleStartingData.length
              ? possibleStartingData[0].external_contact.company_name
              : ''
          }
          valueCallback={(value) => {
            updateContact(null, null, value, null)
            setCompanyNameError(!value)
          }}
          error={companyNameError}
          allowEmptyStart={false}
        />
      </div>
    </section>
  )
}

const baseStyle = css({
  '.first-row': {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    div: {
      width: '30%',
      marginBottom: 30,
      '@media (max-width: 650px)': { width: '100%' },
    },
  },
  '.second-row': {
    width: '100%',
    marginBottom: 30,
  },
})
