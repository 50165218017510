import { adminUser } from '@screens/Admin/utils/commomTypes'
import { css } from '@emotion/react'
import { UISref } from '@components/UISref'
import { CustomCheckbox, TableHighlight } from '@/react/components'
import { BinDeleteIcon } from '@/react/componentAssets'
import React from 'react'

type Props = {
  user: adminUser
  variableColor: string
  idx: number
  search: string
}
export const AdminTableRow = ({ user, variableColor, idx, search }: Props) => {
  return (
    <tr
      css={css({
        backgroundColor: Math.abs(idx % 2) == 1 ? 'none' : 'rgb(242, 243, 245)',
        '.username__td': {
          width: '30%',
        },
        '.fullname__td': {
          width: '30%',
        },
        '.email__td': {
          width: '30%',
        },
      })}
    >
      <td className="username__td">
        <UISref to={'true_admin.admin_edit'} params={{ id: user.id }}>
          <a>
            <TableHighlight text={user.username} width={250} search={search} />
          </a>
        </UISref>
      </td>
      <td className="fullname__td">
        <TableHighlight
          text={user.first_name + user.last_name}
          search={search}
          width={300}
        />
      </td>
      <td className="email__td">{user.email}</td>
      <td className="active__td">
        <CustomCheckbox isDefaultChecked={false} reversedColor={true} />
      </td>
      <td className="icon__td">
        <BinDeleteIcon color={variableColor} onClick={() => {}} />
      </td>
    </tr>
  )
}
