angular.module('ccs').filter('highlight', function ($sce) {
  return function (text, phrase) {
    if (phrase && (text.toLowerCase()).includes(phrase.toLowerCase())) {
      let pattern = phrase.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')
      text = text.replace(
        new RegExp('(' + pattern + ')', 'gi'),
        '<span class="highlighted">$1</span>',
      )
    }
    return $sce.trustAsHtml(text)
  }
})
