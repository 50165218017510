import { useAngularServices } from '@/react/components'
import React, { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { CreateEditQuestionComponent } from '../CreateEditQuestionPage/CreateEditQuestionComponent'

export const ObservationEditQuestion = () => {
  const { stateService } = useRouter()
  const [startingData, setStartingData] = useState({})
  const { Api, CurrentUser } = useAngularServices()

  useEffect(() => {
    const getStartingData = async () => {
      const { data: categoriesRes } = await Api.get('categories', {
        application: stateService.params.app,
        client: CurrentUser.getClientId(),
        order: 'name',
        is_active: true,
        custom: true,
        page_size: 250,
      })

      const { data } = await Api.get(
        `questions/${stateService.params.question}`,
        {},
      )
      const selectedCategory = categoriesRes.results.find(
        (c) => c.id === data.category,
      )

      setStartingData({
        ...data,
        category: selectedCategory.name,
        editorContent: data?.name,
      })
    }
    getStartingData()
  }, [])

  const handleQuestionAdd = async (selectedCategory, editorContent) => {
    await Api.patch(`questions/${startingData.id}`, {
      category: selectedCategory?.id,
      client: CurrentUser.getClientId(),
      date_created: startingData?.date_created,
      date_updated: startingData?.date_updated,
      deleted: false,
      id: startingData?.id,
      is_active: true,
      name: editorContent,
      order: 0,
      source: 'internal',
    })

    stateService.go('app.questions.my')
  }

  return (
    <CreateEditQuestionComponent
      handleQuestionAdd={handleQuestionAdd}
      startingData={startingData}
    />
  )
}
