import { ReactWrapper } from '@components'

import { SDSCreate } from './SDSCreate'
import { SDSList } from './SDSList'
import { SDSArchive } from './SDSArchive'
import { SDSAssignProject } from './SDSAssignProject'

export const SDSCreateComponent = ReactWrapper(SDSCreate, 'sdsCreate')
export const SDSListComponent = ReactWrapper(SDSList, 'sdsList')
export const SDSArchiveComponent = ReactWrapper(SDSArchive, 'sdsArchive')
export const SDSAssignProjectComponent = ReactWrapper(
  SDSAssignProject,
  'sdsAssignProject',
)
