angular
  .module('ccs')
  .controller(
    'ShareLessonCtrl',
    function (
      $scope,
      Api,
      $stateParams,
      Notification,
      $log,
      VariableThemeColor,
    ) {
      $log.debug('ShareLessonCtrl')

      const clientObjectKey = $stateParams.key

      function isLessonSynchronised(client_object_key) {
        // not working --> shares/lesson/${client_object_key}/synchronized
        Api.get(`shares/lesson/${client_object_key}`, {}, function (resp) {
          $scope.synchronised = resp.synchronised
          $scope.synchronised && ($scope.error = null)
        })
      }

      function waitForLessonSync(client_object_key) {
        var interval = setInterval(function () {
          isLessonSynchronised(client_object_key)
          if ($scope.synchronised) {
            getSharesLessonDataById()
            clearInterval(interval)
          }
        }, 1000)
      }

      function getSharesLessonDataById() {
        Api.get(
          `shares/lesson/${clientObjectKey}`,
          {},
          (resp) => {
            VariableThemeColor.mainColorSet(
              resp.client.general_settings.colors.secondary_color,
              true,
            )

            $scope.lesson = resp

            $scope.synchronised = resp.synchronised
            $scope.error = !$scope.synchronised
              ? 'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.'
              : null
            $scope.synchronised === false &&
              waitForLessonSync(resp.client_object_key)
          },
          (err) => {
            Notification.error(
              'Sorry, but this lesson has not finished processing. Please try again later.',
            )
          },
        )
      }

      getSharesLessonDataById()
    },
  )
