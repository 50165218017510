import React, { useState } from 'react'
import { css } from '@emotion/react'
import { checkIsSafari } from '@/utils/checkIsSafari'
import Highlighter from 'react-highlight-words'

export const TableHighlight = ({
  text,
  search = '',
  width = 200,
}: {
  text: string
  search?: string
  width?: string | number
}) => {
  const [baseWidth, setBaseWidth] = useState<number>(200)
  const [textWidth, setTextWidth] = useState<number>(200)

  return (
    <div
      css={baseStyle(width)}
      ref={(el) => {
        if (!el) return
        setBaseWidth(el.getBoundingClientRect().width)
      }}
    >
      {textWidth < baseWidth || checkIsSafari() ? null : (
        <span className="highlight__holder">
          <div className="highlight">
            <Highlighter
              textToHighlight={text}
              searchWords={[search]}
              autoEscape={true}
            />
          </div>
        </span>
      )}
      <div className="text__holder">
        <span
          ref={(el) => {
            if (!el) return
            setTextWidth(el.getBoundingClientRect().width)
          }}
        >
          <Highlighter
            textToHighlight={text}
            searchWords={[search]}
            autoEscape={true}
          />
        </span>
      </div>
    </div>
  )
}

const baseStyle = (width: string | number) =>
  css({
    '.text__holder': {
      position: 'relative',
      maxHeight: '40px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: '10xp',
      width,
      maxWidth: width,
    },
    '.highlight__holder': {
      height: 0,
      width: 0,
      position: 'relative',
      '.highlight': {
        display: 'none',
        position: 'absolute',
        marginTop: '30px',
        zIndex: 1,
        border: '1px solid #D3D3D3',
        boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
        background: 'white',
        left: '10px',
        padding: '4px 11px',
        maxWidth: '600px',
      },
    },
    ':hover': {
      '.highlight': {
        display: 'block',
      },
    },
  })
