angular.module('ccs').directive('pageheader', pageheader)

function pageheader() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/pageheader.html',
    scope: {
      title: '@',
      breadcrumbs: '=',
      hideSingleBreadcrumb: '@',
    },
  }
}
