import * as React from 'react'
import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'
import { useAngularServices, BesideInputButton } from '@/react/components'
import check from '/src/assets/icons/Check.svg'

type Props = {
  initialText: string
  id: number
  handleClose: () => void
}

export const EditForPartyObserved: React.FC<Props> = ({
  initialText,
  id,
  handleClose,
}: Props) => {
  const { Api } = useAngularServices()

  const [uneditedValue, setUneditedValue] = React.useState<string>(initialText)
  const [value, setValue] = React.useState<string>(initialText)
  const [initialTextValue, setInitialTextValue] =
    React.useState<string>(initialText)

  async function submitEdit() {
    if (!value.length || uneditedValue === value) return
    await Api.patch('answer_party_observed/' + id, { name: value })
    setInitialTextValue(value)
    setUneditedValue(value)
    handleClose()
  }

  return (
    <div css={editStyle}>
      <input
        placeholder="Enter party observed"
        defaultValue={initialTextValue}
        type="text"
        maxLength={255}
        onChange={(value) => {
          setValue(value.target.value)
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            submitEdit()
          }
        }}
      />
      <BesideInputButton deleteButton={true} functionToTrigger={handleClose} />
      <BesideInputButton
        deleteButton={false}
        possibleText={
          <img
            css={css({ height: '100%', width: '100%' })}
            src={check}
            alt="Save"
          />
        }
        functionToTrigger={submitEdit}
        fontSize={'20px'}
        width={'auto'}
        additionalStyle={css({
          padding: '6px',
          color: 'white',
          width: '34px',
        })}
        disabled={!value.length || uneditedValue === value}
      />
    </div>
  )
}

const editStyle = css({
  display: 'flex',
  input: {
    height: '34px',
    width: '80%',
    ...TextInputStyle,
  },
})
