import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  color?: string
  onClick?: () => void
  className?: string
}

export const QuizIcon = ({ color, onClick = () => {}, className }: Props) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      css={[
        css({
          display: 'block',
          color: color,
          width: '16px',
          height: '16px',
        }),
      ]}
      onClick={onClick}
    >
      <path
        d="M9.6 10.4C9.82667 10.4 10.0235 10.3165 10.1904 10.1496C10.3573 9.98267 10.4405 9.78613 10.44 9.56C10.44 9.33333 10.3565 9.1368 10.1896 8.9704C10.0227 8.804 9.82613 8.72053 9.6 8.72C9.37333 8.72 9.17653 8.80347 9.0096 8.9704C8.84267 9.13733 8.75947 9.33387 8.76 9.56C8.76 9.78667 8.84347 9.98347 9.0104 10.1504C9.17733 10.3173 9.37387 10.4005 9.6 10.4ZM9 7.84H10.2C10.2 7.45333 10.24 7.16987 10.32 6.9896C10.4 6.80933 10.5867 6.5728 10.88 6.28C11.28 5.88 11.5467 5.5568 11.68 5.3104C11.8133 5.064 11.88 4.77387 11.88 4.44C11.88 3.84 11.6699 3.35013 11.2496 2.9704C10.8293 2.59067 10.2795 2.40053 9.6 2.4C9.05333 2.4 8.57653 2.55333 8.1696 2.86C7.76267 3.16667 7.47947 3.57333 7.32 4.08L8.4 4.52C8.52 4.18667 8.68347 3.93653 8.8904 3.7696C9.09733 3.60267 9.33387 3.51947 9.6 3.52C9.92 3.52 10.18 3.61013 10.38 3.7904C10.58 3.97067 10.68 4.21387 10.68 4.52C10.68 4.70667 10.6267 4.88347 10.52 5.0504C10.4133 5.21733 10.2267 5.4272 9.96 5.68C9.52 6.06667 9.25013 6.36987 9.1504 6.5896C9.05067 6.80933 9.00053 7.22613 9 7.84ZM4.8 12.8C4.36 12.8 3.9832 12.6432 3.6696 12.3296C3.356 12.016 3.19947 11.6395 3.2 11.2V1.6C3.2 1.16 3.3568 0.783201 3.6704 0.469601C3.984 0.156001 4.36053 -0.000531975 4.8 1.35823e-06H14.4C14.84 1.35823e-06 15.2168 0.156801 15.5304 0.470401C15.844 0.784001 16.0005 1.16053 16 1.6V11.2C16 11.64 15.8432 12.0168 15.5296 12.3304C15.216 12.644 14.8395 12.8005 14.4 12.8H4.8ZM1.6 16C1.16 16 0.783201 15.8432 0.469601 15.5296C0.156001 15.216 -0.000531975 14.8395 1.35823e-06 14.4V3.2H1.6V14.4H12.8V16H1.6Z"
        style={{ fill: color }}
      />
    </svg>
  )
}
