import { HorizontalNavigation } from '@screens/components'
import { css } from '@emotion/react'
import { flatMap } from 'lodash'

import viewIcon from '/src/assets/icons/sds-connected-icons/view-small.svg'
import addIcon from '@/assets/icons/add-icon.svg'
import settingsIcon from '/src/assets/icons/settings.svg'

type TimeCard = {
  manage_client: HorizontalNavigation
  add_client: HorizontalNavigation
  manage_superadmin: HorizontalNavigation
  add_superadmin: HorizontalNavigation
}

export function getAdminNavigationData(
  activePage:
    | 'manage_client'
    | 'add_client'
    | 'manage_superadmin'
    | 'add_superadmin',
): HorizontalNavigation[] {
  const timeCard: TimeCard = {
    manage_client: {
      text: 'Manage Client',
      redirectTo: 'true_admin.admin_clients',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: settingsIcon,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
    add_client: {
      text: 'Add Client',
      redirectTo: 'true_admin.admin_clients_add',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: addIcon,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
    manage_superadmin: {
      text: 'Superadmin',
      redirectTo: 'true_admin.admin_table',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: viewIcon,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
    add_superadmin: {
      text: 'Add Superadmin',
      redirectTo: 'true_admin.admin_add',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: addIcon,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
  }
  timeCard[activePage].isActive = true

  return flatMap(timeCard)
}
