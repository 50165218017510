import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const FinalizeRow = ({
  observation,
  index,
  search,
  selectedIds,
  handleChecked,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [hoveredField, setHoveredField] = useState(null)

  const { first_name, last_name, company_name } = observation?.user
  const { name: project } = observation?.project
  const { title, date_performed, date_created } = observation

  const { stateService } = useRouter()

  return (
    <>
      <tr
        className={classNames('view__row', {
          dark: index % 2 === 0,
        })}
      >
        <td className="checkmark__td">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={selectedIds.includes(observation?.id)}
              onChange={() => {
                handleChecked(observation?.id)
              }}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
        </td>
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'category__fullname',
              text: title,
              max__length: 35,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <UISref
            to="app.observations.view"
            params={{
              observation: observation?.id,
              f_id: stateService?.params?.f_id,
            }}
          >
            <p className="table__text category">
              <Highlighter
                highlightClassName={'highlighted__item'}
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={title}
              />
            </p>
          </UISref>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'originator__fullname',
              text: `${first_name} ${last_name}`,
              max__length: 18,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text originator">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={`${first_name} ${last_name}`}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: company_name,
              max__length: 25,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text company">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={company_name}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project__fullname',
              text: project,
              max__length: 20,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text project">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={project}
            />
          </p>
        </td>
        <td>
          {date_performed
            ? moment(date_performed).format('MM/DD/YY')
            : moment(date_created).format('MM/DD/YY')}
        </td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
