import { validateEmail } from '@/utils/uniqueValidation'

const ROLES = {
  client_admin: 'Administrator',
  supervisor: 'Supervisor',
  client_user: 'Employee',
  read_only: 'Field Employee',
  trainee_manager: 'Training Manager',
  trainee: 'Trainee',
  recipient: 'Recipient',
}

const SUPERVISOR_APPROVED_ROLES = {
  supervisor: 'Supervisor',
  client_user: 'Employee',
  read_only: 'Field Employee',
  trainee_manager: 'Training Manager',
  trainee: 'Trainee',
}

angular
  .module('ccs')
  .controller(
    'UsersEditCtrl',
    function (
      $scope,
      $state,
      $stateParams,
      $countries,
      Api,
      $uibModal,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('UsersEditCtrl')

      $scope.user = {
        under_client: CurrentUser.getClientId(),
        role: 'client_user',
        company_name: CurrentUser.getClientCompany().name,
        custom_permissions: [],
        photo: null,
        photo_url: null,
      }
      $scope.countries = $countries
      $scope.ROLES = CurrentUser.is('supervisor')
        ? SUPERVISOR_APPROVED_ROLES
        : ROLES

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.userIs = CurrentUser.is
      $scope.hasRootUserPerm = !!CurrentUser.getCustomPermissions().find(
        (p) => p.content_type == 'user',
      )

      let editing = false
      $scope.exceedTheCharsLimit = false

      let fileChanged = false
      var fileDetected = 0

      if ($stateParams.userID) {
        editing = true
        Api.UsersV2.byID($stateParams.userID, function (resp) {
          $scope.user = resp
          $scope.hasUserPerm =
            $scope.user && Array.isArray($scope.user.custom_permissions)
              ? !!$scope.user.custom_permissions.find(
                  (p) => p.content_type == 'user',
                )
              : false
          setTimeout(function () {
            $scope.isValidEmail = validateEmail($scope.user.email)
            $scope.$apply()
          }, 0)
        })
      } else {
        $scope.hasUserPerm = false
      }

      $scope.dzConfig = {
        parallelUploads: 1,
        acceptedFiles: 'image/png, image/jpeg, .jpg, .jpeg, .png',
        url: '#',
        maxFiles: 1,
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        init: function () {
          //For the backfilled mockfiles
          //https://github.com/enyo/dropzone/issues/279
          this.on('addedfile', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
        },
      }

      $scope.dzAddedFile = function (file) {
        if (!file) return

        if (file.type === 'image/png' || file.type === 'image/jpeg') {
          // $scope.user.photo = file;
          fileDetected = 1
          fileChanged = true

          $('.dz-progress').css('display', 'block')
          Api.uploadImageV2(file, {}, function (response) {
            $scope.user['photo'] = response.id
            $scope.user['photo_url'] = response.image
            $('.dz-progress').css('display', 'none')
          })
        } else {
          fileDetected = -1
          fileChanged = false
          document.querySelectorAll('.dz-error-mark')[0].style.opacity = '1'
          var errorDisplay = document.querySelectorAll('.dz-error-message')[0]
          errorDisplay.style.opacity = '1'
          $(errorDisplay).text('.png or .jpeg files only please.')
          $('.dz-progress').css('display', 'none')
        }
      }

      $scope.dzRemovedFile = function (file) {
        fileChanged = !fileChanged
        fileDetected -= 1
        $scope.user.photo_url = null
        $scope.user.photo = null
      }

      $scope.viewPhoto = function (photo_url) {
        var $scp = this.$new(true)
        $scp.photo = { image: photo_url }
        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      $scope.removePhoto = function () {
        if ($scope.user.photo_url && $scope.user.photo) {
          $scope.user.photo_url = null
          $scope.user.photo = null
        }
      }

      function redirect() {
        $state.go(
          'admin.users.list',
          {
            pageNumber: $scope.previousPageNumber,
            search: $scope.previousSearch,
            order: $scope.previousOrder,
            reverse: $scope.previousReverse,
          },
          { reload: true },
        )
      }

      function updateHandler(resp) {
        if (resp) {
          var action = editing ? 'Updated!' : 'Created!'
          Notification.success('User Successfully ' + action)
          redirect()
        }
      }

      $scope.resendInvite = (user) => {
        Api.post(
          'invites',
          { user: user.id },
          () => {
            Notification.success(`Successfully resent ${user.email} an invite!`)
          },
          (error) => {
            Notification.danger(
              'User is set as inactive so the Resend Welcome email is disabled.',
            )
          },
        )
      }

      $scope.toggleUserPerm = (hasUserPerm) => {
        $scope.hasUserPerm = hasUserPerm
      }

      $scope.glyphClick = function () {
        // if (header.key == 'is_default') {
        $scope.modal_title = 'MANAGE USERS'
        $scope.modal_text =
          'Manage User Custom Permission allows Supervisors to create and edit Users. This also allows Supervisors to assign/unassign Users to or from their projects.'
        $scope.informational_only = true
        var modalInstance = $uibModal.open({
          templateUrl: 'app/views/common/flexible_modal.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function ($scope) {
            $scope.dismiss = function () {
              modalInstance.close()
            }
          },
        })
        // }
      }

      $scope.$watch(
        'user',
        function (newValue) {
          if (newValue) {
            for (var key of Object.keys(newValue)) {
              if (newValue[key]) {
                if (key == 'email' && newValue[key].length > 75) {
                  $scope.exceedTheCharsLimit = true
                  return
                }
                if (key == 'phone') {
                  newValue[key] = newValue[key].replace(/[^0-9()-]/g, '')
                  if (newValue[key].length > 20) {
                    $scope.exceedTheCharsLimit = true
                    return
                  }
                }
                if (
                  [
                    'company_name',
                    'employee_id',
                    'state',
                    'division',
                    'town',
                    'title',
                    'street',
                  ].indexOf(key) + 1 &&
                  newValue[key].length > 255
                ) {
                  $scope.exceedTheCharsLimit = true
                  return
                }
                if (
                  ['username', 'first_name', 'last_name'].indexOf(key) + 1 &&
                  newValue[key].length > 150
                ) {
                  $scope.exceedTheCharsLimit = true
                  return
                } else {
                  $scope.exceedTheCharsLimit = false
                }
              } else {
                $scope.exceedTheCharsLimit = false
              }
            }
          }
        },
        true,
      )

      $scope.allowLettersNumbersAdnSomeChars = function (event) {
        var regex = new RegExp('^[a-zA-Z0-9@.+_-]+$')
        var key = event.key
        if (!regex.test(key)) {
          event.preventDefault()
          return false
        }
      }

      $scope.checkEmail = () => {
        $scope.isValidEmail = validateEmail($scope.user.email)
      }

      $scope.removeUnsafeCharacters = function (value, field, numbers = false) {
        if (value) {
          $scope.user[field] = value.replace(
            numbers
              ? new RegExp(/[\/\\^$%#`~<>()"|[\]{}-]/g)
              : new RegExp(/[\/\\^$%#`~<>"|[\]{}]/g),
            '',
          )
        }
        setTimeout(function () {
          $scope.$apply()
        }, 0)
      }

      $scope.handleError = (error) => {
        const errors = []
        Object.entries(error).forEach(([key, value]) => {
          errors.push(key + ': ' + value)
        })
        Notification.danger(errors.join(errors.length <= 1 ? '' : '; '))
      }

      $scope.checkPhone = (phone) => {
        if (!phone) return true
        return phone.split('').length === 10 && /^\d+$/.test(phone)
      }

      $scope.submit = function (e) {
        // This won't scale but I want to explore the problem as we expand into several categories of custom perms
        // We will generalize this after we build a few
        $scope.user.custom_permissions = $scope.hasUserPerm
          ? [{ content_type: 'user' }]
          : []

        if (!$scope.user.phone || $scope.user.phone === '')
          $scope.user.phone = null

        if (!$scope.checkPhone($scope.user.phone)) {
          Notification.danger('Invalid Phone Number')
          return
        }

        if (editing) {
          Api.UsersV2.patch($scope.user, updateHandler, $scope.handleError)
        } else {
          Api.postV2(
            'clients/' + CurrentUser.getClientId() + '/add_user',
            $scope.user,
            updateHandler,
            $scope.handleError,
          )
        }
      }
    },
  )
