import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { useEffect, useMemo, useState } from 'react'
import { DesktopView } from './components/DesktopView'
import { MobileRow } from './components/MobileRow'

export const ShareTrainingDocumentation = () => {
  const { stateService } = useRouter()
  const { Api } = useAngularServices()
  const [color, setColor] = useState('')
  const [employeeData, setEmployeeData] = useState({})

  useEffect(() => {
    const { key, name, client_id } = stateService.params

    const getEmployeeData = async () => {
      const { data } = await Api.get(
        `shares/documentation/${key}/${name}/${client_id}`,
        null,
        undefined,
        undefined,
        true,
      )
      setEmployeeData(data)
      document.body.style = `background: ${data.general_settings.colors.secondary_color};`
      setColor(data.general_settings.colors.secondary_color)
    }
    getEmployeeData()
  }, [])

  const {
    company_logo,
    first_name,
    last_name,
    employee_id,
    company_name,
    jobs,
    headshot,
  } = employeeData

  const baseStyle = getBaseStyle(color, headshot)

  const isMobile = window.innerWidth < 800

  const sortedJobs = useMemo(() => {
    return jobs?.sort((a, b) =>
      a.training.training_topic
        .toLowerCase()
        .localeCompare(b.training.training_topic.toLowerCase()),
    )
  }, [jobs])

  return (
    <section css={baseStyle}>
      <div className="logo__wrapper">
        <img src={company_logo} className="top__photo" />
      </div>
      {jobs ? (
        <div className="card__wrapper">
          <div className="card__body">
            <div className="employee__info">
              {headshot && (
                <div className="photo__wrapper">
                  <img
                    className="employee__photo"
                    src={headshot}
                    alt="employee"
                  />
                </div>
              )}
              <div className="info__wrapper">
                <div className="info__block">
                  <p className="info__title">Employee{isMobile && ':'}</p>
                  <p className="info__data">{`${first_name} ${last_name}`}</p>
                </div>
                <div className="info__block">
                  <p className="info__title">ID #{isMobile && ':'}</p>
                  <p className="info__data">{employee_id}</p>
                </div>
                <div className="info__block">
                  <p className="info__title">Company{isMobile && ':'}</p>
                  <p className="info__data">{company_name}</p>
                </div>
              </div>
            </div>
          </div>
          {isMobile ? (
            <div>
              <h4 className="mobile__title">Training Topics</h4>
              {sortedJobs?.map((job, index) => (
                <MobileRow job={job} isDark={index % 2 === 0} color={color} />
              ))}
            </div>
          ) : (
            <DesktopView jobs={sortedJobs} color={color} />
          )}
        </div>
      ) : null}
    </section>
  )
}

const getBaseStyle = (color: string, headshot: string | null) => {
  return css({
    position: 'relative',
    marginTop: '45px',
    '.card__wrapper': {
      borderRadius: '20px',
      paddingTop: '37px',
      paddingBottom: '63px',
      boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      background: '#fff',
    },
    '.logo__wrapper': {
      position: 'absolute',
      width: '800px',
      height: '200px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: '-160px',
    },
    '.top__photo': {
      height: '70px',
    },
    '.card__body': {
      marginLeft: headshot ? '43px' : 0,
    },
    '.employee__info': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.photo__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRight: headshot ? '1px solid #EEEFF3' : 'none',
      width: '137px',
      height: '120px',
      padding: '50px',
    },
    '.employee__photo': {
      width: '100px',
      height: '120px',
    },
    '.info__wrapper': {
      display: 'flex',
      gap: '60px',
      marginLeft: '43px',
    },
    '.info__block': {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      height: 'fit-content',
    },
    '.info__title': {
      margin: 0,
      color: '#686A6C',
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.13px',
    },
    '.info__data': {
      margin: 0,
      paddingBottom: '12px',
      color,
      fontSize: '13px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.13px',
      borderBottom: '1px solid #EEEFF3',
      width: '140px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.mobile__title': {
      textAlign: 'center',
      marginTop: '20px',
      marginBottom: '20px',
    },
    '@media(max-width: 800px)': {
      '.info__wrapper': {
        flexDirection: 'column',
        gap: '17px',
        marginLeft: '10px',
      },
      '.info__block': {
        flexDirection: 'row',
        justifyContent: 'space-between',
        gap: '20px',
      },
      '.info__data': {
        width: '125px',
        paddingBottom: '5px',
      },
      '.card__body': {
        marginLeft: '17px',
      },
      '.photo__wrapper': {
        width: headshot ? '80px' : 0,
        height: headshot ? '90px' : 0,
        padding: headshot ? '50px' : 0,
      },
      '.employee__photo': {
        width: '75px',
        height: '90px',
        marginRight: '10px',
      },
      '.logo__wrapper': {
        width: '400px',
      },
    },
  })
}
