import * as React from 'react'
import {
  NestedField,
  GroupFields,
  ReportTitleField,
  TextField,
  MeasureFields,
  DateField,
  ImageReferenceField,
  OptionsFields,
  ParagraphField,
  FilesFields,
  RadioField,
  SignaturesAndSketchesFields,
  TimeField,
  NumberField,
  HeaderField,
} from './index'

export const FieldSelector = ({
  fieldData,
  formMemory,
  setFormMemory,
  possibleGroupData,
  addSketchOrSignatureText,
  promisesToDeleteFiles,
  firstFieldToError,
  onChange,
}) => {
  const possibleFields = {
    nested_option: (
      <NestedField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        addSketchOrSignatureText={addSketchOrSignatureText}
        promisesToDeleteFiles={promisesToDeleteFiles}
        onChange={onChange}
      />
    ),
    header: (
      <HeaderField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        addSketchOrSignatureText={addSketchOrSignatureText}
        promisesToDeleteFiles={promisesToDeleteFiles}
        firstFieldToError={firstFieldToError}
        onChange={onChange}
      />
    ),
    group: (
      <GroupFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        promisesToDeleteFiles={promisesToDeleteFiles}
        addSketchOrSignatureText={addSketchOrSignatureText}
        onChange={onChange}
      />
    ),
    report_title: (
      <ReportTitleField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    text: (
      <TextField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    address: (
      <TextField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    currency: (
      <MeasureFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    mass: (
      <MeasureFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    dimension: (
      <MeasureFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    temperature: (
      <MeasureFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    date: (
      <DateField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        onChange={onChange}
      />
    ),
    files: (
      <FilesFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        promisesToDeleteFiles={promisesToDeleteFiles}
        onChange={onChange}
      />
    ),
    image_reference: <ImageReferenceField fieldData={fieldData} />,
    number: (
      <NumberField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    options: (
      <OptionsFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        onChange={onChange}
      />
    ),
    options_multi: (
      <OptionsFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        onChange={onChange}
      />
    ),
    paragraph: <ParagraphField fieldData={fieldData} />,
    photos: (
      <FilesFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        promisesToDeleteFiles={promisesToDeleteFiles}
        onChange={onChange}
      />
    ),
    radio: (
      <RadioField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
    signatures: (
      <SignaturesAndSketchesFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        addSketchOrSignatureText={addSketchOrSignatureText}
        onChange={onChange}
      />
    ),
    sketches: (
      <SignaturesAndSketchesFields
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        setFormMemory={setFormMemory}
        addSketchOrSignatureText={addSketchOrSignatureText}
        onChange={onChange}
      />
    ),
    time: (
      <TimeField
        fieldData={fieldData}
        formMemory={formMemory}
        possibleGroupData={possibleGroupData}
        onChange={onChange}
      />
    ),
  }
  if (possibleFields[fieldData.type]) return possibleFields[fieldData.type]
  return <section>{fieldData.type} Missing</section>
}
