import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { SubTable } from '@screens/Timecard/Timesheet/components/SubTable'
import { useRouter } from '@/react/hooks'
import { getHoursAndMinutes } from '@/react/components'
import checkmarkIcon from '/src/assets/icons/Check.svg'
import { StageSegment } from '@screens/Timecard/Timesheet/components/StageSegment'
import { DeleteAlert } from '@screens/Timecard/Timesheet/components/DeleteAlert'
import { debounce, flatMap } from 'lodash'
import { saveBase64AsFile } from '@/utils'
import { PdfIcon, RenewIcon, VoidIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

export const TimeSheetCustomTableRow = ({
  startingRowData,
  idx,
  voidPage = false,
  reloadCloseAll,
  voidedCount,
  setVoidedCount,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const user = CurrentUser.getInstance()

  const [openTab, setOpenTab] = React.useState<boolean>(false)
  const [popUp, setPopUp] = React.useState(null)
  const [reload, setReload] = React.useState<boolean>(false)
  const [localReload, setLocalReload] = React.useState<boolean>(false)
  const [pdfGrayOut, setPdfGrayOut] = React.useState<boolean>(false)
  const [segmentVisibility, setSegmentVisibility] =
    React.useState<boolean>(true)

  const [rowData, setRowData] = React.useState(startingRowData)
  const [currentDisplayedStage, setCurrentDisplayedStage] = React.useState(
    rowData.stage_value,
  )

  const [tabData, setTabData] = React.useState(null)
  const [forceUpdateStage, setForceUpdateStage] = React.useState('')
  const [weekTime, setWeekTime] = React.useState<object[]>(
    getWeekTimes(rowData.hours_by_day),
  )
  const [editableWeek] = React.useState<boolean>(rowData.stage !== 'c')
  const allChecked = checkForWeek(weekTime, rowData)
  const isAdminRole = ['client_admin', 'supervisor'].includes(user.role)

  const setStage = () => {
    if (forceUpdateStage) {
      return forceUpdateStage
    }

    if (isAdminRole && rowData.signature) {
      if (rowData.stage_value === 'Approved') {
        return 'Submitted'
      }

      return rowData.stage_value
    }

    return 'Open'
  }

  async function fetchData() {
    const [{ data }] = await Promise.all([
      Api.get('timesheets/' + rowData.id, {
        application: stateService.params.app,
        deleted: false,
      }),
    ])
    setReload(false)
    setTabData(null)
    setCurrentDisplayedStage(setStage())
    setRowData(data)
    setWeekTime(getWeekTimes(data.hours_by_day))
    setForceUpdateStage('')
  }

  async function voidUnvoidTimesheet() {
    await Api.put('timesheets/' + rowData.id + '/', {
      deleted: !voidPage,
      application: rowData.application,
      client: rowData.client,
      stage: rowData.stage,
      supervisor: rowData.supervisor.id,
      user: rowData.user.id,
    })
    if (!voidPage) {
      setVoidedCount(voidedCount + 1)
    }
    setSegmentVisibility(false)
    reloadCloseAll(true)
  }
  if (reload) {
    fetchData()
  }

  if (localReload) {
    setTimeout(() => {
      setLocalReload(false)
    })
  }

  window.addEventListener(
    'resize',
    debounce(() => {
      setLocalReload(true)
    }, 50),
  )

  async function downloadPDF() {
    if (pdfGrayOut) return
    setPdfGrayOut(true)
    const { data: fileData } = await Api.get(
      'timesheets/' + rowData.id + '/generate_pdf',
    )
    setTimeout(async () => {
      const { data } = await Api.getFileDataForDownload(fileData.file_id)
      saveBase64AsFile(data.content, data.filename, data.content_type)
      setPdfGrayOut(false)
    }, fileData.time * 1000 + 1000)
  }

  if (!segmentVisibility) return null

  const variableStyle = css({
    cursor: 'pointer',
    height: 30,
    backgroundColor: idx % 2 == 0 ? '#F2F3F5' : 'initial',
    '.centered-cell': {
      textAlign: 'center',
      '*': {
        display: 'inline-block',
      },
      img: {
        height: 18,
        marginLeft: 4,
      },
    },
    '.centered-cell-with-alert': {
      img: {
        height: 18,
        marginLeft: 4,
      },
      '.holder': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      },
    },
    td: { paddingLeft: 5 },
    '.alert-holder': {
      width: 0,
      height: 0,
      position: 'relative',
      zIndex: 300,
    },
  })

  return [
    <tr className="colored-row" css={variableStyle} key={'baseRow'}>
      <td
        onClick={() => {
          setOpenTab(!openTab)
        }}
      >
        {rowData.supervisor.first_name} {rowData.supervisor.last_name}
      </td>
      <td
        onClick={() => {
          setOpenTab(!openTab)
        }}
      >
        {rowData.user.first_name} {rowData.user.last_name}
      </td>

      <td>
        <StageSegment
          currentStage={rowData.stage_value}
          openChoice={
            (CurrentUser.getRole() === 'client_admin' ||
              (CurrentUser.getRole() === 'supervisor' &&
                currentDisplayedStage === 'Submitted')) &&
            rowData.stage !== 'c' &&
            !voidPage &&
            allChecked
          }
          currentDisplayedStage={currentDisplayedStage}
          setCurrentDisplayedStage={setCurrentDisplayedStage}
          rowData={rowData}
          reloadCloseAll={reloadCloseAll}
        />
      </td>
      {weekTime.map((weekDay, idx) => (
        <td
          key={'single-day-' + idx}
          className="centered-cell"
          onClick={() => {
            setOpenTab(!openTab)
          }}
        >
          <span>
            {getHoursAndMinutes(weekDay.hours) || checkStatus(weekDay.status)}
          </span>
          {weekDay.green_check && weekDay.hours ? (
            <img src={checkmarkIcon} alt="+" />
          ) : null}
        </td>
      ))}
      <td
        className="centered-cell"
        onClick={() => {
          setOpenTab(!openTab)
        }}
      >
        {getHoursAndMinutes(rowData.hours)}
        {!allChecked ? null : <img src={checkmarkIcon} alt="+" />}
      </td>
      {voidPage ? null : (
        <td
          className="centered-cell"
          onClick={() => {
            if (!pdfGrayOut) downloadPDF()
          }}
        >
          <PdfIcon
            height={'16px'}
            color={pdfGrayOut ? 'gray' : variableColor}
          />
        </td>
      )}
      {CurrentUser.getRole() !== 'client_admin' ? null : (
        <td className="centered-cell-with-alert">
          <div className="holder">
            {voidPage ? (
              <RenewIcon
                height={'18px'}
                color={rowData.can_be_restored ? variableColor : 'gray'}
                onClick={() => {
                  if (rowData.can_be_restored) {
                    setPopUp(
                      <ConfirmationModal
                        handleClose={() => {
                          setPopUp(null)
                        }}
                        handleClick={voidUnvoidTimesheet}
                        action={'Restore'}
                      />,
                    )
                  }
                }}
              />
            ) : (
              <VoidIcon
                height={'18px'}
                color={editableWeek ? variableColor : 'gray'}
                onClick={() => {
                  if (editableWeek) {
                    setPopUp(
                      <ConfirmationModal
                        handleClose={() => {
                          setPopUp(null)
                        }}
                        handleClick={voidUnvoidTimesheet}
                        action={'Delete'}
                      />,
                    )
                  }
                }}
              />
            )}
            <div className="alert-holder">{popUp}</div>
          </div>
        </td>
      )}
    </tr>,
    openTab && !voidPage ? (
      <SubTable
        key={'subTableRow'}
        rowData={rowData}
        tabData={tabData}
        setTabData={setTabData}
        weekTime={weekTime}
        setReload={setReload}
        setForceUpdateStage={setForceUpdateStage}
        setLocalReload={setLocalReload}
        editableWeek={editableWeek}
      />
    ) : null,
  ]
}

function getWeekTimes(daysTimeccards) {
  const weekHoursInSeconds = {
    Monday: { hours: 0 },
    Tuesday: { hours: 0 },
    Wednesday: { hours: 0 },
    Thursday: { hours: 0 },
    Friday: { hours: 0 },
    Saturday: { hours: 0 },
    Sunday: { hours: 0 },
  }
  daysTimeccards.forEach((timecard) => {
    weekHoursInSeconds[timecard.weekday] = timecard
    if (!timecard.hours) {
      weekHoursInSeconds[timecard.weekday].green_check = false
    }
  })
  return flatMap(weekHoursInSeconds)
}

function checkForWeek(weekDays, rowData) {
  const checkArray = []
  weekDays.forEach((day) => {
    if (day.weekday) checkArray.push(day.green_check)
  })
  if (!checkArray.length || !rowData.hours) return false
  return !checkArray.includes(false)
}

function checkStatus(status) {
  switch (status) {
    case 'i':
      return 'In'
    case 'm':
      return 'In'
    case 'b':
      return 'In'
    default:
      return ''
  }
}
