import { ReactWrapper } from '@components'
import { LoginPageRedirect } from './LoginPageRedirect'
import { ForgotPasswordRedirect } from './ForgotPasswordRedirect'
import { InviteUserRedirect } from './InviteUserRedirect'
import { RequestInvite } from './RequestInvite'

export const loginPageRedirectComponent = ReactWrapper(
  LoginPageRedirect,
  'loginPageRedirect',
)
export const forgotPasswordRedirect = ReactWrapper(
  ForgotPasswordRedirect,
  'forgotPasswordRedirect',
)
export const inviteUserRedirectRedirect = ReactWrapper(
  InviteUserRedirect,
  'inviteUserRedirect',
)
export const requestInvite = ReactWrapper(RequestInvite, 'requestInvite')
