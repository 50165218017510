angular.module('ccs').directive('autoResize', autoResize)

autoResize.$inject = ['$timeout']

function autoResize($timeout) {
  const defaultStyle = {
    minHeight: '34px',
    height: '34px',
    'overflow-y': 'hidden',
    marginBottom: '12px',
  }

  const directive = {
    restrict: 'A',
    link: function autoResizeLink(scope, element, attributes, controller) {
      element.css(defaultStyle)
      $timeout(function () {
        element.css('height', element[0].scrollHeight + 'px')
      }, 100)

      element.on('input', function () {
        element.css(defaultStyle)
        element.css('height', element[0].scrollHeight + 'px')
      })
    },
  }

  return directive
}
