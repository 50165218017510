angular.module('ccs').directive('sortedheader', sortableTableHeader)

function sortableTableHeader(CurrentUser) {
  return {
    restrict: 'A',
    templateUrl: 'app/views/common/sortable_table_header.html',
    scope: {
      app: '=',
      headers: '=',
      order: '=',
      reverse: '=',
      refHeaders: '=',
      changePageFn: '&?',
    },
    link: function ($scope) {
      $scope.isReadOnly = CurrentUser.isReadOnly
      
      if ($scope.$parent.toggleAll) {
        $scope.toggleAll = $scope.$parent.toggleAll
      }

      $scope.handleHeaderClick = (header) => {
        if (!header.sortable) return
        if ($scope.order === header.key) {
          $scope.reverse = !$scope.reverse
        } else {
          $scope.order = header.key
        }
      }

      $scope.handleGlyphClick = (header) => {
        $scope.$parent.glyphClick(header)
      }

      $scope.$watch('order', function (newValue, oldValue) {
        if (newValue === oldValue) return
        $scope.pageNumber = 1
        if ($scope.changePageFn === undefined) {
          $scope.$parent.changePage()
        } else {
          $scope.changePageFn()
        }
      })
      $scope.$watch('reverse', function (newValue, oldValue) {
        if (newValue === oldValue) return
        $scope.pageNumber = 1
        if ($scope.changePageFn === undefined) {
          $scope.$parent.changePage()
        } else {
          $scope.changePageFn()
        }
      })
    },
  }
}
