angular
  .module('ccs')
  .controller(
    'ProgramsListCtrl',
    function ($scope, Api, $state, $stateParams, $log) {
      $log.debug('ProgramsListCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search

      $scope.pageSize = 20
      $scope.maxSize = 10 //Number of pager buttons to show
      $scope.serial = 1
      $scope.$watch('pageNumber', function () {
        $scope.serial =
          $scope.pageNumber * $scope.pageSize - ($scope.pageSize - 1)
      })

      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //total is really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageSize * $scope.pageNumber }

      function getTrainings() {
        Api.Jobs.get(query(), function (response) {
          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })
      }

      getTrainings()

      $scope.status = { opened: false }
      $scope.completed = false

      function queryCompetedJobs() {
        $scope.completed = !$scope.completed

        let q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          completed: $scope.completed,
          user_filter: true,
        }
        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      $scope.showHideCompletedTrainings = () => {
        Api.Jobs.get(queryCompetedJobs(), function (response) {
          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })
      }

      function query() {
        let q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          tz_offset: new Date().getTimezoneOffset(),
          user_filter: true,
        }
        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      $scope.changePage = function () {
        $state.transitionTo(
          'trainings.program',
          {
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          {
            notify: false,
          },
        )

        getTrainings()
      }
    },
  )
