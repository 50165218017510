import * as React from 'react'

import { PartyObservedField, PhotoSection, CopyAlert } from './index'
import { FieldsWithVariableAmount, TextInputStyle } from '@screens/components'
import { css } from '@emotion/react'
import { CopyIconLinestyle } from '@/react/componentAssets'
import { percentageShadeBlendColor } from '@/utils'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  ReloadElement,
  useAngularServices,
} from '@/react/components'
import {
  ApplicationConfig,
  DataWrap,
  GenericOption,
  PaginatedGet,
  ReportAnswer,
  ReportQuestion,
} from '@/react/types'
import { Dispatch, SetStateAction } from 'react'
import { cloneDeep, debounce } from 'lodash'
import { default as ReactSelect } from 'react-select'
import {
  processSingleAnswer,
  initialSingleAnswer,
} from '@screens/Observations/CreateEditPage/components/dataLogic'
import { useRouter } from '@/react/hooks'

type SingleQuestionProps = {
  questionInfo: ReportQuestion
  answersLink: ReportAnswer
  copyField: () => void
  globalPartyObserved: GenericOption[]
  setPartyObservedOptions: Dispatch<SetStateAction<GenericOption[]>>
  partyObservedOptions: GenericOption[]
  app: ApplicationConfig
  saveButtonPressed: number
  memoryState: {
    [key: string]: ReportAnswer[]
  }
  setMemoryState: Dispatch<
    SetStateAction<{ [key: string]: ReportAnswer[] } | null>
  >
  index: number
}

export function SingleQuestion({
  questionInfo,
  memoryState,
  setMemoryState,
  copyField,
  globalPartyObserved,
  partyObservedOptions,
  setPartyObservedOptions,
  app,
  saveButtonPressed,
  index,
}: SingleQuestionProps) {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const observationID = stateService.params.observation

  const answerLink =
    memoryState[questionInfo.id + '-' + questionInfo.category][index]

  const [reload, setReload] = React.useState(false)
  const [answerState, setAnswerState] = React.useState<ReportAnswer>(
    initialAnswerDataProcessing(answerLink),
  )
  const [severity, setSeverity] = React.useState<number>(answerState.severity)

  const debouncedSend = React.useRef(
    debounce(async (answer) => {
      await processSingleAnswer(
        cloneDeep(answer),
        Api,
        observationID,
        setAnswerState,
      )
    }, 3000),
  ).current

  if (!answerState) {
    console.error('Missing answer link, error info:', {
      answersLink_base: answerState,
      Question_Info: questionInfo,
    })
    return null
  }

  const [answerVariable, setAnswerVariable] = React.useState(
    cloneDeep(answerState?.answer) || 'n/a',
  )
  const [alert, setAlert] = React.useState([])
  const [openToggle, setOpenToggle] = React.useState(false)
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )

  const options = [
    { value: 'yes', label: app.observation_yes },
    { value: 'no', label: app.observation_follow_up_required },
    { value: 'n/a', label: 'N/A' },
    { value: 'cls', label: app.observation_issue_resolved },
    {
      value: 'pr',
      label: app.observations_pending_review,
      isDisabled: true,
    },
    {
      value: 'pa',
      label: app.observations_pending_approval,
      isDisabled: true,
    },
  ]

  const textareaRef = React.useRef(null)

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(rows * lineHeight)
    }
  }

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
    }
  }, [])

  React.useEffect(() => {
    if (answerVariable === 'cls' && !answerState.corrective_actions[0]?.text) {
      setOpenToggle(true)
    }
  }, [saveButtonPressed])

  answerState.ref = React.useRef()

  const firstChange = async (value, globalPartyObserved) => {
    const answerId: number = await initialSingleAnswer(
      cloneDeep({ ...answerState, answer: value.value }),
      Api,
      observationID,
    )
    const POToAdd = globalPartyObserved.map((po) => {
      return {
        answer: answerId,
        party_observed: po.value,
      }
    })

    const {
      data: POs,
    }: DataWrap<{ id: number; party_observed: { id: number } }> =
      await Api.post(`answer_party_observed_answer`, POToAdd)

    setMemoryState((prevState: { [key: string]: ReportAnswer[] }) => {
      if (!prevState) return
      const newState = cloneDeep(prevState)
      const newAnswerState =
        newState[questionInfo.id + '-' + questionInfo.category][index]

      newAnswerState.answer = value.value
      newAnswerState.severity = 1
      newAnswerState.edited = true
      newAnswerState.answer_party_observed_answer_list = POs
      newAnswerState.id = answerId

      setAnswerState((prevState: ReportAnswer) => {
        return cloneDeep(newAnswerState)
      })

      return newState
    })

    setAnswerVariable(value.value)
    if (value !== 'n/a') {
      setOpenToggle(true)
    }
    setReload(true)
  }

  return (
    <div css={baseStyle} ref={answerState.ref}>
      <div
        className="fullWidth header-answer-holder"
        css={css({
          backgroundColor: openToggle
            ? 'transparent'
            : percentageShadeBlendColor(0.8, variableColor),
        })}
      >
        <div
          className="question-header"
          onClick={() => {
            setOpenToggle(!openToggle)
          }}
        >
          <h5
            dangerouslySetInnerHTML={{
              __html: questionInfo.name,
            }}
          />
        </div>
        <div className="right-header-part">
          <div className="select-answer-holder">
            <ReactSelect
              defaultValue={options.filter((option) => {
                if (option.value === (answerState.answer || 'n/a'))
                  return option
              })}
              options={options}
              onChange={(value) => {
                if (!answerState.id) {
                  firstChange(value, globalPartyObserved)
                } else {
                  debouncedSend([
                    { field: 'answer', value: value.value },
                    { field: 'edited', value: true },
                  ])
                  if (value !== 'n/a') {
                    setOpenToggle(true)
                  }
                }
              }}
              styles={dropdownOptionStyles(variableColor)}
              theme={(theme) => dropdownFiledTheme(theme, variableColor)}
            />
          </div>
          <CopyIconLinestyle
            color={variableColor}
            height={'50%'}
            width={'32px'}
            onClick={() => {
              localStorage.getItem('doNotAskAboutCopy')
                ? copyField()
                : setAlert([
                    <CopyAlert callback={copyField} setAlert={setAlert} />,
                  ])
            }}
            additionalStyles={css({
              marginTop: 20,
              marginLeft: 20,
              cursor: 'pointer',
            })}
          />
          <div className="alert-holder">{alert}</div>
          <div
            className="toggle-holder"
            onClick={() => {
              setOpenToggle(!openToggle)
            }}
          >
            <div
              css={[
                css({
                  fontSize: 20,
                  color: variableColor,
                  transform:
                    'rotate(' + (openToggle ? 90 : -90) + 'deg) scale(1,2)',
                }),
              ]}
            >
              {'>'}
            </div>
          </div>
        </div>
      </div>
      <section
        css={css({
          display: openToggle && answerVariable !== 'n/a' ? 'block' : 'none',
          paddingLeft: 15,
          '.severity-body': { display: answerVariable === 'yes' ? 'none' : '' },
        })}
      >
        <section>
          <div className="field-with-variable-amounts">
            <div className="severity-body">
              <label>Severity: </label>
              <section className="select-severity">
                <div
                  className="severity-button"
                  css={css({
                    backgroundColor: severity === 1 ? '#639A00' : 'transparent',
                    color: severity === 1 ? 'white' : 'black',
                  })}
                  onClick={() => {
                    answerState.severity = 1
                    setSeverity(1)
                    debouncedSend([
                      { field: 'severity', value: 1 },
                      { field: 'edited', value: true },
                    ])
                  }}
                >
                  {app.observation_low_severity}
                </div>
                <div
                  className="severity-button"
                  css={css({
                    backgroundColor: severity === 2 ? '#DC952C' : 'transparent',
                    color: severity === 2 ? 'white' : 'black',
                  })}
                  onClick={() => {
                    answerState.severity = 2
                    setSeverity(2)
                    debouncedSend([
                      { field: 'severity', value: 2 },
                      { field: 'edited', value: true },
                    ])
                  }}
                >
                  {app.observation_medium_severity}
                </div>
                <div
                  className="severity-button"
                  css={css({
                    backgroundColor: severity === 3 ? '#BD2828' : 'transparent',
                    color: severity === 3 ? 'white' : 'black',
                  })}
                  onClick={() => {
                    answerState.severity = 3
                    setSeverity(3)
                    debouncedSend([
                      { field: 'severity', value: 3 },
                      { field: 'edited', value: true },
                    ])
                  }}
                >
                  {app.observation_high_severity}
                </div>
              </section>
            </div>
            {answerVariable === 'yes' ? null : (
              <FieldsWithVariableAmount
                typeOfField={app.observation_corrective_action_header}
                answersLink={answerState.corrective_actions}
                startWithEmptyField={answerVariable === 'cls'}
                wholeAnswerLink={answerState}
                width={'40%'}
                setAnswerState={setAnswerState}
                debouncedSend={debouncedSend}
              />
            )}
          </div>
          <div className="line-of-inputs">
            <FieldsWithVariableAmount
              typeOfField={app.observation_note_header}
              answersLink={answerState.notes}
              wholeAnswerLink={answerState}
              setAnswerState={setAnswerState}
              debouncedSend={debouncedSend}
            />
            {reload ? (
              <ReloadElement setReload={setReload} />
            ) : (
              <PartyObservedField
                answerState={answerState}
                debouncedSend={debouncedSend}
                options={partyObservedOptions}
                setOptions={setPartyObservedOptions}
                partyObservedVariableFiled={app.observation_party_observed}
                width={'30%'}
              />
            )}
            <div
              className="reference-body"
              css={css({
                width: '30%',
              })}
            >
              <label>{app.observation_reference}</label>
              <textarea
                className="form__textarea"
                onFocus={adjustTextareaHeight}
                onInput={adjustTextareaHeight}
                ref={textareaRef}
                defaultValue={answerState.reference || ''}
                onChange={(value) => {
                  debouncedSend([
                    { field: 'reference', value: value.target.value },
                    { field: 'edited', value: true },
                  ])
                }}
                style={{ height: `${textareaHeight}px` }}
              />
            </div>
          </div>
          <PhotoSection
            answerLink={answerState.photos}
            wholeAnswerLink={answerState}
            debouncedSend={debouncedSend}
          />
          <hr />
        </section>
      </section>
    </div>
  )
}

const baseStyle = css({
  marginBottom: 20,
  '.fullWidth': {
    width: '100%',
  },
  '.header-answer-holder': {
    display: 'flex',
    justifyContent: 'space-between',
    '.question-header': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      width: 'calc(100% - 265px)',
      paddingLeft: 15,
      paddingRight: 30,
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
      '*': {
        margin: 0,
        padding: 0,
      },
    },
    '.right-header-part': {
      width: 300,
      display: 'flex',
      justifyContent: 'right',
    },
    '.select-answer-holder': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minWidth: 180,
      '.select-answer': {
        height: 34,
        borderColor: '#e5e6e7',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '0',
      },
    },
  },
  '.severity-body': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 20,
    width: '50%',
    '.select-severity': {
      height: 35,
      display: 'flex',
      '.severity-button': {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 10,
        paddingBottom: 10,
        marginRight: 20,
        lineHeight: '12px',
        border: 'solid 1px gray',
        cursor: 'pointer',
      },
    },
  },
  '.field-with-variable-amounts': {
    marginBottom: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '.line-of-inputs': {
    width: '100%',
    display: 'flex',
    marginBottom: 20,
    justifyContent: 'space-between',
    '.reference-body': {
      display: 'flex',
      flexDirection: 'column',
      input: {
        ...TextInputStyle,
      },
    },
  },
  '.alert-holder': {
    width: 0,
    height: 0,
    position: 'relative',
    zIndex: 300,
  },
  '.toggle-holder': {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 20,
    cursor: 'pointer',
  },
  '.form__textarea': {
    width: '100%',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    minHeight: '30px',
    maxWidth: '100%',
    resize: 'vertical',
  },
})

function initialAnswerDataProcessing(answerLink) {
  if (!answerLink.notes || !answerLink.notes.length) answerLink.notes = []
  if (!answerLink.reference) answerLink.reference = ''
  if (!answerLink.corrective_actions || !answerLink.corrective_actions.length)
    answerLink.corrective_actions = []
  if (!answerLink.photos || !answerLink.photos.length) answerLink.photos = []
  return cloneDeep(answerLink)
}
