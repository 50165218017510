angular.module('ccs').directive('editnameinline', editNameInline)

function editNameInline() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/edit_name_inline.html',
    scope: {
      name: '=',
      isEditing: '=',
      isHeader: '@',
      save: '&',
      edit: '&',
      cancel: '&',
    },
  }
}
