import moment from 'moment'
import pluralize from 'pluralize'

import { _extends } from '@/utils'

angular
  .module('ccs')
  .controller(
    'FormReportsCtrl',
    function (
      $scope,
      $state,
      Api,
      ngDialog,
      app,
      $stateParams,
      $timeout,
      CurrentUser,
      $log,
    ) {
      $log.debug('FormReportsCtrl')

      $scope.reports = []
      $scope.titlesApp = app
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'date_created'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.maxSize = 10 //Number of pager buttons to show
      $scope.reverse = true //for default 'Newest to Oldest' order
      $scope.userIs = CurrentUser.is
      $scope.isClientOrAdmin = CurrentUser.isClientOrAdmin

      //pageSize Used in the view
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.totalItems = $scope.pageNumber * $scope.pageSize

      $scope.titleSingularForm = pluralize.singular(app.app_mobile_form_display)

      $scope.changePage = function () {
        $state.transitionTo(
          'app.forms.reports',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
        getReports()
      }

      if (CurrentUser.is('client_admin')) {
        $scope.tableHeaders = [
          { key: 'report__title', display: 'Report Title', sortable: true },
          {
            key: 'form__name',
            display: $scope.titleSingularForm,
            sortable: true,
          },
          { key: 'user__first_name', display: 'Full Name', sortable: true },
          { key: 'company_name', display: 'Company', sortable: true },
          { key: 'project__name', display: app.project, sortable: true },
          { key: 'date_created', display: 'Date Created', sortable: true },
          { key: 'view', display: 'View', centered: true },
          { key: 'edit', display: 'Edit', edit: true, centered: true },
          { key: 'email', display: 'Share', centered: true },
          { key: 'delete', display: 'Delete', centered: true },
        ]
      } else {
        $scope.tableHeaders = [
          { key: 'report__title', display: 'Report Title', sortable: true },
          {
            key: 'form__name',
            display: $scope.titleSingularForm,
            sortable: true,
          },
          { key: 'user__first_name', display: 'Full Name', sortable: true },
          {
            key: 'project__client__company__name',
            display: 'Company',
            sortable: true,
          },
          {
            key: 'project__name',
            display: app.project + ' Name',
            sortable: true,
          },
          { key: 'date_created', display: 'Date Created', sortable: true },
          { key: 'view', display: 'View', centered: true },
          { key: 'edit', display: 'Edit', edit: true, centered: true },
          { key: 'email', display: 'Share', centered: true },
        ]
      }

      $scope.breadcrumbs = [
        {
          title: app.name,
        },
      ]

      $scope.isCurrentUserIsAuthor = function (report) {
        const currentUser = CurrentUser.getInstance()
        const currentUserName =
          currentUser.first_name + ' ' + currentUser.last_name
        const reportUserName =
          report.external_contacts.length > 0
            ? report.external_contacts[0].external_contact.first_name +
              ' ' +
              report.external_contacts[0].external_contact.last_name
            : report.user.first_name + ' ' + report.user.last_name
        return currentUserName === reportUserName
      }

      $scope.getReportShareUrl = (report) => {
        return $state.href(
          'shares.reports',
          { key: report.client_object_key },
          { absolute: true },
        )
      }

      $scope.showUnsyncError = function () {
        $scope.error =
          'Attention: You may not edit a report that has not fully synchronized with the server. Please try again later.'
        $timeout(function () {
          $scope.error = null
        }, 3000)
      }

      $scope.add = function () {
        ngDialog.open({
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          template: 'app/views/form_add_report.html',
          /** @ngInject */
          controller: function controller($scope, $state) {
            $scope.report = {
              project: null,
              form: null,
            }
            $scope.appProject = null
            $scope.mobileForms = []
            $scope.projects = []
            $scope.app = app

            //Infinite Scroll Begin
            $scope.infiniteScroll = {}
            $scope.infiniteScroll.numToAdd = 20
            $scope.infiniteScroll.currentProjectItems = 20
            $scope.infiniteScroll.currentFormItems = 20

            $scope.addMoreProjects = function () {
              $scope.infiniteScroll.currentProjectItems +=
                $scope.infiniteScroll.numToAdd
            }

            $scope.addMoreForms = function () {
              $scope.infiniteScroll.currentFormItems +=
                $scope.infiniteScroll.numToAdd
            }

            //Infinite Scroll End

            function getProjects(page) {
              var pageNumber = page || 1
              Api.Projects.get(
                {
                  client: CurrentUser.getClientId(),
                  page: pageNumber,
                  is_active: 'True',
                },
                function (resp) {
                  $scope.projects = $scope.projects.concat(resp.results)
                  if (resp.next) {
                    getProjects(pageNumber + 1)
                  }
                  // isProjects
                  $scope.projects.length > 0
                    ? ($scope.isProjects = true)
                    : ($scope.isProjects = false)
                },
              )
            }

            function getForms() {
              Api.AppProjects.get(
                {
                  app: app.id,
                  project: $scope.report.project,
                },
                function (resp) {
                  if (resp.results.length) {
                    $scope.appProject = resp.results[0]
                    var formIds = $scope.appProject.active_mobile_forms
                    if (formIds.length) {
                      //Changed from getMobileForms to getFilteredMobileForms
                      //getMobileForms(formIds, 1);
                      getFilteredMobileForms(1)
                    }
                  } else {
                    Api.AppProjects.post(
                      {
                        app: app.id,
                        project: $scope.report.project,
                      },
                      function (resp) {
                        $scope.appProject = resp
                      },
                    )
                  }
                },
              )
            }

            function getFilteredMobileForms(pageNumber) {
              Api.MobileForms.get(
                {
                  project_id: $scope.report.project,
                  application_id: app.id,
                  page: pageNumber,
                  order: 'name',
                },
                function (resp) {
                  $scope.mobileForms = $scope.mobileForms.concat(resp.results)
                  if (resp.next) {
                    getFilteredMobileForms(pageNumber + 1)
                  }

                  $scope.mobileForms.length > 0
                    ? ($scope.isMobileForms = true)
                    : ($scope.isMobileForms = false)
                },
              )
            }

            $scope.save = function () {
              $scope.closeThisDialog()
              $state.go('app.forms.reports_create', {
                form: $scope.report.form,
                project: $scope.report.project,
              })
            }

            getProjects()

            $scope.$watch('report.project', function (newValue, oldValue) {
              $scope.appProject = null
              $scope.mobileForms = []
              $scope.mobileForms = []
              $scope.report.form = null
              if (newValue) {
                getForms()
              }
            })
          },
        })
      }

      $scope.delete = function (report_id) {
        ngDialog.open({
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          template: 'app/views/form_delete_report_dialog.html',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.save = function () {
              Api.Reports.delete({ id: report_id }, function (resp) {
                getReports()
                getDeletedReportsCount()
                $scope.closeThisDialog()
              })
            }

            $scope.cancel = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      function getDraftReportsCount() {
        Api.Reports.get(
          { application: app.id, is_draft: true, role: true, counter: true },
          function (resp) {
            $scope.draftReportsCount = resp.count
          },
        )
      }

      function getDeletedReportsCount() {
        Api.Reports.get(
          { application: app.id, deleted: 'True', role: true, counter: true },
          function (resp) {
            $scope.deletedReportsCount = resp.count
          },
        )
      }

      function getReports() {
        Api.Reports.get(reportQuery(), function (resp) {
          $scope.reports = resp.results
          $scope.totalItems = resp.count
          getDeletedReportsCount()
          getDraftReportsCount()
          for (var l = 0; l < $scope.reports.length; l++) {
            var item = $scope.reports[l]
            if (!item.fields.length) {
              item.form.report_title =
                item.form.name +
                ' - ' +
                moment(item.date_created).format('MM/DD/YYYY')
              continue
            }

            item.form.report_title = item.form.name

            for (var i = 0; i < item.fields.length; i++) {
              item.form.report_title =
                item.fields[i].type === 'report_title' && item.fields[i].text
                  ? item.fields[i].text
                  : item.form.name +
                    ' - ' +
                    moment(item.date_created).format('MM/DD/YYYY')
            }
          }
        })
      }

      function reportQuery() {
        var query = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.searchExecuted ? $scope.search : null,
          table: true,
          role: true,
          non_empty: true,
          tz_offset: new Date().getTimezoneOffset(),
          is_draft: false,
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return query
      }

      getReports()

      $scope.goToDeletedReportsPage = function () {
        $state.go(
          'app.forms.deleted_reports',
          {
            app: app.id,
            pageNumber: 1,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { reload: 'app.forms.reports' },
        )
      }

      $scope.goToDraftReportsPage = function () {
        $state.go(
          'app.forms.draft_reports_list',
          {
            app: app.id,
            pageNumber: 1,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { reload: 'app.forms.reports' },
        )
      }
    },
  )
