import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const FilterIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      width="64"
      height="55"
      viewBox="0 0 64 55"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <g>
        <path
          id="svg_1"
          fill="currentcolor"
          d="m64,0l0,7.65417l-22.8571,21.81213l0,25.5337l-18.2858,0l0,-25.5337l-22.8571,-21.81672l0,-7.64958l64,0z"
          clip-rule="evenodd"
          fill-rule="evenodd"
        />
      </g>
    </svg>
  )
}
