import { makeLoadResolver, resolvers } from '../resolvers'

/** @ngInject */
export function appsFormsConfig($stateProvider) {
  $stateProvider
    .state('app.forms', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
    })
    .state('app.forms.reports_view', {
      controller: 'FormReportDetailCtrl',
      templateUrl: 'app/views/form_report_view.html',
      url: '/forms/reports/:report/view?previousPageNumber&previousOrder&previousReverse&previousSearch&backTo&startDate&endDate&formId',
      resolve: {
        ...resolvers.appResolver,
        ...resolvers.forbidEditing,
        ...resolvers.PreviousStateResolver,
      },
    })
    .state('app.forms.create', {
      controller: 'FormCreateCtrl',
      templateUrl: 'app/views/form_form.html',
      url: '/forms/create',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
    .state('app.forms.edit', {
      controller: 'FormEditCtrl',
      templateUrl: 'app/views/form_form.html',
      url: '/forms/:form/edit?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
    .state('app.forms.assign', {
      controller: 'FormAssignCtrl',
      templateUrl: 'app/views/form_assign.html',
      url: '/forms/assign?pageNumber&order&search&reverse',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
    .state('app.forms.project_assign', {
      controller: 'FormProjectAssign',
      templateUrl: 'app/views/form_project_assign.html',
      url: '/forms/assign/:project?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
    .state('app.forms.custom', {
      controller: 'FormCustomCtrl',
      templateUrl: 'app/views/form_custom.html',
      url: '/forms/custom?pageNumber&order&search&reverse',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionViewFormGroups'),
      },
    })
    .state('app.forms.qr_codes', {
      controller: 'FormQRCodes',
      templateUrl: 'app/views/qr_codes_form_list.html',
      url: '/forms/qr_codes?pageNumber&order&search&reverse',
      resolve: resolvers.appResolver,
    })
    // .state('app.forms.settings', {
    //   controller: 'FormSettingsCtrl',
    //   templateUrl: 'app/views/settings_form_list.html',
    //   url: '/forms/settings?pageNumber&order&search&reverse',
    //   resolve: resolvers.appResolver,
    // })
    .state('app.forms.assign_set', {
      controller: 'FormAssignSetCtrl',
      templateUrl: 'app/views/form_assign_set.html',
      url: '/forms/sets/:formSet/assign?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
    .state('app.forms.list', {
      controller: 'FormList',
      templateUrl: 'app/views/form_list.html',
      url: '/forms?pageNumber&order&search&reverse',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
    .state('app.forms.assign_projects_to_form', {
      controller: 'FormAssignProjects',
      templateUrl: 'app/views/form_assign_projects.html',
      url: '/forms/:form/projects?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionDownloadReportDataOrCreateForm'),
      },
    })
}
