import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const CopyIconLinestyle = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 25 30"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <polygon
        style={{ fill: 'none', stroke: 'currentcolor', strokeMiterlimit: 10 }}
        points="16.25 9.55 16.25 2.68 1.88 2.68 1.88 20.45 8.75 20.45 8.75 9.55 16.25 9.55"
      />
      <polygon
        style={{ fill: 'none', stroke: 'currentcolor', strokeMiterlimit: 10 }}
        points="16.25 9.55 8.75 9.55 8.75 20.45 8.75 27.32 23.13 27.32 23.13 9.55 16.25 9.55"
      />
    </svg>
  )
}
