import {
  CheckIcon,
  EditIcon,
  ShareIcon,
  ViewIcon,
} from '@/react/componentAssets'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const ObservationRow = ({
  observation,
  index,
  selectedIds,
  setSelectedIds,
  setAllChecked,
  search,
  page,
  isDeleteAvailable,
}) => {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()

  const [hoveredField, setHoveredField] = useState(null)
  const [isLinkCopied, setLinkCopied] = useState(false)
  const {
    title,
    date_created,
    client_object_key,
    project_name,
    category_name,
    author_name,
    date_range,
    company_name,
  } = observation

  const handleCopyLink = () => {
    const currentURL = window.location.href
    const urlObject = new URL(currentURL)
    const host = urlObject.host

    navigator.clipboard.writeText(
      `https://${host}/share/batch_report/${client_object_key}`,
    )

    setLinkCopied(true)
  }

  const handleChecked = (id) => {
    setAllChecked(false)
    if (selectedIds.includes(id)) {
      setSelectedIds(selectedIds.filter((sId) => sId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  const formatDateRangeString = (dateRangeString: string) => {
    const [startDateString, endDateString] = dateRangeString.split(' - ')

    const formattedStartDate = moment(startDateString).format('MM/DD/YY')
    const formattedEndDate = moment(endDateString).format('MM/DD/YY')

    return `${formattedStartDate} - ${formattedEndDate}`
  }

  return (
    <>
      <tr
        className={classNames('view__row', {
          dark: index % 2 === 0,
        })}
      >
        {isDeleteAvailable ? (
          <td className="checkmark__td">
            <label className="checkbox-container">
              <input
                type="checkbox"
                checked={selectedIds.includes(observation?.id)}
                onChange={() => {
                  handleChecked(observation?.id)
                }}
              />
              <span className="checkmark">
                <ThinCheckmark color={variableColor} />
              </span>
            </label>
          </td>
        ) : (
          <></>
        )}
        <td
          className="title__td"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'category__fullname',
              text: title || category_name,
              max__length: 35,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <UISref
            to={'app.batch_reports.view'}
            params={{
              batch_report: observation?.id,
              previousSearch: search,
              previousPageNumber: page,
            }}
          >
            <p className="table__text category">
              <Highlighter
                highlightClassName={'highlighted__item'}
                searchWords={[search]}
                autoEscape={true}
                textToHighlight={title || category_name}
              />
            </p>
          </UISref>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'originator__fullname',
              text: author_name,
              max__length: 18,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text originator">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={author_name}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: company_name,
              max__length: 25,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text company">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={company_name}
            />
          </p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project__fullname',
              text: project_name,
              max__length: 20,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="table__text project">
            <Highlighter
              highlightClassName={'highlighted__item'}
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={project_name}
            />
          </p>
        </td>
        <td>
          <Highlighter
            highlightClassName={'highlighted__item'}
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={formatDateRangeString(date_range)}
          />
        </td>
        <td
          onClick={handleCopyLink}
          onMouseOut={() => {
            setLinkCopied(false)
          }}
          className="share__td"
        >
          <ShareIcon
            height="30px"
            width="30px"
            color={variableColor}
            additionalStyles={{
              position: 'relative',
            }}
          />
          {isLinkCopied && (
            <div className="sucess__copy">
              <CheckIcon width="11" height="8" color="green" />
              <p className="copy__message">URL copied to clipboard</p>
            </div>
          )}
        </td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
