import React from 'react'
import { css } from '@emotion/react'
import { UISref } from '@components/UISref'
import { CustomCheckbox, TableHighlight } from '@/react/components'
import { GenericClient } from '@/react/types'

type Props = {
  user: GenericClient
  idx: number
  checkField: (number) => void
}

export const ClientTableRow = ({ user, idx, checkField }: Props) => {
  return (
    <tr
      css={css({
        backgroundColor: Math.abs(idx % 2) == 1 ? 'none' : 'rgb(242, 243, 245)',
        '.username__td': {
          width: '23%',
        },
        '.fullname__td': {
          width: '23%',
        },
        '.company__td': {
          width: '23%',
        },
        '.amount__td': {
          width: '10%',
        },
        '.email__td': {
          width: '15%',
        },
      })}
    >
      <td className="username__td">
        <UISref
          to={'true_admin.admin_clients_edit'}
          params={{ id: user.user.id }}
        >
          <a>
            <TableHighlight length={26} text={user.user.username} />
          </a>
        </UISref>
      </td>
      <td className="fullname__td">
        <TableHighlight
          length={26}
          text={user.user.first_name + user.user.last_name}
        />
      </td>
      <td className="company__td">
        <TableHighlight length={26} text={user.user.company_name} />
      </td>
      <td className="amount__td">{user.users_under}</td>
      <td className="email__td">{user.user.email}</td>
      <td className="active__td">
        <CustomCheckbox
          isDefaultChecked={user.is_active}
          reversedColor={true}
          onChange={() => {
            checkField(user.id)
          }}
        />
      </td>
    </tr>
  )
}
