angular
  .module('ccs')
  .directive('completioncodevalidation', completionCodeValidation)

function completionCodeValidation() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      min: '=',
      max: '=',
    },
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          var transformedInput = text.slice(0, scope.max)
          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput)
            ngModelCtrl.$render()
          }
          return transformedInput
        }
        return undefined
      }

      ngModelCtrl.$parsers.push(fromUser)
    },
  }
}
