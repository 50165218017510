import * as React from 'react'
import { css } from '@emotion/react'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'

import { updateQueryParams } from '@/utils/updateQueryParams'

import { MinimalisticArrows } from '@/react/componentAssets'
import { useAngularServices } from '@components/ReactAngularContext'

const BUTTONS_VISIBILITY_RANGE = 5

type Props = {
  pageLength: number
  listCount: number
  currentPage: number
  additionalParameters?: any
  callback: any
  updateUrl?: boolean
}
export const PageSwitcher: React.FC<Props> = ({
  pageLength,
  listCount,
  currentPage,
  callback,
  updateUrl = true,
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const nextPage = currentPage + 1
  const beforePage = currentPage - 1
  const pageCount = Math.ceil(listCount / (pageLength || 20))
  const pages = Array.from({ length: pageCount }, (_, i) => i + 1)

  let numberButtons

  if (pages.length > 0) {
    numberButtons = pages.map((number) => {
      if (
        number === 1 ||
        number === pages.length ||
        (number > currentPage - BUTTONS_VISIBILITY_RANGE &&
          number < currentPage + BUTTONS_VISIBILITY_RANGE)
      ) {
        return (
          <button
            key={number}
            className={
              currentPage === number ? 'active button-number' : 'button-number'
            }
            onClick={() => {
              callback(number)
              if (updateUrl) updateQueryParams({ pageNumber: number }, true)
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          >
            {number}
          </button>
        )
      } else if (
        number === currentPage - BUTTONS_VISIBILITY_RANGE ||
        number === currentPage + BUTTONS_VISIBILITY_RANGE
      ) {
        return (
          <button
            key={number}
            className="button-number"
            onClick={() => {
              callback(number)
              if (updateUrl) updateQueryParams({ pageNumber: number }, true)
              window.scrollTo({
                top: 0,
                behavior: 'smooth',
              })
            }}
          >
            &hellip;
          </button>
        )
      }
    })
  }

  if (!numberButtons || numberButtons?.length === 1) return null

  const baseStyle = getBaseStyle(variableColor)

  return (
    <div css={baseStyle}>
      {currentPage > 1 ? (
        <button
          key={beforePage}
          className="arrow left active-arrow"
          onClick={() => {
            callback(beforePage)
            if (updateUrl) updateQueryParams({ pageNumber: beforePage }, true)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }}
        >
          <MinimalisticArrows height={'16px'} direction={'l'} />
        </button>
      ) : (
        <button className="arrow left">
          <MinimalisticArrows height={'16px'} direction={'l'} />
        </button>
      )}

      {numberButtons}
      {currentPage < pageCount ? (
        <button
          key={nextPage}
          className="arrow right active-arrow"
          onClick={() => {
            callback(nextPage)
            if (updateUrl) updateQueryParams({ pageNumber: nextPage }, true)
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            })
          }}
        >
          <MinimalisticArrows height={'16px'} direction={'r'} />
        </button>
      ) : (
        <button className="arrow right">
          <MinimalisticArrows height={'16px'} direction={'r'} />
        </button>
      )}
    </div>
  )
}

function getBaseStyle(variableColor) {
  return css({
    color: variableColor,
    marginTop: '20px',
    marginBottom: '20px',
    display: 'flex',
    fontSize: 11,
    button: {
      color: variableColor,
      backgroundColor: 'transparent',
      margin: 0,
      border: 'none',
      marginRight: 2,
      marginLeft: 2,
      textAlign: 'center',
    },
    '.button-number': {
      height: 20,
      minWidth: 20,
      padding: '0 3px',
      borderRadius: 10,
      ':hover': {
        backgroundColor: percentageShadeBlendColor(0.8, variableColor),
      },
    },
    '.active': {
      backgroundColor: variableColor,
      color: 'white',
      ':hover': {
        backgroundColor: variableColor,
      },
    },
    arrow: {
      height: 20,
      display: 'flex',
      alignItems: 'center',
    },
    '.arrow.active-arrow': {
      ':hover': {
        color: percentageShadeBlendColor(0.8, variableColor),
      },
    },
  })
}
