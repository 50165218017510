import * as React from 'react'
import { css } from '@emotion/react'
import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import { LinearProgress } from '@mui/material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import closeIcon from '@/assets/icons/background-x.svg'
import { FileIcon } from '@/react/componentAssets/FileIcon'
import { BulkUploadIcon } from '@/react/componentAssets/BulkUploadIcon'

type Props = {
  dropMessage: ReactJSXElement
  hoverMessage: ReactJSXElement
  addCallback?: any
  removeCallback?: any
  fileStorage: any
  fileSetStorage: any
  additionalStyles?: object
  accept?: string
  isLoading: boolean
}

export const DropZone = ({
  dropMessage = <p />,
  hoverMessage = <p />,
  addCallback,
  removeCallback,
  fileStorage,
  fileSetStorage,
  additionalStyles = {},
  accept = 'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  isLoading,
}: Props) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    addCallback()
    fileSetStorage(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  })

  function deleteFile() {
    removeCallback()
    fileSetStorage(null)
  }

  return (
    <div css={[fileUploadBase, additionalStyles]}>
      <div className="border-spread">
        <div css={fileDisplayBase}>
          {fileStorage ? (
            <div css={fileDisplay}>
              <img
                src={closeIcon}
                className="close__icon"
                alt="X"
                onClick={() => {
                  deleteFile()
                }}
              />
              <div className="file-name">
                <FileIcon className="file-icon" />
                <p className="file__text">{fileStorage?.name}</p>
              </div>
              {isLoading && (
                <LinearProgress sx={{ height: '9px', borderRadius: '10px' }} />
              )}
            </div>
          ) : null}
        </div>
        <form
          encType="multipart/form-data"
          css={dropzoneBase}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {fileStorage ? (
            ''
          ) : (
            <div className={'prop-name'}>
              <BulkUploadIcon className="upload-icon" />
              <div>
                <p className="prop-message">
                  Click to choose a .csv file or drag it here
                </p>
                <p className="prop-message">{`(No more than 2,000 rows.)`}</p>
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const fileUploadBase = css({
  marginTop: '20px',
  width: '98%',
  backgroundColor: '#F7F7F7',
  borderColor: 'rgba(229,230,231,0.5)',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '0',
  '.border-spread': {
    position: 'relative',
    borderWidth: 10,
    borderStyle: 'solid',
    borderColor: '#F7F7F7',
    width: '100%',
    height: '100%',
  },
})

const fileDisplayBase = css({
  position: 'absolute',
  width: '100%',
  height: 0,
  left: 0,
  top: '24px',
})

const dropzoneBase = css({
  zIndex: 100,
  width: '100%',
  height: '100%',
  backgroundImage: 'none',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '.prop-name': {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    gap: '35px',
    '.upload-icon': {
      width: '30%',
    },
  },
  '.prop-message': {
    color: '#B3B3B3',
    fontSize: '24px',
    textAlign: 'center',
    margin: 0,
  },
})

const fileDisplay = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  zIndex: 200,
  width: '227px',
  height: '116px',
  position: 'absolute',
  backgroundColor: 'white',
  top: '35px',
  left: '210px',
  borderColor: '#e5e6e7',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '10px',
  '@media(max-width: 990px)': {
    left: '35px',
  },
  '.close__icon': {
    cursor: 'pointer',
    width: '12px',
    alignSelf: 'flex-end',
    height: '12px',
  },
  '.file-name': {
    padding: '5px',
    backgroundColor: '#e5e6e7',
    height: '58px',
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.file-icon': {
    height: '25px',
    width: '25px',
  },
  '.file__text': {
    maxWidth: '135px',
    color: '#565656',
    fontSize: '12px',
    margin: 0,
    maxHeight: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.delete-button': {
    cursor: 'pointer',
    borderRadius: '2px',
    border: '1px solid #eee',
    textDecoration: 'none',
    display: 'block',
    padding: '4px 5px',
    textAlign: 'center',
    color: '#aaa',
  },
  '.delete-button:hover': {
    color: '#676a6c',
  },
})
