import { ButtonElement, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import React, { useEffect, useMemo, useRef } from 'react'
import { useRouter } from '@/react/hooks'

export const DownloadModal = ({ setModalVisible, selectedIds }) => {
  const modalRef = useRef()
  const { $rootScope } = useAngularServices()
  const { stateService } = useRouter()

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setModalVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const configurableToolboxNames = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  return (
    <div css={baseStyle}>
      <div className="modal__wrapper" ref={modalRef}>
        <img
          src={closeIcon}
          alt="cross-icon"
          className="close__icon"
          onClick={() => {
            setModalVisible(false)
          }}
        />
        <p className="modal__message">
          <strong>{`Downloading ${selectedIds.length} ${
            selectedIds.length > 1
              ? configurableToolboxNames?.toolbox_talks_display
              : configurableToolboxNames?.toolbox
          }`}</strong>
          Don't refresh or exit the app until it's completed.
        </p>
        <div className="modal__button">
          <ButtonElement
            buttonType="submit"
            text="OK"
            functionToTrigger={() => {
              setModalVisible(false)
            }}
          />
        </div>
      </div>
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  zIndex: 11,
  top: '300px',
  '.modal__wrapper': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    background: '#fff',
    width: '293px',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  },
  '.modal__message': {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    fontSize: '14px',
    paddingTop: '26px',
    paddingBottom: '10px',
    paddingInline: '22px',
    margin: 0,
  },
  '.close__icon': {
    position: 'absolute',
    right: '4px',
    top: '5px',
    width: '13px',
    height: '13px',
    cursor: 'pointer',
  },
  '.modal__button': {
    paddingBottom: '20px',
  },
})
