export const CountriesByCodes = {
  USA: 'United States',
  AFG: 'Afghanistan',
  ALB: 'Albania',
  ALG: 'Algeria',
  AND: 'Andorra',
  ANG: 'Angola',
  ANT: 'Antigua and Barbuda',
  ARG: 'Argentina',
  ARM: 'Armenia',
  ARU: 'Aruba',
  ASA: 'American Samoa',
  AUS: 'Australia',
  AUT: 'Austria',
  AZE: 'Azerbaijan',
  BAH: 'Bahamas',
  BAN: 'Bangladesh',
  BAR: 'Barbados',
  BDI: 'Burundi',
  BEL: 'Belgium',
  BEN: 'Benin',
  BER: 'Bermuda',
  BHU: 'Bhutan',
  BIH: 'Bosnia and Herzegovina',
  BIZ: 'Belize',
  BLR: 'Belarus',
  BOL: 'Bolivia',
  BOT: 'Botswana',
  BRA: 'Brazil',
  BRN: 'Bahrain',
  BRU: 'Brunei',
  BUL: 'Bulgaria',
  BUR: 'Burkina Faso',
  CAF: 'Central African Republic',
  CAM: 'Cambodia',
  CAN: 'Canada',
  CAY: 'Cayman Islands',
  CGO: 'Congo',
  CHA: 'Chad',
  CHI: 'Chile',
  CHN: 'China',
  CIV: "Cote d'Ivoire",
  CMR: 'Cameroon',
  COD: 'DR Congo',
  COK: 'Cook Islands',
  COL: 'Colombia',
  COM: 'Comoros',
  CPV: 'Cape Verde',
  CRC: 'Costa Rica',
  CRO: 'Croatia',
  CUB: 'Cuba',
  CYP: 'Cyprus',
  CZE: 'Czech Republic',
  DEN: 'Denmark',
  DJI: 'Djibouti',
  DMA: 'Dominica',
  DOM: 'Dominican Republic',
  ECU: 'Ecuador',
  EGY: 'Egypt',
  ERI: 'Eritrea',
  ESA: 'El Salvador',
  ESP: 'Spain',
  EST: 'Estonia',
  ETH: 'Ethiopia',
  FIJ: 'Fiji',
  FIN: 'Finland',
  FRA: 'France',
  FSM: 'Micronesia',
  GAB: 'Gabon',
  GAM: 'Gambia',
  GBR: 'Great Britain',
  GBS: 'Guinea-Bissau',
  GEO: 'Georgia',
  GEQ: 'Equatorial Guinea',
  GER: 'Germany',
  GHA: 'Ghana',
  GRE: 'Greece',
  GRN: 'Grenada',
  GUA: 'Guatemala',
  GUI: 'Guinea',
  GUM: 'Guam',
  GUY: 'Guyana',
  HAI: 'Haiti',
  HKG: 'Hong Kong',
  HON: 'Honduras',
  HUN: 'Hungary',
  INA: 'Indonesia',
  IND: 'India',
  IRI: 'Iran',
  IRL: 'Ireland',
  IRQ: 'Iraq',
  ISL: 'Iceland',
  ISR: 'Israel',
  ISV: 'Virgin Islands',
  ITA: 'Italy',
  IVB: 'British Virgin Islands',
  JAM: 'Jamaica',
  JOR: 'Jordan',
  JPN: 'Japan',
  KAZ: 'Kazakhstan',
  KEN: 'Kenya',
  KGZ: 'Kyrgyzstan',
  KIR: 'Kiribati',
  KOR: 'South Korea',
  KSA: 'Saudi Arabia',
  KUW: 'Kuwait',
  LAO: 'Laos',
  LAT: 'Latvia',
  LBA: 'Libya',
  LBR: 'Liberia',
  LCA: 'Saint Lucia',
  LES: 'Lesotho',
  LIB: 'Lebanon',
  LIE: 'Liechtenstein',
  LTU: 'Lithuania',
  LUX: 'Luxembourg',
  MAD: 'Madagascar',
  MAR: 'Morocco',
  MAS: 'Malaysia',
  MAW: 'Malawi',
  MDA: 'Moldova',
  MDV: 'Maldives',
  MEX: 'Mexico',
  MGL: 'Mongolia',
  MHL: 'Marshall Islands',
  MKD: 'Macedonia',
  MLI: 'Mali',
  MLT: 'Malta',
  MNE: 'Montenegro',
  MON: 'Monaco',
  MOZ: 'Mozambique',
  MRI: 'Mauritius',
  MTN: 'Mauritania',
  MYA: 'Myanmar',
  NAM: 'Namibia',
  NCA: 'Nicaragua',
  NED: 'Netherlands',
  NEP: 'Nepal',
  NGR: 'Nigeria',
  NIG: 'Niger',
  NOR: 'Norway',
  NRU: 'Nauru',
  NZL: 'New Zealand',
  OMA: 'Oman',
  PAK: 'Pakistan',
  PAN: 'Panama',
  PAR: 'Paraguay',
  PER: 'Peru',
  PHI: 'Philippines',
  PLE: 'Palestine',
  PLW: 'Palau',
  PNG: 'Papua New Guinea',
  POL: 'Poland',
  POR: 'Portugal',
  PRK: 'North Korea',
  PUR: 'Puerto Rico',
  QAT: 'Qatar',
  ROU: 'Romania',
  RSA: 'South Africa',
  RUS: 'Russia',
  RWA: 'Rwanda',
  SAM: 'Samoa',
  SEN: 'Senegal',
  SEY: 'Seychelles',
  SIN: 'Singapore',
  SKN: 'Saint Kitts and Nevis',
  SLE: 'Sierra Leone',
  SLO: 'Slovenia',
  SMR: 'San Marino',
  SOL: 'Solomon Islands',
  SOM: 'Somalia',
  SRB: 'Serbia',
  SRI: 'Sri Lanka',
  STP: 'Sao Tome and Principe',
  SUD: 'Sudan',
  SUI: 'Switzerland',
  SUR: 'Suriname',
  SVK: 'Slovakia',
  SWE: 'Sweden',
  SWZ: 'Swaziland',
  SYR: 'Syria',
  TAN: 'Tanzania',
  TGA: 'Tonga',
  THA: 'Thailand',
  TJA: 'Tajikistan',
  TKM: 'Turkmenistan',
  TLS: 'Timor-Leste',
  TOG: 'Togo',
  TPE: 'Chinese Taipei',
  TRI: 'Trinidad and Tobago',
  TUN: 'Tunisia',
  TUR: 'Turkey',
  TUV: 'Tuvalu',
  UAE: 'United Arab Emirates',
  UGA: 'Uganda',
  UKR: 'Ukraine',
  URU: 'Uruguay',
  UZB: 'Uzbekistan',
  VAN: 'Vanuatu',
  VEN: 'Venezuela',
  VIE: 'Vietnam',
  VIN: 'Saint Vincent and the Grenadines',
  YEM: 'Yemen',
  ZAM: 'Zambia',
  ZIM: 'Zimbabwe',
}

export const CountriesForSelect: { value: string; label: string }[] = [
  {
    value: 'USA',
    label: 'United States',
  },
  {
    value: 'AFG',
    label: 'Afghanistan',
  },
  {
    value: 'ALB',
    label: 'Albania',
  },
  {
    value: 'ALG',
    label: 'Algeria',
  },
  {
    value: 'AND',
    label: 'Andorra',
  },
  {
    value: 'ANG',
    label: 'Angola',
  },
  {
    value: 'ANT',
    label: 'Antigua and Barbuda',
  },
  {
    value: 'ARG',
    label: 'Argentina',
  },
  {
    value: 'ARM',
    label: 'Armenia',
  },
  {
    value: 'ARU',
    label: 'Aruba',
  },
  {
    value: 'ASA',
    label: 'American Samoa',
  },
  {
    value: 'AUS',
    label: 'Australia',
  },
  {
    value: 'AUT',
    label: 'Austria',
  },
  {
    value: 'AZE',
    label: 'Azerbaijan',
  },
  {
    value: 'BAH',
    label: 'Bahamas',
  },
  {
    value: 'BAN',
    label: 'Bangladesh',
  },
  {
    value: 'BAR',
    label: 'Barbados',
  },
  {
    value: 'BDI',
    label: 'Burundi',
  },
  {
    value: 'BEL',
    label: 'Belgium',
  },
  {
    value: 'BEN',
    label: 'Benin',
  },
  {
    value: 'BER',
    label: 'Bermuda',
  },
  {
    value: 'BHU',
    label: 'Bhutan',
  },
  {
    value: 'BIH',
    label: 'Bosnia and Herzegovina',
  },
  {
    value: 'BIZ',
    label: 'Belize',
  },
  {
    value: 'BLR',
    label: 'Belarus',
  },
  {
    value: 'BOL',
    label: 'Bolivia',
  },
  {
    value: 'BOT',
    label: 'Botswana',
  },
  {
    value: 'BRA',
    label: 'Brazil',
  },
  {
    value: 'BRN',
    label: 'Bahrain',
  },
  {
    value: 'BRU',
    label: 'Brunei',
  },
  {
    value: 'BUL',
    label: 'Bulgaria',
  },
  {
    value: 'BUR',
    label: 'Burkina Faso',
  },
  {
    value: 'CAF',
    label: 'Central African Republic',
  },
  {
    value: 'CAM',
    label: 'Cambodia',
  },
  {
    value: 'CAN',
    label: 'Canada',
  },
  {
    value: 'CAY',
    label: 'Cayman Islands',
  },
  {
    value: 'CGO',
    label: 'Congo',
  },
  {
    value: 'CHA',
    label: 'Chad',
  },
  {
    value: 'CHI',
    label: 'Chile',
  },
  {
    value: 'CHN',
    label: 'China',
  },
  {
    value: 'CIV',
    label: "Cote d'Ivoire",
  },
  {
    value: 'CMR',
    label: 'Cameroon',
  },
  {
    value: 'COD',
    label: 'DR Congo',
  },
  {
    value: 'COK',
    label: 'Cook Islands',
  },
  {
    value: 'COL',
    label: 'Colombia',
  },
  {
    value: 'COM',
    label: 'Comoros',
  },
  {
    value: 'CPV',
    label: 'Cape Verde',
  },
  {
    value: 'CRC',
    label: 'Costa Rica',
  },
  {
    value: 'CRO',
    label: 'Croatia',
  },
  {
    value: 'CUB',
    label: 'Cuba',
  },
  {
    value: 'CYP',
    label: 'Cyprus',
  },
  {
    value: 'CZE',
    label: 'Czech Republic',
  },
  {
    value: 'DEN',
    label: 'Denmark',
  },
  {
    value: 'DJI',
    label: 'Djibouti',
  },
  {
    value: 'DMA',
    label: 'Dominica',
  },
  {
    value: 'DOM',
    label: 'Dominican Republic',
  },
  {
    value: 'ECU',
    label: 'Ecuador',
  },
  {
    value: 'EGY',
    label: 'Egypt',
  },
  {
    value: 'ERI',
    label: 'Eritrea',
  },
  {
    value: 'ESA',
    label: 'El Salvador',
  },
  {
    value: 'ESP',
    label: 'Spain',
  },
  {
    value: 'EST',
    label: 'Estonia',
  },
  {
    value: 'ETH',
    label: 'Ethiopia',
  },
  {
    value: 'FIJ',
    label: 'Fiji',
  },
  {
    value: 'FIN',
    label: 'Finland',
  },
  {
    value: 'FRA',
    label: 'France',
  },
  {
    value: 'FSM',
    label: 'Micronesia',
  },
  {
    value: 'GAB',
    label: 'Gabon',
  },
  {
    value: 'GAM',
    label: 'Gambia',
  },
  {
    value: 'GBR',
    label: 'Great Britain',
  },
  {
    value: 'GBS',
    label: 'Guinea-Bissau',
  },
  {
    value: 'GEO',
    label: 'Georgia',
  },
  {
    value: 'GEQ',
    label: 'Equatorial Guinea',
  },
  {
    value: 'GER',
    label: 'Germany',
  },
  {
    value: 'GHA',
    label: 'Ghana',
  },
  {
    value: 'GRE',
    label: 'Greece',
  },
  {
    value: 'GRN',
    label: 'Grenada',
  },
  {
    value: 'GUA',
    label: 'Guatemala',
  },
  {
    value: 'GUI',
    label: 'Guinea',
  },
  {
    value: 'GUM',
    label: 'Guam',
  },
  {
    value: 'GUY',
    label: 'Guyana',
  },
  {
    value: 'HAI',
    label: 'Haiti',
  },
  {
    value: 'HKG',
    label: 'Hong Kong',
  },
  {
    value: 'HON',
    label: 'Honduras',
  },
  {
    value: 'HUN',
    label: 'Hungary',
  },
  {
    value: 'INA',
    label: 'Indonesia',
  },
  {
    value: 'IND',
    label: 'India',
  },
  {
    value: 'IRI',
    label: 'Iran',
  },
  {
    value: 'IRL',
    label: 'Ireland',
  },
  {
    value: 'IRQ',
    label: 'Iraq',
  },
  {
    value: 'ISL',
    label: 'Iceland',
  },
  {
    value: 'ISR',
    label: 'Israel',
  },
  {
    value: 'ISV',
    label: 'Virgin Islands',
  },
  {
    value: 'ITA',
    label: 'Italy',
  },
  {
    value: 'IVB',
    label: 'British Virgin Islands',
  },
  {
    value: 'JAM',
    label: 'Jamaica',
  },
  {
    value: 'JOR',
    label: 'Jordan',
  },
  {
    value: 'JPN',
    label: 'Japan',
  },
  {
    value: 'KAZ',
    label: 'Kazakhstan',
  },
  {
    value: 'KEN',
    label: 'Kenya',
  },
  {
    value: 'KGZ',
    label: 'Kyrgyzstan',
  },
  {
    value: 'KIR',
    label: 'Kiribati',
  },
  {
    value: 'KOR',
    label: 'South Korea',
  },
  {
    value: 'KSA',
    label: 'Saudi Arabia',
  },
  {
    value: 'KUW',
    label: 'Kuwait',
  },
  {
    value: 'LAO',
    label: 'Laos',
  },
  {
    value: 'LAT',
    label: 'Latvia',
  },
  {
    value: 'LBA',
    label: 'Libya',
  },
  {
    value: 'LBR',
    label: 'Liberia',
  },
  {
    value: 'LCA',
    label: 'Saint Lucia',
  },
  {
    value: 'LES',
    label: 'Lesotho',
  },
  {
    value: 'LIB',
    label: 'Lebanon',
  },
  {
    value: 'LIE',
    label: 'Liechtenstein',
  },
  {
    value: 'LTU',
    label: 'Lithuania',
  },
  {
    value: 'LUX',
    label: 'Luxembourg',
  },
  {
    value: 'MAD',
    label: 'Madagascar',
  },
  {
    value: 'MAR',
    label: 'Morocco',
  },
  {
    value: 'MAS',
    label: 'Malaysia',
  },
  {
    value: 'MAW',
    label: 'Malawi',
  },
  {
    value: 'MDA',
    label: 'Moldova',
  },
  {
    value: 'MDV',
    label: 'Maldives',
  },
  {
    value: 'MEX',
    label: 'Mexico',
  },
  {
    value: 'MGL',
    label: 'Mongolia',
  },
  {
    value: 'MHL',
    label: 'Marshall Islands',
  },
  {
    value: 'MKD',
    label: 'Macedonia',
  },
  {
    value: 'MLI',
    label: 'Mali',
  },
  {
    value: 'MLT',
    label: 'Malta',
  },
  {
    value: 'MNE',
    label: 'Montenegro',
  },
  {
    value: 'MON',
    label: 'Monaco',
  },
  {
    value: 'MOZ',
    label: 'Mozambique',
  },
  {
    value: 'MRI',
    label: 'Mauritius',
  },
  {
    value: 'MTN',
    label: 'Mauritania',
  },
  {
    value: 'MYA',
    label: 'Myanmar',
  },
  {
    value: 'NAM',
    label: 'Namibia',
  },
  {
    value: 'NCA',
    label: 'Nicaragua',
  },
  {
    value: 'NED',
    label: 'Netherlands',
  },
  {
    value: 'NEP',
    label: 'Nepal',
  },
  {
    value: 'NGR',
    label: 'Nigeria',
  },
  {
    value: 'NIG',
    label: 'Niger',
  },
  {
    value: 'NOR',
    label: 'Norway',
  },
  {
    value: 'NRU',
    label: 'Nauru',
  },
  {
    value: 'NZL',
    label: 'New Zealand',
  },
  {
    value: 'OMA',
    label: 'Oman',
  },
  {
    value: 'PAK',
    label: 'Pakistan',
  },
  {
    value: 'PAN',
    label: 'Panama',
  },
  {
    value: 'PAR',
    label: 'Paraguay',
  },
  {
    value: 'PER',
    label: 'Peru',
  },
  {
    value: 'PHI',
    label: 'Philippines',
  },
  {
    value: 'PLE',
    label: 'Palestine',
  },
  {
    value: 'PLW',
    label: 'Palau',
  },
  {
    value: 'PNG',
    label: 'Papua New Guinea',
  },
  {
    value: 'POL',
    label: 'Poland',
  },
  {
    value: 'POR',
    label: 'Portugal',
  },
  {
    value: 'PRK',
    label: 'North Korea',
  },
  {
    value: 'PUR',
    label: 'Puerto Rico',
  },
  {
    value: 'QAT',
    label: 'Qatar',
  },
  {
    value: 'ROU',
    label: 'Romania',
  },
  {
    value: 'RSA',
    label: 'South Africa',
  },
  {
    value: 'RUS',
    label: 'Russia',
  },
  {
    value: 'RWA',
    label: 'Rwanda',
  },
  {
    value: 'SAM',
    label: 'Samoa',
  },
  {
    value: 'SEN',
    label: 'Senegal',
  },
  {
    value: 'SEY',
    label: 'Seychelles',
  },
  {
    value: 'SIN',
    label: 'Singapore',
  },
  {
    value: 'SKN',
    label: 'Saint Kitts and Nevis',
  },
  {
    value: 'SLE',
    label: 'Sierra Leone',
  },
  {
    value: 'SLO',
    label: 'Slovenia',
  },
  {
    value: 'SMR',
    label: 'San Marino',
  },
  {
    value: 'SOL',
    label: 'Solomon Islands',
  },
  {
    value: 'SOM',
    label: 'Somalia',
  },
  {
    value: 'SRB',
    label: 'Serbia',
  },
  {
    value: 'SRI',
    label: 'Sri Lanka',
  },
  {
    value: 'STP',
    label: 'Sao Tome and Principe',
  },
  {
    value: 'SUD',
    label: 'Sudan',
  },
  {
    value: 'SUI',
    label: 'Switzerland',
  },
  {
    value: 'SUR',
    label: 'Suriname',
  },
  {
    value: 'SVK',
    label: 'Slovakia',
  },
  {
    value: 'SWE',
    label: 'Sweden',
  },
  {
    value: 'SWZ',
    label: 'Swaziland',
  },
  {
    value: 'SYR',
    label: 'Syria',
  },
  {
    value: 'TAN',
    label: 'Tanzania',
  },
  {
    value: 'TGA',
    label: 'Tonga',
  },
  {
    value: 'THA',
    label: 'Thailand',
  },
  {
    value: 'TJA',
    label: 'Tajikistan',
  },
  {
    value: 'TKM',
    label: 'Turkmenistan',
  },
  {
    value: 'TLS',
    label: 'Timor-Leste',
  },
  {
    value: 'TOG',
    label: 'Togo',
  },
  {
    value: 'TPE',
    label: 'Chinese Taipei',
  },
  {
    value: 'TRI',
    label: 'Trinidad and Tobago',
  },
  {
    value: 'TUN',
    label: 'Tunisia',
  },
  {
    value: 'TUR',
    label: 'Turkey',
  },
  {
    value: 'TUV',
    label: 'Tuvalu',
  },
  {
    value: 'UAE',
    label: 'United Arab Emirates',
  },
  {
    value: 'UGA',
    label: 'Uganda',
  },
  {
    value: 'UKR',
    label: 'Ukraine',
  },
  {
    value: 'URU',
    label: 'Uruguay',
  },
  {
    value: 'UZB',
    label: 'Uzbekistan',
  },
  {
    value: 'VAN',
    label: 'Vanuatu',
  },
  {
    value: 'VEN',
    label: 'Venezuela',
  },
  {
    value: 'VIE',
    label: 'Vietnam',
  },
  {
    value: 'VIN',
    label: 'Saint Vincent and the Grenadines',
  },
  {
    value: 'YEM',
    label: 'Yemen',
  },
  {
    value: 'ZAM',
    label: 'Zambia',
  },
  {
    value: 'ZIM',
    label: 'Zimbabwe',
  },
]
