angular
  .module('ccs')
  .controller(
    'CategoryCustomProjectsCtrl',
    function ($scope, $state, $stateParams, Api, app, CurrentUser, $log) {
      $log.debug('CategoryCustomProjectsCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.pageNumber = 1
      $scope.projects = []
      $scope.totalItems = 0
      $scope.category = {}
      $scope.reverse = false
      $scope.order = 'name'
      $scope.breadcrumbs = [
        { title: app.name },
        {
          title: 'Custom ' + app.categories,
          url: 'app.categories.custom({app: ' + app.id + '})',
        },
      ]

      $scope.app = app

      $scope.tableHeaders = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'active_for_category',
          display: 'Inactive/Active',
          sortable: true,
          toggleAllBtn: true
        },
      ]
      const previousPageNumber = $stateParams.previousPageNumber
      var categoryID = $stateParams.category

      Api.Categories.byID(categoryID, function (resp) {
        $scope.category = resp
        $scope.breadcrumbs.push({
          title: resp.name,
        })
      })

      function query() {
        let q = {
          client: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_for_category: categoryID,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      function getProjects() {
        Api.Projects.get(query(), function (resp) {
          var projects = resp.results.map(function (p) {
            p.is_active = false
            return p
          })
          $scope.totalItems = resp.count

          var projectIds = resp.results.map(function (p) {
            return p.id
          })
          var projectQuery = { projects: projectIds.join() }

          Api.AppProjects.get(
            { ...projectQuery, app: app.id },
            function (resp) {
              $scope.projects = projects.map(function (project) {
                resp.results.forEach(function (appProject) {
                  if (project.id === appProject.project.id) {
                    project.appProject = appProject
                  }
                })

                if (project.appProject) {
                  project.active_for_category =
                    project.appProject.categories.indexOf(
                      parseInt(categoryID),
                    ) > -1
                } else {
                  project.active_for_category = false
                }

                return project
              })
            },
          )
        })
      }

      var updateCategories = function updateCategories(data, cb) {
        Api.AppProjects.patch(data, function (resp) {
          if (cb) return cb(resp)
        })
      }

      getProjects()

      $scope.changePage = () => {
        getProjects()
      }
      $scope.toggleAll = () => {
        Api.post(
          `categories/${categoryID}/assign_all_projects`,
          {
            id: categoryID,
            application: app.id,
            on: $scope.active,
            search: $scope.search,
          },
          (resp) => {
            getProjects()
          },
        )
      }

      $scope.toggleActive = function (project) {
        var hasAppProject = !!project.appProject

        if (!hasAppProject) {
          Api.AppProjects.post(
            {
              app: app.id,
              client: CurrentUser.getClientId(),
              project: project.id,
            },
            function (resp) {
              updateCategories({
                id: resp.id,
                categories: [categoryID],
              })
            },
          )
        } else {
          if (project.active_for_category) {
            project.appProject.categories.push(categoryID)
          } else {
            project.appProject.categories =
              project.appProject.categories.filter((cat) => cat != categoryID)
          }

          updateCategories({
            id: project.appProject.id,
            categories: project.appProject.categories,
          })
        }
      }

      $scope.backToSearch = function () {
        $state.go('app.categories.custom', {
          app: app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          search: $scope.previousSearch,
          reverse: $scope.previousReverse,
        })
      }
    },
  )
