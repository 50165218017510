import * as React from 'react'
import { css } from '@emotion/react'
import moment from 'moment'
import {
  ReloadElement,
  AdvancedFilter,
  ButtonElement,
  DateSwitcher,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { UISref } from '@components/UISref'

import { parseInt } from 'lodash'
import { useRouter } from '@/react/hooks'
import { singleHeaderTypes } from '@screens/components'
import {
  CreateTimeSheetPopUp,
  TimeSheetTableBody,
  ClosingAndExportElement,
} from './index'
import { getFirstAndLastWeekDate } from '@/react/components'

type Props = { voidPage: boolean }

const PAGE_LENGTH = 20

const todayDate = new Date()

export const ReloadableElements: React.FC<Props> = ({ voidPage }: Props) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [count, setCount] = React.useState<number>(0)
  const [voidedCount, setVoidedCount] = React.useState<number>(0)
  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [order, setOrder] = React.useState(
    stateService.params.order || 'supervisor_full_name',
  )
  const [period, setPeriod] = React.useState(
    stateService.params.period || getFirstAndLastWeekDate(todayDate),
  )
  const [popUp, setPopUp] = React.useState(null)
  const [advancedFilters, setAdvancedFilters] = React.useState(
    getFilterStates(stateService),
  )
  const [search, setSearch] = React.useState(stateService.params.search || '')
  const [reload, setReload] = React.useState(false)
  const [reloadCloseAll, setReloadCloseAll] = React.useState(false)
  const [editableWeek] = React.useState<boolean>(
    !stateService.params.period
      ? true
      : moment(stateService.params.period.split(',')[0]).week() ===
          moment.utc().week(),
  )

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'supervisor',
      name: 'Supervisor',
      type: 'supervisor_full_name',
      // filterHeader: true,
      additionalStyles: {
        width: '14%',
        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'supervisor_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'user',
      name: 'Employee',
      type: 'user_full_name',
      // filterHeader: true,
      additionalStyles: {
        width: '14%',
        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'user_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'stage_value',
      name: 'Stage',
      type: 'stage_value',
      // filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'stage_value'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'Mon',
      name: 'Mon',
      type: 'monday_hours',
      filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'center',
      },
    },
    {
      className: 'Tues',
      name: 'Tues',
      type: 'tuesday_hours',
      filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'center',
      },
    },
    {
      className: 'Wed',
      name: 'Wed',
      type: 'wednesday_hours',
      filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'center',
      },
    },
    {
      className: 'Thurs',
      name: 'Thurs',
      type: 'thursday_hours',
      filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'center',
      },
    },
    {
      className: 'Friday',
      name: 'Friday',
      type: 'friday_hours',
      filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'center',
      },
    },
    {
      className: 'Sat',
      name: 'Sat',
      type: 'saturday_hours',
      filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'center',
      },
    },
    {
      className: 'Sun',
      name: 'Sun',
      type: 'sunday_hours',
      filterHeader: true,
      additionalStyles: {
        width: '7%',

        textAlign: 'center',
      },
    },
    {
      className: 'total_H',
      name: 'Total Hours',
      type: 'hours',
      // filterHeader: true,
      additionalStyles: {
        width: '10%',
        '.header-sort-arrow': {
          marginLeft: '5px',
        },
      },
    },
    {
      className: 'pdf',
      name: 'PDF',
      type: 'pdf',
      // filterHeader: true,
      additionalStyles: {
        display: !voidPage ? 'table-cell' : 'none',
        width: '4%',
        textAlign: 'center',
      },
    },
    {
      className: voidPage ? 'Restore' : 'Void',
      name: voidPage ? 'Restore' : 'Void',
      type: voidPage ? 'restore' : 'void',
      // filterHeader: true,
      adminOnly: true,
      additionalStyles: {
        display:
          CurrentUser.getRole() === 'client_admin' ? 'table-cell' : 'none',
        width: '4%',
        textAlign: 'center',
      },
    },
  ]

  const baseStyle = css({
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: 'white',
    marginLeft: 1,
    table: {
      margin: '20px 25px',
      marginTop: voidPage ? 5 : 20,
      minWidth: 'calc(100% - 50px)',
      width: 1120,
      '.static-nav': {
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#D9D9D9',
      },
    },
    '.function-row': {
      padding: '20px 25px',
      paddingTop: 0,
      paddingBottom: voidPage ? 0 : 20,
      display: 'flex',
      justifyContent: 'space-between',
      width: '-moz-available',
      width: '-webkit-fill-available',
      width: 'fill-available',
      flexWrap: 'wrap',
      '.first-responsive-row': {
        marginTop: voidPage ? 0 : 20,
        display: 'flex',
        width: 500,
        justifyContent: 'space-between',
        paddingRight: 30,
      },
      '.secondary-responsive-row': {
        marginTop: voidPage ? 0 : 20,
        display: 'flex',
        width: editableWeek ? 560 : 400,
        justifyContent: 'space-between',
        '@media (max-width: 1360px)': {
          width: editableWeek ? '100%' : 400,
        },
        '@media (max-width: 1199px)': {
          width: '100%',
        },
      },
      '.void-page-button': {
        cursor: 'pointer',
      },
    },
    '.page-switcher-base': {
      marginLeft: '20px',
    },
    '.dual-color-table': {
      marginTop: 0,
    },
    '.table-container': {
      overflowX: 'scroll',
      paddingBottom: 300,
      marginBottom: -300,
    },
    '.blue-count-text': {
      fontWeight: 600,
      color: variableColor,
      fontStyle: 'normal',
    },
  })

  let _isMounted = false

  React.useEffect(() => {
    // componentWillMount
    _isMounted = true
    // componentWillUnmount
    return () => {
      // Your code here
      _isMounted = false
    }
  }, [])

  async function fetchData() {
    const { data } = await Api.getWithoutDeletedParam('timesheets', {
      deleted: true,
      page_size: 1,
      period: period,
    })
    if (_isMounted) {
      setVoidedCount(data.count)
    }
  }

  React.useEffect(() => {
    if (!voidPage) {
      fetchData()
    }
  }, [])

  return (
    <section css={baseStyle}>
      {popUp}
      <div className="function-row">
        <div className="first-responsive-row">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
          />
          {CurrentUser.getRole() !== 'client_admin' || voidPage ? null : (
            <UISref
              to={'app.timecard.voided'}
              params={{ app: stateService.params.app, period: period }}
              key={'uiref'}
            >
              <div className="void-page-button">
                <h3 className="status-grey-italic">
                  Voided: <span className="blue-count-text">{voidedCount}</span>
                </h3>
              </div>
            </UISref>
          )}
        </div>
        {voidPage ? null : (
          <div className="secondary-responsive-row">
            {!editableWeek ? null : (
              <ButtonElement
                text={'+ Add Timesheet'}
                height={'30px'}
                fontSize={'12px'}
                minWidth={'146px'}
                key={'button'}
                functionToTrigger={() => {
                  setPopUp(
                    <CreateTimeSheetPopUp
                      setPage={setPage}
                      setPopUp={setPopUp}
                      setReload={(bool) => {
                        setReload(bool)
                        setReloadCloseAll(bool)
                      }}
                    />,
                  )
                }}
              />
            )}
            {reloadCloseAll ? (
              <ReloadElement setReload={setReloadCloseAll} key={'reload'} />
            ) : (
              CurrentUser.getRole() !== 'client_admin' || (
                <ClosingAndExportElement
                  key={'CEE'}
                  payRollPeriod={period}
                  setReload={setReload}
                  count={count}
                />
              )
            )}
            <DateSwitcher
              pageCallback={setPage}
              dateCallback={setPeriod}
              date={period}
              setReload={setReloadCloseAll}
            />
          </div>
        )}
      </div>
      <div className="table-container">
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            popUpLocation={false}
            callback={setOrder}
            stickyElement
          />
          {reload ? (
            <ReloadElement setReload={setReload} />
          ) : (
            <TimeSheetTableBody
              order={order}
              search={search}
              page={page}
              period={period}
              setCount={setCount}
              advancedFilters={advancedFilters}
              voidPage={voidPage}
              reloadCloseAll={setReloadCloseAll}
              voidedCount={voidedCount}
              setVoidedCount={setVoidedCount}
            />
          )}
        </table>
      </div>
      <div className="page-switcher-base">
        <PageSwitcher
          pages={PAGE_LENGTH}
          listCount={count}
          currentPage={page}
          callback={setPage}
        />
      </div>
    </section>
  )
}

function getFilterStates(stateService) {
  const filtersToReturn = {
    supervisor_full_name: {},
    user_full_name: {},
    stage_value: {},
  }
  if (stateService.params.supervisor_full_name)
    filtersToReturn.supervisor_full_name = JSON.parse(
      stateService.params.supervisor_full_name,
    )
  if (stateService.params.user_full_name)
    filtersToReturn.user_full_name = JSON.parse(
      stateService.params.user_full_name,
    )
  if (stateService.params.stage_value)
    filtersToReturn.stage_value = JSON.parse(stateService.params.stage_value)
  return filtersToReturn
}
