import * as React from 'react'
import { useCallback } from 'react'
import { css } from '@emotion/react'
import { useDropzone } from 'react-dropzone'

import { validateFileName } from '@/utils/fileValidation'

export function FileField({ uploadFile, onChange, file, setFile }: any) {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files

    const fileError = validateFileName(
      acceptedFiles[0].name.substring(0, acceptedFiles[0].name.length - 4),
    )

    if (fileError === false) {
      uploadFile(acceptedFiles[0], {}, (callback) => {
        onChange(callback.id)
        setFile(callback)
      })
    } else {
      alert(fileError[0])
    }
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'application/pdf',
  })

  function deleteFile() {
    onChange('')
    setFile('')
  }

  return (
    <div css={fileUploadBase}>
      <label>Upload File</label>
      <div css={fileDisplayBase}>
        {file ? (
          <div css={fileDisplay}>
            <div className="file-name">{file.file.split('/').pop()}</div>
            <div
              className="delete-button"
              onClick={() => {
                deleteFile()
              }}
            >
              Remove File
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      <div css={dropzoneBase} {...getRootProps()}>
        <input {...getInputProps()} />
        {file ? (
          ''
        ) : isDragActive ? (
          <div className={'prop-name'}>Drop the PDF here ...</div>
        ) : (
          <div className={'prop-name'}>Drag PDF or click to Upload</div>
        )}
      </div>
    </div>
  )
}

const fileUploadBase = css({
  marginTop: '20px',
  width: '98%',
  marginRight: '1%',
  marginLeft: '1%',
  position: 'relative',
})

const dropzoneBase = css({
  zIndex: 100,
  width: '100%',
  height: '300px',
  backgroundColor: '#F7F7F7',
  backgroundImage: 'none',
  borderColor: '#e5e6e7',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '.prop-name': {
    width: '60%',
    textAlign: 'center',
    fontSize: '30px',
  },
})
const fileDisplayBase = css({
  position: 'absolute',
  width: '100%',
  height: 0,
  left: 0,
  top: '24px',
})
const fileDisplay = css({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  zIndex: 200,
  width: '140px',
  height: '180px',
  position: 'absolute',
  backgroundColor: 'white',
  top: '35px',
  left: '35px',
  borderColor: '#e5e6e7',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '10px',
  '.file-name': {
    padding: '5px',
    backgroundColor: '#e5e6e7',
    width: '100%',
    height: '75%',
  },
  '.delete-button': {
    cursor: 'pointer',
    borderRadius: '2px',
    border: '1px solid #eee',
    textDecoration: 'none',
    display: 'block',
    padding: '4px 5px',
    textAlign: 'center',
    color: '#aaa',
  },
  '.delete-button:hover': {
    color: '#676a6c',
  },
})
