import React, { useState } from 'react'
import { css } from '@emotion/react'
import StringUtil from '@/utils/autolinks'

type Props = {
  title: string
  content: string
}
export const Accordion: React.FC<Props> = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false)

  return (
    <div css={baseStyle}>
      <div
        className="accordion-title-wrapper"
        onClick={() => setIsActive(!isActive)}
      >
        <div className="accordion-title">{title}</div>
        <div
          className={`header-sort-arrow fa fa-angle-${
            isActive ? 'up' : 'down'
          }`}
        />
      </div>
      {isActive && (
        <div
          className="accordion-content"
          style={{ whiteSpace: 'pre-wrap' }}
          dangerouslySetInnerHTML={{
            __html: StringUtil.parseHyperlinks(content),
          }}
        ></div>
      )}
    </div>
  )
}

const baseStyle = css({
  '.accordion-title-wrapper': {
    position: 'relative',
    background: '#EFF6FA',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '54px',
  },
  '.accordion-title': {
    color: '#676A6B',
    fontSize: '14px',
    fontWeight: 700,
  },
  '.header-sort-arrow': {
    position: 'absolute',
    top: '15px',
    right: '15px',
    margin: '0 0 0 auto',
    fontSize: '26px',
  },
  '.accordion-content': {
    color: '#676A6B',
    fontSize: '14px',
    fontWeight: 400,
    padding: '8px',
    /* These are technically the same, but use both */
    overflowWrap: 'break-word',
    wordWrap: 'break-word',

    msWordBreak: 'break-all',
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    // wordBreak: 'break-all',
    /* Instead use this non-standard one: */
    wordBreak: 'break-word',

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    msHyphens: 'auto',
    mozHyphens: 'auto',
    webkitHyphens: 'auto',
    hyphens: 'auto',
  },
})
