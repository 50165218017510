angular
  .module('ccs')
  .controller(
    'LessonFormCtrl',
    function (
      $scope,
      Notification,
      Api,
      $state,
      $stateParams,
      $uibModal,
      metadataService,
      $log,
    ) {
      $log.debug('LessonFormCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse == 'true'
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.prevousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.metadataService = metadataService

      $scope.lesson = { files: [], images: [], synchronised: true }
      $scope.apps = []
      $scope.users = []
      $scope.priority_choices = []
      $scope.type_choices = []
      $scope.category_choices = []

      $scope.popup = {
        opened: false,
      }

      $scope.dateOptions = {
        formatYear: 'yy',
        minDate: new Date(),
        datepickerMode: 'day',
      }

      $scope.deletedPhotosId = []

      $scope.open = function () {
        $scope.popup.opened = true
      }

      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo

        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'lg',
          scope: $scp,
        })
      }

      var editing = false

      if ($stateParams.lessonID) {
        editing = true
        Api.Lessons.byID($stateParams.lessonID, function (resp) {
          $scope.lesson = resp
          $scope.due_date = resp.due_date ? new Date(resp.due_date) : null

          $scope.synchronised = resp.synchronised
          if (!resp.synchronised) {
            $scope.goBack()
          }
        })
      } else {
        $scope.due_date = new Date()
      }

      Api.get('lessons/priorities', null, function (resp) {
        $scope.priority_choices = resp
      })

      Api.get('lessons/types', null, function (resp) {
        $scope.type_choices = resp
      })

      Api.get('lessons/categories', null, function (resp) {
        $scope.category_choices = resp
      })

      Api.Apps.get({ myapps: true }, function (resp) {
        $scope.apps = resp.results
      })

      function updateHandler(resp) {
        if (resp) {
          var action = editing ? 'Updated!' : 'Created!'
          Notification.success('Lesson Successfully ' + action)
          $scope.goBack()
        }
      }

      function ids(obj) {
        return obj.id
      }

      function formatDate(date) {
        if (!date) return null
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      function validSave() {
        return (
          $scope.lesson.priority &&
          $scope.lesson.description &&
          $scope.lesson.application &&
          $scope.lesson.category &&
          $scope.lesson.type
        )
      }

      $scope.submit = function (e) {
        if (!validSave()) return

        function lessonRequest() {
          const lesson = {
            ...$scope.lesson,
            files: $scope.lesson.files.map(ids),
            images: $scope.lesson.images.map(ids),
            due_date: formatDate($scope.due_date),
          }

          if (editing) {
            Api.Lessons.patch(lesson, updateHandler)
          } else {
            Api.Lessons.post(lesson, updateHandler)
          }
        }

        if ($scope.deletedPhotosId.length > 0) {
          var promiseList = []

          for (var i = 0; i < $scope.deletedPhotosId.length; i++) {
            var photoID = $scope.deletedPhotosId[i]
            promiseList.push(
              new Promise(function (resolve, reject) {
                Api.removeImage(
                  photoID,
                  function (resp) {
                    return resolve()
                  },
                  function (error) {
                    Notification.danger(error)
                    return reject()
                  },
                )
              }),
            )
          }
          Promise.all(promiseList).then(function (values) {
            lessonRequest()
          })
        } else {
          lessonRequest()
        }
      }

      Api.Users.get('no_pagination=true', function (resp) {
        $scope.users = resp.results
      })

      $scope.addPhoto = function (input) {
        if (
          input.files[0].type == 'image/png' ||
          input.files[0].type == 'image/jpeg'
        ) {
          Api.uploadImage(input.files[0], {}, function (response) {
            var image = response

            $scope.lesson.images.push(image)
            input.value = null
          })
        } else {
          Notification.danger(
            'Invalid image file format. Please select a png or jpg file.',
          )
          setTimeout(function () {
            $scope.$apply()
          }, 0)
        }
      }

      $scope.addFile = function (input) {
        Api.uploadFile(input.files[0], {}, function (response) {
          var file = response

          $scope.lesson.files.push(file)
          input.value = null
        })
      }

      $scope.parseName = function (name) {
        var tmp = name.split('/')
        return tmp.pop()
      }

      $scope.removePhoto = function (photoID) {
        $scope.deletedPhotosId.push(photoID)
        $scope.lesson.images = $scope.lesson.images.filter(function (p) {
          return p.id !== photoID
        })
      }

      $scope.removeFile = function (fileID) {
        $scope.lesson.files = $scope.lesson.files.filter(function (f) {
          return f.id !== fileID
        })
      }

      $scope.goBack = function () {
        $state.go(
          'lessons.list',
          {
            pageNumber: $scope.previousPageNumber,
            reverse: $scope.previousReverse,
            order: $scope.previousOrder,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }
    },
  )
