import * as React from 'react'
import { TextInput } from '@/react/components'
import { css } from '@emotion/react'
import { getTranslatedString } from '@/utils'

import { getStartingValue, NoteElement } from './index'

export const TextField = ({
  fieldData,
  formMemory,
  possibleGroupData,
  onChange,
}) => {
  const [error, setError] = React.useState(false)

  function callback(value) {
    onChange({ value, possibleGroupData, id: fieldData.id })
    setError(!value && fieldData.required)
  }

  const baseStyle = getBaseStyle()

  const startingText = getStartingValue(
    possibleGroupData,
    formMemory,
    fieldData,
    'text',
  )

  React.useEffect(() => {}, [error])

  React.useEffect(() => {
    if (fieldData.required && !startingText) {
      setError(true)
    }
  }, [])

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="label-holder">
        <label css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      <TextInput
        defaultValue={startingText}
        valueCallback={callback}
        width={'400px'}
        waitTime={600}
        textarea
        error={error}
      />
    </section>
  )
}

function getBaseStyle() {
  return css({
    marginBottom: 15,
    marginTop: 10,
  })
}
