angular
  .module('ccs')
  .controller('IssueDashboard', function ($scope, Api, $log) {
    $log.debug('IssueDashboard')

    $scope.issues = []
    $scope.order = 'date_created'
    $scope.reverse = false
    $scope.showIssueTable = false
    $scope.issueTableIssues = []

    function issueQuery(page) {
      let q = {
        after_date: $scope.startDate,
        before_date: $scope.endDate,
        order: $scope.reverse ? '-' + $scope.order : $scope.order,
        type: $scope.type,
        page_size: 200,
        severity: $scope.severity,
        division: $scope.division,
        page,
      }

      q.application = $scope.app ? $scope.app.id : null
      q.project = $scope.project ? $scope.project.id : null
      q.user = $scope.user ? $scope.user.id : null

      return q
    }

    function getAllIssues(cb, issues, page) {
      issues = issues || []
      page = page || 1

      Api.get('issues/reporting', issueQuery(page), (resp) => {
        issues = issues.concat(resp.results)

        if (resp.next) return getAllIssues(cb, issues, ++page)

        return cb(issues)
      })
    }

    Object.byString = function (o, s) {
      s = s.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
      s = s.replace(/^\./, '') // strip a leading dot
      var a = s.split('.')
      for (var i = 0, n = a.length; i < n; ++i) {
        var k = a[i]
        if (k in o) {
          o = o[k]
        } else {
          return
        }
      }
      return o
    }

    $scope.reverse = false

    $scope.sortIssueTable = function (sort) {
      $scope.issueTableIssues = $scope.issueTableIssues.sort((a, b) => {
        if (Object.byString(a, sort) < Object.byString(b, sort)) {
          return -1
        } else if (Object.byString(a, sort) > Object.byString(b, sort)) {
          return 1
        }
        return 1
      })

      if ($scope.reverse) $scope.issueTableIssues.reverse()

      $scope.issueTableIssueIds = $scope.issueTableIssues.map((i) => i.id)

      $scope.reverse = !$scope.reverse
    }

    $scope.numPerPage = 10
    $scope.pageNumber = 1

    $scope.paginate = function (value) {
      var begin, end, index
      begin = ($scope.pageNumber - 1) * $scope.numPerPage
      end = begin + $scope.numPerPage
      index = $scope.issueTableIssueIds.indexOf(value.id)
      return begin <= index && index < end
    }

    function saveIssues(issues) {
      $scope.issues = issues
      $scope.issueTableIssues = issues
      $scope.issueTableIssueIds = issues.map((i) => i.id)
      $scope.pageNumber = 1
      $scope.totalItems = issues.length
    }

    getAllIssues(saveIssues)

    $scope.changePage = () => {
      getAllIssues(saveIssues)
    }
  })
