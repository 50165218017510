angular
  .module('ccs')
  .controller(
    'ShareNewsCtrl',
    function (
      $scope,
      Api,
      $state,
      $stateParams,
      Notification,
      $log,
      VariableThemeColor,
    ) {
      $log.debug('ShareNewsCtrl')

      const clientObjectKey = $stateParams.key

      Api.get(
        `shares/news/${clientObjectKey}`,
        {},
        (resp) => {
          VariableThemeColor.mainColorSet(
            resp.client.general_settings.colors.secondary_color,
            true,
          )
          if (resp.images.length <= 1) {
            $scope.news = resp
          } else {
            Notification.warning('news items are limited to 1 picture.')
          }
        },
        (err) => {
          if (err.message) {
            Notification.error(err.message)
          } else {
            Notification.error(
              'Sorry, but this news item has not finished processing. Please try again later.',
            )
          }
        },
      )
    },
  )
