import { css } from '@emotion/react'
import classNames from 'classnames'
import { Field, FormikContextType, useFormikContext } from 'formik'
import { TextInputStyle } from '@screens/components'
import { ExternalHeader } from './ExternalHeader'
import { SingleQuestion } from './SingleQuestion'
import { ButtonElement, UniversalAlert } from '@/react/components'
import { AssessmentQuestion } from '@screens/AssessmentsConfig/CreateAssessment/components/types'
import { Memory } from './types'
import { Dispatch, SetStateAction, useState } from 'react'
import {
  isFormValid,
  checkFields,
} from '@screens/AssessmentsConfig/ExternalAssessment/components/utils'
import * as React from 'react'
import { cloneDeep } from 'lodash'

type Props = {
  isValid: boolean
  handleSubmitAnswers: () => void
  initialValues: AssessmentQuestion[]
  setInitialValues: Dispatch<SetStateAction<AssessmentQuestion[]>>
  saveLock: boolean
}

export const ExportAssessmentForm = ({
  handleSubmitAnswers,
  initialValues,
  setInitialValues,
  saveLock,
}: Props) => {
  const { values, setFieldValue }: FormikContextType<Memory> =
    useFormikContext()

  const [popUp, setPopUp] = useState<Element | null>(null)

  const {
    assessment_title,
    first_name,
    last_name,
    company_name,
    email,
  }: Memory = values

  const handleNotFilled = () => {
    let firstWithError = null
    let lastHeader = null
    let lastSubHeader = null
    const headersToOpen = []
    for (const question of initialValues) {
      if (question.type === 'header') {
        lastHeader = question.id
        lastSubHeader = null
      } else if (question.type === 'subheader') {
        lastSubHeader = question.id
      } else {
        const answer = values.answers[question.id]
        if (!(answer.text || answer.option || answer.notAnswered)) {
          if (!firstWithError) firstWithError = question.id
          if (lastHeader) headersToOpen.push(lastHeader)
          if (lastSubHeader) headersToOpen.push(lastSubHeader)
        }
      }
    }
    setInitialValues((prevState: AssessmentQuestion[]) => {
      const newState: AssessmentQuestion[] = cloneDeep(prevState).map(
        (question) => {
          return headersToOpen.includes(question.id)
            ? { ...question, closed: false }
            : question
        },
      )
      return newState
    })
    document.getElementById(`${firstWithError}`).scrollIntoView()
  }

  return (
    <section className="form__wrapper" css={baseStyle}>
      <div className="form__content">
        <h4 className="section__header">{assessment_title}</h4>
        <div className="required__section">
          <p className="section__title">Required Information</p>
          <Field
            name="first_name"
            value={first_name}
            className={classNames('required__input', {
              error: !first_name,
            })}
            placeholder="First Name"
          />
          <Field
            name="last_name"
            value={last_name}
            className={classNames('required__input', {
              error: !last_name,
            })}
            placeholder="Last Name"
          />
          <Field
            name="company_name"
            value={company_name}
            className={classNames('required__input', {
              error: !company_name,
            })}
            placeholder="Company Name"
          />
          <Field
            name="email"
            value={email}
            className={classNames('required__input', {
              error: !email,
            })}
            placeholder="Email"
          />
        </div>

        <div className="questions__wrapper">
          {initialValues.length
            ? initialValues.map((question, qIndex) => {
                if (question.type === 'header') {
                  return (
                    <ExternalHeader
                      header={question}
                      initialValues={initialValues}
                      setInitialValues={setInitialValues}
                      index={qIndex}
                    />
                  )
                } else if (question.type === 'subheader') {
                  return (
                    <ExternalHeader
                      header={question}
                      initialValues={initialValues}
                      setInitialValues={setInitialValues}
                      index={qIndex}
                      isSubheader={true}
                    />
                  )
                } else {
                  return (
                    <SingleQuestion
                      key={qIndex}
                      question={question}
                      initialValues={initialValues}
                      index={qIndex}
                    />
                  )
                }
              })
            : null}
        </div>

        <div className="buttons__wrapper">
          <ButtonElement
            text="Draft"
            buttonType="submit"
            functionToTrigger={() => {
              handleSubmitAnswers(values, initialValues, true)
            }}
            disabled={!isFormValid(values) || saveLock}
          />
          <ButtonElement
            text="Submit"
            debounceTimeout={1000}
            buttonType="submit"
            functionToTrigger={() => {
              if (checkFields(values, initialValues)) {
                handleSubmitAnswers(values, initialValues, false)
              } else {
                setPopUp(
                  <UniversalAlert
                    text={'Please answer all required fields before saving.'}
                    setAlert={setPopUp}
                    submitButtonText={'OK'}
                  />,
                )
                handleNotFilled()
              }
            }}
            disabled={!isFormValid(values) || saveLock}
          />
        </div>
      </div>
      {popUp}
    </section>
  )
}
const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '.section__header': {
    color: '#676A6C',
    fontSize: '22px',
    fontWeight: 400,
    marginTop: '30px',
    marginBottom: '20px',
  },
  '.section__title': {
    fontSize: '13px',
    fontWeight: '600',
    margin: 0,
    color: '#676A6C',
  },
  '.required__section': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    alignItems: 'center',
    paddingBottom: '30px',
    marginBottom: '30px',
    width: '100%',
    borderBottom: '1px solid #F2F2F2',
  },
  '.required__input': {
    ...TextInputStyle,
    width: '363px',
    textAlign: 'center',
    fontSize: '12px',

    '::placeholder': {
      fontSize: '12px',
      color: '#B1B1B1',
    },
  },
  '.error': {
    borderColor: '#C80404 !important',
  },
  '.questions__wrapper': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    gap: '20px',
    marginBottom: '20px',
  },
  '.headers__wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    width: '100%',
  },
  '.form__content': {
    width: '100%',
    maxWidth: '792px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  '.buttons__wrapper': {
    display: 'flex',
    gap: '20px',
    paddingTop: '35px',
  },
})
