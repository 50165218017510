import * as React from 'react'
import * as Yup from 'yup'
import { css } from '@emotion/react'
import { useFormik } from 'formik'

import { useRouter } from '@/react/hooks'
import { UISref } from '@components/UISref'
import { singleSDSInterface } from '@screens/SafetyDataSheets/components/types'
import { getSDSNavigationSettings } from '../components/commonVariables'

import {
  TextField,
  Select,
  DateInput,
  FileField,
  HeaderSeparator,
} from './components'

import {
  useAngularServices,
  FloatingWindowHorizontalNavigation,
  CommonPageHeader,
  ButtonElement,
} from '@components/index'

import { formChoicesBackUp } from '@screens/SafetyDataSheets/SDSCreate/formChoicesBackUp'

interface formChoices {
  flammability_choices: { value: number; label: string }[]
  hazard_choices: { value: number; label: string }[]
  health_choices: { value: number; label: string }[]
  physical_hazards_choices: { value: number; label: string }[]
  ppe_choices: { value: number; label: string }[]
  toxic_chemical_choices: { value: number; label: string }[]
  whmis_health_hazards_choices: { value: number; label: string }[]
  whmis_physical_hazards_choices: { value: number; label: string }[]
}

// @ts-ignore
export function SDSCreate() {
  const { CurrentUser, Api, $rootScope } = useAngularServices()
  const { stateService } = useRouter()
  const user = CurrentUser.getInstance()
  const applicationId: any = stateService.params.app

  const [editData, setEditData] = React.useState<singleSDSInterface>({})
  const [formChoices, setFormChoices] =
    React.useState<formChoices>(formChoicesBackUp)
  const [file, setFile] = React.useState<string>('')

  const hazardChoices = formChoices.hazard_choices || []
  const toxicChemicalChoices = formChoices.toxic_chemical_choices || []
  const healthChoices = formChoices.health_choices || []
  const flammabilityChoices = formChoices.flammability_choices || []
  const physicalHazardsChoices = formChoices.physical_hazards_choices || []
  const ppeChoices = formChoices.ppe_choices || []
  const whmisPhysicalHazardsChoices =
    formChoices.whmis_physical_hazards_choices || []
  const whmisHealthHazardsChoices =
    formChoices.whmis_health_hazards_choices || []

  React.useEffect(async () => {
    const resp = await Api.getSDSFormChoices()
    setFormChoices(resp.data as formChoices)
    if (stateService.params.key) {
      const { data: formData } = await Api.SDSForm.byID(stateService.params.key)
      setEditData(formData)
      setFile(formData.pdf)
    }
  }, [])

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: '',
      manufacturer: '',
      hazards: [],
      toxic_chemical: '',
      whmis_physical_hazards: [],
      whmis_health_hazards: [],
      health: '',
      flammability: '',
      physical_hazards: '',
      ppe: '',
      issue_date: '',
      review_date: '',
      pdf: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      if (
        !values.review_date ||
        new Date(values.issue_date) < new Date(values.review_date)
      ) {
        const sendObject = {
          application: applicationId,
          client: user.client.id,
          ...values,
        }
        if (values.review_date === '') {
          sendObject.review_date = null
        }
        if (stateService.params.key) {
          await Api.SDSForm.patch({
            ...sendObject,
            id: stateService.params.key,
          })
        } else {
          await Api.SDSForm.post(sendObject)
        }
        stateService.go('app.safety_data_sheets_list', {
          app: stateService.params.app,
        })
      } else {
        alert('The review date must be later then Issue date')
      }
    },
  })

  if (
    editData.id &&
    formik.values.name === '' &&
    formik.values.manufacturer === '' &&
    formik.values.issue_date === ''
  ) {
    formik.setValues({
      name: editData.name,
      manufacturer: editData.manufacturer,
      hazards: editData.hazards.map(({ value }: any) => value),
      toxic_chemical: editData.toxic_chemical.value,
      whmis_physical_hazards: editData.whmis_physical_hazards.map(
        ({ value }: any) => value,
      ),
      whmis_health_hazards: editData.whmis_health_hazards.map(
        ({ value }: any) => value,
      ),
      health: editData.health.value,
      flammability: editData.flammability.value,
      physical_hazards: editData.physical_hazards.value,
      ppe: editData.ppe.value,
      issue_date: dateCheckerAndParser(editData.issue_date),
      review_date: dateCheckerAndParser(editData.review_date),
      pdf: editData.pdf ? editData.pdf.id : '',
    })
  }

  if (stateService.params.key && !editData.id) return null

  return (
    <div>
      <CommonPageHeader
        headerText={
          $rootScope.mobile_apps.filter((obj) => {
            return obj.id === Number(stateService.params.app)
          })[0].safety_data_sheets_display
        }
      />
      <FloatingWindowHorizontalNavigation
        navSettings={getSDSNavigationSettings(
          'safety_data_sheets_create',
          applicationId,
        )}
      />
      <form css={base} onSubmit={formik.handleSubmit}>
        <div css={formLine}>
          <TextField
            formik={formik}
            name={'name'}
            header={'SDS Name *'}
            width={fullWidth}
          />
          <HeaderSeparator header={'SARA'} />
          <Select
            errors={formik.errors}
            name={'hazards'}
            header={'311/312 Hazard'}
            width={halfWidth}
            optionList={hazardChoices}
            value={formik.values.hazards}
            onChange={(values: []) => {
              formik.setFieldValue(
                'hazards',
                values.map(({ value }: any) => value),
              )
            }}
            isMulti
          />
          <Select
            errors={formik.errors}
            name={'toxic_chemical'}
            header={'311 Toxic Chemical'}
            width={halfWidth}
            optionList={toxicChemicalChoices}
            value={formik.values.toxic_chemical}
            onChange={({ value }: any) => {
              formik.setFieldValue('toxic_chemical', value)
            }}
          />
          <HeaderSeparator header={'HMIS'} />
          <Select
            errors={formik.errors}
            name={'health'}
            header={'Health'}
            width={fullWidth}
            optionList={healthChoices}
            value={formik.values.health}
            onChange={({ value }: any) => {
              formik.setFieldValue('health', value)
            }}
          />
          <Select
            errors={formik.errors}
            name={'flammability'}
            header={'Flammability'}
            width={fullWidth}
            optionList={flammabilityChoices}
            value={formik.values.flammability}
            onChange={({ value }: any) => {
              formik.setFieldValue('flammability', value)
            }}
          />
          <Select
            errors={formik.errors}
            name={'physical_hazards'}
            header={'Physical Hazards'}
            width={fullWidth}
            optionList={physicalHazardsChoices}
            value={formik.values.physical_hazards}
            onChange={({ value }: any) => {
              formik.setFieldValue('physical_hazards', value)
            }}
          />
          <Select
            errors={formik.errors}
            name={'ppe'}
            header={'Personal Protective Equipment'}
            width={fullWidth}
            optionList={ppeChoices}
            value={formik.values.ppe}
            onChange={({ value }: any) => {
              formik.setFieldValue('ppe', value)
            }}
          />
          <HeaderSeparator header={'WHMIS'} />
          <Select
            errors={formik.errors}
            name={'whmis_physical_hazards'}
            header={'Physical Hazards'}
            width={halfWidth}
            optionList={whmisPhysicalHazardsChoices}
            value={formik.values.whmis_physical_hazards}
            onChange={(values: []) => {
              formik.setFieldValue(
                'whmis_physical_hazards',
                values.map(({ value }: any) => value),
              )
            }}
            isMulti
          />
          <Select
            errors={formik.errors}
            name={'whmis_health_hazards'}
            header={'Health Hazards'}
            width={halfWidth}
            optionList={whmisHealthHazardsChoices}
            value={formik.values.whmis_health_hazards}
            onChange={(values: []) => {
              formik.setFieldValue(
                'whmis_health_hazards',
                values.map(({ value }: any) => value),
              )
            }}
            isMulti
          />
        </div>
        <div css={formLine}>
          <TextField
            formik={formik}
            name={'manufacturer'}
            header={'Manufacturer Name'}
            width={fullWidth}
          />
          <DateInput
            errors={formik.errors}
            formik={formik}
            name={'issue_date'}
            header={'Issuance Date'}
            width={halfWidth}
          />
          <DateInput
            errors={formik.errors}
            formik={formik}
            name={'review_date'}
            header={'Review Date'}
            width={halfWidth}
          />
          <FileField
            uploadFile={Api.uploadFile}
            onChange={(value: string) => {
              formik.setFieldValue('pdf', value)
            }}
            file={file}
            setFile={setFile}
          />
          <div className="button-body">
            <UISref
              to="app.safety_data_sheets_list"
              params={{ app: stateService.params.app }}
            >
              <ButtonElement
                buttonType={'neutral'}
                text={'Cancel'}
                minWidth={'20%'}
              />
            </UISref>
            <ButtonElement
              buttonType={'submit'}
              text={'Submit'}
              minWidth={'20%'}
              additionalStyles={css({ marginLeft: 20 })}
              functionToTrigger={() => {
                removeAllErrorHiders()
              }}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

function removeAllErrorHiders() {
  ;[...document.getElementsByClassName('error-hide')].map((element) => {
    element.classList.remove('error-hide')
  })
}

function dateCheckerAndParser(date: string) {
  if (date) {
    return date.replace(/(\d\d)\/(\d\d)\/(\d{4})/, '$3-$1-$2')
  } else {
    return ''
  }
}

const base = css({
  backgroundColor: 'white',
  paddingTop: '20px',
  width: '100%',
  marginTop: '1px',
  display: 'flex',
  justifyContent: 'flex-start',
  paddingBottom: '70px',
})

const formLine = css({
  width: '46%',
  marginRight: '2%',
  marginLeft: '2%',
  display: 'flex',
  flexWrap: 'wrap',
  maxHeight: '95%',
  alignContent: 'flex-start',
  position: 'relative',
  '.button-body': {
    width: '100%',
    justifyContent: 'right',
    position: 'absolute',
    marginRight: '12px',
    right: 0,
    bottom: 0,
    display: 'flex',
  },
})
const fullWidth = css({
  width: '98%',
  marginRight: '1%',
  marginLeft: '1%',
})
const halfWidth = css({
  width: '48%',
  marginRight: '1%',
  marginLeft: '1%',
})
