import { resolvers } from '../resolvers'

/** @ngInject */
export function adminProcoreConfig($stateProvider) {
  $stateProvider
    .state('admin.procore', {
      url: '/procore',
      templateUrl: 'app/views/procore.html',
      controller: 'ProcoreCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.procore_redirect_url', {
      url: '/procore/redirect_url',
      templateUrl: 'app/views/procore.html',
      controller: 'ProcoreRedirectCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
}
