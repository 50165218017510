import { delayLoop } from '@/utils'
import { checkIsSafari } from '@/utils/checkIsSafari'

angular
  .module('ccs')
  .controller(
    'UsersListCtrl',
    function (
      $scope,
      $location,
      Api,
      $stateParams,
      $state,
      uiPermissionService,
      CurrentUser,
      $window,
      $uibModal,
      $log,
    ) {
      $log.debug('UsersListCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order
        ? $stateParams.order
        : 'first_name,last_name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = $scope.search ? true : false
      $scope.showDownloadUserData =
        uiPermissionService.getPermissionDownloadUserData()
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //total is really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageSize * $scope.pageNumber }
      $scope.userIs = CurrentUser.is

      function fetchAll(params) {
        Api.UsersV2.get(params || '', function (response) {
          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })
      }

      $scope.tableHeaders = [
        {
          key: 'photo,first_name,last_name',
          display: 'Headshots',
          sortable: true,
        },
        { key: 'first_name,last_name', display: 'Full Name', sortable: true },
        { key: 'edit', display: 'Edit', centered: true },
        {
          key: 'is_active',
          display: 'Inactive/Active',
          centered: true,
          sortable: true,
        },
        { key: 'username', display: 'Username', sortable: true },
        { key: 'employee_id', display: 'Employee ID', sortable: true },
        { key: 'division', display: 'Division', sortable: true },
        { key: 'company_name', display: 'Company Name', sortable: true },
        { key: 'email', display: 'Email', sortable: true },
        {
          key: 'date_joined',
          display: 'Date Created',
          sortable: true,
          centered: true,
        },
        {
          key: 'projects',
          display: CurrentUser.getClientSettings().projects_language,
          centered: true,
        },
        {
          key: 'd',
          display: 'Delete',
          centered: true,
        },
      ]

      $scope.deleteUser = function (user) {
        Api.UsersV2.patch(
          { id: user.id, deleted: true, is_active: false },
          function () {
            fetchAll({
              page: $scope.pageNumber,
              order: $scope.order,
              search: $scope.searchExecuted ? $scope.search : null,
              deleted: false,
            })
          },
        )
        $scope.deleteModal.close()
      }

      $scope.deleteHandler = function (user) {
        $scope.deletedUser = user
        $scope.deleteModal = $uibModal.open({
          animation: false,
          windowClass: 'custom-content download-alert-width',
          className: 'ngdialog-theme-default custom-content',
          templateUrl: 'app/views/modals/delete-modal.html',
          size: 'sm',
          scope: $scope,
        })
      }

      $scope.toggleActive = (user) => {
        Api.postV2(
          'users/' + user.id + '/toggle_active',
          {},
          function (resp) {},
        )
      }

      $scope.getSorted = function () {
        let orderBy
        if ($scope.order === 'photo,first_name,last_name') {
          orderBy = $scope.reverse ? $scope.order : '-' + $scope.order
        } else {
          orderBy = $scope.reverse ? '-' + $scope.order : $scope.order
        }
        fetchAll({
          page: $scope.pageNumber,
          order: orderBy,
          search: $scope.searchExecuted ? $scope.search : null,
          tz_offset: new Date().getTimezoneOffset(),
          deleted: false,
        })
      }

      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = {
          image: photo,
        }
        $uibModal.open({
          animation: false,
          windowClass: 'userListPopUp',
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      $scope.changePage = function () {
        $scope.getSorted()
        //update the URL in the navigation bar
        $state.transitionTo(
          'admin.users.list',
          {
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.getSorted()

      function initValuesOnRefresh() {
        angular.element($window).on('onbeforeunload', function (event) {
          $scope.in_progress = false
          $scope.is_loading = false
          $scope.downloaded_file_counter = 0
          $scope.expected_file_counter = 0
          $window.localStorage.setItem('is_loading', 'false')
          $window.localStorage.setItem('downloaded_file_counter', '0')
          $window.localStorage.setItem('expected_file_counter', '0')
        })
      }

      initValuesOnRefresh()

      $scope.in_progress = false
      $scope.is_loading =
        JSON.parse($window.localStorage.getItem('is_loading')) || false
      $scope.downloaded_file_counter =
        JSON.parse($window.localStorage.getItem('downloaded_file_counter')) || 0
      $scope.expected_file_counter =
        JSON.parse($window.localStorage.getItem('expected_file_counter')) || 0

      $window.localStorage.setItem('is_loading', $scope.is_loading.toString())
      $window.localStorage.setItem(
        'downloaded_file_counter',
        $scope.downloaded_file_counter.toString(),
      )
      $window.localStorage.setItem(
        'expected_file_counter',
        $scope.expected_file_counter.toString(),
      )

      $scope.loadingList = []
      $scope.effectiveTypetable = {
        'slow-2g': 15,
        '2g': 10,
        '3g': 5,
        '4g': 2,
      }

      $scope.getEmployeeDataDownload = function () {
        $scope.is_loading = true
        $window.localStorage.setItem('is_loading', 'true')
        $scope.showAlertDialog(
          '',
          'Your User download is in process. Please do not refresh or exit the app until your download has completed.',
          'center',
          'regular',
        )
        Api.EmployeeDataDownloadV2.getWithoutDeletedParam(null, (resp) => {
          if (resp) {
            $scope.expected_file_counter =
              Object.keys(resp).length -
              1 -
              ('logo_zip_id' in resp ? 1 : 0) -
              ('headshots_zip_id' in resp && resp.headshots_zip_id === null
                ? 1
                : 0)
            $window.localStorage.setItem(
              'expected_file_counter',
              $scope.expected_file_counter.toString(),
            )
            for (var key in resp) {
              if (
                resp.hasOwnProperty(key) &&
                key !== 'time' &&
                key !== 'logo_zip_id'
              ) {
                $scope.getFileUrl(
                  resp[key],
                  resp.time,
                  $scope.expected_file_counter,
                )
              }
            }
          }
        })
      }

      $scope.in_progress = false
      $scope.is_loading = false
      $window.localStorage.setItem('is_loading', 'false')

      $scope.getFileUrl = function (file_id, time, expectedLength) {
        let waiting_time = time * 1000
        let fileTimeout
        if (checkIsSafari()) {
          fileTimeout = 5
        } else {
          fileTimeout =
            $scope.effectiveTypetable[navigator.connection.effectiveType]
        }
        setTimeout(function () {
          if (!file_id) return
          Api.getWithoutDeletedParamV2(`file_upload/${file_id}`, {}, (resp) => {
            setTimeout(function () {
              $scope.in_progress = true
              $window.localStorage.setItem('is_loading', 'true')
              $scope.$apply()
              $scope.loadingList.push(resp)
              if ($scope.loadingList.length === expectedLength) {
                $scope.loadingList.forEach(
                  delayLoop(
                    (file) => {
                      $scope.downloadFile(file)
                    },
                    fileTimeout * 1000,
                    () => {
                      $scope.loadingList = []
                      $scope.in_progress = false
                      $scope.is_loading = false
                      $window.localStorage.setItem('is_loading', 'false')
                      $scope.$apply()
                    },
                  ),
                )
              }
            }, 0)
          })
        }, waiting_time)
      }

      $scope.downloadFile = function (resp) {
        $scope.exportData = resp.file
        var a = document.createElement('a')
        a.href = $scope.exportData
        document.body.appendChild(a)
        clickEvent(a)
        a.remove()
      }

      function clickEvent(element) {
        var evt = element.ownerDocument.createEvent('MouseEvents')
        evt.initMouseEvent(
          'click',
          true,
          true,
          element.ownerDocument.defaultView,
          1,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null,
        )
        element.dispatchEvent(evt)
        setTimeout(function () {
          $scope.in_progress = false
          ++$scope.downloaded_file_counter

          $window.localStorage.setItem(
            'downloaded_file_counter',
            $scope.downloaded_file_counter.toString(),
          )
          if ($scope.downloaded_file_counter == $scope.expected_file_counter) {
            $scope.is_loading = false
            $scope.downloaded_file_counter = 0
            $scope.expected_file_counter = 0
            $window.localStorage.setItem('is_loading', 'false')
            $window.localStorage.setItem('downloaded_file_counter', '0')
            $window.localStorage.setItem('expected_file_counter', '0')
            $scope.alertModalInstance.close()
          }
          $scope.$apply()
        }, 0)
      }

      $scope.showAlertDialog = function (
        title,
        message,
        text_alignment,
        text_weight,
      ) {
        $scope.alertModalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content download-alert-width',
          templateUrl: 'app/views/alert_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.title = ''
            $scope.message = message
            $scope.textAlignment = text_alignment
            $scope.textWeight = text_weight
          },
        })

        $scope.close = function () {
          $scope.alertModalInstance.close()
        }
      }
    },
  )
