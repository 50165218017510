import * as React from 'react'
import { css } from '@emotion/react'
import moment from 'moment'
import { useAngularServices } from '@/react/components'
import { CreateActivityPopup, SingleTimeCard } from './index'
import { processTimeCards } from '@screens/Timecard/Timesheet/components/SubTableHelperss'
import { VisualPlugElements } from '@screens/Timecard/Timesheet/components/VisualPlugElements'

export const SubTable = ({
  rowData,
  tabData,
  setTabData,
  setReload,
  setLocalReload,
  editableWeek,
  setForceUpdateStage,
}) => {
  const { Api } = useAngularServices()
  const [popUp, setPopUp] = React.useState(null)
  const [activityHeadersRefs, setActivityHeadersRefs] = React.useState(null)
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  async function fetchData() {
    const [{ data }] = await Promise.all([
      Api.get('timecards', { timesheet: rowData.id }),
    ])
    setTabData(await processTimeCards(data.results, rowData, Api))
  }

  if (!tabData) fetchData()

  function addEmptyActivity(costCode) {
    costCode.local = true
    costCode.localInx = Number(tabData.costCodes.length)
    tabData.costCodes.push(costCode)
    setTabData(tabData)
    setPopUp(null)
    setLocalReload(true)
  }

  function editActivity(costCodeToSet, currentCostCode) {
    if (currentCostCode.local) {
      costCodeToSet.local = true
      costCodeToSet.localInx = Number(tabData.costCodes.length) - 1
      tabData.costCodes[currentCostCode.localInx] = costCodeToSet
      setLocalReload(true)
    } else {
      Api.put('activity/update_cost_code/', {
        new_costcode: costCodeToSet.id,
        timesheet: rowData.id,
        old_costcode: currentCostCode.id,
      })
      setReload(true)
    }
    setTabData(tabData)
    setPopUp(null)
  }

  const activityHeadersHolder = React.useRef()

  if (!tabData) return null

  setTimeout(() => {
    if (activityHeadersHolder.current && activityHeadersHolder.current.children)
      setActivityHeadersRefs(activityHeadersHolder.current.children)
  })

  const baseStyle = getBaseStyle(variableColor)

  return (
    <tr css={baseStyle}>
      <td />
      <td colSpan={2}>
        <div className="pop-up-holder">{popUp}</div>
        <div className="fake-row dark-row">Details</div>
        <div className="fake-row darker-row">Start</div>
        <div className="fake-row half-darker-row">Meal Start</div>
        <div className="fake-row darker-row">Meal Duration</div>
        <div className="fake-row half-darker-row">End</div>
        <div className="fake-row dark-row">
          <span>Activities</span>
          {!editableWeek ? null : (
            <div
              className="add-activity-button"
              onClick={() => {
                setPopUp(
                  <CreateActivityPopup
                    setPopUp={setPopUp}
                    setReload={setReload}
                    setForceUpdateStage={setForceUpdateStage}
                    callback={addEmptyActivity}
                    costCodes={tabData.costCodes}
                  />,
                )
              }}
            >
              +
            </div>
          )}
        </div>
        <div ref={activityHeadersHolder}>
          {tabData.costCodes.map((costCode, idx) => {
            if ((idx + 1) % 2 === 0)
              return (
                <div
                  key={idx + '-costCode'}
                  className="fake-row activity-row-header half-darker-row"
                  css={css({
                    cursor: editableWeek ? 'pointer' : 'default',
                    '.activity-header': {
                      color: editableWeek ? variableColor : 'inherit',
                    },
                  })}
                  onClick={() => {
                    !editableWeek
                      ? null
                      : setPopUp(
                          <CreateActivityPopup
                            setPopUp={setPopUp}
                            setReload={setReload}
                            setForceUpdateStage={setForceUpdateStage}
                            callback={addEmptyActivity}
                            costCodes={tabData.costCodes}
                            edit
                            editCallback={editActivity}
                            currentCostCode={costCode}
                          />,
                        )
                  }}
                >
                  <div className=" activity-header">
                    <b>
                      {costCode.project_number ? costCode.project_number : ''}
                    </b>{' '}
                    {costCode.project.name}
                  </div>
                  <div className=" activity-header">
                    <b>{costCode.costcode_code}</b> {costCode.name}
                  </div>
                </div>
              )
            return (
              <div
                key={idx + '-costCode'}
                className="fake-row activity-row-header darker-row"
                css={css({
                  cursor: editableWeek ? 'pointer' : 'default',
                  '.activity-header': {
                    color: editableWeek ? variableColor : 'inherit',
                  },
                })}
                onClick={() => {
                  !editableWeek
                    ? null
                    : setPopUp(
                        <CreateActivityPopup
                          setPopUp={setPopUp}
                          setReload={setReload}
                          setForceUpdateStage={setForceUpdateStage}
                          callback={addEmptyActivity}
                          costCodes={tabData.costCodes}
                          edit
                          editCallback={editActivity}
                          currentCostCode={costCode}
                        />,
                      )
                }}
              >
                <div className=" activity-header">
                  <b>{costCode.project_number}</b> {costCode.project.name}
                </div>
                <div className=" activity-header">
                  <b>{costCode.costcode_code}</b> {costCode.name}
                </div>
              </div>
            )
          })}
        </div>
      </td>
      <td className="single-column">
        <SingleTimeCard
          timeCard={tabData.timeCardPerWeekDay[1]}
          costCodes={tabData.costCodes}
          setReload={setReload}
          setForceUpdateStage={setForceUpdateStage}
          rowData={rowData}
          editableWeek={editableWeek}
          weekDayDate={moment.utc(rowData.payroll_period_start)}
          activityHeadersRefs={activityHeadersRefs}
        />
      </td>
      <td className="single-column">
        <SingleTimeCard
          timeCard={tabData.timeCardPerWeekDay[2]}
          costCodes={tabData.costCodes}
          setReload={setReload}
          setForceUpdateStage={setForceUpdateStage}
          rowData={rowData}
          editableWeek={editableWeek}
          weekDayDate={moment.utc(rowData.payroll_period_start).add(1, 'd')}
          activityHeadersRefs={activityHeadersRefs}
        />
      </td>
      <td className="single-column">
        <SingleTimeCard
          timeCard={tabData.timeCardPerWeekDay[3]}
          costCodes={tabData.costCodes}
          setReload={setReload}
          setForceUpdateStage={setForceUpdateStage}
          rowData={rowData}
          editableWeek={editableWeek}
          weekDayDate={moment.utc(rowData.payroll_period_start).add(2, 'd')}
          activityHeadersRefs={activityHeadersRefs}
        />
      </td>
      <td className="single-column">
        <SingleTimeCard
          timeCard={tabData.timeCardPerWeekDay[4]}
          costCodes={tabData.costCodes}
          setForceUpdateStage={setForceUpdateStage}
          setReload={setReload}
          rowData={rowData}
          editableWeek={editableWeek}
          weekDayDate={moment.utc(rowData.payroll_period_start).add(3, 'd')}
          activityHeadersRefs={activityHeadersRefs}
        />
      </td>
      <td className="single-column">
        <SingleTimeCard
          timeCard={tabData.timeCardPerWeekDay[5]}
          costCodes={tabData.costCodes}
          setReload={setReload}
          setForceUpdateStage={setForceUpdateStage}
          rowData={rowData}
          editableWeek={editableWeek}
          weekDayDate={moment.utc(rowData.payroll_period_start).add(4, 'd')}
          activityHeadersRefs={activityHeadersRefs}
        />
      </td>
      <td className="single-column">
        <SingleTimeCard
          timeCard={tabData.timeCardPerWeekDay[6]}
          costCodes={tabData.costCodes}
          setReload={setReload}
          setForceUpdateStage={setForceUpdateStage}
          rowData={rowData}
          editableWeek={editableWeek}
          weekDayDate={moment.utc(rowData.payroll_period_start).add(5, 'd')}
          activityHeadersRefs={activityHeadersRefs}
        />
      </td>
      <td className="single-column">
        <SingleTimeCard
          timeCard={tabData.timeCardPerWeekDay[0]}
          costCodes={tabData.costCodes}
          setReload={setReload}
          setForceUpdateStage={setForceUpdateStage}
          rowData={rowData}
          editableWeek={editableWeek}
          weekDayDate={moment.utc(rowData.payroll_period_start).add(6, 'd')}
          activityHeadersRefs={activityHeadersRefs}
        />
      </td>
      <VisualPlugElements
        activityHeadersRefs={activityHeadersRefs}
        tabData={tabData}
      />
      <VisualPlugElements
        activityHeadersRefs={activityHeadersRefs}
        tabData={tabData}
      />
      {CurrentUser.getRole() === 'client_admin' ? (
        <VisualPlugElements
          activityHeadersRefs={activityHeadersRefs}
          tabData={tabData}
        />
      ) : null}
    </tr>
  )
}

function getBaseStyle(variableColor) {
  return css({
    '.fake-row': {
      height: 30,
      width: '100%',
      color: '#676a6c',
      lineHeight: '30px',
      textAlign: 'left',
      paddingLeft: 10,
    },
    '.activity-row': {
      height: 60,
      lineHeight: '60px',
    },
    '.activity-row-header': {
      minHeight: 60,
      height: 'fit-content',
      lineHeight: '60px',
      '.activity-header': {
        lineHeight: '30px',
        minHeight: 30,
        height: 'fit-content',
      },
    },
    '.darker-row': {
      backgroundColor: '#EAEBED',
      textAlign: 'right',
      paddingRight: 10,
    },
    '.half-darker-row': {
      backgroundColor: '#F7F7F8',
      textAlign: 'right',
      paddingRight: 10,
    },
    '.third-darker-row': {
      backgroundColor: '#f9f9f9',
      textAlign: 'left',
    },
    '.dark-row': {
      backgroundColor: '#7A7A7A',
      color: 'white',
      paddingLeft: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    '.add-activity-button': {
      borderRadius: '50%',
      height: 22,
      width: 22,
      fontSize: 18,
      textAlign: 'center',
      lineHeight: '18px',
      borderStyle: 'solid',
      borderWidth: 2,
      borderColor: 'white',
      fontWeight: 'bold',
      backgroundColor: variableColor,
      cursor: 'pointer',
    },
    '.pop-up-holder': {
      width: 0,
      height: 0,
    },
  })
}
