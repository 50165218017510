import * as React from 'react'
import { css } from '@emotion/react'
import { default as ReactSelect } from 'react-select'
import { getTranslatedString } from '@/utils'
import { FieldSelector, getStartingValue, NoteElement } from '../index'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@components/index'

export const NestedField = ({
  fieldData,
  formMemory,
  setFormMemory,
  possibleGroupData,
  addSketchOrSignatureText,
  promisesToDeleteFiles,
  onChange,
}) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [selectedOption, setSelectedOption] = React.useState(null)
  const [updated, setUpdated] = React.useState(false)
  const [error, setError] = React.useState(false)
  const optionsById = {}
  const options = []

  fieldData.answer_options.forEach((value, idx) => {
    optionsById[value.name] = {
      value: idx,
      label: getTranslatedString(value.name),
    }
    options.push({ value: idx, label: getTranslatedString(value.name) })
  })

  const startingOption = getStartingValue(
    possibleGroupData,
    formMemory,
    fieldData,
    'text',
  )

  const startingData = getStartingValue(
    possibleGroupData,
    formMemory,
    fieldData,
    'text',
  )

  React.useEffect(() => {
    if (selectedOption) {
      setUpdated(true)
      onChange({
        value: fieldData.answer_options[selectedOption.value].name,
        possibleGroupData,
        id: fieldData.id,
      })
      valueUpdate(
        { ...fieldData.answer_options[selectedOption.value], isAnswer: true },
        possibleGroupData,
        formMemory,
        fieldData,
        setFormMemory,
      )
    }
    if (!updated && startingOption !== '') {
      setSelectedOption(
        Object.keys(optionsById).length ? optionsById[startingData] : null,
      )
    }
  }, [selectedOption])

  React.useEffect(() => {
    if (fieldData.required && !startingData) {
      setError(true)
    }
  }, [])

  const baseStyle = getBaseStyle()

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="label-holder">
        <label css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      <div className="select-holder">
        <ReactSelect
          options={options}
          onChange={(value) => {
            setError(false)
            setSelectedOption(value)
          }}
          styles={{
            ...dropdownOptionStyles(variableColor, '30px'),
            control: (provided, state) => ({
              ...provided,
              minHeight: '0px',
              height: '30px',
              border: error && '1px solid #C80404',
            }),
          }}
          defaultValue={
            Object.keys(optionsById).length
              ? optionsById[
                  getStartingValue(
                    possibleGroupData,
                    formMemory,
                    fieldData,
                    'text',
                  )
                ]
              : null
          }
          theme={(theme) => dropdownFiledTheme(theme, variableColor)}
        />
      </div>
      {selectedOption === null ? null : (
        <div className="fields-container">
          {fieldData.answer_options[selectedOption.value].nested_fields.map(
            (field, idx) => {
              return (
                <FieldSelector
                  key={idx}
                  formMemory={formMemory}
                  possibleGroupData={possibleGroupData}
                  setFormMemory={setFormMemory}
                  fieldData={field}
                  addSketchOrSignatureText={addSketchOrSignatureText}
                  promisesToDeleteFiles={promisesToDeleteFiles}
                  onChange={onChange}
                />
              )
            },
          )}
        </div>
      )}
    </section>
  )
}

function valueUpdate(
  value,
  possibleGroupData,
  formMemory,
  fieldData,
  setFormMemory,
) {
  if (possibleGroupData) {
    formMemory[fieldData.id][possibleGroupData]['touched'] = true
    formMemory[fieldData.id][possibleGroupData].localAnswer.option = value
    formMemory[fieldData.id][possibleGroupData].localAnswer.chosen_answer =
      value.id
    setFormMemory(formMemory)
  } else {
    formMemory[fieldData.id]['touched'] = true
    formMemory[fieldData.id].localAnswer.option = value
    formMemory[fieldData.id].localAnswer.chosen_answer = value.id
    setFormMemory(formMemory)
  }
}

function getBaseStyle() {
  return css({
    marginBottom: 15,
    '.select-holder': {
      width: 400,
      maxWidth: '100%',
    },
    '.fields-container': {
      marginTop: 15,
    },
  })
}
