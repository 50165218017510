/** @ngInject */
export function reactDemoConfig($stateProvider) {
  $stateProvider
    .state('react', {
      abstract: true,
      templateUrl: 'app/views/share/base.html',
    })
    .state('react.demo', {
      url: '/react/demo',
      template: `<react-demo-component></react-demo-component>`,
      controller: function () {},
    })
}
