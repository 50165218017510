import { ButtonElement, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import React, { useEffect, useRef, useState } from 'react'
import closeIcon from '@/assets/icons/X Gray.svg'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { AutoExpandTextArea } from '@/react/components/AutoExpandTextArea'

interface StartingData {
  name: string
  order: string | number
}

type Props = {
  startingData?: StartingData
  handleClose: () => void
  maxTopicOrder: number
}

export const AddEditTopicModal = ({
  handleClose,
  startingData,
  maxTopicOrder,
}: Props) => {
  const [topic, setTopic] = useState({
    name: '',
    order: maxTopicOrder,
  })

  const modalRef = useRef()
  const { stateService } = useRouter()
  const { Api } = useAngularServices()

  const handleChange = (e) => {
    if (e.target.name === 'order' && e.target.value > maxTopicOrder) {
      e.target.value = maxTopicOrder
    }

    setTopic({
      ...topic,
      [e.target.name]: e.target.value,
    })
  }

  useEffect(() => {
    if (startingData?.name) {
      setTopic(startingData)
    }
  }, [startingData])

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleClick = async () => {
    if (!startingData) {
      try {
        Api.post('training_topic', {
          ...topic,
          training: stateService.params.trainingID,
        })
        handleClose()
      } catch {
        console.log('Error')
      }
    } else {
      try {
        Api.patch(`training_topic/${startingData?.id}`, {
          ...topic,
          training: stateService.params.trainingID,
        })
        handleClose()
      } catch {
        console.log('Error')
      }
    }
  }

  return (
    <div
      css={baseStyle}
      ref={modalRef}
      onMouseOver={(e) => {
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <img
        className="modal__close"
        src={closeIcon}
        alt="close"
        onClick={handleClose}
      />
      <div className="inputs__row">
        <div className="single__input center">
          <label className="input__name">Topic Name: *</label>
          <AutoExpandTextArea
            className="long__input"
            placeholder="Enter Name"
            name="name"
            value={topic.name}
            handleChange={handleChange}
          />
        </div>
        <div className="single__input center">
          <label className="input__name">Topic Order: *</label>
          <input
            className={classNames('small__input', {
              error: !topic.order,
            })}
            placeholder="Order"
            type="number"
            name="order"
            value={topic.order}
            onChange={handleChange}
          />
        </div>
      </div>
      <div className="button__wrapper">
        <ButtonElement
          text={startingData?.name ? 'Save' : 'Create'}
          disabled={!topic?.name || !topic?.order}
          functionToTrigger={handleClick}
        />
      </div>
    </div>
  )
}

const baseStyle = css({
  background: '#fff',
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  boxShadow: '0px 4px 7px 0px rgba(0, 0, 0, 0.25)',
  paddingTop: '21px',
  paddingBottom: '22px',
  paddingInline: '30px',
  gap: '23px',
  left: '120px',
  '.input__name': {
    fontSize: '12px',
    fontWeight: 600,
    color: '#575757',
    margin: 0,
  },
  '.modal__close': {
    position: 'absolute',
    width: '12px',
    height: '12px',
    right: '10px',
    top: '8px',
    cursor: 'pointer',
  },
  '.long__input': {
    border: '1px solid #C6C6C6',
    height: '30px',
    width: '301px',
    outline: 'none',
    color: '#000',
    padding: '5px',
    '&::placeholder': {
      color: '#B3B3B3',
      fontSize: '12px',
    },
  },
  '.single__input': {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  '.center': {
    textAlign: 'center',
    '&::placeholder': {
      textAlign: 'center',
    },
  },
  '.small__input': {
    border: '1px solid #C6C6C6',
    height: '30px',
    padding: '5px',
    color: '#000',
    textAlign: 'center',
    width: '94px',
    outline: 'none',
    '&::placeholder': {
      color: '#B3B3B3',
      fontSize: '12px',
    },
  },
  '.error': {
    borderColor: '#C80404',
  },
  '.inputs__row': {
    display: 'flex',
    gap: '10px',
  },
  '.button__wrapper': {
    display: 'flex',
    justifyContent: 'center',
  },
})
