angular.module('ccs').directive('multipleselect', multipleSelect)

function multipleSelect() {
  return {
    restrict: 'E',
    scope: {
      items: '=',
      selectedItems: '=',
      field: '=',
      isOpen: '=',
      change: '&',
    },
    templateUrl: 'app/views/common/multiple_select.html',
    link: function ($scope) {
      $scope.selectItem = function ($event, item) {
        item.isSelected = !item.isSelected
        $scope.field.answer.selectedOption = item
        $scope.field.answer.selectedOption.deleted = !item.isSelected
        $event.stopPropagation()

        $scope.field.answer.multi_answers = []
        if (item.isSelected == false) {
          for (var index = 0; index < $scope.selectedItems.length; index++) {
            if (item.id == $scope.selectedItems[index].id) {
              item.isSelected = false
              $scope.selectedItems.splice(index, 1)
              break
            }
          }
        } else {
          $scope.selectedItems.push(item)
        }

        $scope.selectedItems.forEach(function (i) {
          i.isSelected === true && $scope.field.answer.multi_answers.push(i.id)
        })

        $scope.change()
      }
    },
  }
}
