import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height?: string
  width?: string
  color: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const AddNoteIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      onClick={onClick}
    >
      <path
        d="M9.68421 6.05263C9.68421 7.01579 9.3016 7.93949 8.62055 8.62055C7.93949 9.3016 7.01579 9.68421 6.05263 9.68421H1.21053L1.21053 19.3684C1.21053 20.0105 1.4656 20.6263 1.91964 21.0804C2.37367 21.5344 2.98948 21.7895 3.63158 21.7895H16.9474C17.5895 21.7895 18.2053 21.5344 18.6593 21.0804C19.1133 20.6263 19.3684 20.0105 19.3684 19.3684V3.63158C19.3684 2.98948 19.1133 2.37367 18.6593 1.91964C18.2053 1.4656 17.5895 1.21053 16.9474 1.21053H9.68421V6.05263ZM6.05263 8.47368C6.69474 8.47368 7.31054 8.21861 7.76458 7.76457C8.21861 7.31054 8.47369 6.69473 8.47369 6.05263V1.70684L1.70684 8.47368H6.05263ZM0 19.3684L0 8.47368L8.47369 0H16.9474C17.9105 0 18.8342 0.38261 19.5153 1.06366C20.1963 1.74472 20.5789 2.66842 20.5789 3.63158V19.3684C20.5789 20.3316 20.1963 21.2553 19.5153 21.9363C18.8342 22.6174 17.9105 23 16.9474 23H3.63158C2.66842 23 1.74472 22.6174 1.06367 21.9363C0.382612 21.2553 0 20.3316 0 19.3684Z"
        fill={color}
      />
      <path
        d="M14.5667 14.024H12.2667V11.7203C12.2667 11.5166 12.186 11.3213 12.0422 11.1773C11.8984 11.0333 11.7034 10.9524 11.5001 10.9524C11.2967 10.9524 11.1017 11.0333 10.958 11.1773C10.8142 11.3213 10.7334 11.5166 10.7334 11.7203V14.024H8.43341C8.23008 14.024 8.03508 14.1049 7.8913 14.2489C7.74752 14.3929 7.66675 14.5882 7.66675 14.7919C7.66675 14.9955 7.74752 15.1908 7.8913 15.3349C8.03508 15.4789 8.23008 15.5598 8.43341 15.5598H10.7334V17.8512C10.7334 18.0548 10.8142 18.2501 10.958 18.3942C11.1017 18.5382 11.2967 18.6191 11.5001 18.6191C11.7034 18.6191 11.8984 18.5382 12.0422 18.3942C12.186 18.2501 12.2667 18.0548 12.2667 17.8512V15.5598H14.5667C14.7701 15.5598 14.9651 15.4789 15.1089 15.3349C15.2526 15.1908 15.3334 14.9955 15.3334 14.7919C15.3334 14.5882 15.2526 14.3929 15.1089 14.2489C14.9651 14.1049 14.7701 14.024 14.5667 14.024Z"
        fill={color}
      />
    </svg>
  )
}
