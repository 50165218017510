import * as React from 'react'

import { ArchiveTableRow } from './ArchiveTableRow'

export function ArchiveTableList({ changeIsProjectSelect, rows }: any) {
  let table = (
    <tr>
      <td>No data</td>
    </tr>
  )

  if (rows.results) {
    table = rows.results.map((result) => (
      <ArchiveTableRow
        key={result.id}
        result={result}
        changeIsProjectSelect={changeIsProjectSelect}
      />
    ))
  }

  return <tbody>{table}</tbody>
}
