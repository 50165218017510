import * as React from 'react'
import { FieldSelector, createAnswerTemplate } from '../index'
import { ButtonElement, useAngularServices } from '@components/index'
import { css } from '@emotion/react'
import { getTranslatedString } from '@/utils'
import { flatMap } from 'lodash'

export const GroupFields = ({
  fieldData,
  formMemory,
  setFormMemory,
  promisesToDeleteFiles,
  addSketchOrSignatureText,
  onChange,
}) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [newGroups, setNewGroups] = React.useState([])

  function addGroup() {
    const keys = Object.keys(formMemory[fieldData.group_list[0].id])
    const lastKeySplit = keys[keys.length - 1].split('_')
    const newKey =
      lastKeySplit[0] +
      '_' +
      lastKeySplit[1] +
      '_' +
      (Number(lastKeySplit[2]) + 1)
    fieldData.group_list.forEach((field) => {
      formMemory[field.id][newKey] = {
        ...field,
        localAnswer: createAnswerTemplate(
          field.id,
          flatMap({ ...formMemory[fieldData.group_list[0].id] }).length,
        ),
      }
    })
    setFormMemory(formMemory)
    const fieldsToReturn = fieldData.group_list.map((field, idx) => {
      return (
        <FieldSelector
          fieldData={field}
          formMemory={formMemory}
          setFormMemory={setFormMemory}
          possibleGroupData={newKey}
          key={idx}
          promisesToDeleteFiles={promisesToDeleteFiles}
          addSketchOrSignatureText={addSketchOrSignatureText}
          onChange={onChange}
        />
      )
    })
    setNewGroups([...newGroups, fieldsToReturn])
  }

  const baseStyle = getBaseStyle(variableColor)

  return (
    <section css={baseStyle}>
      {Object.keys(formMemory[fieldData.group_list[0].id]).map((key) => {
        return (
          <div className="single-group">
            <div className="bracket" />
            <div className="fields-holder">
              {fieldData.group_list.map((field, idx) => {
                return (
                  <FieldSelector
                    fieldData={field}
                    formMemory={formMemory}
                    setFormMemory={setFormMemory}
                    possibleGroupData={key}
                    key={idx}
                    promisesToDeleteFiles={promisesToDeleteFiles}
                    addSketchOrSignatureText={addSketchOrSignatureText}
                    onChange={onChange}
                  />
                )
              })}
            </div>
          </div>
        )
      })}
      <ButtonElement
        text={'+ ' + getTranslatedString(fieldData.group_name)}
        functionToTrigger={addGroup}
        height="auto"
      />
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    paddingBottom: 30,
    '.single-group': {
      width: '100%',
      marginBottom: 20,
      display: 'flex',
      '.bracket': {
        marginTop: 14,
        marginBottom: 16,
        width: 20,
        marginRight: 10,
        borderTop: 'solid 2px ' + variableColor,
        borderLeft: 'solid 2px ' + variableColor,
        borderBottom: 'solid 2px ' + variableColor,
      },
      '.fields-holder': {
        width: '90%',
      },
    },
  })
}
