import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import classNames from 'classnames'
import Highlighter from 'react-highlight-words'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'

export const PartyObservedRow = ({
  party,
  index,
  handleChecked,
  selectedParties,
  partySearch,
}) => {
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const styles = getStyles(variableColor)

  return (
    <tr
      className={classNames('view__row', {
        dark: index % 2 === 0,
      })}
      css={styles}
    >
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={selectedParties?.includes(party)}
            onChange={() => {
              handleChecked(party)
            }}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      <td>
        <Highlighter
          highlightClassName={'highlighted__item'}
          searchWords={[partySearch]}
          autoEscape={true}
          textToHighlight={party}
        />
      </td>
    </tr>
  )
}

const getStyles = (variableColor) =>
  css({
    '.checkmark__td': {
      paddingLeft: '9px',
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBAWithOpacity(variableColor, 0.1),
    },
  })
