import { ReactWrapper } from '@components'

import { CreateAssessment } from './CreateAssessment'
import { AssessmentScoreKey } from './AssessmentScoreKey'
import { ManageAssessments } from './ManageAssesments'
import { ExternalAssessment } from './ExternalAssessment'
import { CompletedAssessment } from './CompletedAssessment'
import { DraftAssessments } from './DraftAssessments'
import { FinalAssessment } from './FinalAssessment'

export const CreateAssessmentComponent = ReactWrapper(
  CreateAssessment,
  'createAssessmentComponent',
)
export const AssessmentScoreKeyComponent = ReactWrapper(
  AssessmentScoreKey,
  'assessmentScoreKeyComponent',
)

export const ManageAssessmentsComponent = ReactWrapper(
  ManageAssessments,
  'manageAssessmentsComponent',
)

export const FinalAssessmentComponent = ReactWrapper(
  FinalAssessment,
  'finalAssessmentComponent',
)

export const DraftAssessmentsComponent = ReactWrapper(
  DraftAssessments,
  'draftAssessmentsComponent',
)

export const CompletedAssessmentComponent = ReactWrapper(
  CompletedAssessment,
  'completedAssessmentsComponent',
)

export const ExternalAssessmentComponent = ReactWrapper(
  ExternalAssessment,
  'externalAssessmentComponent',
)
