angular.module('ccs').directive('daterange', dateRange)

function dateRange() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/date_range.html',
    link: function ($scope) {
      $scope.startDate = null
      $scope.endDate = null

      $scope.startPopUp = {
        opened: false,
      }

      $scope.endPopUp = {
        opened: false,
      }

      $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.openStart = () => {
        $scope.startPopUp.opened = true
      }

      $scope.openEnd = () => {
        $scope.endPopUp.opened = true
      }

      $scope.$watch('[startDate,endDate]', function (newValue, oldValue) {
        if (newValue === oldValue) return
        $scope.changePage()
      })
    },
  }
}
