import * as React from 'react'
import moment from 'moment'
import classNames from 'classnames'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'
import { default as ReactSelect } from 'react-select'

import { css } from '@emotion/react'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  NumberInput,
  useAngularServices,
} from '@/react/components'
import { LimitedTimezonesForSelect } from '@/utils/timezonesRelated'

type Props = {
  startingOptionString?: string | null
  onChange: any
  onTimezoneChange: any
  startingTimezone: { label: string; value: string }
}

export const TimePicker = ({
  onChange,
  startingOptionString,
  onTimezoneChange,
  startingTimezone,
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const possibleStartingTimeSplit = startingOptionString
    ? startingOptionString.split(':')
    : ['HH', 'MM']

  const [hour, setHour] = React.useState<number | string>(
    possibleStartingTimeSplit[0],
  )
  const [minute, setMinute] = React.useState<number | string>(
    possibleStartingTimeSplit[1],
  )
  const [dayTime, setDayTime] = React.useState('am')
  const [rerender, setRerender] = React.useState(false)

  const baseStyle = getBaseStyle(variableColor)

  const parseStartingString = (string) => {
    const split = moment(string, 'HH:mm')
    setHour(split.format('hh'))
    setMinute(split.format('mm'))
    setDayTime(split.format('a'))
    setTimeout(() => {
      setRerender(false)
    })
  }

  React.useEffect(() => {
    toMomentObj()
  }, [hour, minute, dayTime])

  React.useEffect(() => {
    if (startingOptionString) {
      setRerender(true)
      parseStartingString(startingOptionString)
    }
  }, [])

  const toMomentObj = () => {
    const selectedTime = moment(
      `${hour === 'HH' ? 12 : hour}:${minute === 'MM' ? 0 : minute} ${dayTime}`,
      'LT',
    )
    onChange(selectedTime.format('HH:mm'))
  }

  if (rerender) return true

  return (
    <section css={baseStyle}>
      <div className="input-row">
        <div className="single-input">
          <NumberInput
            textInputDefaultValue={hour}
            width={'60px'}
            maxNumber={12}
            valueCallback={(value) => {
              setHour(value)
            }}
            fakeNumberInput={true}
            height={36}
            placeholder={'HH'}
          />
        </div>
        <div className="single-input">
          <NumberInput
            textInputDefaultValue={minute}
            width={'60px'}
            maxNumber={59}
            valueCallback={(value) => {
              setMinute(value)
            }}
            height={36}
            fakeNumberInput={true}
            placeholder={'MM'}
          />
        </div>
        <div className="day-time-selector-holder">
          <div
            className={classNames('AM day-time-selector', {
              active: dayTime === 'am',
            })}
            onClick={() => {
              setDayTime('am')
            }}
          >
            <span>AM</span>
          </div>
          <div
            className={classNames('PM day-time-selector', {
              active: dayTime === 'pm',
            })}
            onClick={() => {
              setDayTime('pm')
            }}
          >
            <span>PM</span>
          </div>
        </div>
        <div className="select-holder">
          <ReactSelect
            options={LimitedTimezonesForSelect}
            defaultValue={startingTimezone}
            onChange={(value) => {
              onTimezoneChange(value)
            }}
            styles={{
              ...dropdownOptionStyles(variableColor, '36px'),
              control: (provided, state) => ({
                ...provided,
                ...dropdownStyle(false, '36px'),
              }),
            }}
            theme={(theme) => dropdownFiledTheme(theme, variableColor)}
          />
        </div>
      </div>
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    marginBottom: 15,
    '.header-label': { color: 'inherit' },
    '.input-row': {
      display: 'flex',
    },
    '.single-input': {
      marginRight: 5,
    },
    '.select-holder': {
      marginLeft: 10,
      width: 85,
    },
    '.day-time-selector-holder': {
      display: 'flex',
      '.day-time-selector': {
        padding: 3,
        height: 36,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#FFFFFF',
        backgroundImage: 'none',
        borderColor: '#e5e6e7',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '0',
        fontSize: '10px',
        lineHeight: '10px',
        marginBottom: '10px',
        cursor: 'pointer',
      },
      '.active': {
        backgroundColor: percentageShadeBlendColor(0.85, variableColor),
      },
    },
  })
}

function dropdownStyle(error, height = '30px') {
  return {
    minHeight: '0px',
    height,
    border: error && '1px solid #C80404',
  }
}

function hourTransform(
  hour: number,
  minute: number | string,
  daytime: string,
): string {
  const day = moment(`${hour} ${minute} ${daytime}`, 'h m A')
  return day.format('HH:mm:ss')
}

function changeStartingHour(hour: number): number {
  if (hour === 0) {
    return 12
  }
  if (hour > 12) {
    return hour - 12
  } else {
    return hour
  }
}
