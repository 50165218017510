import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { AssessmentNavBar } from '../components/AssessmentNavBar'
import { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { DraftRow } from './components/DraftRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'

const TABLE_HEADER_SETTINGS = [
  {
    className: 'training',
    name: 'Assessment',
    type: 'template__name',
    additionalStyles: {
      height: '30px',
      textAlign: 'left',
      width: '186px',
      maxWidth: '186px',
      minWidth: '186px',
      borderBottom: '1.5px solid #B3B3B3',
      paddingLeft: '12px',
      p: {
        display: 'flex',
        gap: '4px',
        width: 'fit-content',
        margin: '0 !important',
      },
      '.header-base': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      },
    },
  },
  {
    className: 'training',
    name: 'Name',
    type: 'external_contact_full_name',
    additionalStyles: {
      height: '30px',
      textAlign: 'left',
      paddingInline: '0px',
      width: '146px',
      maxWidth: '146px',
      minWidth: '146px',
      borderBottom: '1.5px solid #B3B3B3',
      p: {
        display: 'flex',
        gap: '4px',
        width: 'fit-content',
        margin: '0 !important',
      },
      '.header-base': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      },
    },
  },
  {
    className: 'training',
    name: 'Email',
    type: 'external_contact__email',
    additionalStyles: {
      height: '30px',
      textAlign: 'left',
      paddingInline: '0px',
      width: '177px',
      maxWidth: '177px',
      minWidth: '177px',
      borderBottom: '1.5px solid #B3B3B3',
      p: {
        display: 'flex',
        gap: '4px',
        width: 'fit-content',
        margin: '0 !important',
      },
      '.header-base': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      },
    },
  },
  {
    className: 'training',
    name: 'Company',
    type: 'external_contact__company_name',
    additionalStyles: {
      height: '30px',
      textAlign: 'left',
      paddingInline: '0px',
      width: '180px',
      maxWidth: '180px',
      minWidth: '180px',
      borderBottom: '1.5px solid #B3B3B3',
      p: {
        display: 'flex',
        gap: '4px',
        width: 'fit-content',
        margin: '0 !important',
      },
      '.header-base': {
        display: 'flex',
        alignItems: 'center',
        width: 'fit-content',
      },
    },
  },
  {
    className: 'training',
    name: 'Created',
    type: 'date_created',
    additionalStyles: {
      height: '30px',
      textAlign: 'left',
      paddingInline: '0px',
      width: '120px',
      maxWidth: '120px',
      minWidth: '120px',
      borderBottom: '1.5px solid #B3B3B3',
      p: {
        display: 'flex',
        gap: '4px',
        width: 'fit-content',
        margin: '0 !important',
      },
      '.header-base': {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
      },
    },
  },
  {
    className: '',
    name: 'Resend Link',
    filterHeader: false,
    additionalStyles: {
      height: '30px',
      textAlign: 'left',
      paddingInline: '0px',
      width: '120px',
      maxWidth: '120px',
      minWidth: '120px',
      borderBottom: '1.5px solid #B3B3B3',
      p: {
        display: 'flex',
        gap: '4px',
        width: 'fit-content',
        margin: '0 !important',
      },
      '.header-base': {
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
      },
    },
  },
]

export const DraftAssessments = () => {
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('-date_created')
  const [page, setPage] = useState(1)
  const [draftList, setDraftList] = useState([])

  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const isSafari = checkIsSafari()
  const styles = getStyles(variableColor, isSafari)

  useEffect(() => {
    const getDraftList = async () => {
      const { data } = await Api.get('assessment/assessments', {
        is_draft: true,
        search,
        order,
        page,
      })

      setDraftList(data)
    }

    getDraftList()
  }, [page, order, search])

  return (
    <section css={styles}>
      <CommonPageHeader headerText="Assessments" />
      <AssessmentNavBar />
      <main className="content__wrapper">
        <div className="search__wrapper">
          <Search
            searchCallback={setSearch}
            search={search}
            pageCallback={() => {}}
          />
        </div>
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              callback={setOrder}
              popUpLocation={false}
              updateUrl={false}
              order={order}
              setOrder={setOrder}
            />
            <tbody>
              {draftList?.results?.map((assessment, index) => (
                <DraftRow
                  key={assessment?.id}
                  assessment={assessment}
                  search={search}
                  index={index}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="switch__wrapper">
          <PageSwitcher
            pageLength={20}
            listCount={draftList?.count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </main>
    </section>
  )
}

const getStyles = (variableColor, isSafari) =>
  css({
    paddingBottom: '250px',
    tr: {
      position: 'relative',
    },
    '#search': {
      width: '264px',
    },
    '.content__wrapper': {
      background: '#fff',
      paddingInline: '20px',
      width: 'fit-content',
      paddingBottom: '20px',
      marginLeft: '5px',
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.search__wrapper': {
      paddingTop: '20px',
      paddingBottom: '15px',
    },
    '.centered__cell': {
      textAlign: 'center',
    },
    '.envelope__cell': {
      textAlign: 'center',
    },
    '.modal__wrapper': {
      right: !isSafari ? '-5px' : '240px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    td: {
      height: '40px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      paddingRight: '10px',
      svg: {
        cursor: 'pointer',
      },
    },
    '.assessment__td': {
      paddingLeft: '12px',
      maxWidth: '186px',
    },
    '.name__td': {
      maxWidth: '146px',
    },
    '.email__td': {
      maxWidth: '177px',
    },
    '.company__td': {
      maxWidth: '180px',
    },
    '@media(max-width: 1190px)': {
      '.table__wrapper': {
        overflowX: 'scroll',
        minHeight: '200px',
      },
      '.content__wrapper': {
        width: '100%',
      },
    },
  })
