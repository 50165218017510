import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { Field, FieldArray, useFormikContext } from 'formik'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import ReactSelect from 'react-select'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'
import { useAngularServices } from '@/react/components'
import { BinDeleteIcon } from '@/react/componentAssets'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { generateUUID } from '@/utils'
import { MultiOption } from './MultiOption'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'

const selectOptions = [
  { value: 'multi', label: 'Multiple Choice' },
  { value: 'true_false', label: 'True/False' },
]

export const QuizQuestion = ({ field, index, handleDelete }) => {
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const { values, setFieldValue } = useFormikContext()

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const customSelectStyles = {
    control: (provided, state) => ({
      display: 'flex',
      width: '159px',
      minHeight: '32px',
      height: '32px',
      border: field?.type ? '1px solid #CDCCCC' : '1px solid #C80404',
      background: '#fff',
      borderRadius: '0',
      outline: 'none',
      boxShadow: 'none',
      cursor: 'pointer',
      '&:hover': {
        cursor: 'pointer',
        border: '1px solid #e5e6e7',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
      color: '#B1B1B1',
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: 'pointer',
      fontSize: '12px',
      borderBottom: '1px solid #E5E6E7',
      background: state.isSelected
        ? variableColor
        : state.options.indexOf(state.data) % 2 === 1
        ? '#FAFAFA'
        : 'none',
      '&:hover': {
        background: percentageShadeBlendColor(0.1, variableColor),
        color: 'white',
      },
    }),
  }

  const addOption = () => {
    const options = field.options ? [...field.options] : []
    setFieldValue(`training_fields[${index}].options`, [
      ...options,
      { name: '', weight: '', id: generateUUID(), answer: false },
    ])
  }

  const isCorrectAnswer = field?.options.some(
    (option) => option.answer === true,
  )

  const handleOrderUpdate = (indexGoTo) => {
    const items = Array.from(values?.training_fields)
    const [reorderedItem] = items.splice(index, 1)
    items.splice(indexGoTo - 1, 0, reorderedItem)
    items.map((item, idx) => {
      item.order = idx + 1
      return item
    })

    setFieldValue('training_fields', items)
  }

  useEffect(() => {
    setFieldValue(
      'training_fields',
      values?.training_fields?.map((field, index) => {
        return {
          ...field,
          order: index + 1,
        }
      }),
    )
  }, [values?.training_fields?.length])

  const handleCheckboxChange = (optionIndex) => {
    const updatedOptions = field.options.map((option, idx) => ({
      ...option,
      answer: idx === optionIndex ? !option.answer : false,
    }))
    setFieldValue(`training_fields[${index}].options`, updatedOptions)
  }

  return (
    <div className="question__block">
      <div className="question__wrapper">
        <Field
          name={`training_fields[${index}].order`}
          className="input order"
          onBlur={(e) => {
            if (!e.target.value) {
              e.target.value = index + 1
              setFieldValue(`training_fields[${index}].order`, index + 1)
            }
          }}
          onChange={(e) => {
            if (isNaN(e.target.value)) {
              return
            }

            if (e.target.value > values.training_fields.length) {
              setFieldValue(
                `training_fields[${index}].order`,
                values.training_fields.length,
              )
            } else {
              setFieldValue(`training_fields[${index}].order`, e.target.value)
            }
          }}
          value={field?.order}
          onKeyDown={(e) => {
            if (e?.key === 'Enter') {
              if (+e.target.value === 0) {
                handleOrderUpdate(1)
              } else {
                handleOrderUpdate(e.target.value)
              }
            }
          }}
        />
        <Field
          type="text"
          as={FormikAutoExpandedTextArea}
          name={`training_fields[${index}].name`}
          className={classNames('input question', {
            error: !field?.name,
          })}
          value={field?.name}
        />
        <ReactSelect
          options={selectOptions}
          value={selectOptions?.find((option) => option?.value === field?.type)}
          name={`training_fields[${index}].type`}
          onChange={(selectOption) => {
            if (selectOption.value === 'true_false') {
              setFieldValue(`training_fields[${index}]`, {
                ...field,
                type: selectOption?.value,
                options: [
                  {
                    order: 0,
                    name: 'True',
                    deleted: false,
                    answer: false,
                    field: 33417,
                  },
                  {
                    order: 1,
                    name: 'False',
                    deleted: false,
                    answer: false,
                    field: 33417,
                  },
                ],
              })
            } else {
              setFieldValue(`training_fields[${index}]`, {
                ...field,
                type: selectOption?.value,
                options: [
                  {
                    name: '',
                    weight: '',
                    id: generateUUID(),
                    answer: false,
                  },
                ],
              })
            }
          }}
          styles={customSelectStyles}
          isSearchable={false}
        />
        <div className="delete__wrapper">
          {isDeleteModalOpen ? (
            <ConfirmationModal
              action={'Delete'}
              handleClick={() => {
                handleDelete(index)
                setDeleteModalOpen(false)
              }}
              handleClose={() => {
                setDeleteModalOpen(false)
              }}
              isScrollDisabled={false}
            />
          ) : null}
          <BinDeleteIcon
            color={variableColor}
            height="22px"
            width="17px"
            onClick={() => {
              setDeleteModalOpen(true)
            }}
          />
        </div>
      </div>
      {field?.type === 'true_false' ? (
        <div className="checkboxes__wrapper">
          <div className="checkmark__wrapper">
            <label className="checkbox-container">
              <Field
                type="checkbox"
                checked={field?.options[0].answer}
                name={`training_fields[${index}].options[${0}].answer`}
                onChange={() => {
                  handleCheckboxChange(0)
                }}
              />
              <span
                className={classNames('checkmark', {
                  error: !isCorrectAnswer,
                })}
              >
                <ThinCheckmark color={variableColor} />
              </span>
            </label>
            <p className="checkbox__text">{field?.options[0]?.name}</p>
          </div>
          <div className="checkmark__wrapper">
            <label className="checkbox-container">
              <Field
                type="checkbox"
                checked={field?.options[1].answer}
                name={`training_fields[${index}].options[${1}].answer`}
                onChange={() => {
                  handleCheckboxChange(1)
                }}
              />
              <span
                className={classNames('checkmark', {
                  error: !isCorrectAnswer,
                })}
              >
                <ThinCheckmark color={variableColor} />
              </span>
            </label>
            <p className="checkbox__text">{field?.options[1]?.name}</p>
          </div>
        </div>
      ) : (
        <>
          {field?.type === 'multi' ? (
            <p
              className={classNames('button', {
                button__error: !field?.options?.length,
              })}
              onClick={addOption}
            >
              + Answer Choices
            </p>
          ) : null}
          <FieldArray
            name={`training_fields[${index}].options`}
            render={(arrayHelpers) => {
              async function handleOnDragEnd(result) {
                if (!result.destination) return

                const items = Array.from(field?.options)
                const [reorderedItem] = items.splice(result.source.index, 1)
                items.splice(result.destination.index, 0, reorderedItem)
                items.forEach((item, idx) => {
                  item.order = idx + 1
                })

                await setFieldValue(`training_fields[${index}].options`, items)
              }

              return (
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="options">
                    {(provided) => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        {field?.options.map((option, optionIndex) => (
                          <MultiOption
                            key={optionIndex}
                            option={option}
                            fIndex={index}
                            oIndex={optionIndex}
                            handleDelete={arrayHelpers.remove}
                            isCorrectAnswer={isCorrectAnswer}
                            handleCheckboxChange={handleCheckboxChange}
                          />
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )
            }}
          />
        </>
      )}
    </div>
  )
}
