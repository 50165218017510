import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormQRCodes',
    function ($scope, $stateParams, $state, Api, app, CurrentUser, $log) {
      $log.debug('FormQRCodes')

      $scope.titleFormAssign = app.app_mobile_form_display
      $scope.titleMobileForm = pluralize.singular(app.app_mobile_form_display)
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.prevousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.pageNumber = 1
      $scope.pageSize = 20
      $scope.forms = []
      $scope.totalItems = 0
      $scope.order = 'name'
      $scope.app = app

      $scope.tableHeaders = [
        { key: 'name', display: ' Name', sortable: true },
        {
          key: 'qr_code',
          display: 'QR Code',
          sortable: false,
          centered: true,
          info: true,
          info_type: 'qr_code',
        },
      ]

      $scope.changePage = () => {
        getForms()
        $state.transitionTo(
          'app.forms.qr_codes',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.deleteForm = (form) => {
        Api.MobileForms.patch(
          { id: form.id, deleted: true, is_active: false },
          () => {
            getForms()
          },
        )
      }

      function query() {
        let q = {
          page: $scope.pageNumber,
          client_id: CurrentUser.getClientId(),
          application: app.id,
          search: $scope.searchExecuted ? $scope.search : null,
          is_active: 'True',
          annotate_app_project_count: true,
          serializer: 'index',
          only_assigned_projects: true,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      function getForms() {
        Api.MobileForms.get(query(), (resp) => {
          $scope.totalItems = resp.count
          $scope.forms = resp.results
          $scope.forms.map(function (form) {
            form.isOpen = false
          })
        })
      }

      getForms()
    },
  )
