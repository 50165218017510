import { useRef, useEffect, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'
import { css } from '@emotion/react'
import deleteCross from '/src/assets/icons/X Gray.svg'
import { ButtonElement } from '@components/ButtonElement'
interface Props {
  hide: () => void
  onSubmit: () => void
}
export const SignatureAndSketch = ({ hide, onSubmit }: Props) => {
  let signatureCanvas = null

  const clearImage = () => {
    signatureCanvas?.clear()
  }
  const convertImage = () => {
    onSubmit(b64toBlob(signatureCanvas?.toDataURL('image/png')))
    hide()
  }

  const modalRef = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        hide()
      }
    }

    window.addEventListener('click', handleClickOutside)
    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [modalRef])

  const [screenWidth, setScreenWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [screenWidth])

  return (
    <div css={baseStyle}>
      <div className="popupWindow">
        <div className="exitButtonRow">
          <img
            src={deleteCross}
            alt="EXIT"
            onClick={() => {
              hide()
            }}
          />
        </div>
        <div className="canvas__wrapper">
          <SignatureCanvas
            penColor="black"
            backgroundColor="white"
            canvasProps={{
              className: 'sigCanvas',
              width: screenWidth > 700 ? 400 : 250,
              height: screenWidth > 700 ? 200 : 150,
            }}
            ref={(ref) => {
              signatureCanvas = ref
            }}
          />
        </div>
        <div className="buttonRow">
          <ButtonElement
            text={'Clear'}
            functionToTrigger={clearImage}
            fontSize={'15px'}
          />
          <ButtonElement
            text={'Save'}
            functionToTrigger={convertImage}
            buttonType={'submit'}
            fontSize={'15px'}
          />
        </div>
      </div>
    </div>
  )
}

function b64toBlob(dataURI: string) {
  const byteString = atob(dataURI.split(',')[1])
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)

  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }
  return new Blob([ab], { type: 'image/png' })
}

const baseStyle = css({
  position: 'fixed',
  width: '100vw',
  height: '100%',
  zIndex: 50,
  backgroundColor: 'rgba(0,0,0,0.5)',
  left: 0,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '@media(max-width: 700px)': {
    '.popupWindow': {
      width: '330px',
    },
    '.buttonRow': {
      display: 'flex',
      gap: '35px',
    },
    button: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  '.popupWindow': {
    padding: 10,
    paddingBottom: 30,
    backgroundColor: 'white',
    '.sigCanvas': {
      border: '1px solid #343a40',
      marginRight: 50,
      marginLeft: 50,
      width: '400px',
    },
    '.canvas__wrapper': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.exitButtonRow': {
      marginBottom: 30,
      display: 'flex',
      justifyContent: 'right',
      img: {
        height: 20,
        cursor: 'pointer',
      },
    },
    '.buttonRow': {
      marginTop: 20,
      display: 'flex',
      paddingLeft: 140,
      paddingRight: 140,
      justifyContent: 'space-around',
    },
  },
})
