import { resolvers } from '../resolvers'

/** @ngInject */
export function adminConfig($stateProvider) {
  $stateProvider
    .state('admin', {
      abstract: true,
      templateUrl: 'app/views/common/content.html',
      resolve: {
        ...resolvers.adminOnlyViewResolver,
        ...resolvers.appsResolver,
      },
    })
    .state('admin.disclaimer', {
      url: '/disclaimer',
      templateUrl: 'app/views/settings_disclaimer.html',
      controller: 'SettingsDisclaimerCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.general_settings', {
      url: '/configurable_settings',
      templateUrl: 'app/views/settings_general.html',
      controller: 'SettingsGeneralCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.templates', {
      url: '/templates',
      templateUrl: 'app/views/settings_reports.html',
      controller: 'SettingsReportCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.service_accounts', {
      url: '/service_accounts?pageNumber&order&search',
      template: '<service-accounts-list />',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.service_accounts_create', {
      url: '/service_accounts/create',
      template: '<service-accounts />',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.service_accounts_edit', {
      url: '/service_accounts/:id/edit',
      template: '<service-accounts />',
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.service_accounts_view', {
      url: '/service_accounts/:id/view',
      template: '<service-account-view />',
      params: {
        isShowSecret: false,
      },
      controller: function () {},
      resolve: resolvers.adminOnlyViewResolver,
    })
}
