import React, { useState } from 'react'
import { ButtonElement } from '@/react/components'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { css } from '@emotion/react'
import { useEffect, useRef } from 'react'
import classNames from 'classnames'

export const ConfirmationModal = ({
  setModalVisible,
  module,
  isCopyClicked,
  handleClick,
}) => {
  const modalRef = useRef()
  const [isButtonDisabled, setButtonDisabled] = useState(false)

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      setModalVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  return (
    <div css={baseStyle} className="modal__wrapper" ref={modalRef}>
      <img
        src={closeIcon}
        alt="cross-icon"
        className="close__icon"
        onClick={() => {
          setModalVisible(false)
        }}
      />
      <div className="modal__info">
        <p
          className={classNames('modal__message', {
            delete__title: !isCopyClicked,
          })}
        >
          {isCopyClicked ? 'Copy' : 'Delete'}
          <br />
          {module}?
        </p>
        {!isCopyClicked && (
          <p className="delete__subtitle">
            This training will be removed from all employees in progress.
          </p>
        )}
        <ButtonElement
          text="OK"
          buttonType="submit"
          functionToTrigger={() => {
            setButtonDisabled(true)
            handleClick()
          }}
          disabled={isButtonDisabled}
        />
      </div>
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  backgroundColor: 'white',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  marginTop: '-27px',
  right: '10px',
  top: '28px',
  zIndex: 1,
  '.close__icon': {
    position: 'absolute',
    right: '4px',
    top: '5px',
    width: '13px',
    height: '13px',
    cursor: 'pointer',
  },
  '.modal__message': {
    color: '#666',
    fontWeight: '700',
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    marginTop: '10px',
    marginBottom: '10px',
    width: '146px',
    textAlign: 'center',
    maxHeight: '54px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  '.delete__title': {
    marginBottom: '14px',
  },
  '.delete__subtitle': {
    color: '#666',
    textAlign: 'center',
    fontSsize: '14px',
    margin: 0,
    marginBottom: '25px',
    width: '206px',
  },
  '.message__email': {
    margin: 0,
    fontWeight: 600,
  },
  '.modal__info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '17px',
  },
})
