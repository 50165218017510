angular
  .module('ccs')
  .directive('iboxtools', iboxTools)
  .directive('iboxtoolstoolbar', iboxToolsToolbar)
  .directive('iboxtoolstoolbartopic', iboxToolsTopicHeader)
  .directive('iboxtoolseditheader', iboxToolsEditHeader)

/**
 * iboxTools - Directive for iBox tools elements in right corner of ibox
 */
function iboxTools($timeout) {
  return {
    restrict: 'A',
    scope: {
      collapsed: '@',
    },
    templateUrl: 'app/views/common/ibox_tools.html',
    controller: function ($scope, $element) {
      // Function for collapse ibox
      $scope.showhide = function (noslide) {
        var ibox = $element.closest('div.ibox')
        var icon = $element.find('i:first')
        var content = ibox.find('div.ibox-content')
        if (noslide) {
          content.hide()
        } else {
          content.slideToggle(200)
        }

        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down')
        ibox.toggleClass('').toggleClass('border-bottom')
        $timeout(function () {
          ibox.resize()
          ibox.find('[id^=map-]').resize()
        }, 50)
      }
      if ($scope.collapsed === '') {
        $scope.showhide(true)
      }
      // Function for close ibox
      $scope.closebox = function () {
        var ibox = $element.closest('div.ibox')
        ibox.remove()
      }
    },
  }
}

function iboxToolsToolbar($timeout) {
  return {
    restrict: 'A',
    scope: {
      collapsed: '@',
      title: '@',
    },
    templateUrl: 'app/views/common/ibox_tools_header.html',
    controller: function ($scope, $element) {
      // Function for collapse ibox

      $element.click(function (e) {
        $scope.showhide()
      })

      $scope.showhide = function (noslide) {
        var ibox = $element.closest('div.ibox')
        var icon = $element.find('i:first')

        var content = ibox.find('div.ibox-content')
        if (noslide) {
          content.hide()
        } else {
          content.slideToggle(200)
        }

        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down')
        ibox.toggleClass('').toggleClass('border-bottom')
        $timeout(function () {
          ibox.resize()
          ibox.find('[id^=map-]').resize()
        }, 50)
      }

      if ($scope.collapsed === '') {
        $scope.showhide(true)
      }
    },
  }
}

function iboxToolsEditHeader($timeout) {
  return {
    restrict: 'A',
    scope: {
      collapsed: '@',
      cabinet: '=',
    },
    templateUrl: 'app/views/common/ibox_tools_edit_header.html',
    controller: function ($scope, Api, $element) {
      // Function for collapse ibox
      $scope.isEditing = false
      $element.click(function (e) {
        $scope.showhide()
      })

      $scope.showhide = function (noslide) {
        var ibox = $element.closest('div.ibox')
        var icon = $element.find('i:last')

        var content = ibox.find('div.ibox-content')
        if (noslide) {
          content.hide()
        } else {
          content.slideToggle(200)
        }

        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down')
        ibox.toggleClass('').toggleClass('border-bottom')
        $timeout(function () {
          ibox.resize()
          ibox.find('[id^=map-]').resize()
        }, 50)
      }

      if ($scope.collapsed === '') {
        $scope.showhide(true)
      }

      $scope.updateCabinetName = function (cabinet) {
        $scope.isEditing = false
        Api.Cabinets.patch(
          { id: cabinet.id, name: cabinet.name },
          function (resp) {
            if (resp) {
              $scope.cabinet.name = resp.name
            }
          },
        )
      }

      $scope.editCabinetName = function () {
        $scope.currentTitle = angular.copy($scope.cabinet.name)
        $scope.isEditing = true
      }

      $scope.cancelCabinetNameEdit = function () {
        $scope.isEditing = false
        $scope.cabinet.name = $scope.currentTitle
      }
    },
  }
}

function iboxToolsTopicHeader($timeout) {
  return {
    restrict: 'A',
    scope: {
      collapsed: '@',
      title: '@',
      topicId: '@',
      topicName: '@',
      topicOrder: '@',
      hideActions: '@',
      maxOrder: '@',
    },
    transclude: true,
    templateUrl: 'app/views/common/ibox_tools_topic_header.html',
    controller: function (
      $scope,
      $element,
      Api,
      $transclude,
      ngDialog,
      $stateParams,
      CurrentUser,
    ) {
      // Function for collapse ibox

      $element.click(() => {
        $scope.showhide()
      })
      $scope.role = CurrentUser.getRole()

      $scope.showhide = function (noslide) {
        var ibox = $element.closest('div.ibox')
        var icon = $element.find('i:last')

        var content = ibox.find('div.ibox-content')
        if (noslide) {
          content.hide()
        } else {
          content.slideToggle(200)
        }

        // Toggle icon from up to down
        icon.toggleClass('fa-chevron-up').toggleClass('fa-chevron-down')
        ibox.toggleClass('').toggleClass('border-bottom')
        if (icon.hasClass('fa-chevron-up')) {
          content.addClass('remove-top-border')
          ibox.find('.ibox-title').removeClass('gray-bg')
          ibox.find('.ibox-title').find('i:not(:last)').addClass('ccs-link')
        } else {
          ibox.find('.ibox-title').addClass('gray-bg')
          ibox.find('.ibox-title').find('i:not(:last)').removeClass('ccs-link')
          content.removeClass('remove-top-border')
        }
        $timeout(function () {
          ibox.resize()
          ibox.find('[id^=map-]').resize()
        }, 50)
      }

      if ($scope.collapsed === '') {
        $scope.showhide(true)
      }

      $scope.deleteTopic = function () {
        Api.Topic.delete({ id: $scope.topicId }, function (resp) {
          $scope.$emit('removeStep')
        })
      }

      $scope.editTopicName = function (topicName, topicOrder) {
        ngDialog.open({
          template: 'app/views/add_new_topic_dialog.html',
          className: 'ngdialog-theme-default custom-content',
          scope: $scope,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.topic = {
              id: $scope.topicId,
              name: topicName || null,
              order: topicOrder || null,
              training: $stateParams.trainingID,
            }

            $scope.save = function () {
              if (topicName) {
                Api.Topic.patch($scope.topic, function (resp) {
                  if (resp) {
                    $scope.$emit('updateTopic')
                    $scope.closeThisDialog()
                  }
                })
              }
            }

            $scope.close = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }
    },
  }
}
