export const toolbarSettings: { items: string[] } = {
  items: [
    'Bold',
    'Italic',
    'Underline',
    'StrikeThrough',
    'FontName',
    'FontSize',
    'FontColor',
    'BackgroundColor',
    'LowerCase',
    'UpperCase',
    '|',
    'Alignments',
    'OrderedList',
    'UnorderedList',
    'Outdent',
    'Indent',
    '|',
    'CreateLink',
    'Image',
    '|',
    'ClearFormat',
    'FullScreen',
    '|',
    'Undo',
    'Redo',
  ],
}

export const quickToolbarSettings: { image: string[]; link: string[] } = {
  image: [
    'Replace',
    'Align',
    'Caption',
    'Remove',
    'InsertLink',
    'OpenImageLink',
    '-',
    'EditImageLink',
    'RemoveImageLink',
    'Display',
    'AltText',
    'Dimension',
  ],
  link: ['Open', 'Edit', 'UnLink'],
}
