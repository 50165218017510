import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const ArchiveIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      xmlSpace="preserve"
      onClick={onClick}
    >
      <path
        style={{ fill: 'currentcolor' }}
        d="M18.4,4.2v0.2c0,0.5-0.4,0.8-0.8,0.8H2.4C2,5.2,1.6,4.9,1.6,4.4V4.2c0-0.5,0.4-0.8,0.8-0.8h15.1
	C18,3.4,18.4,3.8,18.4,4.2z M2.5,7.3h14.9v8.4c0,0.5-0.4,0.9-0.9,0.9H3.5c-0.5,0-0.9-0.4-0.9-0.9V7.3z M7.2,11
	c0,0.5,0.4,0.9,0.9,0.9h3.7c0.5,0,0.9-0.4,0.9-0.9c0-0.5-0.4-0.9-0.9-0.9H8.1C7.6,10.1,7.2,10.5,7.2,11z"
      />
    </svg>
  )
}
