import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import pluralize from 'pluralize'
import { css } from '@emotion/react'
import SignatureCanvas from 'react-signature-canvas'
import {
  Accordion,
  ButtonElement,
  TextInput,
  useAngularServices,
} from '@/react/components'
import { Alert } from '@screens/ToolboxTalks/components/Alert'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useRouter } from '@/react/hooks'

export const ExternalSignatures = () => {
  const { Api, Notification } = useAngularServices()
  const { stateService } = useRouter()
  const clientObjectKey = stateService.params.key
  const [toolboxData, setToolboxData] = useState<boolean | null>(null)
  const [isSynchronized, setIsSynchronized] = useState<boolean | null>(null)
  const [userName, setUserName] = useState<string>('')
  const [companyName, setCompanyName] = useState<string>('')
  const [isSignMsgVisible, setIsSignMsgVisible] = useState<boolean>(true)
  const [isCanvasTouched, setIsCanvasTouched] = useState<boolean>(false)
  const [error, setError] = useState(null)
  const [isSignatureSent, setIsSignatureSent] = useState<boolean>(false)
  let isSubmitting = false
  const signatureWrapperRef = useRef(null)
  const [canvasMargin, setCanvasMargin] = useState<number>(0)
  const [canvasClearPosition, setCanvasClearPosition] = useState<number>(5)
  const [mainColor, setMainColor] = useState<string>('rgb(57, 129, 205)')
  const isSubmitBtnDisabled = () =>
    !userName.length || !isCanvasTouched || isSignMsgVisible

  let canvasFunctionality = {}

  function b64toBlob(dataURI): Blob {
    const byteString = atob(dataURI.split(',')[1])
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)

    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: 'image/png' })
  }

  const isToolboxSynchronised = async () => {
    try {
      const { data } = await Api.get(`shares/toolbox/${clientObjectKey}`)
      setIsSynchronized(data.synchronised)
      if (data.synchronised) {
        setError(null)
      }
    } catch (err) {
      setTimeout(() => {
        Notification.clearNotification()
      })
    }
  }

  const waitForToolboxSync = () => {
    intervalRef.current = setInterval(() => {
      if (isSynchronized) {
        getToolboxData()
        clearInterval(intervalRef.current)
      } else {
        isToolboxSynchronised()
      }
    }, 5000)
  }

  useEffect(() => {
    if (!isSynchronized) {
      waitForToolboxSync()
    } else {
      clearInterval(intervalRef.current)
    }
    return () => {
      clearInterval(intervalRef.current)
    }
  }, [isSynchronized])

  const intervalRef = useRef(null)

  const createNewToolboxData = async () => {
    const { data: topic } = await Api.get(
      `shares/toolbox_topics/${stateService.params.topicId}`,
      {},
    )
    const { data: project } = await Api.get(
      `shares/projects/${stateService.params.projectId}`,
      {},
    )
    const { data: client } = await Api.get(
      `shares/clients/${stateService.params.clientId}`,
      {},
    )
    const newTalk = {
      application: {
        id: stateService?.params?.appId,
        party_observed_language:
          client.general_settings?.party_observed_language,
        projects_language: client?.general_settings?.projects_language,
        projects_number_language:
          client?.general_settings?.projects_number_language,
      },
      client: { general_settings: client?.general_settings },
      client_object_key: clientObjectKey,
      company: client?.company,
      disclaimer: client?.general_settings?.disclaimer,
      topic,
      project,
      notes: [],
      photos: [],
      signatures: [],
      user: null,
      synchronised: true,
    }
    const sendNewTalk = {
      application: Number(stateService.params.appId),
      client: client?.id,
      client_object_key: clientObjectKey,
      project: project?.id,
      topic: topic?.id,
      user: null,
      is_active: true,
      deleted: false,
      notes: [],
      photos: [],
      signatures: [],
      synchronised: true,
    }

    await Api.post(`shares/toolbox/${clientObjectKey}`, sendNewTalk)

    if (newTalk.client?.general_settings?.colors.secondary_color) {
      setMainColor(newTalk.client.general_settings.colors.secondary_color)
      document.body.style = `background: ${newTalk.client.general_settings.colors.secondary_color};`
    } else {
      document.body.style = `background: ${mainColor}`
    }

    setToolboxData(newTalk)
  }
  const getToolboxData = async () => {
    try {
      const { data: res } = await Api.get(
        `shares/toolbox/${clientObjectKey}`,
        {},
      )
      if (res.client?.general_settings?.colors.secondary_color) {
        setMainColor(res.client.general_settings.colors.secondary_color)
        document.body.style = `background: ${res.client.general_settings.colors.secondary_color};`
      } else {
        document.body.style = `background: ${mainColor}`
      }
      setToolboxData(res)
      setIsSynchronized(res.synchronised)
      if (!res.synchronised) {
        setError(
          res.message
            ? res.message
            : 'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.',
        )
      }
    } catch ({ data }) {
      setTimeout(() => {
        Notification.clearNotification()
      })
      setIsSynchronized(true)
      await createNewToolboxData()
    }
  }

  useEffect(() => {
    getToolboxData()
  }, [])

  useEffect(() => {
    const handleResize = () => {
      const FORM_WIDTH_LIMIT: number = 445
      const windowWidth: number = window.innerWidth
      const difference: number = FORM_WIDTH_LIMIT - windowWidth
      const margin: number = (FORM_WIDTH_LIMIT - windowWidth) / 2
      if (difference > 0) {
        setCanvasMargin(margin * -1)
      } else {
        setCanvasMargin(0)
      }
      // Checking if the canvas is hitting the edge of the form and moving clear button
      if (margin >= 45) {
        setCanvasClearPosition(margin - 45)
      } else {
        setCanvasClearPosition(5)
      }
    }

    handleResize()

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [toolboxData])

  const submit = async () => {
    if (isSubmitting) return
    isSubmitting = true
    try {
      const signature = b64toBlob(canvasFunctionality.toDataURL('image/png'))
      const response = await Api.uploadImage(signature, {})
      const response2 = await Api.Signatures.post({
        image: response.data.id,
        printed_name: userName,
        company_name: companyName,
        user: toolboxData?.user?.id,
      })

      const signatureIDs = toolboxData.signatures.map(
        (signature) => signature.id,
      )

      signatureIDs.push(response2.data.id)

      await Api.put(
        `shares/toolbox/${clientObjectKey}/sign/`,
        {
          signatures: signatureIDs,
        },
        () => {
          setIsSignatureSent(true)
        },
        (err) => {
          setError(err.message)
          console.error(err)
        },
      )
    } catch (err) {
      isSubmitting = false
      setError(err.message)
      throw new Error(err)
    }
  }

  if (!toolboxData) return null

  return (
    <section css={wrapperStyle}>
      {error && <Alert text={error} type="danger" />}
      {isSynchronized && (
        <main css={baseStyle}>
          <div className="logo-w">
            <img
              className="logo"
              src={toolboxData.company?.logo_url}
              alt="logo"
            />
          </div>
          <div
            className={classNames('wrapper', { submitted: isSignatureSent })}
          >
            <div className="title-block">
              {toolboxData.topic?.name && (
                <h2 className="page-title">{toolboxData.topic.name}</h2>
              )}
              <div className="page-title-project">
                {!isSignatureSent &&
                  toolboxData.application?.projects_display && (
                    <span>
                      {pluralize.singular(
                        toolboxData.application.projects_display,
                      )}
                      :{' '}
                    </span>
                  )}
                {toolboxData.project?.name}
              </div>
            </div>
            {!isSignatureSent && (
              <>
                {toolboxData.topic?.summary?.length > 0 && (
                  <div style={{ marginBottom: '15px' }}>
                    <Accordion
                      title="Summary"
                      content={toolboxData.topic.summary}
                    />
                  </div>
                )}
                {toolboxData.topic?.importance?.length > 0 && (
                  <div style={{ marginBottom: '15px' }}>
                    <Accordion
                      title="Why is it important?"
                      content={toolboxData.topic.importance}
                    />
                  </div>
                )}
                {toolboxData.topic?.consider?.length > 0 && (
                  <div style={{ marginBottom: '15px' }}>
                    <Accordion
                      title="Things to consider"
                      content={toolboxData.topic.consider}
                    />
                  </div>
                )}
                {toolboxData.topic?.discussion?.length > 0 && (
                  <div style={{ marginBottom: '15px' }}>
                    <Accordion
                      title="Discussion items"
                      content={toolboxData.topic.discussion}
                    />
                  </div>
                )}
                <div
                  className="signature-wrapper"
                  style={{
                    borderColor: isSignMsgVisible ? '#C80404' : '#676A6B',
                  }}
                  css={css({
                    marginLeft: canvasMargin,
                    marginRight: canvasMargin,
                  })}
                  ref={signatureWrapperRef}
                >
                  {isSignMsgVisible && (
                    <div className="signature-text">Sign here</div>
                  )}
                  <SignatureCanvas
                    penColor="black"
                    backgroundColor="white"
                    canvasProps={{
                      width: 320,
                      height: 112,
                      className: 'sigCanvas',
                    }}
                    ref={(ref) => {
                      canvasFunctionality = ref
                    }}
                    onBegin={() => setIsSignMsgVisible(false)}
                    onEnd={() => setIsCanvasTouched(true)}
                  />
                  {!isSignMsgVisible && (
                    <img
                      src={closeIcon}
                      css={css({ right: canvasClearPosition })}
                      className="signature__clear"
                      onClick={() => {
                        canvasFunctionality.clear()
                        setIsSignMsgVisible(true)
                      }}
                    />
                  )}
                </div>
                <div
                  className={classNames('common-field name-field', {
                    touched: userName,
                  })}
                >
                  <TextInput
                    defaultValue={userName}
                    valueCallback={(value) => setUserName(value)}
                    placeholder="Name (Required)"
                    height={34}
                    startUpCase={true}
                    allowEmptyStart={false}
                  />
                </div>
                <div className="common-field company-field">
                  <TextInput
                    defaultValue={companyName}
                    valueCallback={(value) => setCompanyName(value)}
                    placeholder="Company (Optional)"
                    height={34}
                    startUpCase={true}
                    allowEmptyStart={false}
                  />
                </div>
                <ButtonElement
                  text="SUBMIT"
                  functionToTrigger={submit}
                  buttonType={'submit'}
                  fontSize={'15px'}
                  possibleVariableColor={mainColor}
                  width="100%"
                  height="54px"
                  disabled={isSubmitBtnDisabled()}
                />
              </>
            )}
            {isSignatureSent && (
              <>
                <div className="success-data-text username">{userName}</div>
                <div className="success-data-text company-name">
                  {companyName}
                </div>
                <div className="success-msg">
                  Your signature has been added.
                </div>
              </>
            )}
          </div>
        </main>
      )}
    </section>
  )
}

const wrapperStyle = css({
  marginTop: '-40px',
  paddingBottom: '25px',
  '@media (max-width: 799px)': {
    paddingLeft: '15px',
  },
})

const baseStyle = css({
  width: 'calc(100% + 20px)',
  marginLeft: -10,
  marginRight: -10,
  '.logo-w': {
    margin: '10px auto 25px',
    width: '145px',
    img: {
      maxWidth: '100%',
    },
  },
  '.wrapper': {
    backgroundColor: 'white',
    position: 'relative',
    margin: 'auto',
    overflow: 'hidden',
    borderRadius: '30px !important',
    padding: '20px 46px 65px !important',
    maxWidth: '415px',
    '&.submitted': {
      padding: '20px 46px 35px !important',
    },
  },
  '.title-block': {
    textAlign: 'center',
    color: '#676A6B',
  },
  '.page-title': {
    fontSize: '28px',
    fontWeight: 200,
    lineHeight: '33px',
  },
  '.page-title-project': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '17px',
    paddingBottom: '35px',
    marginBottom: '22px',
    borderBottom: '1px solid rgba(103, 106, 107, 0.5)',
  },
  '.signature-wrapper': {
    border: '1px solid',
    background: '#fff',
    position: 'relative',
    height: '114px',
  },
  '.signature-text': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    color: 'rgba(76, 127, 199, 0.50)',
    fontSize: '20px',
    fontWeight: 300,
    userSelect: 'none',
  },
  '.signature__clear': {
    position: 'absolute',
    cursor: 'pointer',
    width: '16px',
    height: '16px',
    top: 5,
    paddingTop: '0 !important',
  },
  '.common-field': {
    width: '100%',
    input: {
      width: '100%',
      border: '1px solid',
      padding: '0 19px',
      '&::placeholder': {
        color: '#676A6B',
        fontWeight: 200,
        fontSize: '12px',
      },
    },
  },
  '.name-field': {
    margin: '15px 0 5px',
    input: {
      border: '1px solid #C80404',
    },
  },
  '.touched': {
    input: {
      borderColor: '#676A6B',
    },
  },
  '.company-field': {
    margin: '0 0 20px',
    input: {
      borderColor: '#676A6B',
    },
  },
  button: {
    fontSize: '16px',
    '&:disabled': {
      backgroundColor: '#888 !important',
      borderColor: '#888 !important',
      color: '#fff !important',
    },
  },
  '.success-data-text': {
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '14px',
    color: '#676A6B',
    '&.company-name': {
      marginBottom: '15px',
    },
  },
  '.success-msg': {
    paddingTop: '10px',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 600,
    color: '#676A6B',
  },
})
