import { useEffect, useLayoutEffect, useState } from 'react'
import { NavBar } from '@/react/screens/TrainingsPages/LMSCommonComponents/NavBar'
import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import 'react-circular-progressbar/dist/styles.css'
import { TableElement } from './TableElement'
import { useRouter } from '@/react/hooks'
import { updateQueryParams } from '@/utils/updateQueryParams'
import { Toggle } from './components/Toggle'

export const TrainingProgramBase = () => {
  const { stateService } = useRouter()
  const [page, setPage] = useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [search, setSearch] = useState(
    stateService.params.search ? stateService.params.search : '',
  )
  const [jobs, setJobs] = useState([])
  const [listCount, setListCount] = useState(0)
  const { Api } = useAngularServices()
  const [checked, setChecked] = useState<boolean>(
    stateService.params.order === 'true',
  )

  useEffect(() => {
    const getJobs = async () => {
      const { data: jobsFromServer } = await Api.get('jobs', {
        page,
        search,
        user_filter: true,
        completed: checked ? 'false' : '',
      })

      setListCount(jobsFromServer.count)
      setJobs(jobsFromServer.results)
    }
    getJobs()
  }, [page, search, checked])

  useEffect(() => {
    updateQueryParams({ order: checked }, true)
  }, [checked])

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <div className="lms__wrapper">
        <div className="section__header">
          <h4 className="page__text header">Training Modules</h4>
          <div className="row__wrap">
            <Search
              search={search}
              searchCallback={setSearch}
              pageCallback={setPage}
            />
            <Toggle setChecked={setChecked} checked={checked} />
          </div>
        </div>
        <div className="lms__table">
          {jobs.map((job, index) => (
            <TableElement key={job.id} job={job} index={index} />
          ))}
        </div>

        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={listCount}
            currentPage={page}
            callback={setPage}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  paddingBottom: '200px',
  '@media(max-width: 1180px)': {
    '.row__wrap': {
      flexDirection: 'column',
      gap: '10px !important',
      alignItems: 'normal !important',
    },
    '#search': {
      width: '260px',
    },
    '.lms__wrapper': {
      width: '100% !important',
    },
  },
  '.header': {
    margin: '10px 0',
  },
  '.lms__wrapper': {
    background: 'white',
    width: '60%',
    marginLeft: '5px',
  },
  '.section__header': {
    marginLeft: '14px',
  },
  '.row__wrap': {
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
  },
  '.lms__table': {
    marginTop: '16px',
    paddingInline: '14px',
    paddingBottom: '15px',
  },
  '.page__text': {
    color: '#68696D',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    fontWeight: 700,
    letterSpacing: ' -0.12px',
  },
  '.switch__wrapper': {
    display: 'flex',
    gap: '10px',
  },
  '.icon': {
    cursor: 'pointer',
  },
  '.switch': {
    label: {
      marginBottom: '0px',
    },
  },
  '.dark': {
    background: '#F2F3F5',
    borderColor: '#E7EAEC',
  },
})
