import { BinDeleteIcon } from '@/react/componentAssets'
import { css } from '@emotion/react'
import { Field, useFormikContext } from 'formik'
import { TextInputStyle } from '@screens/components'
import { useAngularServices } from '@/react/components'
import classNames from 'classnames'
import manageIcon from '@/assets/icons/manage-icon.svg'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { Draggable } from 'react-beautiful-dnd'
import { useState } from 'react'

export const SingleOption = ({ option, qIndex, oIndex, handleDelete }) => {
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { values, setFieldValue, setFieldTouched } = useFormikContext()
  const { name, weight } = option

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const handleWeightChange = (event) => {
    const newValue = event.target.value
    if (!isNaN(newValue) && newValue >= 0 && newValue <= 1000) {
      setFieldValue(
        `questionsList[${qIndex}].options[${oIndex}].weight`,
        newValue,
      )
    }
  }

  return (
    <Draggable key={option.id} draggableId={'' + option.id} index={oIndex}>
      {(provided) => (
        <div
          className="option__wrapper"
          css={baseStyle}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <img
            src={manageIcon}
            alt="manage-icon"
            className="option__manage"
            {...provided.dragHandleProps}
          />
          <Field
            type="text"
            as={FormikAutoExpandedTextArea}
            name={`questionsList[${qIndex}].options[${oIndex}].name`}
            className={classNames('option__input', {
              error: !name,
            })}
            placeholder="Enter Option"
            value={name}
          />
          <Field
            type="text"
            name={`questionsList[${qIndex}].options[${oIndex}].weight`}
            className={classNames('weight__input', {
              error: weight === '',
            })}
            value={weight}
            placeholder="Weight"
            onChange={handleWeightChange}
          />

          <div className="delete__wrapper">
            {isDeleteModalOpen ? (
              <ConfirmationModal
                action={'Delete'}
                handleClick={() => {
                  handleDelete(oIndex)
                  setDeleteModalOpen(false)
                  setFieldTouched('any', true)
                }}
                handleClose={() => {
                  setDeleteModalOpen(false)
                }}
              />
            ) : null}
            <BinDeleteIcon
              color={variableColor}
              height="22px"
              width="17px"
              onClick={() => {
                setDeleteModalOpen(true)
              }}
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}

const baseStyle = css({
  display: 'flex',
  gap: '10px',
  alignItems: 'flex-start',
  paddingLeft: '167px',
  paddingBottom: '10px',
  '.option__manage': {
    marginTop: '10px',
  },
  '.option__input': {
    ...TextInputStyle,
    minWidth: '400px',
    background: '#FAFAFA',
    height: '32px',
    fontSize: '12px',

    '::placeholder': {
      color: '#B1B1B1',
    },
  },
  '.weight__input': {
    ...TextInputStyle,
    width: '73px',
    background: '#FAFAFA',
    height: '32px',
    fontSize: '12px',
    textAlign: 'center',

    '::placeholder': {
      color: '#B1B1B1',
    },
  },
  '.delete__wrapper': {
    position: 'relative',
    marginTop: '5px',
  },
})
