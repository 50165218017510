angular
  .module('ccs')
  .controller(
    'ToolboxGroupAssignCtrl',
    function ($scope, $state, $stateParams, Api, app, $log) {
      $log.debug('ToolboxGroupAssignCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      var groupID = $stateParams.group
      $scope.appProject = { toolbox_topics: [] }
      $scope.pageNumber = 1
      $scope.reverse = false
      $scope.order = 'name'
      $scope.breadcrumbs = [
        { title: app.name },
        {
          title: 'Toolbox Groups',
          url: 'app.toolboxes.groups({app:' + app.id + '})',
        },
      ]
      $scope.app = app

      function getToolboxSet() {
        Api.ToolboxTopicSets.byID(groupID, function (resp) {
          $scope.group = resp
          $scope.changePage()
        })
      }

      function toolboxQuery() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_for_toolbox_group: groupID,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      $scope.changePage = function () {
        Api.ToolboxTopics.get(toolboxQuery(), function (resp) {
          resp.results = resp.results.map(function (toolbox) {
            toolbox.active_for_toolbox_group =
              !!$scope.group.toolbox_topics.filter(function (c) {
                return c == toolbox.id
              }).length

            return toolbox
          })

          $scope.data = {
            items: resp.results,
            total: resp.count,
          }
        })
      }

      $scope.toggleAll = () => {
        Api.post(
          `toolbox_topic_sets/${$scope.group.id}/assign_all_toolbox_topics`,
          { id: $scope.group.id, on: $scope.active, search: $scope.search },
          (resp) => {
            getToolboxSet()
          },
        )
      }

      $scope.toggleActive = function (toolbox) {
        if (toolbox.active_for_toolbox_group) {
          $scope.group.toolbox_topics.push(toolbox.id)
        } else {
          $scope.group.toolbox_topics = $scope.group.toolbox_topics.filter(
            function (c) {
              return c != toolbox.id
            },
          )
        }

        Api.ToolboxTopicSets.patch(
          {
            id: $scope.group.id,
            toolbox_topics: $scope.group.toolbox_topics,
          },
          function (resp) {
            if (!resp) {
              toolbox.active_for_toolbox_group = !toolbox.is_active
            }
          },
        )
      }

      getToolboxSet()

      $scope.goBack = function () {
        $state.go(
          'app.toolboxes.groups',
          {
            app: app.id,
            pageNumber: $scope.previousPageNumber,
            reverse: $scope.previousReverse,
            order: $scope.previousOrder,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }
    },
  )
