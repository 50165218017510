angular
  .module('ccs')
  .controller(
    'QuestionProjectsCtrl',
    function ($scope, $stateParams, $state, Api, app, CurrentUser, $log) {
      $log.debug('QuestionProjectsCtrl')

      $scope.appProjects = []
      $scope.clientAppQuestion = null
      $scope.pageSize = 20
      $scope.app = app

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'project__name'

      $scope.projectsTotal = $scope.pageSize * $scope.pageNumber
      const questionID = $stateParams.question
      const categoryID = $stateParams.category
      const previousPageNumber = $stateParams.previousPageNumber
      const previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : ''
      $scope.categoryId = $stateParams.category
      // $scope.active = true;

      $scope.breadcrumbs = [
        { title: app.name },
        {
          title: 'Questions',
          url: 'app.questions.list({app: ' + app.id + '})',
        },
      ]
      $scope.tableHeaders = [
        {
          key: 'project__name',
          display: app.project + ' Name',
          sortable: true,
        },
        { key: 'excluded_from_question', display: 'Assign', sortable: true, toggleAllBtn: true },
      ]

      function appProjectQuery(page) {
        let q = {
          page: page || $scope.pageNumber,
          search: $scope.search,
          categories: $scope.categoryId,
          is_active: 'True',
          annotate_excluded_app_question: $scope.clientAppQuestion.id,
        }
        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      function getAppProjects() {
        Api.AppProjects.get(appProjectQuery(), (resp) => {
          $scope.projectsTotal = resp.count
          $scope.appProjects = resp.results.map(function (project) {
            var is_active =
              $scope.clientAppQuestion.exclude_projects.indexOf(
                project.project.id,
              ) === -1
            project.project.excluded_from_question = is_active
            return project
          })
        })
      }

      function getData() {
        Api.ClientAppQuestions.get(
          {
            application: app.id,
            client: CurrentUser.getClientId(),
            question: questionID,
          },
          function (resp) {
            if (resp.results.length) {
              $scope.clientAppQuestion = resp.results[0]
              getAppProjects()
            } else {
              Api.ClientAppQuestions.post(
                {
                  question: questionID,
                  application: app.id,
                  client: CurrentUser.getClientId(),
                },
                function (resp) {
                  $scope.clientAppQuestion = { ...resp, exclude_projects: [] }
                  getAppProjects()
                },
              )
            }
          },
        )
      }

      getData()

      $scope.toggleActive = function (project) {
        if (project.excluded_from_question) {
          $scope.clientAppQuestion.exclude_projects =
            $scope.clientAppQuestion.exclude_projects.filter((projectId) => {
              return projectId !== project.id
            })
        } else {
          $scope.clientAppQuestion.exclude_projects.push(project.id)
        }

        Api.ClientAppQuestions.patch(
          {
            id: $scope.clientAppQuestion.id,
            exclude_projects: $scope.clientAppQuestion.exclude_projects,
          },
          function (resp) {},
        )
      }

      function getAllAppProjects(cb, projects, page) {
        projects = projects || []
        page = page || 1
        Api.AppProjects.get(
          {
            app: app.id,
            page: page,
            categories: $scope.categoryId,
          },
          (resp) => {
            projects = projects.concat(resp.results)
            if (resp.next) {
              return getAllAppProjects(cb, projects, ++page)
            } else {
              return cb(projects)
            }
          },
        )
      }

      $scope.toggleAll = () => {
        getAllAppProjects(function (projects) {
          if (!$scope.active) {
            //make sure all project ids for this categories are excluded by pushing them
            //to the already excluded projects if they are not there already
            var projects_to_exclude = projects.map((p) => p.project.id)
            for (var i = 0; i < projects.length; i++) {
              if (
                $scope.clientAppQuestion.exclude_projects.indexOf(
                  projects[i].project.id,
                ) == -1
              ) {
                $scope.clientAppQuestion.exclude_projects.push(
                  projects[i].project.id,
                )
              }
            }
            Api.ClientAppQuestions.patch(
              {
                id: $scope.clientAppQuestion.id,
                exclude_projects: $scope.clientAppQuestion.exclude_projects,
                search: $scope.search,
              },
              () => {
                getData()
              },
            )
          } else {
            //remove existing project IDs from the excluded list if they are there
            for (var i = 0; i < projects.length; i++) {
              var location = $scope.clientAppQuestion.exclude_projects.indexOf(
                projects[i].project.id,
              )
              if (location !== -1) {
                $scope.clientAppQuestion.exclude_projects.splice(location, 1)
              }
            }
            Api.ClientAppQuestions.patch(
              {
                id: $scope.clientAppQuestion.id,
                exclude_projects: $scope.clientAppQuestion.exclude_projects,
                search: $scope.search,
              },
              () => {
                getData()
              },
            )
          }
        })
      }

      $scope.backToSearch = function () {
        $state.go(
          'app.questions.list',
          {
            categoryId: $scope.categoryId,
            appID: app.id,
            pageNumber: previousPageNumber,
            search: previousSearch,
          },
          { reload: false },
        )
      }

      $scope.changePage = function () {
        getAppProjects()
        //update the URL in the navigation bar
        $state.transitionTo(
          'app.questions.projects',
          {
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            question: questionID,
            category: categoryID,
            app: app.id,
          },
          { notify: false },
        )
      }
    },
  )
