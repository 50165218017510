/** @ngInject */
export function shareConfig($stateProvider) {
  $stateProvider
    .state('share', {
      abstract: true,
      templateUrl: 'app/views/share/base.html',
      data: { specialClass: 'body-share' },
    })
    .state('share.lesson', {
      url: '/share/lesson/:key',
      templateUrl: 'app/views/share/lesson.html',
      controller: 'ShareLessonCtrl',
    })
    .state('share.file', {
      url: '/share/cabinetfile/:key',
      templateUrl: 'app/views/share/cabinetfile.html',
      controller: 'ShareCabinetFileCtrl',
    })
    .state('share.news', {
      url: '/share/news/:key',
      template: '<share-company-news-component />',
      controller: 'ShareNewsCtrl',
    })
    .state('share.reports', {
      url: '/share/report/:key',
      templateUrl: 'app/views/share/report.html',
      controller: 'ShareReportCtrl',
    })
}
