angular.module('ccs').service('CurrentCompanyLogo', CurrentCompanyLogo)

function CurrentCompanyLogo($log) {
  let currentLogoInstance = null

  function reset() {
    currentLogoInstance = ''
  }

  function getLogo() {
    if (currentLogoInstance) {
      return currentLogoInstance
    } else {
      $log.error('Error! No company logo found!')
      return ''
    }
  }

  function setLogo(newLogo) {
    if (newLogo) {
      currentLogoInstance = newLogo
    } else {
      $log.error('Error! Cannot set company logo!')
    }
  }

  return {
    reset,
    getLogo,
    setLogo,
  }
}
