import * as React from 'react'
import { css } from '@emotion/react'
import { SingleTableHeader } from '@components/SingleTableHeader'

export function ArchiveTableHeaders() {
  return (
    <thead css={baseStyle}>
      <tr>
        <SingleTableHeader
          className={'nameColumn startingColumn'}
          name={'SDS'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'name'}
        />
        <SingleTableHeader
          className={'manufacturer'}
          name={'Manufacturer'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'manufacturer'}
        />
        <SingleTableHeader
          className={'issue'}
          name={'Issue'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'issue_date'}
        />
        <SingleTableHeader
          className={'review_date'}
          name={'Review'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'review_date'}
        />
        <SingleTableHeader
          className={'hazards'}
          name={'311/312 Hazard'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'hazards'}
        />
        <SingleTableHeader
          className={'toxic'}
          name={'313 Toxic Chemical'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'toxic_chemical'}
        />
        <SingleTableHeader
          className={'physicalHazard'}
          name={'WHMIS Physical Hazard'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'whmis_physical_hazards'}
        />
        <SingleTableHeader
          className={'healthlHazard'}
          name={'WHMIS Health Hazard'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'whmis_health_hazards'}
        />
        <SingleTableHeader
          className={'health'}
          name={'Health'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'health'}
        />
        <SingleTableHeader
          className={'flammability'}
          name={'Flammability'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'flammability'}
        />
        <SingleTableHeader
          className={'PH'}
          name={'Physical hazards'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'physical_hazards'}
        />
        <SingleTableHeader
          className={'PPE'}
          name={'PPE'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'ppe'}
        />
        <SingleTableHeader
          className={'editDate'}
          name={'Archived'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'date_updated'}
        />
        <SingleTableHeader
          className={'note'}
          name={'Note'}
          listLocation={'app.safety_data_sheets_archive'}
          type={'archive_notes'}
        />
        <th className={'download'}>Download</th>
        <th className={'archive'}>Restore</th>
        <th css={popUpBase} />
      </tr>
    </thead>
  )
}

const popUpBase = css({
  width: 0,
})

const baseStyle = css({
  '.manufacturer': {
    textAlign: 'left',
  },
  '.nameColumn': {
    textAlign: 'left',
    paddingLeft: '10px',
  },
})
