angular
  .module('ccs')
  .directive('primaryBtn', primaryBtn)
  .directive('secondaryBtn', secondaryBtn)

const defaultStyle = {
  border: 'none',
  minWidth: '75px',
  width: 'fit-content',
  height: '28px',
  padding: '0 25px',
  fontSize: '12px',
  boxSizing: 'border-box',
  fontWeight: 400,
  fontFamily: "'Open Sans', Arial, sans-serif",
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}

function primaryBtn() {
  const primaryStyle = {
    backgroundColor: '#3980ce',
    color: '#fff',
  }
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      elem.css({ ...defaultStyle, ...primaryStyle })
      elem.on('mouseleave', () => {
        elem.css({ backgroundColor: '#3980ce' })
      })
      elem.on('mouseenter', () => {
        elem.css({ backgroundColor: '#3373b9' })
      })
    },
  }
}

function secondaryBtn() {
  const secondaryStyle = {
    backgroundColor: '#fff',
    color: '#3980ce',
    border: '1px solid #3980ce',
  }
  return {
    restrict: 'A',
    link: function (scope, elem, attrs) {
      elem.css({ ...defaultStyle, ...secondaryStyle })
      elem.on('mouseenter', () => {
        elem.css({ backgroundColor: '#3980ce', color: '#fff' })
      })
      elem.on('mouseleave', () => {
        elem.css({
          backgroundColor: '#fff',
          color: '#3980ce',
        })
      })
    },
  }
}
