import * as React from 'react'
import { useRouter } from '@/react/hooks'

import { QuestionsList } from './templateParts'
import {
  BottomObservationNavigation,
  Signatures,
  TableSummary,
  TableTemplateHeader,
} from '../components'

export function TemplateBaseThree({
  baseStyle,
  baseObservationData,
  processedObservationData,
  app,
  viewNaQuestions,
}: any) {
  const { stateService } = useRouter()

  const lists = {
    no: Object.values(processedObservationData.no),
    pr: Object.values(processedObservationData.pr),
    pa: Object.values(processedObservationData.pa),
    cls: Object.values(processedObservationData.cls),
    yes: Object.values(processedObservationData.yes),
    'n/a': Object.values(processedObservationData['n/a']),
  }

  return (
    <section css={baseStyle}>
      <div id="to__print">
        {baseObservationData ? (
          <TableTemplateHeader headerData={baseObservationData} app={app} />
        ) : null}
        {baseObservationData && processedObservationData ? (
          <TableSummary
            headerData={baseObservationData}
            lists={lists}
            app={app}
            viewNaQuestions={viewNaQuestions}
          />
        ) : null}
        {processedObservationData ? (
          <QuestionsList
            lists={lists}
            app={app}
            viewNaQuestions={viewNaQuestions}
          />
        ) : null}
        {baseObservationData.signatures.length > 0 ? (
          <Signatures signatures={baseObservationData.signatures} />
        ) : null}
        <div className="disclaimer">
          {baseObservationData.client.general_settings.disclaimer}
        </div>
      </div>
      {stateService.params.key ? null : (
        <BottomObservationNavigation
          deleted={baseObservationData.deleted}
          observationData={baseObservationData}
        />
      )}
    </section>
  )
}
