import { BinDeleteIcon, EditIcon } from '@/react/componentAssets'
import { RightArrowIcon } from '@/react/componentAssets/RightArrowIcon'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import classNames from 'classnames'
import React, { useMemo, useState } from 'react'
import { VideoIcon } from '@/react/componentAssets/VideoIcon'
import { PdfTrainingsIcon } from '@/react/componentAssets/PdfTrainingsIcon'
import { PptIcon } from '@/react/componentAssets/PptIcon'
import arrowDown from '@/assets/icons/arrow-down.svg'
import { DefaultTrainingIcon } from '@/react/componentAssets/DefaultTrainingIcon'
import { DownArrowIcon } from '@/react/componentAssets/DownArrowIcon'
import { AddEditContentModal } from './AddEditContentModal'
import { QuizIcon } from '@/react/componentAssets/QuizIcon'
import { DeleteModal } from './DeleteModal'
import { css } from '@emotion/react'
import { AddEditTopicModal } from './AddEditTopicModal'
import { HandsOnModalIcon } from '@/react/componentAssets/HandsOnModalIcon'
import { useRouter } from '@/react/hooks'

export const SingeTopic = ({
  topic,
  setRefresh,
  trainingId,
  maxTopicOrder,
}) => {
  const { CurrentUser } = useAngularServices()
  const [isTopicHovered, setTopicHovered] = useState(false)
  const [isAddEditContentModalVisible, setAddEditContentModalVisible] =
    useState(false)

  const [contentToDelete, setContentToDelete] = useState(0)

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isTopicOpen, setTopicOpen] = useState(false)
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [topicEditData, setTopicEditData] = useState(null)
  const [contentEditData, setContentEditData] = useState(null)

  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const { name, content_items, order } = topic

  const handleShowModal = () => {
    setAddEditContentModalVisible(!isAddEditContentModalVisible)
  }

  const handleDelete = async () => {
    try {
      await Api.delete(`training_topic/${topic?.id}`)
      setDeleteModalVisible(false)
      setRefresh(true)
    } catch {
      console.log('Failed to delete Module Topic')
    }
  }

  const handleDeleteContent = async () => {
    try {
      await Api.delete(`training_content/${contentToDelete}`)
      setContentToDelete(0)
      setRefresh(true)
    } catch {
      console.log('Failed to delete Module Topic')
    }
  }

  const getFileNameForEdit = (url: string) => {
    const parts = url.split('/')
    const fileName = parts[parts.length - 1]

    return fileName
  }

  const maxContentOrder = useMemo(() => {
    if (content_items.length) {
      return content_items[content_items.length - 1].order + 1
    } else {
      return 1
    }
  }, [content_items])

  const isQuizInTheTopic = useMemo(() => {
    return content_items.some((item) => item.content_type === 'quiz')
  }, [content_items])

  return (
    <div
      className={classNames('topic', {
        dark: false,
        hovered: isTopicHovered && !isTopicOpen,
      })}
      onMouseOver={() => {
        setTopicHovered(true)
      }}
      onMouseOut={() => {
        setTopicHovered(false)
      }}
      onClick={() => {
        setTopicOpen(!isTopicOpen)
      }}
    >
      <div className="topic__info">
        <p className="training__title">{`${order}. ${name}`}</p>
        <div className="training__buttons">
          <EditIcon
            color={variableColor}
            onClick={(e) => {
              e.stopPropagation()
              setTopicEditData({
                id: topic?.id,
                name: topic?.name,
                order: topic?.order,
              })
            }}
          />
          <BinDeleteIcon
            color={variableColor}
            onClick={(e) => {
              e.stopPropagation()
              setDeleteModalVisible(true)
            }}
          />
          {isTopicOpen ? (
            <DownArrowIcon
              color={'#8B8B8B'}
              onClick={() => {
                setTopicOpen(!isTopicOpen)
              }}
            />
          ) : (
            <RightArrowIcon
              color={'#8B8B8B'}
              onClick={() => {
                setTopicOpen(!isTopicOpen)
              }}
            />
          )}
        </div>
        {isDeleteModalVisible && (
          <DeleteModal
            handleClose={() => {
              setDeleteModalVisible(false)
            }}
            module={name}
            handleClick={handleDelete}
          />
        )}
        {topicEditData && (
          <AddEditTopicModal
            startingData={topicEditData}
            handleClose={() => {
              setTopicEditData(null)
              setRefresh(true)
            }}
            maxTopicOrder={maxTopicOrder - 1}
          />
        )}
      </div>
      {isTopicOpen && (
        <div
          className="topic__content"
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          {content_items?.map((item, index) => (
            <div className="single__item" key={index}>
              <div className="item__info">
                {(() => {
                  switch (item.content_type) {
                    case 'video':
                      return <VideoIcon color={variableColor} />
                    case 'pdf':
                      return <PdfTrainingsIcon color={variableColor} />
                    case 'ppt':
                      return <PptIcon color={variableColor} />
                    case 'quiz':
                      return <QuizIcon color={variableColor} />
                    case 'ho':
                      return <HandsOnModalIcon color={variableColor} />
                    default:
                      return <DefaultTrainingIcon color={variableColor} />
                  }
                })()}
                <p
                  className="item__title"
                  style={{ textOverflow: 'ellipsis' }}
                  onClick={(e) => {
                    e.stopPropagation()
                    if (item?.content_type === 'quiz') {
                      stateService.go('trainings.quiz.edit', {
                        topicId: topic?.id,
                        contentId: item?.id,
                        trainingID: trainingId,
                        quizName: item.name,
                        topicOrder: item.order,
                      })
                    } else {
                      handleShowModal()
                      setContentEditData({
                        content_id: item.id,
                        content_name: item.name,
                        content_type: item.content_type,
                        order: item.order,
                        description: item?.description,
                        completion_code: item?.completion_code,
                        file: item?.attachment_info
                          ? {
                              ...item?.attachment_info,
                              name: getFileNameForEdit(
                                item?.attachment_info?.file,
                              ),
                            }
                          : null,
                      })
                    }
                  }}
                >{`${item.order}. ${item.name}`}</p>
              </div>
              <BinDeleteIcon
                color={variableColor}
                onClick={(e) => {
                  e.stopPropagation()
                  setContentToDelete(item.id)
                }}
              />
              {contentToDelete === item.id ? (
                <DeleteModal
                  handleClose={() => {
                    setContentToDelete(0)
                  }}
                  module={item.name}
                  handleClick={handleDeleteContent}
                />
              ) : null}
            </div>
          ))}
          <p
            className="add__button"
            onClick={(e) => {
              e.stopPropagation()
              setContentEditData(null)
              handleShowModal()
            }}
          >
            + Content
          </p>
        </div>
      )}
      {isAddEditContentModalVisible && (
        <AddEditContentModal
          handleShowModal={handleShowModal}
          topicId={topic?.id}
          setRefresh={setRefresh}
          trainingId={trainingId}
          contentEditData={contentEditData}
          maxContentOrder={
            contentEditData ? maxContentOrder - 1 : maxContentOrder
          }
          isQuizInTheTopic={isQuizInTheTopic}
        />
      )}
    </div>
  )
}
