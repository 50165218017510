import * as React from 'react'
import { css } from '@emotion/react'
import { debounce } from 'lodash'

type Props = {
  onChange: (newValue: string) => void
  waitTime?: number
}

export function SearchForProjects({ onChange, waitTime = 500 }: Props) {
  const [search, setSearch] = React.useState('')

  const debouncedOnChange = React.useMemo(
    () => debounce(onChange, waitTime),
    [onChange, waitTime],
  )

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(evt.target.value)
    debouncedOnChange(evt.target.value)
  }

  const onClearClick = () => {
    setSearch('')
    onChange('')
  }

  return (
    <div css={baseStyle}>
      <input
        id={'search'}
        name={'search'}
        className="general-input-style"
        placeholder={'Search'}
        type="text"
        value={search}
        onChange={handleChange}
      />
      {search && (
        <div className="clear" onClick={onClearClick}>
          <span className="fa fa-remove" />
        </div>
      )}
    </div>
  )
}

const baseStyle = css({
  display: 'flex',
  ' input': {
    color: 'rgb(103, 106, 108)',
    backgroundColor: 'rgb(255, 255, 255)',
    borderColor: 'rgb(229, 230, 231)',
    borderRadius: 0,
    borderStyle: 'solid',
    paddingBottom: 6,
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 6,
    height: 30,
    width: 309,
  },
  '.clear': {
    display: 'flex',
    cursor: 'pointer',
    height: 30,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ED5565',
    width: 30,
    marginLeft: 5,
    span: {
      width: 12,
      color: 'white',
    },
  },
})
