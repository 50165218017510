import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import React, { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'
import { CategoriesNavBar } from '../components/CategoriesNavBar'
import { CategoryRow } from './components/CategoryRow'

export const AssignCategoriesToGroup = () => {
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const [order, setOrder] = useState('name')
  const [group, setGroup] = useState(null)
  const [categoriesList, setCategoriesList] = useState([])
  const [assignedList, setAssignedList] = useState([])
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(20)
  const [refresh, setRefresh] = useState(false)

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'categories',
      name: 'Categories',
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '14px',
        width: '90%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          display: 'flex',
          gap: '4px',
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'table__header',
      name: 'Assign',
      type: 'active_for_category_group',
      additionalStyles: {
        width: '46px',
        minWidth: '46px',
        paddingLeft: 0,
        textAlign: 'center',
        borderBottom: '2px solid #C6C6C6',
        paddingInline: 0,
        '.header-base': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        },
      },
    },
  ]

  useEffect(() => {
    const getCategoriesList = async () => {
      const { data } = await Api.get('categories', {
        annotate_active_category_group: stateService.params.group,
        application: stateService.params.app,
        deleted: false,
        is_active: true,
        order,
        page,
        search,
      })

      const { data: group } = await Api.get(
        `category_sets/${stateService.params.group}`,
        {},
      )

      setCategoriesList(data?.results)
      setAssignedList(group?.categories)
      setCount(data.count)
      setRefresh(false)
      setGroup(group)
    }
    getCategoriesList()
  }, [search, page, order, refresh])

  const baseStyle = getBaseStyle(variableColor)

  const handleButtonClick = async () => {
    stateService.go('app.categories.groups')
  }

  return (
    <div css={baseStyle}>
      <CommonPageHeader headerText={'Categories'} />
      <CategoriesNavBar />
      <section className="category__assign__wrapper">
        <div className="top__wrapper">
          <h4 className="employee__name">Group: {group?.name}</h4>
          <ButtonElement
            buttonType="submit"
            text="Done"
            functionToTrigger={handleButtonClick}
            additionalStyles={css({ padding: 0 })}
          />
        </div>
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
        />
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          {categoriesList?.map((category, index) => (
            <CategoryRow
              index={index}
              search={search}
              category={category}
              assignedList={assignedList}
              setRefresh={setRefresh}
            />
          ))}
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

function getBaseStyle(variableColor) {
  return css({
    paddingBottom: '200px',
    '.employee__name': {
      margin: 0,
    },
    '.category__assign__wrapper': {
      background: '#fff',
      paddingLeft: '20px',
      width: '834px',
      marginLeft: '5px',
    },
    '.select__wrapper': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.top__wrapper': {
      display: 'flex',
      gap: '30px',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '11px',
      paddingRight: '20px',
      paddingBottom: '14px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
      height: '20px',
      width: '20px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.category__name': {
      fontSize: '13px',
      margin: 0,
    },
    table: {
      width: '791px',
    },
    td: {
      height: '40px',
    },
    '.category__row': {
      position: 'relative',
      height: '40px',
    },
    '.name__td': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.group__row': {
      position: 'relative',
      height: '40px',
      background: hexToRGBAWithOpacity(variableColor, 0.25),
    },
    '.light': {
      background: hexToRGBAWithOpacity(variableColor, 0.25),
    },
    '.checkmark__td': {
      textAlign: 'center',
      minWidth: '80px',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
      marginBottom: '20px',
    },
    '.name__cell': {
      paddingLeft: '16px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBAWithOpacity(variableColor, 0.25),
    },
    '.info__wrapper': {
      position: 'absolute',
      right: '15px',
      top: '-3px',
    },
    '.info__icon': {
      cursor: 'pointer',
    },
    '.sort__icon': {
      cursor: 'pointer',
      height: '17px',
    },
    '.full__name': {
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      top: '40px',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '550px',
    },
    '#search': {
      width: '264px',
      marginBottom: '10px',
    },
    '@media(max-width: 1050px)': {
      '#search': {
        width: '264px',
      },
      '.category__name': {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: '243px',
        overflow: 'hidden',
      },
      '.top__wrapper': {
        paddingRight: 0,
        gap: '8px',
      },
      '.category__assign__wrapper': {
        width: '100%',
        paddingRight: '23px',
      },
      table: {
        width: '100%',
      },
      '.name__td': {
        maxWidth: '204px',
      },
    },
  })
}
