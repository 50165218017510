import React, { useState } from 'react'
import { Field, FormikContextType, useFormikContext } from 'formik'
import ReactSelect from 'react-select'
import { TextInputStyle } from '@screens/components'
import { css } from '@emotion/react'
import classNames from 'classnames'
import { useAngularServices } from '@/react/components'
import InfoIcon from '@/assets/icons/info-icon.svg'
import { NoteModal } from './NoteModal'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import {
  AssessmentQuestion,
  SingleAssessmentOption,
} from '@screens/AssessmentsConfig/CreateAssessment/components/types'
import { ExternalAssessmentOption, Memory } from './types'
import { checkIfHeaderSibling } from '@screens/AssessmentsConfig/ExternalAssessment/components/utils'

type Props = {
  question: AssessmentQuestion
  initialValues: AssessmentQuestion[]
  index: string
  isNa?: boolean
  isNested?: boolean
  isNestedUnderSubheader?: boolean
}

export const SingleQuestion = ({
  question,
  initialValues,
  index,
  isNested,
  isNestedUnderSubheader = false,
}: Props) => {
  const { values, setFieldValue }: FormikContextType<Memory> =
    useFormikContext()
  const { CurrentUser } = useAngularServices()
  const variableColor =
    CurrentUser.getClientSettings().web_primary_color || '#3980CE'
  const [isNoteModalOpen, setNoteModalOpen] = useState(false)

  const customSelectStyles = {
    container: (provided, state) => ({
      ...provided,
      '@media(max-width: 1150px)': {
        width: '100%',
      },
    }),
    control: (provided, state) => ({
      ...provided,
      width: '210px',
      minHeight: '32px',
      height: '32px',
      border:
        !values?.answers[question.id]?.option &&
        question?.required &&
        !values?.answers[question.id].notAnswered
          ? '1px solid #C80404'
          : '1px solid #e5e6e7',
      background: values?.answers[question.id].notAnswered ? '#E5E6E7' : '#fff',
      borderRadius: '0',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        border: '1px solid #e5e6e7',
      },

      '@media(max-width: 1150px)': {
        width: '100%',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      marginBottom: '5px',
      fontSize: '12px',
      color: '#B1B1B1',
    }),
    singleValue: (provided) => ({
      ...provided,
      marginBottom: '5px',
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      padding: '0px',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      borderBottom: '1px solid #E5E6E7',
      background: state?.isFocused
        ? variableColor
        : state.options.indexOf(state.data) % 2 === 1
        ? '#FAFAFA'
        : 'none',
      color: state?.isFocused ? '#fff' : '#000',
    }),
  }

  const possibleOptions: SingleAssessmentOption[] =
    question.type === 'options' || question.type === 'nested_option'
      ? question.type === 'options'
        ? question.options
        : question.nested_options
      : []

  const options: ExternalAssessmentOption =
    question.type === 'options' || question.type === 'nested_option'
      ? possibleOptions.map((option) => {
          return { value: option.id, label: option.name, question: option }
        })
      : []

  const { isOpen, isUnderSubheader, isUnderHeader } = checkIfHeaderSibling({
    question,
    initialValues,
    index,
  })

  if (isNested ? false : !isOpen) return null

  return [
    <div
      css={baseStyle(
        isUnderSubheader || isNestedUnderSubheader,
        isNested,
        isUnderHeader,
      )}
      id={question.id}
    >
      {(question.type === 'options' || question.type === 'nested_option') && (
        <div className="question__wrapper">
          <div className="question_text">
            <p className="question__text">
              {question?.question_text}
              {question?.name}
            </p>
            {question?.note && (
              <div className="note__section">
                <img
                  src={InfoIcon}
                  className="info__icon"
                  alt="info"
                  onClick={() => {
                    setNoteModalOpen(true)
                  }}
                />
                {isNoteModalOpen && (
                  <NoteModal
                    note={question?.note}
                    handleClose={() => {
                      setNoteModalOpen(false)
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <div className="dropdown__wrapper">
            <ReactSelect
              options={
                question?.required
                  ? options
                  : [{ value: '', label: 'N/A' }, ...options]
              }
              defaultValue={values.answers[question.id].option}
              value={values.answers[question.id].option}
              placeholder={!question?.required ? 'N/A' : 'Required'}
              name={`answers[${question.id}].option`}
              onChange={async (selectOption) => {
                await setFieldValue(
                  `answers[${question.id}].option`,
                  selectOption,
                )
              }}
              styles={customSelectStyles}
              isDisabled={values?.answers[question.id].notAnswered}
            />
          </div>
        </div>
      )}
      {question.type === 'text' && (
        <div className="question__wrapper text">
          <div className="question__heading">
            <p className="question__text">
              {question?.question_text}
              {question?.name}
            </p>
            {question?.note && (
              <div className="note__section">
                <img
                  src={InfoIcon}
                  className="info__icon"
                  alt="info"
                  onClick={() => {
                    setNoteModalOpen(true)
                  }}
                />
                {isNoteModalOpen && (
                  <NoteModal
                    note={question?.note}
                    handleClose={() => {
                      setNoteModalOpen(false)
                    }}
                  />
                )}
              </div>
            )}
          </div>
          <Field
            className={classNames('question__input', {
              disabled: values?.answers[question.id].notAnswered,
              error:
                question?.required &&
                !values?.answers[question.id]?.text &&
                !values?.answers[question.id].notAnswered,
            })}
            type="text"
            as={FormikAutoExpandedTextArea}
            placeholder={
              !question?.required || values?.answers[question.id].notAnswered
                ? ''
                : 'Required'
            }
            name={`answers[${question.id}].text`}
            disabled={values?.answers[question.id].notAnswered}
          />
        </div>
      )}
    </div>,
    values.answers[question.id].option?.question?.nested_fields
      ? values.answers[question.id].option.question.nested_fields.map(
          (nestedField, idx) => (
            <SingleQuestion
              question={nestedField}
              initialValues={initialValues}
              isNested={true}
              isNestedUnderSubheader={isUnderSubheader}
              key={idx}
            />
          ),
        )
      : null,
  ]
}

const baseStyle = (
  underSubHeader: boolean,
  isNested: boolean,
  isUnderHeader: boolean,
) => {
  return css({
    paddingRight: underSubHeader ? 30 : 0,
    paddingLeft:
      (isNested ? 20 : 0) +
      (underSubHeader ? 40 : 0) +
      (isUnderHeader ? 10 : 0),
    '.question__wrapper': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '10px',
      '.question_text': {
        width: '90%',
        display: 'flex',
        justifyContent: 'space-between',
      },
    },
    '.text': {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    '.question__text': {
      margin: 0,
      fontWeight: 600,
      fontSize: '12px',
    },
    '.question__input': {
      ...TextInputStyle,
      fontSize: '13px',
      color: '#000',
      fontWeight: 400,
      height: '32px',
      width: '100%',
      marginRight: 0,
      '&::placeholder': {
        color: '#B1B1B1',
      },
    },
    '.disabled': {
      background: '#E5E6E7',
    },
    '.info__icon': {
      height: '14px',
      width: '14px',
      cursor: 'pointer',
    },
    '.dropdown__wrapper': {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '10px',
    },
    '.note__section': {
      position: 'relative',
    },
    '.question__heading': {
      display: 'flex',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
    },
    '@media(max-width: 1150px)': {
      paddingLeft: 0,
      paddingRight: 0,
      '.question__wrapper': {
        display: 'flex',
        flexDirection: 'column',
        gap: 0,
        '.question_text': {
          display: 'flex',
          width: '100%',
          marginBottom: 5,
          justifyContent: 'space-between',
        },
      },
      '.dropdown__wrapper': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '100%',
      },
    },
  })
}
