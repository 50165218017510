import { ReactWrapper } from '@components'
import { CompanyNewsCreateEdit } from './CompanyNewsCreateEdit'
import { ShareCompanyNews } from './ShareCompanyNews'

export const CompanyNewsCreateEditComponent = ReactWrapper(
  CompanyNewsCreateEdit,
  'companyNewsCreateEditComponent',
)

export const ShareCompanyNewsComponent = ReactWrapper(
  ShareCompanyNews,
  'shareCompanyNewsComponent',
)
