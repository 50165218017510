type Option = {
  label: string
  value: string
}

export const getSelectOption = (
  value: string,
  options: Option[],
  byLabel = false,
): Option | null =>
  options.findLast((option) =>
    byLabel ? value === option.label : value === option.value,
  ) || null
