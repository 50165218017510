angular
  .module('ccs')
  .controller(
    'SettingsDisclaimerCtrl',
    function ($scope, Api, CurrentUser, Notification, $log) {
      $log.debug('SettingsDisclaimerCtrl')

      $scope.maxLength = 4000

      $scope.computeCharactersLeft = function () {
        // $scope.settings.disclaimer won't exist if ng-maxlength is exceeded.
        if ($scope.settings.disclaimer) {
          $scope.charactersLeft =
            $scope.maxLength - $scope.settings.disclaimer.length
        } else {
          $scope.charactersLeft = 0
        }
      }

      Api.GeneralSettings.get({}, function (resp) {
        if (resp.results.length) {
          $scope.settings = resp.results[0]
        } else {
          Api.GeneralSettings.post(
            {
              client: CurrentUser.getClientId(),
            },
            function (resp) {
              $scope.settings = resp
            },
          )
        }
      })

      $scope.update = function () {
        Api.GeneralSettings.patch($scope.settings, (resp) => {
          if (resp) {
            Notification.success('Settings updated')
          }
        })
      }
    },
  )
