import moment from 'moment'
import {
  saveBase64AsFile,
  trimFileName,
  _extends,
  getTranslatedString,
} from '@/utils'

import pluralize from 'pluralize'
import { times } from 'lodash'

angular
  .module('ccs')
  .controller(
    'ShareReportCtrl',
    function (
      $scope,
      Api,
      $stateParams,
      $state,
      $uibModal,
      filterService,
      Notification,
      CurrentUser,
      VariableThemeColor,
      $log,
    ) {
      $log.debug('ShareReportCtrl')

      const clientObjectKey = $stateParams.key
      $scope.filterService = filterService

      $scope.selectedItems = []
      $scope.displayTime = (dateString) => {
        return moment(dateString).format('hh:mm A')
      }

      $scope.trimFileName = trimFileName
      $scope.getTranslatedString = getTranslatedString
      $scope.displayPage = true

      $scope.displayDate = (dateString) => {
        const date = new Date(dateString)
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      }

      $scope.downloadFileInReport = function (idParam) {
        Api.getFileDataForDownload(
          idParam,
          (response) => {
            saveBase64AsFile(
              response.content,
              response.filename,
              response.content_type,
            )
          },
          (error) => {
            Notification.danger(error)
          },
        )
      }
      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo
        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      var compareFieldBySetId = function (qOne, qTwo) {
        if (qOne.answer.set_id < qTwo.answer.set_id) {
          return -1
        }
        if (qOne.answer.set_id > qTwo.answer.set_id) {
          return 1
        }
        return 0
      }

      function isReportSynchronised(client_object_key) {
        Api.getWithoutDeletedParam(
          'shares/report/' + client_object_key + '/synchronized',
          {},
          function (resp) {
            $scope.synchronised = resp.synchronised
            $scope.synchronised && ($scope.error = null)
          },
        )
      }

      function waitForReportSync(client_object_key) {
        var interval = setInterval(function () {
          isReportSynchronised(client_object_key)
          if ($scope.synchronised) {
            getSharesReportDataById()
            clearInterval(interval)
          }
        }, 1000)
      }

      $scope.orderByMultiAnswerOption = function (qOne, qTwo) {
        if (qOne.order < qTwo.order) {
          return -1
        }
        if (qOne.order > qTwo.order) {
          return 1
        }
        return 0
      }

      function getSharesReportDataById() {
        Api.getWithoutDeletedParam(
          'external_reports/' + clientObjectKey,
          {
            no_fields: true,
          },
          (initialResp) => {
            if (initialResp.message) {
              $scope.error = initialResp.message
              return
            }
            const resp = initialResp
            if (resp.deleted) {
              $scope.displayPage = false
              CurrentUser.getClientSettings(
                resp.project.client.general_settings,
              )
              VariableThemeColor.mainColorSet(
                resp.project.client.general_settings.colors.secondary_color,
                true,
              )
              $scope.error =
                'This report has been deleted by an Administrator. Please reach out to your Administrator if you have any questions.'
              return
            }

            let fieldsGet = []

            Api.get(
              'external_report_fields',
              {
                report: resp.client_object_key,
                page: 1,
              },
              async (fieldsPage) => {
                fieldsGet = fieldsPage.results
                const amountOfPages = Math.ceil(
                  fieldsPage.count / fieldsPage.results.length - 1,
                )
                let otherPages = []

                if (amountOfPages > 0) {
                  const promises = times(amountOfPages, (i) => {
                    return Api.get('external_report_fields', {
                      report: resp.client_object_key,
                      page: i + 2,
                    })
                  })
                  otherPages = (await Promise.all(promises))
                    .map(({ data }) => data.results)
                    .flat()
                }
                resp.fields = [...fieldsGet, ...otherPages]
                await translateLogic(resp)
                pageProcessing()
                $scope.$apply()
              },
            )

            async function translateLogic(resp) {
              const listToTranslate = [
                'Please answer all required fields before saving.',
                'First name',
                'Last name',
                'Email',
                'Company name',
                'Required Information:',
                'Save',
                'Save as Draft',
                'DATE CREATED:',
                'Report Title',
                ' NAME:',
                'COMPANY NAME:',
                'ADDRESS:',
                ' NUMBER:',
                'SUBMITTED BY: ',
                'SUBMITTED BY:',
                'DATE CREATED:',
              ]

              listToTranslate.push(
                resp.form.name,
                resp.project.name,
                resp.project.address,
                resp.project.number,
                resp.application.projects_display,
                pluralize.singular(resp.application.projects_display),
                resp.user.disclaimer,
              )

              resp.form.fields.forEach((value) => {
                listToTranslate.push(value.name)
                value.answer_options.forEach((option) => {
                  listToTranslate.push(option.name)
                  option.nested_fields.forEach((value) => {
                    listToTranslate.push(value.name)
                    value.answer_options.forEach((option) => {
                      listToTranslate.push(option.name)
                    })
                    if (value.group_info) {
                      if (!listToTranslate.includes(value.group_info.name)) {
                        listToTranslate.push(value.group_info.name)
                      }
                    }
                  })
                })
                if (value.group_info) {
                  if (!listToTranslate.includes(value.group_info.name)) {
                    listToTranslate.push(value.group_info.name)
                  }
                }
              })
              resp.fields.forEach((value) => {
                value.sketches.forEach((sketch) => {
                  if (sketch.printed_name) {
                    listToTranslate.push(sketch.printed_name)
                  }
                })
                value.signatures.forEach((signature) => {
                  if (signature.printed_name) {
                    listToTranslate.push(signature.printed_name)
                  }
                })
                listToTranslate.push(value.value)
                listToTranslate.push(value.text)
              })

              const languageData = JSON.parse(
                localStorage.getItem('language-data'),
              )
              const untranslatedCash = JSON.parse(
                localStorage.getItem('untranslated-cash'),
              )

              const filteredListToTranslate = []

              listToTranslate.forEach((string) => {
                if (
                  !untranslatedCash.includes(string) &&
                  string &&
                  string !== ''
                ) {
                  untranslatedCash.push(string)
                }
              })

              localStorage.setItem(
                'untranslated-cash',
                JSON.stringify(untranslatedCash),
              )

              const currentlyTranslatable = localStorage.getItem(
                'currentlyTranslatable',
              )

              if (
                languageData.selected !== 'origin' &&
                currentlyTranslatable !== 'false'
              ) {
                listToTranslate.forEach((stringToTranslate) => {
                  if (!languageData[languageData.selected]) {
                    languageData[languageData.selected] = {}
                  }
                  if (!languageData[languageData.selected][stringToTranslate]) {
                    if (stringToTranslate !== '' && stringToTranslate)
                      filteredListToTranslate.push(stringToTranslate)
                  }
                })
                const { data: translatedData } =
                  filteredListToTranslate.length === 0
                    ? { data: [] }
                    : await getTranslated(
                        filteredListToTranslate,
                        languageData.selected,
                      )

                const updatingLanguageData = languageData
                updatingLanguageData[languageData.selected] = {
                  ...updatingLanguageData[languageData.selected],
                  ...translatedData,
                }

                localStorage.setItem(
                  'language-data',
                  JSON.stringify(updatingLanguageData),
                )
                if (filteredListToTranslate.length !== 0) $state.reload()
              } else {
                if (currentlyTranslatable === 'false') {
                  localStorage.setItem(
                    'language-data',
                    JSON.stringify({
                      selected: 'origin',
                    }),
                  )
                }
              }
            }

            async function getTranslated(data, language = 'en') {
              let dataToReturn = { data: [] }
              try {
                dataToReturn = await Api.post('translation', {
                  contents: data,
                  target: language,
                })
                localStorage.setItem('currentlyTranslatable', 'true')
              } catch (e) {
                console.log(e)
                localStorage.setItem('currentlyTranslatable', 'false')
              }
              return dataToReturn
            }

            function pageProcessing() {
              CurrentUser.getClientSettings(
                resp.project.client.general_settings,
              )
              VariableThemeColor.mainColorSet(
                resp.project.client.general_settings.colors.secondary_color,
                true,
              )

              $scope.app = resp.application
              $scope.app.project = pluralize.singular(
                $scope.app.projects_display,
              )

              $scope.synchronised = resp.synchronised
              $scope.error = !$scope.synchronised
                ? 'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.'
                : null
              $scope.synchronised === false &&
                waitForReportSync(resp.client_object_key)

              var group_sets = {},
                pointer_group_sets
              $scope.origin_group = {}
              var origin_group_id, origin_pointer_group

              var answers = {}

              for (var i = 0; i < resp.fields.length; i++) {
                answers[resp.fields[i].field] =
                  answers[resp.fields[i].field] || {}
                answers[resp.fields[i].field][resp.fields[i].set_id] =
                  resp.fields[i]
              }
              $scope.report = _extends({}, resp, {
                form: _extends({}, resp.form, {
                  fields: resp.form.fields.map(function (field, index) {
                    field.isCollapsed = false
                    field.hideLines = false
                    field.limitTo = 0
                    field.answer = {
                      photos: [],
                      signatures: [],
                      sketches: [],
                      multi_answers: [],
                      set_id: 0,
                      field: field.id,
                      text: '',
                      option: {},
                    }

                    if (
                      $state.current.name === 'share.reports' &&
                      index > 0 &&
                      resp.form.fields[index].type === 'header' &&
                      resp.form.fields[index - 1].type === 'text'
                    ) {
                      resp.form.fields[index - 1].fullReportWidth = true
                    }

                    if (
                      $state.current.name === 'share.reports' &&
                      index > 0 &&
                      resp.form.fields[index].type === 'header' &&
                      !resp.form.fields[index - 1].group &&
                      resp.form.fields[index - 1].type === 'nested_option'
                    ) {
                      const nestedFields =
                        resp.form.fields[index - 1].answer?.option
                          ?.nested_fields

                      if (
                        nestedFields?.length &&
                        nestedFields[nestedFields.length - 1].type === 'text'
                      ) {
                        nestedFields[
                          nestedFields.length - 1
                        ].fullReportWidth = true
                      }
                    }

                    if (
                      $state.current.name === 'share.reports' &&
                      index > 0 &&
                      resp.form.fields[index].type === 'header' &&
                      resp.form.fields[index - 1].group &&
                      resp.form.fields[index - 1].type === 'nested_option'
                    ) {
                      const nestedFields =
                        answers[resp.form.fields[index - 1]?.id]?.[0]?.option
                          ?.nested_fields

                      if (
                        nestedFields?.length &&
                        nestedFields[nestedFields.length - 1].type === 'text'
                      ) {
                        nestedFields[
                          nestedFields.length - 1
                        ].fullReportWidth = true
                      }
                    }

                    if (field.type === 'options_multi') {
                      $scope.selectedItems[field.answer.set_id] =
                        $scope.selectedItems[field.answer.set_id] || {}
                      $scope.selectedItems[field.answer.set_id][field.id] = []
                    }

                    if (field.group) {
                      origin_group_id = field.group
                      if (!$scope.origin_group[origin_group_id]) {
                        $scope.origin_group[origin_group_id] = {
                          group: {
                            title: field.group_info.name,
                            fields: [],
                          },
                        }
                        origin_pointer_group = $scope.origin_group[
                          origin_group_id
                        ].group.fields = []
                      }
                      origin_pointer_group.push(field)
                    }

                    field.answer.dateOpen = false

                    if (field.type === 'nested_option') {
                      field.answer_options.map(function (answer_option) {
                        answer_option.isAnswer = false
                        if (answer_option.field == field.answer.field) {
                          answer_option.isAnswer = true
                        }

                        if (answer_option.id == field.answer.chosen_answer) {
                          field.answer.option = answer_option
                        }

                        answer_option.nested_fields.map(function (option) {
                          if (option.type === 'options_multi') {
                            $scope.selectedItems[field.answer.set_id] =
                              $scope.selectedItems[field.answer.set_id] || {}
                            $scope.selectedItems[field.answer.set_id][
                              option.id
                            ] = []
                          }
                          option.isCollapsed = false
                          option.limitTo = 0
                          option.answer = {
                            photos: [],
                            signatures: [],
                            sketches: [],
                            multi_answers: [],
                            set_id: 0,
                            time: null,
                            field: option.id,
                          }
                          option.answer.dateOpen = false
                        })
                      })
                    }
                    var set_id

                    if (field.type === 'time' && !field.answer.time) {
                      //I'm not sure why this was initialized in this way, I commented it out because it was causing
                      //Records with empty times to render dps 4/19/2018
                      //field.answer.time = new Date();
                    }

                    if (field.type === 'date' && !field.answer.date) {
                      //I'm not sure why this was initialized in this way, I commented it out because it was causing
                      //Records with empty times to render dps 4/19/2018
                      //field.answer.date = new Date();
                    }

                    if (field.group) {
                      group_id = field.group
                      if (!group_sets[group_id]) {
                        group_sets[group_id] = {
                          fields: [],
                        }
                        pointer_group_sets = group_sets[group_id].fields = []
                      }
                    }

                    if (field.group && field.type === 'nested_option') {
                      var answersBySetId = answers[field.id]

                      if (!answersBySetId) {
                        return field
                      }

                      for (var setId in answersBySetId) {
                        var answer = answersBySetId[setId]

                        var copy = angular.copy(field)

                        copy.answer_options.map(function (answer_option) {
                          if (!answer_option.nested_fields.length) {
                            answer_option.isAnswer = true
                          }

                          if (answer_option.id == answer.chosen_answer) {
                            copy.answer.chosen_answer = answer.chosen_answer
                            copy.answer.option = answer_option
                            answer.option = answer_option
                          }

                          answer_option.nested_fields.map(function (option) {
                            resp.fields.forEach(function (nested_answer) {
                              if (
                                nested_answer.field === option.id &&
                                nested_answer.set_id === parseInt(setId)
                              ) {
                                option.answer = nested_answer
                                isAnswerValid(nested_answer) &&
                                  (answer_option.isAnswer = true)
                                copy.hideLines = true

                                if (
                                  option.type === 'time' &&
                                  option.answer.time
                                ) {
                                  var currentDate = new Date()
                                  var splitTime = option.answer.time.split(':')
                                  currentDate.setHours(splitTime[0])
                                  currentDate.setMinutes(splitTime[1])
                                  option.answer.time = currentDate
                                }
                                if (
                                  option.type === 'date' &&
                                  option.answer.date
                                ) {
                                  option.answer.date = new Date(
                                    option.answer.date,
                                  )
                                }
                                if (
                                  option.type === 'address' &&
                                  option.answer.map_snapshot
                                ) {
                                  option.answer.map_snapshot_info =
                                    option.answer.map_snapshot
                                  option.answer.map_snapshot =
                                    option.answer.map_snapshot.id
                                }
                                if (
                                  option.type === 'options_multi' &&
                                  option.answer.multi_answers.length > 0
                                ) {
                                  $scope.selectedItems[parseInt(setId)] =
                                    $scope.selectedItems[parseInt(setId)] || {}
                                  $scope.selectedItems[parseInt(setId)][
                                    option.id
                                  ] = []
                                  option.answer_options.forEach(function (
                                    item,
                                  ) {
                                    item.isSelected = false
                                    option.answer.multi_answers
                                      .sort($scope.orderByMultiAnswerOption)
                                      .forEach(function (selectedItem) {
                                        if (item.name === selectedItem.name) {
                                          item.isSelected = true
                                          $scope.selectedItems[parseInt(setId)][
                                            option.id
                                          ].push(item)
                                        }
                                      })
                                  })
                                }
                              }
                            })
                          })

                          if (
                            $state.current.name === 'share.reports' &&
                            answer_option.nested_fields.length
                          ) {
                            const fakeField = {
                              answer_options: [],
                              answer: {
                                files: [],
                                multi_answers: [],
                                multi_answers_junction: [],
                                photos: [],
                                signatures: [],
                                sketches: [],
                              },
                              type: 'fake_field',
                            }
                            answer_option.nested_fields.unshift(fakeField)
                          }
                        })

                        pointer_group_sets.push(copy)
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer = answer
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].set_id = answer.set_id

                        pointer_group_sets =
                          pointer_group_sets.sort(compareFieldBySetId)
                      }
                    }

                    resp.fields.forEach(function (answer) {
                      if (field.group) {
                        if (
                          answer.field === field.id &&
                          field.type !== 'nested_option'
                        ) {
                          field.hideLines = true
                          var field_group = angular.copy(field)
                          pointer_group_sets.push(field_group)
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].answer = answer
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].set_id = answer.set_id
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].answer.set_id = answer.set_id
                          if (
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .type === 'time' &&
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .answer.time
                          ) {
                            var currentDate = new Date()
                            var splitTime =
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].answer.time.split(':')
                            currentDate.setHours(splitTime[0])
                            currentDate.setMinutes(splitTime[1])
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.time = currentDate
                          }
                          if (
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .type === 'date' &&
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .answer.date
                          ) {
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.date = new Date(
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].answer.date,
                            )
                          }
                          if (
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .type === 'address' &&
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .answer.map_snapshot
                          ) {
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.map_snapshot_info =
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].answer.map_snapshot
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.map_snapshot =
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].answer.map_snapshot.id
                          }
                          if (
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .type === 'options_multi' &&
                            pointer_group_sets[pointer_group_sets.length - 1]
                              .answer.multi_answers.length > 0
                          ) {
                            $scope.selectedItems[
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].answer.set_id
                            ] =
                              $scope.selectedItems[
                                pointer_group_sets[
                                  pointer_group_sets.length - 1
                                ].answer.set_id
                              ] || {}
                            $scope.selectedItems[
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].answer.set_id
                            ][
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].id
                            ] = []
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer_options.forEach(function (item) {
                              item.isSelected = false
                              pointer_group_sets[
                                pointer_group_sets.length - 1
                              ].answer.multi_answers
                                .sort($scope.orderByMultiAnswerOption)
                                .forEach(function (selectedItem) {
                                  if (item.name === selectedItem.name) {
                                    item.isSelected = true
                                    $scope.selectedItems[
                                      pointer_group_sets[
                                        pointer_group_sets.length - 1
                                      ].answer.set_id
                                    ][
                                      pointer_group_sets[
                                        pointer_group_sets.length - 1
                                      ].id
                                    ].push(item)
                                  }
                                })
                            })
                          }
                          set_id = answer.set_id
                          pointer_group_sets =
                            pointer_group_sets.sort(compareFieldBySetId)
                        }

                        return field
                      }

                      if (field.type !== 'nested_option' && !field.group) {
                        if (answer.field === field.id) {
                          field.answer = answer
                          field.hideLines = true
                          if (field.type === 'time' && field.answer.time) {
                            var currentDate = new Date()
                            var splitTime = field.answer.time.split(':')
                            currentDate.setHours(splitTime[0])
                            currentDate.setMinutes(splitTime[1])
                            field.answer.time = currentDate
                          }
                          if (field.type === 'date' && field.answer.date) {
                            field.answer.date = new Date(field.answer.date)
                          }
                          if (
                            field.type === 'address' &&
                            field.answer.map_snapshot
                          ) {
                            field.answer.map_snapshot_info =
                              field.answer.map_snapshot
                            field.answer.map_snapshot =
                              field.answer.map_snapshot.id
                          }
                          if (
                            field.type === 'options_multi' &&
                            field.answer.multi_answers.length > 0
                          ) {
                            $scope.selectedItems[field.answer.set_id] =
                              $scope.selectedItems[field.answer.set_id] || {}
                            $scope.selectedItems[field.answer.set_id][
                              field.id
                            ] = []
                            field.answer_options.forEach(function (item) {
                              item.isSelected = false
                              field.answer.multi_answers
                                .sort($scope.orderByMultiAnswerOption)
                                .forEach(function (selectedItem) {
                                  if (item.name === selectedItem.name) {
                                    item.isSelected = true
                                    $scope.selectedItems[field.answer.set_id][
                                      field.id
                                    ].push(item)
                                  }
                                })
                            })
                          }
                        }

                        return field
                      }

                      if (field.type === 'nested_option' && !field.group) {
                        field.answer_options.map(function (answer_option) {
                          if (!answer_option.nested_fields.length) {
                            resp.fields.forEach(function (
                              answer_for_nested_option,
                            ) {
                              if (answer_for_nested_option.field === field.id) {
                                answer_option.isAnswer = true
                                field.answer = answer_for_nested_option
                              }
                            })
                          }

                          if (answer_option.id == field.answer.chosen_answer) {
                            field.answer.option = answer_option
                          }

                          answer_option.nested_fields.map(function (option) {
                            resp.fields.forEach(function (nested_answer) {
                              if (nested_answer.field === field.id) {
                                field.answer = nested_answer
                              }
                              if (nested_answer.field === option.id) {
                                option.answer = nested_answer
                                field.hideLines = true
                                isAnswerValid(nested_answer) &&
                                  (answer_option.isAnswer = true)
                                if (
                                  option.type === 'time' &&
                                  option.answer.time
                                ) {
                                  if (!option.answer.currentDate) {
                                    var currentDate = new Date()
                                    var splitTime =
                                      option.answer.time.split(':')
                                    currentDate.setHours(splitTime[0])
                                    currentDate.setMinutes(splitTime[1])
                                    option.answer.time = currentDate
                                    option.answer.currentDate = true
                                  }
                                }
                                if (
                                  option.type === 'date' &&
                                  option.answer.date
                                ) {
                                  option.answer.date = new Date(
                                    option.answer.date,
                                  )
                                }
                                if (
                                  option.type === 'address' &&
                                  option.answer.map_snapshot
                                ) {
                                  option.answer.map_snapshot_info =
                                    option.answer.map_snapshot
                                  option.answer.map_snapshot =
                                    option.answer.map_snapshot.id
                                }
                                if (
                                  option.type === 'options_multi' &&
                                  option.answer.multi_answers.length > 0
                                ) {
                                  $scope.selectedItems[field.answer.set_id][
                                    option.id
                                  ] = []
                                  option.answer_options.forEach(function (
                                    item,
                                  ) {
                                    item.isSelected = false
                                    option.answer.multi_answers
                                      .sort($scope.orderByMultiAnswerOption)
                                      .forEach(function (selectedItem) {
                                        if (item.name === selectedItem.name) {
                                          item.isSelected = true
                                          $scope.selectedItems[
                                            field.answer.set_id
                                          ][option.id].push(item)
                                        }
                                      })
                                  })
                                }
                              }
                            })
                          })
                        })
                      }
                    })

                    return field
                  }),
                }),
              })

              var group_with_all_sets = {}
              for (var k in group_sets) {
                if (
                  $state.current.name === 'share.reports' &&
                  $scope.origin_group[k].group.fields
                ) {
                  const groupFields = $scope.origin_group[k].group.fields
                  let countGroupFields = groupFields.length

                  for (let i = 0; i < groupFields.length; i++) {
                    if (
                      groupFields[i].type === 'photos' ||
                      groupFields[i].type === 'signatures' ||
                      groupFields[i].type === 'sketches'
                    ) {
                      countGroupFields++
                    }
                  }
                  const fakeField = {
                    answer_options: [],
                    answer: {
                      files: [],
                      multi_answers: [],
                      multi_answers_junction: [],
                      photos: [],
                      signatures: [],
                      sketches: [],
                    },
                    type: 'fake_field',
                  }
                  if (countGroupFields % 2 !== 0) {
                    $scope.origin_group[k].group.fields.push(fakeField)
                  }
                }

                var current_set_id = 0
                if (!group_with_all_sets[k]) {
                  group_with_all_sets[k] = {
                    fields: [],
                  }
                  pointer_group_sets = group_with_all_sets[k].fields =
                    angular.copy($scope.origin_group[k].group.fields)
                }

                if (!group_sets[k].fields.length > 0) {
                  Array.prototype.push.apply(
                    group_with_all_sets[k].fields,
                    group_sets[k].fields,
                  )
                } else {
                  group_sets[k].fields.map(function (field) {
                    if (current_set_id !== field.answer.set_id) {
                      var copied_origin_group = angular.copy(
                        $scope.origin_group[k].group.fields,
                      )
                      copied_origin_group.map(function (copy) {
                        copy.set_id = current_set_id + 1
                        copy.answer.set_id = current_set_id + 1
                      })
                      Array.prototype.push.apply(
                        group_with_all_sets[k].fields,
                        copied_origin_group,
                      )
                    }
                    current_set_id = field.answer.set_id
                  })
                }
              }

              $scope.report.form.collapsedSections = {
                headers: [],
                others: [],
              }
              var pointer =
                ($scope.report.form.collapsedSections.others.fields = [])
              var path = ''

              var group_obj = {}
              var pointer_group
              var group_id = null
              for (var i = 0; i < $scope.report.form.fields.length; i++) {
                var field = $scope.report.form.fields[i]
                if (
                  $scope.report.form.fields[i] &&
                  $scope.report.form.fields[i].type &&
                  $scope.report.form.fields[i].type == 'header'
                ) {
                  $scope.report.form.collapsedSections.headers.push(
                    $scope.report.form.fields[i],
                  )
                  var index =
                    $scope.report.form.collapsedSections.headers.length - 1
                  pointer = $scope.report.form.collapsedSections.headers[
                    index
                  ].fields = []
                  path = 'headers_' + index
                  continue
                }

                if (field.group) {
                  group_id = field.group
                  if (!group_obj[group_id]) {
                    pointer.push(field)
                    group_obj[group_id] = {
                      group: {
                        title: field.group_info.name,
                        fields: [],
                      },
                    }
                    pointer_group = group_obj[group_id].group.fields = []
                  }
                  if (group_sets[group_id]) {
                    group_with_all_sets[group_id].fields.map(function (item) {
                      for (
                        var k = 0;
                        k < group_sets[group_id].fields.length;
                        k++
                      ) {
                        if (
                          item.answer.field ===
                            group_sets[group_id].fields[k].id &&
                          item.answer.set_id ===
                            group_sets[group_id].fields[k].answer.set_id
                        ) {
                          item.answer = group_sets[group_id].fields[k].answer
                          item.hideLines =
                            group_sets[group_id].fields[k].hideLines
                          item.answer_options.length > 0 &&
                            (item.answer_options =
                              group_sets[group_id].fields[k].answer_options)
                        }
                      }
                    })
                    group_obj[group_id] = {
                      group: {
                        title: field.group_info.name,
                        fields: group_with_all_sets[group_id].fields,
                      },
                    }
                  } else {
                    pointer_group.push(field)
                  }
                  group_obj[group_id].group.fields.map(function (field) {
                    field.path = path
                      ? path + '_group_' + field.answer.set_id
                      : 'others_' + i + '_group_' + field.answer.set_id
                    if (field.type === 'nested_option') {
                      field.answer_options.map(function (answer_option, i) {
                        answer_option.path = field.path + '_answer-options_' + i
                        answer_option.nested_fields.map(function (
                          nested_field,
                          i,
                        ) {
                          nested_field.path =
                            answer_option.path + '_nested-fields_' + i
                        })
                      })
                    }
                  })
                  pointer[pointer.length - 1] = group_obj[group_id]
                } else {
                  pointer.push(field)
                }

                pointer[pointer.length - 1].path = path || 'others_' + i

                if (field.type === 'nested_option' && !field.group) {
                  field.answer_options.map(function (answer_option, i) {
                    var group_str = answer_option.group
                      ? '_group_' + answer_option.answer.set_id
                      : ''
                    answer_option.path =
                      pointer[pointer.length - 1].path +
                      '_answer-options_' +
                      i +
                      group_str
                    answer_option.nested_fields.map(function (nested_field, i) {
                      nested_field.path =
                        answer_option.path + '_nested-fields_' + i + group_str
                    })
                  })
                }
              }
              $scope.report.form.fields[0].answer.text = $scope.report.form
                .fields[0].answer.text
                ? $scope.report.form.fields[0].answer.text
                : $scope.report.form.name +
                  ' - ' +
                  moment($scope.report.form.date_updated).format('MM/DD/YYYY')
            }
          },
          (error) => {
            Notification.error(
              error.message ||
                'Sorry, but this report has not finished processing. Please try again later.',
            )
          },
        )
      }

      getSharesReportDataById()

      function isAnswerValid(answer) {
        return (
          answer.text ||
          answer.date ||
          answer.time ||
          answer.signatures.length > 0 ||
          answer.sketches.length > 0 ||
          answer.photos.length > 0 ||
          answer.multi_answers.length > 0 ||
          answer.map_snapshot
        )
      }
    },
  )
