import { changeBackground } from '@/utils'
import { cloneDeep } from 'lodash'
import moment from 'moment'

export async function dataGet(
  Api,
  setTemplateData,
  stateService,
  setFormMemory,
  setOldFormMemory,
  setExternalError,
  setLogoForExternals,
  setError,
) {
  let { data } =
    stateService.current.name === 'assigned.external_form'
      ? await Api.get(
          'external_reports/' + stateService.params.client_object_key,
          {
            from_official_client: 1,
            no_fields: true,
          },
        )
      : stateService.current.name !== 'app.forms.reports_edit'
      ? await Api.ReportTemp.post({
          application: stateService.params.app,
          synchronised: true,
          is_draft: false,
          form: stateService.params.form,
          project: stateService.params.project,
        })
      : await Api.get('reports', {
          id: stateService.params.report,
          no_fields: true,
        })
  let fieldsGet = []

  if (data.count) {
    data = data.results[0]
  }
  if (data?.message) {
    setError(data?.message)
    changeBackground('#3682CF')
    return
  }
  if (stateService.current.name === 'assigned.external_form') {
    changeBackground(
      data.project.client.general_settings.colors.secondary_color,
    )
  }
  if (
    data?.form?.deleted &&
    stateService.current.name === 'assigned.external_form'
  ) {
    setError(
      'This form has been deleted. Please use a different link or contact the administrator.',
    )
    return
  }
  if (data.id) {
    await getFields()
  }
  async function getFields(possibleNextPage = 1) {
    let next
    const { data: fieldsPage } =
      stateService.current.name === 'assigned.external_form'
        ? await Api.get('external_report_fields', {
            report: data.client_object_key,
            page: possibleNextPage,
          })
        : await Api.getWithoutDeletedParam('report_fields', {
            report: data.id,
            page: possibleNextPage,
          })
    fieldsGet = fieldsGet.concat(fieldsPage.results)
    next = fieldsPage.next
    while (next) {
      possibleNextPage++
      const { data: fieldsPageLoop } =
        stateService.current.name === 'assigned.external_form'
          ? await Api.get('external_report_fields', {
              report: data.client_object_key,
              page: possibleNextPage,
            })
          : await Api.get('report_fields', {
              report: data.id,
              page: possibleNextPage,
            })
      fieldsGet = fieldsGet.concat(fieldsPageLoop.results)
      next = fieldsPageLoop.next
    }
  }
  data.fields = fieldsGet
  if (data.message) {
    setExternalError(data.message)
    return
  }
  if (setLogoForExternals && data?.project?.client?.company?.logo_url) {
    setLogoForExternals(data.project.client.company.logo_url)
  }
  if (
    stateService.current.name === 'assigned.external_form' &&
    data.is_external &&
    data.is_draft === false &&
    data.fields.length !== 0
  ) {
    stateService.go('share.reports', {
      key: stateService.params.client_object_key,
    })
  }
  const fields = []
  const memoryFields = {}
  const listToTranslate = [
    'Please answer all required fields before saving.',
    'First name',
    'Last name',
    'Email',
    'Company name',
    'Required Information:',
    'Save',
    'Save as Draft',
    'Draft',
    'Submit',
    'Cancel',
    'US Dollar',
    'Euro',
    'Swiss Franc',
    'British Pound',
    'Canadian Dollar',
    'Metric Ton',
    'Kilogram',
    'Gram',
    'Pounds',
    'Meter',
    'Centimeter',
    'Millimeter',
    'Kilometer',
    'Feet',
    'Inches',
    'Miles',
    'Square Feet',
    'Square Meters',
    'Cubic Feet',
    'Cubic Meters',
    'Fahrenheit',
    'Celsius',
    'Kelvin',
  ]

  listToTranslate.push(
    data.form.name,
    data.project.name,
    data.project.address,
    data.project.number,
  )
  data.form.fields.forEach((value) => {
    listToTranslate.push(value.name)
    value.answer_options.forEach((option) => {
      listToTranslate.push(option.name)
      option.nested_fields.forEach((value) => {
        listToTranslate.push(value.name)
        value.answer_options.forEach((option) => {
          listToTranslate.push(option.name)
        })
        if (value.group_info) {
          if (!listToTranslate.includes(value.group_info.name)) {
            listToTranslate.push(value.group_info.name)
          }
        }
      })
    })
    if (value.group_info) {
      if (!listToTranslate.includes(value.group_info.name)) {
        listToTranslate.push(value.group_info.name)
      }
    }
    if (fields.length === 0) {
      fields.push(value)
      memoryFields[value.id] = {
        ...value,
        localAnswer: {
          ...createAnswerTemplate(value.id),
          text: data.form.name + ' - ' + moment().format('MM/DD/YYYY'),
          value: data.form.name,
        },
      }
    } else {
      if (!value.group_info) {
        fields.push(value)
        memoryFields[value.id] = {
          ...value,
          localAnswer: createAnswerTemplate(value.id),
        }
        if (value.type === 'nested_option') {
          value.answer_options.forEach((answer) => {
            answer.nested_fields.forEach((nested_field) => {
              memoryFields[nested_field.id] = {
                ...nested_field,
                localAnswer: createAnswerTemplate(nested_field.id),
              }
            })
          })
        }
      } else if (
        fields[fields.length - 1].group_name === value.group_info.name
      ) {
        fields[fields.length - 1].group_list.push(value)
        memoryFields[value.id] = {
          set_id_0: { ...value, localAnswer: createAnswerTemplate(value.id) },
        }
        if (value.type === 'nested_option') {
          value.answer_options.forEach((answer) => {
            answer.nested_fields.forEach((nested_field) => {
              memoryFields[nested_field.id] = {
                set_id_0: {
                  ...nested_field,
                  localAnswer: createAnswerTemplate(nested_field.id),
                },
              }
            })
          })
        }
      } else {
        fields.push({
          type: 'group',
          group_list: [value],
          group_name: value.group_info.name,
        })
        memoryFields[value.id] = {
          set_id_0: { ...value, localAnswer: createAnswerTemplate(value.id) },
        }
        if (value.type === 'nested_option') {
          value.answer_options.forEach((answer) => {
            answer.nested_fields.forEach((nested_field) => {
              memoryFields[nested_field.id] = {
                set_id_0: {
                  ...nested_field,
                  localAnswer: createAnswerTemplate(nested_field.id),
                },
              }
            })
          })
        }
      }
    }
  })

  const languageData = JSON.parse(localStorage.getItem('language-data'))
  const untranslatedCash: string[] = JSON.parse(
    localStorage.getItem('untranslated-cash'),
  )

  const filteredListToTranslate: string[] = []

  listToTranslate.forEach((string: string) => {
    if (!untranslatedCash.includes(string) && string && string !== '') {
      untranslatedCash.push(string)
    }
  })

  localStorage.setItem('untranslated-cash', JSON.stringify(untranslatedCash))

  if (languageData.selected !== 'origin') {
    listToTranslate.forEach((stringToTranslate: string) => {
      if (!languageData[languageData.selected]) {
        languageData[languageData.selected] = {}
      }
      if (!languageData[languageData.selected][stringToTranslate]) {
        if (stringToTranslate !== '' && stringToTranslate)
          filteredListToTranslate.push(stringToTranslate)
      }
    })
    const { data: translatedData } =
      filteredListToTranslate.length === 0
        ? { data: [] }
        : await getTranslated(filteredListToTranslate, languageData.selected)
    const updatingLanguageData = languageData
    updatingLanguageData[languageData.selected] = {
      ...updatingLanguageData[languageData.selected],
      ...translatedData,
    }
    localStorage.setItem('language-data', JSON.stringify(updatingLanguageData))
  }

  async function getTranslated(data, language = 'en') {
    let dataToReturn = { data: [] }
    try {
      dataToReturn = await Api.post('translation', {
        contents: data,
        target: language,
      })
      localStorage.setItem('currentlyTranslatable', 'true')
    } catch (e) {
      localStorage.setItem('currentlyTranslatable', 'false')
    }
    return dataToReturn
  }

  const headerFilteredFields = []

  fields.forEach((field) => {
    if (headerFilteredFields.length === 0) {
      headerFilteredFields.push(field)
    } else if (field.type === 'header') {
      field.fields = []
      field.required = false
      headerFilteredFields.push(field)
    } else if (
      field.type !== 'header' &&
      headerFilteredFields[headerFilteredFields.length - 1].type === 'header'
    ) {
      headerFilteredFields[headerFilteredFields.length - 1].fields.push(field)
    } else {
      headerFilteredFields.push(field)
    }
  })

  data.form.fields = headerFilteredFields

  data.fields.forEach((field) => {
    if ('set_id_0' in memoryFields[field.field]) {
      if (!memoryFields[field.field]['set_id_' + field.set_id]) {
        memoryFields[field.field]['set_id_' + field.set_id] = {}
      }
      memoryFields[field.field]['set_id_' + field.set_id].localAnswer = {
        ...field,
        map_snapshot: field.map_snapshot ? field.map_snapshot.id : null,
        fieldmeta: null,
      }
    } else {
      memoryFields[field.field].localAnswer = {
        ...field,
        map_snapshot: field.map_snapshot ? field.map_snapshot.id : null,
        fieldmeta: null,
      }
    }
  })

  setTemplateData(data)
  setFormMemory(memoryFields)
  setOldFormMemory(cloneDeep(memoryFields))
}

export function createAnswerTemplate(formFieldId, setId = 0) {
  return {
    photos: [],
    files: [],
    map_snapshot: null,
    signatures: [],
    sketches: [],
    multi_answers: [],
    multi_answers_junction: [],
    set_id: setId,
    is_visible: true,
    text: '',
    value: '',
    field: formFieldId,
    chosen_answer: null,
  }
}
