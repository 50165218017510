import { chunk, flatMap, isEqual } from 'lodash'

export async function sendDataLogic(
  sketches,
  signatures,
  memory,
  oldMemory,
  Api,
  stateService,
  userId,
  form,
  contact,
  promisesToDeleteFiles,
  asDraft,
) {
  const promiseList = []
  promisesToDeleteFiles.forEach((fileToDelete) => {
    if (fileToDelete.type === 'image') {
      if (fileToDelete.page === 'external') {
        promiseList.push(Api.removeExternalImage(fileToDelete.id))
      } else {
        promiseList.push(Api.removeImage(fileToDelete.id))
      }
    } else {
      if (fileToDelete.page === 'external') {
        promiseList.push(Api.removeExternalFile(fileToDelete.id))
      } else {
        promiseList.push(Api.removeFile(fileToDelete.id))
      }
    }
  })
  flatMap(sketches).forEach((sketch) => {
    promiseList.push(
      Api.patch(`sketches/${sketch.id}`, {
        printed_name: sketch.printed_name || '',
        id: sketch.id,
        deleted: sketch.deleted,
      }),
    )
  })
  flatMap(signatures).forEach((signature) => {
    promiseList.push(
      Api.patch(`signatures/${signature.id}`, {
        printed_name: signature.printed_name || '',
        id: signature.id,
        deleted: signature.deleted,
      }),
    )
  })

  const processedOldFields = []
  const processedFields = []

  if (stateService.params.key) {
    flatMap({
      ...memory,
    }).forEach((field) => {
      if (field.set_id_0) {
        Object.keys(field).forEach((key) => {
          processedFields.push({
            ...field[key].localAnswer,
            multi_answers_junction: [],
            set_id: key.split('_')[2],
            report: Number(form.id),
          })
        })
      } else {
        processedFields.push({
          ...field.localAnswer,
          multi_answers_junction: [],
          report: Number(form.id),
        })
      }
      if (field.type === 'options_multi') {
      }
    })
    flatMap({
      ...oldMemory,
    }).forEach((field) => {
      if (field.set_id_0) {
        Object.keys(field).forEach((key) => {
          processedOldFields.push({
            ...field[key].localAnswer,
            set_id: key.split('_')[2],
            report: Number(form.id),
          })
        })
      } else {
        processedOldFields.push({
          ...field.localAnswer,
          report: Number(form.id),
        })
      }
      if (field.type === 'options_multi') {
      }
    })
  }

  const { data: reportResponse } =
    stateService.current.name === 'assigned.external_form'
      ? !stateService.params.key
        ? await Api.post('external_reports', {
            application: Number(stateService.params.app_id),
            synchronised: true,
            project: form.project.id,
            user: userId,
            form: form.form.id,
            is_external: true,
            is_draft: !!asDraft,
            ...contact,
          })
        : await Api.patch(
            'external_reports/' + stateService.params.client_object_key,
            {
              fields: processedFields,
              synchronised: true,
              is_external: true,
              is_draft: !!asDraft,
            },
          )
      : stateService.current.name === 'app.forms.reports_edit'
      ? { data: { id: stateService.params.report } }
      : await Api.post('reports', {
          application: Number(stateService.params.app),
          synchronised: true,
          form: Number(stateService.params.form),
          project: Number(stateService.params.project),
          user: userId,
        })
  const { data: external_contact } =
    stateService.current.name === 'assigned.external_form' &&
    form.is_draft === true
      ? await Api.put(
          'external_reports/' +
            stateService.params.client_object_key +
            '/external_contact/',
          contact,
        )
      : { data: null }

  if (!stateService.params.key) {
    flatMap({
      ...memory,
    }).forEach((field) => {
      if (field.set_id_0) {
        Object.keys(field).forEach((key) => {
          processedFields.push({
            ...field[key].localAnswer,
            multi_answers_junction: [],
            set_id: Number(key.split('_')[2]),
            report: Number(reportResponse.id),
          })
        })
      } else {
        processedFields.push({
          ...field.localAnswer,
          multi_answers_junction: [],
          report: Number(reportResponse.id),
        })
      }
    })
    flatMap({
      ...oldMemory,
    }).forEach((field) => {
      if (field.set_id_0) {
        Object.keys(field).forEach((key) => {
          processedOldFields.push({
            ...field[key].localAnswer,
            set_id: Number(key.split('_')[2]),
            report: Number(reportResponse.id),
          })
        })
      } else {
        processedOldFields.push({
          ...field.localAnswer,
          report: Number(reportResponse.id),
        })
      }
    })
  }

  if (stateService.current.name === 'assigned.external_form') {
    await Api.post(
      'external_reports/' + reportResponse.client_object_key + '/send_alerts',
      { created: !asDraft },
    )
  } else {
    await Api.post('reports/' + reportResponse.id + '/send_alerts', {
      created: stateService.current.name === 'app.forms.reports_create',
    })
  }

  await Promise.all(promiseList)
  if (!stateService.params.key) {
    const sendChunkSize: number = 20
    if (stateService.current.name === 'app.forms.reports_create' || asDraft) {
      const splitFields = chunk(processedFields, sendChunkSize).map(
        (fields) => {
          return Api.post('report_fields', fields)
        },
      )
      await Promise.all(splitFields)
    } else {
      const processedFieldsEditChecked = []
      processedFields.forEach((field, index) => {
        if (
          !isEqual(field, processedOldFields[index]) ||
          memory[field.field].type === 'report_title'
        ) {
          processedFieldsEditChecked.push(field)
        }
      })
      if (processedFieldsEditChecked.length) {
        const splitFields = chunk(
          processedFieldsEditChecked,
          sendChunkSize,
        ).map((fields) => {
          return Api.post('report_fields', fields)
        })
        await Promise.all(splitFields)
      }
    }
  }

  if (stateService.current.name === 'assigned.external_form') {
    if (asDraft) {
      stateService.go(
        'assigned.external_form',
        {
          client_object_key: reportResponse.client_object_key,
          app_id: stateService.params.app_id,
          application_bundle_id: stateService.params.application_bundle_id,
          key: reportResponse.external_contacts[0].external_contact.key,
        },
        { reload: true },
      )
    } else {
      stateService.go('share.reports', {
        key: reportResponse.client_object_key,
      })
    }
  } else {
    stateService.go('app.forms.reports', {
      app: stateService.params.app,
    })
  }
}

export function validateFields(fields, setAlert, alert, setFirstFieldToError) {
  let errorCount = 0
  let idsOfErrorsByOrder = {}
  let idsOfErrors = []
  flatMap({ ...fields }).forEach((field) => {
    const possibleFields = {
      nested_option: 'value',
      report_title: 'value',
      text: 'value',
      address: 'value',
      currency: 'value',
      mass: 'value',
      dimension: 'value',
      temperature: 'value',
      date: 'date',
      files: 'files',
      number: 'value',
      options: 'value',
      options_multi: 'multi_answers',
      photos: 'photos',
      radio: 'value',
      signatures: 'signatures',
      sketches: 'sketches',
      time: 'time',
    }
    if (
      field.type !== 'header' &&
      field.type !== 'image_reference' &&
      field.type !== 'paragraph'
    ) {
      if (field.required) {
        if (
          field.type === 'currency' ||
          field.type === 'mass' ||
          field.type === 'dimension' ||
          field.type === 'temperature'
        ) {
          if (
            Number(
              field.localAnswer[possibleFields[field.type]].replace(/\D/g, ''),
            ) === 0
          ) {
            idsOfErrors.push(field.id)
            if (!idsOfErrorsByOrder[field.order]) {
              idsOfErrorsByOrder[field.order] = [
                `${field.id}_set_id_${field.localAnswer.set_id}`,
              ]
            } else {
              idsOfErrorsByOrder[field.order].push(
                `${field.id}_set_id_${field.localAnswer.set_id}`,
              )
            }
            errorCount += 1
          }
        } else if (field.type === 'time') {
          if (
            field.localAnswer[possibleFields[field.type]] === '0:0:0' ||
            field.localAnswer[possibleFields[field.type]] === 'null:0:0' ||
            field.localAnswer[possibleFields[field.type]] === null
          ) {
            idsOfErrors.push(field.id)
            if (!idsOfErrorsByOrder[field.order]) {
              idsOfErrorsByOrder[field.order] = [
                `${field.id}_set_id_${field.localAnswer.set_id}`,
              ]
            } else {
              idsOfErrorsByOrder[field.order].push(
                `${field.id}_set_id_${field.localAnswer.set_id}`,
              )
            }
            errorCount += 1
          }
        }
        if (
          (!field.localAnswer[possibleFields[field.type]] ||
            !field.localAnswer[possibleFields[field.type]].length) &&
          field.type !== 'report_title'
        ) {
          idsOfErrors.push(field.id)
          if (!idsOfErrorsByOrder[field.order]) {
            idsOfErrorsByOrder[field.order] = [
              `${field.id}_set_id_${field.localAnswer.set_id}`,
            ]
          } else {
            idsOfErrorsByOrder[field.order].push(
              `${field.id}_set_id_${field.localAnswer.set_id}`,
            )
          }
          errorCount += 1
        }
      } else if (field.set_id_0) {
        Object.values({ ...field }).forEach((field) => {
          if (
            field.type !== 'header' &&
            field.type !== 'image_reference' &&
            field.type !== 'paragraph'
          ) {
            if (field.required) {
              if (
                !field.localAnswer[possibleFields[field.type]] ||
                !field.localAnswer[possibleFields[field.type]].length
              ) {
                idsOfErrors.push(field.id)
                if (!idsOfErrorsByOrder[field.order]) {
                  idsOfErrorsByOrder[field.order] = [
                    `${field.id}_set_id_${field.localAnswer.set_id}`,
                  ]
                } else {
                  idsOfErrorsByOrder[field.order].push(
                    `${field.id}_set_id_${field.localAnswer.set_id}`,
                  )
                }
                errorCount += 1
              }
            }
          }
        })
      }
    }
  })

  if (errorCount > 0) {
    setAlert([alert])
    const elementToScrollTo = Object.values(idsOfErrorsByOrder)[0][0]
    if (document.getElementById(elementToScrollTo)) {
      document.getElementById(elementToScrollTo).scrollIntoView()
    }
    setFirstFieldToError({
      idOfFirstErroredField: elementToScrollTo,
      idsThatErrored: idsOfErrors,
    })
    return false
  } else {
    return true
  }
}
