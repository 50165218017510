import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const AddImageIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M31.0999 40.4399C36.5676 40.4399 41 36.0075 41 30.5399C41 25.0723 36.5676 20.6399 31.0999 20.6399C25.6323 20.6399 21.2 25.0723 21.2 30.5399C21.2 36.0075 25.6323 40.4399 31.0999 40.4399Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M21.5 28C21.8444 26.6977 22.4512 25.4795 23.2832 24.4201C24.1152 23.3607 25.1549 22.4824 26.3385 21.8391C27.522 21.1959 28.8245 20.8011 30.166 20.6792C31.5076 20.5573 32.8599 20.7107 34.14 21.13V5.13C34.14 4.03466 33.7049 2.98417 32.9304 2.20965C32.1558 1.43512 31.1053 1 30.01 1H5.12C4.035 1.00255 2.99458 1.43196 2.22363 2.19543C1.45268 2.95889 1.01313 3.99507 1 5.08V29.99C1.00264 31.0819 1.43756 32.1283 2.20964 32.9004C2.98172 33.6724 4.02812 34.1074 5.12 34.11H21.86C21.0942 32.1632 20.9681 30.0232 21.5 28V28Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1 27.38L8 15.64C8.14014 15.4077 8.33473 15.2131 8.56696 15.073C8.79919 14.9328 9.06207 14.8513 9.33285 14.8356C9.60364 14.8198 9.87419 14.8703 10.1211 14.9825C10.368 15.0948 10.5839 15.2655 10.75 15.48L15.5 21.58C15.6556 21.787 15.8572 21.955 16.0889 22.0707C16.3206 22.1864 16.576 22.2466 16.835 22.2466C17.094 22.2466 17.3494 22.1864 17.5811 22.0707C17.8128 21.955 18.0144 21.787 18.17 21.58L20 19.1C20.1583 18.8831 20.3669 18.7078 20.6078 18.5892C20.8488 18.4707 21.1149 18.4123 21.3834 18.4192C21.6518 18.4261 21.9146 18.498 22.1491 18.6288C22.3837 18.7595 22.583 18.9452 22.73 19.17L25.08 22.73"
        stroke="currentcolor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M31.1001 26.1699V34.9099"
        stroke="currentcolor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M26.72 30.5399H35.47"
        stroke="currentcolor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M25.1699 13.4899C27.23 13.4899 28.8999 11.8199 28.8999 9.75991C28.8999 7.69989 27.23 6.02991 25.1699 6.02991C23.1099 6.02991 21.4399 7.69989 21.4399 9.75991C21.4399 11.8199 23.1099 13.4899 25.1699 13.4899Z"
        stroke="currentcolor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </svg>
  )
}
