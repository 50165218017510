import * as React from 'react'
import {
  getFilterParam,
  TableRow,
  useAngularServices,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { UISref } from '@components/UISref'
import { ViewIcon } from '@/react/componentAssets'
import { DownloadElement } from '@screens/Timecard/TimeAndMaterialPage/components/DownloadElement'
import { encodeString } from '@/utils/additionalEncodeLogic'

export const TableBody = ({
  page,
  search,
  order,
  setCount,
  advancedFilters,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [list, setList] = React.useState<object[][]>([])

  let _isMounted = false

  React.useEffect(() => {
    // componentWillMount
    _isMounted = true
    // componentWillUnmount
    return () => {
      // Your code here
      _isMounted = false
    }
  }, [])

  async function fetchData() {
    const listRenderSettings: object[][] = []
    const params = {
      page: page,
      order: order,
      current: true,
      application: stateService.params.app,
    }
    if (search) params.search = search

    let stringParams = ''

    Object.entries(params).forEach((param) => {
      stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
    })

    Object.entries(advancedFilters).forEach((param) => {
      if (param[1].type && param[1].string) {
        stringParams +=
          getFilterParam(
            param[0],
            param[1].type,
            param[1].string,
            param[1].secondType,
            param[1].secondString,
          ) + '&'
      }
    })

    const [{ data }] = await Promise.all([
      Api.get('tickets', stringParams.slice(0, -1)),
    ])

    data.results.forEach((ticket) => {
      listRenderSettings.push([
        {
          object: <div css={css({ textAlign: 'left' })}>{ticket.date}</div>,
        },
        {
          object: (
            <div css={css({ textAlign: 'left' })}>
              {ticket.project.number ? ticket.project.number + ' ' : ''}
              {ticket.project.name}
            </div>
          ),
        },
        {
          object: (
            <div css={css({ textAlign: 'center' })}>{ticket.tm_number}</div>
          ),
        },
        {
          object: (
            <div css={css({ textAlign: 'center' })}>
              {ticket.user.first_name + ' ' + ticket.user.last_name}
            </div>
          ),
        },
        {
          object: (
            <div css={css({ textAlign: 'center' })}>
              ${(Math.round(ticket.grand_total * 100) / 100).toFixed(2)}
            </div>
          ),
        },
        {
          object: (
            <div
              css={css({
                display: 'flex',
                justifyContent: 'center',
                cursor: 'pointer',
              })}
            >
              <UISref to="app.timecard.tam_view" params={{ key: ticket.id }}>
                <a>
                  <ViewIcon height={'20px'} color={variableColor} />
                </a>
              </UISref>
            </div>
          ),
        },
        {
          object: (
            <DownloadElement id={ticket.id} variableColor={variableColor} />
          ),
        },
      ])
    })

    if (_isMounted) {
      setCount(data.count)
      setList(listRenderSettings)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [page, search, order, advancedFilters])

  if (!list.length) return null

  return (
    <tbody>
      {list.map((ticket, idx) => {
        return (
          <TableRow
            key={idx}
            columns={ticket}
            additionalStyles={css({ td: { paddingLeft: 5, height: 32 } })}
          />
        )
      })}
    </tbody>
  )
}
