import { css } from '@emotion/react'

export function commonLoginBodyStyle() {
  return css({
    '.forgot': {
      marginTop: '30px',
      paddingBottom: '38px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '.forgot__title': {
      textAlign: 'center',
      margin: '0px',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '14px',
      lineHeight: '15px',
      color: '#000000',
      marginBottom: '30px',
    },

    '@media(max-width: 500px)': {
      '.forgot': {
        paddingBottom: '19px',
      },
      '.forgot__title': {
        marginBottom: '15px',
      },
    },

    '.forgot__subtitle': {
      textAlign: 'center',
      margin: '0px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      display: 'flex',
      alignItems: 'center',
      marginBottom: '11px',
      color: '#000000',
    },

    '.forgot__error': {
      textAlign: 'center',
      margin: '0px',
      color: '#FF0505',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '11px',
      marginBottom: '5px',
    },

    '.forgot__input': {
      boxSizing: 'border-box',
      padding: '0px 79px 0px 25px',
      marginBottom: '20px',
      border: '0.5px solid #2167ae',
      borderRadius: '25px',
      width: '100%',
      height: '40px',
    },

    '.forgot__input:focus': {
      outline: 'none',
    },

    '.forgot__button': {
      background: '#2167ae',
      borderRadius: '25px',
      width: '100%',
      height: '35px',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      color: '#FFFFFF',
      border: 'none',
    },
    '.forgot__button[disabled]': {
      background: '#68696D',
    },
  })
}
