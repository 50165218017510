import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const PdfIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <path
        d="M13.88 6.14H11.32V0H0V6.14V12.55V15H11.32V12.55H13.88V6.14Z"
        fill="currentcolor"
      />
      <path
        d="M2.67993 7.65006C3.09636 7.58215 3.51808 7.55202 3.93993 7.56006C4.37273 7.52343 4.80461 7.64025 5.15993 7.89006C5.30042 7.99969 5.41273 8.14126 5.48751 8.303C5.5623 8.46475 5.59741 8.64201 5.58993 8.82006C5.59976 8.99565 5.57182 9.17132 5.50799 9.3352C5.44417 9.49908 5.34594 9.64737 5.21993 9.77006C4.85366 10.0664 4.39035 10.2161 3.91993 10.1901H3.60993V11.6301H2.70993L2.67993 7.65006ZM3.57993 9.50006C3.67959 9.51182 3.78028 9.51182 3.87993 9.50006C4.36993 9.50006 4.65993 9.26006 4.65993 8.86006C4.65993 8.46006 4.40993 8.27006 3.94993 8.27006C3.82706 8.25499 3.7028 8.25499 3.57993 8.27006V9.50006Z"
        fill="white"
      />
      <path
        d="M6.13989 7.64996C6.54386 7.5906 6.95159 7.56052 7.35989 7.55996C7.94225 7.5176 8.52228 7.66877 9.00989 7.98996C9.23521 8.16939 9.41445 8.4 9.53271 8.66263C9.65098 8.92526 9.70487 9.21232 9.68989 9.49996C9.70549 9.80188 9.65445 10.1035 9.54039 10.3835C9.42632 10.6635 9.25204 10.9149 9.02989 11.12C8.48614 11.4978 7.83076 11.681 7.16989 11.64C6.83561 11.6416 6.50158 11.6216 6.16989 11.58L6.13989 7.64996ZM7.03989 10.92C7.14628 10.9309 7.2535 10.9309 7.35989 10.92C7.54914 10.9402 7.7405 10.9169 7.91938 10.8519C8.09826 10.7869 8.25993 10.6819 8.39207 10.5449C8.52422 10.408 8.62334 10.2426 8.68189 10.0615C8.74043 9.88043 8.75685 9.68836 8.72989 9.49996C8.74891 9.32723 8.72888 9.15244 8.67128 8.98849C8.61368 8.82454 8.51998 8.67563 8.39711 8.55275C8.27423 8.42987 8.12532 8.33617 7.96137 8.27857C7.79742 8.22097 7.62262 8.20094 7.44989 8.21996C7.31368 8.20417 7.1761 8.20417 7.03989 8.21996V10.92Z"
        fill="white"
      />
      <path
        d="M10.29 7.58997H12.73V8.33997H11.19V9.25997H12.63V9.98997H11.19V11.61H10.29V7.58997Z"
        fill="white"
      />
    </svg>
  )
}
