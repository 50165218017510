angular
  .module('ccs')
  .controller(
    'EmployeesListCtrl',
    function ($scope, Api, $state, $stateParams, $log) {
      $log.debug('EmployeesListCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.employeesList = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name,first_name,last_name'

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageSize = 20

      function makeQuery() {
        const q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          is_active: 'True',
        }

        if ($scope.order) {
          q.order = $scope.reverse
            ? $scope.order
                .split(',')
                .map((a) => '-' + a)
                .join(',')
            : $scope.order
        }

        return q
      }

      var getEmployeesList = function getProcedures() {
        Api.EmployeesList.get(makeQuery(), function (resp) {
          $scope.employeesList = resp.results
          $scope.totalItems = resp.count
        })
      }

      getEmployeesList()

      $scope.changePage = function () {
        getEmployeesList()
      }
    },
  )
