angular
  .module('ccs')
  .controller(
    'ToolboxAssignProjectsCtrl',
    function ($scope, $stateParams, Api, app, $state, CurrentUser, $log) {
      $log.debug('ToolboxAssignProjectsCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.data = { items: [], total: 0 }
      $scope.breadcrumbs = [{ title: app.name }]
      $scope.app = app

      const toolboxId = parseInt($stateParams.toolbox, 10)
      Api.ToolboxTopics.byID(toolboxId, (resp) => {
        $scope.toolbox = resp
      })

      $scope.changePage = () => {
        getProjects()
      }

      $scope.tableHeaders = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'active_for_toolbox_topic',
          display: 'Inactive/Active',
          sortable: true,
          toggleAllBtn: true
        },
      ]

      $scope.toggleAll = () => {
        Api.post(
          `toolbox_topics/${toolboxId}/assign_all_projects`,
          {
            id: toolboxId,
            application: app.id,
            on: $scope.active,
            search: $scope.search,
            assigned: CurrentUser.is('supervisor'),
          },
          (resp) => {
            getProjects()
          },
        )
      }

      $scope.toggleActive = (project) => {
        if (project.active_for_toolbox_topic) {
          project.appProject.toolbox_topics.push(toolboxId)
        } else {
          project.appProject.toolbox_topics =
            project.appProject.toolbox_topics.filter((c) => c != toolboxId)
        }

        if (project.appProject.id) {
          Api.AppProjects.patch(
            {
              id: project.appProject.id,
              toolbox_topics: project.appProject.toolbox_topics,
            },
            (resp) => {},
          )
        } else {
          Api.AppProjects.post(
            {
              app: app.id,
              project: project.id,
            },
            (resp) => {
              Api.AppProjects.patch(
                {
                  id: resp.id,
                  toolbox_topics: project.appProject.toolbox_topics,
                },
                (resp) => {},
              )
            },
          )
        }
      }

      function projectQuery() {
        let query = {
          application: app.id,
          client: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_for_toolbox_topic: toolboxId,
          assigned: CurrentUser.is('supervisor'),
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return query
      }

      function appProjectQuery(projects) {
        return {
          projects: projects.map((p) => p.id).join(),
          app: app.id,
          only_toolboxes: true,
        }
      }

      function getProjects() {
        Api.Projects.get(
          { ...projectQuery(), search_fields: 'name' },
          function (resp) {
            $scope.data.total = resp.count
            const projects = resp.results

            Api.AppProjects.get(appProjectQuery(projects), function (resp) {
              const appProjects = resp.results

              $scope.data.items = projects
                .map(function (project) {
                  project.active_for_toolbox_topic = !!appProjects.filter(
                    (appProject) => {
                      return (
                        appProject.project.id == project.id &&
                        appProject.toolbox_topics.indexOf(toolboxId) > -1
                      )
                    },
                  ).length

                  if (
                    appProjects.filter((ap) => ap.project.id == project.id)
                      .length
                  ) {
                    project.appProject = appProjects.filter(
                      (ap) => ap.project.id == project.id,
                    )[0]
                  } else {
                    project.appProject = { toolbox_topics: [] }
                  }
                  return project
                })
                .filter((project) => project !== null)
            })
          },
        )
      }

      $scope.changePage()

      $scope.goBack = function () {
        $state.go(
          'app.toolboxes.project_to_toolbox',
          {
            app: app.id,
            pageNumber: $scope.previousPageNumber,
            reverse: $scope.previousReverse,
            order: $scope.previousOrder,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }
    },
  )
