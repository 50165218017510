import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function safetyDataSheetsConfig($stateProvider) {
  $stateProvider.state('app.safety_data_sheets_create', {
    url: '/safety_data_sheets_create',
    template: `<sds-create></sds-create>`,
    controller: function () {},
    resolve: resolvers.adminOnlyViewResolver,
  })
  $stateProvider.state('app.safety_data_sheets_edit', {
    url: '/safety_data_sheets_edit/:key',
    template: `<sds-create></sds-create>`,
    controller: function () {},
    resolve: resolvers.adminOnlyViewResolver,
  })
  $stateProvider.state('app.safety_data_sheets_list', {
    url: '/safety_data_sheets_list?pageNumber&order&search',
    template: `<sds-list></sds-list>`,
    controller: function () {},
  })
  $stateProvider.state('app.safety_data_sheets_project_assign', {
    url: '/safety_data_sheets_list/:key/project_assign?pageNumber&order&search',
    template: `<sds-assign-project></sds-assign-project>`,
    controller: function () {},
    resolve: resolvers.adminOnlyViewResolver,
  })
  $stateProvider.state('app.safety_data_sheets_archive', {
    url: '/safety_data_sheets_archive?pageNumber&order&search',
    template: `<sds-archive></sds-archive>`,
    controller: function () {},
    resolve: resolvers.adminOnlyViewResolver,
  })
}
