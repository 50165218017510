angular
  .module('ccs')
  .controller(
    'TrainingsListCtrl',
    function ($scope, Api, $state, $stateParams, $log) {
      $log.debug('TrainingsListCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.trainingsList = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name'

      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1

      $scope.pageSize = 20

      function query() {
        let q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          is_active: 'True',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      var getTrainingsList = function getProcedures() {
        Api.TrainingsList.get(query(), function (resp) {
          $scope.trainingsList = resp.results
          $scope.totalItems = resp.count
        })
      }

      getTrainingsList()

      $scope.changePage = function () {
        getTrainingsList()
      }
    },
  )
