import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'

export const DeleteConfirmationModal = (props) => {
  const { deleteHandler, currentId, uniqModalId } = props
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const modalStyle = getModalStyle(variableColor)

  return (
    <div
      css={modalStyle}
      className="modal fade"
      id={`delete-${uniqModalId}-${currentId}`}
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <h4 className="modal-title" id="modalLabel">
              Delete?
            </h4>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-ok"
              data-dismiss="modal"
              onClick={deleteHandler}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

function getModalStyle(variableColor) {
  return css({
    '.modal-dialog': {
      height: '90%',
      paddingTop: '15%',
      maxWidth: '400px',
    },
    '.modal-title': {
      textAlign: 'center',
      marginTop: '10px',
    },
    '.modal-header': {
      border: 'none',
    },
    '.modal-body': {
      padding: '10px 30px',
    },
    '.modal-footer': {
      border: 'none',
      display: 'flex',
      justifyContent: 'center',
      '.btn-ok': {
        border: '1px solid #337ab7',
        color: '#fff',
        padding: '5px 15px',
        background: variableColor,
        minWidth: '80px',
      },
      '.btn-ok:hover': {
        border: '1px solid',
        borderColor: variableColor,
        background: variableColor,
      },
    },
  })
}
