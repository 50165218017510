import { useAngularServices } from '@components/ReactAngularContext'
import { css } from '@emotion/react'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'
import { SectionResult } from './SectionResult'
import { useEffect, useState } from 'react'
import moment from 'moment'

export const AssessmentResults = ({ assessment }) => {
  const variableColor =
    assessment?.client_settings?.general_settings?.colors.secondary_color ||
    '#2167AE'

  const baseStyle = getBaseStyle(variableColor)

  const getDeletedMessage = (message: string) => {
    const [firstSentence, ...restOfText] = message.split(/(?<=\.)\s(.+)/)

    return (
      <div className="text-container">
        <p className="sentance">{firstSentence}</p>
        <p className="sentance">{restOfText.join('')}</p>
      </div>
    )
  }

  console.log(assessment, 'assessment')

  return (
    <section className="results__wrapper" css={baseStyle}>
      {!assessment?.error ? (
        <div>
          <h4 className="assessment__title">{assessment?.template_name}</h4>

          <div className="info_block">
            <div className="user__info__wrapper">
              <div className="user__info">
                <p className="info__title">Submitted By:</p>
                <p className="info__value">
                  {assessment?.external_contact?.full_name}
                </p>
              </div>
              <div className="user__info">
                <p className="info__title">Company Name:</p>
                <p className="info__value">
                  {assessment?.external_contact?.company_name}
                </p>
              </div>
              <div className="user__info no_border">
                <p className="info__title">Date Created:</p>
                <p className="info__value">
                  {moment(assessment?.date_created).format('MM/DD/YYYY')}
                </p>
              </div>
            </div>
            <SectionResult
              header={assessment?.assessment_sections?.find(
                (header) => header?.section?.name === 'Overall Scoring',
              )}
            />
          </div>
          <div className="sections__wrapper">
            {assessment?.assessment_sections
              ?.filter((header) => header?.section?.name !== 'Overall Scoring')
              ?.map((header) => (
                <SectionResult header={header} />
              ))}
          </div>

          <div className="general__description">
            {assessment?.client_settings?.general_settings?.disclaimer}
          </div>
        </div>
      ) : (
        <div className="error__wrapper">
          <p className="error__text">{getDeletedMessage(assessment?.error)}</p>
        </div>
      )}
    </section>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    background: '#fff',
    borderRadius: '30px',
    display: 'flex',
    flexDirection: 'column',
    width: '926px',
    alignItems: 'center',
    '.assessment__title': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#000',
      marginTop: '25px',
      marginBottom: '10px',
      fontWeight: 400,
      fontSize: '20px',
    },
    '.info_block': {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      paddingInline: '30px',
      alignItems: 'flex-start',
    },
    '.user__info': {
      display: 'flex',
      flexDirection: 'column',
      gap: '5px',
      paddingBottom: '10px',
      marginBottom: '10px',
      borderBottom: '1px solid #000',
    },
    '.info__title': {
      whiteSpace: 'nowrap',
      color: '#000',
      fontWeight: 600,
      fontSize: '12px',
      margin: 0,
    },
    '.info__value': {
      fontWeight: 400,
      fontSize: '12px',
      color: '#000',
      margin: 0,
      maxWidth: '230px',
    },
    '.section__result': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: '688px',
    },
    '.header': {
      height: '32px',
      width: '100%',
      color: '#000',
      background: hexToRGBAWithOpacity(variableColor, 0.1),
      paddingInline: '10px',
      fontSize: '16px',
      fontWeight: 400,
      display: 'flex',
      alignItems: 'center',
    },
    '.description': {
      color: '#000',
      whiteSpace: 'pre-wrap',
      margin: 0,
      marginLeft: '10px',
    },
    '.no_border': {
      border: 'none',
    },
    '.sections__wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      paddingInline: '20px',
      width: '100%',

      '.section__result': {
        width: '100%',
      },
    },
    '.general__description': {
      marginTop: '40px',
      fontStyle: 'italic',
      fontSize: '12px',
      fontWeight: 300,
      lineHeight: '15px',
      color: '#000',
      paddingInline: '20px',
      paddingBottom: '21px',
      whiteSpace: 'break-spaces',
    },
    '.user__info__wrapper': {
      paddingRight: '20px',
    },
    '@media(max-width: 1050px)': {
      width: '100%',
      '.section__result': {
        width: '100%',
      },
      '.sections__wrapper': {
        paddingInline: '12px',
      },
    },
    '.error__wrapper': {
      height: '222px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.error__text': {
      fontSize: '16px',
      fontWeight: 400,
      color: '#000000',
      margin: 0,
      paddingInline: '115px',
    },
    '.sentance': {
      textAlign: 'center',
      margin: 0,
    },
  })
