export const SectionResult = ({ header }) => {
  return (
    <div className="section__result">
      <div className="header">
        {header?.is_passed
          ? `${header?.section?.name} - ${
              +header?.grade >= 0 ? `${header?.grade}%` : header?.grade
            }`
          : `${header?.section?.name}`}
      </div>
      <p
        className="description"
        dangerouslySetInnerHTML={{
          __html: header?.grade_description,
        }}
      />
    </div>
  )
}
