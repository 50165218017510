import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import React, { useEffect, useMemo, useState } from 'react'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useRouter } from '@/react/hooks'
import { NavBar } from '../LMSCommonComponents/NavBar'
import { EmployeeRow } from './components/EmployeeRow'
import InfoIcon from '@/assets/icons/info-icon.svg'
import sortArrows from '@/assets/icons/sort-arrows.svg'
import sortArrowsActiveFirst from '@/assets/icons/sort-arrows-active.svg'
import sortArrowsActiveSecond from '@/assets/icons/sort-arrows-inactive.svg'
import { InformationPopUp } from './components/InformationPopUp'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'

export const TrainingEmployeeList = () => {
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const [order, setOrder] = useState('name,first_name,last_name')
  const [training, setTraining] = useState({})
  const [employeeList, setEmployeeList] = useState([])
  const [selectedProjects, setSelectedProject] = useState()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const projectId = stateService.params.project
  const [page, setPage] = useState(1)
  const [isAllChecked, setAllChecked] = useState<boolean>(false)
  const [search, setSearch] = useState('')
  const [count, setCount] = useState(20)
  const [refresh, setRefresh] = useState(false)
  const [isPopUpVisible, setPopUpVisible] = useState(false)
  const [listToAssign, setListToAssign] = useState([])
  const [listToUnassign, setListToUnassign] = useState([])
  const [wholeIdsList, setWholeIdsList] = useState([])
  const [alreadyAssigned, setAlreadyAssigned] = useState([])

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const handleCheckedAll = async () => {
    if (!isAllChecked) {
      setAllChecked(true)
      setListToUnassign([])
      setListToAssign(wholeIdsList)
    } else {
      setAllChecked(false)
      setListToAssign([])
      setListToUnassign(wholeIdsList)
    }
    setEmployeeList(
      employeeList.map((emp) => {
        emp.assigned = !isAllChecked

        return emp
      }),
    )
  }

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'template',
      name: 'Employees',
      multiType: ['name', 'first_name', 'last_name'],
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '18px',
        width: '90%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          display: 'flex',
          gap: '4px',
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '95px',
          position: 'relative',
        },
      },
      additionalInnerElement: (
        <div className="info__wrapper">
          <img
            className="info__icon"
            src={InfoIcon}
            alt="I"
            onClick={() => {
              setPopUpVisible(true)
              document.body.style.overflow = 'hidden'
            }}
          />
          {isPopUpVisible ? (
            <InformationPopUp setPopUpVisible={setPopUpVisible} />
          ) : null}
        </div>
      ),
    },
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '46px',
        minWidth: '46px',
        paddingLeft: 0,
        textAlign: 'left',
        borderBottom: '2px solid #C6C6C6',
        paddingInline: 0,
        p: {
          width: '0px',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          paddingRight: '13px',
        },
      },
      additionalInnerElement: (
        <div className="select__wrapper">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            src={
              order === 'assigned'
                ? sortArrowsActiveSecond
                : order === '-assigned'
                ? sortArrowsActiveFirst
                : sortArrows
            }
            className="sort__icon"
            alt="sort icon"
            onClick={() => {
              setOrder(order === '-assigned' ? 'assigned' : '-assigned')
            }}
          />
        </div>
      ),
    },
  ]

  useEffect(() => {
    const getStartingData = async () => {
      const { data: trainingResponse } = await Api.get(
        `trainings/${stateService.params.training}`,
        {},
      )

      const { data: wholeListRes } = await Api.get(
        'assign_training_set/user_ids',
        {
          is_active: true,
          training: stateService.params.training,
        },
      )

      const { data: assignedListRes } = await Api.get(
        'assign_training_set/user_ids',
        {
          training: stateService.params.training,
          is_assigned: true,
        },
      )

      setTraining(trainingResponse)
      setWholeIdsList(wholeListRes)
      setListToAssign(assignedListRes)
      setAlreadyAssigned(assignedListRes)
    }
    getStartingData()
  }, [])

  useEffect(() => {
    const getEmployeeData = async () => {
      const queryParams = {
        training: stateService.params.training,
        deleted: false,
        is_active: true,
        order,
        active: true,
        page,
        search,
      }

      const { data: employeeGroup } = await Api.get(
        'assign_training_set/user_set',
        queryParams,
      )

      setEmployeeList(employeeGroup.results)
      setCount(employeeGroup.count)
      setRefresh(false)
    }
    getEmployeeData()
  }, [search, page, order, refresh])

  const baseStyle = getBaseStyle(variableColor)

  const handleAssign = (employee, isGroup: boolean) => {
    const condition = isGroup
      ? employee?.users?.every((id) => !listToAssign.includes(id))
      : !listToAssign.includes(employee?.id)

    if (condition) {
      setListToAssign((prevList) =>
        !isGroup
          ? [...prevList, employee?.id]
          : [...prevList, ...employee?.users],
      )
      setListToUnassign(listToUnassign.filter((id) => id !== employee?.id))
      setAllChecked(listToAssign.length === wholeIdsList.length)
    } else {
      setAllChecked(false)
      setListToAssign(
        listToAssign.filter((id) => {
          if (isGroup) {
            return !employee?.users.includes(id)
          } else {
            return id !== employee?.id
          }
        }),
      )
      setListToUnassign((prevList) =>
        !isGroup
          ? [...prevList, employee?.id]
          : [...prevList, ...employee?.users],
      )
    }
  }

  const handleButtonClick = async () => {
    if (listToAssign.filter((id) => !alreadyAssigned.includes(id)).length) {
      await Api.post('assign', {
        active: true,
        trainings: [training?.id],
        users: listToAssign.filter((id) => !alreadyAssigned.includes(id)),
      })
    }
    if (listToUnassign.length) {
      await Api.post('assign', {
        active: false,
        trainings: [training?.id],
        users: listToUnassign,
      })
    }

    stateService.go('trainings.assign.employees_list')
  }

  return (
    <div css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <section className="assign-employee">
        <div className="top__wrapper">
          <h4 className="project__name">{training?.name}</h4>
          <ButtonElement
            buttonType="submit"
            text="Assign"
            functionToTrigger={handleButtonClick}
            additionalStyles={css({ padding: 0 })}
          />
        </div>
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
          updateUrl={false}
        />
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          {employeeList?.map((employee, index) => (
            <EmployeeRow
              employee={employee}
              index={index}
              search={search}
              handleAssign={handleAssign}
              listToAssign={listToAssign}
            />
          ))}
        </table>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

function getBaseStyle(variableColor) {
  return css({
    paddingBottom: '200px',
    '.assign-employee': {
      background: '#fff',
      paddingLeft: '20px',
      width: '834px',
    },
    '.select__wrapper': {
      display: 'flex',
      gap: '10px',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.top__wrapper': {
      display: 'flex',
      gap: '30px',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: '20px',
      paddingRight: '20px',
      paddingBottom: '20px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
      height: '20px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.project__name': {
      fontSize: '13px',
      margin: 0,
    },
    table: {
      width: '791px',
    },
    '.project__row': {
      position: 'relative',
      height: '40px',
    },
    '.name__td': {
      paddingLeft: '16px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '650px',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.group__row': {
      position: 'relative',
      height: '40px',
      background: hexToRGBAWithOpacity(variableColor, 0.25),
    },
    '.light': {
      background: hexToRGBAWithOpacity(variableColor, 0.1),
    },
    '.checkmark__td': {
      textAlign: 'center',
      minWidth: '80px',
    },
    '.page-switcher-base': {
      paddingBottom: '20px',
      marginBottom: '20px',
    },
    '.name__cell': {
      paddingLeft: '16px',
    },
    '.full__name': {
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      top: '40px',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '550px',
    },
    '.edit__cell': {
      display: 'flex',
      gap: '4px',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
    '.template__row': {
      height: '40px',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBAWithOpacity(variableColor),
    },
    '.info__wrapper': {
      position: 'absolute',
      right: '15px',
      top: '-3px',
    },
    '.info__icon': {
      cursor: 'pointer',
    },
    '.sort__icon': {
      cursor: 'pointer',
      height: '17px',
    },
    '@media(max-width: 1050px)': {
      '#search': {
        width: '264px',
      },
      '.top__wrapper': {
        paddingRight: 0,
        gap: '8px',
      },
      '.assign-employee': {
        width: '100%',
        paddingRight: '23px',
      },
      table: {
        width: '100%',
      },
      '.name__td': {
        maxWidth: '204px',
      },
    },
  })
}
