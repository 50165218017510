angular.module('ccs').directive('gettarget', gettarget)

function gettarget($log) {
  return {
    restrict: 'A',
    link: function (scope, element) {
      element.bind('load', function () {
        if (element.width() > 0) {
          var textInput =
            element[0].nextElementSibling.nextElementSibling.nextElementSibling
          textInput.style.width = element.width() + 'px'
          $(window)
            .resize(function () {
              var newWidth = element.width()
              textInput.style.width = newWidth + 'px'
            })
            .resize()
        }
      })
      element.bind('error', function () {
        $log.warn('image could not be loaded')
      })
    },
  }
}
