import * as React from 'react'
import { css } from '@emotion/react'
import { DateInputField } from '@/react/components'
import moment from 'moment'

export function DateInput(props: any) {
  const onChange = (value: moment.Moment) => {
    preValidationErrorHider(props.name)
    props.formik.setFieldValue(
      props.name,
      !value ? '' : value.format('yyyy-MM-DD'),
      true,
    )
  }

  return (
    <div className="inputBase" css={[fieldStyle, props.width]}>
      <label id={props.name} htmlFor={props.name}>
        {props.header}
      </label>
      <DateInputField
        defDate={moment(props.formik.values[props.name], 'yyyy-MM-DD')}
        ohChange={onChange}
        width={'100%'}
      />
    </div>
  )
}

function preValidationErrorHider(elementId) {
  const element = document.getElementById(elementId)
  if (element.classList.contains('error')) {
    element.classList.add('error-hide')
  }
}

const fieldStyle = css({
  display: 'flex',
  marginTop: '15px',
  maxHeight: '60px',
  flexDirection: 'column',
  label: {
    width: '100%',
    fontFamily: [
      'open sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    fontWeight: 'bold',
    fontSize: '13px',
  },
  input: {
    width: '100%',
    backgroundColor: '#FFFFFF',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0',
    padding: 6,
    fontSize: '14px',
  },
  '.error': {
    borderColor: 'red',
  },
  '.error.error-hide': {
    borderColor: '#e5e6e7',
  },
})
