import { resolvers } from '../resolvers'

/** @ngInject */
export function adminUsersConfig($stateProvider) {
  $stateProvider
    .state('admin.users', {
      abstract: true,
      url: '/users',
      templateUrl: 'app/views/common/uiview.html',
      data: { pageTitle: 'User Administration' },
    })
    .state('admin.users.list', {
      url: '/users?pageNumber&order&reverse&search',
      templateUrl: 'app/views/user_list.html',
      controller: 'UsersListCtrl',
    })
    .state('admin.users.add', {
      url: '/add',
      templateUrl: 'app/views/user_form.html',
      controller: 'UsersEditCtrl',
    })
    .state('admin.users.bulkUpload', {
      url: '/bulkUpload?previousPageNumber&previousOrder&previousReverse&previousSearch',
      templateUrl: 'app/views/users_bulk_upload.html',
      controller: 'UsersBulkUploadCtrl',
    })
    .state('admin.users.edit', {
      url: '/:userID?previousPageNumber&previousOrder&previousReverse&previousSearch',
      templateUrl: 'app/views/user_form.html',
      controller: 'UsersEditCtrl',
    })
    .state('admin.users.permissions', {
      url: '/users/permissions',
      templateUrl: 'app/views/user_permissions.html',
      controller: 'UsersPermissionsCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
    .state('admin.users.editProjects', {
      url: '/:userID/projects?previousPageNumber&previousOrder&previousReverse&previousSearch&pageNumber&order&reverse',
      templateUrl: 'app/views/user_projects_form.html',
      controller: 'UserProjectsEditCtrl',
      resolve: resolvers.adminOnlyViewResolver,
    })
}
