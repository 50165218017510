import * as Icons from '../../webpack.icons.js'

import { validateFileName } from '@/utils'

angular
  .module('ccs')
  .controller(
    'TrainingTopicContentFileCtrl',
    function (
      $scope,
      $state,
      $rootScope,
      Api,
      topicContent,
      file,
      quizExist,
      hoExist,
      editTopic,
      topicOrder,
      maxOrder,
      $timeout,
      CurrentUser,
      $log,
    ) {
      $log.debug('TrainingTopicContentFileCtrl')

      //initialize myDatePicker
      if (file) {
        if (file.expiration_date) {
          //get it into a format that will work with the date picker
          $scope.myDatePicker = new Date(file.expiration_date)
        } else {
          $scope.myDatePicker = null
        }
      }

      var editing = editTopic

      var fileChanged = false

      $scope.quizExist = quizExist
      $scope.hoExist = hoExist
      $scope.isAnotherFileUploaded = false
      $scope.disableButton = false

      $scope.loadIcon = function (icon) {
        return Icons.icons[icon]
      }

      $scope.contentTypes = [
        { id: 0, icon: 'video', title: 'Video Content', value: 'video' },
        { id: 1, icon: 'pdf', title: 'Pdf Document', value: 'pdf' },
        { id: 2, icon: 'ppt', title: 'Power Point Document', value: 'ppt' },
        { id: 3, icon: 'ho', title: 'Hands-on', value: 'ho' },
        { id: 3, icon: 'quiz', title: 'Quiz', value: 'quiz' },
      ]

      function getTopicDate() {
        if (editTopic) {
          Api.TopicContent.byID(topicContent, (resp) => {
            if (resp) {
              $scope.topicContent = resp
              if (
                topicContent === $scope.topicContent.id &&
                $scope.topicContent.content_type === 'ho'
              ) {
                $scope.hoExist = false
              }
              let selected = $scope.topicContent.content_type
              $scope.topicContent.content_type = {}
              $scope.topicContent.content_type = $scope.contentTypes.filter(
                function (e) {
                  return e.value == selected
                },
              )
              $scope.topicContent.content_type =
                $scope.topicContent.content_type[0]
              $scope.getAcceptedFilesFormats(
                $scope.topicContent.content_type.value,
              )
              $scope.dzConfig.acceptedFiles = $scope.acceptedFilesFormats
            }
          })
        } else {
          $scope.topicContent = topicContent
        }
        if ($scope.topicContent) {
          $scope.disabled = $scope.topicContent.attachment ? false : true
          $scope.topicContent.attachment_info =
            file || $scope.topicContent.id
              ? $scope.topicContent.attachment_info
              : { client: CurrentUser.getClientId() }
          $scope.filename_only = ''
          $scope.acceptedFilesFormats = ''
        }
      }

      getTopicDate()

      $scope.save = function () {
        $scope.disableButton = true
        if (editing) {
          $scope.topicContent.content_type =
            typeof $scope.topicContent.content_type === 'string'
              ? $scope.topicContent.content_type
              : $scope.topicContent.content_type.value
          Api.TopicContent.patch($scope.topicContent, function (resp) {
            if (resp) {
              $scope.updateTopic($scope.idToUpdate)
              $scope.closeThisDialog()
            }
          })
        } else {
          // $scope.topicContent.content_type = $scope.topicContent.content_type.value;
          Api.TopicContent.post($scope.topicContent, function (resp) {
            if (resp) {
              $scope.updateTopic($scope.idToUpdate)
              $scope.closeThisDialog()
              $scope.topicContent.id = resp.id
            }
          })
        }
      }

      $scope.close = function () {
        if (editTopic) {
          Api.TopicContent.byID($scope.topicContent.id, (resp) => {
            if (resp) {
              $scope.updateTopic($scope.idToUpdate)
              $scope.closeThisDialog()
            }
          })
        } else {
          $scope.closeThisDialog()
        }
      }

      $scope.getAcceptedFilesFormats = function (type) {
        var typeValue = typeof type === 'string' ? type : type.value
        if (typeValue === 'pdf') {
          $scope.acceptedFilesFormats = 'application/pdf'
        } else if (typeValue === 'ppt') {
          $scope.acceptedFilesFormats =
            'application/vnd.openxmlformats-officedocument.presentationml.presentation,.ppt,.pptx,application/vnd.ms-powerpoint,.pptx'
        } else if (typeValue === 'video') {
          // $scope.acceptedFilesFormats = 'video/mp4,video/x-flv,video/x-msvideo,video/quicktime,video/*';
          $scope.acceptedFilesFormats =
            'video/mp4,video/webm,audio/ogg,video/quicktime'
        } else {
          $scope.typeValue = false
          return false
        }

        $timeout(function () {
          $scope.dzConfig.acceptedFiles = $scope.acceptedFilesFormats
          $scope.typeValue = type
        }, 0)

        $scope.typeValue = false

        //////////Clear dropzone/////////////////////////////
        if (
          !$scope.topicContent.attachment_info ||
          $scope.topicContent.attachment_info.file === ''
        ) {
          $timeout(function () {
            $('.dz-preview').hide()
          }, 0)
        }
        /////////////////////////////////////////////////////
      }

      $scope.dzConfig = {
        parallelUploads: 1,
        acceptedFiles: $scope.acceptedFilesFormats,
        url: '#',
        maxFiles: 1,
        maxFileSize: 750,
        maxFilesize: 750,
        maxThumbnailFilesize: 750,
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        init: function () {
          //if we are editing, we need to restore the existing file as a MockFile
          if (editing) {
            //get the filename to render it on the DropZone preview.
            // var filename_only = $scope.attachment.file.file.split(/[/]+/).pop();
            // $scope.filename_only = ($scope.topicContent.attachment_info) && ($scope.topicContent.attachment_info.file.split(/[/]+/).pop() || $scope.topicContent.attachment_info.file.file.split(/[/]+/).pop());
            if (
              $scope.topicContent.attachment_info &&
              $scope.topicContent.attachment_info.client
            ) {
              $scope.filename_only =
                $scope.topicContent.attachment_info.file.file
                  .split(/[/]+/)
                  .pop()
            } else {
              $scope.topicContent.attachment_info &&
                ($scope.filename_only = $scope.topicContent.attachment_info.file
                  .split(/[/]+/)
                  .pop())
              if (!$scope.topicContent.attachment_info) {
                $scope.filename_only = ''
                $scope.topicContent.attachment_info = {}
                $scope.topicContent.attachment_info.file = ''
              }
            }

            var dz = this

            //the size isn't in the database, set it arbitrarily then remove it from the DOM later.
            var mockFile = { name: $scope.filename_only, size: 1337 }

            if ($scope.filename_only !== '') {
              dz.emit('addedfile', mockFile)
              dz.files.push(mockFile)
              dz.emit('complete', mockFile)
            } else {
              dz.emit('addedfile', mockFile)
              dz.emit('complete', mockFile)
            }

            //Remove the size information, since it is not stored on the server
            var fileSizeElement =
              mockFile.previewElement.querySelector('.dz-size')
            fileSizeElement.parentNode.removeChild(fileSizeElement)
          } else {
            //do nothing
          }

          //For the backfilled mockfiles
          //https://github.com/enyo/dropzone/issues/279
          this.on('addedfile', function () {
            if (this.files[1] != null) {
              $scope.isAnotherFileUploaded = true
              this.removeFile(this.files[0])
            }
          })
          this.on('removedfile', function () {
            if ($scope.isAnotherFileUploaded == false) {
              this.removeAllFiles()
              setTimeout(function () {
                $scope.$apply(function () {
                  $scope.topicContent.attachment_info = {}
                  $scope.topicContent.attachment_info.file = ''
                  $scope.topicContent.attachment = null
                  $scope.disabled = true
                })
              }, 10)
            }
          })
        },
      }

      $scope.dzAddedFile = function (file) {
        const fileType =
          typeof $scope.topicContent.content_type === 'string'
            ? $scope.topicContent.content_type
            : $scope.topicContent.content_type.value
        const maxFileSize = sizeForFiles(fileType)
        const errors = validateFileName(
          file.name.substring(0, file.name.lastIndexOf('.')),
        )
        if (
          ($scope.acceptedFilesFormats.indexOf(file.type) !== -1 ||
            file.type === $scope.acceptedFilesFormats ||
            file.name.split('.').pop() === $scope.acceptedFilesFormats) &&
          file.size < 1024 * 1024 * maxFileSize &&
          errors === false
        ) {
          $scope.topicContent.attachment_info.file = file
          fileChanged = true
          $scope.addFile()
          $('.dz-progress').css('display', 'block')
        } else {
          const errorDisplays = document.querySelectorAll('.dz-error-message')
          const activeErrorDisplay = errorDisplays[errorDisplays.length - 1]
          document.querySelectorAll('.dz-error-mark')[
            errorDisplays.length - 1
          ].style.opacity = '1'
          activeErrorDisplay.style.opacity = '1'
          if (
            ($scope.acceptedFilesFormats.indexOf(file.type) !== -1 ||
              file.type === $scope.acceptedFilesFormats ||
              file.name.split('.').pop() === $scope.acceptedFilesFormats) &&
            file.size > 1024 * 1024 * maxFileSize
          ) {
            $(activeErrorDisplay)
              .find('span')
              .text(function () {
                return (
                  'File is too big (' +
                  Math.round(file.size / 1024 / 10.24) / maxFileSize +
                  'MiB). Max filesize: ' +
                  maxFileSize +
                  ' MiB.'
                )
              })
          } else if (
            ($scope.acceptedFilesFormats.indexOf(file.type) == -1 ||
              file.type !== $scope.acceptedFilesFormats ||
              file.name.split('.').pop() !== $scope.acceptedFilesFormats) &&
            file.size > 1024 * 1024 * maxFileSize
          ) {
            $(activeErrorDisplay)
              .find('span')
              .text(function () {
                return (
                  'You can`t upload file of this type; File is too big (' +
                  Math.round(file.size / 1024 / 10.24) / maxFileSize +
                  'MiB). Max filesize: ' +
                  maxFileSize +
                  ' MiB.'
                )
              })
          } else if (errors.length > 0) {
            $(activeErrorDisplay)
              .find('span')
              .text(function () {
                return errors.join('\n')
              })
          } else {
            $(activeErrorDisplay)
              .find('span')
              .text('You can`t upload file of this type;')
          }
        }
      }

      $scope.dzRemovedFile = function () {}

      //$scope.addFile is the submit action on the form.
      $scope.addFile = function () {
        //preprocess the date picker to convert it to a date storable by the API
        if ($scope.myDatePicker) {
          //put it in a string that can be stored in the database
          var expd =
            ('0' + ($scope.myDatePicker.getMonth() + 1)).slice(-2) +
            '/' +
            ('0' + $scope.myDatePicker.getDate()).slice(-2) +
            '/' +
            $scope.myDatePicker.getFullYear()
          if (file) {
            //update the file date on the page behind the popup if we
            //actually update it.
            file.expiration_date = expd
          }
        } else {
          var expd = null
        }

        var updateData = {
          topic: $scope.topicContent.topic,
          attachment: $scope.topicContent.attachment || '',
          // attachment_info: $scope.topicContent.attachment_info || '',
          content_type: $scope.topicContent.content_type,
          name: $scope.topicContent.name,
          description: $scope.topicContent.description || '',
          completion_code: $scope.topicContent.completion_code || '',
          quiz_name: $scope.topicContent.quiz_name || '',
        }

        if (editing) updateData.id = $scope.topicContent.attachment

        if (fileChanged) {
          Api.uploadFile(
            $scope.topicContent.attachment_info.file,
            {},
            function (data) {
              updateData.attachment = data.id
              $scope.topicContent.attachment = data.id
              $scope.topicContent.attachment_info.file = data
              $scope.disabled = false
              $scope.isAnotherFileUploaded = false
              $('.dz-progress').css('display', 'none')
            },
          )
        } else {
          Notification.warning('No Image Selected.')
        }
      }

      //Begin stuff for date picker//
      $scope.endPopup = {
        opened: false,
      }

      $scope.startOptions = {
        formatYear: 'yy',
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.openEnd = function () {
        $scope.endPopup.opened = true
      }
      //End stuff for date picker//
    },
  )

function sizeForFiles(fileFormat) {
  switch (fileFormat) {
    case 'ppt':
      return 25
    case 'video':
      return 750
    default:
      return 100
  }
}
