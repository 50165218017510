angular
  .module('ccs')
  .controller(
    'QuestionCustomCategoryCtrl',
    function ($scope, $stateParams, Api, app, $state, CurrentUser, $log) {
      $log.debug('QuestionCustomCategoryCtrl')

      $scope.iter = []
      $scope.clientAppQuestion = null
      $scope.totalItems = 0
      $scope.pageNumber = 1
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : ''
      $scope.previousCategory = $stateParams.previousCategory
        ? $stateParams.previousCategory
        : ''
      $scope.order = 'name'
      $scope.app = app
      $scope.active = true
      $scope.parent_category_id = null

      const questionID = $stateParams.question

      $scope.breadcrumbs = [
        { title: app.name },
        {
          title: 'Questions',
          url: 'app.questions.list({app: ' + app.id + '})',
        },
      ]

      function categoryQuery(page) {
        var query = {
          custom: true,
          application: app.id,
          page: page || $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          page_size: 20,
          order: $scope.order,
          annotate_question: questionID,
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return query
      }

      $scope.tableHeaders = [
        { key: 'name', display: app.category + ' Name', sortable: true },
        { key: 'active_for_question', display: 'Assign', sortable: true, toggleAllBtn: true },
      ]

      function getCategories() {
        Api.Categories.get(categoryQuery(), function (resp) {
          $scope.totalItems = resp.count
          $scope.iter = resp.results.map(function (cat) {
            cat.active_for_question =
              $scope.clientAppQuestion.custom_categories.indexOf(cat.id) > -1
            return cat
          })
        })
      }

      //This is only used for toggle all.
      function getEveryCustomCategory(cb, categories, page) {
        categories = categories || []
        page = page || 1

        Api.Categories.get(categoryQuery(page), (resp) => {
          categories = categories.concat(resp.results)

          if (resp.next) {
            return getEveryCustomCategory(cb, categories, ++page)
          } else {
            return cb(categories)
          }
        })
      }

      function getData() {
        Api.ClientAppQuestions.get(
          {
            application: app.id,
            client: CurrentUser.getClientId(),
            question: questionID,
          },
          function (resp) {
            if (resp.results.length) {
              $scope.clientAppQuestion = resp.results[0]
              $scope.parent_category_id = resp.results[0].parent_category
              getCategories()
              getParentCustomCategoryById($scope.parent_category_id)
            } else {
              Api.ClientAppQuestions.post(
                {
                  question: questionID,
                  application: app.id,
                  client: CurrentUser.getClientId(),
                },
                function (resp) {
                  $scope.clientAppQuestion = {
                    ...resp,
                    custom_categories: [],
                  }
                  getCategories()
                },
              )
            }
          },
        )
      }

      getData()

      $scope.toggleAll = () => {
        if ($scope.active) {
          getEveryCustomCategory(function (categories) {
            Api.ClientAppQuestions.patch(
              {
                id: $scope.clientAppQuestion.id,
                custom_categories: categories.map((p) => p.id),
                search: $scope.search,
              },
              () => {
                getData()
              },
            )
          })
        } else {
          Api.ClientAppQuestions.patch(
            {
              id: $scope.clientAppQuestion.id,
              custom_categories: [],
              search: $scope.search,
            },
            () => {
              getData()
            },
          )
        }
      }

      $scope.toggleActive = function (cat) {
        if (cat.active_for_question) {
          $scope.clientAppQuestion.custom_categories.push(cat.id)
        } else {
          $scope.clientAppQuestion.custom_categories =
            $scope.clientAppQuestion.custom_categories.filter(function (catID) {
              return catID !== cat.id
            })
        }

        Api.ClientAppQuestions.patch(
          {
            id: $scope.clientAppQuestion.id,
            custom_categories: $scope.clientAppQuestion.custom_categories,
          },
          function (resp) {},
        )
      }

      $scope.changePage = function () {
        getCategories()
        getParentCustomCategoryById($scope.parent_category_id)
      }

      $scope.backToSearch = function () {
        $state.go(
          'app.questions.list',
          {
            categoryId: $scope.previousCategory,
            appID: app.id,
            pageNumber: $scope.previousPageNumber,
            search: $scope.previousSearch,
          },
          { reload: false },
        )
      }

      Api.Questions.byID(questionID, (resp) => {
        $scope.question_category = resp
        $scope.active_for_question_category = true
      })

      function getParentCustomCategoryById(id) {
        if (!$scope.parent_category_id) return
        Api.Categories.byID(id, (resp) => {
          $scope.parent_custom_category = resp
        })
      }

      getParentCustomCategoryById($scope.parent_category_id)
    },
  )
