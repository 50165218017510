type Props = {
  handleClick: () => void
  color?: string
  width?: string
  height?: string
}

export const InfoIcon = ({
  handleClick,
  color = '#3980CE',
  width = '8',
  height = '8',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      onClick={handleClick}
    >
      <path
        d="M4 6C4.11333 6 4.2084 5.9616 4.2852 5.8848C4.36173 5.80827 4.4 5.71333 4.4 5.6V3.99C4.4 3.87667 4.36173 3.78333 4.2852 3.71C4.2084 3.63667 4.11333 3.6 4 3.6C3.88667 3.6 3.79173 3.63827 3.7152 3.7148C3.6384 3.7916 3.6 3.88667 3.6 4V5.61C3.6 5.72333 3.6384 5.81667 3.7152 5.89C3.79173 5.96333 3.88667 6 4 6ZM4 2.8C4.11333 2.8 4.2084 2.7616 4.2852 2.6848C4.36173 2.60827 4.4 2.51333 4.4 2.4C4.4 2.28667 4.36173 2.1916 4.2852 2.1148C4.2084 2.03827 4.11333 2 4 2C3.88667 2 3.79173 2.03827 3.7152 2.1148C3.6384 2.1916 3.6 2.28667 3.6 2.4C3.6 2.51333 3.6384 2.60827 3.7152 2.6848C3.79173 2.7616 3.88667 2.8 4 2.8ZM4 8C3.44667 8 2.92667 7.89493 2.44 7.6848C1.95333 7.47493 1.53 7.19 1.17 6.83C0.81 6.47 0.525067 6.04667 0.3152 5.56C0.105067 5.07333 0 4.55333 0 4C0 3.44667 0.105067 2.92667 0.3152 2.44C0.525067 1.95333 0.81 1.53 1.17 1.17C1.53 0.81 1.95333 0.524933 2.44 0.3148C2.92667 0.104933 3.44667 0 4 0C4.55333 0 5.07333 0.104933 5.56 0.3148C6.04667 0.524933 6.47 0.81 6.83 1.17C7.19 1.53 7.47493 1.95333 7.6848 2.44C7.89493 2.92667 8 3.44667 8 4C8 4.55333 7.89493 5.07333 7.6848 5.56C7.47493 6.04667 7.19 6.47 6.83 6.83C6.47 7.19 6.04667 7.47493 5.56 7.6848C5.07333 7.89493 4.55333 8 4 8Z"
        fill={color}
        fill-opacity="0.5"
      />
    </svg>
  )
}
