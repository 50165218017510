import {
  ButtonElement,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import sortArrows from '@/assets/icons/sort-arrows.svg'
import sortArrowsActiveFirst from '@/assets/icons/sort-arrows-active.svg'
import sortArrowsActiveSecond from '@/assets/icons/sort-arrows-inactive.svg'
import { PartyObservedRow } from './PartyObservedRow'
import { hexToRGBAWithOpacity } from '@/utils/generalUtilities.js'
import { useMemo } from 'react'
import { useRouter } from '@/react/hooks'

export const PartyObservedModal = ({
  handleClose,
  partySearch,
  setPartySearch,
  setPartyOrder,
  partyOrder,
  isNaAllowed,
  setNaAllowed,
  setSelectedParties,
  selectedParties,
  partyObservedList,
  handlePartyOrderChange,
}) => {
  const { CurrentUser, $rootScope } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const { stateService } = useRouter()

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const handleAllChecked = () => {
    if (partyObservedList.length !== selectedParties?.length) {
      setSelectedParties(partyObservedList)
      setNaAllowed(true)
    } else {
      setSelectedParties([])
      setNaAllowed(false)
    }
  }

  const handleChecked = (name) => {
    if (selectedParties?.includes(name)) {
      setSelectedParties(selectedParties?.filter((party) => party !== name))
    } else {
      setSelectedParties([...selectedParties, name])
    }
  }

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        width: '46px',
        minWidth: '46px',
        paddingLeft: 0,
        textAlign: 'left',
        borderBottom: '2px solid #C6C6C6',
        paddingInline: 0,
        p: {
          width: '0px',
        },
        '.header-base': {
          display: 'flex',
          justifyContent: 'flex-end',
          paddingRight: '13px',
          alignItems: 'center',
        },
      },
      additionalInnerElement: (
        <div className="select__wrapper">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={
                selectedParties?.length === partyObservedList?.length &&
                partyObservedList?.length > 0
              }
              onChange={handleAllChecked}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <img
            src={
              partyOrder === 'active'
                ? sortArrowsActiveSecond
                : partyOrder === '-active'
                ? sortArrowsActiveFirst
                : sortArrows
            }
            className="sort__icon"
            alt="sort icon"
            onClick={() => {
              setPartyOrder(partyOrder === '-active' ? 'active' : '-active')
              handlePartyOrderChange(
                partyOrder === '-active' ? 'active' : '-active',
              )
            }}
          />
        </div>
      ),
    },
    {
      className: 'template',
      name: configurableNamesList?.observation_party_observed,
      type: 'name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        paddingLeft: '0px',
        width: '90%',
        borderBottom: '2px solid #C6C6C6',
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: 'fit-content',
        },
      },
    },
  ]

  return (
    <div className="modal__wrapper" css={styles}>
      <img
        className="close__icon"
        src={closeIcon}
        alt="close"
        onClick={handleClose}
      />
      <h4 className="modal__title">{`Select ${configurableNamesList?.observation_party_observed}`}</h4>
      <p className="modal__message">
        {`Final report will only display answers associated with the ${configurableNamesList?.observation_party_observed} chosen below`}
      </p>
      <main className="main__wrapper">
        <Search
          search={partySearch}
          searchCallback={setPartySearch}
          pageCallback={() => {}}
        />
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              updateUrl={false}
              order={partyOrder}
              popUpLocation={false}
              setOrder={setPartyOrder}
              callback={(newOrder) => {
                handlePartyOrderChange(newOrder)
                setPartyOrder(newOrder)
              }}
            />
            <tr
              style={{
                background: hexToRGBAWithOpacity(variableColor, 0.1),
              }}
            >
              <td className="checkmark__td">
                <label className="checkbox-container">
                  <input
                    type="checkbox"
                    checked={isNaAllowed}
                    onChange={() => {
                      setNaAllowed(!isNaAllowed)
                    }}
                  />
                  <span className="checkmark">
                    <ThinCheckmark color={variableColor} />
                  </span>
                </label>
              </td>
              <td>N/A (Include answers without party observed)</td>
            </tr>
            {partyObservedList.map((party, index) => (
              <PartyObservedRow
                party={party}
                key={index}
                index={index}
                handleChecked={handleChecked}
                selectedParties={selectedParties}
                partySearch={partySearch}
              />
            ))}
          </table>
        </div>
        <div className="button__wrapper">
          <ButtonElement
            buttonType="submit"
            text="Done"
            functionToTrigger={handleClose}
          />
        </div>
      </main>
    </div>
  )
}

const styles = css({
  position: 'absolute',
  background: '#fff',
  paddingInline: '45px',
  zIndex: 1,
  boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
  width: '510px',
  top: '140px',
  left: '150px',

  '.table__wrapper': {
    maxHeight: '320px',
    overflowY: 'scroll',
  },
  '.close__icon': {
    position: 'absolute',
    height: '16px',
    width: '16px',
    right: '13px',
    top: '5px',
    cursor: 'pointer',
  },
  '.modal__title': {
    display: 'flex',
    justifyContent: 'center',
    margin: 0,
    marginTop: '18px',
    color: '#656565',
    fontWeight: 600,
    fontSize: '14px',
  },
  '.modal__message': {
    fontWeight: 400,
    fontSize: '12px',
    color: '#656565',
    marginTop: '10px',
  },
  '#search': {
    width: '264px',
    height: '30px',
    marginBottom: '10px',
  },
  '.select__wrapper': {
    display: 'flex',
    gap: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: '9px',
  },
  '.sort__icon': {
    cursor: 'pointer',
    height: '17px',
  },
  '.button__wrapper': {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  '.checkmark__td': {
    paddingLeft: '9px',
  },
  '@media(max-width:1135px)': {
    left: '15px',
    width: '300px',
    paddingInline: '20px',
    '#search': {
      width: '150px',
    },
  },
})
