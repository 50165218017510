angular.module('ccs').directive('imageonload', imageonload)

function imageonload() {
  return {
    restrict: 'A',
    scope: {
      photo: '=',
      answer: '=',
    },
    link: function (scope, element) {
      $(document).ready(function () {
        element
          .bind('load', function () {
            timeOut(true, scope)
          })
          .bind('error', function () {
            timeOut(false, scope)
          })
      })
    },
  }
}

function timeOut(value, scope) {
  setTimeout(function () {
    scope.photo.imageLoaded = value
    scope.photo.in_progress = !value
    scope.photo.show_error = !value
    scope.$apply()
  }, 0)
}
