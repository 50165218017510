import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { InfoPopUp } from '@screens/MobileForms/SettingsPage/components'

type Props = {
  mobileForm: string
  project: string
  alertType: string
}

export const PopupBaseAndOpener = ({
  mobileForm,
  project,
  alertType = 'privacy',
}: Props) => {
  const { CurrentUser } = useAngularServices()

  const [popUp, setPopUp] = React.useState(null)

  const variableColor = CurrentUser.getClientSettings()
    .web_primary_color as string

  const baseStyle = getBaseStyle(variableColor)

  return (
    <div css={baseStyle}>
      <div className="alert-holder">{popUp}</div>
      <div
        className="icon"
        onClick={() => {
          if (popUp) {
            setPopUp(null)
          } else {
            setPopUp(
              <InfoPopUp
                setPopUp={setPopUp}
                mobileForm={mobileForm}
                project={project}
                alertType={alertType}
              />,
            )
          }
        }}
      >
        ?
      </div>
    </div>
  )
}

function getBaseStyle(variableColor) {
  return css({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '5px',
    '.alert-holder': {
      width: 0,
      display: 'flex',
      alignItems: 'center',
    },
    '.icon': {
      cursor: 'pointer',
      color: 'white',
      fontSize: 12,
      lineHeight: '13px',
      height: 13,
      width: 13,
      backgroundColor: variableColor,
      borderRadius: '50%',
    },
  })
}
