import React from 'react'
import { saveBase64AsFile } from '@/utils'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { PdfIcon, ViewIcon } from '@/react/componentAssets'

const DailyReportTableRow = (props) => {
  const { row, idx } = props
  const [pdfGrayOut, setPdfGrayOut] = React.useState<boolean>(false)
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const viewReportHandler = (id: number) => {
    stateService.go('app.timecard.daily_reports_view', {
      id,
      ...stateService.params,
    })
  }

  async function downloadPDF(id: number) {
    if (pdfGrayOut) return
    setPdfGrayOut(true)
    const { data: fileData } = await Api.get(
      'daily_reports/' + id + '/generate_pdf',
    )

    setTimeout(async () => {
      const { data } = await Api.getFileDataForDownload(fileData?.file_id)
      saveBase64AsFile(data?.content, data?.filename, data?.content_type)
      setPdfGrayOut(false)
    }, fileData?.time * 1000 + 1000)
  }

  return (
    <tr
      key={row.id}
      css={idx % 2 === 0 ? css({ backgroundColor: '#f9f9f9' }) : null}
    >
      <td className="textAlignLeft">{row.date}</td>
      <td className="textAlignLeft">
        {row?.project?.number} {row?.project?.name}
      </td>
      <td>
        {row.user.first_name} {row.user.last_name}
      </td>
      <td>{row.total_workers}</td>
      <td>{row.total_hours}</td>
      <td>
        <ViewIcon
          height={'20px'}
          color={variableColor}
          onClick={viewReportHandler.bind(null, row.id)}
        />
      </td>
      <td
        onClick={() => {
          if (!pdfGrayOut) downloadPDF(row.id)
        }}
      >
        <PdfIcon height={'20px'} color={pdfGrayOut ? 'gray' : variableColor} />
      </td>
    </tr>
  )
}

export default DailyReportTableRow
