import { resolvers } from '../../app/routes/resolvers'

/** @ngInject */
export function timeCardRouteConfig($stateProvider) {
  $stateProvider
    .state('app.timecard', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
      resolve: resolvers.appResolver,
    })
    .state('app.timecard.current_page', {
      url: '/timecard/current-page?pageNumber&order&search&supervisor_full_name&user_full_name&status_value',
      template: `<time-card-current-page></time-card-current-page>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {
        order: { dynamic: true, value: 'supervisor_full_name' },
      },
    })
    .state('app.timecard.timesheet', {
      url: '/timecard/timesheet?pageNumber&order&search&supervisor_full_name&user_full_name&stage_value&period',
      template: `<time-card-timesheet></time-card-timesheet>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {
        order: { dynamic: true, value: 'supervisor_full_name' },
      },
    })
    .state('app.timecard.voided', {
      url: '/timecard/timesheet/voided?pageNumber&order&search&supervisor_full_name&user_full_name&stage_value&period',
      template: `<time-card-timesheet-voided></time-card-timesheet-voided>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {
        order: { dynamic: true, value: 'supervisor_full_name' },
      },
    })
    .state('app.timecard.daily_reports', {
      url: '/timecard/daily-reports?pageNumber&order&search&job&user_full_name',
      template: `<daily-reports></daily-reports>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {},
    })
    .state('app.timecard.daily_reports_view', {
      url: '/timecard/daily-reports/:id/view?pageNumber&order&search&job&user_full_name',
      template: `<view-report></view-report>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {},
    })
    .state('app.timecard.settings', {
      url: '/timecard/settings',
      template: `<time-card-settings-page></time-card-settings-page>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {},
    })
    .state('app.timecard.tam', {
      url: '/timecard/tam?pageNumber&order&search&job&user_full_name&tm_number',
      template: `<time-and-materials-list></time-and-materials-list>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {
        order: { dynamic: true, value: '-date' },
      },
    })
    .state('app.timecard.tam_view', {
      url: '/timecard/tam_view/:key',
      template: `<time-and-material-view></time-and-material-view>`,
      controller: function () {},
      resolve: resolvers.appResolver,
      params: {},
    })
}
