import { HorizontalNavigation } from '@screens/components'
import { css } from '@emotion/react'
import { flatMap } from 'lodash'

import viewIcon from '/src/assets/icons/sds-connected-icons/view-small.svg'
import timesheetIcon from '/src/assets/icons/svg-gray-icons/time_timesheet_gray.svg'
import settingsIcon from '/src/assets/icons/settings.svg'
import reportsIcon from '/src/assets/icons/reports.svg'

export function getTimeCardNavigationData(
  activePage: string,
): HorizontalNavigation[] {
  const timeCard: {
    current: HorizontalNavigation
    timesheet: HorizontalNavigation
    tam: HorizontalNavigation
    daily_reports: HorizontalNavigation
    settings: HorizontalNavigation
  } = {
    current: {
      text: 'Current',
      redirectTo: 'app.timecard.current_page',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: viewIcon,
      onlyAdminView: true,
      supervisorView: true,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
    timesheet: {
      text: 'Timesheet',
      redirectTo: 'app.timecard.timesheet',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: timesheetIcon,
      onlyAdminView: true,
      supervisorView: true,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
    tam: {
      text: 'T&M Tickets',
      redirectTo: 'app.timecard.tam',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: reportsIcon,
      onlyAdminView: true,
      supervisorView: true,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
    daily_reports: {
      text: 'Daily Reports',
      redirectTo: 'app.timecard.daily_reports',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: reportsIcon,
      onlyAdminView: true,
      supervisorView: true,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
    settings: {
      text: 'Settings',
      redirectTo: 'app.timecard.settings',
      redirectParams: {},
      isActive: false,
      hasIcon: true,
      icon: settingsIcon,
      onlyAdminView: true,
      additionalStyles: css({
        'img.icon': {
          height: '14px !important',
          width: '14px !important',
        },
      }),
    },
  }
  timeCard[activePage].isActive = true

  return flatMap(timeCard)
}
