import * as React from 'react'
import { css } from '@emotion/react'

import { TextInputStyle } from '@screens/components'

export function TextField(props: any) {
  return (
    <div className="inputBase" css={[fieldStyle, props.width]}>
      <label htmlFor={props.name}>{props.header}</label>
      <input
        id={props.name}
        name={props.name}
        type="text"
        onChange={props.formik.handleChange}
        onClick={() => {
          preValidationErrorHider(props.name)
        }}
        value={props.formik.values[props.name]}
        className={props.formik.errors[props.name] ? 'error' : ''}
      />
    </div>
  )
}

function preValidationErrorHider(elementId) {
  const element = document.getElementById(elementId)
  if (element.classList.contains('error')) {
    element.classList.add('error-hide')
  }
}

const fieldStyle = css({
  marginTop: '15px',
  maxHeight: '60px',
  display: 'flex',
  flexDirection: 'column',
  label: {
    width: '100%',
    fontFamily: [
      'open sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    fontWeight: 'bold',
    fontSize: '13px',
  },
  input: {
    width: '100%',
    ...TextInputStyle,
  },
  '.error': {
    borderColor: 'red',
  },
  '.error.error-hide': {
    borderColor: '#e5e6e7',
  },
})
