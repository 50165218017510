import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormCustomCtrl',
    function (
      $scope,
      Api,
      app,
      $stateParams,
      $state,
      $uibModal,
      CurrentUser,
      $log,
    ) {
      $log.debug('FormCustomCtrl')

      $scope.titleMobileFormGroups = pluralize.singular(
        app.app_mobile_form_display,
      )
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = $scope.search ? true : false
      $scope.isClientOrAdmin = CurrentUser.isClientOrAdmin

      $scope.formSets = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.adding = false
      $scope.order = 'name'
      $scope.app = app
      $scope.tableHeaders = [
        { key: 'name', display: 'Group Name', sortable: true },
        {
          key: 'edit',
          display: 'Edit/View ' + app.app_mobile_form_display,
          centered: true,
        },
        {
          key: 'is_default',
          display: 'Default',
          sortable: true,
          centered: true,
          glyph: 'glyphicon-question-sign',
        },
        { key: 'delete', display: 'Delete', centered: true },
      ]

      function setQuery() {
        var query = {
          client: CurrentUser.getClientId(),
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
        }
        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return query
      }

      function getSets() {
        Api.FormSets.get(setQuery(), function (resp) {
          $scope.formSets = resp.results
          $scope.totalItems = resp.count
        })
      }

      function resetInput() {
        $scope.name = ''
      }

      $scope.delete = (group) => {
        Api.FormSets.delete(group, (resp) => {
          getSets()
        })
      }

      getSets()

      $scope.changePage = function () {
        getSets()
        $state.transitionTo(
          'app.forms.custom',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.toggleAdd = function () {
        $scope.adding = !$scope.adding
        resetInput()
      }

      $scope.save = function () {
        Api.FormSets.post(
          {
            name: $scope.name,
            application: app.id,
            mobile_forms: [],
            client: CurrentUser.getClientId(),
          },
          function (resp) {
            resetInput()
            $scope.toggleAdd()
            $scope.formSets.unshift(resp)
          },
        )
      }

      $scope.update = function (obj) {
        Api.FormSets.patch({ ...obj }, (resp) => {})
      }

      $scope.updateName = function (obj) {
        obj.isEditing = false
        $scope.update(obj)
      }

      $scope.editName = function (obj) {
        obj.isEditing = true
        $scope.editingObjWithName = obj.name
      }

      $scope.cancelNameEdit = function (obj) {
        obj.name = $scope.editingObjWithName
        obj.isEditing = false
        $scope.editingObjWithName = ''
      }

      $scope.glyphClick = function (header) {
        if (header.key == 'is_default') {
          $scope.modal_title = 'DEFAULT GROUP'
          $scope.modal_text =
            'A default group will automatically turn on for all existing and future ' +
            app.projects.toLowerCase() +
            '.'
          $scope.informational_only = true
          var modalInstance = $uibModal.open({
            templateUrl: 'app/views/common/flexible_modal.html',
            scope: $scope,
            bindToController: true,
            /** @ngInject */
            controller: function controller($scope) {
              $scope.dismiss = function () {
                modalInstance.close()
              }
            },
          })
        }
      }

      $scope.changeChecked = function (obj) {
        $scope.already_has_default_group = false
        $scope.intended_state = obj.is_default
        // Check if there is a default already
        if (obj.is_default == false) {
          // Then it's being disabled.
          $scope.modal_title = 'GROUP CONFIRMATION'
          $scope.modal_text =
            'Are you sure you want to deactivate this default group? It will no longer be turned on for new ' +
            app.projects.toLowerCase() +
            ' by default.'
          $scope.informational_only = false
          // Re-check it so it still shows as checked in the modal
          obj.is_default = true
        } else {
          // Then it's being enabled

          // First, check to see if there is default group already.
          for (var i = 0; i < $scope.formSets.length; i++) {
            if ($scope.formSets[i].is_default == true) {
              if ($scope.formSets[i].id != obj.id) {
                $scope.already_has_default_group = true
                //Uncheck it so that it doesn't show as checked behind the modal
                obj.is_default = false
                break
              }
            }
          }

          // Configure the flexible modal based on whether or not there is a default group already
          if ($scope.already_has_default_group == true) {
            $scope.modal_title = 'GROUP NOTIFICATION'
            $scope.modal_text =
              'You may only have one default group active at a time. Please disable the other group first.'
            $scope.informational_only = true
          } else {
            $scope.modal_title = 'GROUP CONFIRMATION'
            $scope.modal_text =
              'Are you sure you want to make this group active for all existing and future ' +
              app.projects.toLowerCase() +
              '?'
            $scope.informational_only = false
          }
        }

        var modalInstance = $uibModal.open({
          templateUrl: 'app/views/common/flexible_modal.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.dismiss = function () {
              // Revert the value of the box that was checked
              if ($scope.already_has_default_group == true) {
                // Don't do anything.  We already unchecked it when we made this determination.
              } else {
                // Then the user is dismissing the modal because they don't actually
                // want the group to be active.  Revert the check on the UI.
                obj.is_default = !$scope.intended_state
              }
              modalInstance.close()
            }

            $scope.save = function () {
              obj.is_default = $scope.intended_state
              $scope.update(obj)
              modalInstance.close()
            }
          },
          resolve: {
            obj: function () {
              return obj
            },
          },
        })

        modalInstance.result.catch(function () {
          obj.is_default = !$scope.intended_state
        })
      } // End of $scope.changeChecked
    },
  )
