angular.module('ccs').directive('issuegraph', issueGraph)

function issueGraph() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/nvd3_graph.html',
    link: function ($scope) {
      const toDate = (dateStr) => new Date(dateStr)

      function getIssueData(issues) {
        let dates = {}

        issues.forEach((issue) => {
          if (dates[issue.date_created]) {
            ++dates[issue.date_created]
          } else {
            dates[issue.date_created] = 1
          }
        })

        return [
          {
            key: 'Issues',
            color: $scope.getClientSettings().web_primary_color,
            area: true,
            values: Object.keys(dates).map((key) => {
              return {
                x: toDate(key).getTime(),
                y: dates[key],
              }
            }),
          },
        ]
      }

      $scope.$watch('issues', function (newValue) {
        if (newValue.length) {
          $scope.data = getIssueData(newValue)
        } else {
          $scope.data = []
        }
      })

      $scope.data = []

      $scope.$on('$destroy', function () {
        d3.selectAll('.nvtooltip').remove()
      })

      $scope.options = {
        chart: {
          type: 'lineChart',
          height: 450,
          margin: {
            top: 20,
            right: 100,
            bottom: 40,
            left: 100,
          },
          x: (d) => d.x,
          y: (d) => d.y,
          useInteractiveGuideline: true,
          xAxis: {
            tickFormat: function (d) {
              return d3.time.format('%x')(new Date(d))
            },
          },
          yAxis: {
            axisLabel: '# of Issues',
            tickFormat: function (d) {
              if (d % 1 === 0) {
                return d3.format('.f')(d)
              } else {
                return ''
              }
            },
          },
        },
        title: {
          enable: true,
          text: 'Number of Issues over time',
        },
      }
    },
  }
}
