export const ColoredArrowDown = ({
  variableColor,
  onClick,
  height = '8',
  width = '11',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1.04085 0L0 1.22022L5.82173 8L11.6435 1.21337L10.6026 0L5.82173 5.57326L1.04085 0Z"
        fill={variableColor}
      />
    </svg>
  )
}
