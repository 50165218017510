import * as React from 'react'
import { css } from '@emotion/react'
import Logo from '@/assets/images/zurich-logo.png'
import Eye from '@/assets/icons/eye-icon.svg'
import CrossedEye from '@/assets/icons/crossed-eye-icon.svg'
import { useState } from 'react'
import {
  LoginPopUp,
  DisclaimerPopupBody,
  ForgotPasswordBody,
  ForgotUsernameBody,
} from './components'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'

export const ZurichLoginPage = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [isPasswordVisabable, setPasswordVisability] = useState(false)
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [popupBody, setPopupBody] = useState(<></>)
  const [isError, setError] = useState(false)

  const onHandleUsername = () => {
    setIsOpenPopup(true)
    setPopupBody(<ForgotUsernameBody />)
  }

  const onHandlePassword = () => {
    setIsOpenPopup(true)
    setPopupBody(<ForgotPasswordBody />)
  }

  const onHandleDisclaimer = () => {
    setIsOpenPopup(true)
    setPopupBody(<DisclaimerPopupBody />)
  }

  const { Api, CurrentUser, $rootScope, Auth, Notification } =
    useAngularServices()

  const { stateService } = useRouter()

  const handleLogin = async () => {
    Notification.clearNotification()
    try {
      const { data: loginResponse, status } = await Api.login({
        username,
        password,
      })
      await CurrentUser.setToken(loginResponse.token)
      await Auth.setCookieToken(loginResponse.token, rememberMe)

      // $rootScope.authentication() also does the smart redirection already with the user set starting page
      await $rootScope.authentication()
      // stateService.go('index.dashboard', {})
    } catch {
      setError(true)
      Notification.clearNotification()
    }
  }

  const baseStyle = getBaseStyles()

  return (
    <div css={baseStyle}>
      <div className="login__wrapper">
        <div className="login__body">
          <div className="login__logo">
            <img src={Logo} alt="" />
          </div>
          {isError && (
            <p className="login__error">Invalid Username or Password</p>
          )}
          <div className="login__row">
            <div className="login__inputs-row">
              <input
                type="text"
                className="login__input"
                placeholder="Username"
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value)
                  setError(false)
                }}
              />
              <div className="login__password-row">
                <input
                  type={isPasswordVisabable ? 'text' : 'password'}
                  className="login__input"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                    setError(false)
                  }}
                />
                <img
                  src={isPasswordVisabable ? Eye : CrossedEye}
                  alt="eye"
                  onClick={() => {
                    setPasswordVisability(!isPasswordVisabable)
                  }}
                />
              </div>
            </div>
            <div onClick={handleLogin} className="login__button">
              Log in
            </div>
            <div className="login__checks">
              <div className="checks__checkbox">
                <input
                  className="checkbox"
                  type="checkbox"
                  id="remember"
                  onChange={(evt) => setRememberMe(evt.target.checked)}
                />
                <label htmlFor="remember" className="checkbox__title">
                  Remember me?
                </label>
              </div>
              <p className="login__questions">
                Forgot{' '}
                <span className="login__link" onClick={onHandleUsername}>
                  Username
                </span>{' '}
                /{' '}
                <span className="login__link" onClick={onHandlePassword}>
                  Password
                </span>
              </p>
            </div>
            <p className="login__info">
              By logging in, you agree to our{' '}
              <span className="login__link" onClick={onHandleDisclaimer}>
                Disclaimer
              </span>{' '}
              and have read and acknowledge our Global{' '}
              <a
                href="https://www.zurich.com/services/privacy"
                className="login__link"
              >
                Privacy Statement
              </a>
              .
            </p>
            <p className="login__copyright">© Copyright 2023</p>
          </div>
          {isOpenPopup && (
            <LoginPopUp onClose={setIsOpenPopup} body={popupBody} />
          )}
        </div>
      </div>
    </div>
  )
}

function getBaseStyles() {
  return css({
    '.login__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#2167ae',
      width: '100%',
      paddingBottom: '27.8vh',
      height: '100%',
      paddingTop: '5vh',
    },
    '@media (min-height: 1010px)': {
      '.login__wrapper': {
        paddingBottom: '32vh',
        paddingTop: '15vh',
      },
    },
    '.login__body': {
      boxSizing: 'border-box',
      padding: '27px 0px 27px 0px',
      marginTop: '100px',
      width: '542px',
      background: 'rgba(217, 217, 217, 0.8)',
      borderRadius: '20px',
      position: 'relative',
    },
    '.login__logo': {
      width: '333px',
      margin: '0 auto',
    },
    '.login__error': {
      textAlign: 'center',
      color: '#FF0505',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '11px',
      padding: '0px',
      margin: 0,
      marginBottom: '7px',
    },
    '.login__row': {
      width: '333px',
      margin: '0 auto',
    },
    '.login__inputs-row': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '27px',
    },
    '.login__input': {
      boxSizing: 'border-box',
      width: '100%',
      background: '#ffffff',
      borderRadius: '25px',
      fontFamily: 'Sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      padding: '0px 79px 0px 25px',
      color: '#515253',
      border: 'none',
      '&::placeholder': {
        color: '#999999',
      },
      '&:focus, &:active': {
        border: 'none',
        outline: 'none',
      },
    },
    '.login__password-row': {
      position: 'relative',
      width: '100%',
      img: {
        cursor: 'pointer',
        position: 'absolute',
        right: '16px',
        top: '15px',
      },
    },
    '.login__button': {
      width: '100%',
      margin: '0 auto',
      marginTop: '23px',
      height: '50px',
      background: '#2167ae',
      borderRadius: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    '.login__checks': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      fontSize: '10px',
      flexDirection: 'row',
      color: '#68696D',
      marginTop: '15px',
      '.checks__checkbox': {
        display: 'flex',
        gap: '5px',
      },
      '.checkbox': {
        width: '20px',
        height: '20px',
        background: '#FFFFFF',
        border: '0.5px solid #2167AE',
        borderRadius: '5px',
      },
      '.checkbox__title': {
        marginTop: '8px',
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '11px',
      },
    },
    '.login__questions': {
      margin: '0',
    },
    '.login__info': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '11px',
      textAlign: 'center',
      marginTop: '30px',
      color: '#68696D',
      width: '303px',
    },
    '.login__link': {
      cursor: 'pointer',
      color: '#2167AE',
    },
    '.login__copyright': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '11px',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      color: '#68696D',
      width: '303',
    },
  })
}
