import * as React from 'react'
import { css } from '@emotion/react'
import { AnalyticsIcon } from '../componentAssets/AnalyticsIcon'
import { useAngularServices } from './ReactAngularContext'

type Props = {
  headerText: string
  isAnalyticsIconVisible?: boolean
  iconLink?: string
}

export const CommonPageHeader: React.FC<Props> = ({
  headerText,
  isAnalyticsIconVisible = false,
  iconLink = '',
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  return (
    <div css={baseStyle} className="no-print">
      <h2 className="header__title">{headerText}</h2>
      {isAnalyticsIconVisible ? (
        <a href={iconLink} className="header__icon" target="_blank">
          <AnalyticsIcon color={variableColor} />
        </a>
      ) : null}
    </div>
  )
}

const baseStyle = css({
  background: '#fff',
  marginLeft: '-15px',
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  '.header__title': {
    paddingLeft: '30px',
    paddingTop: '24px',
    paddingBottom: '24px',
    margin: 0,
  },
  '.header__icon': {
    height: '20px',
    width: '20px',
  },
})
