const Regex_Url_Str =
  '(https?:\\/\\/)?' + // protocol
  '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
  '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
  '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
  '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
  '(\\#[-a-z\\d_]*)?'

const StringUtil = {
  isValidUrl: (str: string): boolean =>
    new RegExp(Regex_Url_Str, 'i').test(str),
  parseHyperlinks: (str: string): string =>
    str.replaceAll(
      new RegExp(Regex_Url_Str, 'gim'),
      '<a target="_blank" rel="noreferrer" href="$&">$&</a>',
    ),
}

export default StringUtil
