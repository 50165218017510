import { useAngularServices } from '@/react/components'
import { UIRouterState } from '@/react/types'

export const useRouter = (): { stateService: UIRouterState } => {
  const stateService = useAngularServices().$state

  // @TODO implement additional fields if needed according to docs:
  // https://ui-router.github.io/react/docs/latest/classes/_uirouter_core_router.uirouter.html
  return {
    stateService,
  }
}
