import * as React from 'react'
import { css } from '@emotion/react'
import { useDropzone } from 'react-dropzone'
import { useCallback, useState } from 'react'
import { LinearProgress } from '@mui/material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import closeIcon from '@/assets/icons/background-x.svg'
import { FileIcon } from '@/react/componentAssets/FileIcon'
import { BulkUploadIcon } from '@/react/componentAssets/BulkUploadIcon'

type Props = {
  addCallback?: any
  fileStorage: any
  fileSetStorage: any
  additionalStyles?: object
  isLoading: boolean
  fileError?: string
}

export const LogoDropzone = ({
  addCallback,
  fileStorage,
  fileSetStorage,
  additionalStyles = {},
  isLoading,
  fileError,
}: Props) => {
  const onDrop = useCallback((acceptedFiles) => {
    addCallback(acceptedFiles[0])
    fileSetStorage(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png',
  })

  function deleteFile() {
    fileSetStorage(null)
  }

  return (
    <div css={[fileUploadBase, additionalStyles]}>
      <div css={fileDisplayBase}>
        {fileStorage ? (
          <div css={fileDisplay}>
            <img
              src={closeIcon}
              className="close__icon"
              alt="X"
              onClick={() => {
                deleteFile()
              }}
            />
            <div className="file-name">
              {fileStorage?.image && (
                <img
                  src={fileStorage?.image}
                  alt="Logo"
                  className="file-picture"
                />
              )}
            </div>
            {isLoading && (
              <LinearProgress sx={{ height: '9px', borderRadius: '10px' }} />
            )}
            {fileError && <p className="error__message">{fileError}</p>}
          </div>
        ) : null}
      </div>
      <form
        encType="multipart/form-data"
        css={dropzoneBase}
        {...getRootProps()}
      >
        <input
          {...getInputProps()}
          disabled={false}
          type="file"
          onChange={(e) => {
            addCallback(e?.target?.files[0])
          }}
        />
        {fileStorage ? (
          ''
        ) : (
          <div className={'prop-name'}>
            <BulkUploadIcon className="upload-icon" width="94" height="77" />
            <div className="prop-info">
              <p className="prop-message">
                Click to choose a file or drag it here
              </p>
              <p className="prop-sub-message">
                Accepted File Types: .png, .jpg
              </p>
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

const fileUploadBase = css({
  margin: '0px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '561px',
  height: '312px',
  backgroundColor: '#F7F7F7',
  '.border-spread': {
    width: '312px',
    height: '312px',
  },
  '@media(max-width: 900px)': {
    width: '307px',
    height: '240px',
  },
})

const fileDisplayBase = css({
  position: 'absolute',
  zIndex: 1000,
})

const dropzoneBase = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundImage: 'none',
  border: 'none',
  width: '100%',
  height: '100%',
  '.prop-info': {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.prop-name': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    marginTop: '20px',
  },
  '.prop-message': {
    color: '#B3B3B3',
    fontSize: '24px',
    textAlign: 'center',
    margin: 0,
  },
  '.prop-sub-message': {
    fontSize: '12px',
    color: '#B3B3B3',
    fontWeight: 600,
    margin: 0,
  },
  '.prop-file-type': {
    margin: 0,
    color: '#B3B3B3',
    fontWeight: 600,
    fontSize: '8px',
  },
})

const fileDisplay = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  zIndex: 200,
  backgroundColor: 'white',
  bottom: '135px',
  left: '80px',
  borderColor: '#e5e6e7',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '10px',
  '@media(max-width: 990px)': {
    left: '35px',
  },
  '.close__icon': {
    cursor: 'pointer',
    width: '12px',
    alignSelf: 'flex-end',
    height: '12px',
  },
  '.file-name': {
    padding: '5px',
    backgroundColor: '#e5e6e7',
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.file-icon': {
    height: '25px',
    width: '25px',
  },
  '.file-picture': {
    width: '203px',
    height: '101px',
  },
  '.file__text': {
    maxWidth: '135px',
    color: '#565656',
    fontSize: '12px',
    margin: 0,
    maxHeight: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.delete-button': {
    cursor: 'pointer',
    borderRadius: '2px',
    border: '1px solid #eee',
    textDecoration: 'none',
    display: 'block',
    padding: '4px 5px',
    textAlign: 'center',
    color: '#aaa',
  },
  '.delete-button:hover': {
    color: '#676a6c',
  },
  '.error__message': {
    fontSize: '10px',
    color: '#C80404',
    margin: 0,
  },
})
