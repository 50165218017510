import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'

export const ErrorModal = ({ handleErrorReset, error_message }) => {
  return (
    <div css={baseStyle}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={handleErrorReset}
      />
      <div className="popup__title">{error_message}</div>
      <ButtonElement
        text="OK"
        buttonType="submit"
        additionalStyles={css({ marginBottom: '25px' })}
        functionToTrigger={handleErrorReset}
      />
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  background: '#FFF',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  zIndex: 2,
  width: '275px',
  top: 'auto',
  left: '90px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '@media(max-width: 1150px)': {
    left: '80px',
  },
  '.popup__cross': {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignSelf: 'flex-end',
    marginRight: '7px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  '.popup__title': {
    color: 'grey',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    marginTop: '8px',
    marginBottom: '11px',
    paddingInline: '20px',
  },
})
