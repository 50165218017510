import * as React from 'react'

export function VariousTable({ element }: any) {
  if (element.length < 2) {
    return <span>{element[0]}</span>
  } else {
    return (
      <div className={'various-block'}>
        <span>Various</span>
        <div className="hover-block">
          {element.map((option) => {
            return (
              <div key={option} className={'hover-element'}>
                {option}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
