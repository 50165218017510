import * as React from 'react'
import { css } from '@emotion/react'
import { ButtonElement } from '@/react/components'

import closeIcon from '/src/assets/icons/X Gray.svg'

type Props = {
  callback: any
  setAlert: any
}

export const CopyAlert: React.FC<Props> = ({ setAlert, callback }: Props) => {
  function copyLogic() {
    callback()
    setAlert([])
  }

  return (
    <section css={baseStyle}>
      <div
        className="background"
        onClick={() => {
          setAlert([])
        }}
      />
      <div className="alert">
        <div
          className="single-alert-row"
          css={css({
            justifyContent: 'right',
          })}
        >
          <img
            src={closeIcon}
            alt="close"
            css={css({
              height: 25,
              cursor: 'pointer',
            })}
            onClick={() => {
              setAlert([])
            }}
          />
        </div>
        <div
          className="single-alert-row"
          css={css({
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: 20,
            fontSize: 16,
            fontWeight: 600,
          })}
        >
          Copy Question?
        </div>
        <div
          className="single-alert-row"
          css={css({ justifyContent: 'right' })}
        >
          <ButtonElement
            buttonType={'submit'}
            text={'OK'}
            functionToTrigger={() => {
              copyLogic()
            }}
            width={'40%'}
            fontSize={'16px'}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  position: 'relative',
  height: 0,
  width: 0,
  '.alert': {
    backgroundColor: 'white',
    left: -300,
    position: 'absolute',
    right: 100,
    height: 'fit-content',
    width: 300,
    zIndex: 1000,
    '.single-alert-row': {
      width: '100%',
      display: 'flex',
    },
  },
  '.background': {
    width: '100vw',
    height: '100vh',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 400,
  },
})
