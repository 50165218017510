import * as React from 'react'
import { useAngularServices } from '@/react/components'
import { generateUUID } from '@/utils'
import { css } from '@emotion/react'

import { SinglePhoto } from './index'
import { flatMap } from 'lodash'
import { AddImageIcon } from '@/react/componentAssets'

export function PhotoSection({ answerLink, wholeAnswerLink, debouncedSend }) {
  const { Api } = useAngularServices()
  const [photoElementList, setPhotoElementList] = React.useState([])
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  async function addPhoto(value) {
    const promiseList = []

    const startingIndex = photoElementList.length
    flatMap(value.target.files).forEach((file, index) => {
      const blob = new Blob([file], {
        type: file.type,
      })
      const fileOfBlob = new File([blob], generateUUID())
      promiseList.push(Api.uploadImage(fileOfBlob, {}))
    })
    const responseArray = await Promise.all(promiseList)
    const elementArray = []
    responseArray.forEach((fileResponse, index) => {
      wholeAnswerLink.edited = true
      answerLink[startingIndex + index] = {
        id: fileResponse.data.id,
        image: fileResponse.data.image,
      }
      elementArray.push(
        <SinglePhoto
          key={startingIndex + index}
          fileUrl={fileResponse.data.image}
          index={startingIndex + index}
          answerLink={answerLink}
          wholeAnswerLink={wholeAnswerLink}
        />,
      )
    })

    setPhotoElementList([...photoElementList, ...elementArray])
    debouncedSend([
      { field: 'photos', value: answerLink },
      { field: 'edited', value: true },
    ])
  }

  React.useEffect(() => {
    const initialFields = []
    answerLink?.forEach((photo, idx) => {
      initialFields.push(
        <SinglePhoto
          key={idx}
          fileUrl={photo.image}
          index={idx}
          answerLink={answerLink}
          wholeAnswerLink={wholeAnswerLink}
          debouncedSend={debouncedSend}
        />,
      )
    })
    setPhotoElementList(initialFields)
  }, [])

  const baseStyle = getBaseStyle(variableColor)

  return (
    <section css={baseStyle}>
      <label className="file-input">
        <AddImageIcon height={'auto'} width={'30px'} color={variableColor} />
        <input
          multiple
          type="file"
          onChange={(value) => {
            addPhoto(value)
          }}
          accept="image/png, image/gif, image/jpeg"
        />
      </label>
      <div className="photoBlock">{photoElementList}</div>
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    width: '100%',
    '.file-input': {
      img: {
        width: 30,
      },
      cursor: 'pointer',
      color: variableColor + ' !important',
      input: { display: 'none' },
    },
    '.photoBlock': {
      display: 'flex',
      flexWrap: 'wrap',
    },
  })
}
