import { languageByKeys } from '../../utils'

angular
  .module('ccs')
  .controller(
    'MainController',
    function (
      $scope,
      $rootScope,
      $location,
      $timeout,
      $state,
      Api,
      uiPermissionService,
      CurrentUser,
      Auth,
      CurrentCompanyLogo,
      Notification,
      Configuration,
      VariableThemeColor,
    ) {
      CurrentCompanyLogo.reset()
      CurrentUser.reset()
      $scope.userIs = CurrentUser.is
      $scope.Notification = Notification
      $scope.getClientSettings = CurrentUser.getClientSettings

      this.title = Configuration.Title

      $scope.$watch(
        function () {
          return $location.path()
        },
        function () {
          monitoringCurrentUserRole()
          $rootScope.authentication()
        },
      )
      if (
        Configuration.appEnv === 'zurich-dev' ||
        Configuration.appEnv === 'zurich-production' ||
        Configuration.appEnv === 'zurich-master'
      ) {
        $('head').append(
          '<link id="dynamic-favicon" rel="shortcut icon" href="' +
            window.location.origin +
            '/assets/icons/faviconZurich.ico" />',
        )
      } else {
        $('head').append(
          '<link id="dynamic-favicon" rel="shortcut icon" href="' +
            window.location.origin +
            '/assets/icons/faviconRTRS.ico" />',
        )
      }

      function fetchCurrentUser(callback) {
        if (!CurrentUser.getId()) {
          Api.currentUser(function (response) {
            if (!response) return $location.path('/login')

            $rootScope.clientData = response.client
            $scope.authenticateUser(response)
            VariableThemeColor.mainColorSet(
              response.client.general_settings.colors.secondary_color,
            )

            if (callback) {
              callback(response.client.role)
            } else if ($location.path() === '/login') {
              redirectToProperPage(response.role)
            }
          })
        } else {
          if (callback) {
            callback()
          } else if ($location.path() === '/login') {
            $location.path('/')
          }
        }
      }

      function redirectToProperPage(role) {
        if (
          role === 'trainee_manager' ||
          role === 'trainee' ||
          role === 'client_user'
        ) {
          $rootScope.returnToState && $rootScope.isTrainingModuleOrGeneralUrl
            ? (window.location.href = $rootScope.returnToState)
            : $state.go('trainings.documentation')
        } else if (role === 'read_only') {
          $rootScope.returnToState && $rootScope.isTrainingModuleOrGeneralUrl
            ? (window.location.href = $rootScope.returnToState)
            : $state.go('trainings.program')
        } else {
          $rootScope.returnToState
            ? (window.location.href = $rootScope.returnToState)
            : $state.go('index.dashboard')
        }
      }

      function monitoringCurrentUserRole() {
        Api.currentUser(function (resp) {
          if (resp) {
            $scope.monitoredCurrentUserRole = resp.role
            $scope.currentUserRole = CurrentUser.getRole()
            if (
              $scope.monitoredCurrentUserRole &&
              $scope.currentUserRole &&
              $scope.currentUserRole !== $scope.monitoredCurrentUserRole
            ) {
              CurrentUser.setInstance(resp)
              window.location.reload(true)
              setTimeout(
                redirectToProperPage($scope.monitoredCurrentUserRole),
                2000,
              )
            }
          }
        })

        setTimeout(monitoringCurrentUserRole, 60000)
      }

      $rootScope.authentication = function (callback) {
        const cookieToken = Auth.getCookieToken()
        if ($location.path() === '/password-reset' && !cookieToken) return
        if ($location.path() === '/username-reset' && !cookieToken) return
        if ($location.path().indexOf('/share/') > -1) return
        if ($location.path().indexOf('/external_assessment/') > -1) return
        if ($location.path().indexOf('/reset') > -1 && !cookieToken) return
        if ($location.path().indexOf('/invite') > -1 && !cookieToken) return
        if ($location.path().indexOf('/request-invite') > -1 && !cookieToken)
          return
        if ($location.path().indexOf('/assigned/') > -1) return

        if (cookieToken) {
          fetchCurrentUser(callback)
        } else {
          $rootScope.$on(
            '$locationChangeStart',
            function (event, fromState, toState) {
              $rootScope.returnToState =
                toState.includes('login') || $rootScope.isUserLogOut
                  ? ''
                  : toState
              $rootScope.isTrainingModuleOrGeneralUrl =
                $rootScope.returnToState.includes('training') ||
                $rootScope.returnToState.includes('assigned') ||
                $rootScope.returnToState.includes('share') ||
                $rootScope.returnToState.includes('reset') ||
                $rootScope.returnToState.includes('invite')
            },
          )
          $location.path('/login')
        }
      }

      // @TODO: rework! Be careful with any shared template included via `ng-include` as they rely on local controller scope.
      $scope.authenticateUser = function (response) {
        response.token = Auth.getCookieToken()
        CurrentUser.setInstance(response)
        $scope.currentUser = CurrentUser.getInstance()
        $scope.getClientCompany = CurrentUser.getClientCompany

        $rootScope.showLessons = uiPermissionService.getShowLessons()
        $rootScope.permissionViewFullAdministration =
          CurrentUser.getRole() === 'admin'
        $rootScope.permissionViewAdministration =
          uiPermissionService.getPermissionViewAdministration()
        $rootScope.permissionViewCompanyNews =
          uiPermissionService.getPermissionViewCompanyNews()
        $rootScope.permissionViewFileCabinet =
          uiPermissionService.getPermissionViewFileCabinet()
        $rootScope.permissionViewRiskDashboard =
          uiPermissionService.getPermissionViewRiskDashboard()
        $rootScope.permissionViewMobileApps =
          uiPermissionService.getPermissionViewMobileApps()
        $rootScope.permissionViewMobileForms =
          uiPermissionService.getPermissionViewMobileForms()
        $rootScope.permissionViewTraining =
          uiPermissionService.getPermissionViewTraining()
        $rootScope.permissionViewTrainingProgram =
          uiPermissionService.getPermissionViewTrainingProgram()
        $rootScope.permissionViewProcedures =
          uiPermissionService.getPermissionViewProcedures()
        $rootScope.permissionViewProcessFlows =
          uiPermissionService.getPermissionViewProcessFlows()
        $rootScope.permissionEditOrDeletTrainingDocumentation =
          uiPermissionService.getPermissionEditOrDeletTrainingDocumentation()
        $rootScope.permissionDownloadReportDataOrCreateForm =
          uiPermissionService.getPermissionDownloadReportDataOrCreateForm()
        $rootScope.permissionViewCategories =
          uiPermissionService.getPermissionViewCategories()
        $rootScope.permissionViewQuestions =
          uiPermissionService.getPermissionViewQuestions()
        $rootScope.permissionAssignToolboxTalkToProject =
          uiPermissionService.getPermissionAssignToolboxTalkToProject()
        $rootScope.permissionAddNewTrainingAndUploadCSV =
          uiPermissionService.getPermissionAddNewTrainingAndUploadCSV()
        $rootScope.permissionViewFormGroups =
          uiPermissionService.getPermissionViewFormGroups()
        $rootScope.permissionViewCategoryGroups =
          uiPermissionService.getPermissionViewCategoryGroups()
        $rootScope.permissionViewToolboxTalkGroups =
          uiPermissionService.getPermissionViewToolboxTalkGroups()
        $rootScope.permissionViewMyQuestions =
          uiPermissionService.getPermissionViewMyQuestions()
        $rootScope.permissionCreateToolboxTalk =
          uiPermissionService.getPermissionCreateToolboxTalk()
        $rootScope.permissionToSeeToolboxTalk =
          uiPermissionService.getPermissionToSeeToolboxTalk()
        $rootScope.permissionAddCustomCategory =
          uiPermissionService.getPermissionAddCustomCategory()
        $rootScope.permissionViewDeleteCustomCategory =
          uiPermissionService.getPermissionViewDeleteCustomCategory()
        $rootScope.permissionUploadProcedure =
          uiPermissionService.getPermissionCreateProcedure()
        $rootScope.permissionAssignTraining =
          uiPermissionService.getPermissionAssignTraining()
        $rootScope.permissionToManageTraining =
          uiPermissionService.getPermissionToManageTraining()
      }

      $rootScope.$on('$stateChangeError', function () {
        Notification.danger('You are not allowed to create/edit this content.')
      })

      $rootScope.$on('ngDialog.opened', function (e) {
        $(document).on('keydown', function (evt) {
          var $target = $(evt.target || evt.srcElement)
          if (
            evt.keyCode == 8 &&
            !$target.is('input,[contenteditable="true"],textarea')
          ) {
            e.preventDefault()
            return false
          }
        })
      })

      function navHeightToWrapHeight() {
        let nav
        let pageWrapper
        const elementGetter = setInterval(function () {
          nav = document.getElementById('left-navbar')
          pageWrapper = document.getElementById('page-wrapper')
          if (nav && pageWrapper) {
            clearInterval(elementGetter)
            const navHeight = nav.offsetHeight
            const windowHeight = window.screen.height
            if (navHeight > windowHeight) {
              pageWrapper.style.minHeight = navHeight + 'px'
            } else {
              pageWrapper.style.minHeight = windowHeight + 'px'
            }
          }
        }, 200)
      }

      const isSafari = /^((?!chrome|android).)*safari/i.test(
        navigator.userAgent,
      )

      if (isSafari) {
        setTimeout(() => {
          navHeightToWrapHeight()
        }, 3000)
      } else {
        navHeightToWrapHeight()
      }

      function onNavVisualReaction() {
        setTimeout(() => {
          navHeightToWrapHeight()
        }, 200)
      }

      $scope.onNavVisualReaction = onNavVisualReaction

      const untranslatedCash = localStorage.getItem('untranslated-cash')
      const languageData = localStorage.getItem('language-data')

      if (untranslatedCash) {
        $scope.languageData = JSON.parse(untranslatedCash)
      } else {
        $scope.untranslatedCash = []
        localStorage.setItem('untranslated-cash', JSON.stringify([]))
      }
      if (languageData) {
        const parsedLanguageData = JSON.parse(languageData)
        if (!languageByKeys[parsedLanguageData.selected]) {
          parsedLanguageData.selected = 'origin'
        }
        localStorage.setItem(
          'language-data',
          JSON.stringify(parsedLanguageData),
        )
        $scope.languageData = parsedLanguageData
      } else {
        const setLanguageData = {
          selected: 'origin',
        }
        $scope.languageData = setLanguageData
        localStorage.setItem('language-data', JSON.stringify(setLanguageData))
      }
    },
  )
