import * as React from 'react'
import { css } from '@emotion/react'
import {
  ButtonElement,
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { getFirstAndLastWeekDate } from '@/react/components'
import { default as ReactSelect } from 'react-select'
import closeIcon from '/src/assets/icons/X Gray.svg'

export const CreateTimeSheetPopUp = ({ setPopUp, setPage, setReload }) => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const todayDate = new Date()

  const [supervisor, setSupervisor] = React.useState(0)
  const [user, setUser] = React.useState(0)
  const [supervisorsOptions, setSupervisorsOptions] = React.useState([])
  const [usersOptions, setUsersOptions] = React.useState([])

  async function fetchData() {
    const supervisors = []
    const users = []
    const { data } = await Api.Users.get({
      roles: 'field_employee,client_user,supervisor,client_admin',
      order: 'first_name,last_name',
      no_pagination: 'true',
    })

    data.results.forEach((user) => {
      if (!user.is_active) return
      if (user.role === 'client_admin' || user.role === 'supervisor') {
        supervisors.push({
          value: user.id,
          label: user.first_name + ' ' + user.last_name,
        })
      }
      users.push({
        value: user.id,
        label: user.first_name + ' ' + user.last_name,
      })
    })
    setUsersOptions(users)
    setSupervisorsOptions(supervisors)
  }

  async function sendTimesheet() {
    await Api.postWithParams('timesheets', {
      supervisor: supervisor,
      user: user,
      stage: 'o',
      application: stateService.params.app,
    })
    setPopUp(null)
    setReload(true)
    setPage(1)
  }

  async function checkForExistingTimeSheet(selected, type) {
    const listToReturn = []
    const { data: ids } =
      type === 'users'
        ? await Api.get('timesheets/get_available_users_ids', {
            supervisor_id: selected,
            period: getFirstAndLastWeekDate(todayDate),
          })
        : await Api.get('timesheets/get_available_supervisors_ids', {
            user_id: selected,
            period: getFirstAndLastWeekDate(todayDate),
          })
    const { data: usersList } = await Api.Users.get({
      roles:
        type === 'users'
          ? 'field_employee,client_user,supervisor,client_admin'
          : 'supervisor,client_admin',
      ids: ids.ids.join(','),
      order: 'first_name,last_name',
      no_pagination: 'true',
    })
    usersList.results.forEach((user) => {
      if (!user.is_active) return
      listToReturn.push({
        value: user.id,
        label: user.first_name + ' ' + user.last_name,
      })
    })
    if (type === 'users') setUsersOptions(listToReturn)
    if (type === 'supervisors') setSupervisorsOptions(listToReturn)
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  if (!usersOptions.length) return null

  return (
    <section css={baseStyle}>
      <div
        className="background"
        onClick={() => {
          setPopUp(null)
        }}
      />
      <div className="alert-holder">
        <div
          className="single-alert-row"
          css={css({
            justifyContent: 'right',
          })}
        >
          <img
            src={closeIcon}
            alt="close"
            css={css({
              height: 25,
              cursor: 'pointer',
            })}
            onClick={() => {
              setPopUp(null)
            }}
          />
        </div>
        <div className="header-row">
          <h3>Add Timesheet</h3>
        </div>
        <label>Employee:</label>
        <ReactSelect
          options={usersOptions}
          onChange={(value) => {
            setUser(value.value)
            checkForExistingTimeSheet(value.value, 'users')
          }}
          styles={dropdownOptionStyles(variableColor)}
          theme={(theme) => dropdownFiledTheme(theme, variableColor)}
        />
        <label className="second-dropdown">Supervisor:</label>
        <ReactSelect
          options={supervisorsOptions}
          onChange={(value) => {
            setSupervisor(value.value)
            checkForExistingTimeSheet(value.value, 'supervisors')
          }}
          styles={dropdownOptionStyles(variableColor)}
          theme={(theme) => dropdownFiledTheme(theme, variableColor)}
        />
        <div className="button-row">
          <ButtonElement
            height={'31px'}
            width={'91px'}
            fontSize={'12px'}
            text={'Save'}
            disabled={!(supervisor && user)}
            buttonType={'submit'}
            functionToTrigger={() => {
              sendTimesheet()
            }}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  '.background': {
    position: 'fixed',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
    zIndex: 500,
  },
  '.alert-holder': {
    position: 'fixed',
    boxShadow: '0 0 1px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 20%)',
    padding: 20,
    width: 400,
    zIndex: 1000,
    '.error-text': {
      marginTop: 10,
      color: 'red',
    },
    backgroundColor: 'white',
    '.header-row': {
      textAlign: 'center',
      marginBottom: 15,
    },
    '.single-alert-row': {
      width: '100%',
      display: 'flex',
      marginBottom: 10,
    },
    '.second-dropdown': {
      marginTop: 20,
    },
    '.button-row': {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'right',
    },
  },
})

function giveWhiteAndConsoleLog(state) {
  console.log(state)
  return 'white'
}
