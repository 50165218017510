import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useEffect, useRef } from 'react'

export const InfoModal = ({ setPopUpVisible }) => {
  const popupRef = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setPopUpVisible(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setPopUpVisible])
  return (
    <div css={baseStyle} ref={popupRef}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={() => {
          setPopUpVisible(false)
        }}
      />
      <div className="popup__title">
        A default group will automatically assign templates to all existing and
        future projects.
      </div>
      <ButtonElement
        text="OK"
        buttonType="submit"
        additionalStyles={css({ marginBottom: '14px' })}
        functionToTrigger={() => {
          setPopUpVisible(false)
        }}
      />
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  background: '#FFF',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  zIndex: 200000,
  width: '275px',
  right: '-50px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '.popup__cross': {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignSelf: 'flex-end',
    marginRight: '7px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  '.popup__title': {
    color: 'grey',
    textAlign: 'center',
    fontSize: '13px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    paddingTop: '28px',
    paddingInline: '44px',
    paddingBottom: '18px',
  },
})
