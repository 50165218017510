export const LimitedTimezonesForSelect = [
  {
    value: 'US/Central',
    label: 'CT',
  },
  {
    value: 'US/Eastern',
    label: 'ET',
  },
  {
    value: 'US/Mountain',
    label: 'MT',
  },
  {
    value: 'US/Pacific',
    label: 'PT',
  },
  {
    value: 'US/Alaska',
    label: 'AT',
  },
  {
    value: 'Pacific/Honolulu',
    label: 'HT',
  },
]
