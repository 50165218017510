import * as React from 'react'
import { css } from '@emotion/react'
import { default as ReactSelect, components } from 'react-select'
import { ChangeLanguagePopUp } from './ChangeLanguagePopUp'
import { languageByKeys } from '@/utils'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@/react/components'
import { flatMap } from 'lodash'

export const TranslationDropdown = () => {
  const [popUp, setPopUp] = React.useState(null)
  const [isVisible, setIsVisible] = React.useState(false)
  const { Api, Notification } = useAngularServices()

  const languageData = JSON.parse(localStorage.getItem('language-data'))
  const locallyStoredColor = localStorage.getItem('variable-color')
  const variableColor = locallyStoredColor ? locallyStoredColor : '#3981CD'

  async function checkIfWorking() {
    try {
      await Api.post('translation', {
        contents: ['Would you kindly?'],
        target: 'uk',
      })
      localStorage.setItem('currentlyTranslatable', 'true')
      setIsVisible(true)
    } catch (e) {
      console.log(e)
      localStorage.setItem('currentlyTranslatable', 'false')
      localStorage.setItem(
        'language-data',
        JSON.stringify({
          selected: 'origin',
        }),
      )
      Notification.clearNotification()
      setIsVisible(false)
    }
    Notification.clearNotification()
  }

  const options = [
    { label: 'Original', altLabel: 'Translate', value: 'origin' },
    ...flatMap(languageByKeys),
  ]
  const optionsByKey = {
    origin: { label: 'Original', altLabel: 'Translate', value: 'origin' },
    ...languageByKeys,
  }

  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      {props.data.altLabel || children}
    </components.SingleValue>
  )

  React.useEffect(() => {
    checkIfWorking()
  }, [])

  if (!isVisible) return <div css={css({ width: 140 })} />

  return (
    <section css={baseStyle}>
      <ReactSelect
        defaultValue={optionsByKey[languageData.selected]}
        options={options}
        components={{ SingleValue }}
        onChange={(value) => {
          setPopUp(<ChangeLanguagePopUp language={value} setPopUp={setPopUp} />)
        }}
        styles={dropdownOptionStyles(variableColor, '38px', true)}
        theme={(theme) => dropdownFiledTheme(theme, variableColor)}
      />
      <div className="alert-holder">{popUp}</div>
    </section>
  )
}

const baseStyle = css({
  width: 140,
  '.alert-holder': {
    width: 0,
    height: 0,
  },
})
