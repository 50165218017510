import { css } from '@emotion/react'

export const DisclaimerPopupBody = () => {
  const baseStyle = css({
    '.disclaimer': {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'justify',
      color: '#000',
      padding: '28px 0px',
    },
    '.disclaimer__title': {
      marginTop: '35px',
      fontWeight: 800,
      fontSize: '14px',
      lineHeight: '15px',
      margin: '0 auto',
      marginBottom: '10px',
    },
    '.disclaimer__p': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '13px',
    },
    '.disclaimer__p-uppercase': {
      fontSize: '12px',
      fontWeight: 600,
      lineHeight: '13px',
    },
    '@media(max-width: 500px)': {
      '.disclaimer__title': {
        fontSize: '12px',
      },
      '.disclaimer__p': {
        fontSize: '10px',
        lineHeight: '10px',
      },
      '.disclaimer__p-uppercase': {
        fontSize: '10px',
        lineHeight: '10px',
      },
    },
  })

  return (
    <div css={baseStyle}>
      <div className="disclaimer">
        <h1 className="disclaimer__title">Disclaimer</h1>
        <p className="disclaimer__p">
          Risk Audits should be administered as a program and the responsibility
          to create and implement each program lies within each company. The
          information contained in this platform is based upon information that
          is believed to be accurate but is provided without guarantee or
          representation as to accuracy. Advice, graphics, images and
          information contained in this app is presented for general educational
          and information purposes and to increase overall awareness and
          monitoring of risk
        </p>
        <p className="disclaimer__p">
          It is not intended to provide legal or other expert advice or
          services, and should not be considered exhaustive and the user should
          seek the advice of appropriate professionals. In no event shall Real
          Time Risk Solutions and its trustees, officers, loss, injury or risk
          (including, without limitation, incidental and consequential damages,
          personal injury/wrongful death, lost profits or damages) which is
          incurred or suffered as a direct or indirect result of the use of any
          of the material, advice, guidance or services on this platform,
          whether based on warranty, contract, tort or any other legal theory
          and whether or not Real Time Risk Solutions or any of its trustees,
          officers, or employees is advised of the possibility of such damages
        </p>
        <p className="disclaimer__p-uppercase">
          REAL TIME RISK SOLUTIONS, TO THE FULLEST EXTENT PERMITTED BY LAW,
          DISCLAIMS ALL WARRANTIES, EITHER EXPRESS OR IMPLIED, STATUTORY OR
          OTHERWISE, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
          MERCHANTABILITY, NON-INFRINGEMENT OF THIRD PARTIES RIGHTS AND FITNESS
          FOR PARTICULAR PURPOSE.
        </p>
      </div>
    </div>
  )
}
