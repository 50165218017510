import * as React from 'react'
import { getTranslatedString } from '@/utils'
import { css } from '@emotion/react'

export const ParagraphField = ({ fieldData }) => {
  const baseStyle = getBaseStyle()

  return (
    <section className="label-holder" css={baseStyle}>
      <label className="label_paragraph">
        {getTranslatedString(fieldData.name)}
      </label>
    </section>
  )
}

function getBaseStyle() {
  return css({
    marginBottom: 15,
    '.label_paragraph': {
      overflowWrap: 'break-word',
      whiteSpace: 'pre-wrap',
    },
  })
}
