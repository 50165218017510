import * as React from 'react'
import { singleHeaderTypes } from '@screens/components'
import { css } from '@emotion/react'
import { useAngularServices, SingleTableHeader } from '@components/index'
import { adminCheck } from '@/utils/elementChecks'
import { debounce } from 'lodash'

//TODO change props names (callback, popUpLocation)
type Props = {
  headers: singleHeaderTypes[]
  popUpLocation: boolean
  callback: any
  stickyElement?: boolean
  updateUrl?: boolean
  order?: string
  setOrder?: any
}

export const TableHeaders: React.FC<Props> = ({
  headers,
  popUpLocation = false,
  callback,
  stickyElement,
  updateUrl = true,
  order,
  setOrder,
}: Props) => {
  const { uiPermissionService } = useAngularServices()

  const adminStatus = uiPermissionService.getPermissionViewAdministration()

  const staticNavRef = React.useRef()
  const fixedNavRef = React.useRef()

  if (stickyElement) {
    window.addEventListener(
      'resize',
      debounce(() => {
        if (fixedNavRef.current && fixedNavRef.current.style) {
          fixedNavRef.current.style.width = window
            .getComputedStyle(staticNavRef.current)
            .getPropertyValue('width')
        }
      }, 100),
    )

    window.addEventListener('scroll', () => {
      if (
        fixedNavRef.current &&
        fixedNavRef.current.style &&
        staticNavRef.current
      ) {
        fixedNavRef.current.style.width = window
          .getComputedStyle(staticNavRef.current)
          .getPropertyValue('width')
        if (staticNavRef.current.getBoundingClientRect().top >= 0) {
          if (fixedNavRef.current.style.display !== 'none') {
            fixedNavRef.current.style.display = 'none'
          }
        } else {
          if (fixedNavRef.current.style.display !== 'flex') {
            fixedNavRef.current.style.display = 'flex'
          }
        }
      }
    })
  }

  const renderElements = [
    headers.map((header, idx) => {
      return adminCheck(
        header.adminOnly,
        adminStatus,
        <SingleTableHeader
          key={idx}
          className={header.className}
          name={header.name}
          listLocation={header.listLocation}
          type={header.type}
          multiType={header?.multiType}
          filterHeader={header.filterHeader}
          additionalStyles={header.additionalStyles}
          additionalInnerElement={header.additionalInnerElement}
          alternativePosition={header.alternativePosition}
          callback={callback}
          updateUrl={updateUrl}
          orderFromState={order}
          setOrder={setOrder}
        />,
      )
    }),
    popUpLocation ? <th css={popUpBase} /> : null,
  ]

  return [
    stickyElement ? (
      <thead key="sticky-nav" className="sticky-nav" css={stickyBase}>
        <tr className="sticky" ref={fixedNavRef}>
          {renderElements}
        </tr>
      </thead>
    ) : null,
    <thead key="static-nav" className="static-nav" ref={staticNavRef}>
      <tr>{renderElements}</tr>
    </thead>,
  ]
}

const popUpBase = css({
  width: 0,
})
const stickyBase = css({
  height: 0,
  width: '100%',
  display: 'block',
  '.sticky': {
    display: 'none',
    position: 'fixed',
    top: 0,
    width: '100%',
    backgroundColor: 'white',
    paddingTop: 15,
    borderColor: '#e7eaec',
    borderBottomStyle: 'solid',
    borderWidth: 2,
    zIndex: 2000,
  },
  th: {
    display: 'block',
    marginBottom: 0,
  },
  p: {
    marginBottom: 0,
  },
})
