import appModule from '@appModule'

function importTemplates(name) {
  const ctx = require.context('./views', true, /.*\.html$/)

  appModule.run(function ($templateCache) {
    ctx.keys().forEach((key) => {
      const k = key.replace('./', `app/${name}/`)
      $templateCache.put(k, ctx(key))
    })
  })
}

//import templates by module
importTemplates('views')
