angular.module('ccs').directive('severitydisplay', severityDisplay)

function severityDisplay() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/issue_severity_display.html',
    link: function ($scope) {
      const severities = {
        1: 'Low',
        2: 'Medium',
        3: 'High',
      }

      $scope.getSeverityDisplay = () => severities[$scope.issue.severity]
    },
  }
}
