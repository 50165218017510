import * as React from 'react'
import { css } from '@emotion/react'
import { SingleSignature } from '@screens/Observations/ViewTemplates/components/SingularSignature'

export function Signatures({ signatures }: any) {
  return (
    <div css={baseStyle}>
      <div className="signature-row">
        {signatures.map((signature, idx) => (
          <SingleSignature signature={signature} idx={idx}></SingleSignature>
        ))}
      </div>
    </div>
  )
}

const baseStyle = css({
  width: '100%',
  paddingBottom: 20,
  '.signature-row': {
    paddingTop: 10,
    '.signature': {
      maxWidth: '135px',
      marginRight: '2%',
      display: 'inline-block',
      verticalAlign: 'top',
      img: {
        width: '100%',
        height: '69px',
        border: '1.5px solid lightgray',
      },
    },
  },
  '.img-w': {
    position: 'relative',
  },
  '.signature-name': {
    margin: 0,
    paddingTop: 10,
    fontSize: '9px',
    color: 'black',
  },
})
