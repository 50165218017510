import * as React from 'react'
import { useState, useRef, useEffect } from 'react'
import { commonLoginBodyStyle } from './index'
import { useAngularServices } from '@/react/components'

export const ForgotUsernameBody = () => {
  const baseStyle = commonLoginBodyStyle()
  const [isError, setError] = useState(false)
  const [email, setEmail] = useState('')
  const [isSuccessfulRequest, setSuccessfulRequest] = useState(false)

  const { Api, Notification } = useAngularServices()

  const inputRef = useRef(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }, [])

  const handleForgotUsername = async () => {
    try {
      await Api.post('users/forgot_username', {
        email,
      })
      setSuccessfulRequest(true)
    } catch {
      setError(true)
      Notification.clearNotification()
    }
  }

  return (
    <div css={baseStyle}>
      <div className="forgot">
        <h2 className="forgot__title">Forgot Username</h2>
        {!isSuccessfulRequest ? (
          <>
            <p className="forgot__subtitle">
              Enter your email to receive your username(s)
            </p>
            {isError && (
              <p className="forgot__error">
                Email does not exist for an active account.
              </p>
            )}
            <input
              type="text"
              className="forgot__input"
              placeholder="email"
              value={email}
              ref={inputRef}
              onChange={(e) => {
                setEmail(e.target.value)
                setError(false)
              }}
            />
            <button
              className="forgot__button"
              onClick={handleForgotUsername}
              disabled={isError}
            >
              Email Username(s)
            </button>
          </>
        ) : (
          <p className="forgot__subtitle">
            Success! Reset instructions have been emailed to you.
          </p>
        )}
      </div>
    </div>
  )
}
