angular.module('ccs').directive('searcher', searcher)

function searcher() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/searcher.html',
    scope: {
      search: '=',
      searchExecuted: '=?',
    },
    link: function (scope, element) {
      const ENTER = 13

      function search() {
        scope.$parent.searchExecuted = true
        scope.$parent.pageNumber = 1
        scope.$parent.changePage()
      }

      function clearSearch() {
        scope.$parent.search = undefined
      }

      element.on('keyup', '#searcher-input', function (event) {
        if (event.keyCode === ENTER) {
          search()
        }
      })

      scope.handleSearchClick = () => {
        search()
      }

      scope.clearSearch = () => {
        clearSearch()
        search()
        scope.$parent.searchExecuted = false
      }
    },
  }
}
