import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'ToolboxProjectListCtrl',
    function ($scope, Api, app, $stateParams, $state, CurrentUser, $log) {
      $log.debug('ToolboxProjectListCtrl')

      $scope.titleSingular = pluralize.singular(app.toolbox_talks_display)
      $scope.headersToolbox = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'edit',
          display: 'Assign ' + app.toolbox_talks_display,
          centered: true,
        },
      ]
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //total is really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageSize * $scope.pageNumber }
      $scope.breadcrumbs = [{ title: app.name }]
      $scope.app = app

      function projectQuery() {
        let query = {
          application: app.id,
          client: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          is_active: 'True',
        }
        if ($scope.search) query.search = $scope.search
        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return query
      }

      function appProjectQuery(projects) {
        return { projects: projects.map((p) => p.id).join(), app: app.id }
      }

      $scope.changePage = () => {
        //update the URL query parameters without reloading the controller
        $state.transitionTo(
          'app.toolboxes.toolbox_to_project',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          {
            notify: false,
          },
        )
        getProjects()
      }

      function getProjects() {
        Api.Projects.get(
          { ...projectQuery(), search_fields: 'name' },
          function (resp) {
            $scope.data.total = resp.count
            $scope.data.items = resp.results

            Api.AppProjects.get(appProjectQuery(resp.results), function (resp) {
              var appProjects = resp.results

              $scope.data.items = $scope.data.items.map(function (project) {
                appProjects.forEach(function (appProject) {
                  if (appProject.project.id == project.id) {
                    project.appProject = appProject
                  }
                })

                return project
              })
            })
          },
        )
      }

      getProjects()
    },
  )
