import * as React from 'react'

type Props = {
  className: string
}

export const FileIcon = ({ className }: Props) => (
  <svg
    width="20"
    height="24"
    viewBox="0 0 20 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M19.8607 6.80595L13.194 0.139286C13.1498 0.095079 13.0973 0.0600247 13.0395 0.0361256C12.9816 0.0122265 12.9197 -4.90409e-05 12.8571 1.47239e-07H1.42857C1.04969 1.47239e-07 0.686328 0.15051 0.418419 0.418419C0.15051 0.686328 0 1.04969 0 1.42857V22.381C0 22.7598 0.15051 23.1232 0.418419 23.3911C0.686328 23.659 1.04969 23.8095 1.42857 23.8095H18.5714C18.9503 23.8095 19.3137 23.659 19.5816 23.3911C19.8495 23.1232 20 22.7598 20 22.381V7.14286C20 7.0803 19.9878 7.01835 19.9639 6.96055C19.94 6.90274 19.9049 6.85021 19.8607 6.80595ZM13.3333 1.625L18.3738 6.66667H13.3333V1.625ZM18.5714 22.8571H1.42857C1.30228 22.8571 1.18116 22.807 1.09185 22.7177C1.00255 22.6284 0.952381 22.5072 0.952381 22.381V1.42857C0.952381 1.30228 1.00255 1.18116 1.09185 1.09185C1.18116 1.00255 1.30228 0.952381 1.42857 0.952381H12.381V7.14286C12.381 7.26915 12.4311 7.39027 12.5204 7.47958C12.6097 7.56888 12.7308 7.61905 12.8571 7.61905H19.0476V22.381C19.0476 22.5072 18.9974 22.6284 18.9081 22.7177C18.8188 22.807 18.6977 22.8571 18.5714 22.8571ZM13.194 13.4726C13.2834 13.562 13.3336 13.6832 13.3336 13.8095C13.3336 13.9359 13.2834 14.0571 13.194 14.1464C13.1047 14.2358 12.9835 14.286 12.8571 14.286C12.7308 14.286 12.6096 14.2358 12.5202 14.1464L10.4762 12.1024V18.5714C10.4762 18.6977 10.426 18.8188 10.3367 18.9081C10.2474 18.9974 10.1263 19.0476 10 19.0476C9.87371 19.0476 9.75259 18.9974 9.66328 18.9081C9.57398 18.8188 9.52381 18.6977 9.52381 18.5714V12.1024L7.47976 14.1464C7.39041 14.2358 7.26922 14.286 7.14286 14.286C7.01649 14.286 6.8953 14.2358 6.80595 14.1464C6.7166 14.0571 6.6664 13.9359 6.6664 13.8095C6.6664 13.6832 6.7166 13.562 6.80595 13.4726L9.6631 10.6155C9.70732 10.5712 9.75984 10.5361 9.81765 10.5121C9.87546 10.4881 9.93742 10.4758 10 10.4758C10.0626 10.4758 10.1245 10.4881 10.1824 10.5121C10.2402 10.5361 10.2927 10.5712 10.3369 10.6155L13.194 13.4726Z"
      fill="#575757"
    />
  </svg>
)
