angular
  .module('ccs')
  .controller(
    'ProjectsEditCtrl',
    function (
      $scope,
      Api,
      $stateParams,
      $state,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('ProjectsEditCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse == 'true'
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.prevousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.getClientSettings = CurrentUser.getClientSettings

      var editing = false

      function redirect() {
        $state.go(
          'admin.projects.list',
          {
            pageNumber: $scope.previousPageNumber,
            order: $scope.previousOrder,
            reverse: $scope.previousReverse,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }

      function formatDateForSave(date) {
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      if ($stateParams.projectID) {
        editing = true
        Api.Projects.byID($stateParams.projectID, function (resp) {
          $scope.project = {
            ...resp,
            end_date: resp.end_date ? new Date(resp.end_date) : null,
            proceed_date: resp.proceed_date
              ? new Date(resp.proceed_date)
              : null,
            substantial_date: resp.substantial_date
              ? new Date(resp.substantial_date)
              : null,
          }
        })
      } else {
        $scope.project = {
          client: CurrentUser.getClientId(),
        }
      }

      $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.submit = function () {
        var func, verb

        const project = {
          ...$scope.project,
          proceed_date: $scope.project.proceed_date
            ? formatDateForSave($scope.project.proceed_date)
            : null,
          end_date: $scope.project.end_date
            ? formatDateForSave($scope.project.end_date)
            : null,
          substantial_date: $scope.project.substantial_date
            ? formatDateForSave($scope.project.substantial_date)
            : null,
        }

        if (editing) {
          func = Api.Projects.patch
          verb = 'Updated!'
        } else {
          func = Api.Projects.post
          verb = 'Created!'
        }

        func(project, function (resp) {
          if (resp) {
            Notification.success('Project Successfully ' + verb)
            redirect()
          }
        })
      }
    },
  )
