import { useState } from 'react'
import arrowUp from '@/assets/icons/arrow-up.svg'
import arrowDown from '@/assets/icons/arrow-down.svg'
import classNames from 'classnames'
import { ViewIcon } from '@/react/componentAssets'
import { css } from '@emotion/react'

export const MobileRow = ({ job, isDark, color }) => {
  const [isFullDataOpen, setFullDataOpen] = useState(false)

  const baseStyle = getBaseStyle()

  const { training, certificate } = job

  const {
    training_topic,
    date_trained,
    date_training_expires,
    trained_by,
    trade,
  } = training

  return (
    <div css={baseStyle}>
      <div
        className={classNames('mobile__row', {
          dark: isDark && !isFullDataOpen,
        })}
        onClick={() => setFullDataOpen(!isFullDataOpen)}
      >
        <div className="mobile__info">
          <p className="row__title">{training_topic}</p>
          <img
            className="row__arrow"
            src={isFullDataOpen ? arrowUp : arrowDown}
            alt=""
            onClick={() => setFullDataOpen(!isFullDataOpen)}
          />
        </div>
      </div>
      {isFullDataOpen && (
        <div className="mobile__fulldata">
          <div className="fulldata__block">
            <p className="info__title">Trained:</p>
            <p className="fulldata__value">{date_trained}</p>
          </div>
          <div className="fulldata__block expiring">
            <p className="info__title">Expiring:</p>
            <p className="fulldata__value">{date_training_expires}</p>
          </div>
          <div className="fulldata__block trained">
            <p className="info__title">Trained By:</p>
            <p className="fulldata__value">{trained_by}</p>
          </div>
          {trade && (
            <div className="fulldata__block">
              <p className="info__title">Trade:</p>
              <p className="fulldata__value">{trade}</p>
            </div>
          )}
          {certificate?.file && (
            <div className="fulldata__block view">
              <p className="info__title">View:</p>
              <a href={certificate.file} target="_blank">
                <ViewIcon height="18px" color={color} />
              </a>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

function getBaseStyle() {
  return css({
    '.row__title': {
      fontSize: '12px',
      margin: 0,
    },
    '.mobile__row': {
      display: 'flex',
      height: '40px',
      border: '1px solid #E7EAEC',
      marginInline: '8px',
    },
    '.mobile__info': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      paddingInline: '17px',
    },
    '.row__arrow': {
      height: '9px',
      width: '9px',
    },
    '.mobile__fulldata': {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
      border: '1px solid #E7EAEC',
      borderTop: 'none',
      borderBottom: 'none',
      paddingInline: '37px',
      paddingTop: '15px',
      paddingBottom: '15px',
      marginInline: '8px',
    },
    '.fulldata__block': {
      display: 'flex',
      gap: '45px',
    },
    '.expiring': {
      gap: '42px',
    },
    '.trained': {
      gap: '25px',
    },
    '.view': {
      gap: '65px',
    },
    '.fulldata__value': {
      margin: 0,
    },
  })
}
