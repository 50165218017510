'use strict'
/**
 * Module to use Switchery as a directive for angular.
 *
 * @TODO implement Switchery as a service, https://github.com/abpetkov/switchery/pull/11
 */

import 'babel-loader!../switchery/switchery.js'

export default angular
  .module('NgSwitchery', [])
  .directive('uiSwitch', function ($window, $timeout, $log, $parse) {
    /**
     * Initializes the HTML element as a Switchery switch.
     *
     * $timeout is in place as a workaround to work within angular-ui tabs.
     *
     * @param scope
     * @param elem
     * @param attrs
     * @param ngModel
     */
    function linkSwitchery(scope, elem, attrs, ngModel) {
      if (!ngModel) return false
      let options = {}
      try {
        options = $parse(attrs.uiSwitch)(scope)
      } catch (e) {
        $log.debug('Switchery options parse error', e)
      }

      let switcher

      attrs.$observe('disabled', function (value) {
        if (!switcher) {
          return
        }

        if (value) {
          switcher.disable()
        } else {
          switcher.enable()
        }
      })

      // Watch changes
      scope.$watch(
        function () {
          return ngModel.$modelValue
        },
        function () {
          initializeSwitch()
        },
      )

      function initializeSwitch() {
        $timeout(function () {
          // Remove any old switcher
          if (switcher) {
            angular.element(switcher.switcher).remove()
          }
          // (re)create switcher to reflect latest state of the checkbox element
          // eslint-disable-next-line
          switcher = new Switchery(elem[0], options)
          const element = switcher.element
          element.checked = scope.initValue
          if (attrs.disabled) {
            switcher.disable()
          }

          switcher.setPosition(false)
          element.addEventListener('change', function () {
            scope.$apply(function () {
              ngModel.$setViewValue(element.checked)
            })
          })
          scope.$watch('initValue', function () {
            switcher.setPosition(false)
          })
        }, 0)
      }
      initializeSwitch()
    }

    return {
      require: 'ngModel',
      restrict: 'AE',
      scope: {
        initValue: '=ngModel',
      },
      link: linkSwitchery,
    }
  })
