import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const ToolboxRow = ({
  toolbox,
  assignedList,
  search,
  index,
  setRefresh,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const { stateService } = useRouter()
  const groupId = stateService.params.group

  const [hoveredField, setHoveredField] = useState(null)

  const handleAssign = async () => {
    await Api.patch(`toolbox_topic_sets/${groupId}`, {
      id: groupId,
      toolbox_topics: !assignedList?.includes(toolbox?.id)
        ? [...assignedList, toolbox?.id]
        : assignedList.filter((id) => id !== toolbox?.id),
    })

    setRefresh(true)
  }
  return (
    <tr
      className={classNames('project__row', {
        dark: index % 2 === 0,
      })}
    >
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: `${toolbox?.name}`,
            max__length: 108,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={`${toolbox?.name}`}
        />
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={assignedList?.includes(toolbox?.id)}
            onChange={handleAssign}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
