import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height?: string
  width?: string
  color: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const EditNoteIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M14.0269 11.0452L11.934 8.97397L12.6234 8.28356C12.8122 8.09452 13.0441 8 13.3193 8C13.5944 8 13.8261 8.09452 14.0146 8.28356L14.704 8.97397C14.8928 9.16301 14.9913 9.39118 14.9995 9.65847C15.0077 9.92575 14.9174 10.1538 14.7286 10.3425L14.0269 11.0452ZM13.3129 11.7726L8.0929 17H6V14.9041L11.2199 9.67671L13.3129 11.7726Z"
        fill={color}
      />
      <path
        d="M8.84211 5.52632C8.84211 6.40572 8.49277 7.2491 7.87093 7.87093C7.2491 8.49277 6.40572 8.84211 5.52632 8.84211H1.10526L1.10526 17.6842C1.10526 18.2705 1.33816 18.8327 1.75271 19.2473C2.16727 19.6618 2.72952 19.8947 3.31579 19.8947H15.4737C16.06 19.8947 16.6222 19.6618 17.0368 19.2473C17.4513 18.8327 17.6842 18.2705 17.6842 17.6842V3.31579C17.6842 2.72952 17.4513 2.16727 17.0368 1.75271C16.6222 1.33816 16.06 1.10526 15.4737 1.10526H8.84211V5.52632ZM5.52632 7.73684C6.11258 7.73684 6.67484 7.50395 7.08939 7.08939C7.50395 6.67484 7.73684 6.11258 7.73684 5.52632V1.55842L1.55842 7.73684H5.52632ZM0 17.6842L0 7.73684L7.73684 0H15.4737C16.3531 0 17.1965 0.349342 17.8183 0.971172C18.4401 1.593 18.7895 2.43639 18.7895 3.31579V17.6842C18.7895 18.5636 18.4401 19.407 17.8183 20.0288C17.1965 20.6507 16.3531 21 15.4737 21H3.31579C2.43639 21 1.593 20.6507 0.971172 20.0288C0.349341 19.407 0 18.5636 0 17.6842Z"
        fill={color}
      />
    </svg>
  )
}
