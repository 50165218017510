import * as React from 'react'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { ButtonElement, UniversalAlert } from '@/react/components'
import { useState } from 'react'
import { printPageWithFirefoxWarning } from '@/utils/printConnected'

export function BottomObservationNavigation({ deleted }: { deleted: boolean }) {
  const { stateService } = useRouter()

  const [popUp, setPopUp] = useState<Element | null>(null)
  const liveListRoute = stateService.current.name.includes('batch_reports')
    ? 'app.batch_reports.list'
    : 'app.observations.list'

  const printPage = () => {
    const isFirefox = typeof InstallTrigger !== 'undefined'
    if (!isFirefox) {
      print()
    } else {
      setPopUp(
        <UniversalAlert
          text={'Print to PDF on Firefox may result in large files sizes.'}
          setAlert={setPopUp}
          submitButtonText={'OK'}
          optionalFunction={print}
        />,
      )
    }
  }

  return (
    <section css={baseStyle}>
      <ButtonElement
        text={'Print'}
        functionToTrigger={() => {
          printPageWithFirefoxWarning(setPopUp)
        }}
      />
      <ButtonElement
        text={'Back'}
        functionToTrigger={() => {
          stateService.go(
            stateService.params.f_id
              ? 'app.batch_reports.finalize'
              : deleted
              ? 'app.observations.deleted_observations'
              : liveListRoute,
            {
              search: stateService.params.previousSearch,
              pageNumber: stateService.params.previousPageNumber,
              order: stateService.params.previousOrder,
              reverse: stateService.params.previousReverse,
              f_id: stateService.params.f_id,
            },
          )
        }}
      />
      {popUp}
    </section>
  )
}

const baseStyle = css({
  width: '100%',
  padding: '20px 0',
  display: 'flex',
  justifyContent: 'space-between',
  '@media print': {
    display: 'none !important',
  },
})
