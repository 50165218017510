import * as React from 'react'
import { NavBar } from '@/react/screens/TrainingsPages/LMSCommonComponents/NavBar'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { QuizIcon } from '@/react/componentAssets/QuizIcon'
import { useRouter } from '@/react/hooks'
import { useState, useEffect } from 'react'
import { UISref } from '@/react/components/UISref'
import { SingleQuiz } from './SingleQuiz'

export const ViewQuiz = () => {
  const { stateService } = useRouter()
  const { Api, CurrentUser } = useAngularServices()
  const [contentData, setContentData] = useState({})
  const [answerIds, setAnswerIds] = useState([])
  const variableColor: string =
    CurrentUser.getClientSettings().web_primary_color

  const handleQuizAnswers = (obj) => {
    const { contendId } = obj

    const findContent = answerIds.map((item) => item.contendId === contendId)

    if (findContent) {
      const filteredAnswers = answerIds.filter(
        (item) => item.contendId !== contendId,
      )
      return setAnswerIds([...filteredAnswers, obj])
    }

    setAnswerIds([...answerIds, obj])
  }

  useEffect(() => {
    const getContentData = async () => {
      const contentId = stateService.params.programId
      const { data: contentFromServer } = await Api.get(`quiz/${contentId}`)
      setContentData(contentFromServer)
    }
    getContentData()
  }, [])

  const handleAnswerSubmit = async () => {
    const quizAnswerIds = answerIds.map((quiz) => quiz.answerId)
    const jobId = stateService.params.jobId

    await Api.patch(`jobs/${jobId}`, {
      quiz_answers: quizAnswerIds,
      user_filter: true,
    })
    stateService.go('trainings.topics', {
      jobId: stateService.params.jobId,
      search: contentData?.id,
    })
  }

  const baseStyle = getBaseStyle(contentData?.fields?.length)
  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Learning Management System'} />
      <NavBar />
      <div className="content__wrapper">
        <div className="page__title">
          <QuizIcon color={variableColor} />
          <p className="page__text">{contentData?.name}</p>
        </div>
        {contentData.fields && (
          <div className="quiz__body">
            {contentData?.fields.map((field, index) => {
              return (
                <SingleQuiz
                  key={index}
                  field={field}
                  index={index}
                  handleQuizAnswers={handleQuizAnswers}
                  quizPassed={
                    contentData?.quiz_status >= contentData?.quiz_passing_score
                  }
                />
              )
            })}
          </div>
        )}
        <div className="button__block">
          <ButtonElement
            text={'Submit'}
            buttonType={'submit'}
            functionToTrigger={handleAnswerSubmit}
            disabled={
              contentData?.quiz_status >= contentData?.quiz_passing_score
            }
          />
        </div>
      </div>
    </section>
  )
}

function getBaseStyle(quizLength) {
  return css({
    paddingBottom: '200px',
    '.content__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      background: '#fff',
      flexDirection: 'column',
      width: '60%',
    },
    '.page__title': {
      display: 'flex',
      gap: '10px',
      marginTop: '15px',
      marginLeft: '15px',
      marginBottom: '10px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 700,
      letterSpacing: '-0.12px',
    },
    '.page__text': {
      margin: 0,
    },
    '.button__block': {
      display: 'flex',
      alignSelf: 'flex-start',
      marginLeft: '16px',
      marginBottom: quizLength > 7 ? '60px' : '16px',
    },
    '@media(max-width:800px)': {
      '.content__wrapper': {
        width: '100%',
      },
      '.button__block': {
        marginBottom: '60px',
      },
    },
  })
}
