angular
  .module('ccs')
  .controller(
    'UserProjectsEditCtrl',
    function ($scope, $state, $stateParams, Api, CurrentUser, $log) {
      $log.debug('UserProjectsEditCtrl')

      $scope.user = {}
      $scope.projects = []

      $scope.getClientSettings = CurrentUser.getClientSettings

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //total is really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageSize * $scope.pageNumber }

      $scope.tableHeaders = [
        {
          key: 'name',
          display: CurrentUser.getClientSettings().projects_language,
          sortable: true,
        },
        { key: 'is_active', display: 'Assign', sortable: true, toggleAllBtn: true },
      ]

      function get() {
        Api.UsersV2.byID(
          $stateParams.userID,
          function (resp) {
            $scope.user = resp
            getProjects()
            $scope.breadcrumbs = [
              { title: 'Administration' },
              { title: 'Users', url: 'admin.users.list' },
              { title: resp.username },
            ]
          },
          () => redirect(),
        ) // Redirect back to list if there is an error getting the user
      }

      function query() {
        let q = {
          page: $scope.pageNumber,
          search: $scope.search,
          user_id: $scope.user.id,
          is_active_project: true,
        }
        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      function redirect() {
        $state.go(
          'admin.users.list',
          {
            pageNumber: $scope.previousPageNumber,
            search: $scope.previousSearch,
            order: $scope.previousOrder,
            reverse: $scope.previousReverse,
          },
          { reload: true },
        )
      }

      function getProjects(params) {
        Api.ProjectsV2.get(query(), function (response) {
          response.results = response.results.map(function (p) {
            p.has_user = p.users.indexOf($scope.user.id) >= 0
            return p
          })

          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })
      }

      $scope.toggleAll = () => {
        Api.postV2(
          `users/${$stateParams.userID}/toggle_all_projects`,
          {
            id: $stateParams.userID,
            on: $scope.active,
            search: $scope.search,
          },
          (resp) => {
            get()
          },
        )
      }

      $scope.changePage = function () {
        get()

        //update the URL in the navigation bar
        $state.transitionTo(
          'admin.users.editProjects',
          {
            userID: $stateParams.userID,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            previousPageNumber: $scope.previousPageNumber,
            previousOrder: $scope.previousOrder,
            previousReverse: $scope.previousReverse,
            previousSearch: $scope.previousSearch,
          },
          { notify: false },
        )
      }

      $scope.toggleActive = function (project) {
        var user = $scope.user
        var data = { id: user.id }

        if (project.has_user) {
          user.projects.push(project.id)
        } else {
          user.projects = user.projects.filter(function (p) {
            return p != project.id
          })
        }

        data.projects = user.projects
        Api.postV2(
          'users/' + user.id + '/projects',
          data,
          function (resp) {},
          () => redirect(),
        )
      }

      get()

      $scope.backToSearch = function () {
        $state.go(
          'admin.users.list',
          {
            pageNumber: $scope.previousPageNumber,
            search: $scope.previousSearch,
            order: $scope.previousOrder,
            reverse: $scope.previousReverse,
          },
          { reload: false },
        )
      }
    },
  )
