import { cloneDeep } from 'lodash'

export function clearOptionCopies(options, removeFieldsForPost = true) {
  const nameReferences = []
  const newOptions = []
  cloneDeep(options).forEach((option) => {
    if (removeFieldsForPost) {
      delete option.tempId
      delete option.objType
      delete option.isLengthValid
    }
    if (!nameReferences.includes(option.name)) {
      newOptions.push(option)
      nameReferences.push(option.name)
    }
  })
  return newOptions
}
