import { cloneDeep, flatMap } from 'lodash'
import {
  ApiService,
  DataWrap,
  GeneralSettings,
  GenericOption,
  GeneticTextResult,
  NoteType,
  NotificationService,
  ObservationBody,
  PaginatedGet,
  ReportAnswer,
  ReportCorrectiveAction,
  ReportQuestion,
  ReportSettings,
  SignatureType,
  UIRouterState,
} from '@/react/types'
import moment from 'moment'

export const fetchData = async (
  Api: ApiService,
  requestParams: {
    deleted: string
    observation: number
    page_size: number
    application: number
  },
  observationID: string | number,
  userId: string | number,
  setPartyObservedOptions: (value: GenericOption[]) => void,
  setBaseObservationData: (value: any) => void,
  setQuestionData: (value: any) => void,
  setNotes: (value: any) => void,
  setSignatures: (value: any) => void,
  setMemoryState: (value: any) => void,
  setSettings: (value: any) => void,
  ANSWER: ReportAnswer,
  setWildcard: (value: any) => void,
  setWildcardId: (value: any) => void,
) => {
  const [
    { data: observationData },
    { data: generalSettings },
    { data: reportSettings },
    { data: globalPO },
    { data: userPO },
  ]: [
    DataWrap<ObservationBody>,
    DataWrap<PaginatedGet<GeneralSettings[]>>,
    DataWrap<PaginatedGet<ReportSettings[]>>,
    DataWrap<PaginatedGet<GeneticTextResult[]>>,
    DataWrap<PaginatedGet<GeneticTextResult[]>>,
  ] = await Promise.all([
    Api.Observations.byIDWithoutDeletedParam(observationID),
    Api.GeneralSettings.get(),
    Api.ReportSettings.get(),
    Api.get('answer_party_observed', {
      page: 1,
      deleted: false,
      is_active: true,
      page_size: 1000,
      has_user: false,
    }),
    Api.get('answer_party_observed', {
      page: 1,
      deleted: false,
      is_active: true,
      page_size: 1000,
      has_user: true,
      user: parseInt(userId),
    }),
  ])

  const questions: ReportQuestion[] = []
  const answers: ReportAnswer[] = []
  const questionsPromises: Promise<DataWrap<PaginatedGet<ReportQuestion[]>>>[] =
    []

  const PAGE_REQUEST_SIZE = 500
  const questionPagesToGet: number = Math.ceil(
    observationData.questions / PAGE_REQUEST_SIZE,
  )

  for (let i = 1; i < questionPagesToGet + 1; i++) {
    questionsPromises.push(
      Api.Questions.get({
        ...requestParams,
        page_size: PAGE_REQUEST_SIZE,
        page: i,
      }),
    )
  }

  const questionsGet: DataWrap<PaginatedGet<ReportQuestion[]>>[] =
    await Promise.all(questionsPromises)

  questionsGet.forEach((singleQuestionGet) => {
    singleQuestionGet.data.results.forEach((question) => {
      if (question.custom_categories.length) {
        question.custom_categories.forEach((category) => {
          const newQuestion = cloneDeep(question)
          newQuestion.category = category
          questions.push(newQuestion)
        })
      }
    })
  })

  const answerPagesToGet: number = Math.ceil(
    observationData.answers_count / PAGE_REQUEST_SIZE,
  )

  const answersPromises: Promise<DataWrap<PaginatedGet<ReportAnswer[]>>>[] = []

  for (let i = 1; i < answerPagesToGet + 1; i++) {
    answersPromises.push(
      Api.Answers.get({
        ...requestParams,
        page_size: PAGE_REQUEST_SIZE,
        page: i,
      }),
    )
  }

  const answersGet: DataWrap<PaginatedGet<ReportAnswer[]>>[] =
    await Promise.all(answersPromises)

  answersGet.forEach((singleAnswerGet) => {
    singleAnswerGet.data.results.forEach((answer) => {
      answers.push(answer)
    })
  })

  const optionsArray: GenericOption[] = []
  const optionIdToOrder: { [key: string | number]: number } = {}

  globalPO.results.forEach((po, idx) => {
    optionsArray.push({ value: po.id, label: po.name })
    optionIdToOrder[po.id] = idx
  })
  userPO.results.forEach((po, idx) => {
    optionsArray.push({ value: po.id, label: po.name })
    optionIdToOrder[po.id] = optionsArray.length + idx - 1
  })
  setPartyObservedOptions(optionsArray.sort(compare))

  const settingsData = {
    generalSettings: generalSettings.results[0],
    reportSettings: reportSettings.results[0],
  }

  if (
    observationData.observation_wildcards &&
    observationData.observation_wildcards.length
  ) {
    observationData.observation_wildcards.forEach((wildcard) => {
      if (!wildcard.deleted) {
        setWildcard(wildcard.text)
        setWildcardId(wildcard.id)
      }
    })
  }
  setBaseObservationData(observationData)
  setQuestionData(questions)
  setNotes(notesProcessing(observationData.observation_notes))
  setSignatures(signaturesProcessing(observationData.signatures))

  setMemoryState(listProcessing(questions, answers, observationID, ANSWER))
  setSettings(settingsData)
}

export async function sendForm(
  memoryState: {
    [key: string]: ReportAnswer[]
  },
  Api: ApiService,
  signatures: SignatureType[],
  notes: NoteType[],
  observationID: number,
  stateService: UIRouterState,
  Notification: NotificationService,
  setDisableButton: (arg0: boolean) => void,
  wildcard: string,
  wildcardId: number,
  baseObservationData: ObservationBody,
) {
  const initialData = flatMap(memoryState)
  setDisableButton(true)
  const promiseList: Promise<DataWrap<any>>[] = []
  const signaturePromiseList: Promise<DataWrap<any>>[] = []

  const categoriesWithAnswers: number[] = []

  for (const answer of initialData) {
    if (
      answer &&
      answer?.answer !== 'n/a' &&
      !categoriesWithAnswers.includes(answer?.category)
    ) {
      categoriesWithAnswers.push(answer.category)
    }
  }

  if (!baseObservationData.title) {
    if (categoriesWithAnswers.length === 0) {
      baseObservationData.title =
        'No answers filled - ' +
        moment(
          baseObservationData.date_performed ||
            baseObservationData.date_created,
        ).format('MM/DD/YYYY')
    } else if (categoriesWithAnswers.length > 1) {
      baseObservationData.title =
        'Various - ' +
        moment(
          baseObservationData.date_performed ||
            baseObservationData.date_created,
        ).format('MM/DD/YYYY')
    } else if (categoriesWithAnswers.length === 1) {
      baseObservationData.categories.forEach((cat) => {
        if (cat.id === categoriesWithAnswers[0]) {
          baseObservationData.title =
            cat.name +
            ' - ' +
            moment(
              baseObservationData.date_performed ||
                baseObservationData.date_created,
            ).format('MM/DD/YYYY')
        }
      })
    }
  }

  if (wildcardId && wildcard) {
    promiseList.push(
      Api.patch('observation_wildcards/' + wildcardId, {
        text: wildcard,
        observation: observationID,
        id: wildcardId,
      }),
    )
  } else if (wildcardId) {
    promiseList.push(
      Api.delete('observation_wildcards/' + wildcardId, {
        observation: observationID,
        id: wildcardId,
      }),
    )
  } else {
    if (wildcard) {
      promiseList.push(
        Api.post('observation_wildcards', {
          observation: observationID,
          text: wildcard,
        }),
      )
    }
  }

  signatures.forEach((signature) => {
    if (signature.deleted) {
      signaturePromiseList.push(Api.removeSignature(signature.id))
    } else if (signature.id) {
      signaturePromiseList.push(Api.Signatures.patch(signature))
    } else {
      signaturePromiseList.push(Api.Signatures.post(signature))
    }
  })
  notes.forEach((note) => {
    if ((note.deleted && note.id) || (note.text === '' && note.id)) {
      promiseList.push(Api.ObservationNotes.delete({ id: note.id }))
    } else if (note.id) {
      promiseList.push(Api.ObservationNotes.patch(note))
    } else if (note.deleted) {
      return
    } else if (note.text) {
      Api.ObservationNotes.post(note)
    }
  })

  await Promise.all([...promiseList])
  const signatureIdArray = []
  await Promise.all([...signaturePromiseList]).then((values) => {
    values.forEach((value) => {
      signatureIdArray.push(value.data.id)
    })
  })
  await Api.Observations.patch({
    title: baseObservationData.title,
    id: observationID,
    signatures: signatureIdArray,
    is_active: true,
  })
  await Api.put('observations/' + observationID + '/synchronised/', {
    synchronised: true,
  })
  await Api.put('observations/' + observationID + '/activate/', {})
  stateService.go('app.observations.list', {
    pageNumber: stateService.params.previousPageNumber,
    search: stateService.params.previousSearch,
    order: stateService.params.previousOrder,
    reverse: stateService.params.previousReverse,
  })
}

function listProcessing(questions, answers, observationID, ANSWER) {
  const questionsFormData = {}

  questions.forEach((question) => {
    if (question.custom_categories.length) {
      question.custom_categories.forEach((category) => {
        if (category)
          questionsFormData[question.id + '-' + category] = [
            {
              ...ANSWER,
              observation: observationID,
              question: question.id,
              category: category,
            },
          ]
      })
    }
  })

  answers.forEach((answer) => {
    const notes = []
    const corrective_actions = []
    const photos = []
    answer.notes.forEach((note) => {
      if (!note.deleted) {
        notes.push({
          id: note.id,
          text: note.text,
          fieldmeta: {
            text: {
              last_updated: answer.date_updated,
              last_updated_by_user_id: note?.user?.id,
            },
          },
        })
      }
    })
    answer.corrective_actions.forEach((corrective_action) => {
      if (!corrective_action.deleted) {
        corrective_actions.push({
          id: corrective_action.id,
          text: corrective_action.text,
          fieldmeta: {
            text: {
              last_updated: corrective_action.date_updated,
              last_updated_by_user_id: corrective_action?.user?.id,
            },
          },
        })
      }
    })
    answer.photos.forEach((photo) => {
      if (!photo.deleted) {
        photos.push({ id: photo.id, image: photo.image })
      }
    })
    questionsFormData[answer.question + '-' + answer.category][
      answer.copy_sequence_number
    ] = {
      ...ANSWER,
      observation: observationID,
      question: answer.question,
      answer: answer.answer,
      initialAnswer: answer.answer,
      reference: answer.reference,
      party_observed: answer.party_observed,
      severity: answer.severity,
      notes: notes,
      corrective_actions: corrective_actions,
      photos: photos,
      copy_sequence_number: answer.copy_sequence_number,
      answer_party_observed_answer_list:
        answer.answer_party_observed_answer_list,
      category:
        questionsFormData[answer.question + '-' + answer.category][0].category,
    }
    if (answer.id) {
      questionsFormData[answer.question + '-' + answer.category][
        answer.copy_sequence_number
      ].id = answer.id
    }
  })

  return questionsFormData
}

function signaturesProcessing(signatures) {
  const processedSignatures = []
  signatures.forEach((signature) => {
    if (!signature.deleted) {
      processedSignatures.push({
        id: signature.id,
        image: signature.image.id,
        url: signature.image.image,
        printed_name: signature.printed_name,
      })
    }
  })
  return processedSignatures
}

function notesProcessing(notes) {
  const processedNotes = []
  notes.forEach((note) => {
    if (!note.deleted) {
      processedNotes.push({
        id: note.id,
        text: note.text,
        observation: note.observation,
      })
    }
  })
  return processedNotes
}

export function compare(a: { label: string }, b: { label: string }) {
  if (a.label.toLowerCase() < b.label.toLowerCase()) {
    return -1
  }
  if (a.label.toLowerCase() > b.label.toLowerCase()) {
    return 1
  }
  return 0
}

export async function processSingleAnswer(
  changedFields: { field: string; value: any }[],
  Api,
  observationID: number,
  setAnswerState,
) {
  let answer: ReportAnswer = {}

  setAnswerState((prevState: ReportAnswer) => {
    if (!prevState) return

    const newState = cloneDeep(prevState)
    changedFields.forEach(({ field, value }) => {
      newState[field] = value
    })
    answer = newState
    return newState
  })

  const promiseList: Promise<DataWrap<any>>[] = []
  const party_observed_add: { label: string; value: string | number }[] = []

  const notes: NoteType[] = []
  const corrective_actions: ReportCorrectiveAction[] = []
  const photos: number[] = []

  answer.notes.forEach((note) => {
    if (note.text && !note.deleted) {
      notes.push(note)
    } else if (note.id) {
      promiseList.push(Api.AnswerNotes.delete({ id: note.id }))
    }
  })
  answer.corrective_actions.forEach((corrective_action, idx) => {
    if (!corrective_action.deleted && corrective_action.text) {
      corrective_actions.push(corrective_action)
    } else if (corrective_action.id) {
      promiseList.push(
        Api.CorrectiveActions.delete({ id: corrective_action.id }),
      )
    }
  })
  answer.photos.forEach((photo, idx) => {
    if (!photo.deleted) {
      photos.push(photo.id)
    } else {
      promiseList.push(Api.removeImage(photo.id))
    }
  })

  const answerToSend: ReportAnswer = {
    id: answer.id,
    question: answer.question,
    category: answer.category,
    copy_sequence_number: answer.copy_sequence_number,
  }

  changedFields.forEach(({ field }) => {
    if (field === 'notes') {
      answerToSend.notes = notes
    } else if (field === 'corrective_actions') {
      answerToSend.corrective_actions = corrective_actions
    } else if (field === 'photos') {
      answerToSend.photos = photos
    } else {
      if (field !== 'edited') answerToSend[field] = answer[field]
    }
  })

  // if (party_observed_add.length)
  //   await Api.post(`answer_party_observed_answer`, party_observed_add)
  await Promise.all([...promiseList])
  await Api.post(`observations/` + observationID + `/answers`, {
    answers: [answerToSend],
  })

  return answer.id
}

export async function initialSingleAnswer(answer, Api, observationID) {
  const {
    data: answerWithId,
  }: DataWrap<{ id: number; observation: number; category: number }> =
    await Api.post(`observations/` + observationID + `/answers`, {
      answers: [
        {
          answer: answer.answer,
          deleted: false,
          party_observed: [],
          severity: answer.severity,
          category: answer.category,
          answer_party_observed_answer_list: [],
          copy_sequence_number: 0,
          observation: answer.observation,
          question: answer.question,
          reference: '',
          notes: [],
          corrective_actions: [],
          photos: [],
          party_observed_easy_acces_data: {},
        },
      ],
    })
  answer.id = answerWithId[0].id
  return answerWithId[0].id
}
