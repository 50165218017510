import { ReactWrapper } from '@components'

import { CurrentPage } from './CurrentPage'
import { Timesheet, VoidedTimesheetPage } from './Timesheet'
import { SettingsPage } from './SettingsPage'
import { DailyReports, ViewReport } from './DailyReports'
import {
  TimeAndMaterialsList,
  TimeAndMaterialView,
} from './TimeAndMaterialPage'

export const currentPageComponent = ReactWrapper(
  CurrentPage,
  'timeCardCurrentPage',
)
export const timesheetComponent = ReactWrapper(Timesheet, 'timeCardTimesheet')
export const voidedTimesheetPageComponent = ReactWrapper(
  VoidedTimesheetPage,
  'timeCardTimesheetVoided',
)
export const dailyReportsComponent = ReactWrapper(DailyReports, 'dailyReports')
export const viewReportComponent = ReactWrapper(ViewReport, 'viewReport')
export const settingsPageComponent = ReactWrapper(
  SettingsPage,
  'timeCardSettingsPage',
)
export const timeAndMaterialsList = ReactWrapper(
  TimeAndMaterialsList,
  'timeAndMaterialsList',
)
export const timeAndMaterialView = ReactWrapper(
  TimeAndMaterialView,
  'timeAndMaterialView',
)
