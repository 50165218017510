import * as React from 'react'
import { css } from '@emotion/react'

import { ButtonElement } from '@/react/components'

import closeIcon from '/src/assets/icons/X Gray.svg'
import { BinDeleteIcon } from '@/react/componentAssets'

type Props = {
  id: number
  deleteRequest: any
  color: string
}

export const DeleteElement: React.FC<Props> = ({
  deleteRequest,
  id,
  color,
}: Props) => {
  const [alertActivity, setAlertActivity] = React.useState<boolean>(false)
  const [value, setValue] = React.useState(null)

  function closeAlert() {
    setAlertActivity(false)
  }
  function deletePO() {
    deleteRequest(id, value)
    closeAlert()
  }

  return (
    <div
      css={css({
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      })}
    >
      <div css={css({ height: 0, width: 0, position: 'relative' })}>
        {alertActivity ? (
          <div
            css={css({
              left: 0,
              top: 0,
              height: '100vh',
              width: '100vw',
              position: 'fixed',
              zIndex: 100,
            })}
            onClick={closeAlert}
          />
        ) : null}
        {alertActivity ? (
          <div
            css={css({
              width: '250px',
              backgroundColor: 'white',
              position: 'absolute',
              padding: '10px',
              top: '-150px',
              left: '-200px',
              WebkitBoxShadow: '7px 7px 18px 9px rgba(34, 60, 80, 0.28)',
              MozBoxShadow: '7px 7px 18px 9px rgba(34, 60, 80, 0.28)',
              boxShadow: '7px 7px 18px 9px rgba(34, 60, 80, 0.28)',
              zIndex: 1000,
            })}
          >
            <img
              src={closeIcon}
              alt="close"
              css={css({
                display: 'block',
                marginLeft: 'auto',
                height: 25,
                cursor: 'pointer',
              })}
              onClick={closeAlert}
            />
            <h3
              css={css({
                width: '100%',
                textAlign: 'center',
                paddingBottom: 15,
                fontSize: 16,
                fontWeight: 600,
              })}
            >
              Delete?
            </h3>
            <div
              css={css({
                width: '100%',
                display: 'flex',
                padding: 0,
                justifyContent: 'center',
                margin: '0 0 20px 0',
              })}
            >
              <ButtonElement
                text={'OK'}
                buttonType={'submit'}
                functionToTrigger={deletePO}
                fontSize={'16px'}
              />
            </div>
          </div>
        ) : null}
      </div>
      <BinDeleteIcon
        color={color}
        additionalStyles={css({ cursor: 'pointer' })}
        onClick={(value) => {
          setAlertActivity(true)
          setValue(value)
        }}
      />
    </div>
  )
}
