angular.module('ccs').directive('back', back)

function back() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/back.html',
    scope: {
      toLocation: '=',
    },
  }
}
