//used for observation reports.  take a list of questions and return the number in the last that have a particular severity
angular.module('ccs').filter('listServerityFilter', function ($filter) {
  return function (ary, severity) {
    if (typeof ary !== 'undefined') {
      const myFilteredObjects = $filter('filter')(ary, {
        answer: { severity },
      })
      return myFilteredObjects.length
    }
  }
})
