import * as React from 'react'
import { css } from '@emotion/react'
import { default as ReactSelect } from 'react-select'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@/react/components'

// @ts-ignore
export function Select({
  width,
  name,
  header,
  value,
  optionList,
  onChange,
  isMulti,
  errors,
}: any) {
  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  return (
    <div className={'inputBase'} css={[fieldStyle, width]}>
      <label htmlFor={name}>{header}</label>
      <div
        className="select-Base"
        onClick={() => {
          preValidationErrorHider(name)
        }}
      >
        <ReactSelect
          className={'select ' + (errors[name] ? 'error' : '')}
          id={name}
          options={optionList}
          // For multible onChange needed format is:
          // values => {
          // formik.setFieldValue(NEEDED FIELD, values.map(({ value }) => value))
          onChange={onChange}
          value={
            isMulti
              ? defaultValues(optionList, value)
              : defaultValue(optionList, value)
          }
          // true for multiple select
          isMulti={isMulti}
          styles={dropdownOptionStyles(variableColor)}
          theme={(theme) => dropdownFiledTheme(theme, variableColor)}
        />
      </div>
    </div>
  )
}

const defaultValue = (options, value) => {
  return options ? options.find((option) => option.value === value) : ''
}

const defaultValues = (options, values) => {
  return options
    ? options.filter((option) => values.includes(option.value))
    : []
}

function preValidationErrorHider(elementId) {
  const element = document.getElementById(elementId)
  if (element.classList.contains('error')) {
    element.classList.add('error-hide')
  }
}

const fieldStyle = css({
  display: 'flex',
  marginTop: '15px',
  flexDirection: 'column',
  label: {
    width: '100%',
    fontFamily: [
      'open sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    fontWeight: 'bold',
    fontSize: '13px',
  },
  '.error>div': {
    borderColor: 'red',
  },
  '.error.error-hide>div': {
    borderColor: '#e5e6e7',
  },
  '.select>div:first-of-type>div': {
    overflowY: 'auto',
  },
})
