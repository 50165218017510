import * as React from 'react'
import { css } from '@emotion/react'
import { UISref } from '@components/UISref'
import { useAngularServices } from '@components/index'
import { adminCheck } from '@/utils/elementChecks'

import { HorizontalNavigation } from '@screens/components/types'

// @ts-ignore
export const FloatingWindowHorizontalNavigation = ({ navSettings }) => {
  const { uiPermissionService, CurrentUser } = useAngularServices()

  const role = CurrentUser.getRole()
  const adminStatus = uiPermissionService.getPermissionViewAdministration()

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        {navSettings.map((tab: HorizontalNavigation, idx: number) => {
          return adminCheck(
            tab.onlyAdminView,
            adminStatus,
            <UISref key={idx} to={tab.redirectTo} params={tab.redirectParams}>
              <a
                key={idx}
                className={`tab ${tab.isActive ? 'active' : ''}`}
                css={tab.additionalStyles}
              >
                {tab.hasIcon ? (
                  <img className="icon" src={tab.icon} alt={tab.text} />
                ) : null}
                <p>{tab.text}</p>
              </a>
            </UISref>,
            tab.supervisorView,
            role === 'supervisor',
          )
        })}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.icon': {
        marginRight: '15px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.icon': {
        marginRight: '0 !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 1,
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    '.icon': {
      marginLeft: '15px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: '5px',
      marginRight: '15px',
      marginBottom: '0px',
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
})
