angular
  .module('ccs')
  .controller(
    'CompleteQuizCtrl',
    function ($scope, Api, $state, $stateParams, Notification, $log) {
      $log.debug('CompleteQuizCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.trainingID = $stateParams.trainingID
      $scope.jobId = $stateParams.jobId
      $scope.contentId = $stateParams.contentId
      $scope.programId = $stateParams.programId
      // $scope.app = app;

      $scope.contentId = $stateParams.contentId
      $scope.topicId = $stateParams.topicId
      $scope.disabled_btn = false

      function getTrainingTopics() {
        Api.getWithoutDeletedParam(
          `jobs/${$scope.programId}`,
          { user_filter: true, order: 'order' },
          (resp) => {
            $scope.topics = resp.training
          },
          (error) => {
            Notification.danger(
              JSON.stringify(error && error.detail ? error.detail : error),
            )
          },
        )
      }

      getTrainingTopics()

      Api.Quiz.byID($scope.contentId, (resp) => {
        $scope.original = resp
        $scope.form = {
          ...resp,
          id: null,
          fields: resp.fields.map((field) => {
            return {
              ...field,
              id: field.id,
              tempId: field.order,
              objType: 'field',
              order: null,
              options: field.answer_options.map((option) => {
                return {
                  ...option,
                  id: option.id,
                  tempId: option.order,
                  objType: 'option',
                  order: null,
                }
              }),
            }
          }),
        }
      })

      $scope.FIELD_TYPES = {
        multi: 'Multiple Choice',
        true_false: 'True/False',
      }

      $scope.allowableFields = ['field']
      $scope.allowableOptions = ['option']

      $scope.quiz_answers = []
      $scope.quizCompeteBtnActive = false
      $scope.setQuizAnswer = function (field, current_option, questionCount) {
        field.options.forEach(function (option, k) {
          if (current_option.id === option.id) {
            option.user_answer = true
            let index = $scope.quiz_answers.indexOf(current_option.id)
            if (index === -1) {
              $scope.quiz_answers.push(current_option.id)
            }
          } else {
            option.user_answer = false
            let index = $scope.quiz_answers.indexOf(option.id)
            if (index > -1) {
              $scope.quiz_answers.splice(index, 1)
            }
          }
        })
        if ($scope.quiz_answers.length === questionCount) {
          $scope.quizCompeteBtnActive = true
          return $scope.quizCompeteBtnActive
        }
      }

      $scope.goBack = function () {
        $state.go(
          'trainings.topics',
          {
            jobId: $scope.programId,
            pageNumber: $scope.previousPageNumber,
          },
          { reload: true },
        )
      }

      $scope.save = function (form) {
        $scope.quiz_answers = []
        $scope.disabled_btn = true
        form.fields.forEach(function (field, k) {
          field.options.forEach(function (option, k) {
            if (option.user_answer === true) {
              $scope.quiz_answers.push(option.id)
            }
          })
        })

        Api.patch(
          `jobs/${$scope.programId}`,
          {
            user_filter: true,
            quiz_answers: $scope.quiz_answers,
          },
          (resp) => {
            if (resp) {
              $scope.disabled_btn = false
              $state.go('trainings.topics', {
                jobId: $scope.programId,
                topicId: form.topic,
              })
            }
          },
        )
      }
    },
  )
