angular.module('ccs').directive('categoryfilter', categoryFilter)

function categoryFilter(Api) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/category_filter.html',
    link: function ($scope) {
      function getCategories(search = '') {
        Api.Categories.get(
          {
            search,
            order: 'name',
            is_active: 'True',
            page_size: 250,
            application: $scope.app?.id || null,
          },
          (resp) => {
            $scope.categories = resp.results
          },
        )
      }

      getCategories()

      $scope.selected = () => {
        $scope.pageNumber = 1
        $scope.changePage()
      }

      $scope.refreshCategories = getCategories

      //the app was changed
      $scope.$watch('app', function () {
        getCategories()
      })
    },
  }
}
