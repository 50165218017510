import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const RenewIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      version="1.0"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 20 20"
      xmlSpace="preserve"
      onClick={onClick}
    >
      <path
        style={{ fill: 'currentcolor' }}
        d="M19.5,5.4L16.9,10l-4.6-2.6l2.7-0.7C13.8,5,12,4,10,4C8,4,6.2,5,5,6.7C4.8,7.1,4.2,7.2,3.8,6.9
	C3.4,6.6,3.3,6.1,3.5,5.6C5,3.5,7.4,2.2,10,2.2c2.8,0,5.4,1.5,6.7,3.9L19.5,5.4z M15,13.3C13.8,15,12,16,10,16c-2,0-3.8-1-4.9-2.6
	l2.7-0.7L3.1,10l-2.6,4.6l2.8-0.8c1.4,2.4,3.9,3.9,6.7,3.9c2.6,0,5-1.3,6.5-3.4c0.3-0.4,0.2-1-0.2-1.3C15.8,12.8,15.2,12.9,15,13.3z
	"
      />
    </svg>
  )
}
