import { AssessmentQuestion } from '@screens/AssessmentsConfig/CreateAssessment/components/types'
import { Memory } from '@screens/AssessmentsConfig/ExternalAssessment/components/types'

export const checkIfHeaderSibling = ({
  question,
  initialValues,
  index,
}: {
  question: AssessmentQuestion
  initialValues: AssessmentQuestion[]
  index: string
}): { isOpen: boolean; isUnderSubheader: boolean; isUnderHeader: boolean } => {
  let openQuestion: boolean = true
  let prevIndex = index - 1
  let ignoreSubheaders = false
  let underHeader = false
  if (question.type === 'header')
    return { isOpen: true, isUnderSubheader: false, isUnderHeader: false }

  while (prevIndex >= 0) {
    if (initialValues[prevIndex].type === 'header') {
      if (initialValues[prevIndex].closed) {
        openQuestion = false
      }
      underHeader = true
      prevIndex = -1
    } else if (
      initialValues[prevIndex].type === 'subheader' &&
      question.type !== 'subheader'
    ) {
      if (initialValues[prevIndex].closed && !ignoreSubheaders) {
        openQuestion = false
      }
      ignoreSubheaders = true
    }
    prevIndex--
  }

  return {
    isOpen: openQuestion,
    isUnderSubheader: ignoreSubheaders,
    isUnderHeader: underHeader,
  }
}

export const isFormValid = (memory: Memory): boolean => {
  return !(
    !memory.email ||
    !memory.first_name ||
    !memory.last_name ||
    !memory.company_name
  )
}

export const checkFields = (
  memory: Memory,
  initialValues: AssessmentQuestion[],
): boolean => {
  for (const question of initialValues.filter(
    (q) => q?.required && q?.type !== 'header' && q?.type !== 'subheader',
  )) {
    const answer = memory.answers[question.id]
    if (!(answer.text || answer.option || answer.notAnswered)) {
      return false
    }
  }
  return true
}
