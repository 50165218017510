import { CommonPageHeader } from '@/react/components'
import { NavBar } from '../commonComponents/Navbar'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { CreateEditBase } from './CreateEditBase'

export const EditPage = () => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const trainingId = stateService.params.trainingID

  const changeToServer = async (
    trainingData,
    dateTrained,
    dateExpires,
    attachmentId,
  ) => {
    const { client } = trainingData
    await Api.patchV2(`trainings/documentation/${trainingId}`, {
      ...trainingData,
      client: client.id,
      date_trained: dateTrained,
      date_training_expires: dateExpires,
      attachment: attachmentId,
    })
    stateService.go('trainings.documentation', {
      search: stateService.params.previousSearch,
      pageNumber: stateService.params.previousPageNumber,
    })
  }

  return (
    <div>
      <CommonPageHeader headerText={'Training Documentation'} />
      <NavBar></NavBar>
      <CreateEditBase isEdit={true} handleSubmit={changeToServer} />
    </div>
  )
}
