angular
  .module('ccs')
  .controller(
    'ProcoreCtrl',
    function ($scope, $stateParams, Procore, CurrentUser, Notification, $log) {
      $log.debug('ProcoreCtrl')

      $scope.hasAuthToken = !!CurrentUser.getProcoreAccessToken()
      $scope.authTokenURL = Procore.getAuthLink()

      $scope.importProjects = (projects) => {
        if (!projects.length) {
          Notification.warning('No projects selected')
        }
        Procore.importProjects(
          projects,
          (resp) => {
            Notification.success(projects.length + ' successfully imported!')
          },
          (err) => {
            Notification.danger(
              'There was an error importing the projects. Please try again later.',
            )
          },
        )
      }
    },
  )
