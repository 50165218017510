import * as React from 'react'
import { css } from '@emotion/react'

export function HeaderSeparator(props: any) {
  return (
    <div css={headerStyle}>
      <h3>{props.header}</h3>
    </div>
  )
}

const headerStyle = css({
  width: '98%',
  marginTop: '15px',
  marginRight: '1%',
  marginLeft: '1%',
  borderBlockColor: '#939393',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  h3: {
    fontFamily: [
      'open sans',
      'Helvetica Neue',
      'Helvetica',
      'Arial',
      'sans-serif',
    ],
    fontWeight: 'bold',
  },
})
