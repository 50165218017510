const BE_HOST = {
  production: 'https://api.knowledgetransfer.us',
  'zurich-production': 'https://api.knowledgetransfer.us',
  master: 'https://ccs-api-staging.knowledgetransfer.us',
  'zurich-master': 'https://ccs-api-staging.knowledgetransfer.us',
  'dev-2': 'https://ccs-api-dev-2.knowledgetransfer.us',
  dev: 'https://ccs-api-dev.knowledgetransfer.us',
  'zurich-dev': 'https://ccs-api-dev.knowledgetransfer.us',
  local: 'http://localhost:8000',
}

const FE_HOST = {
  production: 'https://knowledgetransfer.us',
  'zurich-production': 'https://zurich.knowledgetransfer.us',
  master: 'https://client-staging.knowledgetransfer.us',
  'zurich-master': 'http://zurich-staging.knowledgetransfer.us/',
  'dev-2': 'https://client-dev-2.knowledgetransfer.us',
  dev: 'https://client-dev.knowledgetransfer.us',
  'zurich-dev': 'https://zurich-dev.knowledgetransfer.us',
  local: 'http://localhost:9000',
}

const BE_HOST_NAME = {
  production: 'Prod',
  'zurich-production': 'ZurichProd',
  master: 'QA',
  'zurich-master': 'ZurichQA',
  'dev-2': 'Dev2',
  dev: 'Dev',
  'zurich-dev': 'ZurichDev',
  local: 'Local',
}

const SENTRY_HOST = {
  Prod: 'https://e794589cc3c14c0a98646a62f1a929da@o75782.ingest.sentry.io/1474897',
  ZurichProd:
    'https://e794589cc3c14c0a98646a62f1a929da@o75782.ingest.sentry.io/1474897',
  QA: 'https://7270888a35d74b0a81384abd2e4373f6@o75782.ingest.sentry.io/167018',
  ZurichQA:
    'https://7270888a35d74b0a81384abd2e4373f6@o75782.ingest.sentry.io/167018',
  Dev2: 'https://0e86e6bff44d47a794a322c62bedad76@o75782.ingest.sentry.io/1474876',
  Dev: 'https://8edcd7b20bbd4c80812a59cf31092068@o75782.ingest.sentry.io/1474888',
  ZurichDev:
    'https://8edcd7b20bbd4c80812a59cf31092068@o75782.ingest.sentry.io/1474888',
  Local: null,
}

angular.module('ccs').service('Configuration', function ($location, $log) {
  // Environment can be overridden by using ?overrideEnv=master or dev or dev-2 or production in URL
  const urlEnv = $location.search().overrideEnv

  const appEnv =
    process.env.BUILD_ENV !== 'production' &&
    process.env.BUILD_ENV !== 'zurich-production' && // Don't allow BE env overriding for production
    [
      'master',
      'zurich-master',
      'dev-2',
      'dev',
      'production',
      'zurich-dev',
      'zurich-production',
    ].includes(urlEnv)
      ? urlEnv
      : process.env.BUILD_ENV || 'local'

  const domainEnv = getDomainEnv(window.location.origin, appEnv, urlEnv)

  this.ApiHost = BE_HOST[appEnv]
  this.ClientHost = FE_HOST[appEnv]
  this.Title = makeTitle(appEnv, $log) + makeDomainTitle(domainEnv, $log)
  this.appEnv = domainEnv
  this.SentryEnv = getEnv(window.location.origin)
  this.SentryHost = SENTRY_HOST[this.SentryEnv]

  this.ProcoreClientID =
    'a25ab564aa87cdebf33b94576e804f0d79568aa48ae83988483c31370acadcf2'
  this.ProcoreApiURL = 'https://app.procore.com'
})

function makeTitle(appEnv, $log) {
  const beEnv = BE_HOST_NAME[appEnv]
  const feEnv = getEnv(window.location.origin)

  if (feEnv === beEnv && appEnv === 'production') return '' // Title should be unchanged on Prod
  if (beEnv === 'Prod' && appEnv === 'zurich-production') return '' // Title should be unchanged on Zurich-Prod
  if (beEnv === 'Prod' && feEnv === 'ZurichProduction') return '' // Title should be unchanged on Zurich-Prod

  if (feEnv !== beEnv) {
    return `(${feEnv}/${beEnv}): ` // Different FE/BE hosts (i.e. overridden BE or local development)
  } else {
    return `(${feEnv}): ` // Same FE/BE host
  }
}
function makeDomainTitle(appEnv, $log) {
  switch (appEnv) {
    case 'zurich-production':
      return 'Zurich Resilience Solutions'
    case 'zurich-dev':
      return 'Zurich Resilience Solutions'
    case 'zurich-master':
      return 'Zurich Resilience Solutions'
    default:
      return 'Real Time Risk Solutions'
  }
}

function getEnv(host) {
  switch (host) {
    case FE_HOST.production:
      return 'Prod'
    case FE_HOST['zurich-production']:
      return 'ZurichProduction'
    case FE_HOST.master:
      return 'QA'
    case FE_HOST['zurich-master']:
      return 'ZurichQA'
    case FE_HOST['dev-2']:
      return 'Dev2'
    case FE_HOST['zurich-dev']:
      return 'ZurichDev'
    case FE_HOST.dev:
      return 'Dev'
    case FE_HOST.local:
      return 'Local'
    default:
      return ''
  }
}

function getDomainEnv(host, env, urlEnv) {
  if (urlEnv) {
    return urlEnv
  }
  switch (host) {
    case FE_HOST.production:
      return 'production'
    case FE_HOST['zurich-production']:
      return 'zurich-production'
    case FE_HOST.master:
      return 'master'
    case FE_HOST['zurich-master']:
      return 'zurich-master'
    case FE_HOST['dev-2']:
      return 'dev-2'
    case FE_HOST['zurich-dev']:
      return 'zurich-dev'
    case FE_HOST.dev:
      return 'dev'
    case FE_HOST.local:
      return env
    default:
      return env
  }
}
