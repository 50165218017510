import { validateFileName } from '@/utils'

angular
  .module('ccs')
  .controller(
    'TrainingFormCtrl',
    function ($scope, Api, $state, $stateParams, $timeout, Notification, $log) {
      $log.debug('TrainingFormCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      function redirect() {
        $state.go('trainings.documentation', {
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }

      $scope.popup = {
        opened: false,
        opened2: false,
      }

      $scope.dateOptions = {
        formatYear: 'yy',
        datepickerMode: 'day',
      }

      $scope.dateOptions2 = {
        formatYear: 'yy',
        datepickerMode: 'day',
        minDate: new Date(),
      }

      $scope.isClickable = true

      function hasNumber(value) {
        return /\d/.test(value)
      }

      $scope.isDateValid = function (date, event, type) {
        let current = angular.copy(event.target.value)
        if (
          hasNumber(current) &&
          current.replace(/[^0-9]/gi, '').length === 8 &&
          date === undefined
        ) {
          $scope.showError('You must enter a valid date for Date ' + type + '.')
          $scope.invalid_date = true
        } else {
          $scope.invalid_date = false
        }
      }

      $scope.$watch(
        'date_trained',
        function () {
          $scope.dateOptions2.minDate = $scope.date_trained
          if (
            $scope.date_expiring &&
            $scope.date_trained &&
            $scope.date_expiring < $scope.date_trained
          ) {
            $scope.showError(
              'You cannot have an Expiration Date prior to the Date Trained.',
            )
            $scope.invalid_date_period = true
          } else {
            $scope.invalid_date_period = false
          }
        },
        true,
      )

      $scope.$watch(
        'date_expiring',
        function () {
          $scope.dateOptions2.minDate = $scope.date_trained
          if (
            $scope.date_expiring &&
            $scope.date_trained &&
            $scope.date_expiring < $scope.date_trained
          ) {
            $scope.showError(
              'You cannot have an Expiration Date prior to the Date Trained.',
            )
            $scope.invalid_date_period = true
          } else {
            $scope.invalid_date_period = false
          }
        },
        true,
      )

      $scope.showError = function (error_msg) {
        $scope.error = error_msg
        $timeout(function () {
          $scope.error = null
        }, 3000)
      }

      $scope.open = function () {
        $scope.popup.opened = true
      }

      $scope.open2 = function () {
        $scope.popup.opened2 = true
      }

      function validSave() {
        if (
          $scope.date_expiring &&
          $scope.date_trained &&
          $scope.date_expiring < $scope.date_trained
        ) {
          $scope.showError(
            'You cannot have an Expiration Date prior to the Date Trained.',
          )
          return false
        }
        return true
      }

      function formatDate(date) {
        if (!date) return null
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      var editing = false

      $scope.submit = function (e) {
        if (!validSave()) return
        $scope.isClickable = false

        var training = {
          ...$scope.training,
          date_trained: formatDate($scope.date_trained),
          date_training_expires: formatDate($scope.date_expiring),
        }

        if ($scope.file_to_upload) {
          Api.uploadFile($scope.file_to_upload, {}, function (response) {
            training.attachment = response.id
            updateTraining(training)
          })
        } else {
          if (training.attachment) {
            training.attachment = training.attachment.id
          }
          updateTraining(training)
        }
      }

      function updateTraining(training) {
        if (editing) {
          if ($stateParams.version === 'v1') {
            Api.TrainingsDocumentationV1.patch({...training, client: training.client.id}, updateHandler)
          } else {
            Api.TrainingsDocumentation.patch({...training, client: training.client.id}, updateHandler)
          }
        } else {
          Api.TrainingsDocumentation.post(training, updateHandler)
        }
      }

      function updateHandler(resp) {
        if (resp) {
          $scope.isClickable = true
          var action = editing ? 'Updated!' : 'Created!'
          Notification.success('Training Successfully ' + action)
          redirect()
        }
      }

      if ($stateParams.trainingID) {
        editing = true

        if ($stateParams.version === 'v1') {
          Api.TrainingsDocumentationV1.byIDWithoutDeletedParam(
            $stateParams.trainingID,
            function (resp) {
              $scope.training = resp
              $scope.date_trained = resp.date_trained
                ? new Date(resp.date_trained)
                : null
              $scope.date_expiring = resp.date_training_expires
                ? new Date(resp.date_training_expires)
                : null
              if ($scope.training.attachment) {
                $scope.training.attachment.file_name =
                  $scope.training.attachment.file.split(/[/]+/).pop()
              }
            },
          )
        } else {
          Api.TrainingsDocumentation.byIDWithoutDeletedParam(
            $stateParams.trainingID,
            function (resp) {
              $scope.training = resp
              $scope.date_trained = resp.date_trained
                ? new Date(resp.date_trained)
                : null
              $scope.date_expiring = resp.date_training_expires
                ? new Date(resp.date_training_expires)
                : null
              if ($scope.training.attachment) {
                $scope.training.attachment.file_name =
                  $scope.training.attachment.file.split(/[/]+/).pop()
              }
            },
          )
        }
      }

      $scope.dzAddedFile = function (file) {
        var errors = validateFileName(
          file.files[0].name.substring(0, file.files[0].name.lastIndexOf('.')),
        )
        if (errors === false) {
          $scope.fileError = null
          $scope.addFile(file)
        } else {
          $scope.$apply(function () {
            $scope.fileError = errors.join('\n')
          })
        }
      }

      $scope.addFile = function (file) {
        $scope.$apply(function () {
          $scope.file_to_upload = file.files[0]
        })
      }
    },
  )
