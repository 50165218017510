import * as React from 'react'
import { css } from '@emotion/react'
import { getTranslatedString } from '@/utils'
import { CustomCheckbox, ReloadElement } from '@/react/components'
import {
  getStartingValue,
  NoteElement,
} from '@screens/MobileForms/CreateEditPages/components'

export const RadioField = ({
  fieldData,
  formMemory,
  possibleGroupData,
  onChange,
}) => {
  const [error, setError] = React.useState(false)

  const startingOption = getStartingValue(
    possibleGroupData,
    formMemory,
    fieldData,
    'text',
  )

  function checkIfOptionIsChosen(startingOption, id): boolean {
    return (
      !!startingOption && startingOption === fieldData.answer_options[id]?.name
    )
  }

  const [firstCheckmark, setFirstCheckmark] = React.useState(
    checkIfOptionIsChosen(startingOption, 0),
  )
  const [secondCheckmark, setSecondCheckmark] = React.useState(
    checkIfOptionIsChosen(startingOption, 1),
  )
  const [reload, setReload] = React.useState(false)

  const baseStyle = getBaseStyle()

  function onCheckboxChange(checkboxIndex, addparams, value) {
    if (value === false) {
      if (checkboxIndex === 0) {
        setFirstCheckmark(false)
      } else {
        setSecondCheckmark(false)
      }
    } else {
      if (checkboxIndex === 0) {
        setFirstCheckmark(true)
        setSecondCheckmark(false)
      } else {
        setSecondCheckmark(true)
        setFirstCheckmark(false)
      }
      onChange({
        value: fieldData.answer_options[checkboxIndex].name,
        possibleGroupData,
        id: fieldData.id,
      })
      setReload(true)
    }
    setError(!value && fieldData.required)
  }

  React.useEffect(() => {
    if (fieldData.required && !firstCheckmark && !secondCheckmark) {
      setError(true)
    }
  }, [])

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  if (!fieldData.answer_options.length) return null

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="label-holder">
        <label css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      {reload ? (
        <div className="check-row">
          <ReloadElement setReload={setReload} />
        </div>
      ) : (
        <div className="check-row">
          {fieldData?.answer_options[0] ? (
            <div className="single-check">
              {firstCheckmark}
              <CustomCheckbox
                onChange={(addparams, value) => {
                  setError(!value && fieldData.required)
                  onCheckboxChange(0, addparams, value)
                }}
                isDefaultChecked={firstCheckmark}
                isRound
                additionalStyle={{
                  border: error ? '1px solid #C80404' : '',
                  borderRadius: '50%',
                }}
              />
              <div>{getTranslatedString(fieldData.answer_options[0].name)}</div>
            </div>
          ) : null}
          {fieldData?.answer_options[1] ? (
            <div className="single-check">
              <CustomCheckbox
                onChange={(addparams, value) => {
                  setError(!value && fieldData.required)
                  onCheckboxChange(1, addparams, value)
                }}
                isDefaultChecked={secondCheckmark}
                additionalStyle={{
                  border: error ? '1px solid #C80404' : '',
                  borderRadius: '50%',
                }}
                isRound
              />
              <div>{getTranslatedString(fieldData.answer_options[1].name)}</div>
            </div>
          ) : null}
        </div>
      )}
    </section>
  )
}

function getBaseStyle() {
  return css({
    marginBottom: 15,
    '.check-row': {
      display: 'flex',
      '.single-check': {
        marginRight: 30,
        display: 'flex',
        p: {
          marginLeft: 20,
        },
      },
    },
  })
}
