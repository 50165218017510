import { EditIcon } from '@/react/componentAssets'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const TrainingRow = ({
  index,
  search,
  setRefresh,
  training,
  user,
  handleAssign,
  listToAssign,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { name } = training
  const [hoveredField, setHoveredField] = useState(null)

  const isGroup = training.hasOwnProperty('trainings')

  return (
    <tr
      className={classNames(isGroup ? 'group__row' : 'project__row', {
        dark: index % 2 === 0 && !isGroup,
        light: index % 2 === 0 && isGroup,
      })}
    >
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: training?.name,
            max__length: 112,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={name}
        />
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={
              !isGroup
                ? listToAssign.includes(training?.id)
                : training?.trainings?.every((id) => listToAssign.includes(id))
            }
            onChange={() => {
              handleAssign(training, isGroup)
            }}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
