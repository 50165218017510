import { useEffect } from 'react'

export const useAutoSizeTextArea = (
  textAreaRef: HTMLTextAreaElement,
  value: string,
) => {
  useEffect(() => {
    if (textAreaRef) {
      textAreaRef.style.height = '0px'

      const scrollHeight = textAreaRef.scrollHeight

      textAreaRef.style.height = `${scrollHeight}px`
    }
  }, [textAreaRef, value])
}
