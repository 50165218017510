import { updateQueryParams } from '@/utils/updateQueryParams'
import { useEffect } from 'react'

type Props = {
  setChecked: (boolean: boolean) => void
  checked: boolean
}

export const Toggle = ({ setChecked, checked }: Props) => {
  return (
    <div className="switch__wrapper">
      <h4 className="page__text">All</h4>
      <label className="switch">
        <input
          className="projectCheckbox"
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <span className="slider round" />
      </label>
      <h4 className="page__text">Incomplete</h4>
    </div>
  )
}
