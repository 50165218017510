export const formChoicesBackUp = {
  hazard_choices: [
    { value: 0, label: 'Acute Health Hazard' },
    {
      value: 1,
      label: 'Chronic Health Hazard',
    },
    { value: 2, label: 'Fire Hazard' },
    { value: 3, label: 'Reactive Hazard' },
    {
      value: 4,
      label: 'Sudden Release of Pressure Hazard',
    },
  ],
  toxic_chemical_choices: [
    { value: 0, label: 'No' },
    { value: 1, label: 'Yes' },
    {
      value: 2,
      label: 'N/A',
    },
  ],
  whmis_physical_hazards_choices: [
    { value: 0, label: 'Not Applicable' },
    {
      value: 1,
      label: 'None',
    },
    { value: 2, label: 'Combustible dusts' },
    { value: 3, label: 'Corrosive to metals' },
    {
      value: 4,
      label: 'Flammable aerosols',
    },
    { value: 5, label: 'Flammable gases' },
    { value: 6, label: 'Flammable liquids' },
    {
      value: 7,
      label: 'Flammable solids',
    },
    { value: 8, label: 'Gases under pressure' },
    { value: 9, label: 'Organic peroxides' },
    {
      value: 10,
      label: 'Oxidizing gases',
    },
    { value: 11, label: 'Oxidizing liquids' },
    { value: 12, label: 'Oxidizing solids' },
    {
      value: 13,
      label: 'Physical hazards not otherwise classified',
    },
    { value: 14, label: 'Pyrophoric gases' },
    { value: 15, label: 'Pyrophoric liquids' },
    {
      value: 16,
      label: 'Pyrophoric solids',
    },
    { value: 17, label: 'Self-heating substances and mixtures' },
    {
      value: 18,
      label: 'Self-reactive substances and mixtures',
    },
    { value: 19, label: 'Simple asphyxiants' },
    {
      value: 20,
      label:
        'Substances and mixtures which, in contact with water, emit flammable gases',
    },
  ],
  whmis_health_hazards_choices: [
    { value: 0, label: 'Not Applicable' },
    {
      value: 1,
      label: 'None',
    },
    { value: 2, label: 'Acute toxicity' },
    { value: 3, label: 'Aspiration hazard' },
    {
      value: 4,
      label: 'Biohazardous infectious materials',
    },
    { value: 5, label: 'Carcinogenicity' },
    { value: 6, label: 'Germ cell mutagenicity' },
    {
      value: 7,
      label: 'Health hazards not otherwise classified',
    },
    { value: 8, label: 'Reproductive toxicity' },
    {
      value: 9,
      label: 'Respiratory or skin sensitization',
    },
    { value: 10, label: 'Serious eye damage/eye irritation' },
    {
      value: 11,
      label: 'Skin corrosion/irritation',
    },
    { value: 12, label: 'Specific target organ toxicity – repeated exposure' },
    {
      value: 13,
      label: 'Specific target organ toxicity – single exposure',
    },
  ],
  health_choices: [
    { value: 0, label: '0 - No significant risk to health.' },
    {
      value: 1,
      label: '1 - Irritation or minor reversible injury possible.',
    },
    { value: 2, label: '2 - Temporary or minor injury may occur.' },
    {
      value: 3,
      label:
        '3 - Major injury is likely unless prompt action is taken and medical treatment is given.',
    },
    {
      value: 4,
      label:
        '4 - Life threatening, major or permanent damage may result from single or repeated overexposures.',
    },
  ],
  flammability_choices: [
    { value: 0, label: '0 - Materials that will not burn.' },
    {
      value: 1,
      label:
        '1 - Materials that must be preheated before ignition will occur. Includes liquids, solids and semi solids having a flash point above 200 °F. (Class IIIB).',
    },
    {
      value: 2,
      label:
        '2 - Materials which must be moderately heated or exposed to high ambient temperatures before ignition will occur. Includes liquids having a flash point at or above 100 °F but below 200 °F. (Classes II & IIIA).',
    },
    {
      value: 3,
      label:
        '3 - Materials capable of ignition under almost all normal temperature conditions. Includes flammable liquids with flash points below 73 °F and boiling points above 100 °F, as well as liquids with flash points between 73 °F and 100 °F. (Classes IB & IC).',
    },
    {
      value: 4,
      label:
        '4 - Flammable gases, or very volatile flammable liquids with flash points below 73 °F, and boiling points below 100 F. Materials may ignite spontaneously with air. (Class IA).',
    },
  ],
  physical_hazards_choices: [
    {
      value: 0,
      label:
        '0 - Materials that are normally stable, even under fire conditions, and will not react with water, polymerize, decompose , condense, or self-react. Non-explosives.',
    },
    {
      value: 1,
      label:
        '1 - Materials that are normally stable but can become unstable (self-react) at high temperatures and pressures. Materials may react non-violently with water or undergo hazardous polymerization in the absence of inhibitors.',
    },
    {
      value: 2,
      label:
        '2 - Materials that are unstable and may undergo violent chemical changes at normal temperature and pressure with low risk for explosion. Materials may react violently with water or form peroxides upon exposure to air.',
    },
    {
      value: 3,
      label:
        '3 - Materials that may form explosive mixtures with water and are capable of detonation or explosive reaction in the presence of a strong initiating source. Materials may polymerize, decompose, self-react, or undergo other chemical change at normal temperature and pressure with moderate risk of explosion.',
    },
    {
      value: 4,
      label:
        '4 - Materials that are readily capable of explosive water reaction, detonation or explosive decomposition, polymerization, or self-reaction at normal temperature and pressure.',
    },
  ],
  ppe_choices: [
    { value: 0, label: 'A - Safety glasses' },
    {
      value: 1,
      label: 'B - Safety glasses, gloves',
    },
    { value: 2, label: 'C - Safety glasses, gloves, protective apron' },
    {
      value: 3,
      label: 'D - Face shield, gloves, protective apron',
    },
    { value: 4, label: 'E - Safety glasses, gloves, dust respirator' },
    {
      value: 5,
      label: 'F - Safety glasses, gloves, protective apron, dust respirator',
    },
    { value: 6, label: 'G - Safety glasses, gloves, vapor respirator' },
    {
      value: 7,
      label: 'H - Splash goggles, gloves, protective apron, vapor respirator',
    },
    {
      value: 8,
      label: 'I - Safety glasses, gloves, dust respirator, vapor respirator',
    },
    {
      value: 9,
      label:
        'J - Splash goggles, gloves, protective apron, dust respirator, vapor respirator',
    },
    { value: 10, label: 'K - Air line mask or hood, gloves, full suit, boots' },
    {
      value: 11,
      label: 'L-Z - Site specific requirements',
    },
  ],
}
