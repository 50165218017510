import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const ViewIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <path
        d="M17.0403 15.3803L13.4303 11.7303C14.5152 10.2184 14.9916 8.35368 14.7647 6.50669C14.5379 4.65971 13.6244 2.96569 12.2059 1.76131C10.7873 0.55693 8.96755 -0.0696308 7.10823 0.00615249C5.24892 0.0819358 3.48617 0.854515 2.17034 2.17034C0.854515 3.48617 0.0819358 5.24891 0.00615249 7.10823C-0.0696308 8.96755 0.55693 10.7873 1.76131 12.2059C2.96569 13.6244 4.65971 14.5379 6.50669 14.7647C8.35368 14.9916 10.2184 14.5152 11.7303 13.4303L15.3503 17.0403C15.4595 17.1543 15.5906 17.2451 15.7359 17.3071C15.8811 17.3691 16.0374 17.401 16.1953 17.401C16.3532 17.401 16.5094 17.3691 16.6547 17.3071C16.7999 17.2451 16.931 17.1543 17.0403 17.0403C17.2565 16.8181 17.3775 16.5203 17.3775 16.2103C17.3775 15.9002 17.2565 15.6024 17.0403 15.3803ZM2.40026 7.44026C2.40026 6.45135 2.69351 5.48466 3.24291 4.66241C3.79232 3.84016 4.57321 3.1993 5.48684 2.82086C6.40048 2.44243 7.40581 2.34341 8.37571 2.53633C9.34562 2.72926 10.2365 3.20546 10.9358 3.90473C11.6351 4.60399 12.1113 5.4949 12.3042 6.46481C12.4971 7.43471 12.3981 8.44005 12.0197 9.35368C11.6412 10.2673 11.0004 11.0482 10.1781 11.5976C9.35587 12.147 8.38917 12.4403 7.40026 12.4403C6.07418 12.4403 4.80241 11.9135 3.86473 10.9758C2.92705 10.0381 2.40026 8.76634 2.40026 7.44026Z"
        fill="currentcolor"
      />
    </svg>
  )
}
