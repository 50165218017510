import * as React from 'react'
import { css } from '@emotion/react'
import {
  CurrencyInput,
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@/react/components'
import { getTranslatedString } from '@/utils'
import { default as ReactSelect } from 'react-select'
import { TextInputStyle } from '@screens/components'
import {
  getStartingValue,
  NoteElement,
} from '@screens/MobileForms/CreateEditPages/components'

export const MeasureFields = ({
  fieldData,
  formMemory,
  possibleGroupData,
  onChange,
}) => {
  const startData = getStartingValue(
    possibleGroupData,
    formMemory,
    fieldData,
    'text',
  )

  const options = {
    currency: [
      { value: '$', label: getTranslatedString('US Dollar') },
      { value: '€', label: getTranslatedString('Euro') },
      {
        value: '₣',
        label: getTranslatedString('Swiss Franc'),
      },
      {
        value: '£',
        label: getTranslatedString('British Pound'),
      },
      {
        value: 'C$',
        label: getTranslatedString('Canadian Dollar'),
      },
    ],
    mass: [
      {
        value: 't',
        label: getTranslatedString('Metric Ton'),
      },
      {
        value: 'kg',
        label: getTranslatedString('Kilogram'),
      },
      {
        value: 'g',
        label: getTranslatedString('Gram'),
      },
      {
        value: 'lbs',
        label: getTranslatedString('Pounds'),
      },
    ],
    dimension: [
      {
        label: getTranslatedString('Meter'),
        value: 'm',
      },
      {
        label: getTranslatedString('Centimeter'),
        value: 'cm',
      },
      {
        label: getTranslatedString('Millimeter'),
        value: 'mm',
      },
      {
        label: getTranslatedString('Kilometer'),
        value: 'km',
      },
      {
        label: getTranslatedString('Feet'),
        value: 'ft',
      },
      {
        label: getTranslatedString('Inches'),
        value: 'in',
      },
      {
        label: getTranslatedString('Miles'),
        value: 'mi',
      },
      {
        label: getTranslatedString('Square Feet'),
        value: 'ft²',
      },
      {
        label: getTranslatedString('Square Meters'),
        value: 'm²',
      },
      {
        label: getTranslatedString('Cubic Feet'),
        value: 'ft³',
      },
      {
        label: getTranslatedString('Cubic Meters'),
        value: 'm³',
      },
    ],
    temperature: [
      {
        value: 'ºF',
        label: getTranslatedString('Fahrenheit'),
      },
      {
        value: 'ºC',
        label: getTranslatedString('Celsius'),
      },
      {
        value: 'K',
        label: getTranslatedString('Kelvin'),
      },
    ],
  }
  const optionsByKey = {
    currency: {
      $: { value: '$', label: getTranslatedString('US Dollar') },
      '€': { value: '€', label: getTranslatedString('Euro') },
      E: { value: '€', label: getTranslatedString('Euro') },
      '₣': {
        value: '₣',
        label: getTranslatedString('Swiss Franc'),
      },
      '£': {
        value: '£',
        label: getTranslatedString('British Pound'),
      },
      C$: {
        value: 'C$',
        label: getTranslatedString('Canadian Dollar (CAD)'),
      },
    },
    mass: {
      t: {
        value: 't',
        label: getTranslatedString('Metric Ton'),
      },
      kg: {
        value: 'kg',
        label: getTranslatedString('Kilogram'),
      },
      g: {
        value: 'g',
        label: getTranslatedString('Gram'),
      },
      lbs: {
        value: 'lbs',
        label: getTranslatedString('Pounds'),
      },
    },
    dimension: {
      m: {
        label: getTranslatedString('Meter'),
        value: 'm',
      },
      cm: {
        value: 'cm',
        label: getTranslatedString('Centimeter'),
      },
      mm: {
        value: 'mm',
        label: getTranslatedString('Millimeter'),
      },
      km: {
        value: 'km',
        label: getTranslatedString('Kilometer'),
      },
      ft: {
        value: 'ft',
        label: getTranslatedString('Feet'),
      },
      in: {
        value: 'in',
        label: getTranslatedString('Inches'),
      },
      mi: {
        value: 'mi',
        label: getTranslatedString('Miles'),
      },
      'ft²': {
        label: getTranslatedString('Square Feet'),
        value: 'ft²',
      },
      'm²': {
        label: getTranslatedString('Square Meters'),
        value: 'm²',
      },
      'ft³': {
        label: getTranslatedString('Cubic Feet'),
        value: 'ft³',
      },
      'm³': {
        label: getTranslatedString('Cubic Meters'),
        value: 'm³',
      },
    },
    temperature: {
      ºC: {
        value: 'ºC',
        label: getTranslatedString('Celsius'),
      },
      ºF: {
        value: 'ºF',
        label: getTranslatedString('Fahrenheit'),
      },
      K: {
        value: 'K',
        label: getTranslatedString('Kelvin'),
      },
    },
  }

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [number, setNumber] = React.useState(
    startData === ''
      ? fieldData.type === 'currency'
        ? 0
        : ''
      : Number(startData.replace(/[^0-9.-]/g, '')) *
          (fieldData.type === 'currency' ? 100 : 1),
  )
  const [dropdown, setDropdown] = React.useState(
    startData === ''
      ? options[fieldData.type][0]
      : optionsByKey[fieldData.type][
          startData.replace(/[0-9]/g, '').replace(/[-.,\s]/g, '')
        ],
  )

  const [error, setError] = React.useState(false)

  React.useEffect(() => {
    onChange({
      value:
        fieldData.type === 'currency'
          ? getFullFieldData(dropdown?.value, number)
          : number === 0 || number === ''
          ? ''
          : number + dropdown.value,
      possibleGroupData,
      id: fieldData.id,
    })
  }, [number, dropdown])

  React.useEffect(() => {
    if (fieldData.required && !number) {
      setError(true)
    }
  }, [])

  const baseStyles = getBaseStyles(variableColor)

  const inputRef = React.useRef()

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyles}>
      <div className="label-holder">
        <label className="label-holder" css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      <div className="field-subrow">
        <div className="selector-holder">
          <ReactSelect
            options={options[fieldData.type]}
            defaultValue={dropdown}
            onChange={(value) => {
              setDropdown(value)
            }}
            styles={{
              ...dropdownOptionStyles(variableColor, '30px'),
              control: (provided, state) => ({
                ...provided,
                minHeight: '0px',
                height: '30px',
                border: error && '1px solid #C80404',
              }),
            }}
            theme={(theme) => dropdownFiledTheme(theme, variableColor)}
          />
        </div>
        {fieldData.type === 'currency' ? (
          <CurrencyInput
            onValueChange={(value) => {
              setNumber(value)
              setError(!value && fieldData.required)
            }}
            style={{
              textAlign: 'left',
              height: 30,
              width: 240,
              maxWidth: '60%',
              border: error ? '1px solid #C80404 !important' : '',
              '@media (max-width: 410px)': {
                maxWidth: '50%',
              },
              '@media (max-width: 355px)': {
                maxWidth: '30%',
              },
            }}
            value={number}
            currency={dropdown.value}
          />
        ) : (
          <div className="input-body">
            <div key={'1'} className="fake-measurement-body">
              <p className="sing-mover">{number + ''}</p>
              <p>{dropdown.value}</p>
            </div>
            <input
              key={'2'}
              className="pure-number-input"
              css={css({
                ...TextInputStyle,
                backgroundColor: 'transparent',
                height: 30,
                border: error ? '1px solid #C80404' : '',
              })}
              type="text"
              inputMode="numeric"
              defaultValue={number}
              ref={inputRef}
              onChange={(env) => {
                setError(!inputRef.current.value && fieldData.required)
                inputRef.current.value = env.target.value.replace(
                  /[^0-9.-]/g,
                  '',
                )
                setNumber(env.target.value.replace(/[^0-9.-]/g, ''))
              }}
            />
          </div>
        )}
      </div>
    </section>
  )
}

function getBaseStyles(variableColor) {
  return css({
    width: 400,
    maxWidth: '100%',
    marginBottom: 15,
    '.input-body': {
      display: 'flex',
      maxWidth: '60%',
      width: 240,
      '@media (max-width: 410px)': {
        maxWidth: '50%',
      },
      '@media (max-width: 355px)': {
        maxWidth: '30%',
      },
    },
    '.field-subrow': {
      width: '100%',
      display: 'flex',
      '.selector-holder': {
        width: 140,
        marginRight: 25,
        minWidth: 100,
        maxWidth: 'calc(40% - 20px)',
        '@media (max-width: 410px)': {
          maxWidth: 'calc(50% - 20px)',
        },
        '@media (max-width: 355px)': {
          maxWidth: 'calc(70% - 20px)',
        },
        '@media (max-width: 277px)': {
          minWidth: 50,
        },
      },
      '.pure-number-input': {
        paddingRight: 30,
        width: 240,
        maxWidth: '100%',
      },
      '.fake-measurement-body': {
        display: 'flex',
        marginRight: 'calc(-100% - 10px)',
        fontSize: '14px',
        lineHeight: '30px',
        width: '100%',
        maxWidth: '100%',
        marginLeft: 10,
        '.sing-mover': {
          zIndex: 0,
          maxWidth: 'calc(100% - 30px)',
          color: 'transparent',
        },
      },
    },
  })
}

function getFullFieldData(currency, value) {
  if (value === 0) return ''
  if (value < 9) {
    return currency + '0.0' + value
  }
  if (value < 99) {
    return currency + '0.' + value
  }
  const combinedWithCurr = currency + value
  const len = combinedWithCurr.length
  return (
    combinedWithCurr.substring(0, len - 2) +
    '.' +
    combinedWithCurr.substring(len - 2)
  )
}
