import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const EditIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="12 12 58 58"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <g id="icons">
        <path
          className="cls-1"
          style={{ fill: 'currentcolor' }}
          d="M30.37,43.27a1.22,1.22,0,0,0-.37.88v7.07a1.25,1.25,0,0,0,1.25,1.25h7.07a1.25,1.25,0,0,0,.88-.36L62.28,29.05l-8.84-8.83Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'currentcolor' }}
          d="M65.67,25.67a6.25,6.25,0,0,0-8.84-8.84l-1.62,1.62,8.84,8.84Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'currentcolor' }}
          d="M66.25,35A1.24,1.24,0,0,0,65,36.25v20A8.77,8.77,0,0,1,56.25,65h-30a8.77,8.77,0,0,1-8.75-8.75v-30a8.76,8.76,0,0,1,8.75-8.74h20a1.25,1.25,0,0,0,0-2.5h-20A11.26,11.26,0,0,0,15,26.25v30A11.27,11.27,0,0,0,26.25,67.5h30A11.27,11.27,0,0,0,67.5,56.25v-20A1.25,1.25,0,0,0,66.25,35Z"
        />
      </g>
    </svg>
  )
}
