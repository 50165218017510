import { UISref } from '@/react/components/UISref'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { ButtonElement } from '@/react/components'
import { useState } from 'react'
import { printPageWithFirefoxWarning } from '@/utils/printConnected'

export const ReportButtons = () => {
  const { stateService } = useRouter()
  const [popUp, setPopUp] = useState<Element | null>(null)

  return (
    <div css={baseStyle} className="no-print">
      <ButtonElement
        text={'Print'}
        minWidth={'77px'}
        functionToTrigger={() => {
          printPageWithFirefoxWarning(setPopUp)
        }}
      />
      <UISref
        to={'app.timecard.daily_reports'}
        params={{
          ...stateService.params,
        }}
      >
        <ButtonElement text={'Back'} minWidth={'77px'} />
      </UISref>
      {popUp}
    </div>
  )
}

const baseStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '60px',
})
