import moment from 'moment'

export function makeMonthData(month, year) {
  const monthToCreate = moment(0).year(year).month(month)

  const firstWeekFirstDay = moment(monthToCreate)
    .startOf('month')
    .startOf('week')
    .add(1, 'days')
  const lastWeekLastDay = moment(monthToCreate)
    .endOf('month')
    .endOf('week')
    .startOf('day')
    .add(1, 'days')

  const allVisibleDays = [
    {
      date: firstWeekFirstDay,
      currentMonth: month === moment(firstWeekFirstDay).month(),
      week: moment(firstWeekFirstDay).week(),
    },
  ]

  while (true) {
    const weekNumber = moment(
      allVisibleDays[allVisibleDays.length - 1].date,
    ).week()
    allVisibleDays.push({
      date: moment(allVisibleDays[allVisibleDays.length - 1].date).add(1, 'd'),
      currentMonth:
        moment(allVisibleDays[allVisibleDays.length - 1].date)
          .add(1, 'd')
          .month() === month,
      week: weekNumber,
    })

    if (
      lastWeekLastDay.valueOf() ===
      allVisibleDays[allVisibleDays.length - 1].date.valueOf()
    ) {
      break
    }
    if (allVisibleDays.length === 40) {
      break
    }
  }
  return { allVisibleDays }
}
