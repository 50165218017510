import * as React from 'react'
import { css } from '@emotion/react'

import {
  TableHeaders,
  Search,
  PageSwitcher,
  AdvancedFilter,
  useAngularServices,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { parseInt } from 'lodash'
import { singleHeaderTypes } from '@screens/components'
import { CurrentPageTableBody } from '@screens/Timecard/CurrentPage/components/CurrentPageTableBody'

type Props = {}

const PAGE_LENGTH = 20

export const ReloadableElements: React.FC<Props> = () => {
  const { stateService } = useRouter()

  const [count, setCount] = React.useState<number>(0)
  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [order, setOrder] = React.useState(
    stateService.params.order || 'supervisor_full_name',
  )
  const [advancedFilters, setAdvancedFilters] = React.useState(
    getFilterStates(stateService),
  )
  const [search, setSearch] = React.useState(stateService.params.search || '')

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'supervisor',
      name: 'Supervisor',
      listLocation: 'app.timecard.current_page',
      type: 'supervisor_full_name',
      filterHeader: true,
      additionalStyles: {
        width: '20%',
        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'supervisor_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'employee',
      name: 'Employee',
      listLocation: 'app.timecard.current_paged_list',
      type: 'user_full_name',
      filterHeader: true,
      additionalStyles: {
        width: '20%',
        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'user_full_name'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'status',
      name: 'Status',
      listLocation: 'app.timecard.current_paged_list',
      type: 'status',
      filterHeader: true,
      additionalStyles: {
        width: '20%',
        textAlign: 'left',
        p: {
          width: 'fit-content',
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
      additionalInnerElement: (
        <AdvancedFilter
          fieldName={'status_value'}
          advancedFilters={advancedFilters}
          setAdvancedFilters={setAdvancedFilters}
        />
      ),
    },
    {
      className: 'is_meal_missed',
      name: 'Missed Meal',
      listLocation: 'app.timecard.current_paged_list',
      type: 'is_meal_missed',
      filterHeader: true,
      additionalStyles: { width: '25%', textAlign: 'left' },
    },
    {
      className: 'hours',
      name: 'Hours',
      listLocation: 'app.timecard.current_paged_list',
      type: 'hours',
      filterHeader: true,
      additionalStyles: { width: '15%', textAlign: 'left' },
    },
  ]

  return (
    <section css={baseStyle}>
      <div className="search-base">
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
        />
      </div>
      <table className="dual-color-table">
        <TableHeaders
          headers={TABLE_HEADER_SETTINGS}
          popUpLocation={false}
          callback={setOrder}
          stickyElement
        />
        <CurrentPageTableBody
          order={order}
          search={search}
          page={page}
          setCount={setCount}
          advancedFilters={advancedFilters}
        />
      </table>
      <div className="page-switcher-base">
        <PageSwitcher
          pages={PAGE_LENGTH}
          listCount={count}
          currentPage={page}
          callback={setPage}
        />
      </div>
    </section>
  )
}

function getFilterStates(stateService) {
  const filtersToReturn = {
    supervisor_full_name: {},
    user_full_name: {},
    status_value: {},
  }
  if (stateService.params.supervisor_full_name)
    filtersToReturn.supervisor_full_name = JSON.parse(
      stateService.params.supervisor_full_name,
    )
  if (stateService.params.user_full_name)
    filtersToReturn.user_full_name = JSON.parse(
      stateService.params.user_full_name,
    )
  if (stateService.params.status_value)
    filtersToReturn.status_value = JSON.parse(stateService.params.status_value)
  return filtersToReturn
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: '100%',
  backgroundColor: 'white',
  marginLeft: 1,
  table: {
    margin: '20px 25px',
    minWidth: 'calc(100% - 50px)',
    '.static-nav': {
      borderBottomWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomColor: '#e7eaec',
    },
  },
  '.search-base': {
    padding: '20px 25px',
  },
  '.page-switcher-base': {
    marginLeft: '20px',
  },
  '.dual-color-table': {
    marginTop: 0,
  },
})
