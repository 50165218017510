import * as React from 'react'
import { css } from '@emotion/react'

import { updateQueryParams } from '@/utils/updateQueryParams'
import { useAngularServices } from '@components/ReactAngularContext'
import { CheckIcon, XIcon } from '../componentAssets'

type Props = {
  search?: string
  searchCallback: any
  pageCallback: any
  updateUrl?: boolean
  extraFunction?: () => void
  width?: string | number
}

export const Search: React.FC<Props> = ({
  search = '',
  searchCallback,
  pageCallback,
  updateUrl = true,
  extraFunction = () => {},
  width = 369,
}: Props) => {
  const [searchState, setSearchState] = React.useState(search)
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  function onSearch() {
    searchCallback(searchState)
    pageCallback(1)
    if (updateUrl)
      updateQueryParams({ search: searchState, pageNumber: 1 }, true)
  }

  const inputRef = React.useRef()

  const base = getBaseStyle(variableColor, width)

  return (
    <div css={base}>
      <div
        css={searchState ? nonActiveSearch : activeSearch}
        className="base"
        id={'search-form-base'}
      >
        <input
          ref={inputRef}
          css={inputStyle}
          id={'search'}
          name={'search'}
          className="general-input-style"
          placeholder={'Search'}
          type="text"
          onChange={(value) => {
            setSearchState(value.target.value)
          }}
          defaultValue={search}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearch()
            }
          }}
        />
        {searchState
          ? [
              <div
                key="clear"
                className="search-row-buttons clear-button"
                onClick={() => {
                  extraFunction()
                  setSearchState('')
                  searchCallback('')
                  pageCallback(1)
                  updateQueryParams({ search: '' }, true)
                  if (updateUrl)
                    updateQueryParams({ search: '', pageNumber: 1 }, true)
                  inputRef.current.value = ''
                }}
              >
                <XIcon height={16} />
              </div>,
              <div
                key="search"
                className="search-row-buttons search-button"
                onClick={() => {
                  onSearch()
                }}
              >
                <CheckIcon height={'12.3px'} width={16} />
              </div>,
            ]
          : null}
      </div>
    </div>
  )
}

const inputStyle = css({
  color: 'rgb(103, 106, 108)',
  backgroundColor: 'rgb(255, 255, 255)',
  borderColor: 'rgb(229, 230, 231)',
  borderRadius: 0,
  borderStyle: 'solid',
  paddingBottom: '6px',
  paddingLeft: '12px',
  paddingRight: '12px',
  paddingTop: '6px',
  height: '30px',
  outline: 'none',
})

function getBaseStyle(variableColor: string, width: string | number) {
  return css({
    '.base': {
      display: 'flex',
      width: width,
      button: {
        height: '30px',
        borderWidth: 0,
        color: 'white',
        backgroundColor: variableColor,
      },
      '.clear': {
        display: 'flex',
        cursor: 'pointer',
        height: '30px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#ED5565',
        width: '30px',
        span: {
          width: '12px',
          color: 'white',
        },
      },
    },
    '.search-row-buttons': {
      height: 30,
      width: 30,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: variableColor,
      cursor: 'pointer',
    },
    '.clear-button': {
      color: variableColor,
      ':hover': {
        backgroundColor: variableColor,
        color: 'white',
      },
    },
    '.search-button': {
      color: 'white',
      backgroundColor: variableColor,
      ':hover': {
        backgroundColor: 'transparent',
        color: variableColor,
      },
    },
  })
}

const activeSearch = css({
  input: {
    width: '100%',
  },
})

const nonActiveSearch = css({
  input: {
    width: 'calc(100% - 32px)',
  },
})
