import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'ToolboxFormCtrl',
    function (
      $scope,
      $state,
      $stateParams,
      Api,
      app,
      metadataService,
      Notification,
      $log,
    ) {
      $log.debug('ToolboxFormCtrl')

      $scope.disableButton = false

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.metadataService = metadataService

      $scope.toolbox_talk_id = $stateParams.toolbox
        ? $stateParams.toolbox
        : false
      $scope.app = app
      $scope.app.toolbox_talks_display = pluralize.singular(
        app.toolbox_talks_display,
      )

      var editing = false
      if ($stateParams.toolbox) {
        editing = true
        Api.ToolboxTopics.byID($stateParams.toolbox, function (resp) {
          $scope.toolbox = { ...resp }
          $scope.innitial_toolbox = { ...resp }
        })
      }

      function validSave() {
        return true
      }

      $scope.submit = function (e) {
        if (
          !(
            $scope.innitial_toolbox?.name !== $scope?.toolbox?.name ||
            $scope.innitial_toolbox.summary !== $scope.toolbox.summary ||
            $scope.innitial_toolbox.consider !== $scope.toolbox.consider ||
            $scope.innitial_toolbox.importance !== $scope.toolbox.importance ||
            $scope.innitial_toolbox.discussion !== $scope.toolbox.discussion
          )
        ) {
          Notification.danger('Please edit at least one field before saving.')
          return
        }
        if (!validSave()) return
        var toolbox = {
          ...$scope.toolbox,
        }
        $scope.disableButton = true
        updateToolbox(toolbox)
      }

      function updateToolbox(toolbox) {
        if (editing) {
          Api.ToolboxTopics.patch(toolbox, updateHandler)
        } else {
          Api.ToolboxTopics.post(
            { ...toolbox, application: $scope.app.id },
            updateHandler,
          )
        }
      }

      function updateHandler(resp) {
        if (resp) {
          const action = editing ? 'Updated!' : 'Created!'
          Notification.success('Toolbox Topic Successfully ' + action)
          redirect()
        }
      }

      $scope.goBack = redirect

      function redirect() {
        $state.go('app.toolboxes.project_to_toolbox', {
          app: $scope.app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }
    },
  )
