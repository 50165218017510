import * as React from 'react'
import { css } from '@emotion/react'

export function SingleFileTrainings({ file, removeFunction, isLoaded }) {
  const baseStyle = getBaseStyles()

  return (
    <div css={baseStyle} className="body-for-uploaded one-file">
      {isLoaded ? <div className="loaded-check" /> : null}
      <div
        className="file-name-base-block"
        ng-class="{'error-due-to-size': file.state === 'failedDueSize' || file.state === 'uploadFailed',
          'loading-state': file.state === 'uploadingFile'}"
      >
        <span className="file-icon" />
        <a href={file.file} target="_blank" className="file-name">
          <p className="file-name">{file.name.slice(0, 15)}</p>
        </a>
        {!isLoaded ? null : (
          <a
            className="remove-file-cross-base"
            onClick={() => {
              removeFunction()
            }}
          >
            <img
              ng-if="!file.state || file.state === 'uploadingFile'"
              alt="X"
              src="/assets/icons/X Gray.svg"
              className="remove-file-cross"
            />
          </a>
        )}
        {isLoaded ? null : (
          <span className="fa fa-spinner variable-color-text fa-spin spinner" />
        )}
        {file.name?.length > 11 ? (
          <div className="hover-body-for-files">{file.name}</div>
        ) : null}
      </div>
    </div>
  )
}
function getBaseStyles() {
  return css({
    '.file-name': {
      color: 'gray',
    },
  })
}
