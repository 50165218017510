angular.module('ccs').directive('elastic', elastic)

function elastic($timeout) {
  return {
    restrict: 'A',
    link: function ($scope, element, attrs) {
      $scope.initialHeight = 0

      var resize = function () {
        element[0].style.height = $scope.initialHeight
        element[0].style.height = '' + element[0].scrollHeight + 'px'
      }

      //In case the element is hidden.
      $scope.$watch(
        function () {
          return element.height()
        },
        function (newValue, oldValue) {
          if (newValue != oldValue) {
            resize()
          }
        },
      )

      element.on('input change', resize)
      $timeout(resize, 0)
    },
  }
}
