import pluralize from 'pluralize'

import { _extends } from '@/utils'

angular
  .module('ccs')
  .controller(
    'ProcessFlowListCtrl',
    function (
      $scope,
      Api,
      app,
      $stateParams,
      $state,
      uiPermissionService,
      $log,
    ) {
      $log.debug('ProcessFlowListCtrl')

      $scope.title = app.app_process_flow_display
      $scope.titleSingular = pluralize.singular(app.app_process_flow_display)
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20

      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.totalItems = $scope.pageNumber * $scope.pageSize

      $scope.permissionCreateProcessFlow =
        uiPermissionService.getPermissionCreateProcessFlow()
      $scope.permissionDeleteProcessFlow =
        uiPermissionService.getPermissionDeleteProcessFlow()
      $scope.permissionEditProcessFlow =
        uiPermissionService.getPermissionEditProcessFlow()
      $scope.permissionToggleAllProjectsToAllProcessFlows =
        uiPermissionService.getPermissionToggleAllProjectsToAllProcessFlows()

      $scope.processFlows = []

      $scope.tableHeaders = [
        {
          key: 'name',
          display: $scope.titleSingular + ' Name',
          sortable: true,
        },
        { key: 'view', display: 'Download', centered: true },
        {
          key: 'projects',
          display: app.projects,
          edit: true,
          centered: true,
          hidden: !$scope.permissionEditProcessFlow,
        },
        {
          key: 'delete',
          display: 'Delete',
          edit: true,
          centered: true,
          hidden: !$scope.permissionDeleteProcessFlow,
        },
      ]

      function query() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      var getProcessFlows = function getProcessFlows() {
        Api.ProcessFlows.get(query(), function (resp) {
          var processFlows = resp.results
          $scope.totalItems = resp.count

          Api.ClientProcessFlows.get(
            {
              process_flows: processFlows
                .map(function (p) {
                  return p.id
                })
                .join(),
            },
            function (resp) {
              $scope.processFlows = processFlows.map(function (p) {
                p.clientProcessFlow = null

                resp.results.forEach(function (cp) {
                  if (cp.process_flow.id == p.id) {
                    p.clientProcessFlow = cp
                  }
                })

                return p
              })
            },
          )
        })
      }

      getProcessFlows()

      $scope.changePage = function () {
        getProcessFlows()

        $state.transitionTo(
          'app.process_flows.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.assignAll = function () {
        Api.post(
          `client_process_flows/assign_all_projects_to_all`,
          {
            on: true,
            app: app.id,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          () => {
            getProcessFlows()
          },
        )
      }

      $scope.delete = (processFlow) => {
        Api.ProcessFlows.delete(processFlow, (resp) => {
          getProcessFlows()
        })
      }
    },
  )
