import { makeLoadResolver, resolvers } from '../resolvers'

/** @ngInject */
export function appsProceduresConfig($stateProvider) {
  $stateProvider
    .state('app.procedures', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
    })
    .state('app.procedures.list', {
      controller: 'ProcedureListCtrl',
      templateUrl: 'app/views/procedure_list.html',
      url: '/procedures?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
    .state('app.procedures.new', {
      controller: 'ProcedureFormCtrl',
      templateUrl: 'app/views/procedure_form.html',
      url: '/procedures/new',
      resolve: {
        ...resolvers.appResolver,
        ...makeLoadResolver('permissionUploadProcedure'),
      },
    })
    .state('app.procedures.view', {
      controller: 'ProcedureViewCtrl',
      templateUrl: 'app/views/procedure_detail.html',
      url: '/procedures/:procedure',
    })
    .state('app.procedures.assign', {
      controller: 'ProcedureAssignCtrl',
      templateUrl: 'app/views/procedure_assign.html',
      url: '/procedures/:procedure/projects?previousPageNumber&previousOrder&previousReverse&previousSearch',
      resolve: resolvers.appResolver,
    })
}
