import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const AssignTemplateRow = ({
  template,
  index,
  search,
  projectId,
  setRefresh,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const { name, active } = template
  const [hoveredField, setHoveredField] = useState({})

  const handleAssignGroup = async () => {
    await Api.postV2WithParams(
      `projects/${projectId}/assign_toolbox_topic_sets`,
      [
        {
          id: template?.id,
          active: !active,
        },
      ],
      {
        application: stateService.params.app,
      },
    )
    setRefresh(true)
  }

  const handleAssign = async () => {
    await Api.postV2WithParams(
      `projects/${projectId}/assign_toolbox_topics`,
      [
        {
          id: template?.id,
          active: !active,
        },
      ],
      {
        application: stateService.params.app,
      },
    )
    setRefresh(true)
  }

  return (
    <tr
      className={classNames('project__row', {
        dark: index % 2 === 0,
        group__row: template?.source === 1,
        light: index % 2 === 0 && template?.source === 1,
      })}
      key={index + '_' + template?.id}
    >
      <td
        className="name__td"
        onMouseOver={() => {
          setHoveredField({
            field_name: 'title__fullname',
            text: name,
            max_length: 105,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={name}
        />
        {hoveredField?.text?.length > hoveredField?.max_length && (
          <div className={`full__name ${hoveredField?.field_name}`}>
            {hoveredField?.text}
          </div>
        )}
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={active}
            onChange={template?.source === 1 ? handleAssignGroup : handleAssign}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
      </td>
    </tr>
  )
}
