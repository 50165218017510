import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { Field, FormikContextType, useFormikContext } from 'formik'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { ColoredArrowUp } from '@/react/componentAssets/ColoredArrowUp'
import { ColoredArrowDown } from '@/react/componentAssets/ColoredArrowDown'
import classNames from 'classnames'
import { hexToRGBAWithOpacity } from '@/utils'
import { AssessmentQuestion } from '@screens/AssessmentsConfig/CreateAssessment/components/types'
import { Memory } from '@screens/AssessmentsConfig/ExternalAssessment/components/types'
import { checkIfHeaderSibling } from '@screens/AssessmentsConfig/ExternalAssessment/components/utils'
import { cloneDeep, flatMap, values } from 'lodash'

export const ExternalHeader = ({
  header,
  initialValues,
  setInitialValues,
  index,
  isSubheader = false,
}: {
  header: AssessmentQuestion
  initialValues: AssessmentQuestion[]
  setInitialValues: Dispatch<SetStateAction<AssessmentQuestion[]>>
  index: string | number
  isSubheader?: boolean
}) => {
  const [isHeaderOpen, setHeaderOpen] = useState(!header.closed)

  const { values: memoryValues, setFieldValue }: FormikContextType<Memory> =
    useFormikContext()

  const { CurrentUser } = useAngularServices()
  const variableColor =
    CurrentUser.getClientSettings().web_primary_color || '#3980CE'

  const handleHeaderToggle = (e) => {
    e.stopPropagation()
    setInitialValues((prevState: AssessmentQuestion[]) => {
      const newState: AssessmentQuestion[] = cloneDeep(prevState)
      newState[index] = { ...newState[index], closed: isHeaderOpen }
      return newState
    })
    setHeaderOpen(!isHeaderOpen)
  }

  const styles = getStyles(variableColor, isSubheader)
  const headerMemory = memoryValues.answers[header.id]

  const putNA = (index: number, memoryValues: Memory, NA: boolean): void => {
    for (const question of initialValues.slice(index + 1)) {
      if (
        question.type === initialValues[index].type ||
        question.type === 'header'
      ) {
        return
      } else {
        setFieldValue(`answers[${question.id}]`, {
          ...memoryValues.answers[question.id],
          text: '',
          option: NA ? { value: '', label: 'N/A' } : null,
          notAnswered: NA,
        })
        if (question.type === 'nested_option') {
          question.nested_options?.forEach((nested_option) => {
            nested_option.nested_fields.forEach((nested_field) => {
              setFieldValue(`answers[${nested_field.id}]`, {
                ...memoryValues.answers[nested_field.id],
                text: '',
                option: NA ? { value: '', label: 'N/A' } : null,
                notAnswered: NA,
              })
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    if (memoryValues.answers[initialValues[index + 1].id]) {
      setFieldValue(
        `answers[${header.id}].notAnswered`,
        memoryValues.answers[initialValues[index + 1].id].notAnswered,
      )
    }
  }, [])

  if (
    !checkIfHeaderSibling({
      question: header,
      initialValues,
      index,
    }).isOpen
  )
    return null

  return (
    <div className="header__wrapper" css={styles}>
      <div
        onClick={handleHeaderToggle}
        className={classNames('header__info', {
          disabled__header: headerMemory.notAnswered,
        })}
      >
        <p className="header__text">
          {header?.question_text}
          {header?.name}
        </p>
        <div className="right__section">
          {header.required || header.type === 'subheader' ? null : (
            <div className="checkbox__wrapper">
              <p className="checkbox__title">N/A</p>
              <label className="checkbox-container">
                <Field
                  type="checkbox"
                  checked={memoryValues.answers[header.id].notAnswered}
                  name={`answers[${header.id}].notAnswered`}
                  onChange={() => {
                    putNA(
                      index,
                      memoryValues,
                      !memoryValues.answers[header.id].notAnswered,
                    )
                    setFieldValue(
                      `answers[${header.id}].notAnswered`,
                      !memoryValues.answers[header.id].notAnswered,
                    )
                  }}
                />
                <span className="checkmark">
                  <ThinCheckmark color={variableColor} />
                </span>
              </label>
            </div>
          )}
          {!isHeaderOpen ? (
            <ColoredArrowUp
              height="18"
              width="18"
              variableColor={variableColor}
              onClick={handleHeaderToggle}
            />
          ) : (
            <ColoredArrowDown
              height="18"
              width="18"
              variableColor={variableColor}
              onClick={handleHeaderToggle}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const getStyles = (variableColor, isSubheader) =>
  css({
    width: '100%',
    paddingInline: isSubheader ? 30 : 0,
    '.header__info': {
      cursor: 'pointer',
      position: 'relative',
      display: 'flex',
      background: hexToRGBAWithOpacity(variableColor, 0.1),
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.header__text': {
      margin: 0,
      fontSize: '14px',
      fontWeight: 600,
      color: '#676A6C',
      padding: '10px',
    },
    '.checkbox__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '5px',
      input: {
        display: 'none',
      },
      svg: {
        height: '18px',
        width: '18px',
      },
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
      height: '20px',
    },
    '.checkmark': {
      height: '20px',
      width: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: '#FAFAFA',
      border: `1px solid ${variableColor}`,
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      border: 'none',
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.checkbox__title': {
      fontSize: '12px',
      fontWeight: 400,
      color: '#676A6C',
      margin: 0,
    },
    '.right__section': {
      position: 'absolute',
      display: 'flex',
      gap: '35px',
      right: '10px',
      alignItems: 'center',
    },
    '.qustions__list': {
      marginTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      marginLeft: '10px',
    },
    '.subheaders__wrapper': {
      marginTop: '20px',
      marginInline: '30px',
    },
    '.disabled__header': {
      background: '#E5E6E7',
      filter: 'brightness(90%)',
    },
    '@media(max-width:926px)': {
      paddingInline: 0,
      '.header__info': {
        paddingInline: '15px',
        justifyContent: 'space-between',
      },
      '.right__section': {
        position: 'static',
      },
    },
  })
