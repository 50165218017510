import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

export const adminCheck = (
  adminView = false,
  adminStatus?: boolean,
  element?: ReactJSXElement,
  supervisorView = false,
  supervisorStatus?: boolean,
) => {
  if (supervisorStatus && !supervisorView) {
    return null;
  }
  if (adminStatus && adminView) {
    return element
  }
  if (supervisorStatus && supervisorView) {
    return element
  }
  if (!adminView && !supervisorView) {
    return element
  }
  return null
}
