angular
  .module('ccs')
  .controller(
    'CategoryGroupAssignCtrl',
    function ($scope, $state, $stateParams, Api, app, $log) {
      $log.debug('CategoryGroupAssignCtrl')

      var groupID = $stateParams.group
      $scope.appProject = { categories: [] }
      $scope.pageNumber = 1
      $scope.reverse = false
      $scope.order = 'name'
      $scope.breadcrumbs = [
        { title: app.name },
        {
          title: app.category + ' Groups',
          url: 'app.categories.groups({app:' + app.id + '})',
        },
      ]
      $scope.app = app

      function getCategorySet() {
        Api.CategorySets.byID(groupID, function (resp) {
          $scope.group = resp
          $scope.changePage()
        })
      }

      function categoryQuery() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_active_category_group: groupID,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      $scope.changePage = function () {
        Api.Categories.get(categoryQuery(), function (resp) {
          resp.results = resp.results.map(function (category) {
            category.active_for_category_group =
              !!$scope.group.categories.filter(function (c) {
                return c == category.id
              }).length

            return category
          })

          $scope.data = {
            items: resp.results,
            total: resp.count,
          }
        })
      }

      $scope.toggleAll = () => {
        Api.post(
          `category_sets/${$scope.group.id}/assign_all_categories`,
          { id: $scope.group.id, on: $scope.active, search: $scope.search },
          (resp) => {
            getCategorySet()
          },
        )
      }

      $scope.toggleActive = function (category) {
        if (category.active_for_category_group) {
          $scope.group.categories.push(category.id)
        } else {
          $scope.group.categories = $scope.group.categories.filter(
            (c) => c != category.id,
          )
        }

        Api.CategorySets.patch(
          { id: $scope.group.id, categories: $scope.group.categories },
          function (resp) {
            if (!resp) {
              category.active_for_category_group =
                !category.active_for_category_group
            }
          },
        )
      }

      getCategorySet()
    },
  )
