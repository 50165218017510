import { CommonPageHeader, useAngularServices } from '@/react/components'
import * as React from 'react'
import { useRouter } from '@/react/hooks'
import { Table } from './components/Table'
import { ToolboxTalksNavBar } from '../ToolboxTalksList/ToolboxTalksNavBar'

export const DeletedListPage = () => {
  const { $rootScope } = useAngularServices()
  const { stateService } = useRouter()
  return (
    <section style={{ paddingBottom: '220px' }}>
      <CommonPageHeader
        headerText={
          $rootScope.mobile_apps.filter((obj) => {
            return obj.id === Number(stateService.params.app)
          })[0].toolbox_talks_display
        }
      />
      <ToolboxTalksNavBar />
      <Table />
    </section>
  )
}
2
