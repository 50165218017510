angular.module('ccs').service('Auth', Auth)

const AUTH_TOKEN_COOKIE_NAME = 'ccsClientToken'

function Auth($cookies) {
  function getCookieToken() {
    return $cookies.get(AUTH_TOKEN_COOKIE_NAME)
  }

  function setCookieToken(newToken, shouldRememberMe) {
    $cookies.put(AUTH_TOKEN_COOKIE_NAME, newToken, {
      expires: getExpirationDate(shouldRememberMe),
    })
  }

  function removeCookieToken() {
    $cookies.remove(AUTH_TOKEN_COOKIE_NAME)
  }

  function getAuthHeader() {
    const token = getCookieToken()

    if (!token) return null

    return 'Token ' + token
  }

  return {
    getCookieToken,
    setCookieToken,
    removeCookieToken,
    getAuthHeader,
  }
}

// @TODO: the function is broken actually. If we, for example, add 30 days to September, 16, we get September, 16 again,
// because we modify only the day of the given date.
function getExpirationDate(rememberMe) {
  const expires = new Date()
  const NORMAL_LOGIN_DAYS = 1
  const SAVE_SESSION_LOGIN_DAYS = 30

  if (rememberMe) {
    expires.setDate(expires.getDate() + SAVE_SESSION_LOGIN_DAYS)
  } else {
    expires.setDate(expires.getDate() + NORMAL_LOGIN_DAYS)
  }

  return expires
}
