import { resolvers } from '../resolvers'

/** @ngInject */
export function appsBatchReportsConfig($stateProvider) {
  $stateProvider
    .state('app.batch_reports', {
      abstract: true,
      data: { pageTitle: 'Batch Reports' },
      templateUrl: 'app/views/common/uiview.html',
    })
    .state('app.batch_reports.finalize', {
      url: '/batch_reports/finalize?f_id&previousPage&previousOrder&previousSearch',
      template: '<combined-finalize-component />',
      resolve: resolvers.appResolver,
    })
}
