import { resolvers } from '../resolvers'

/** @ngInject */
export function rootConfig($stateProvider) {
  $stateProvider
    .state('index', {
      abstract: true,
      templateUrl: 'app/views/common/content.html',
      resolve: {
        ...resolvers.appsResolver,
        ...resolvers.riskDashboardPageAccessResolver,
      },
    })
    .state('index.dashboard', {
      url: '/',
      templateUrl: 'app/views/dashboard.html',
      data: { pageTitle: 'CCS Risk Dashboard' },
    })
}
