import { ReactWrapper } from '@components'
import { ServiceAccountsList } from './ServiceAccountsList'
import { ServiceAccounts } from './ServiceAccounts'
import { ServiceAccountView } from './ServiceAccountView'

export const ServiceAccountsComponent = ReactWrapper(
  ServiceAccounts,
  'serviceAccounts',
)
export const ServiceAccountsListComponent = ReactWrapper(
  ServiceAccountsList,
  'serviceAccountsList',
)
export const ServiceAccountViewComponent = ReactWrapper(
  ServiceAccountView,
  'serviceAccountView',
)
