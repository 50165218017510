const ALLOWED_FILE_TYPE = [
  'text/csv',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.ms-powerpoint',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'application/pdf',
  'audio/x-wav',
  'audio/wav',
  'audio/mpeg',
  'image/gif',
  'video/quicktime',
  'video/mp4',
]

export const isCorrectFile = (fileType) => ALLOWED_FILE_TYPE.includes(fileType)
