import * as React from 'react'
import { css } from '@emotion/react'
import moment from 'moment'
import {
  ButtonElement,
  dropdownFiledTheme,
  dropdownOptionStyles,
  NumberInput,
  useAngularServices,
  VerticalTextedSwitch,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { convertTime12toSeconds } from '@/utils/index'
import { default as ReactSelect } from 'react-select'

import closeIcon from '/src/assets/icons/X Gray.svg'
import { TextInputStyle } from '@screens/components'
import { percentageShadeBlendColor } from '@/utils'
import { useState } from 'react'

const timePointToKey = {
  n: 'n',
  time_in: 'i',
  meal_in: 'm',
  meal_out: 'b',
  time_out: 'o',
}

export const SubTableDetailEditPopUp = ({
  setPopUp,
  setReload,
  timecard,
  timePoint,
  weekDay,
  rowData,
  activityData,
  optionalHeader,
  setForceUpdateStage,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const user = CurrentUser.getInstance()
  const isAdminRole = ['client_admin', 'supervisor'].includes(user.role)
  const [dropdownNotes, setDropdownNotes] = React.useState([])

  const duration =
    timePoint === 'meal_out'
      ? moment.utc(
          moment.utc(timecard.meal_out).valueOf() -
            moment.utc(timecard.meal_in).valueOf(),
        )
      : timePoint === 'activity' && activityData.duration
      ? moment.utc(activityData.duration * 1000)
      : 0

  const [note, setNote] = React.useState('')
  const [hour, setHour] = React.useState<number>(
    timecard[timePoint]
      ? timePoint !== 'meal_out'
        ? Number(moment.utc(timecard[timePoint]).format('hh'))
        : moment(duration).hour()
      : timePoint === 'activity' && activityData.duration
      ? moment(duration).hour()
      : 0,
  )
  const [minute, setMinute] = React.useState<number>(
    timecard[timePoint]
      ? timePoint !== 'meal_out'
        ? Number(moment.utc(timecard[timePoint]).format('mm'))
        : moment(duration).minute()
      : timePoint === 'activity'
      ? moment(duration).minute()
      : 0,
  )
  const [dayTime, setDeyTime] = React.useState<string>(
    timecard[timePoint]
      ? moment.utc(moment.utc(timecard[timePoint])).format('A')
      : 'AM',
  )
  async function getStartingDayTime() {
    const { data } = await Api.TimecardSettingsTimecard.get({})
    if (data.results.length) {
      const splitTime = data.results[0].default_start.split(':')
      return (Number(splitTime[0]) * 60 + Number(splitTime[1])) * 60 * 1000
    } else {
      return 32400 * 1000
    }
  }

  const setStage = () => {
    if (isAdminRole && rowData.signature) {
      if (rowData.stage === 'a') {
        return 's'
      }

      return rowData.stage
    }

    return 'o'
  }

  const optionsForTimezone = [
    { value: 'America/New_York', label: 'ET' },
    { value: 'America/Chicago', label: 'CT' },
    { value: 'America/Denver', label: 'MT' },
    { value: 'America/Los_Angeles', label: 'PT' },
    { value: 'America/Anchorage', label: 'AT' },
    { value: 'America/Honolulu', label: 'HT' },
  ]

  const [timezone, setTimezone] = useState()

  async function sendDate() {
    await Api.patch('timesheets/' + rowData.id, {
      id: rowData.id,
      application: rowData.application,
      client: rowData.client,
      stage: setStage(),
      supervisor: rowData.supervisor.id,
      user: rowData.user.id,
      timezone: timezone.value,
    })

    if (timePoint === 'activity') {
      const { data: timeCardInfo } = timecard.id
        ? { data: timecard }
        : await Api.post('timecards', {
            status: 'n',
            supervisor: rowData.supervisor.id,
            user: rowData.user.id,
            application: stateService.params.app,
            timesheet: rowData.id,
            time_in: moment(weekDay).toISOString(),
            timezone: timezone.value,
          })
      if (activityData.costCodeFullData) {
        await Api.post('activity', {
          duration: moment.utc(0).hour(hour).minute(minute).valueOf() / 1000,
          costcode: activityData.costCodeFullData.id,
          timecard: timeCardInfo.id,
        })
        setPopUp(null)
        setReload(true)
      } else {
        await Api.patch('activity/' + activityData.id, {
          duration: moment.utc(0).hour(hour).minute(minute).valueOf() / 1000,
        })
        setPopUp(null)
        setReload(true)
      }
    } else {
      const dataToSend = {
        status: timecard.status
          ? selectStatus(timecard.status, timePointToKey[timePoint])
          : 'i',
        supervisor: rowData.supervisor.id,
        user: rowData.user.id,
        application: stateService.params.app,
        timesheet: rowData.id,
        note: note,
        time_in: timecard.time_in,
        meal_in: timecard.meal_in,
        meal_out: timecard.meal_out,
        time_out: timecard.time_out,
        timezone: timezone.value,
      }
      if (timePoint === 'meal_out') {
        dataToSend.meal_out =
          moment
            .utc(timecard.meal_in)
            .add(hour, 'h')
            .add(minute, 'm')
            .format('YYYY-MM-DDTHH:mm:ss') + '+0000'
      } else if (timePoint === 'full_day_report') {
        const defDatStart = await getStartingDayTime()
        const defPoint = moment.utc(moment(weekDay) + moment(defDatStart))
        const editTime = moment.utc(0).hour(hour).minute(minute)
        const out_time = moment.utc(defPoint + editTime)
        dataToSend.time_in = defPoint.format('YYYY-MM-DDTHH:mm:ss') + '+0000'
        dataToSend.time_out = out_time.format('YYYY-MM-DDTHH:mm:ss') + '+0000'
        dataToSend.status = 'o'
      } else {
        let possibleTimeOutEditTime = null
        const editTime = moment
          .utc(weekDay)
          .hour(
            moment.utc(convertTime12toSeconds(hour, 0, dayTime, true)).hour(),
          )
          .minute(minute)
        if (timePoint === 'meal_in' && timecard.meal_out) {
          const hourDifferenceForMeal = moment
            .utc(moment(timecard.meal_out) - moment(timecard.meal_in))
            .hour()
          const minuteDifferenceForMeal = moment
            .utc(moment(timecard.meal_out) - moment(timecard.meal_in))
            .minute()
          dataToSend.meal_out =
            moment(editTime)
              .add(hourDifferenceForMeal, 'h')
              .add(minuteDifferenceForMeal, 'm')
              .format('YYYY-MM-DDTHH:mm:ss') + '+0000'
        }
        if (timePoint === 'time_out' && timecard.time_in) {
          const timeOut = moment
            .utc(0)
            .hour(moment.utc(editTime).hour())
            .minute(moment.utc(editTime).minute())
          const timeIn = moment
            .utc(0)
            .hour(moment.utc(timecard.time_in).hour())
            .minute(moment.utc(timecard.time_in).minute())
          if (timeIn >= timeOut) {
            editTime.add(1, 'd')
          } else {
            editTime
              .month(moment.utc(timecard.time_in).month())
              .date(moment.utc(timecard.time_in).date())
          }
        }
        if (timePoint === 'time_in' && timecard.time_out) {
          const timeOut = moment
            .utc(0)
            .hour(moment.utc(timecard.time_out).hour())
            .minute(moment.utc(timecard.time_out).minute())
          const timeIn = moment
            .utc(0)
            .hour(moment.utc(editTime).hour())
            .minute(moment.utc(editTime).minute())
          if (timeIn >= timeOut) {
            possibleTimeOutEditTime = moment
              .utc(timecard.time_out)
              .date(moment.utc(timecard.time_in).date())
              .add(1, 'd')
          } else {
            possibleTimeOutEditTime = moment
              .utc(timecard.time_out)
              .month(moment.utc(timecard.time_in).month())
              .date(moment.utc(timecard.time_in).date())
          }
        }
        dataToSend[timePoint] = editTime.format('YYYY-MM-DDTHH:mm:ss') + '+0000'
        if (possibleTimeOutEditTime)
          dataToSend.time_out =
            possibleTimeOutEditTime.format('YYYY-MM-DDTHH:mm:ss') + '+0000'
      }
      if (timecard.id) {
        await Api.patch('timecards/' + timecard.id, dataToSend)
        await Api.post('timecards_notes', {
          timecard: timecard.id,
          note: note,
          field: timePoint === 'full_day_report' ? 'time_out' : timePoint,
        })

        await Api.patch('timesheets/' + rowData.id, {
          id: rowData.id,
          application: rowData.application,
          client: rowData.client,
          stage: 'o',
          supervisor: rowData.supervisor.id,
          user: rowData.user.id,
          timezone: timezone.value,
        })
        setForceUpdateStage('Open')
        setReload(true)
      } else {
        const { data: postAnswer } = await Api.post('timecards', dataToSend)
        await Api.post('timecards_notes', {
          timecard: postAnswer.id,
          note: note,
          field: timePoint === 'full_day_report' ? 'time_out' : timePoint,
        })
      }
      setPopUp(null)
      setReload(true)
    }
  }

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const getDropdownNotes = async () => {
    const { data: response } = await Api.get('timecards_notes', {
      pull_down: true,
    })

    setDropdownNotes(
      response.results.map((el) => ({
        value: el.id,
        label: el.note,
      })),
    )
  }

  const getDefaultTimezoneValue = async () => {
    const { data: resp } = await Api.TimecardSettingsTimecard.get({})

    if (resp.results.length && !timecard.timezone) {
      const { default_timezone } = resp.results[0]
      setTimezone(
        optionsForTimezone.find(
          (timezone) => timezone.value === default_timezone,
        ),
      )
    } else {
      setTimezone(
        optionsForTimezone.find(
          (timezone) => timezone.value === timecard.timezone,
        ),
      )
    }
  }

  React.useEffect(() => {
    getDropdownNotes()
    getDefaultTimezoneValue()
  }, [])

  const isSaveButtonActive = React.useMemo(() => {
    return hour > 0 && Boolean(note.length) && timePoint !== 'activity'
  }, [hour, note])

  return (
    <section css={baseStyle}>
      <div
        className="background"
        onClick={() => {
          setPopUp(null)
        }}
      />
      <div className="alert-holder">
        <div
          className="single-alert-row"
          css={css({
            justifyContent: 'right',
          })}
        >
          <img
            src={closeIcon}
            alt="close"
            css={css({
              height: 25,
              cursor: 'pointer',
            })}
            onClick={() => {
              setPopUp(null)
            }}
          />
        </div>
        {timePoint !== 'meal_out' &&
        timePoint !== 'activity' &&
        timePoint !== 'full_day_report' ? null : (
          <div className="header-row">
            {optionalHeader ? (
              optionalHeader
            ) : (
              <h3>
                Duration{timePoint === 'full_day_report' ? ' Worked' : ''}
              </h3>
            )}
          </div>
        )}
        <div className="input-row">
          <div className="single-input">
            <h4 className="gray-text">HOUR:</h4>
            <NumberInput
              defaultValue={hour}
              width={'150px'}
              maxNumber={timePoint === 'activity' ? 99 : 12}
              valueCallback={setHour}
              placeholder={'HH'}
            />
          </div>
          <div className="single-input">
            <h4 className="gray-text">MINUTE:</h4>
            <NumberInput
              defaultValue={minute}
              width={'150px'}
              maxNumber={60}
              valueCallback={setMinute}
              placeholder={'MM'}
            />
          </div>
          {timePoint === 'meal_out' ||
          timePoint === 'activity' ||
          timePoint === 'full_day_report' ? null : (
            <VerticalTextedSwitch
              textOne={'AM'}
              valueOne={'AM'}
              textTwo={'PM'}
              valueTwo={'PM'}
              valueCallback={setDeyTime}
              defaultValue={dayTime}
            />
          )}
          {timezone &&
            timePoint !== 'full_day_report' &&
            timePoint !== 'activity' &&
            timePoint !== 'meal_out' && (
              <ReactSelect
                options={optionsForTimezone}
                defaultValue={timezone}
                onChange={(value) => setTimezone(value)}
                styles={{
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused
                      ? percentageShadeBlendColor(0.4, variableColor) +
                        ' !important'
                      : state.isSelected
                      ? percentageShadeBlendColor(0.1, variableColor) +
                        ' !important'
                      : 'white !important',
                  }),
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '50px',
                    width: '78px',
                    marginLeft: '10px',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '50px',
                    width: '78px',
                    minHeight: '50px',
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  height: '25px',
                  width: '40px',
                  backgroundColor: '#FFFFFF',
                  backgroundImage: 'none',
                  borderColor: '#e5e6e7 !important',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  maxHeight: '64px',
                  borderRadius: '0',
                  fontSize: '14px',
                  marginBottom: '10px',
                  colors: {
                    ...theme.colors,
                    primary: percentageShadeBlendColor(0.2, variableColor),
                  },
                })}
              />
            )}
        </div>
        {timePoint === 'activity'
          ? null
          : [
              <div className="note-holder" key={'noteText'}>
                <h4 className="note-text gray-text">NOTE*</h4>
                <div className="note_select">
                  <ReactSelect
                    placeholder="Select a reason"
                    options={dropdownNotes}
                    // className="note-field"
                    styles={dropdownOptionStyles(variableColor)}
                    theme={(theme) => dropdownFiledTheme(theme, variableColor)}
                    onChange={(e: any) => {
                      setNote(e.label)
                    }}
                  />
                </div>
              </div>,
              <textarea
                key={'noteField'}
                placeholder="Or type note here"
                className="note-field"
                onChange={(value) => {
                  setNote(value.target.value)
                }}
              />,
            ]}
        <div className="button-row">
          {timePoint === 'meal_out' || timePoint === 'full_day_report' ? (
            <ButtonElement
              fontSize={'12px'}
              height={'31px'}
              width={'91px'}
              text={'Save'}
              additionalStyles={css({ lineHeight: '12px' })}
              disabled={(!minute && !hour) || !note.length}
              functionToTrigger={sendDate}
              buttonType={'submit'}
            />
          ) : (
            <ButtonElement
              fontSize={'12px'}
              height={'31px'}
              width={'91px'}
              text={'Save'}
              additionalStyles={css({ lineHeight: '12px' })}
              disabled={!isSaveButtonActive && !(hour && minute)}
              functionToTrigger={sendDate}
              buttonType={'submit'}
            />
          )}
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  cursor: 'default',
  color: '#676a6c',
  display: 'flex',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  '.background': {
    position: 'fixed',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
    zIndex: 500,
  },
  '.alert-holder': {
    position: 'fixed',
    boxShadow: '0 0 1px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 20%)',
    padding: 20,
    // width: 400,
    zIndex: 1000,
    backgroundColor: 'white',
    '.header-row': {
      textAlign: 'center',
      marginBottom: 15,
    },
    '.single-alert-row': {
      width: '100%',
      display: 'flex',
      marginBottom: 10,
    },
    '.input-row': {
      display: 'flex',
      alignItems: 'end',
      '.single-input': {
        marginRight: 10,
      },
    },
    '.note-field': {
      ...TextInputStyle,
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '16px',
      marginTop: '12px',
      resize: 'none',
      width: '100%',
      height: 100,
    },
    '.note_select': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '17px',
    },
    '.button-row': {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'center',
    },
    '.note-holder': {
      marginTop: 20,
      width: '100%',
      textAlign: 'left',
    },
    '.gray-text': {
      color: '#a6a6a6',
    },
  },
})

function selectStatus(currentStage, stageToSet) {
  const stageLevel = {
    n: 0,
    i: 1,
    m: 2,
    b: 3,
    o: 4,
  }
  if (stageLevel[stageToSet] > stageLevel[currentStage]) return stageToSet
  return currentStage
}
