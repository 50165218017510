angular
  .module('ccs')
  .controller(
    'EmployeeGroupsAssignCtrl',
    function ($scope, Api, $state, $stateParams, $log) {
      $log.debug('EmployeeGroupsAssignCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.trainings = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'first_name,last_name'
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.userID = $stateParams.userID

      $scope.pageSize = 20

      function getEmployeesListQuery() {
        let q = {
          group: $stateParams.groupID,
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
        }

        if ($scope.order)
          q.order = $scope.reverse
            ? $scope.order
                .split(',')
                .map(function (a) {
                  return '-' + a
                })
                .join(',')
            : $scope.order

        return q
      }

      function getEmployeesList() {
        Api.EmployeesList.get(getEmployeesListQuery(), function (resp) {
          $scope.totalItems = resp.count
          $scope.employees = resp.results
        })
      }

      getEmployeesList()

      $scope.toggleAll = () => {
        Api.post(
          `user_sets/${$stateParams.groupID}/assign_all_users/?deleted=False`,
          {
            active: $scope.active,
            search: $scope.search,
          },
          () => {
            getEmployeesList()
          },
        )
      }

      $scope.toggleActive = (employee) => {
        Api.patch(`user_sets/${$stateParams.groupID}/?deleted=False`, {
          user: employee.id,
          active: employee.assigned_to_group,
        })
      }

      $scope.goBack = () => {
        $state.go('trainings.assign.employee_groups_list', {
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }

      $scope.changePage = function () {
        getEmployeesList()
      }
    },
  )
