interface Question {
  questionId: number
  answer: {
    id: number
    notes: string[]
    corrective_actions: string[]
    photos: string[]
    synchronised: any[]
    internal_assignees: any[]
    external_assignees: any[]
    answer_party_observed_answer_list: any[]
    assigned_to: null | string
    answer: string
    reference: string
    severity: number
    lat: null | string
    lon: null | string
    location_age: null | string
    location_radius: null | string
    client_object_key: string
    date_created: string
    date_updated: string
    deleted: boolean
    fieldmeta: null | string
    copy_sequence_number: number
    question: number
    observation: number
    user: number
  }
  order: number
  question: {
    id: number
    name: string
    is_active: boolean
    deleted: boolean
    order: number
    date_updated: string
    date_created: string
    source: string
    client: null | object | string
    category: number
  }
  answeredFieldOrder: number
}

export function sortByAnswer(questions: Question[]): Question[] {
  const groupedData = {}
  const sortedData = []

  // Step 1: Grouping data by questionId
  questions.forEach((item) => {
    if (groupedData[item.questionId + 'sort']) {
      groupedData[item.questionId + 'sort'].push(item)
    } else {
      groupedData[item.questionId + 'sort'] = [item]
    }
  })

  // Step 2: Sort our copies by copy_sequence_number and modify into array
  Object.keys(groupedData).forEach((item) => {
    return groupedData[item]
      .sort(
        (a, b) => a.answer.copy_sequence_number - b.answer.copy_sequence_number,
      )
      .forEach((item) => sortedData.push(item))
  })

  // Step 3: Modify our order using element index
  return sortedData.map((item, index) => {
    return {
      ...item,
      order: index + 1,
      answeredFieldOrder: index + 1,
    }
  })
}
