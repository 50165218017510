import * as React from 'react'
import { css } from '@emotion/react'
import { ButtonElement } from '@/react/components'
import { default as ReactSelect } from 'react-select'
import {
  useAngularServices,
  dropdownFiledTheme,
  dropdownOptionStyles,
} from '@/react/components'

import closeIcon from '/src/assets/icons/X Gray.svg'

const baseStyle = css({
  '.popup__content': {
    position: 'relative',
  },
  '.close__button': {
    position: 'absolute',
    top: '-20px',
    right: '-22px',
    width: '10px',
    height: '10px',
    cursor: 'pointer',
  },
  '.popup__title': {
    fontFamily: 'Open Sans',
    margin: '0',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'center',
    letterSpacing: '-0.01em',

    color: 'gray',
  },
  '.popup__wrapper': {
    position: 'absolute',
    width: '357px',
    margin: '0 auto',
    padding: '30px',
    marginTop: '-30px',
    backgroundColor: '#ffff',
    boxShadow: '0px 7px 29px rgba(0, 0, 0, 0.18)',
    paddingBottom: '0',
  },
  '.note__title': {
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '19px',
    color: '#a6a6a6',
  },
  '.note__field': {
    fontFamily: 'Open Sans',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '14px',
    resize: 'none',
    width: '100%',
    height: 100,
  },
  '.button': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
    width: '75px',
    height: '28px',
    color: '#fff',
    border: 'none',
    padding: 0,
    marginTop: '12px',
    marginBottom: '12px',
    cursor: 'pointer',
  },
})

interface Props {
  timecardId: number
  handlePopUpVisability: (id: number) => void
  setReload: (boolean: boolean) => void
}

export const DeleteTimeCardPopUp: React.FC<Props> = ({
  timecardId,
  handlePopUpVisability,
  setReload,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [dropdownNotes, setDropdownNotes] = React.useState([])
  const [note, setNote] = React.useState('')

  const getDropdownNotes = async () => {
    const { data: response } = await Api.get('timecards_notes', {
      pull_down: true,
    })

    setDropdownNotes(
      response.results.map((el) => ({
        value: el.id,
        label: el.note,
      })),
    )
  }

  React.useEffect(() => {
    getDropdownNotes()
  }, [])

  const handleDelete = async () => {
    await Api.post('timecards_notes', {
      note: note,
      timecard: timecardId,
      field: 'deleted',
    })

    await Api.Timecards.delete({ id: `${timecardId}` })

    handlePopUpVisability(0)
    setReload(true)
  }

  return (
    <div css={baseStyle}>
      <div className="popup__wrapper">
        <div className="popup__content">
          <img
            src={closeIcon}
            alt="close"
            className="close__button"
            onClick={() => {
              handlePopUpVisability(0)
            }}
          />
          <h1 className="popup__title">Delete?</h1>
          <p className="note__title">NOTE*</p>
          <div className="note__select">
            <ReactSelect
              placeholder="Select a reason"
              options={dropdownNotes}
              // className="note-field"
              styles={dropdownOptionStyles(variableColor)}
              theme={(theme) => dropdownFiledTheme(theme, variableColor)}
              onChange={(e: any) => {
                setNote(e.label)
              }}
            />
          </div>
          <textarea
            key={'noteField'}
            placeholder="Or type note here"
            className="note__field"
            onChange={(value) => {
              setNote(value.target.value)
            }}
          />
          <div
            className="button"
            onClick={() => {
              handleDelete()
            }}
          >
            <ButtonElement
              fontSize={'12px'}
              height={'31px'}
              width={'91px'}
              text={'OK'}
              additionalStyles={css({ lineHeight: '12px' })}
              disabled={!note.length}
              buttonType={'submit'}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
