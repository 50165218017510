import * as React from 'react'
import { ButtonElement, useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { mapValues, mapKeys, pickBy } from 'lodash'

import {
  SortModeHeader,
  SortMode,
  PageSwitcher,
  SearchForProjects,
  ProjectRow,
} from './components'

const PAGE_SIZE = 50

type ProjectData = {
  id: string
  name: string
  isActive: boolean
}

export function SDSAssignProject() {
  const { Api, $rootScope } = useAngularServices()
  const { stateService } = useRouter()

  const app = Number(stateService.params.app)

  const {
    project: projectName,
    projects: projectsName,
    safety_data_sheets_display: sdsName,
  } = $rootScope.mobile_apps.find((obj) => obj.id === app) || {
    projectName: '',
    projectsName: '',
    sdsName: '',
  }

  const [sortMode, setSortMode] = React.useState<SortMode>('name')
  const [page, setPage] = React.useState<number>(
    Number(stateService.params.pageNumber) || 1,
  )
  const [search, setSearch] = React.useState<string>('')
  const [projectsCount, setProjectsCount] = React.useState<number>(0)

  const [currentPage, setCurrentPage] = React.useState<ProjectData[]>([])
  const [toggledProjects, setToggledProjects] = React.useState<{
    [key: string]: boolean
  }>({})

  React.useEffect(() => {
    const fetchToggledProjects = async () => {
      const { data } = await Api.SDSForm.byID(stateService.params.key)
      setToggledProjects(arrayToMap(data.projects))
    }

    fetchToggledProjects()
  }, [stateService.params.key])

  React.useEffect(() => {
    const refetchProjects = async () => {
      const {
        data: { count, results },
      } = await Api.Projects.get({
        page,
        app,
        is_active: true,
        search,
        search_fields: 'name', // Use comma separated values in case several fields are needed
        order: sortMode,
        page_size: PAGE_SIZE,
      })
      setProjectsCount(count)
      setCurrentPage(results.map(({ id, name }) => ({ id, name })))
    }

    refetchProjects()
  }, [page, search, app, sortMode])

  const onToggleAllClick = React.useCallback(async () => {
    const {
      data: { results: toggledProjects },
    } = await Api.get('projects/get_ids', { is_active: true })
    setToggledProjects(arrayToMap(toggledProjects))
  }, [])

  const onUntoggleAllClick = () => {
    setToggledProjects({})
  }

  const onTogglePageClick = React.useCallback(() => {
    const ids = currentPage.map(({ id }) => id)
    setToggledProjects((prevValue) => ({
      ...prevValue,
      ...arrayToMap(ids),
    }))
  }, [currentPage])

  const onUntogglePageClick = React.useCallback(() => {
    const ids = currentPage.map(({ id }) => id)
    setToggledProjects((prevValue) => {
      const newValue = { ...prevValue }
      ids.forEach((id) => delete newValue[id])
      return newValue
    })
  }, [currentPage])

  const onToggleSingleClick = React.useCallback(
    (id: string, value: boolean) => {
      setToggledProjects((prevState) => {
        const newState = {
          ...prevState,
        }
        // We keep only truthy values in object, removing falsy ones
        // That's needed for Toggle all / Untoggle all button to work properly
        if (value) {
          newState[id] = value
        } else {
          delete newState[id]
        }
        return newState
      })
    },
    [],
  )

  const allProjectsAreToggled =
    Object.keys(toggledProjects).length === projectsCount

  const currentPageToggledIdsLength = currentPage.reduce((length, { id }) => {
    if (toggledProjects[id]) return length + 1
    return length
  }, 0)
  const currentPageProjectsAreToggled =
    currentPageToggledIdsLength === currentPage.length

  const onSubmitClick = React.useCallback(async () => {
    const { key, app } = stateService.params
    await Api.SDSAssignProject(key, { projects: mapToArray(toggledProjects) })
    stateService.go('app.safety_data_sheets_list', { app })
  }, [stateService.params.key, toggledProjects])

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const headLine = css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.accept-button': {
      backgroundColor: variableColor,
      color: '#fff',
      border: `1px solid ${variableColor}`,
      minWidth: '75px',
      width: 'fit-content',
      height: '28px',
      padding: '0 25px',
      fontSize: '12px',
      boxSizing: 'border-box',
      fontWeight: 400,
      fontFamily: "'Open Sans', Arial, sans-serif",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  })

  const navigationStyles = css({
    paddingLeft: '30px',
    borderTopStyle: 'solid',
    borderTopColor: 'rgb(231, 234, 236)',
    paddingTop: '15px',
    height: '50px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '30%',
    backgroundColor: 'white',
  })

  const projectFormStyles = css({
    position: 'relative',
    backgroundColor: 'white',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '25px',
    thead: {
      borderBottom: '2px solid rgb(198, 198, 198)',
    },
    th: {
      width: '80%',
      height: '2em',
      paddingRight: '10px',
      paddingLeft: '10px',
      cursor: 'pointer',
    },
    'th.toggle-header': {
      width: '20%',
    },
    tbody: {
      tr: {
        borderTop: '1px solid rgb(229, 230, 231)',
        borderBottom: '1px solid rgb(229, 230, 231)',
      },
    },
    tr: {
      height: '37px',
    },
    td: {
      height: '37px',
      paddingRight: '10px',
      paddingLeft: '10px',
    },
  })

  return (
    <div>
      <div className="row wrapper border-bottom white-bg page-heading">
        <h2>
          Assign {sdsName} to {projectsName}
        </h2>
      </div>
      <div
        className="ibox-title d-flex align-items-center ml-auto"
        css={headLine}
      >
        <h5>{projectName} List</h5>
        <button className="button accept-button" onClick={onSubmitClick}>
          Done
        </button>
      </div>
      <div css={navigationStyles} className="navRow">
        <SearchForProjects onChange={setSearch} />
      </div>
      <div css={projectFormStyles}>
        <table>
          <SortModeHeader
            projectName={projectName}
            sortMode={sortMode}
            setSortMode={setSortMode}
            allProjectsAreToggled={allProjectsAreToggled}
            currentPageProjectsAreToggled={currentPageProjectsAreToggled}
            onUntoggleAllClick={onUntoggleAllClick}
            onTogglePageClick={onTogglePageClick}
            onUntogglePageClick={onUntogglePageClick}
            onToggleAllClick={onToggleAllClick}
            search={search}
          />
          <tbody>
            {currentPage.map((project) => (
              <ProjectRow
                key={project.id}
                id={project.id}
                name={project.name}
                isToggled={!!toggledProjects[project.id]}
                onToggle={onToggleSingleClick}
              />
            ))}
          </tbody>
        </table>
        {projectsCount > PAGE_SIZE ? (
          <PageSwitcher
            currentPage={page}
            listCount={projectsCount}
            pageLength={PAGE_SIZE}
            setActivePage={setPage}
          />
        ) : null}
      </div>
    </div>
  )
}

// Converts array of toggled project ids to hash for convenience
// (checking if project is toggled is faster in Object like { 10: true, 20: true }
// rather than in array like [10, 20]
const arrayToMap = (arr: any[], defaultValue: any = true) =>
  mapValues(
    mapKeys(arr, (value) => value),
    () => defaultValue,
  )

// Converts map of toggled projects into array (only truthy keys)
// like { 10: true, 20: false, 30: true } => [10, 30]
const mapToArray = (obj) => Object.keys(pickBy(obj))
