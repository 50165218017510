import {
  rootConfig,
  adminConfig,
  adminProcoreConfig,
  adminProjectsConfig,
  adminUsersConfig,
  appsConfig,
  appsBatchReportsConfig,
  appsCategoriesConfig,
  appsCompanyNewsConfig,
  appsFormsConfig,
  appsObservationsConfig,
  appsProceduresConfig,
  appsProcessFlowsConfig,
  appsQuestionsConfig,
  appsToolboxesConfig,
  assignedConfig,
  credentialsConfig,
  lessonsConfig,
  trainingsConfig,
} from './configs'

angular
  .module('ccs')
  .config(rootConfig)
  .config(credentialsConfig)
  .config(otherwiseConfig)
  .config(adminConfig)
  .config(adminProcoreConfig)
  .config(adminProjectsConfig)
  .config(adminUsersConfig)
  .config(appsConfig)
  .config(appsBatchReportsConfig)
  .config(appsCategoriesConfig)
  .config(appsCompanyNewsConfig)
  .config(appsFormsConfig)
  .config(appsObservationsConfig)
  .config(appsProceduresConfig)
  .config(appsProcessFlowsConfig)
  .config(appsQuestionsConfig)
  .config(appsToolboxesConfig)
  .config(assignedConfig)
  .config(lessonsConfig)
  .config(trainingsConfig)

/** @ngInject */
function otherwiseConfig($urlRouterProvider) {
  $urlRouterProvider.otherwise('/') // @TODO: implement proper Not Found page
}
