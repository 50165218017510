angular
  .module('ccs')
  .controller(
    'LessonListCtrl',
    function (
      $scope,
      Api,
      $stateParams,
      $state,
      $timeout,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('LessonListCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'date_created'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20
      $scope.reverse = true //for default 'Newest to Oldest' order
      $scope.isClientOrAdmin = CurrentUser.isClientOrAdmin

      $scope.data = {
        items: [],
        total: $scope.pageSize * $scope.pageNumber,
      }

      function query() {
        let q = {
          page: $scope.pageNumber,
          search: $scope.search,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        if ($scope.partnerFilter)
          q.client = $scope.getClientFilterFromPartnerFilter()

        return q
      }

      function getLessons() {
        Api.Lessons.get(query(), function (response) {
          var idx
          var firstUpper

          for (idx in response.results) {
            var currCategory = response.results[idx].category
            var currType = response.results[idx].type

            firstUpper =
              currCategory.charAt(0).toUpperCase() + currCategory.substr(1)
            response.results[idx].category = firstUpper

            firstUpper = currType.charAt(0).toUpperCase() + currType.substr(1)
            response.results[idx].type = firstUpper
          }

          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })
      }

      getLessons()

      $scope.showUnsyncError = function () {
        $scope.error =
          'Attention: You may not edit a lesson that has not fully synchronized with the server. Please try again later.'
        $timeout(function () {
          $scope.error = null
        }, 3000)
      }

      $scope.changePage = function () {
        getLessons()
        $state.transitionTo(
          'lessons.list',
          {
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.toggleActive = function (lesson) {
        Api.Lessons.patch(
          { id: lesson.id, is_active: lesson.is_active },
          function (resp) {
            if (!resp) {
              Notification.danger(
                'Unable to toggle Lesson. Please try again later.',
              )
              lesson.is_active = !lesson.is_active
            }
          },
        )
      }
    },
  )
