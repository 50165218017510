angular.module('ccs').directive('observationlinechart', observationGraph)

function observationGraph() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/nvd3_graph.html',
    link: function ($scope) {
      $scope.observations = $scope.observations || []
      $scope.data = []

      $scope.$watch('observations', function (newValue) {
        if (newValue.length) {
          $scope.data = getObservationData(
            newValue,
            $scope.getClientSettings().web_primary_color,
          )
        } else {
          $scope.data = []
        }
      })

      $scope.$on('$destroy', function () {
        d3.selectAll('.nvtooltip').remove()
      })

      $scope.options = {
        chart: {
          type: 'lineChart',
          height: 450,
          margin: {
            top: 20,
            right: 100,
            bottom: 40,
            left: 100,
          },
          x: (d) => d.x,
          y: (d) => d.y,
          useInteractiveGuideline: true,
          xAxis: {
            tickFormat: (d) => d3.time.format('%x')(new Date(d)),
          },
          yAxis: {
            axisLabel: '# of Observations',
            tickFormat: function (d) {
              if (d % 1 === 0) {
                return d3.format('.f')(d)
              } else {
                return ''
              }
            },
          },
        },
        title: {
          enable: true,
          text: 'Number of Observations over time',
        },
      }
    },
  }
}

function getObservationData(observations, color) {
  let dates = {}

  observations.forEach((observation) => {
    const key = getDayKey(observation.date_created)
    if (dates[key]) {
      ++dates[key]
    } else {
      dates[key] = 1
    }
  })

  return [
    {
      key: 'Observations',
      color: color,
      area: true,
      values: Object.keys(dates).map((key) => {
        return {
          x: getDateForKey(key).getTime(),
          y: dates[key],
        }
      }),
    },
  ]
}

function toDate(dateStr) {
  //Do manual parsing of date because Safari cannot parse the date string from Postgres
  const dateonly = dateStr.split('T')[0]
  const dateparts = dateonly.split('-')
  const year = dateparts[0]
  const month = dateparts[1] - 1
  const day = dateparts[2]
  const timehalf = dateStr.split('T')[1]
  const clockonly = timehalf.split('.')[0]
  const clockparts = clockonly.split(':')
  const hours = clockparts[0]
  const minutes = clockparts[1]
  const seconds = clockparts[2]
  return new Date(Date.UTC(year, month, day, hours, minutes, seconds))
}

function getDayKey(date) {
  const d = toDate(date)
  return `${d.getUTCFullYear()}:${d.getMonth() + 1}:${d.getDate()}`
}

function getDateForKey(key) {
  const parts = key.split(':')
  return new Date(parts[0], parts[1] - 1, parts[2])
}
