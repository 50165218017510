import { _extends } from '@/utils'

angular
  .module('ccs')
  .controller(
    'ProcedureAssignCtrl',
    function (
      $scope,
      $state,
      $stateParams,
      Api,
      app,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('ProcedureAssignCtrl')

      $scope.procedure = null
      $scope.projects = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.pageSize = 20
      $scope.app = app

      var procedureId = $stateParams.procedure

      //For restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.tableHeaders = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'active_for_procedure',
          display: 'Inactive/Active',
          sortable: true,
          toggleAllBtn: true
        },
      ]

      var getProjects = function getProjects() {
        var query = {
          client: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_for_procedure: procedureId,
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        if ($scope.q) query.name = $scope.q

        Api.Projects.get(query, function (resp) {
          $scope.totalItems = resp.count
          $scope.projects = resp.results.map(function (project) {
            return _extends({}, project, {
              active_for_procedure:
                $scope.procedure.clientProcedure.projects.indexOf(project.id) >
                -1,
            })
          })
          setTimeout(function () {
            $scope.$apply()
          }, 0)
        })
      }

      $scope.changePage = function () {
        getProjects()
      }

      let on = true

      function getAllProjects(cb, projects, page) {
        projects = projects || []
        page = page || 1

        var promiseList = []
        promiseList.push(
          new Promise(function (resolve, reject) {
            Api.Projects.get(
              {
                client: CurrentUser.getClientId(),
                is_active: 'True',
                page: page,
                annotate_active_procedure: procedureId,
              },
              (resp) => {
                projects = projects.concat(resp.results)
                if (resp.next) return getAllProjects(cb, projects, ++page)
                return resolve()
              },
              function (error) {
                Notification.danger(error)
                return reject()
              },
            )
          }),
        )

        Promise.all(promiseList).then(function (values) {
          cb(projects)
        })
      }

      function updateClientProcedure(cb) {
        Api.ClientProcedures.patch(
          {
            ...$scope.procedure.clientProcedure,
            procedure: $scope.procedure.id,
            search: $scope.search,
            project: null, // project toggling -> null, when "Toggle All"
          },
          (resp) => {
            let interval = setInterval(function () {
              // destroy interval
              if (resp) {
                cb()
                clearInterval(interval)
              }
            }, 1000)
            setTimeout(function () {
              clearInterval(interval)
            }, 300000)
          },
          (error) => {
            Notification.danger(JSON.stringify(error))
          },
        )
      }

      function createClientProcedure(cb) {
        Api.ClientProcedures.post(
          {
            ...$scope.procedure.clientProcedure,
            procedure: $scope.procedure.id,
            search: $scope.search,
            project: null, // project toggling -> null, when "Toggle All"
          },
          (resp) => {
            let interval = setInterval(function () {
              // destroy interval
              if (resp) {
                cb(resp)
                clearInterval(interval)
              }
            }, 1000)
            setTimeout(function () {
              clearInterval(interval)
            }, 300000)
          },
          (error) => {
            Notification.danger(JSON.stringify(error))
          },
        )
      }

      function saveClientProcedure(cb) {
        if ($scope.procedure.clientProcedure.id) {
          updateClientProcedure(cb)
        } else {
          createClientProcedure(writeClientProcedure)
        }
      }

      function writeClientProcedure(data) {
        $scope.procedure.clientProcedure = data
        getProjects()
      }

      $scope.toggleAll = () => {
        if (on) {
          getAllProjects(function (projects) {
            $scope.procedure.clientProcedure.projects = projects.map(
              (p) => p.id,
            )
            saveClientProcedure($scope.getProceduresByID)
          })
        } else {
          $scope.procedure.clientProcedure.projects = []
          saveClientProcedure($scope.getProceduresByID)
        }

        on = !on
      }

      $scope.toggleActive = function (project) {
        if (!project) {
          return
        }

        if (!$scope.procedure.clientProcedure.id) {
          Api.ClientProcedures.post(
            _extends({}, $scope.procedure.clientProcedure, {
              procedure: procedureId,
              projects: [project.id],
              project: project.id, // project toggling
            }),
            function (resp) {
              return ($scope.procedure.clientProcedure = resp)
            },
            function (error) {
              Notification.danger(JSON.stringify(error))
            },
          )
        } else {
          if (project.active_for_procedure) {
            $scope.procedure.clientProcedure.projects.push(project.id)
          } else {
            $scope.procedure.clientProcedure.projects =
              $scope.procedure.clientProcedure.projects.filter(function (p) {
                return p != project.id
              })
          }
          Api.ClientProcedures.patch(
            {
              ...$scope.procedure.clientProcedure,
              procedure: $scope.procedure.id,
              project: project.id, // project toggling
            },
            function (resp) {},
            function (error) {
              Notification.danger(JSON.stringify(error))
            },
          )
        }
      }

      $scope.getProceduresByID = function () {
        Api.Procedures.byID(procedureId, function (resp) {
          var procedure = resp
          Api.ClientProcedures.get(
            { procedures: procedureId },
            function (resp) {
              if (resp.results.length) {
                $scope.procedure = _extends({}, procedure, {
                  clientProcedure: resp.results[0],
                })
              } else {
                $scope.procedure = _extends({}, procedure, {
                  clientProcedure: {
                    projects: [],
                    client: CurrentUser.getClientId(),
                  },
                })
              }
              getProjects()
            },
          )
        })
      }
      $scope.getProceduresByID()

      //Restore pagination values.
      $scope.backToSearch = function () {
        $state.go('app.procedures.list', {
          app: app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }
    },
  )
