import * as React from 'react'
import moment from 'moment'
import { css } from '@emotion/react'
import { useAngularServices, BesideInputButton } from '@/react/components'

import { TextInputStyle } from '@screens/components'
import { DeleteConfirmationModal } from '../Timecard/SettingsPage/components/DeleteConfirmationModal'
import deleteCross from '/src/assets/icons/x-red-thin.svg'
import classNames from 'classnames'
import { BinDeleteIcon } from '@/react/componentAssets'
import { variableFieldToNames } from '@screens/components/FieldsWithVariableAmount'

export function SingleVariableAmountField({
  fieldId,
  wholeAnswerLink,
  answersLink,
  errorRef,
  isFullScreenWidth,
  isAllowMultiLine,
  isAllowDeleteConfirmation,
  maxLength,
  saveDataHandler,
  deleteFromServer,
  uniqModalId,
  isFieldChanged,
  typeOfField,
  setAnswerState,
  isIssueResolved = false,
  debouncedSend,
}) {
  const [isDeleted, setIsDeleted] = React.useState(false)
  const [error, setError] = React.useState(false)
  const { CurrentUser } = useAngularServices()
  const user = CurrentUser.getInstance()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const handleChangeField = (evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target?.value
    const onChangeData = {
      text: value,
      fieldmeta: {
        text: {
          last_updated: moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
          last_updated_by_user_id: user.id,
        },
      },
      deleted: false,
    }
    if (answersLink[fieldId].id) {
      onChangeData.id = answersLink[fieldId].id
      onChangeData.isEdited = true
    }
    answersLink[fieldId] = onChangeData
    if (errorRef && errorRef.current) {
      errorRef.current.style.display = 'none'
    }
    if (saveDataHandler) {
      saveDataHandler(answersLink)
    }
    if (isFieldChanged) {
      isFieldChanged(true)
    }
    if (debouncedSend) {
      debouncedSend([
        { field: variableFieldToNames[typeOfField], value: answersLink },
        { field: 'edited', value: true },
      ])
    }
  }

  const deleteHandler = () => {
    if (isAllowDeleteConfirmation) {
      deleteFromServer(answersLink[fieldId])
    }

    answersLink[fieldId].id = answersLink[fieldId].id || ''
    answersLink[fieldId].deleted = true
    setIsDeleted(true)
  }

  if (!answersLink[fieldId] || !answersLink[fieldId].text) {
    answersLink[fieldId] = {
      id: answersLink[fieldId] ? answersLink[fieldId].id : '',
      text: '',
      fieldmeta: { text: {} },
      deleted: false,
    }
  }

  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )
  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(rows * lineHeight)
    }
  }
  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      adjustTextareaHeight()
    }
    if (!answersLink[fieldId].text) {
      setError(true)
    }
  }, [])

  if (isDeleted) return null

  const isInvalid =
    error &&
    typeOfField === 'Corrective Action' &&
    isIssueResolved &&
    fieldId === 0

  return (
    <>
      <div css={baseStyle} className={'corrective_action'}>
        {!isAllowMultiLine ? (
          <textarea
            ref={textareaRef}
            className={classNames('form__textarea', {
              error: isInvalid,
            })}
            onFocus={adjustTextareaHeight}
            onInput={adjustTextareaHeight}
            style={{ height: `${textareaHeight}px` }}
            defaultValue={answersLink[fieldId].text || ''}
            maxLength={maxLength}
            onChange={(value) => {
              setError(!value.target.value)
              const onChangeData = {
                text: value.target.value,
                fieldmeta: {
                  text: {
                    last_updated: moment(new Date()).format(
                      'YYYY-MM-DDTHH:mm:ss.SSSZ',
                    ),
                    last_updated_by_user_id: user.id,
                  },
                },
                deleted: false,
              }
              if (answersLink[fieldId].id) {
                onChangeData.id = answersLink[fieldId].id
                onChangeData.isEdited = true
              }
              answersLink[fieldId] = onChangeData
              if (errorRef && errorRef.current) {
                errorRef.current.style.display = 'none'
              }
              if (saveDataHandler) {
                saveDataHandler(answersLink)
              }
              if (isFieldChanged) {
                isFieldChanged(true)
              }
              if (debouncedSend) {
                debouncedSend([
                  {
                    field: variableFieldToNames[typeOfField],
                    value: answersLink,
                  },
                  { field: 'edited', value: true },
                ])
              }
            }}
          />
        ) : (
          <textarea
            id={fieldId}
            name={fieldId}
            style={
              isFullScreenWidth && {
                width: '100%',
                height: `${textareaHeight}px`,
              }
            }
            onFocus={adjustTextareaHeight}
            onInput={adjustTextareaHeight}
            onChange={handleChangeField}
            defaultValue={answersLink?.[fieldId]?.text || ''}
            ref={textareaRef}
          ></textarea>
        )}
        {isAllowDeleteConfirmation ? (
          <>
            <DeleteConfirmationModal
              uniqModalId={uniqModalId}
              currentId={fieldId}
              deleteHandler={deleteHandler}
            />
            <button
              data-toggle="modal"
              data-target={`#delete-${uniqModalId}-${fieldId}`}
              type="button"
              className="delete-btn"
            >
              <img src={deleteCross} />
            </button>
          </>
        ) : (
          <BinDeleteIcon color={variableColor} onClick={deleteHandler} />
        )}
      </div>
    </>
  )
}

const baseStyle = css({
  display: 'flex',
  marginBottom: 20,
  alignItems: 'center',
  svg: {
    paddingLeft: 5,
  },
  input: {
    ...TextInputStyle,
    width: '90%',
  },
  textarea: {
    ...TextInputStyle,
    resize: 'none',
    overflow: 'hidden',
    height: '34.5px',
  },
  '.delete-btn': {
    borderColor: '#e5e6e7',
    backgroundColor: '#fff',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '0',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '34px',
    width: '34px',
    fontSize: '34px',
    img: {
      color: 'red',
      margin: 0,
      width: '12px',
      height: '12px',
    },
  },
  '.form__textarea': {
    width: '100%',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    minHeight: '30px',
    maxWidth: '100%',
    resize: 'vertical',
  },
  '.error': {
    borderColor: 'red',
  },
})
