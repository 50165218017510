import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import {
  ButtonElement,
  CommonPageHeader,
  FloatingWindowHorizontalNavigation,
  NumberInput,
  useAngularServices,
} from '@/react/components'
import { getTimeCardNavigationData } from '../components/commonVariables'
import { useAutoSizeTextArea, useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { SettingTitle } from './components/SettingTitle'
import { FieldsWithVariableAmount } from '../../components'
import { WeeklyAlert } from './components/WeeklyAlert'
import { SettingsSaveReminder } from './components/SettingsSaveReminder'
import { default as ReactSelect } from 'react-select'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'
import { convertTime12toSeconds } from '@/utils'

export const SettingsPage = () => {
  const optionsForTimezone = [
    { value: 'America/New_York', label: 'ET - Eastern' },
    { value: 'America/Chicago', label: 'CT - Central' },
    { value: 'America/Denver', label: 'MT - Mountain' },
    { value: 'America/Los_Angeles', label: 'PT - Pacific' },
    { value: 'America/Anchorage', label: 'AT - Alaska' },
    { value: 'America/Honolulu', label: 'HT - Hawaii' },
  ]

  const { $rootScope, Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [redirectTo, setRedirectTo] = useState(false)
  const [disclaimerField, setDisclaimerField] = useState('')
  const [isOpenSaveReminder, setIsOpenSaveReminder] = useState(false)
  const [mealBreakField, setMealBreakField] = useState('30')
  const [defHour, setDefHour] = useState(0)
  const [defMinute, setDefMinute] = useState(0)
  const [dayPart, setDayPart] = useState()
  const [timezone, setTimezone] = useState(optionsForTimezone[3])
  const [defaultMarkupField, setDefaultMarkupField] = useState({})
  const [termsField, setTermsField] = useState([])
  const [categoriesField, setCategoriesField] = useState([])
  const [notesField, setNotesField] = useState([])
  const [isDisclaimerFieldChanged, setIsDisclaimerFieldChanged] =
    useState(false)
  const [isMealFieldChanged, setIsMealFieldChanged] = useState(false)
  const [isDefTimeFieldChanged, setIsDefTimeFieldChanged] = useState(false)
  const [isTimezoneChanged, setIsTimezoneChanged] = useState(false)
  const [isMarkupFieldChanged, setIsMarkupFieldChanged] = useState(false)
  const [isTermsFieldChanged, setIsTermsFieldChanged] = useState(false)
  const [isDisableSubmitBtn, setIsDisableSubmitBtn] = useState(false)
  const [isCategoriesFieldChanged, setIsCategoriesFieldChanged] =
    useState(false)
  const [isNotesFieldChanged, setIsNotesFieldChanged] = useState(false)
  const disclaimerFieldRef = useRef<HTMLTextAreaElement>(null)
  useAutoSizeTextArea(disclaimerFieldRef.current, disclaimerField)

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  async function fetchTermsData() {
    const { data: response } = await Api.TimecardSettingsTerms.get({})
    if (response.results.length) {
      setTermsField(response.results)
    }
  }
  async function fetchCategoriesData() {
    const { data: response } = await Api.TimecardSettingsCrewCategory.get({})
    if (response.results.length) {
      setCategoriesField(
        response.results.map((el) => ({
          id: el.id,
          text: el.name,
          deleted: el.deleted,
        })),
      )
    }
  }

  async function fetchNotesData() {
    const { data: response } = await Api.get('timecards_notes', {
      pull_down: true,
    })

    if (response.results.length) {
      setNotesField(
        response.results.map((el) => ({
          id: el.id,
          text: el.note,
          deleted: el.deleted,
        })),
      )
    }
  }

  async function fetchAll() {
    const { data: resp } = await Api.TimecardSettingsTimecard.get({})
    if (resp.results.length) {
      const { text, meal_duration, default_timezone } = resp.results[0]
      const defaultTimeZoneIndex = optionsForTimezone.findIndex(
        (timezone) => timezone.value === default_timezone,
      )
      setTimezone(optionsForTimezone[defaultTimeZoneIndex])
      const splitTime = resp.results[0].default_start.split(':')
      if (splitTime[0] >= 12) {
        setDayPart('PM')
      } else {
        setDayPart('AM')
      }
      if (splitTime[0] > 12) {
        setDefHour(splitTime[0] - 12)
      } else {
        setDefHour(splitTime[0])
      }
      setDefMinute(splitTime[1])
      setDisclaimerField(text)
      setMealBreakField(meal_duration)
    }

    const { data: markupResp } = await Api.TimecardSettingsMarkup.get({})
    if (markupResp.results.length) {
      setDefaultMarkupField(markupResp.results[0])
    }

    await fetchTermsData()

    await fetchCategoriesData()

    await fetchNotesData()
  }

  useEffect(() => {
    fetchAll()
  }, [])

  if (!dayPart) return null

  $rootScope.$on('$stateChangeStart', function (e, toState) {
    if (
      (isDisclaimerFieldChanged ||
        isMarkupFieldChanged ||
        isMealFieldChanged ||
        isTermsFieldChanged ||
        isCategoriesFieldChanged ||
        isNotesFieldChanged) &&
      !isOpenSaveReminder
    ) {
      setIsOpenSaveReminder(true)

      e.preventDefault()
      setRedirectTo(toState.name)
    } else {
      e.defaultPrevented = false
    }
  })

  const closeSaveReminderModal = () => setIsOpenSaveReminder(false)
  const leaveAnywaysHandler = () => {
    setIsOpenSaveReminder(false)

    stateService.go(redirectTo, {})
  }

  const deleteTermsHandler = (currentElement) => {
    if (currentElement.id) {
      Api.TimecardSettingsTerms.delete(currentElement)
    }
  }

  const deleteCategoryHandler = (currentElement) => {
    if (currentElement.id) {
      Api.TimecardSettingsCrewCategory.delete(currentElement)
    }
  }

  const deleteNoteHandler = (currentElement) => {
    if (currentElement.id) {
      Api.TimecardSettingsNotes.delete(currentElement)
    }
  }

  const handleChangeDisclaimerField = (
    evt: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    const value = evt.target?.value

    setDisclaimerField(value)
    setIsDisclaimerFieldChanged(true)
  }

  const defaultMarkupFieldHandler = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value
    const reg = RegExp(/^\d+(\.\d{0,2})?$/g)

    if (value === '' || reg.test(value)) {
      setDefaultMarkupField({ ...defaultMarkupField, markup: value })
      setIsMarkupFieldChanged(true)
    }
  }

  const mealBreakFieldHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    const reg = RegExp(/^[0-9\b]+$/)

    if (value === '' || reg.test(value)) {
      setMealBreakField(value)
      setIsMealFieldChanged(true)
    }
  }
  const handleHourChange = (value) => {
    setDefHour(value)
    setIsDefTimeFieldChanged(true)
  }

  const handleMinuteChange = (value) => {
    setDefMinute(value)
    setIsDefTimeFieldChanged(true)
  }

  const handleTimezoneChange = (value) => {
    setTimezone(value)
    setIsTimezoneChanged(true)
  }

  const handleSubmit = () => {
    setIsDisableSubmitBtn(true)
    if (
      isDisclaimerFieldChanged ||
      isMealFieldChanged ||
      isDefTimeFieldChanged ||
      isTimezoneChanged
    ) {
      setIsDisclaimerFieldChanged(false)
      setIsMealFieldChanged(false)
      Api.TimecardSettingsTimecard.post({
        text: disclaimerField,
        meal_duration: mealBreakField || 0,
        default_start: moment
          .utc(convertTime12toSeconds(defHour, defMinute, dayPart, true))
          .format('HH:mm:ss'),
        default_timezone: timezone.value,
      })
    }

    if (isMarkupFieldChanged) {
      setIsMarkupFieldChanged(false)
      Api.TimecardSettingsMarkup.post({ markup: defaultMarkupField?.markup })
    }

    setIsCategoriesFieldChanged(false)
    setIsNotesFieldChanged(false)
    setIsTermsFieldChanged(false)

    for (let i = 0; i < termsField.length; i++) {
      if (termsField[i].text && !termsField[i].deleted) {
        if (!termsField[i].id) {
          termsField[i].id = 1
          if (defaultMarkupField?.id) {
            Api.TimecardSettingsTerms.post(
              {
                text: termsField?.[i]?.text,
                settings: defaultMarkupField?.id,
              },
              (resp) => {
                termsField[i].id = resp.id
              },
            )
          } else {
            Api.TimecardSettingsMarkup.post({})
            Api.TimecardSettingsTerms.post(
              {
                text: termsField?.[i]?.text,
                settings: 1,
              },
              (resp) => {
                termsField[i].id = resp.id
              },
            )
          }
        }

        if (termsField[i].isEdited) {
          Api.TimecardSettingsTerms.patch({
            id: termsField?.[i]?.id,
            text: termsField?.[i]?.text,
            settings: defaultMarkupField?.id,
          })
        }
      }
    }

    for (let i = 0; i < categoriesField.length; i++) {
      if (categoriesField[i].text && !categoriesField[i].deleted) {
        if (!categoriesField[i].id) {
          categoriesField[i].id = 1
          Api.TimecardSettingsCrewCategory.post(
            {
              name: categoriesField?.[i]?.text,
            },
            (resp) => {
              categoriesField[i].id = resp.id
            },
          )
        }

        if (categoriesField[i].isEdited) {
          Api.TimecardSettingsCrewCategory.patch({
            id: categoriesField?.[i]?.id,
            name: categoriesField?.[i]?.text,
          })
        }
      }
    }

    for (let i = 0; i < notesField.length; i++) {
      if (notesField[i].text && !notesField[i].deleted) {
        if (!notesField[i].id) {
          notesField[i].id = 1
          Api.post(
            'timecards_notes',
            {
              note: notesField?.[i]?.text,
            },
            // (resp) => {
            //   notesField[i].id = resp.id
            // },
          )
        }

        if (notesField[i].isEdited) {
          Api.patch(`timecards_notes/${notesField?.[i]?.id}`, {
            id: notesField?.[i]?.id,
            note: notesField?.[i]?.text,
          })
        }
      }
    }

    setIsCategoriesFieldChanged(false)
    setIsNotesFieldChanged(false)
    setIsTermsFieldChanged(false)
    setIsDisableSubmitBtn(false)
  }

  const optionsForDayTime = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ]

  return (
    <section css={sectionStyle}>
      <CommonPageHeader
        headerText={
          $rootScope.mobile_apps.filter((obj) => {
            return obj.id === Number(stateService.params.app)
          })[0].main_menu_timecard
        }
      />
      <FloatingWindowHorizontalNavigation
        navSettings={getTimeCardNavigationData('settings')}
      />
      <main css={baseStyle}>
        <div className="button-wrapper">
          <ButtonElement
            text="Save"
            width="75px"
            buttonType="submit"
            disabled={isDisableSubmitBtn}
            functionToTrigger={handleSubmit}
          />
        </div>
        <SettingTitle title="TIMECARD" />
        <div className="disclaimer-field">
          <label htmlFor="disclaimer">Disclaimer</label>
          <textarea
            id="disclaimer"
            name="disclaimer"
            onChange={handleChangeDisclaimerField}
            value={disclaimerField}
            ref={disclaimerFieldRef}
          ></textarea>
          <div className="markup-field">
            <label htmlFor="mealBreak">Default Meal Break</label>
            <input
              type="text"
              id="mealBreak"
              value={mealBreakField}
              onChange={mealBreakFieldHandler}
            />{' '}
            Min
          </div>
          <div className="time-row-wrapper">
            <div className="default-start-block">
              <span className="start-block-header">Default Start Time</span>
              <NumberInput
                defaultValue={defHour}
                maxNumber={12}
                width={38}
                height={36}
                valueCallback={handleHourChange}
                placeholder={'HH'}
                arrowScaler={false}
              />
              <span className="separator">:</span>
              <NumberInput
                defaultValue={defMinute}
                width={38}
                height={36}
                maxNumber={60}
                valueCallback={handleMinuteChange}
                placeholder={'MM'}
                arrowScaler={false}
              />
              <ReactSelect
                options={optionsForDayTime}
                defaultValue={
                  dayPart === 'AM' ? optionsForDayTime[0] : optionsForDayTime[1]
                }
                onChange={(value) => {
                  setDayPart(value.value)
                  setIsDefTimeFieldChanged(true)
                }}
                styles={{
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused
                      ? percentageShadeBlendColor(0.4, variableColor) +
                        ' !important'
                      : state.isSelected
                      ? percentageShadeBlendColor(0.1, variableColor) +
                        ' !important'
                      : 'white !important',
                  }),
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '36px',
                    marginLeft: '10px',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '36px',
                    minHeight: '36px',
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  height: '25px',
                  width: '40px',
                  backgroundColor: '#FFFFFF',
                  backgroundImage: 'none',
                  borderColor: '#e5e6e7 !important',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  maxHeight: '64px',
                  borderRadius: '0',
                  fontSize: '14px',
                  marginBottom: '10px',
                  colors: {
                    ...theme.colors,
                    primary: percentageShadeBlendColor(0.2, variableColor),
                  },
                })}
              />
            </div>

            <div className="default-timezone-block">
              <span className="timezone-block-header">Default Timezone</span>
              <ReactSelect
                options={optionsForTimezone}
                defaultValue={timezone}
                onChange={(value) => handleTimezoneChange(value)}
                styles={{
                  option: (baseStyles, state) => ({
                    ...baseStyles,
                    backgroundColor: state.isFocused
                      ? percentageShadeBlendColor(0.4, variableColor) +
                        ' !important'
                      : state.isSelected
                      ? percentageShadeBlendColor(0.1, variableColor) +
                        ' !important'
                      : 'white !important',
                  }),
                  container: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '36px',
                    marginLeft: '10px',
                  }),
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    height: '36px',
                    minHeight: '36px',
                  }),
                }}
                theme={(theme) => ({
                  ...theme,
                  height: '25px',
                  width: '40px',
                  backgroundColor: '#FFFFFF',
                  backgroundImage: 'none',
                  borderColor: '#e5e6e7 !important',
                  borderStyle: 'solid',
                  borderWidth: '1px',
                  maxHeight: '64px',
                  borderRadius: '0',
                  fontSize: '14px',
                  marginBottom: '10px',
                  colors: {
                    ...theme.colors,
                    primary: percentageShadeBlendColor(0.2, variableColor),
                  },
                })}
              />
            </div>
          </div>
          {/* <WeeklyAlert /> */}
        </div>

        <FieldsWithVariableAmount
          typeOfField="Note Prompt"
          answersLink={notesField}
          deleteFromServer={deleteNoteHandler}
          saveDataHandler={setNotesField}
          isAllowDeleteConfirmation={true}
          maxLength={50}
          uniqModalId="notes"
          isFieldChanged={setIsNotesFieldChanged}
        />

        <SettingTitle title="DAILY REPORTS" />
        <div className="categories-wrapper">
          <FieldsWithVariableAmount
            typeOfField="Crew Categories"
            answersLink={categoriesField}
            deleteFromServer={deleteCategoryHandler}
            saveDataHandler={setCategoriesField}
            isAllowDeleteConfirmation={true}
            maxLength={30}
            uniqModalId="category"
            isFieldChanged={setIsCategoriesFieldChanged}
          />
        </div>

        <SettingTitle title="T&M Tickets" />
        <div className="markup-field">
          <label htmlFor="defaultMarkup">Default Markup</label>
          <input
            type="text"
            id="defaultMarkup"
            value={defaultMarkupField?.markup}
            onChange={defaultMarkupFieldHandler}
          />{' '}
          %
        </div>
        <FieldsWithVariableAmount
          typeOfField="Terms and Conditions"
          answersLink={termsField}
          deleteFromServer={deleteTermsHandler}
          saveDataHandler={setTermsField}
          isFullScreenWidth={true}
          isAllowMultiLine={true}
          isAllowDeleteConfirmation={true}
          uniqModalId="terms"
          isFieldChanged={setIsTermsFieldChanged}
        />
      </main>
      {isOpenSaveReminder &&
        (isDisclaimerFieldChanged ||
          isMarkupFieldChanged ||
          isMealFieldChanged ||
          isTermsFieldChanged ||
          isCategoriesFieldChanged) && (
          <SettingsSaveReminder
            closeModal={closeSaveReminderModal}
            leaveAnywaysHandler={leaveAnywaysHandler}
          />
        )}
    </section>
  )
}

const baseStyle = css({
  width: '100%',
  minHeight: '400px',
  backgroundColor: '#fff',
  padding: '29px 36px 29px 29px',
  position: 'relative',
  '.button-wrapper': {
    position: 'absolute',
    top: '11px',
    right: '36px',
    button: {
      fontSize: '12px',
      height: '28px',
    },
  },
  '.disclaimer-field': {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '37px',
    label: {
      color: '#696A6C',
      fontSize: '14px',
      fontWeight: 600,
      marginBottom: '3px',
    },
    textarea: {
      width: '100%',
      minHeight: '81px',
      marginBottom: '20px',
      padding: '7px 20px',
      border: '2px solid #F3F3F3',
      resize: 'none',
      overflow: 'hidden',
      outline: 'none',
    },
  },
  '.markup-field': {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '14px',
    fontSize: '14px',
    fontWeight: 600,
    color: '#696A6C',
    label: {
      color: 'inherit',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      marginRight: '10px',
      marginBottom: 0,
    },
    input: {
      width: '47px',
      height: '36px',
      border: '1px solid #EDEDED',
      marginRight: '6px',
      outline: 'none',
      padding: '0 7px',
    },
  },
  '.time-row-wrapper': {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    gap: '10px 60px',
  },
  '.default-start-block': {
    display: 'flex',
    alignItems: 'center',
    '.start-block-header': {
      marginRight: 10,
    },
    '.separator': {
      marginRight: 5,
      marginLeft: 5,
    },
  },
  '.default-timezone-block': {
    display: 'flex',
    alignItems: 'center',
    '.timezone-block-header': {
      marginRight: 10,
    },
  },
  '.categories-wrapper': {
    marginBottom: '37px',
  },
})

const sectionStyle = css({
  paddingBottom: 200,
})
