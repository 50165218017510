import * as React from 'react'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { cloneDeep, flatMap } from 'lodash'
import {
  ApiService,
  DataWrap,
  ObservationBody,
  PaginatedGet,
  ReportAnswer,
  ReportQuestion,
} from '@/react/types'

export const useFetchObservations = () => {
  const [fetchedData, setFetchedData] = React.useState([null, null])

  const { Api, CurrentUser, Notification } = useAngularServices()
  const { stateService } = useRouter()

  const observationID = stateService.params.observation

  React.useEffect(() => {
    async function fetchData() {
      if (observationID) {
        const requestParams = {
          deleted: '',
          observation: observationID,
          page_size: 1000,
          application: stateService.params.app,
        }
        const { data: baseObservationData }: DataWrap<ObservationBody> =
          await Api.Observations.byIDWithoutDeletedParam(observationID)

        const allAnswers: ReportAnswer[] = []

        const allQuestions: ReportQuestion[] = []

        const questionsPromises: Promise<
          DataWrap<PaginatedGet<ReportQuestion[]>>
        >[] = []

        const PAGE_REQUEST_SIZE = 500
        const questionPagesToGet: number = Math.ceil(
          baseObservationData.questions / PAGE_REQUEST_SIZE,
        )

        for (let i = 1; i < questionPagesToGet + 1; i++) {
          questionsPromises.push(
            Api.Questions.get({
              ...requestParams,
              page_size: PAGE_REQUEST_SIZE,
              page: i,
            }),
          )
        }

        const questionsGet: DataWrap<PaginatedGet<ReportQuestion[]>>[] =
          await Promise.all(questionsPromises)

        questionsGet.forEach((singleQuestionGet) => {
          singleQuestionGet.data.results.forEach((question) => {
            if (question.custom_categories.length) {
              question.custom_categories.forEach((category) => {
                const newQuestion = cloneDeep(question)
                newQuestion.category = category
                allQuestions.push(newQuestion)
              })
            }
          })
        })

        const answerPagesToGet: number = Math.ceil(
          baseObservationData.answers_count / PAGE_REQUEST_SIZE,
        )

        const answersPromises: Promise<
          DataWrap<PaginatedGet<ReportAnswer[]>>
        >[] = []

        for (let i = 1; i < answerPagesToGet + 1; i++) {
          answersPromises.push(
            Api.Answers.get({
              ...requestParams,
              page_size: PAGE_REQUEST_SIZE,
              page: i,
            }),
          )
        }

        const answersGet: DataWrap<PaginatedGet<ReportAnswer[]>>[] =
          await Promise.all(answersPromises)

        answersGet.forEach((singleAnswerGet) => {
          singleAnswerGet.data.results.forEach((answer) => {
            allAnswers.push(answer)
          })
        })

        setFetchedData([
          baseObservationData,
          dataChange(
            addCategoryNameToQuestion(baseObservationData, allQuestions),
            allAnswers,
          ),
        ])
      } else if (
        stateService.params.key &&
        stateService.current.name === 'share.observation'
      ) {
        const { data: sharedObservationData } = await Api.get(
          'shares/observation/' + stateService.params.key,
          {},
        )
        CurrentUser.setClientSettings(
          sharedObservationData.client.general_settings,
        )

        const questions: ReportQuestion[] = []

        sharedObservationData.questions.forEach((question) => {
          if (question.custom_categories.length) {
            question.custom_categories.forEach((category) => {
              const newQuestion = cloneDeep(question)
              newQuestion.category = category
              questions.push(newQuestion)
            })
          }
        })

        setFetchedData([
          sharedObservationData,
          dataChange(
            addCategoryNameToQuestion(sharedObservationData, questions),
            sharedObservationData.answers,
          ),
        ])
      } else if (
        stateService.params.key &&
        stateService.current.name === 'share.batch_report'
      ) {
        const { data: batchObservationData }: DataWrap<ObservationBody> =
          await Api.get(
            'shares/batch_report/' + stateService.params.key,
            {
              deleted: false,
            },
            () => {
              return
            },
            () => {
              return
            },
            true,
          )
        if (batchObservationData.message) {
          Notification.danger(message)
          return
        }
        batchObservationData.questions = await getQuestions(
          Api,
          stateService.params.key,
          true,
        )
        CurrentUser.setClientSettings(
          batchObservationData.client.general_settings,
        )

        const wildcards = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_wildcards.forEach((wildcard) => {
              if (!wildcard.deleted) wildcards.push(wildcard)
            })
          },
        )
        batchObservationData.observation_wildcards = wildcards

        const notes = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_notes.forEach((note) => {
              if (!note.deleted) notes.push(note)
            })
          },
        )
        batchObservationData.observation_notes = notes

        setFetchedData([
          batchObservationData,
          batchDataChange(batchObservationData),
        ])
      } else if (stateService.params.batch_report) {
        const { data: batchObservationData }: DataWrap<ObservationBody> =
          await Api.get(
            'batch_report/' + stateService.params.batch_report,
            {
              deleted: false,
            },
            () => {
              return
            },
            () => {
              return
            },
            true,
          )
        if (batchObservationData.message) {
          Notification.danger(batchObservationData.message)
          return
        }
        batchObservationData.questions = await getQuestions(
          Api,
          stateService.params.batch_report,
          false,
        )

        const notes = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_notes.forEach((note) => {
              if (!note.deleted) notes.push(note)
            })
          },
        )
        batchObservationData.observation_notes = notes

        const wildcards = []
        Object.values(batchObservationData.observations_detail).forEach(
          (observation) => {
            observation.observation_wildcards.forEach((wildcard) => {
              if (!wildcard.deleted) wildcards.push(wildcard)
            })
          },
        )
        batchObservationData.observation_wildcards = wildcards

        setFetchedData([
          batchObservationData,
          batchDataChange(batchObservationData),
        ])
      }
    }

    fetchData()
  }, [observationID])

  return fetchedData || null
}

function addCategoryNameToQuestion(observation, questions) {
  const newQuestions = []
  observation.categories.forEach((cat) => {
    questions.forEach((question) => {
      if (cat.id === question.category)
        newQuestions.push({
          ...cloneDeep(question),
          category: cat,
        })
    })
  })

  return newQuestions
}

function dataChange(questionsData, answersData) {
  answersData.sort(
    (a, b) =>
      questionsData.findIndex((item) => item.id === a.question) -
      questionsData.findIndex((item) => item.id === b.question),
  )

  const listsByOrder = {}

  const lists = {
    no: {},
    pr: {},
    pa: {},
    cls: {},
    yes: {},
    'n/a': {},
  }

  let answeredQuestionOrder = 1

  questionsData.forEach((question, idx) => {
    listsByOrder[idx + 1] = {
      question,
      questionId: question.id,
      order: idx + 1,
      answer: {
        notes: [],
        corrective_actions: [],
        photos: [],
        synchronised: [],
        internal_assignees: [],
        external_assignees: [],
        answer_party_observed_answer_list: [],
        answer: 'n/a',
        reference: '',
        severity: 1,
        deleted: false,
        copy_sequence_number: 0,
      },
    }
    answersData.forEach((answer, answerIdx) => {
      if (
        listsByOrder[idx + 1].questionId === answer.question &&
        listsByOrder[idx + 1].question.category.id === answer.category
      ) {
        listsByOrder[idx + 1].answer = answer
        listsByOrder[idx + 1].answeredFieldOrder = answerIdx
        answeredQuestionOrder += 1
      }
    })
  })

  flatMap(listsByOrder).forEach((question) => {
    lists[question.answer.answer][question.order] = question
  })
  flatMap(lists['n/a']).forEach((answer) => {
    lists['n/a'][answer.order].answeredFieldOrder = answeredQuestionOrder
    answeredQuestionOrder += 1
  })

  return lists
}

async function getQuestions(Api: ApiService, uuid: string, shared: boolean) {
  let questions: ReportQuestion[] = []
  const PAGE_REQUEST_SIZE = 500
  const { data: firstGet }: DataWrap<PaginatedGet<ReportQuestion[]>> =
    await Api.get(
      (shared ? 'shares/batch_report/' : 'batch_report/') + uuid + '/questions',
      {
        deleted: false,
        page_size: 1,
      },
      () => {
        return
      },
      () => {
        return
      },
      true,
    )
  const pageAmount: number = Math.ceil(firstGet.count / PAGE_REQUEST_SIZE)
  const promiseList: Promise<DataWrap<PaginatedGet<ReportQuestion[]>>>[] = []
  for (let i = 1; i <= pageAmount; i++) {
    promiseList.push(
      Api.get(
        (shared ? 'shares/batch_report/' : 'batch_report/') +
          uuid +
          '/questions',
        {
          deleted: false,
          page_size: PAGE_REQUEST_SIZE,
          page: i,
        },
        () => {
          return
        },
        () => {
          return
        },
        true,
      ),
    )
  }
  const questionsGet = await Promise.all(promiseList)
  questionsGet.forEach((questionGet) => {
    questions = [...questions, ...questionGet.data.results]
  })

  return questions
}

function batchDataChange(getData) {
  const lists = {
    no: {},
    pr: {},
    pa: {},
    cls: {},
    yes: {},
    'n/a': {},
  }

  let singleOrder = 1

  getData.questions.forEach((question) => {
    if (!question?.answers?.length) {
      lists['n/a'][singleOrder] = {
        question: question,
        order: singleOrder,
        answer: {
          answer: 'n/a',
          photos: [],
        },
      }
      singleOrder += 1
    } else {
      question?.answers?.forEach((answer) => {
        if (answer) {
          lists[answer?.answer][singleOrder] = {
            question: question,
            order: singleOrder,
            answer: answer,
          }
          singleOrder += 1
        }
      })
    }
  })

  let fieldsOrder = 1

  for (const key in lists) {
    Object.values(lists[key]).forEach((field) => {
      lists[key][field.order] = {
        ...field,
        answeredFieldOrder: fieldsOrder,
      }
      fieldsOrder += 1
    })
  }

  return lists
}
