import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { ObservationNavBar } from '../components/ObservationNavBar'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRouter } from '@/react/hooks'
import { singleHeaderTypes } from '../../components'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import actionIcon from '@/assets/icons/action-icon.svg'
import closeIcon from '@/assets/icons/close-icon.svg'
import { css } from '@emotion/react'
import { DeletedObservationRow } from './components/DeletedObservationRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const DeletedObservationsList = () => {
  const { Api, CurrentUser, $rootScope } = useAngularServices()
  const { stateService } = useRouter()

  const [deletedList, setDeletedList] = useState([])
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(stateService.params.pageNumber || 1)
  const [order, setOrder] = useState('-date_created')
  const [isAllChecked, setAllChecked] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [isRestoreClicked, setRestoreClicked] = useState(false)
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const [count, setCount] = useState<number>(0)
  const [refresh, setRefresh] = useState(false)

  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const restorePopUpRef = useRef()

  const handleClickOutside = (event) => {
    if (
      restorePopUpRef.current &&
      !restorePopUpRef.current.contains(event.target)
    ) {
      setRestoreClicked(false)
    }
  }

  useEffect(() => {
    if (isRestoreClicked) {
      document.addEventListener('click', handleClickOutside)
    } else {
      document.removeEventListener('click', handleClickOutside)
    }

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [isRestoreClicked])

  const handleCheckedAll = () => {
    const observationIds = deletedList.map((observation) => observation?.id)
    const selectedToSet = !isAllChecked ? observationIds : []
    setAllChecked(!isAllChecked)
    setSelectedIds(selectedToSet)
  }

  const handleRestore = async () => {
    await Api.postWithParams(
      'observations/bulk_restore',
      {},
      {
        search,
        ids: isAllChecked ? 'all' : selectedIds.join(','),
      },
    )
    setPage(1)
    setAllChecked(false)
    setRestoreClicked(false)
    setRefresh(true)
    setSelectedIds([])
  }

  useEffect(() => {
    const role = CurrentUser.getRole()
    const isRestricted = role == 'supervisor' || role === 'client_user'

    if (isRestricted) {
      stateService.go('app.company_news.list', {})
    }
  }, [])

  useEffect(() => {
    const getDeletedList = async () => {
      const { data } = await Api.getWithoutDeletedParam('observations', {
        application: stateService.params.app,
        counter: true,
        deleted: true,
        order,
        page,
        search,
        role: true,
        table: true,
        tz_offset: -120,
      })

      setDeletedList(data.results)
      setCount(data.count)
      setRefresh(false)
    }
    getDeletedList()
  }, [order, page, search, refresh])

  const configurableProjectTitle = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    ).projects_display
  }, [$rootScope])

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        minWidth: '50px',
        width: '50px',
        textAlign: 'left',
        paddingLeft: '15px',
        paddingRight: '22px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered ? (
            <div className="actions__dropdown hover">
              <div className="hover__text">Actions</div>
            </div>
          ) : null}
          {isActionsClicked && selectedIds.length ? (
            <div className="actions__dropdown">
              <div
                className="action__text"
                onClick={() => {
                  setRestoreClicked(true)
                  setActionsClicked(false)
                }}
              >
                Restore
              </div>
            </div>
          ) : null}
          {isRestoreClicked && selectedIds.length ? (
            <div className="restore__popup" ref={restorePopUpRef}>
              <img
                className="popup__cross"
                src={closeIcon}
                alt="close"
                onClick={() => {
                  setRestoreClicked(false)
                }}
              />
              <div className="popup__title">Restore</div>
              <div className="popup__subtitle">
                {`${isAllChecked ? count : selectedIds.length} ${
                  selectedIds.length > 1 ? 'Reports' : 'Report'
                }?`}
              </div>
              <ButtonElement
                text="OK"
                buttonType="submit"
                additionalStyles={css({ marginBottom: '25px' })}
                functionToTrigger={() => {
                  handleRestore()
                }}
              />
            </div>
          ) : null}
        </div>
      ),
    },
    {
      className: 'topic',
      name: 'Title',
      listLocation: 'app.toolboxes.deleted',
      type: 'title',
      filterHeader: true,
      additionalStyles: {
        width: '250px',
        minWidth: '250px',
        paddingLeft: '10px',
        textAlign: 'left',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
    },
    {
      className: 'originator',
      name: 'Originator',
      listLocation: 'app.toolboxes.deleted',
      type: 'user__first_name',
      filterHeader: true,
      additionalStyles: {
        width: '157px',
        paddingLeft: 0,
        minWidth: '157px',
        textAlign: 'left',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
    },
    {
      className: 'company',
      name: 'Company',
      listLocation: 'app.toolboxes.deleted',
      type: 'user__company_name',
      filterHeader: true,
      additionalStyles: {
        width: '172px',
        minWidth: '172px',
        paddingLeft: 0,
        textAlign: 'left',
        p: {
          width: 'fit-content',
          margin: 0,
        },
        '.header-base': {
          width: 'fit-content',
        },
      },
    },
    {
      className: 'project-name',
      name: configurableProjectTitle,
      listLocation: 'app.toolboxes.deleted',
      type: 'project__name',
      filterHeader: true,
      additionalStyles: {
        width: '183px',
        minWidth: '183px',
        textAlign: 'left',
        p: { margin: 0 },
        padding: 0,
      },
    },
    {
      className: 'completed',
      name: 'Performed',
      listLocation: 'app.toolboxes.deleted',
      type: 'date_performed',
      filterHeader: true,
      additionalStyles: {
        width: '91px',
        minWidth: '91px',
        textAlign: 'left',
        p: { margin: 0, display: 'flex' },
        padding: 0,
      },
    },
  ]

  const handleChecked = (observationId: number) => {
    if (selectedIds.includes(observationId)) {
      setSelectedIds(selectedIds.filter((id) => id !== observationId))
      setAllChecked(false)
    } else {
      setSelectedIds([...selectedIds, observationId])
    }
  }

  const isSafari = checkIsSafari()
  const baseStyle = getBaseStyle(variableColor, isSafari)

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  return (
    <section className="deleted__observations" css={baseStyle}>
      <CommonPageHeader
        headerText={configurableNamesList.observations_display}
      />
      <ObservationNavBar />
      <main className="wrapper">
        <div className="search-base">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
            extraFunction={() => {
              setAllChecked(false)
            }}
          />
        </div>
        <div className="table__wrapper">
          <table>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              callback={setOrder}
              popUpLocation={false}
              updateUrl={false}
              order={order}
              setOrder={setOrder}
            />
            <tbody>
              {deletedList.map((observation, index) => (
                <DeletedObservationRow
                  key={observation?.id}
                  observation={observation}
                  index={index}
                  handleChecked={handleChecked}
                  selectedIds={selectedIds}
                  isAllChecked={isAllChecked}
                  search={search}
                  page={page}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </main>
    </section>
  )
}

const getBaseStyle = (variableColor: string, isSafari: boolean) =>
  css({
    paddingBottom: '250px',
    '#search': {
      width: '264px',
    },
    '.wrapper': {
      background: '#fff',
      width: 'fit-content',
      paddingBottom: '20px',
    },
    '.search-base': {
      padding: '20px 0px',
    },
    td: {
      paddingRight: '10px',
      height: '40px',
    },
    '.title__td': {
      paddingLeft: '13px',
    },
    '.checkmark__td': {
      paddingLeft: '15px',
    },
    '.static-nav': {
      borderBottom: '2px solid #C6C6C6',
    },
    '.header-actions': {
      display: 'flex',
      justifyContent: 'center',
      gap: '14px',
      position: 'relative',
    },
    '.action': {
      marginTop: '3px',
      paddingInline: '3px',
      cursor: 'pointer',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.link__cell': {
      display: 'flex',
      gap: '15px',
      color: '#3980CE',
      cursor: 'pointer',
      alignItems: 'center',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.page-switcher-base': {
      paddingTop: '15px',
    },
    '.table__row': {
      color: '#575757',
      backgroundColor: '#fff',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.checkbox__td': {
      paddingLeft: '15px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.topic__td': {
      paddingLeft: '10px',
    },
    '.text__styles': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      margin: 0,
    },
    '.category': {
      maxWidth: '250px',
      cursor: 'pointer',
      color: '#3980CE',
    },
    '.name': {
      maxWidth: '157px',
    },
    '.company': {
      maxWidth: '172px',
    },
    '.project': {
      maxWidth: '183px',
    },
    '.restore__popup': {
      position: 'absolute',
      background: '#FFF',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
      zIndex: 1,
      width: '200px',
      left: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    '.popup__cross': {
      display: 'flex',
      alignSelf: 'flex-end',
      marginRight: '7px',
      marginTop: '7px',
      cursor: 'pointer',
    },
    '.popup__title': {
      color: '#666',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      marginTop: '20px',
      paddingInline: '20px',
    },
    '.popup__subtitle': {
      color: 'grey',
      textAlign: 'center',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      letterSpacing: '-0.14px',
      paddingInline: '20px',
      marginBottom: '14px',
    },
    '@media(max-width: 1370px)': {
      '.table__container': {
        overflow: 'scroll',
      },
    },
    '.full__name': {
      display: !isSafari ? 'block' : 'none',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '353px',
      padding: '4px 11px',
      maxWidth: '400px',
    },
    '.title__fullname': {
      left: '50px',
    },
    '.company__fullname': {
      left: '480px',
    },
    '.project__fullname': {
      left: '750px',
    },
    '.title__text': {
      margin: '0px',
      maxHeight: '40px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: '10xp',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '@media(max-width: 1175px)': {
      '.full__name': {
        display: 'none',
      },
      '.wrapper': {
        width: '100%',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
        overflowY: 'hidden',
        minHeight: '160px',
      },
    },
    '@media(max-width: 800px)': {
      '.restore__popup': {
        left: '100px',
      },
    },
  })
