import { AddFileIcon, AddImageIcon } from '@/react/componentAssets'
import {
  NoteElement,
  SingleFileWithLoading,
} from '../../MobileForms/CreateEditPages/components'
import { css } from '@emotion/react'
import { flatMap } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useAngularServices } from '@/react/components'
import { SingleFileTrainings } from './SingleFileTrainings'

export const FileUploadTrainings = ({
  setAttchmentId,
  attachmentFromServer,
}) => {
  const baseStyle = getBaseStyle('red')
  const inputRef = useRef()
  const [error, setError] = useState(false)
  const { Api } = useAngularServices()
  const [isLoaded, setIsLoaded] = useState(false)
  const [file, setFile] = useState('')

  async function addFileWithLoading(value) {
    if (error) setError(false)

    setIsLoaded(false)

    setFile(value.target.files[0])

    try {
      const { data: fileResponse } = await Api.uploadFile(
        value.target.files[0],
        {},
      )
      setIsLoaded(true)
      setFile(fileResponse)
      setAttchmentId(fileResponse.id)
    } catch {
      setIsLoaded(false)
    }
  }

  useEffect(() => {
    if (attachmentFromServer.name) {
      setIsLoaded(true)
      setFile(attachmentFromServer)
    }
  }, [attachmentFromServer])

  return (
    <section css={baseStyle}>
      <label className="file-input">
        <AddFileIcon height={'30px'} width={'30px'} color={'#3980CD'} />
        <input
          accept={'.pdf'}
          multiple={false}
          type="file"
          onChange={(value) => {
            addFileWithLoading(value)
          }}
          ref={inputRef}
        />
      </label>
      <div className="photoBlock">
        {file.name && (
          <SingleFileTrainings
            key={file.id}
            id={file.id}
            file={file}
            isLoaded={isLoaded}
            removeFunction={() => {
              setFile('')
              setAttchmentId('')
            }}
          />
        )}
      </div>
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    width: '100%',
    display: 'flex',
    gap: '15px',
    '.file-input': {
      img: {
        width: 30,
      },
      margin: 0,
      cursor: 'pointer',
      color: variableColor + ' !important',
      display: 'flex',
      alignSelf: 'flex-end',
      input: { display: 'none' },
    },
    '.photoBlock': {
      display: 'flex',
      flexWrap: 'wrap',
      position: 'relative',
    },
    '.btn-default-border': {
      ':hover': {
        color: 'white !important',
      },
    },
    '.body-for-uploaded': {
      marginTop: '10px',
      marginRight: '10px',
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
      position: 'relative',
      '.loaded-check': {
        backgroundImage: 'url("../../assets/icons/Check.svg")',
        marginRight: '10px',
        height: '20px',
        width: '20px',
      },
      '.file-name-base-block': {
        height: '32px',
        paddingLeft: '5px',
        paddingRight: '5px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#F7F7F7',
        border: '1px solid #999',
        '.file-icon': {
          padding: 0,
          height: '20px',
          width: '20px',
          backgroundRepeat: 'no-repeat',
          backgroundImage: 'url("../../assets/icons/Card Icon.svg")',
        },
        '.file-name': {
          height: '18px',
          margin: 0,
          width: '100px',
          whiteSpace: 'nowrap',
        },
        '.file-error': {
          height: '18px',
          color: '#C01C20',
          fontStyle: 'italic',
          margin: 0,
          width: '122px',
        },
        '.remove-file-cross-base': {
          padding: 0,
          height: '20px',
          width: '20px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          ' .remove-file-cross': {
            padding: 'inherit',
            height: '15px',
            width: '15px',
          },
          '.remove-file-cross-red': {
            padding: 'inherit',
            height: '20px',
            width: '20px',
          },
        },
        '.retry-button-base': {
          padding: 0,
          height: '32px',
          width: '32px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          '.retry-button': {
            padding: 'inherit',
            height: '20px',
            width: '20px',
          },
        },
        '.spinner': {
          height: '20px',
          width: '20px',
        },
      },
      '.error-due-to-size': {
        backgroundColor: '#FFECEB',
        borderColor: '#C01C20',
      },
      '.hover-body-for-files': {
        position: 'absolute',
        left: '-20px',
        bottom: 'calc(100% + 5px)',
        display: 'none',
        border: '1px solid #999',
        backgroundColor: 'white',
        padding: '5px,',
      },
    },
  })
}
