import { css } from '@emotion/react'
import { AssessmentNavBar } from '../components/AssessmentNavBar'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { SingleScoring } from './components/SingleScoring'
import { FieldArray, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import * as yup from 'yup'

export const AssessmentScoreKey = () => {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const { stateService } = useRouter()

  const [initialValues, setInitialValues] = useState({})
  const [resultTypes, setResultTypes] = useState([])

  const [resultTypesRefresh, setResultTypesRefresh] = useState(false)

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  useEffect(() => {
    const getInitialValues = async () => {
      const templateId = stateService?.params?.assessmentTemplateId

      const { data: templateData } = await Api.get(
        `assessment/templates/${templateId}`,
        {},
      )

      const { data: scoreList } = await Api.get('assessment/sections', {
        template: templateId,
        order: 'order',
      })

      if (scoreList?.results?.length) {
        const formattedScoreListPromises = scoreList.results.map(
          async (section) => {
            const { data: score_ranges } = await Api.get(
              'assessment/score_ranges',
              {
                deleted: false,
                section: section?.id,
                order: '-high_score',
              },
            )

            const formattedScoreRanges = score_ranges?.results?.map(
              (score) => ({
                ...score,
                result_type: score?.result_type_info,
              }),
            )
            return {
              ...section,
              score_ranges: formattedScoreRanges,
            }
          },
        )

        const formattedScoreList = await Promise.all(formattedScoreListPromises)

        setInitialValues({
          template_id: templateData.id,
          template_name: templateData?.name,
          email_text:
            templateData?.email_body_html || templateData?.email_body_txt,
          scoreList: formattedScoreList,
        })
      }
    }
    getInitialValues()
  }, [])

  useEffect(() => {
    const getResultTypes = async () => {
      const templateId = stateService?.params?.assessmentTemplateId

      const { data } = await Api.get('assessment/result_types', {
        template: templateId,
      })

      setResultTypesRefresh(false)
      setResultTypes(data?.results)
    }
    getResultTypes()
  }, [resultTypesRefresh])

  const scoreRangeSchema = yup.object().shape({
    low_score: yup.string().required(),
    high_score: yup.string().required(),
    result_type: yup.mixed().nullable(),
    equals: yup.string(),
    result_text: yup.string(),
    section: yup.number(),
  })

  const validationSchema = yup.object().shape({
    template_id: yup.number().required(),
    template_name: yup.string().required(),
    scoreList: yup.array().of(
      yup.object().shape({
        id: yup.number().required(),
        minimum_question: yup.string().required(),
        inconclusive_description: yup.string(),
        required: yup.boolean().required(),
        date_created: yup.date().required(),
        date_updated: yup.date().required(),
        template: yup.number().required(),
        parent_section: yup.mixed().nullable(),
        name: yup.string().required(),
        score_ranges: yup.array().of(scoreRangeSchema),
        email_text: yup.string(),
      }),
    ),
  })

  const checkIsEmailBodyValid = (html) => {
    const tempDiv = document.createElement('div')
    tempDiv.innerHTML = html

    const textContent = tempDiv.textContent || tempDiv.innerText || ''

    return textContent.trim().length !== 0 || html === null
  }

  const handleSubmit = async (values, setFieldValue) => {
    const { template_id, scoreList, email_text } = values

    try {
      await Api.patch(`assessment/templates/${template_id}/edit_email_text`, {
        email_body_html: checkIsEmailBodyValid(email_text) ? email_text : null,
      })

      const sectionPromises = scoreList?.map(async (section, sectionIndex) => {
        await Api.patch(`assessment/sections/${section?.id}`, {
          minimum_question: section?.minimum_question,
          inconclusive_description: section?.inconclusive_description,
          required: section?.required,
          template: template_id,
        })

        if (section?.score_ranges?.length) {
          try {
            await Api.post(
              'assessment/score_ranges/bulk_update_create',
              section?.score_ranges.map((range) => {
                return {
                  ...range,
                  result_type: range?.result_type?.id || null,
                }
              }),
            )
          } catch (error) {
            if (error.status === 400) {
              const { data } = error
              const { overlapping_ranges } = data

              section?.score_ranges.forEach((range, rangeIndex) => {
                overlapping_ranges?.forEach((overlap) => {
                  if (
                    +overlap?.low_score === +range?.low_score &&
                    +overlap?.high_score === +range?.high_score
                  ) {
                    setFieldValue(
                      `scoreList[${sectionIndex}].score_ranges[${rangeIndex}]`,
                      {
                        ...range,
                        range_error:
                          'Overlapping score ranges are not permitted',
                      },
                    )
                  }
                })
              })
            }

            const errorElement = document.querySelector('.error')

            if (errorElement) {
              const topOffset = 150
              const elementPosition =
                errorElement.getBoundingClientRect().top + window.pageYOffset
              const offsetPosition = elementPosition - topOffset

              window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
              })
            }

            throw error
          }
        }
      })

      await Promise.all(sectionPromises)
      stateService.go('assessment.assessment_manage')
    } catch (error) {
      Notification.clearNotification()
      console.error('An error occurred during submission:', error)
    }
  }

  const styles = getStyles(variableColor)

  return (
    <section css={styles}>
      <CommonPageHeader headerText={'Assessments'} />
      <AssessmentNavBar />
      {initialValues?.scoreList?.length && (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={() => {}}
        >
          {({ values, setFieldValue, isValid, touched }) => {
            const shouldDisableButton =
              !isValid || Object.keys(touched).length === 0
            return (
              <main className="assessment__score__wrapper">
                <div className="header__block">
                  <h4 className="page__title">
                    {values?.template_name} Score Key
                  </h4>
                  <ButtonElement
                    buttonType="submit"
                    text="Save"
                    disabled={shouldDisableButton}
                    functionToTrigger={() => {
                      handleSubmit(values, setFieldValue)
                    }}
                  />
                </div>
                <FieldArray
                  name="scoreList"
                  render={(arrayHelpers) => (
                    <div>
                      {values?.scoreList?.map((score, index) => (
                        <SingleScoring
                          score={score}
                          index={index}
                          key={index}
                          serverResultTypes={resultTypes}
                          setResultTypesRefresh={setResultTypesRefresh}
                        />
                      ))}
                    </div>
                  )}
                />
              </main>
            )
          }}
        </Formik>
      )}
    </section>
  )
}

const getStyles = (variableColor) =>
  css({
    paddingBottom: '200px',
    '.assessment__score__wrapper': {
      background: '#fff',
      paddingInline: '10px',
      paddingBottom: '10px',
      width: '685px',
      marginLeft: '5px',
    },
    '.header__block': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
    '.e-input:focus': {
      borderBottom: `1px solid ${variableColor} !important`,
    },
    '.e-check': {
      backgroundColor: `${variableColor} !important`,
    },
    '.e-insertLink': {
      color: `${variableColor} !important`,
    },
  })
