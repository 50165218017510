import React, { useEffect } from 'react'
import { useAngularServices } from '@/react/components'
import moment from 'moment'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { UISref } from '@/react/components/UISref'
import classNames from 'classnames'
import { useRouter } from '@/react/hooks'
import { DeletedRow } from './DeletedRow'

export const TableBody = ({
  handleChecked,
  selectedItems,
  deletedList,
  isAllChecked,
}) => {
  const { CurrentUser, Api } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const selectedIds = selectedItems.map((el) => el.id)
  const { stateService } = useRouter()

  return (
    <tbody>
      {!deletedList.length && (
        <tr css={{ height: '40px', width: '40px' }}>
          <td></td>
        </tr>
      )}
      {deletedList.map((item, index) => (
        <DeletedRow
          isAllChecked={isAllChecked}
          item={item}
          index={index}
          handleChecked={handleChecked}
          variableColor={variableColor}
          selectedIds={selectedIds}
        />
      ))}
    </tbody>
  )
}
