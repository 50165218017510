angular
  .module('ccs')
  .controller(
    'ToolboxAssignCtrl',
    function ($scope, $state, $stateParams, Api, app, $log) {
      $log.debug('ToolboxAssignCtrl')

      var projectId = $stateParams.project
      $scope.appProject = { toolbox_topics: [] }
      $scope.pageNumber = 1
      $scope.reverse = false
      $scope.order = 'name'
      $scope.toolboxTopicSets = []
      $scope.app = app

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.breadcrumbs = [
        { title: app.name },
        {
          title: app.toolboxes + ' by ' + app.project,
          url: 'app.toolbox.projects({app:' + app.id + '})',
        },
      ]

      function toolboxQuery() {
        let q = {
          page: $scope.pageNumber,
          application: app.id,
          search: $scope.search,
          is_active: 'True',
          annotate_for_project: projectId,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      function getToolboxTopicSets(pageNumber) {
        Api.ToolboxTopicSets.get(
          {
            application: app.id,
            page: pageNumber,
            search: $scope.search,
            order: $scope.reverse ? '-' + $scope.order : $scope.order,
          },
          (resp) => {
            const sets = resp.results.map((s) => {
              s.is_active = false
              if (s.app_projects.indexOf($scope.appProject.id) != -1) {
                s.is_active = true
              }
              return s
            })

            $scope.toolboxTopicSets = $scope.toolboxTopicSets.concat(sets)

            if (resp.next) getToolboxTopicSets(++pageNumber)
          },
        )
      }

      function getAppProjects() {
        Api.AppProjects.get(
          { app: app.id, project: projectId },
          function (resp) {
            if (!resp.results.length) {
              Api.AppProjects.post(
                { app: app.id, project: projectId },
                function (appProj) {
                  $scope.appProject = appProj
                  $scope.appProject.toolbox_topics = []
                  $scope.changePage()
                },
              )
            } else {
              $scope.appProject = resp.results[0]
              $scope.changePage()
            }
          },
        )
      }

      $scope.toggleSet = (set) => {
        if (set.is_active) {
          set.app_projects = set.app_projects.concat($scope.appProject.id)
        } else {
          set.app_projects = set.app_projects.filter(
            (app_project) => app_project != $scope.appProject.id,
          )
        }

        Api.ToolboxTopicSets.patch(
          {
            id: set.id,
            app_projects: set.app_projects,
          },
          (resp) => {
            $scope.toolboxTopicSets = []
            getAppProjects()
          },
        )
      }

      $scope.toggleAll = () => {
        Api.post(
          `app_projects/${$scope.appProject.id}/assign_all_toolbox_topics`,
          {
            id: $scope.appProject.id,
            on: $scope.active,
            search: $scope.search,
          },
          () => {
            $scope.toolboxTopicSets = []
            getAppProjects()
          },
        )
      }

      $scope.changePage = function () {
        Api.ToolboxTopics.get(toolboxQuery(), function (resp) {
          resp.results = resp.results.map(function (toolbox) {
            toolbox.active_for_project =
              !!$scope.appProject.toolbox_topics.filter(function (c) {
                return c == toolbox.id
              }).length

            return toolbox
          })

          $scope.data = {
            items: resp.results,
            total: resp.count,
          }
        })
        $scope.toolboxTopicSets = []
        getToolboxTopicSets(1)
      }

      $scope.toggleActive = function (toolbox) {
        if (toolbox.active_for_project) {
          $scope.appProject.toolbox_topics.push(toolbox.id)
        } else {
          $scope.appProject.toolbox_topics =
            $scope.appProject.toolbox_topics.filter(function (c) {
              return c != toolbox.id
            })
        }

        Api.AppProjects.patch(
          {
            id: $scope.appProject.id,
            toolbox_topics: $scope.appProject.toolbox_topics,
          },
          function (resp) {
            $scope.toolboxTopicSets = []
            getToolboxTopicSets(1)
            if (!resp) {
              toolbox.active_for_project = !toolbox.active_for_project
            }
          },
        )
      }

      //Restore pagination values.
      $scope.backToSearch = function () {
        $state.go('app.toolboxes.toolbox_to_project', {
          app: app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }

      getAppProjects()
    },
  )
