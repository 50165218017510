angular
  .module('ccs')
  .controller(
    'TrainingsAssignCtrl',
    function ($scope, Api, $state, $stateParams, $log) {
      $log.debug('TrainingsAssignCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.trainingsList = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name,first_name,last_name'
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.trainingID = $stateParams.training
      $scope.trainingSetID = $stateParams.trainingSet

      $scope.pageSize = 20

      function getTraineesListQuery() {
        const q = {
          page: $scope.pageNumber,
          search: $scope.search,
          training: $scope.trainingID,
          training_set: $scope.trainingSetID,
        }

        if ($scope.order) {
          q.order = $scope.reverse
            ? $scope.order
                .split(',')
                .map(function (a) {
                  return '-' + a
                })
                .join(',')
            : $scope.order
          if ($scope.order === 'is_active') q.is_active = !$scope.reverse
        }

        return q
      }

      function getTraineesList() {
        Api.get(
          `assign_training_set/user_set`,
          getTraineesListQuery(),
          (resp) => {
            $scope.totalItems = resp.count
            $scope.employees = resp
          },
        )
      }

      getTraineesList()

      // employees

      $scope.toggleAll = () => {
        Api.post(
          `assign_training/toggle_all_users/?deleted=False`,
          {
            active: $scope.active,
            training: $scope.trainingID ? $scope.trainingID : null,
            training_set: $scope.trainingSetID ? $scope.trainingSetID : null,
            search: $scope.search,
          },
          () => {
            getTraineesList()
          },
        )
      }

      $scope.changePage = function () {
        getTraineesList()
      }

      $scope.goBack = () => {
        $state.go('trainings.assign.employees_list', {
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }

      $scope.toggleActive = (employee) => {
        Api.JobAssign.post(
          {
            users: employee.users ? employee.users : [employee.id], //required
            active: employee.assigned, //required,
            trainings: $scope.trainingSetID
              ? $scope.employees.trainings
              : [parseInt($scope.trainingID)], //required
          },
          (resp) => {
            setTimeout(getTraineesList(), 100)
          },
        )
      }
    },
  )
