angular.module('ccs').directive('setFocus', setFocus)

function setFocus($timeout) {
  return {
    restrict: 'A',
    link: function (scope, el) {
      $timeout(function () {
        if (el[0].attributes['set-focus'].value == 'true') {
          el[0].focus()
        }
      }, 500)
    },
  }
}
