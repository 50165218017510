angular
  .module('ccs')
  .controller(
    'CategoryListCtrl',
    function ($scope, Api, app, $stateParams, $state, $log) {
      $log.debug('CategoryListCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20
      $scope.app = app

      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageNumber * $scope.pageSize }

      function query() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
          admin_is_active: 'True',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      $scope.tableHeaders = [
        { key: 'name', display: app.category + ' Name', sortable: true },
        { key: 'projects', display: app.project, centered: true },
      ]

      function getAppProjects(categories, cb, page, appProjects) {
        appProjects = appProjects || []
        page = page || 1

        Api.AppProjects.get(
          {
            categories: categories.map((c) => c.id).join(),
            page: page,
            page_size: 500,
            is_active: 'True',
            only_categories: true,
            app: app.id,
          },
          (resp) => {
            appProjects = appProjects.concat(resp.results)

            if (resp.next) {
              getAppProjects(categories, cb, ++page, appProjects)
            } else {
              cb(appProjects)
            }
          },
        )
      }

      function get() {
        $state.transitionTo(
          'app.categories.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          {
            notify: false,
          },
        )

        Api.Categories.get(query(), function (resp) {
          const categories = resp.results
          $scope.data.total = resp.count

          getAppProjects(categories, function (appProjects) {
            $scope.data.items = categories.map((category) => {
              category.projects = appProjects
                .filter((appProject) => {
                  return (
                    appProject.categories.indexOf(category.id) > -1 &&
                    appProject.project.is_active
                  )
                })
                .map((appProject) => appProject.project.id)
                .filter((value, index, self) => {
                  return self.indexOf(value) === index
                }).length

              return category
            })
          })
        })
      }

      $scope.changePage = get

      get()
    },
  )
