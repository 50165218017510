import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'ProcedureListCtrl',
    function (
      $scope,
      Api,
      app,
      $stateParams,
      $state,
      uiPermissionService,
      $log,
    ) {
      $log.debug('ProcedureListCtrl')

      $scope.titleProcedurePage = pluralize.singular(app.app_procedure_display)
      $scope.procedures = []
      //restore pagination values
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.isLoading = false

      //pageSize Used in the view
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.totalItems = $scope.totalItems =
        $scope.pageNumber * $scope.pageSize
      $scope.app = app
      $scope.addAllProceduresToNewProjects = null
      $scope.isOpenAssignAllBtn = false

      $scope.permissionCreateProcedure =
        uiPermissionService.getPermissionCreateProcedure()
      $scope.permissionDeleteProcedure =
        uiPermissionService.getPermissionDeleteProcedure()
      $scope.permissionEditProcedure =
        uiPermissionService.getPermissionEditProcedure()
      $scope.permissionToggleAllProjectsToAllProcedures =
        uiPermissionService.getPermissionToggleAllProjectsToAllProcedures()

      $scope.tableHeaders = [
        {
          key: 'name',
          display: $scope.titleProcedurePage + ' Name',
          sortable: true,
        },
        { key: 'view', display: 'Download', centered: true },
        {
          key: 'projects',
          display: app.projects,
          edit: true,
          centered: true,
          hidden: !$scope.permissionEditProcedure,
        },
        {
          key: 'delete',
          display: 'Delete',
          edit: true,
          centered: true,
          hidden: !$scope.permissionDeleteProcedure,
        },
      ]

      function query() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      var getProcedures = function getProcedures() {
        Api.Procedures.get(query(), function (resp) {
          var procedures = resp.results
          $scope.totalItems = resp.count

          Api.ClientProcedures.get(
            {
              procedures: procedures
                .map(function (p) {
                  return p.id
                })
                .join(),
            },
            function (resp) {
              $scope.addAllProceduresToNewProjects =
                resp.add_all_procedures_to_new_projects
              $scope.procedures = procedures.map(function (p) {
                p.clientProcedure = null

                resp.results.forEach(function (cp) {
                  if (cp.procedure.id == p.id) {
                    p.clientProcedure = cp
                  }
                })

                return p
              })
            },
          )
        })
      }

      getProcedures()

      $scope.changePage = function () {
        getProcedures()

        $state.transitionTo(
          'app.procedures.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.search,
          },
          { notify: false },
        )
      }

      $scope.$watch('addAllProceduresToNewProjects', (newVal) => {
        if ($scope.addAllProceduresToNewProjects == null) return
        if (newVal || !newVal) {
          getProcedures()
        }
      })

      $scope.assignAll = function () {
        Api.post(
          `client_procedures/assign_all_projects_to_all`,
          {
            on: true,
            app: app.id,
            search: $scope.search,
          },
          () => {
            getProcedures()
          },
        )
      }

      $scope.delete = (procedure) => {
        Api.Procedures.delete(procedure, (resp) => {
          getProcedures()
        })
      }
    },
  )
