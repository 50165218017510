const MAX_FILENAME_LENGTH = 64

export function validateFileName(fileName) {
  var hasIllegalSymbols = Boolean(
    fileName.match(new RegExp(/[\/\\|*:?<>"\%]/g)),
  )
  var isValidFilenameLength = fileName.length <= MAX_FILENAME_LENGTH

  var errors = []
  if (!isValidFilenameLength)
    errors.push('File name length should be less than 65 characters.')
  if (hasIllegalSymbols)
    errors.push('File name shouldn\'t contain / \\ | * : ? < > " % symbols')
  if (errors.length > 0) return errors
  else return false
}
