import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const CopyIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      onClick={onClick}
      css={{
        marginTop: '1px',
      }}
    >
      <path
        d="M11.05 13.6V0H-4.86374e-05V13.6H11.05ZM14.45 17V3.4H12.75V15.3H3.39995V17H14.45Z"
        fill={color}
        width="20"
        height="20"
      />
    </svg>
  )
}
