angular
  .module('ccs')
  .service('Notification', Notification)
  .service('NotificationReact', NotificationReact)

const TOAST_DELAY = 3000
const FILE_ERROR_TOAST_DELAY = 10000

function Notification($timeout) {
  let toastTimeout = null
  let fileErrorToastTimeout = null

  const service = {
    success, // three types of toasts ('danger', 'warning', 'success')
    danger,
    warning,
    fileError, // additional error toast. @TODO: rework the container so it would not cover other three
    error, // permanent error visible in shares and observations
    clearNotification,
    toast: null,
    fileErrorToast: null,
    permanentError: null,
  }

  function setToastTimeout(customDelay) {
    if (toastTimeout) {
      $timeout.cancel(toastTimeout)
    }
    toastTimeout = $timeout(() => {
      service.toast = null
      toastTimeout = null
    }, customDelay || TOAST_DELAY)
  }

  function setFileErrorToastTimeout() {
    if (fileErrorToastTimeout) {
      $timeout.cancel(fileErrorToastTimeout)
    }
    fileErrorToastTimeout = $timeout(() => {
      service.fileErrorToast = null
      fileErrorToastTimeout = null
    }, FILE_ERROR_TOAST_DELAY)
  }

  function success(message) {
    service.toast = { type: 'success', message }
    setToastTimeout()
  }

  function danger(message, delay) {
    service.toast = {
      type: 'danger',
      message: message.message ? message.message : message,
    }
    setToastTimeout(delay)
  }

  function warning(message) {
    service.toast = {
      type: 'warning',
      message: message.message ? message.message : message,
    }
    setToastTimeout()
  }

  function clearNotification() {
    service.toast = null
    service.fileErrorToast = null
  }

  function fileError(message) {
    service.fileErrorToast = { type: 'danger', message }
    setFileErrorToastTimeout()
  }

  function error(message) {
    service.permanentError = {
      message: message.message ? message.message : message,
    }
  }

  return service
}

function NotificationReact(Notification, $rootScope) {
  return {
    success: (msg) => {
      Notification.success(msg)
      $rootScope.$apply()
    },
    danger: (msg) => {
      Notification.danger(msg)
      $rootScope.$apply()
    },
    warning: (msg) => {
      Notification.warning(msg)
      $rootScope.$apply()
    },
    error: (msg) => {
      Notification.error(msg)
      $rootScope.$apply()
    },
    fileError: (msg) => {
      Notification.fileError(msg)
      $rootScope.$apply()
    },
    clearNotification: () => {
      Notification.clearNotification()
      $rootScope.$apply()
    },
  }
}
