import * as React from 'react'
import { ButtonElement, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { saveBase64AsFile } from '@/utils'

export const ClosingAndExportElement = ({
  payRollPeriod,
  setReload,
  count,
}) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const [payRoleApproved, setPayRoleApprove] = React.useState(false)
  const [payRoleClosed, setPayRoleClosed] = React.useState(false)
  const [disabledExportButton, setDisabledExportButton] = React.useState(false)

  let _isMounted = false

  React.useEffect(() => {
    // componentWillMount
    _isMounted = true
    // componentWillUnmount
    return () => {
      // Your code here
      _isMounted = false
    }
  }, [])

  async function fetchData() {
    const [{ data: isApproved }, { data: isClosed }] = await Promise.all([
      Api.get('timesheets/check_stages', {
        application: stateService.params.app,
        deleted: false,
        period: payRollPeriod,
        page_size: 1000,
        stages: 'a,c',
      }),
      Api.get('timesheets/check_stages', {
        application: stateService.params.app,
        deleted: false,
        period: payRollPeriod,
        page_size: 1000,
        stages: 'c',
      }),
    ])
    if (_isMounted) {
      setPayRoleApprove(isApproved.result)
      setPayRoleClosed(isClosed.result)
    }
  }

  async function closeAll() {
    await Api.postWithParams(
      'timesheets/close_all',
      {},
      {
        application: stateService.params.app,
        deleted: false,
        period: payRollPeriod,
      },
    )
    setReload(true)
    setPayRoleApprove(false)
    setPayRoleClosed(true)
  }

  async function downloadExel() {
    if (disabledExportButton) return
    setDisabledExportButton(true)
    const { data: fileData } = await Api.get('timesheets/generate_xls', {
      application: stateService.params.app,
      deleted: false,
      period: payRollPeriod,
    })
    setTimeout(async () => {
      if (fileData.file_id_pw) {
        try {
          const { data } = await Api.getFileDataForDownload(fileData.file_id_pw)
          saveBase64AsFile(data.content, data.filename, data.content_type)
        } catch (e) {
          console.log(e)
        }
      }
      if (fileData.file_id_npw) {
        setTimeout(async () => {
          try {
            const { data } = await Api.getFileDataForDownload(
              fileData.file_id_npw,
            )
            saveBase64AsFile(data.content, data.filename, data.content_type)
          } catch (e) {
            console.log(e)
          }
        }, 500)
      }
      setDisabledExportButton(false)
    }, fileData.time * 1000 + 1000)
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  if (count === 0) return <section />

  return (
    <section css={baseStyle}>
      {!payRoleApproved || payRoleClosed ? null : (
        <ButtonElement
          height={'30px'}
          fontSize={'12px'}
          width={'fit-content'}
          text={'Close All'}
          functionToTrigger={closeAll}
          buttonType={'submit'}
        />
      )}
      {!payRoleClosed ? null : (
        <ButtonElement
          height={'30px'}
          fontSize={'12px'}
          width={'fit-content'}
          text={'Export'}
          disabled={disabledExportButton}
          additionalStyles={css({
            paddingLeft: 12,
            paddingRight: 12,
          })}
          functionToTrigger={downloadExel}
        />
      )}
    </section>
  )
}

const baseStyle = css({
  width: 130,
  display: 'flex',
  justifyContent: 'space-between',
})
