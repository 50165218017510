import preview1 from '../../../assets/observation-preview/preview-1.png'
import preview2 from '../../../assets/observation-preview/preview-2.png'
import preview3 from '../../../assets/observation-preview/preview-3.png'
import preview4 from '../../../assets/observation-preview/preview-4.png'
import preview5 from '../../../assets/observation-preview/preview-5.png'

const preview_images = {
  1: preview1,
  2: preview2,
  3: preview3,
  4: preview4,
  5: preview5,
}

angular
  .module('ccs')
  .controller(
    'SettingsReportCtrl',
    function ($scope, Api, $q, CurrentUser, Notification, $log, $uibModal) {
      $log.debug('SettingsReportCtrl')

      $scope.loadImage = (template_id) => preview_images[template_id]

      $scope.wildcartText = ''

      Api.ReportSettings.get({}, function (resp) {
        if (resp.results.length) {
          $scope.settings = resp.results[0]
        } else {
          Api.ReportSettings.post(
            {
              client: CurrentUser.getClientId(),
            },
            function (resp) {
              $scope.settings = resp
            },
          )
        }
      })

      Api.Themes.get({}, function (resp) {
        if (resp.results.length) {
          $scope.themes = resp.results
        }
      })

      $scope.update = function () {
        Api.ReportSettings.patch($scope.settings, (resp) => {
          Notification.success('Settings updated')
        })
      }

      $scope.changeradio = function (theme) {
        $scope.settings.theme = theme.id
      }

      $scope.glyphClick = function () {
        $scope.modal_text =
          'Wildcard field is a customized field created by Administrators to capture additional data when completing a report'
        $scope.informational_only = true
        var modalInstance = $uibModal.open({
          templateUrl: 'app/views/common/flexible_modal.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.dismiss = function () {
              modalInstance.close()
            }
          },
        })
      }
    },
  )
