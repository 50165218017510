import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'BatchReportsListCtrl',
    function ($scope, Api, app, $stateParams, $state, $log) {
      $log.debug('BatchReportsListCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20

      $scope.appProjectDisplay = pluralize.singular(app.projects_display)

      $scope.app = app

      $scope.reverse = true
      $scope.order = 'date_created'

      $scope.tableHeaders = [
        {
          key: 'project_name',
          display: $scope.appProjectDisplay + ' Name',
          sortable: true,
        },
        { key: 'category_name', display: $scope.app.category, sortable: true },
        { key: 'author_name', display: 'Full Name', sortable: true },
        { key: 'company_name', display: 'Company', sortable: true },
        { key: 'date_created', display: 'Date Created', sortable: true },
        { key: 'view', display: 'View', centered: true },
        { key: 'email', display: 'Share', centered: true },
      ]

      function query() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          tz_offset: new Date().getTimezoneOffset(),
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return q
      }

      $scope.getSyncBatchReport = function (batchReport) {
        var answers_is_synchronised = true // true, by default
        var batch_report_is_synchronised = batchReport.synchronised

        return !!(batch_report_is_synchronised && answers_is_synchronised)
      }

      function getBatchReports() {
        Api.BatchReports.get(query(), function (response) {
          $scope.data = {
            items: response.results,
            total: response.count,
          }
        })
      }

      getBatchReports()

      $scope.changePage = function () {
        $state.transitionTo(
          'app.batch_reports.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
        getBatchReports()
      }
    },
  )
