import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  color?: string
  onClick?: () => void
  className?: string
}

export const PptIcon = ({ color, onClick = () => {}, className }: Props) => {
  return (
    <svg
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      css={[
        css({
          display: 'block',
          color: color,
          width: '16px',
          height: '16px',
        }),
      ]}
      onClick={onClick}
    >
      <path
        d="M4.8 12.8C4.36 12.8 3.9832 12.6432 3.6696 12.3296C3.356 12.016 3.19947 11.6395 3.2 11.2V1.6C3.2 1.16 3.3568 0.783201 3.6704 0.469601C3.984 0.156001 4.36053 -0.000531975 4.8 1.35823e-06H14.4C14.84 1.35823e-06 15.2168 0.156801 15.5304 0.470401C15.844 0.784001 16.0005 1.16053 16 1.6V11.2C16 11.64 15.8432 12.0168 15.5296 12.3304C15.216 12.644 14.8395 12.8005 14.4 12.8H4.8ZM1.6 16C1.16 16 0.783201 15.8432 0.469601 15.5296C0.156001 15.216 -0.000531975 14.8395 1.35823e-06 14.4V3.2H1.6V14.4H12.8V16H1.6Z"
        style={{ fill: color }}
      />
      <path
        d="M6.55195 4.43066C7.01257 4.43066 7.34867 4.52995 7.56025 4.72852C7.77184 4.92546 7.87764 5.19727 7.87764 5.54395C7.87764 5.7002 7.85404 5.84993 7.80684 5.99316C7.75964 6.13477 7.68232 6.2609 7.5749 6.37158C7.46911 6.48226 7.32751 6.57015 7.1501 6.63525C6.97269 6.69873 6.75378 6.73047 6.49336 6.73047H6.16865V8H5.41182V4.43066H6.55195ZM6.51289 5.05078H6.16865V6.11035H6.41768C6.55928 6.11035 6.68216 6.09163 6.78633 6.0542C6.89049 6.01676 6.97106 5.95817 7.02803 5.87842C7.08499 5.79867 7.11348 5.69613 7.11348 5.5708C7.11348 5.39502 7.06465 5.26481 6.96699 5.18018C6.86934 5.09391 6.71797 5.05078 6.51289 5.05078ZM9.6416 4.43066C10.1022 4.43066 10.4383 4.52995 10.6499 4.72852C10.8615 4.92546 10.9673 5.19727 10.9673 5.54395C10.9673 5.7002 10.9437 5.84993 10.8965 5.99316C10.8493 6.13477 10.772 6.2609 10.6646 6.37158C10.5588 6.48226 10.4172 6.57015 10.2397 6.63525C10.0623 6.69873 9.84342 6.73047 9.58301 6.73047H9.2583V8H8.50146V4.43066H9.6416ZM9.60254 5.05078H9.2583V6.11035H9.50732C9.64893 6.11035 9.77181 6.09163 9.87598 6.0542C9.98014 6.01676 10.0607 5.95817 10.1177 5.87842C10.1746 5.79867 10.2031 5.69613 10.2031 5.5708C10.2031 5.39502 10.1543 5.26481 10.0566 5.18018C9.95898 5.09391 9.80762 5.05078 9.60254 5.05078ZM12.9681 8H12.2112V5.06055H11.242V4.43066H13.9373V5.06055H12.9681V8Z"
        fill="white"
      />
    </svg>
  )
}
