import { css } from '@emotion/react'

export const Alert = ({ text, type }) => {
  const alertStyle = css([baseStyle, type === 'danger' && dangerStyle])

  return <div css={alertStyle}>{text}</div>
}

const baseStyle = css({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 9,
  width: '100%',
  borderRadius: 0,
  padding: '15px',
  border: 'none',
  boxShadow: '0 0 1px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.2)',
})

const dangerStyle = css({
  background: '#f2dede',
  borderColor: '#ebccd1',
  color: '#a94442',
})
