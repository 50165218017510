import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { UISref } from '@components/UISref'
import { useRouter, useSref } from '@/react/hooks'

// @ts-ignore
export function DemoComponent() {
  const [counter, setCounter] = React.useState<number>(0)
  const { Notification, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  React.useEffect(() => {
    Notification.error(
      'hello from angular Notification service ' + process.env.BUILD_ENV,
    )
  }, [])

  const user = CurrentUser.getInstance()

  const { href } = useSref('app.company_news.list', { app: 8 })

  return (
    <React.Fragment>
      <div css={styles}>
        <h2>Hello, React/AngularJS world</h2>
        <p>
          The example of working React hooks
          <button onClick={() => setCounter(counter + 1)}>+</button>
          {counter}
          <button onClick={() => setCounter(counter - 1)}>-</button>
        </p>
        <p>
          The current user is: {user.username}/{user.role}
        </p>
      </div>
      <div css={styles}>
        <UISref to="index.dashboard">
          <a>Go to home page via link</a>
        </UISref>
        <button onClick={() => stateService.go('index.dashboard')}>
          Go to Home page
        </button>
        <UISref to="lessons.list">
          <button>Go to Lessons page</button>
        </UISref>
        <a href={href}>App 8 Company news</a>
      </div>
    </React.Fragment>
  )
}

const styles = css({
  backgroundColor: 'white',
  padding: 30,
  ' button': {
    margin: 10,
  },
})
