import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useEffect, useRef } from 'react'

export const InfoModal = ({ setModalOpen }) => {
  const popupRef = useRef()

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setModalOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [setModalOpen])
  return (
    <div css={baseStyle} ref={popupRef}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={() => {
          setModalOpen(false)
        }}
      />
      <div className="popup__title">
        Wildcard field is a customized field created by Administrators to
        capture additional data when completing a report
      </div>
      <ButtonElement
        text="OK"
        buttonType="submit"
        additionalStyles={css({ marginBottom: '14px' })}
        functionToTrigger={() => {
          setModalOpen(false)
        }}
      />
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  background: '#FFF',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  zIndex: 200000,
  width: '265px',
  top: '-55px',
  right: '-160px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '.popup__cross': {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignSelf: 'flex-end',
    marginRight: '7px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  '.popup__title': {
    color: 'grey',
    textAlign: 'center',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    paddingTop: '8px',
    paddingInline: '15px',
    paddingBottom: '7px',
  },
})
