import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { Autocomplete, TextField } from '@mui/material'
import classNames from 'classnames'
import React, { useEffect, useMemo, useState } from 'react'
import Highlighter from 'react-highlight-words'
import { hexToRGBA } from '@/utils/generalUtilities.js'

type Props = {
  title: string
  options: any[]
  value: string
  setValue: React.Dispatch<React.SetStateAction<string>>
  disabled?: boolean
  resetFunction?: () => void
}

export const SelectWithSearch = ({
  title,
  options,
  value,
  setValue,
  disabled = false,
  resetFunction = () => {},
}: Props) => {
  const [inputValue, setInputValue] = useState('')
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const selectOptions = useMemo(() => {
    return options.map((option) => option?.name)
  }, [options])

  const renderOption = (props, option, { selected, index }) => {
    const selectedStyles = getSelectedStyles(variableColor, selected, index)
    return (
      <li
        {...props}
        css={selectedStyles}
        key={option?.name}
        style={{
          backgroundColor:
            index % 2 === 0
              ? selected
                ? variableColor
                : '#F8F8F8'
              : selected
              ? variableColor
              : '#fff',
        }}
      >
        <Highlighter
          highlightClassName={selected ? 'selected__item' : 'highlighted__item'}
          searchWords={[inputValue]}
          autoEscape={true}
          textToHighlight={option}
        />
      </li>
    )
  }
  return (
    <div className="single__select">
      <p className="select___title">{title}</p>
      <Autocomplete
        readOnly={disabled}
        value={value}
        sx={{
          width: '380px',
          background: disabled ? '#E6E6E6 !important' : 'rgb(250, 250, 250)',
          '@media (max-width: 500px)': {
            width: '267px',
          },
          '& .MuiAutocomplete-endAdornment': {
            top: '15px',
            right: '9px',
          },
          "& .MuiAutocomplete-popper .MuiAutocomplete-option[aria-selected='true']":
            {
              backgroundColor: '#FF8787',
            },
        }}
        style={{
          height: 32,
          border: '1px solid #E6E6E6',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          fontSize: '12px',
          background: '#FAFAFA',
        }}
        onChange={(event: any, newValue: string | null) => {
          resetFunction()
          setValue(newValue)
        }}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        id="controllable-states-demo"
        options={selectOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-input': {
                fontSize: '12px',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
            }}
            InputProps={{
              ...params.InputProps,
              placeholder: 'Select...',
            }}
          />
        )}
        renderOption={renderOption}
      />
    </div>
  )
}

function getSelectedStyles(
  variableColor: string,
  selected: boolean,
  index: number,
) {
  return css({
    maxHeight: '26px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    fontSize: '12px',
    color: '#676A6C',
    borderTop: index % 2 === 0 ? '1px solid #E5E6E7' : 'none',
    borderBottom: index % 2 === 0 ? '1px solid #E5E6E7' : 'none',
    ':hover': {
      backgroundColor: `${variableColor} !important`,
      color: '#fff',
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.selected__item': {
      backgroundColor: 'transparent',
      color: '#fff',
    },
  })
}
