angular
  .module('ccs')
  .controller(
    'TrainingGroupAssignCtrl',
    function ($scope, Api, $state, $stateParams, Notification, $log) {
      $log.debug('TrainingGroupAssignCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.trainings = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name'
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.userID = $stateParams.userID

      $scope.pageSize = 20

      function getTrainingsListQuery() {
        let q = {
          group: $stateParams.groupID,
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      function getTrainingGroupById() {
        Api.get(
          `training_sets/${$stateParams.groupID}`,
          {},
          (resp) => {
            $scope.trainingGroup = resp
          },
          (error) => {
            Notification.danger(
              JSON.stringify(error && error.detail ? error.detail : error),
            )
          },
        )
      }

      getTrainingGroupById()

      function getTrainingsList() {
        Api.TrainingsList.get(getTrainingsListQuery(), function (resp) {
          $scope.totalItems = resp.count
          $scope.trainings = resp.results
        })
      }

      getTrainingsList()

      $scope.toggleAll = () => {
        Api.post(
          `training_sets/${$stateParams.groupID}/assign_all_trainings/?deleted=False`,
          {
            active: $scope.active,
            search: $scope.search,
          },
          () => {
            getTrainingsList()
          },
        )
      }

      $scope.toggleActive = (training) => {
        Api.patch(`training_sets/${$stateParams.groupID}/?deleted=False`, {
          training: training.id,
          active: training.assigned_to_group,
        })
      }

      $scope.goBack = () => {
        $state.go('trainings.assign.trainings_list', {
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }

      $scope.changePage = function () {
        getTrainingsList()
      }
    },
  )
