import * as React from 'react'
import { css } from '@emotion/react'
import { PdfIcon } from '@/react/componentAssets'
import { saveBase64AsFile } from '@/utils'
import { useAngularServices } from '@/react/components'

export const DownloadElement = ({
  id,
  variableColor,
}: {
  id: number
  variableColor: string
}) => {
  const { Api } = useAngularServices()

  const [pdfGrayOut, setPdfGrayOut] = React.useState<boolean>(false)

  async function downloadPDF(id: number) {
    if (pdfGrayOut) return
    setPdfGrayOut(true)
    const { data: fileData } = await Api.get('tickets/' + id + '/generate_pdf')

    setTimeout(async () => {
      const { data } = await Api.getFileDataForDownload(fileData?.file_id)
      saveBase64AsFile(data?.content, data?.filename, data?.content_type)
      setPdfGrayOut(false)
    }, fileData?.time * 1000 + 1000)
  }

  return (
    <div
      css={css({
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
      })}
    >
      <PdfIcon
        height={'20px'}
        color={pdfGrayOut ? 'gray' : variableColor}
        onClick={() => {
          downloadPDF(id)
        }}
      />
    </div>
  )
}
