import * as React from 'react'
import { css } from '@emotion/react'

import { ImageWithPreview } from '../../components'

export function ImageSection({ images, theme }) {
  return (
    <div css={baseStyle}>
      {images.map((photo, idx) => {
        if (photo.deleted) return
        return (
          <ImageWithPreview
            key={idx}
            photo={photo}
            theme={theme}
            photoIdx={idx}
          />
        )
      })}
    </div>
  )
}

const baseStyle = css({
  width: '100%',
})
