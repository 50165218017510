import * as React from 'react'

type Props = {
  className: string
  width: string
  height: string
}

export const BulkUploadIcon = ({
  className,
  width = '142',
  height = '115',
}: Props) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 142 115"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <g id="Download">
      <path
        id="Vector"
        d="M100.422 55.147C100.11 54.833 99.7396 54.5838 99.3311 54.4137C98.9227 54.2436 98.4846 54.1561 98.0421 54.1561C97.5996 54.1561 97.1615 54.2436 96.753 54.4137C96.3446 54.5838 95.9738 54.833 95.662 55.147L74.3768 76.4052V3.38076C74.3768 2.48413 74.0206 1.62422 73.3866 0.990202C72.7525 0.356186 71.8926 0 70.996 0C70.0994 0 69.2394 0.356186 68.6054 0.990202C67.9714 1.62422 67.6152 2.48413 67.6152 3.38076V76.4052L46.33 55.147C45.6987 54.5158 44.8426 54.1611 43.9499 54.1611C43.0572 54.1611 42.2011 54.5158 41.5698 55.147C40.9386 55.7782 40.584 56.6343 40.584 57.527C40.584 58.4197 40.9386 59.2759 41.5698 59.9071L68.6159 86.9532C68.9258 87.2705 69.296 87.5226 69.7047 87.6948C70.1134 87.8669 70.5525 87.9556 70.996 87.9556C71.4395 87.9556 71.8785 87.8669 72.2873 87.6948C72.696 87.5226 73.0662 87.2705 73.376 86.9532L100.422 59.9071C100.736 59.5954 100.985 59.2246 101.155 58.8161C101.326 58.4076 101.413 57.9695 101.413 57.527C101.413 57.0846 101.326 56.6465 101.155 56.238C100.985 55.8295 100.736 55.4587 100.422 55.147Z"
        fill="#B3B3B3"
      />
      <path
        id="Vector_2"
        d="M116.974 115H25.0176C18.3847 114.993 12.0256 112.355 7.3354 107.664C2.64524 102.974 0.00715909 96.6151 0 89.9822V44.0038C0 43.1072 0.356186 42.2473 0.990202 41.6132C1.62422 40.9792 2.48413 40.623 3.38076 40.623C4.2774 40.623 5.13731 40.9792 5.77132 41.6132C6.40534 42.2473 6.76152 43.1072 6.76152 44.0038V89.9822C6.76868 94.8218 8.69438 99.4612 12.1165 102.883C15.5387 106.305 20.178 108.231 25.0176 108.238H116.974C121.814 108.231 126.453 106.305 129.875 102.883C133.298 99.4612 135.223 94.8218 135.23 89.9822V44.0038C135.23 43.1072 135.587 42.2473 136.221 41.6132C136.855 40.9792 137.715 40.623 138.611 40.623C139.508 40.623 140.368 40.9792 141.002 41.6132C141.636 42.2473 141.992 43.1072 141.992 44.0038V89.9822C141.985 96.6151 139.347 102.974 134.657 107.664C129.966 112.355 123.607 114.993 116.974 115Z"
        fill="#B3B3B3"
      />
    </g>
  </svg>
)
