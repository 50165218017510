import React, { useState } from 'react'
import { ButtonElement } from '@/react/components'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { css } from '@emotion/react'
import { useEffect, useRef } from 'react'
import { Field, useFormikContext } from 'formik'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { TextInputStyle } from '@screens/components'

export const AddNoteModal = ({
  handleClick = () => {},
  handleClose,
  name,
  noteValue,
}) => {
  const [value, setValue] = useState(noteValue)
  const [isButtonDisabled, setButtonDisabled] = useState(true)
  const modalRef = useRef()
  const { values, setFieldValue } = useFormikContext()

  const handleOutsideClick = (e) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      handleClose()
    }
  }

  const handleSaveClick = () => {
    setFieldValue(name, value)
    handleClose()
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => (document.body.style.overflow = 'auto')
  }, [])

  return (
    <div
      css={baseStyle}
      className="modal__wrapper"
      ref={modalRef}
      onMouseOver={(e) => {
        e.stopPropagation()
      }}
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <img
        src={closeIcon}
        alt="cross-icon"
        className="close__icon"
        onClick={handleClose}
      />
      <div className="modal__info">
        <p className="modal__message">
          <strong>Note</strong>
        </p>
        <div className="note__field">
          <Field
            as={FormikAutoExpandedTextArea}
            maxLength={255}
            className="note__textarea"
            value={value}
            onChange={(e) => {
              setValue(e.target.value)
              setButtonDisabled(false)
            }}
          />
          <div className="symbols-remains">{255 - (value?.length || 0)}</div>
        </div>
        <ButtonElement
          text="Save"
          buttonType="submit"
          functionToTrigger={handleSaveClick}
          disabled={isButtonDisabled}
          additionalStyles={css({
            ':disabled': {
              background: '#888888 !important',
              color: '#fff !important',
            },
          })}
        />
      </div>
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  backgroundColor: 'white',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  right: 0,
  zIndex: 1,
  top: '-50px',
  '.close__icon': {
    position: 'absolute',
    right: '4px',
    top: '5px',
    width: '13px',
    height: '13px',
    cursor: 'pointer',
  },
  '.modal__message': {
    color: '#676A6C',
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    margin: 0,
    textAlign: 'center',
  },
  '.message__name': {
    width: '146px',
    maxHeight: '36px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    margin: 0,
  },
  '.delete__title': {
    marginBottom: '14px',
  },
  '.delete__subtitle': {
    color: '#666',
    textAlign: 'center',
    fontSsize: '14px',
    margin: 0,
    marginBottom: '25px',
    width: '206px',
  },
  '.message__email': {
    margin: 0,
    fontWeight: 600,
  },
  '.modal__info': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '17px',
  },
  '.note__field': {
    display: 'flex',
    gap: '3px',
    flexDirection: 'column',
    paddingTop: '12px',
  },
  '.note__textarea': {
    ...TextInputStyle,
    minHeight: '55px',
    fontSize: '12px',
  },
  '.symbols-remains': {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '11px',
    color: '#B1B1B1',
  },
})
