import * as React from 'react'
import { css } from '@emotion/react'
import { CommonPageHeader, useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { ReloadableElements } from './components'
import { UISref } from '@components/UISref'

export const VoidedTimesheetPage = () => {
  const { $rootScope, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const role = CurrentUser.getRole()

  if (role !== 'client_admin') {
    stateService.go('index.dashboard', {})
    return
  }

  const timeCardCheck = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].has_timecard

  if (!timeCardCheck) {
    stateService.go('index.dashboard', {})
    return null
  }

  return (
    <section>
      <CommonPageHeader headerText={'Voided Timesheets'} />
      <div
        css={css({
          display: 'flex',
          width: '100%',
          maxWidth: '100%',
          backgroundColor: 'white',
          paddingLeft: 25,
          marginBottom: 1,
          marginTop: '2vw',
          paddingTop: 14,
          paddingBottom: 14,
          h3: {
            margin: 0,
          },
        })}
      >
        <h3>Voided Timesheets</h3>
      </div>
      <div
        className="back-row"
        css={css({
          display: 'flex',
          width: '100%',
          maxWidth: '100%',
          backgroundColor: 'white',
          paddingLeft: 25,
          paddingTop: 8,
        })}
      >
        <UISref
          to={'app.timecard.timesheet'}
          params={{ app: stateService.params.app }}
        >
          <h3
            css={css({
              color: variableColor,
              cursor: 'pointer',
              '.fa-chevron-left': {
                fontSize: 20,
                marginRight: 10,
              },
            })}
          >
            <span css={css({ div: { fontSize: '14px !important' } })}>
              <div className="fa fa-chevron-left" />
            </span>
            Back to Timesheets
          </h3>
        </UISref>
      </div>
      <ReloadableElements voidPage={true} />
      <div css={css({ height: 100 })} />
    </section>
  )
}
