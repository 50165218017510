import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
  direction: string
}

export const MinimalisticArrows = ({
  height = '20px',
  width,
  color = 'inherit',
  onClick = () => {},
  additionalStyles,
  direction = 'r',
}: Props) => {
  if (direction === 'r') {
    return (
      <svg
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={[
          css({ height: height, width: width ? width : 'auto', color: color }),
          additionalStyles,
        ]}
        onClick={onClick}
      >
        <path
          d="M0.381998 16C0.306666 16.0001 0.232981 15.9779 0.170243 15.9362C0.107504 15.8945 0.0585224 15.8352 0.0294789 15.7657C0.000435385 15.6961 -0.00736893 15.6196 0.0070515 15.5457C0.0214719 15.4717 0.0574704 15.4037 0.110505 15.3502L7.46828 7.98327L0.110505 0.63465C0.0457644 0.561823 0.0113621 0.466975 0.0143605 0.369578C0.0173589 0.272181 0.0575309 0.179628 0.126628 0.110921C0.195726 0.0422138 0.288505 0.00256688 0.385917 0.00012033C0.483329 -0.00232622 0.577982 0.0326132 0.650441 0.0977649L8.27666 7.72398C8.31217 7.7594 8.34034 7.80148 8.35957 7.84781C8.37879 7.89413 8.38868 7.9438 8.38868 7.99395C8.38868 8.04411 8.37879 8.09377 8.35957 8.1401C8.34034 8.18642 8.31217 8.2285 8.27666 8.26392L0.650441 15.8901C0.61543 15.9256 0.573595 15.9536 0.527458 15.9725C0.481322 15.9914 0.43184 16.0007 0.381998 16Z"
          fill="currentcolor"
        />
      </svg>
    )
  }
  if (direction === 'l') {
    return (
      <svg
        width="9"
        height="16"
        viewBox="0 0 9 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={[
          css({ height: height, width: width ? width : 'auto', color: color }),
          additionalStyles,
        ]}
        onClick={onClick}
      >
        <path
          d="M7.99931 16C7.9493 16.0004 7.89974 15.9906 7.85364 15.9712C7.80754 15.9518 7.76586 15.9232 7.73114 15.8872L0.112755 8.26885C0.0768996 8.23403 0.0484338 8.19233 0.0290607 8.14625C0.00968762 8.10018 -0.000195114 8.05067 2.91825e-06 8.00068C0.000157157 7.89891 0.0407121 7.80136 0.112755 7.72947L7.73114 0.111078C7.80227 0.0399561 7.89873 -1.98272e-09 7.99931 0C8.09989 1.98272e-09 8.19636 0.0399561 8.26748 0.111078C8.3386 0.182201 8.37856 0.278664 8.37856 0.379246C8.37856 0.479828 8.3386 0.576291 8.26748 0.647413L0.91421 8.00982L8.26748 15.3509C8.30286 15.386 8.33094 15.4278 8.3501 15.4738C8.36926 15.5199 8.37913 15.5692 8.37913 15.6191C8.37913 15.6689 8.36926 15.7183 8.3501 15.7643C8.33094 15.8103 8.30286 15.8521 8.26748 15.8872C8.23276 15.9232 8.19109 15.9518 8.14498 15.9712C8.09888 15.9906 8.04932 16.0004 7.99931 16Z"
          fill="currentcolor"
        />
      </svg>
    )
  }
  return null
}
