import { unescape } from 'lodash'

export function getTranslatedString(string, optionalObj) {
  const locationSeparate = window.location.pathname.split('/')
  if (
    !locationSeparate.includes('assigned') &&
    !locationSeparate.includes('share')
  ) {
    return unescape(string)
  }

  const languageData = optionalObj
    ? optionalObj
    : JSON.parse(localStorage.getItem('language-data'))
  if (!languageData[languageData.selected]) {
    return unescape(string)
  } else {
    if (!languageData[languageData.selected][string]) {
      return unescape(string)
    } else {
      return unescape(languageData[languageData.selected][string])
    }
  }
}

export const languageByKeys = {
  af: {
    value: 'af',
    label: 'Afrikaans',
  },
  sq: {
    value: 'sq',
    label: 'Albanian',
  },
  am: {
    value: 'am',
    label: 'Amharic',
  },
  ar: {
    value: 'ar',
    label: 'Arabic',
  },
  hy: {
    value: 'hy',
    label: 'Armenian',
  },
  as: {
    value: 'as',
    label: 'Assamese',
  },
  ay: {
    value: 'ay',
    label: 'Aymara',
  },
  az: {
    value: 'az',
    label: 'Azerbaijani',
  },
  bm: {
    value: 'bm',
    label: 'Bambara',
  },
  eu: {
    value: 'eu',
    label: 'Basque',
  },
  be: {
    value: 'be',
    label: 'Belarusian',
  },
  bn: {
    value: 'bn',
    label: 'Bengali',
  },
  bho: {
    value: 'bho',
    label: 'Bhojpuri',
  },
  bs: {
    value: 'bs',
    label: 'Bosnian',
  },
  bg: {
    value: 'bg',
    label: 'Bulgarian',
  },
  ca: {
    value: 'ca',
    label: 'Catalan',
  },
  ceb: {
    value: 'ceb',
    label: 'Cebuano',
  },
  'zh-CN': {
    value: 'zh-CN',
    label: 'Chinese(Simplified)',
  },
  'zh-TW': {
    value: 'zh-TW',
    label: 'Chinese(Traditional)',
  },
  co: {
    value: 'co',
    label: 'Corsican',
  },
  hr: {
    value: 'hr',
    label: 'Croatian',
  },
  cs: {
    value: 'cs',
    label: 'Czech',
  },
  da: {
    value: 'da',
    label: 'Danish',
  },
  dv: {
    value: 'dv',
    label: 'Dhivehi',
  },
  doi: {
    value: 'doi',
    label: 'Dogri',
  },
  nl: {
    value: 'nl',
    label: 'Dutch',
  },
  en: {
    value: 'en',
    label: 'English',
  },
  eo: {
    value: 'eo',
    label: 'Esperanto',
  },
  et: {
    value: 'et',
    label: 'Estonian',
  },
  ee: {
    value: 'ee',
    label: 'Ewe',
  },
  fil: {
    value: 'fil',
    label: 'Filipino(Tagalog)',
  },
  fi: {
    value: 'fi',
    label: 'Finnish',
  },
  fr: {
    value: 'fr',
    label: 'French',
  },
  fy: {
    value: 'fy',
    label: 'Frisian',
  },
  gl: {
    value: 'gl',
    label: 'Galician',
  },
  ka: {
    value: 'ka',
    label: 'Georgian',
  },
  de: {
    value: 'de',
    label: 'German',
  },
  el: {
    value: 'el',
    label: 'Greek',
  },
  gn: {
    value: 'gn',
    label: 'Guarani',
  },
  gu: {
    value: 'gu',
    label: 'Gujarati',
  },
  ht: {
    value: 'ht',
    label: 'HaitianCreole',
  },
  ha: {
    value: 'ha',
    label: 'Hausa',
  },
  haw: {
    value: 'haw',
    label: 'Hawaiian',
  },
  he: {
    value: 'he',
    label: 'Hebrew',
  },
  hi: {
    value: 'hi',
    label: 'Hindi',
  },
  hmn: {
    value: 'hmn',
    label: 'Hmong',
  },
  hu: {
    value: 'hu',
    label: 'Hungarian',
  },
  is: {
    value: 'is',
    label: 'Icelandic',
  },
  ig: {
    value: 'ig',
    label: 'Igbo',
  },
  ilo: {
    value: 'ilo',
    label: 'Ilocano',
  },
  id: {
    value: 'id',
    label: 'Indonesian',
  },
  ga: {
    value: 'ga',
    label: 'Irish',
  },
  it: {
    value: 'it',
    label: 'Italian',
  },
  ja: {
    value: 'ja',
    label: 'Japanese',
  },
  jv: {
    value: 'jv',
    label: 'Javanese',
  },
  kn: {
    value: 'kn',
    label: 'Kannada',
  },
  kk: {
    value: 'kk',
    label: 'Kazakh',
  },
  km: {
    value: 'km',
    label: 'Khmer',
  },
  rw: {
    value: 'rw',
    label: 'Kinyarwanda',
  },
  gom: {
    value: 'gom',
    label: 'Konkani',
  },
  ko: {
    value: 'ko',
    label: 'Korean',
  },
  kri: {
    value: 'kri',
    label: 'Krio',
  },
  ku: {
    value: 'ku',
    label: 'Kurdish',
  },
  ckb: {
    value: 'ckb',
    label: 'Kurdish(Sorani)',
  },
  ky: {
    value: 'ky',
    label: 'Kyrgyz',
  },
  lo: {
    value: 'lo',
    label: 'Lao',
  },
  la: {
    value: 'la',
    label: 'Latin',
  },
  lv: {
    value: 'lv',
    label: 'Latvian',
  },
  ln: {
    value: 'ln',
    label: 'Lingala',
  },
  lt: {
    value: 'lt',
    label: 'Lithuanian',
  },
  lg: {
    value: 'lg',
    label: 'Luganda',
  },
  lb: {
    value: 'lb',
    label: 'Luxembourgish',
  },
  mk: {
    value: 'mk',
    label: 'Macedonian',
  },
  mai: {
    value: 'mai',
    label: 'Maithili',
  },
  mg: {
    value: 'mg',
    label: 'Malagasy',
  },
  ms: {
    value: 'ms',
    label: 'Malay',
  },
  ml: {
    value: 'ml',
    label: 'Malayalam',
  },
  mt: {
    value: 'mt',
    label: 'Maltese',
  },
  mi: {
    value: 'mi',
    label: 'Maori',
  },
  mr: {
    value: 'mr',
    label: 'Marathi',
  },
  'mni-Mtei': {
    value: 'mni-Mtei',
    label: 'Meiteilon(Manipuri)',
  },
  lus: {
    value: 'lus',
    label: 'Mizo',
  },
  mn: {
    value: 'mn',
    label: 'Mongolian',
  },
  my: {
    value: 'my',
    label: 'Myanmar(Burmese)',
  },
  ne: {
    value: 'ne',
    label: 'Nepali',
  },
  no: {
    value: 'no',
    label: 'Norwegian',
  },
  ny: {
    value: 'ny',
    label: 'Nyanja(Chichewa)',
  },
  or: {
    value: 'or',
    label: 'Odia(Oriya)',
  },
  om: {
    value: 'om',
    label: 'Oromo',
  },
  ps: {
    value: 'ps',
    label: 'Pashto',
  },
  fa: {
    value: 'fa',
    label: 'Persian',
  },
  pl: {
    value: 'pl',
    label: 'Polish',
  },
  pt: {
    value: 'pt',
    label: 'Portuguese',
  },
  pa: {
    value: 'pa',
    label: 'Punjabi',
  },
  qu: {
    value: 'qu',
    label: 'Quechua',
  },
  ro: {
    value: 'ro',
    label: 'Romanian',
  },
  ru: {
    value: 'ru',
    label: 'Russian',
  },
  sm: {
    value: 'sm',
    label: 'Samoan',
  },
  sa: {
    value: 'sa',
    label: 'Sanskrit',
  },
  gd: {
    value: 'gd',
    label: 'ScotsGaelic',
  },
  nso: {
    value: 'nso',
    label: 'Sepedi',
  },
  sr: {
    value: 'sr',
    label: 'Serbian',
  },
  st: {
    value: 'st',
    label: 'Sesotho',
  },
  sn: {
    value: 'sn',
    label: 'Shona',
  },
  sd: {
    value: 'sd',
    label: 'Sindhi',
  },
  si: {
    value: 'si',
    label: 'Sinhala(Sinhalese)',
  },
  sk: {
    value: 'sk',
    label: 'Slovak',
  },
  sl: {
    value: 'sl',
    label: 'Slovenian',
  },
  so: {
    value: 'so',
    label: 'Somali',
  },
  es: {
    value: 'es',
    label: 'Spanish',
  },
  su: {
    value: 'su',
    label: 'Sundanese',
  },
  sw: {
    value: 'sw',
    label: 'Swahili',
  },
  sv: {
    value: 'sv',
    label: 'Swedish',
  },
  tl: {
    value: 'tl',
    label: 'Tagalog(Filipino)',
  },
  tg: {
    value: 'tg',
    label: 'Tajik',
  },
  ta: {
    value: 'ta',
    label: 'Tamil',
  },
  tt: {
    value: 'tt',
    label: 'Tatar',
  },
  te: {
    value: 'te',
    label: 'Telugu',
  },
  th: {
    value: 'th',
    label: 'Thai',
  },
  ti: {
    value: 'ti',
    label: 'Tigrinya',
  },
  ts: {
    value: 'ts',
    label: 'Tsonga',
  },
  tr: {
    value: 'tr',
    label: 'Turkish',
  },
  tk: {
    value: 'tk',
    label: 'Turkmen',
  },
  ak: {
    value: 'ak',
    label: 'Twi(Akan)',
  },
  uk: {
    value: 'uk',
    label: 'Ukrainian',
  },
  ur: {
    value: 'ur',
    label: 'Urdu',
  },
  ug: {
    value: 'ug',
    label: 'Uyghur',
  },
  uz: {
    value: 'uz',
    label: 'Uzbek',
  },
  vi: {
    value: 'vi',
    label: 'Vietnamese',
  },
  cy: {
    value: 'cy',
    label: 'Welsh',
  },
  xh: {
    value: 'xh',
    label: 'Xhosa',
  },
  yi: {
    value: 'yi',
    label: 'Yiddish',
  },
  yo: {
    value: 'yo',
    label: 'Yoruba',
  },
  zu: {
    value: 'zu',
    label: 'Zulu',
  },
}
