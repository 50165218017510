export function parseDate(date: string, time: boolean = true): string {
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
  }
  const dateObj = new Date(date).toLocaleDateString('en-US', options)
  if (time)
    return dateObj.substring(0, 10) + ' - ' + dateObj.substr(dateObj.length - 8)
  return dateObj.substring(0, 10)
}

export const convertTime12toSeconds = (
  initialHours,
  minutes,
  modifier,
  trueSeconds,
) => {
  let hours = Number(initialHours)
  if (hours === 12) {
    hours = 0
  }
  if (modifier === 'PM') {
    hours = parseInt(hours, 10) + 12
  }
  if (trueSeconds) return (hours * 60 + Number(minutes)) * 60000
  return new Date((hours * 60 + Number(minutes)) * 60000)
}
