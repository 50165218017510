import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'

type Props = {
  currentStage: string
  openChoice?: boolean
  rowData: object
  reloadCloseAll: any
}

export const StageSegment = ({
  rowData,
  reloadCloseAll,
  openChoice,
  currentDisplayedStage,
  setCurrentDisplayedStage,
}: Props) => {
  const { Api } = useAngularServices()
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [dropdownVisibility, setDropdownVisibility] = React.useState(false)

  async function changeTimesheetStage(stageValue) {
    await Api.patch('timesheets/' + rowData.id, {
      id: rowData.id,
      application: rowData.application,
      client: rowData.client,
      stage: getStageFromStageValue(stageValue),
      supervisor: rowData.supervisor.id,
      user: rowData.user.id,
    })
    getStageFromStageValue(stageValue)
    setCurrentDisplayedStage(stageValue)
    setDropdownVisibility(false)
    reloadCloseAll(true)
  }

  const baseStyle = css({
    height: 30,
    lineHeight: '27px',
    '.relative-box': {
      position: 'relative',
      width: '100%',
      '.stage-dropdown': {
        borderRadius: 5,
        boxShadow: '0 0 1px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 20%)',
        position: 'absolute',
        left: 0,
        top: 0,
        width: '100%',
        backgroundColor: 'white',
        zIndex: 1000,
        '.single-choice': {
          borderRadius: 5,
          paddingLeft: 10,
          width: '100%',
          height: 30,
          ':hover': {
            color: 'white',
            backgroundColor: variableColor,
          },
        },
      },
    },
    '.background': {
      position: 'fixed',
      width: '100%',
      height: '100%',
      right: 0,
      top: 0,
      zIndex: 500,
    },
    '.current-stage-display': {
      color:
        openChoice && currentDisplayedStage !== 'Approved'
          ? variableColor
          : '#676a6c',
    },
  })

  return (
    <div css={baseStyle}>
      {!dropdownVisibility || currentDisplayedStage === 'Approved' ? null : (
        <div
          className="background"
          onClick={() => {
            setDropdownVisibility(false)
          }}
        />
      )}

      <div className="relative-box">
        <div
          className="current-stage-display"
          onClick={() => {
            if (openChoice) setDropdownVisibility(true)
          }}
        >
          {currentDisplayedStage}
        </div>
        {!dropdownVisibility || currentDisplayedStage === 'Approved' ? null : (
          <div className="stage-dropdown">
            <div
              className="single-choice"
              onClick={() => {
                changeTimesheetStage('Approved')
              }}
            >
              Approved
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function getStageFromStageValue(stageValue) {
  switch (stageValue) {
    case 'Open':
      return 'o'
    case 'Submitted':
      return 's'
    case 'Approved':
      return 'a'
    case 'Closed':
      return 'c'
  }
}
// ("o", "Open"),
//   ("s", "Submitted"),
//   ("a", "Approved"),
//   ("c", "Closed"),
