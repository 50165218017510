import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'ToolboxesDeletedCtrl',
    function (
      $scope,
      Api,
      app,
      $stateParams,
      $state,
      ngDialog,
      CurrentUser,
      $log,
    ) {
      $log.debug('ToolboxesDeletedCtrl')

      $scope.titleSingularToolbox = app.toolbox_talks_display
      $scope.titleSingularDoubleToolbox = pluralize.singular(
        app.toolbox_talks_display,
      )
      $scope.titleSingularProject = pluralize.singular(app.project)
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20
      $scope.deletedObservationsCount = 0
      $scope.userIs = CurrentUser.is

      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageNumber * $scope.pageSize }
      $scope.breadcrumbs = [{ title: app.toolboxes }, { title: app.name }]
      $scope.app = app

      function query() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          deleted: 'True',
          role: true,
          table: true,
          tz_offset: new Date().getTimezoneOffset(),
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      $scope.tableHeaders = [
        {
          key: 'topic__name',
          display: $scope.titleSingularDoubleToolbox + ' Name',
          sortable: true,
        },
        { key: 'user__last_name', display: 'Full Name', sortable: true },
        { key: 'user__company_name', display: 'Company', sortable: true },
        {
          key: 'project__name',
          display: $scope.titleSingularProject,
          sortable: true,
        },
        { key: 'date_created', display: 'Date Created', sortable: true },
        { key: 'view', display: 'View', centered: true },
        { key: 'restore', display: 'Restore', centered: true },
      ]

      function get() {
        Api.get(`toolbox_talks/display_list`, query(), (resp) => {
          $scope.data.items = resp.results
          $scope.data.total = resp.count
        })

        $state.transitionTo(
          'app.toolboxes.deleted',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      $scope.changePage = get

      get()

      $scope.restore = function (id) {
        ngDialog.open({
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          template: 'app/views/restore_dialog.html',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.save = function () {
              Api.ToolboxTalks.patch(
                { id: id, deleted: false },
                function (resp) {
                  if (resp) {
                    get()
                    $scope.closeThisDialog()
                  }
                },
              )
            }

            $scope.cancel = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.goToToolboxesPage = function () {
        $state.go(
          'app.toolboxes.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { reload: 'app.toolboxes.deleted' },
        )
      }
    },
  )
