import { makeLoadResolver, resolvers } from '../resolvers'

/** @ngInject */
export function lessonsConfig($stateProvider) {
  $stateProvider
    .state('lessons', {
      abstract: true,
      templateUrl: 'app/views/common/content.html',
      data: { pageTitle: 'Lessons Learned' },
      resolve: {
        ...resolvers.appsResolver,
        ...makeLoadResolver('showLessons'),
      },
    })
    .state('lessons.list', {
      url: '/lessons?pageNumber&order&reverse&search',
      templateUrl: 'app/views/lesson_list.html',
      controller: 'LessonListCtrl',
      resolve: makeLoadResolver('showLessons'),
    })
    .state('lessons.edit', {
      url: '/lessons/:lessonID?previousPageNumber&previousOrder&previousReverse&previousSearch',
      templateUrl: 'app/views/lesson_form.html',
      controller: 'LessonFormCtrl',
      resolve: {
        ...resolvers.writeOnlyViewResolver,
        ...makeLoadResolver('showLessons'),
      },
    })
    .state('lessons.add', {
      url: '/add_lesson',
      templateUrl: 'app/views/lesson_form.html',
      controller: 'LessonFormCtrl',
      resolve: {
        ...resolvers.writeOnlyViewResolver,
        ...makeLoadResolver('showLessons'),
      },
    })
}
