import { EditIcon, DownloadIcon, CheckIcon } from '@/react/componentAssets'
import { css } from '@emotion/react'
import { BinDeleteIcon } from '@/react/componentAssets'
import { useAngularServices } from '@/react/components'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useState } from 'react'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import classNames from 'classnames'
import Highlighter from 'react-highlight-words'
import { useRouter } from '@/react/hooks'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { UISref } from '@/react/components/UISref'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const ManageRow = ({ template, index, setRefresh, search }) => {
  const { Api, CurrentUser } = useAngularServices()
  const clientId = CurrentUser.getClientId()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isLinkCopied, setLinkCopied] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  const [hoveredField, setHoveredField] = useState(null)

  const { stateService } = useRouter()

  const { name, is_active, id, client_object_key } = template

  const handleCopyLink = () => {
    const currentURL = window.location.href
    const urlObject = new URL(currentURL)
    const host = urlObject.host

    navigator.clipboard.writeText(
      `${host}/external_assessment/${client_object_key}/`,
    )

    setLinkCopied(true)
  }

  const handleDownloadClick = async () => {
    handleCopyLink()
    try {
      const { data: file } = await Api.get(
        `assessment/templates/${id}/qr_code`,
        {},
      )

      const blob = new Blob([file], { type: 'application/pdf' })

      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')

      link.href = url
      link.download = `${name} QR Code.pdf`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } catch (error) {
      console.error('Error downloading PDF:', error)
    }
  }

  const handleActivation = async () => {
    if (!is_active) {
      await Api.put(`assessment/templates/${id}/activate/`, {})
    } else {
      await Api.put(`assessment/templates/${id}/deactivate/`, {})
    }

    setRefresh(true)
  }

  const handleDelete = async () => {
    await Api.delete(`assessment/templates/${id}`, {})
    setRefresh(true)
    setDeleteModalOpen(false)
  }

  const isSafari = checkIsSafari()

  const baseStyle = getBaseStyle(variableColor, index, isSafari)

  return (
    <tr css={baseStyle} className="manage__row">
      <td
        className="template__td"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'template',
            text: name,
            max__length: 79,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <UISref
          to="assessment.assessment_edit"
          params={{
            assessmentTemplateId: id,
          }}
        >
          <Highlighter
            highlightClassName="highlighted__text"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={name}
          />
        </UISref>
      </td>
      <td className="icon__td">
        <EditIcon
          color={variableColor}
          onClick={() => {
            stateService.go('assessment.assessment_score_key', {
              clientId: clientId,
              assessmentTemplateId: id,
            })
          }}
        />
      </td>
      <td className="checkmark__td">
        <label className="checkbox-container">
          <input
            type="checkbox"
            checked={is_active}
            onChange={handleActivation}
          />
          <span className="checkmark">
            <ThinCheckmark color={variableColor} />
          </span>
        </label>
      </td>
      <td
        className="icon__td download__td"
        onClick={handleDownloadClick}
        onMouseOut={() => {
          setLinkCopied(false)
        }}
      >
        <DownloadIcon color={variableColor} height="22px" />
        {isLinkCopied && (
          <div className="sucess__copy">
            <CheckIcon width="11" height="8" color="green" />
            <p className="copy__message">URL copied to clipboard</p>
          </div>
        )}
      </td>
      <td className="icon__td">
        <BinDeleteIcon
          color={variableColor}
          onClick={() => {
            setDeleteModalOpen(true)
          }}
        />
        {isDeleteModalOpen && (
          <ConfirmationModal
            action={'Delete'}
            title={name}
            handleClose={() => {
              setDeleteModalOpen(false)
            }}
            handleClick={handleDelete}
          />
        )}
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}

const getBaseStyle = (variableColor, index, isSafari) =>
  css({
    height: '40px',
    borderBottom: '1px solid #E7EAEC',
    backgroundColor: index % 2 === 0 ? '#F2F3F5' : '#fff',
    '.template__td': {
      position: 'relative',
      cursor: 'pointer',
      color: variableColor,
      paddingLeft: '14px',
      margin: '0px',
      maxHeight: '40px',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      paddingRight: '10xp',
      width: '480px',
      maxWidth: '500px',
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.checkmark__td': {
      display: 'flex',
      justifyContent: 'center',
      height: '29px',
      alignItems: 'center',
    },
    '.icon__td': {
      textAlign: 'center',
      position: 'relative',
    },
    input: {
      left: '0 !important',
    },
    '.checkbox-container': {
      width: '22px',
      paddingLeft: '10px',
      marginBottom: 0,
    },
    svg: {
      cursor: 'pointer',
    },
    '.download__td': {
      paddingTop: '5px',
      position: 'relative',
    },
    '.sucess__copy': {
      display: 'flex',
      gap: '4px',
      background: ' #FFF',
      paddingInline: '9px',
      paddingTop: '5px',
      paddingBottom: '5px',
      width: '153px',
      position: 'absolute',
      zIndex: 1,
      top: 0,
      right: '47px',
      boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.25)',
    },
    '.copy__message': {
      fontSize: '10px',
      margin: 0,
      fontWeight: 600,
      color: '#68696D',
    },
    '.highlighted__text': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      marginTop: '30px',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '500px',
    },
    '@media(max-width: 1120px)': {
      '.template__td': {
        width: '350px',
      },
    },
  })
