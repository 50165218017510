import * as React from 'react'
import moment from 'moment'

export const SingleDay = ({
  day,
  selectNumber,
  callback,
  hoverColor,
  selectionWidth,
}) => {
  const active =
    selectionWidth !== 'day'
      ? selectNumber === day.week
      : selectNumber === moment(day.date).format('MM/DD/YYYY')

  return (
    <div
      className={
        'day calendar-cell week-' +
        day.week +
        ' current-m-' +
        day.currentMonth +
        (active ? ' activeDay' : '')
      }
      onMouseOver={() => {
        if (!active && selectionWidth === 'week') {
          ;[...document.getElementsByClassName('week-' + day.week)].forEach(
            (element) => {
              element.style.backgroundColor = hoverColor
            },
          )
        }
      }}
      onMouseOut={() => {
        if (!active && selectionWidth === 'week') {
          ;[...document.getElementsByClassName('week-' + day.week)].forEach(
            (element) => {
              element.style.backgroundColor = 'transparent'
            },
          )
        }
      }}
      onClick={() => {
        if (selectionWidth === 'week') {
          callback(day.week)
        }
        if (selectionWidth === 'day') {
          callback(day.date)
        }
      }}
    >
      {day.date.date()}
    </div>
  )
}
