import * as React from 'react'
import { css } from '@emotion/react'

const BUTTONS_VISIBILITY_RANGE = 5

type Props = {
  currentPage: number
  listCount: number
  pageLength: number
  setActivePage: (pageNumber: number) => void
}
export function PageSwitcher({
  currentPage,
  listCount,
  pageLength,
  setActivePage,
}: Props) {
  const nextPage = currentPage + 1
  const beforePage = currentPage - 1
  const pageCount = Math.ceil(listCount / (pageLength || 20))
  const pageArray = Array.from({ length: pageCount }, (_, i) => i + 1)

  let numberButtons

  if (pageArray.length > 0) {
    numberButtons = pageArray.map((number) => {
      if (
        number === 1 ||
        number === pageArray.length ||
        (number > currentPage - BUTTONS_VISIBILITY_RANGE &&
          number < currentPage + BUTTONS_VISIBILITY_RANGE)
      ) {
        return (
          <div
            key={number}
            className={'button' + (currentPage === number ? ' active' : '')}
            css={[buttonNumber]}
            onClick={() => {
              setActivePage(number)
            }}
          >
            {number}
          </div>
        )
      } else if (
        number === currentPage - (BUTTONS_VISIBILITY_RANGE + 1) ||
        number === currentPage + (BUTTONS_VISIBILITY_RANGE + 1)
      ) {
        return (
          <div
            key={number}
            className="button"
            css={buttonNumber}
            onClick={() => {
              setActivePage(number)
            }}
          >
            &hellip;
          </div>
        )
      }
    })
  }

  return (
    <div css={base}>
      <div
        className="arrow left button"
        css={buttonBefore}
        onClick={() => {
          if (currentPage > 1) {
            setActivePage(beforePage)
          }
        }}
      >
        Previous
      </div>
      {numberButtons}
      <div
        className="arrow right button"
        css={buttonNext}
        onClick={() => {
          if (currentPage < pageCount) {
            setActivePage(nextPage)
          }
        }}
      >
        Next
      </div>
    </div>
  )
}

const base = css({
  marginTop: '20px',
  marginBottom: '20px',
  display: 'flex',
  '.button': {
    cursor: 'pointer',
    paddingLeft: '6px',
    paddingRight: '6px',
    textAlign: 'center',
    color: '#777777',
    backgroundColor: '#fff',
    borderColor: '#ddd',
    borderStyle: 'solid',
    borderWidth: '1px',
    margin: 0,
  },
  '.active': {
    backgroundColor: '#ddd',
  },
})

const buttonNext = css({
  borderBottomRightRadius: '4px',
  borderTopRightRadius: '4px',
  borderBottomLeftRadius: 0,
  borderTopLeftRadius: 0,
})
const buttonBefore = css({
  borderBottomLeftRadius: '4px',
  borderTopLeftRadius: '4px',
  borderBottomRightRadius: 0,
  borderTopRightRadius: 0,
})
const buttonNumber = css({
  borderRadius: 0,
  borderRightWidth: 0,
  borderLeftWidth: 0,
})
