import { ExportAssessmentForm } from './components/ExternalAssessmentForm'
import { Formik, FormikValues } from 'formik'
import { css } from '@emotion/react'
import * as Yup from 'yup'
import { useAngularServices } from '@/react/components'
import React, { useEffect, useState } from 'react'
import { useRouter } from '@/react/hooks'
import {
  AssessmentQuestion,
  FieldGet,
  SingleSection,
  Template,
  ExternalAssessmentType,
  ExternalAssessmentContactType,
  ExternalAssessmentAnswersType,
  ExternalAssessmentAnswerType,
} from '@screens/AssessmentsConfig/CreateAssessment/components/types'
import { DataWrap } from '@/react/types'
import { Memory } from './components/types'
import { cloneDeep, find } from 'lodash'

export const ExternalAssessment = () => {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const { stateService } = useRouter()

  const [initialValues, setInitialValues] = useState<AssessmentQuestion[]>([])
  const [saveLock, setSaveLock] = useState<boolean>(false)
  const [templateKey, setTemplateKey] = useState<number | string>(
    stateService?.params?.client_object_key,
  )
  const [memory, setMemory] = useState<FormikValues & Memory>({})
  const [assesmentData, setAssesmentData] =
    useState<ExternalAssessmentType | null>(null)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const validationSchema = Yup.object().shape({
    first_name: Yup.string().required(),
    last_name: Yup.string().required(),
    company_name: Yup.string().required(),
    email: Yup.string().required(),
  })

  useEffect(() => {
    let localTemplateKey: string = stateService?.params?.client_object_key
    const assesmentKey: string = stateService?.params?.client_object_key

    const getInitialValues = async () => {
      try {
        const {
          data: assessmentBody,
        }: DataWrap<ExternalAssessmentType | null> = !stateService?.params
          ?.draft
          ? { data: null }
          : await Api.get(`assessment/external_assessments/${assesmentKey}`)

        setAssesmentData(assessmentBody)

        if (assessmentBody) {
          localTemplateKey = assessmentBody.template
          setTemplateKey(assessmentBody.template)
        }

        const { data: template }: { data: Template } = await Api.get(
          `assessment/external_templates/${localTemplateKey}`,
          {},
        )

        CurrentUser.setClientSettings(template.client_settings.general_settings)

        let fields: FieldGet[] = []
        const localMemory: Memory = {
          logo_url: template.client_settings.company.logo_url,
          company: template.client_settings.company.name,
          assessment_title: template?.name,
          first_name: '',
          last_name: '',
          company_name: '',
          email: '',
          answers: {},
        }
        const fieldsById: { [key: string | number]: AssessmentQuestion } = {}

        const { data: sections }: DataWrap<SingleSection[]> = await Api.get(
          `assessment/external_templates/${localTemplateKey}/sections`,
          {
            order: 'order',
          },
        )

        for (const section of sections) {
          if (section.parent_section) {
            fields.push({
              id: section.id,
              name: section.name,
              order: section.order,
              required: section.required,
              date_created: section.date_created,
              date_updated: section.date_updated,
              section: section.parent_section,
              note: '',
              options: null,
              nested_option: null,
              type:
                template.default_section.id === section.parent_section
                  ? 'header'
                  : 'subheader',
            })
          }
          const { data: sectionFields }: DataWrap<FieldGet[]> = await Api.get(
            `assessment/external_templates/${localTemplateKey}/fields/${section?.id}`,
            {
              order: 'order',
            },
          )
          fields = [...fields, ...sectionFields]
        }

        const makeFormattedOptions = (question: FieldGet) => {
          return question?.options?.map((op) => {
            const nestedFields = fields?.filter(
              (q) => q?.nested_option === op?.id,
            )

            return {
              ...op,
              nested_fields: nestedFields.map((field) => ({
                ...field,
                belongs_to_option: op.order,
                type: field?.type === 'text' ? 'text' : 'options',
              })),
            }
          })
        }

        const formattedQuestions: Array<AssessmentQuestion> = fields
          .map((question) => {
            const returnQuestion: AssessmentQuestion = {
              order: question?.order,
              id: question?.id,
              nested_option: question?.nested_option,
              question_text: question?.name,
              note: question?.note || '',
              required: question?.required,
              options: question.options,
              nested_options:
                question.type === 'nested_option'
                  ? makeFormattedOptions(question)
                  : undefined,
              type: question.type,
              closed: true,
            }

            fieldsById[question.id] = returnQuestion

            localMemory.answers[question.id] = {
              id: question.id,
              text: '',
              notAnswered: false,
              option: question.required
                ? null
                : { value: '', label: 'N/A', question: returnQuestion },
              type: question.type,
            }

            return returnQuestion
          })
          .filter((question) => !question?.nested_option)

        if (stateService?.params?.draft && assessmentBody) {
          const PAGE_SIZE = 20

          let externalAnswers: Array<ExternalAssessmentAnswerType> = []

          const [
            { data: externalClient },
            { data: firstPageExternalAnswers },
          ]: [
            DataWrap<ExternalAssessmentContactType>,
            DataWrap<ExternalAssessmentAnswersType>,
          ] = await Promise.all([
            Api.get(
              `assessment/external_templates/get_external_contact/${assessmentBody.external_contact}`,
            ),
            Api.get(`assessment/external_assessment_fields`, {
              assessment: assesmentKey,
              page_size: PAGE_SIZE,
            }),
          ])

          externalAnswers = firstPageExternalAnswers.results

          if (
            firstPageExternalAnswers.count /
              firstPageExternalAnswers.results.length >
            1
          ) {
            const pages = Math.ceil(
              firstPageExternalAnswers.count /
                firstPageExternalAnswers.results.length,
            )
            for (let i = 0; i < pages - 1; i++) {
              const {
                data: externalAnswersPages,
              }: DataWrap<ExternalAssessmentAnswersType> = await Api.get(
                `assessment/external_assessment_fields`,
                {
                  assessment: assesmentKey,
                  page_size: PAGE_SIZE,
                  page: i + 2,
                },
              )
              externalAnswers = externalAnswers.concat(
                externalAnswersPages.results,
              )
            }
          }

          localMemory.first_name = externalClient.first_name
          localMemory.last_name = externalClient.last_name
          localMemory.email = externalClient.email
          localMemory.company_name = externalClient.company_name

          externalAnswers.forEach((answer) => {
            localMemory.answers[answer.field].notAnswered = answer.is_na
            localMemory.answers[answer.field].text = answer.answer
            localMemory.answers[answer.field].answerId = answer.id
            if (fieldsById[answer.field].options) {
              localMemory.answers[answer.field].option = fieldsById[
                answer.field
              ].options
                .filter((option) => option.id === answer.field_option)
                .map((option) => {
                  const initialQuestionLink = cloneDeep(
                    fieldsById[answer.field],
                  )
                  const answerQuestionLink = initialQuestionLink.nested_options
                    ? find(initialQuestionLink.nested_options, (obj) => {
                        return obj.id === answer.field_option
                      })
                    : fieldsById[answer.field]
                  return {
                    value: option.id,
                    label: option.name,
                    question: answerQuestionLink,
                  }
                })[0]
            }
          })
        }

        setMemory(localMemory)
        setInitialValues(formattedQuestions)
      } catch (errorData) {
        console.log(errorData.data)

        if (errorData.data) {
          Notification.clearNotification()
          console.error(errorData.data.error)
          CurrentUser.setClientSettings(
            errorData.data.client_settings.general_settings,
          )
          setErrorMessage(errorData.data.error_message)
          setMemory({
            logo_url: errorData.data.client_settings.company.logo_url,
          })
        } else {
          console.error(errorData)
        }
      }
    }
    getInitialValues()
  }, [])

  const handleSubmitAnswers = async (
    answers: Memory,
    questions: AssessmentQuestion[],
    isDraft: boolean,
  ) => {
    setSaveLock(true)

    const now = new Date()

    const isCurrentDraft = stateService?.params?.draft

    try {
      // external_contact
      const { data: externalContact }: DataWrap<{ id: string | number }> =
        await Api.post(
          `assessment/external_templates/${templateKey}/create_external_contact`,
          {
            first_name: answers?.first_name,
            last_name: answers?.last_name,
            company_name: answers?.company_name,
            email: answers?.email,
          },
        )

      // create assessment
      const {
        data: externalAssessment,
      }: DataWrap<{
        client_object_key: string
        default_assessment_section: { id: string | number }
      }> = isCurrentDraft
        ? { data: assesmentData }
        : await Api.post('assessment/external_assessments', {
            template: templateKey,
            is_draft: true,
            external_contact: externalContact.id,
            date_completed: !isDraft ? now : null,
          })

      let lastSection = externalAssessment.default_assessment_section.id

      const sendField = async (question: AssessmentQuestion) => {
        isCurrentDraft
          ? await Api.patchWithParams(
              `assessment/external_assessment_fields/${
                answers.answers[question?.id].answerId
              }/`,
              {
                answer: answers.answers[question?.id].text,
                field_option: answers.answers[question?.id].option?.value,
                is_na: answers.answers[question?.id].notAnswered,
              },
              {
                assessment: stateService?.params?.client_object_key,
              },
            )
          : await Api.post('assessment/external_assessment_fields', {
              answer: answers.answers[question?.id].text,
              assessment_section: lastSection,
              field: question?.id,
              field_option: answers.answers[question?.id].option?.value,
              is_na: answers.answers[question?.id].notAnswered,
            })
      }

      for (const question of questions) {
        if (question.type === 'header') {
          const {
            data: externalSection,
          }: DataWrap<{ id: string | number }> | DataWrap<null> = isCurrentDraft
            ? { data: null }
            : await Api.post('assessment/external_assessment_sections', {
                assessment: externalAssessment.client_object_key,
                section: question.id,
              })
          if (externalSection) lastSection = externalSection.id
        } else {
          await sendField(question)
        }
        if (question.type === 'nested_option' && question.nested_options) {
          for (const option of question.nested_options) {
            for (const nestedQuestion of option.nested_fields) {
              await sendField(nestedQuestion)
            }
          }
        }
      }

      // external assessment activate
      await Api.patch(
        `assessment/external_assessments/${externalAssessment.client_object_key}`,
        {
          template: templateKey,
          is_draft: isDraft,
          external_contact: externalContact.id,
          date_completed: isDraft ? null : now,
        },
      )
      if (!isDraft) {
        await Api.patch(
          `assessment/external_assessments/${externalAssessment.client_object_key}/activate`,
          {
            template: templateKey,
            external_contact: externalContact?.id,
            date_completed: !isDraft ? now : null,
          },
        )
      }
      if (isDraft) {
        stateService.go('external_assessment', {
          client_object_key: externalAssessment.client_object_key,
          draft: true,
        })
      } else {
        stateService.go('assessment_final_view', {
          client_object_key: externalAssessment.client_object_key,
        })
      }
      setSaveLock(false)
    } catch (e) {
      console.error(e)
    } finally {
      setSaveLock(false)
    }
  }

  if (errorMessage)
    return (
      <div className="external__wrapper" css={baseStyle}>
        <div className="logo__wrapper">
          <img className="logo" src={memory.logo_url} alt={memory.company} />
        </div>
        <section className="form__wrapper">
          <div className="error__wrapper">
            <div className="error__text">{errorMessage}</div>
          </div>
        </section>
      </div>
    )

  return (
    <div className="external__wrapper" css={baseStyle}>
      <div className="logo__wrapper">
        <img className="logo" src={memory.logo_url} alt={memory.company} />
      </div>
      {Object.keys(initialValues).length && (
        <Formik
          initialValues={memory}
          onSubmit={handleSubmitAnswers}
          validationSchema={validationSchema}
        >
          {() => (
            <ExportAssessmentForm
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              handleSubmitAnswers={handleSubmitAnswers}
              saveLock={saveLock}
            />
          )}
        </Formik>
      )}
    </div>
  )
}

const baseStyle = css({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  paddingBottom: '150px',
  '.logo__wrapper': {
    maxWidth: 300,
    maxHeight: 150,
    marginTop: '38px',
    marginBottom: '50px',
    color: 'white',
  },
  '.logo': {
    maxWidth: 300,
    maxHeight: 150,
  },
  '.form__wrapper': {
    width: '926px',
    background: '#fff',
    borderRadius: '30px',
    paddingBottom: '30px',
    '.error__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      width: '100%',
      marginTop: 30,
    },
    '.error__text': {
      textAlign: 'center',
    },
  },
  '@media(max-width:926px)': {
    '.form__wrapper': {
      width: '100%',
      paddingInline: '30px',
    },
  },
})
