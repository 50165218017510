angular.module('ccs').directive('currencyformatter', currencyFormatter)

function currencyFormatter($filter) {
  return {
    restrict: 'EA',
    require: 'ngModel',
    link: function (scope, element, attr, ngModel) {
      scope.convertValue = function (data) {
        let currencyData
        if (!data || data === '$0.0') {
          currencyData = ''
        } else {
          currencyData = $filter('currency')(
            data.replace(/[^0-9]/g, '') / 100,
            '$',
            2,
          )
        }
        if (currencyData !== data) {
          ngModel.$setViewValue(currencyData) // view value is updated
          ngModel.$render() // view is updated
        }
        return currencyData
      }

      ngModel.$parsers.push((modelData) => scope.convertValue(modelData))
      ngModel.$formatters.push((modelData) => scope.convertValue(modelData))
    },
  }
}
