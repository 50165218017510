import { _extends } from '@/utils'

angular
  .module('ccs')
  .controller(
    'ProcessFlowAssignCtrl',
    function (
      $scope,
      $state,
      $stateParams,
      Api,
      app,
      uiPermissionService,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('ProcessFlowAssignCtrl')

      $scope.titleProccessFlowAssign = app.app_process_flow_display

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = $scope.search ? true : false

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse == 'true'
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.prevousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.processFlow = null
      $scope.projects = []
      $scope.pageSize = 20
      $scope.totalItems = $scope.pageNumber * $scope.pageSize
      $scope.app = app
      $scope.permissionToToggleAllProcessFlow =
        uiPermissionService.getPermissionToToggleAllProcessFlow()

      var processFlowId = $stateParams.processFlow

      $scope.tableHeaders = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'active_for_process_flow',
          display: 'Inactive/Active',
          sortable: true,
          toggleAllBtn: true
        },
      ]

      var getProjects = function getProjects() {
        var query = {
          client: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_for_process_flow: processFlowId,
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        if ($scope.q) query.name = $scope.q

        Api.Projects.get(query, function (resp) {
          $scope.totalItems = resp.count
          $scope.projects = resp.results.map(function (project) {
            return _extends({}, project, {
              active_for_process_flow:
                $scope.processFlow.clientProcessFlow.projects.indexOf(
                  project.id,
                ) > -1,
            })
          })
        })
      }

      $scope.changePage = function () {
        getProjects()
        $state.transitionTo(
          'app.process_flows.assign',
          {
            previousPageNumber: $scope.previousPageNumber,
            previousSearch: $scope.previousSearch,
            app: app.id,
            pageNumber: $scope.pageNumber,
            processFlow: processFlowId,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      let on = true

      function getAllProjects(cb, projects, page) {
        projects = projects || []
        page = page || 1

        Api.Projects.get(
          {
            client: CurrentUser.getClientId(),
            is_active: 'True',
            page,
          },
          (resp) => {
            projects = projects.concat(resp.results)
            if (resp.next) return getAllProjects(cb, projects, ++page)
            cb(projects)
          },
        )
      }

      function updateClientProcessFlow(cb) {
        Api.ClientProcessFlows.patch(
          {
            ...$scope.processFlow.clientProcessFlow,
            process_flow: $scope.processFlow.id,
            search: $scope.searchExecuted ? $scope.search : null,
            project: null, // project toggling -> null, when "Toggle All"
          },
          (resp) => {
            let interval = setInterval(function () {
              if (resp) {
                cb()
                clearInterval(interval)
              }
            }, 100)
            setTimeout(function () {
              clearInterval(interval)
            }, 300000)
          },
          (error) => {
            Notification.danger(JSON.stringify(error))
          },
        )
        $scope.getProcessFlowsByID()
      }

      function createClientProcessFlow(cb) {
        Api.ClientProcessFlows.post(
          {
            ...$scope.processFlow.clientProcessFlow,
            process_flow: $scope.processFlow.id,
            search: $scope.searchExecuted ? $scope.search : null,
            project: null, // project toggling -> null, when "Toggle All"
          },
          (resp) => {
            let interval = setInterval(function () {
              if (resp) {
                cb()
                clearInterval(interval)
              }
            }, 100)
            setTimeout(function () {
              clearInterval(interval)
            }, 300000)
          },
          (error) => {
            Notification.danger(JSON.stringify(error))
          },
        )
        $scope.getProcessFlowsByID()
      }

      function saveClientProcessFlow(cb) {
        if ($scope.processFlow.clientProcessFlow.id) {
          updateClientProcessFlow(cb)
        } else {
          createClientProcessFlow(cb)
        }
      }

      $scope.toggleAll = () => {
        if (on) {
          getAllProjects(function (projects) {
            $scope.processFlow.clientProcessFlow.projects = projects.map(
              (p) => p.id,
            )
            saveClientProcessFlow(getProjects)
          })
        } else {
          $scope.processFlow.clientProcessFlow.projects = []
          saveClientProcessFlow(getProjects)
        }

        on = !on
      }

      $scope.toggleActive = function (project) {
        if (!project) {
          return
        }

        if (!$scope.processFlow.clientProcessFlow.id) {
          Api.ClientProcessFlows.post(
            _extends({}, $scope.processFlow.clientProcessFlow, {
              process_flow: processFlowId,
              projects: [project.id],
              project: project.id, // project toggling
            }),
            function (resp) {
              return ($scope.processFlow.clientProcessFlow = resp)
            },
            function (error) {
              Notification.danger(JSON.stringify(error))
            },
          )
        } else {
          if (project.active_for_process_flow) {
            $scope.processFlow.clientProcessFlow.projects.push(project.id)
          } else {
            $scope.processFlow.clientProcessFlow.projects =
              $scope.processFlow.clientProcessFlow.projects.filter(function (
                p,
              ) {
                return p != project.id
              })
          }
          Api.ClientProcessFlows.patch(
            {
              ...$scope.processFlow.clientProcessFlow,
              process_flow: $scope.processFlow.id,
              project: project.id, // project toggling
            },
            function (resp) {},
            function (error) {
              Notification.danger(JSON.stringify(error))
            },
          )
        }
      }

      $scope.getProcessFlowsByID = function () {
        Api.ProcessFlows.byID(processFlowId, function (resp) {
          var processFlow = resp
          Api.ClientProcessFlows.get(
            { process_flows: processFlowId },
            function (resp) {
              if (resp.results.length) {
                $scope.processFlow = _extends({}, processFlow, {
                  clientProcessFlow: resp.results[0],
                })
              } else {
                $scope.processFlow = _extends({}, processFlow, {
                  clientProcessFlow: {
                    projects: [],
                    client: CurrentUser.getClientId(),
                  },
                })
              }
              getProjects()
            },
          )
        })
      }

      $scope.getProcessFlowsByID()

      $scope.goBack = function () {
        $state.go(
          'app.process_flows.list',
          {
            app: app.id,
            pageNumber: $scope.previousPageNumber,
            reverse: $scope.previousReverse,
            order: $scope.previousOrder,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }
    },
  )
