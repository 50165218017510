import { flatMap } from 'lodash'
import { HorizontalNavigation } from '@screens/components/types'

import addIcon from '/src/assets/icons/sds-connected-icons/add-sircle-icon.svg'
import viewIcon from '/src/assets/icons/sds-connected-icons/view-small.svg'
import archiveIcon from '/src/assets/icons/sds-connected-icons/archive-small.svg'

export function getSDSNavigationSettings(
  activePage: any,
  app: string,
): HorizontalNavigation[] {
  const SDSNavigation: {
    safety_data_sheets_list: HorizontalNavigation
    safety_data_sheets_create: HorizontalNavigation
    safety_data_sheets_archive: HorizontalNavigation
  } = {
    safety_data_sheets_list: {
      text: 'View',
      redirectTo: 'app.safety_data_sheets_list',
      redirectParams: { app: app },
      isActive: false,
      hasIcon: true,
      icon: viewIcon,
      onlyAdminView: false,
    },
    safety_data_sheets_create: {
      text: 'Add',
      redirectTo: 'app.safety_data_sheets_create',
      redirectParams: { app: app },
      isActive: false,
      hasIcon: true,
      icon: addIcon,
      onlyAdminView: true,
    },
    safety_data_sheets_archive: {
      text: 'Archive',
      redirectTo: 'app.safety_data_sheets_archive',
      redirectParams: { app: app },
      isActive: false,
      hasIcon: true,
      icon: archiveIcon,
      onlyAdminView: true,
    },
  }
  SDSNavigation[activePage].isActive = true

  return flatMap(SDSNavigation)
}
