import * as React from 'react'
import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { SectionHeader } from '@screens/Timecard/DailyReports/components/SectionHeader'
import { DocumentStyles } from '@screens/components'
import { useRouter } from '@/react/hooks'
import { UISref } from '@components/UISref'
import { useState } from 'react'
import { printPageWithFirefoxWarning } from '@/utils/printConnected'

export const TimeAndMaterialView = () => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [hoursList, setHoursList] = React.useState<[]>([])
  const [termsList, setTermsList] = React.useState<[]>([])
  const [ticket, setTicket] = React.useState({})
  const [gotAllCheck, setGotAllCheck] = React.useState(false)
  const [popUp, setPopUp] = useState<Element | null>(null)

  async function fetchData() {
    const [{ data: Hours }, { data: Ticket }, { data: Term }] =
      await Promise.all([
        Api.get('tm_hours', { ticket: stateService.params.key }),
        Api.get('tickets/' + stateService.params.key, {}),
        Api.get('tm_terms', {}),
      ])
    setHoursList(Hours.results)
    setTicket(Ticket)
    setTermsList(Term.results)
    setGotAllCheck(true)
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  if (!gotAllCheck) return null

  const generalSettings = CurrentUser.getClientSettings()

  const documentStyle = getDocumentStyle()
  return (
    <section css={css({ paddingBottom: 100 })}>
      <CommonPageHeader headerText={'T&M View'} />
      <section css={documentStyle}>
        <div className="container">
          <div className="icon">
            <img src={CurrentUser?.getClientCompany()?.logo_url} />
          </div>
          <h2 className="report-title">T&M TICKET</h2>
          <SectionHeader
            title={generalSettings.project_language + ' Information'}
          />
          <div className="job-info-wrapper">
            <div className="col">
              <div className="info-row">
                <div className="field">
                  {generalSettings.project_language} ID:
                </div>
                <div className="value">{ticket?.project?.number}</div>
              </div>
              <div className="info-row">
                <div className="field">
                  {generalSettings.project_language} Name:
                </div>
                <div className="value">{ticket?.project?.name}</div>
              </div>
              <div className="info-row">
                <div className="field">Address:</div>
                <div className="value">
                  {ticket?.project?.address}
                  {ticket?.project?.city ? ', ' : ''}
                  {ticket?.project?.city}
                  {ticket?.project?.state || ticket?.project?.zip_code
                    ? ', '
                    : ''}
                  {ticket?.project?.state}
                  {ticket?.project?.street ? ', ' : ''}
                  {ticket?.project?.street}
                  {ticket?.project?.zip_code ? ', ' : ''}
                  {ticket?.project?.zip_code}
                </div>
              </div>
              <div className="info-row">
                <div className="field">Client PO#:</div>
                <div className="value">{ticket?.client_po}</div>
              </div>
            </div>
            <div className="col">
              <div className="info-row">
                <div className="field">Date:</div>
                <div className="value">{ticket?.date}</div>
              </div>
              <div className="info-row">
                <div className="field">Customer:</div>
                <div className="value">{ticket?.project?.client_name}</div>
              </div>
              <div className="info-row">
                <div className="field">Prepared By:</div>
                <div className="value">
                  {ticket?.user?.first_name} {ticket?.user?.last_name}
                </div>
              </div>
            </div>
          </div>
          <SectionHeader title="Description" />
          <div className="single-ta description-padding-top">
            {ticket.description}
          </div>
          <div className="container description-padding-bottom">
            <div className="col single-col">
              <div className="info-row">
                <div className="field">Is the work complete?:</div>
                <div className="value">{ticket?.completed ? 'Yes' : 'No'}</div>
              </div>
            </div>
          </div>
          <SectionHeader title="Time & Material" />
          <div className="standard-table">
            <div className="ta-sub-header">Regular Hours</div>
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="">Regular Labor</th>
                  <th className="table-center-text static-number-width-table-col">
                    #
                  </th>
                  <th className="table-center-text static-width-table-col">
                    Hours
                  </th>
                  <th className="table-center-text static-width-table-col">
                    Rate
                  </th>
                  <th className="table-right-text static-width-table-col">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {hoursList.map((hour, idx) => {
                  if (hour.type === 'Regular') {
                    const usedHours = Number.parseFloat(
                      Number(hour.hours / 3600).toFixed(2),
                    )
                    const calculatedTotal = hour.total
                    return (
                      <tr key={idx}>
                        <td className="">{hour.description}</td>
                        <td className="table-center-text static-number-width-table-col">
                          {hour.number}
                        </td>
                        <td className="table-center-text static-width-table-col">
                          {usedHours}
                        </td>
                        <td className="table-center-text static-width-table-col">
                          ${(Math.round(hour.rate * 100) / 100).toFixed(2)}
                        </td>
                        <td className="table-right-text static-width-table-col">
                          $
                          {(Math.round(calculatedTotal * 100) / 100).toFixed(2)}
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
            <div className="ta-sub-header">Overtime Hours</div>
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="">OT Labor</th>
                  <th className="table-center-text static-number-width-table-col">
                    #
                  </th>
                  <th className="table-center-text static-width-table-col">
                    Hours
                  </th>
                  <th className="table-center-text static-width-table-col">
                    Rate
                  </th>
                  <th className="table-right-text static-width-table-col">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {hoursList.map((hour, idx) => {
                  if (hour.type === 'Overtime') {
                    const usedHours = Number.parseFloat(
                      Number(hour.hours / 3600).toFixed(2),
                    )
                    const calculatedTotal = hour.total
                    return (
                      <tr key={idx}>
                        <td className="">{hour.description}</td>
                        <td className="table-center-text static-number-width-table-col">
                          {hour.number}
                        </td>
                        <td className="table-center-text static-width-table-col">
                          {usedHours}
                        </td>
                        <td className="table-center-text static-width-table-col">
                          ${(Math.round(hour.rate * 100) / 100).toFixed(2)}
                        </td>
                        <td className="table-right-text static-width-table-col">
                          $
                          {(Math.round(calculatedTotal * 100) / 100).toFixed(2)}
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
            <div className="ta-sub-header">
              Material/Equipment/Subcontractor
            </div>
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="">Description</th>
                  <th className="table-center-text static-width-table-col">
                    #
                  </th>
                  <th className="table-center-text static-width-table-col">
                    Rate
                  </th>
                  <th className="table-right-text static-width-table-col">
                    Total
                  </th>
                </tr>
              </thead>
              <tbody>
                {hoursList.map((hour, idx) => {
                  if (hour.type === 'MES') {
                    const calculatedTotal = hour.total
                    return (
                      <tr key={idx}>
                        <td className="">{hour.description}</td>
                        <td className="table-center-text static-number-width-table-col">
                          {hour.number}
                        </td>
                        <td className="table-center-text static-width-table-col">
                          ${(Math.round(hour.rate * 100) / 100).toFixed(2)}
                        </td>
                        <td className="table-right-text static-width-table-col">
                          $
                          {(Math.round(calculatedTotal * 100) / 100).toFixed(2)}
                        </td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
            <table className="table-wrapper total-table">
              <tbody>
                <tr>
                  <td className="no-border" />
                  <th className="table-center-text static-width-table-col">
                    Total
                  </th>
                  <td className="table-right-text static-width-table-col">
                    ${Number(ticket.total).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="no-border" />
                  <th className="table-center-text static-width-table-col">
                    Markup ({ticket.markup}%)
                  </th>
                  <td className="table-right-text static-width-table-col">
                    ${Number(ticket.grand_total - ticket.total).toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td className="no-border" />
                  <th className="table-center-text static-width-table-col">
                    Grand Total
                  </th>
                  <td className="table-right-text static-width-table-col">
                    ${Number(ticket.grand_total).toFixed(2)}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="pageBreakInsideAvoid bottom-padding">
            <SectionHeader title="Authorization for Work and Payment" />
            <div className="prepared-wrapper">
              <div className="signature-wrapper">
                <img src={ticket.signature.image} />
              </div>
              <div className="printed-name">{ticket?.signature_name}</div>
            </div>
            <h4 className="ta-header">Terms And Condition</h4>
            {termsList.map((term, idx) => (
              <div className="single-ta" key={idx}>
                {term.text}
              </div>
            ))}
          </div>
          <div className="footer-with-buttons no-print">
            <ButtonElement
              text={'Print'}
              minWidth={'77px'}
              functionToTrigger={() => {
                printPageWithFirefoxWarning(setPopUp)
              }}
            />
            <UISref to={'app.timecard.tam'}>
              <a>
                <ButtonElement text={'Back'} minWidth={'77px'} />
              </a>
            </UISref>
          </div>
        </div>
      </section>
      {popUp}
    </section>
  )
}

function getDocumentStyle() {
  return css({
    minHeight: 100,
    backgroundColor: 'white',
    width: 'calc(100% + 30px)',
    marginTop: 2,
    marginLeft: -15,
    marginRight: -15,
    ...DocumentStyles,
    '.ta-sub-header': {
      marginTop: 5,
      marginBottom: 5,
      width: '100%',
      textAlign: 'center',
      paddingLeft: '10px',
      fontSize: '17px',
      fontWeight: 700,
      fontFamily: [
        'Open Sans',
        'Helvetica Neue',
        'Helvetica',
        'Arial',
        'sans-serif',
      ],
    },
    '.description-padding-top': {
      paddingTop: 5,
      whiteSpace: 'pre-wrap',
    },
    '.description-padding-bottom': {
      marginTop: 10,
      paddingBottom: 5,
    },
    table: {
      '.static-number-width-table-col': {
        width: 130,
      },
      '.static-width-table-col': {
        width: 180,
      },
      '.no-border': {
        border: 'none !important',
      },
    },
    '.total-table': {
      marginTop: 20,
    },
    '.ta-header': {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '.single-ta': {
      paddingLeft: 15,
      paddingRight: 15,
    },
    '.bottom-padding': {
      paddingBottom: 100,
    },
    '.footer-with-buttons': {
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: '60px',
    },
  })
}
