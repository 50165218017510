import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormAssignProjects',
    function ($scope, $stateParams, Api, app, $log) {
      $log.debug('FormAssignProjects')

      $scope.titleFormAssign = app.app_mobile_form_display
      $scope.titleMobileForm = pluralize.singular(app.app_mobile_form_display)
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.prevousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      const formId = $stateParams.form

      $scope.pageNumber = 1
      $scope.projects = []
      $scope.totalItems = 0
      $scope.order = 'name'
      $scope.app = app

      $scope.tableHeaders = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'active_for_mobile_form',
          display: 'Inactive/Active',
          sortable: true,
          toggleAllBtn: true
        },
      ]

      $scope.breadcrumbs = [
        {
          title: app.name,
        },
        {
          title: $scope.titleFormAssign,
          url: 'app.forms.list',
        },
      ]

      $scope.changePage = () => {
        getProjects()
      }

      $scope.toggleAll = () => {
        Api.post(
          `mobile_forms/${formId}/assign_all_projects`,
          {
            id: formId,
            application: app.id,
            on: $scope.active,
            search: $scope.search,
          },
          (resp) => {
            getProjects()
          },
        )
      }

      $scope.toggleActive = (project) => {
        if (!project.appProject) {
          Api.AppProjects.post(
            {
              app: app.id,
              project: project.id,
            },
            (resp) => {
              resp.mobile_forms = [formId]
              patchAppProject(resp)
            },
          )
        } else {
          if (project.active_for_mobile_form) {
            project.appProject.mobile_forms.push(formId)
          } else {
            project.appProject.mobile_forms =
              project.appProject.mobile_forms.filter((form) => form != formId)
          }

          patchAppProject(project.appProject)
        }
      }

      function patchAppProject(appProject) {
        Api.AppProjects.patch(
          {
            id: appProject.id,
            mobile_forms: appProject.mobile_forms,
          },
          (resp) => {},
        )
      }

      function projectQuery() {
        let q = {
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_active_mobile_form: formId,
          search_fields: 'name',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      function getProjects() {
        Api.Projects.get(projectQuery(), (resp) => {
          $scope.totalItems = resp.count
          const projects = resp.results
          const projectIds = projects.map((p) => p.id).join()

          Api.AppProjects.get(
            {
              app: app.id,
              projects: projectIds,
              only_mobile_forms: true,
            },
            (resp) => {
              $scope.projects = projects.map((project) => {
                const appProject = resp.results.filter(
                  (appProject) => appProject.project.id == project.id,
                )[0]

                if (appProject) {
                  project.active_for_mobile_form =
                    appProject.active_mobile_forms.indexOf(
                      parseInt(formId, 10),
                    ) > -1
                  project.appProject = appProject
                } else {
                  project.active_for_mobile_form = false
                  project.appProject = null
                }

                return project
              })
            },
          )
        })
      }

      getProjects()
    },
  )
