import { resolvers } from '../resolvers'

/** @ngInject */
export function trainingsConfig($stateProvider) {
  $stateProvider
    .state('trainings', {
      abstract: true,
      templateUrl: 'app/views/common/content.html',
      data: { pageTitle: 'Trainings' },
      resolve: {
        ...resolvers.appsResolver,
        ...resolvers.trainingPageAccessResolver,
      },
    })
    .state('trainings.assign', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
      data: { pageTitle: 'Assign Trainings' },
    })
    // .state('trainings.assign.trainings_list', {
    //   controller: 'EmployeesListCtrl',
    //   url: '/trainings/assign/training_to_employees?previousPageNumber&previousOrder&previousReverse&previousSearch',
    //   templateUrl: 'app/views/assign_training_to_employees.html',
    //   resolve: makeLoadResolver('permissionAssignTraining'),
    // })
    // .state('trainings.assign.job_trainings_groups_list', {
    //   controller: 'TrainingGroupListCtrl',
    //   url: '/trainings/assign/job_trainings_groups_list?previousPageNumber&previousOrder&previousReverse&previousSearch',
    //   templateUrl: 'app/views/job_trainings_groups_list.html',
    //   resolve: makeLoadResolver('permissionAssignTraining'),
    // })
    .state('trainings.quiz', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
    })
}
