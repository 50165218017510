import * as React from 'react'
import { css } from '@emotion/react'

import {
  useAngularServices,
  CommonPageHeader,
  ButtonElement,
} from '@/react/components'
import { TextInputStyle } from '@screens/components'
import { useRouter } from '@/react/hooks'
import { PartyObservedNavBar } from '../components/PartyObservedNavBar'

export const PartyObservedCreate = () => {
  const { Api, Notification, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const [submitText, setSubmitText] = React.useState<string>('')
  const [countLeft, setCountLeft] = React.useState<number>(255)
  const userSettings = CurrentUser.getClientSettings()
  const [POVariableName] = React.useState(userSettings.party_observed_language)

  async function onSubmit() {
    try {
      await Api.post('answer_party_observed', {
        name: submitText,
      })

      stateService.go('admin.party_observed_list', {})
    } catch (error) {
      Notification.clearNotification()
      Notification.danger(error.data[0])
    }
  }

  return (
    <section>
      <CommonPageHeader headerText={POVariableName} />
      <PartyObservedNavBar />
      <section css={baseStyle}>
        <label>{POVariableName}</label>
        <input
          placeholder={'Enter ' + POVariableName}
          maxLength={255}
          type="text"
          onChange={(value) => {
            setCountLeft(255 - value.target.value.length)
            setSubmitText(value.target.value)
          }}
        />
        <div className="length-message">{countLeft}</div>
        <div className="button-row d-flex justify-content-end">
          <ButtonElement
            text={'Cancel'}
            functionToTrigger={() => {
              stateService.go('admin.party_observed_list', {})
            }}
            width={'auto'}
          />
          <ButtonElement
            disabled={!submitText.length}
            text={'Save'}
            functionToTrigger={onSubmit}
            buttonType={'submit'}
            width={'auto'}
            additionalStyles={{
              marginLeft: '15px',
            }}
          />
        </div>
      </section>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  flexDirection: 'column',
  width: 500,
  maxWidth: '100%',
  backgroundColor: 'white',
  padding: 40,
  marginLeft: 1,
  input: {
    width: '100%',
    ...TextInputStyle,
  },
  '.length-message': {
    width: '100%',
    textAlign: 'right',
  },
  '.button-row': {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 30,
  },
})
