import { css } from '@emotion/react'
import { React, useState, useEffect, useRef } from 'react'
import { TextInputStyle } from '@screens/components'
import {
  ButtonElement,
  CustomCheckbox,
  ReloadElement,
  useAngularServices,
} from '@/react/components'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { CheckIcon, XIcon } from '@/react/componentAssets'
import { without } from 'lodash'

const ROLES = {
  client_admin: 'Administrator',
  supervisor: 'Supervisor',
  client_user: 'Employee',
  read_only: 'Field Employee',
  trainee_manager: 'Training Manager',
  trainee: 'Trainee',
  recipient: 'Recipient',
}

export const AlertSettingPopup = ({
  setUsersList,
  usersList,
  setPopup,
  rowData,
  setAmountOfActiveUser,
}) => {
  const { Api, CurrentUser } = useAngularServices()

  const [userListData, setUserListData] = useState(null)
  const [activeUsers, setActiveUsers] = useState([])
  const [order, setOrder] = useState<string>('first_name,last_name')
  const [temporarySearch, setTemporarySearch] = useState<string>('')
  const [search, setSearch] = useState<string>('')
  const [areAllChecked, setAreAllChecked] = useState<boolean>(false)
  const [reloadCheckMark, setReloadCheckMark] = useState<boolean>(false)
  const [reloadList, setReloadList] = useState<boolean>(false)
  const scrollableContentRef = useRef(null)

  async function getActiveUsersFromCash(userList) {
    if (userList === null) return
    const setUpActiveUsers = []
    let areAllTrue = true
    userList.forEach((user) => {
      if (user.assigned_to_alerts) {
        if (!reFetch) {
          setUpActiveUsers.push(user.id)
        }
      }
      if (!activeUsers.includes(user.id)) {
        areAllTrue = false
      }
    })
    setActiveUsers(setUpActiveUsers)
    setReloadCheckMark(true)
    setAreAllChecked(areAllTrue)
    setUserListData(userList)
    setReloadList(true)
  }

  async function fetchUserList(search, order, reFetch = false) {
    const { data: usersGet } = await Api.get(
      'users/alert_recipients/' + rowData?.id,
      {
        order:
          order.replace('-', '') === 'assigned_to_alerts'
            ? 'first_name,last_name'
            : order,
        search: search,
        page_size: '50000',
        is_active: 'true',
      },
    )

    const setUpActiveUsers = []
    let areAllTrue = true
    usersGet.results.forEach((user) => {
      if (user.assigned_to_alerts) {
        if (!reFetch) {
          setUpActiveUsers.push(user.id)
        }
      }
      if (!reFetch) {
        if (!setUpActiveUsers.includes(user.id)) {
          areAllTrue = false
        }
      } else {
        if (!activeUsers.includes(user.id)) {
          areAllTrue = false
        }
      }
    })
    let reorderedLocallyUsers
    if (order.replace('-', '') === 'assigned_to_alerts') {
      const activeUsersObjList = []
      const nonActiveUsersObjList = []
      usersGet.results.forEach((user) => {
        if (activeUsers.includes(user.id)) {
          activeUsersObjList.push(user)
        } else {
          nonActiveUsersObjList.push(user)
        }
      })
      if (order === 'assigned_to_alerts') {
        reorderedLocallyUsers = [
          ...activeUsersObjList,
          ...nonActiveUsersObjList,
        ]
      } else {
        reorderedLocallyUsers = [
          ...nonActiveUsersObjList,
          ...activeUsersObjList,
        ]
      }
    }
    if (!reFetch) {
      setActiveUsers(setUpActiveUsers)
      setUsersList(usersGet.results)
    }
    setReloadCheckMark(true)
    setAreAllChecked(areAllTrue)
    setUserListData(
      order.replace('-', '') === 'assigned_to_alerts'
        ? reorderedLocallyUsers
        : usersGet.results,
    )
    setReloadList(true)
  }

  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => (document.body.style.overflow = 'auto')
  }, [])

  useEffect(() => {
    if (!userListData) {
      fetchUserList(search, order)
    } else {
      getActiveUsersFromCash(usersList)
    }
  }, [])

  const inputRef = useRef()

  if (!userListData) return null

  function orderChange(orderToSet) {
    if (order === orderToSet) {
      fetchUserList(search, '-' + orderToSet, true)
      setOrder('-' + orderToSet)
    } else {
      fetchUserList(search, orderToSet, true)
      setOrder(orderToSet)
    }
  }

  function searchChange() {
    fetchUserList(temporarySearch, order, true)
    setSearch(temporarySearch)
  }

  function clearSearch() {
    fetchUserList('', order, true)
    inputRef.current.value = ''
    setSearch('')
    setTemporarySearch('')
  }

  function toggleListStatus(addtionalData, value) {
    let updateList = [...activeUsers]
    userListData.forEach((user) => {
      if (value) {
        if (!updateList.includes(user.id)) updateList.push(user.id)
      } else {
        updateList = without(updateList, user.id)
      }
    })
    setReloadList(true)
    setActiveUsers(updateList)
  }

  function userStatusChange(id, value) {
    let updateList = [...activeUsers]
    if (value) {
      updateList.push(id)
    } else {
      updateList = without(updateList, id)
    }
    setActiveUsers(updateList)
  }

  async function submitUsers() {
    await Api.post('mobile_forms/' + rowData?.id + '/set_recipients', {
      alert_recipients: activeUsers,
    })
    setAmountOfActiveUser(activeUsers.length)
    setUsersList(null)
    setPopup(null)
    document.body.style.overflow = 'auto'
  }

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const baseStyle = getBaseStyle(variableColor, userListData.length)

  return (
    <section css={baseStyle}>
      <div className="background-holder">
        <div
          className="background"
          onClick={() => {
            setPopup(null)
            document.body.style.overflow = 'auto'
          }}
        />
      </div>
      <div className="alert-holder">
        <div className="alert-body">
          <div className="close-button-row">
            <img
              src={closeIcon}
              alt="close"
              css={css({
                height: 25,
                cursor: 'pointer',
                marginRight: -25,
              })}
              onClick={() => {
                setPopup(null)
                document.body.style.overflow = 'auto'
              }}
            />
          </div>
          <h4 className="form__title">{rowData?.name}</h4>
          <div className="header">Assign Users</div>
          <div className="search-row">
            <input
              type="text"
              placeholder="Search"
              className="search__input"
              style={{ ...TextInputStyle }}
              onChange={(value) => {
                setTemporarySearch(value.target.value)
              }}
              ref={inputRef}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  searchChange()
                }
              }}
            />
            {temporarySearch.length === 0
              ? null
              : [
                  <div
                    key="clear"
                    className="search-row-buttons clear-button"
                    onClick={clearSearch}
                  >
                    <XIcon height={16} />
                  </div>,
                  <div
                    key="search"
                    className="search-row-buttons search-button"
                    onClick={() => {
                      searchChange()
                    }}
                  >
                    <CheckIcon height={'12.3px'} width={16} />
                  </div>,
                ]}
          </div>
          <div>
            <div className="table-header">
              <div className="checkbox-block">
                {reloadCheckMark ? (
                  <ReloadElement setReload={setReloadCheckMark} />
                ) : (
                  <CustomCheckbox
                    additionalStyle={css({
                      height: 18,
                      width: 18,
                      '.checkmark': {
                        img: {
                          height: 12,
                          width: 12,
                        },
                      },
                    })}
                    isDefaultChecked={areAllChecked}
                    onChange={toggleListStatus}
                  />
                )}
                <div
                  className="arrow-for-checkbox"
                  onClick={() => {
                    orderChange('assigned_to_alerts')
                  }}
                >
                  {order === 'assigned_to_alerts' ? (
                    <span className="header-sort-arrow fa fa-angle-down" />
                  ) : null}
                  {order === '-assigned_to_alerts' ? (
                    <span className="header-sort-arrow fa fa-angle-up" />
                  ) : null}
                </div>
              </div>
              <div
                className="name-block"
                onClick={() => {
                  orderChange('first_name,last_name')
                }}
              >
                <p>Name</p>
                {order === 'first_name,last_name' ? (
                  <span className="header-sort-arrow fa fa-angle-down" />
                ) : null}
                {order === '-first_name,last_name' ? (
                  <span className="header-sort-arrow fa fa-angle-up" />
                ) : null}
              </div>
              <div
                className="id-block"
                onClick={() => {
                  orderChange('employee_id')
                }}
              >
                <p>ID</p>
                {order === 'employee_id' ? (
                  <span className="header-sort-arrow fa fa-angle-down" />
                ) : null}
                {order === '-employee_id' ? (
                  <span className="header-sort-arrow fa fa-angle-up" />
                ) : null}
              </div>
              <div
                className="role-block"
                onClick={() => {
                  orderChange('role')
                }}
              >
                <p>Role</p>
                {order === 'role' ? (
                  <span className="header-sort-arrow fa fa-angle-down" />
                ) : null}
                {order === '-role' ? (
                  <span className="header-sort-arrow fa fa-angle-up" />
                ) : null}
              </div>
            </div>
            <div className="table-body">
              {reloadList ? (
                <ReloadElement setReload={setReloadList} />
              ) : (
                userListData.map((row, idx) => {
                  return (
                    <div
                      key={row?.id}
                      className="table-row"
                      css={css({
                        backgroundColor:
                          idx % 2 === 0 ? '#f9f9f9' : 'transparent',
                      })}
                    >
                      <div className="checkbox-block">
                        <CustomCheckbox
                          additionalStyle={css({
                            height: 18,
                            width: 18,
                            '.checkmark': {
                              img: {
                                height: 12,
                                width: 12,
                              },
                            },
                          })}
                          additionalParams={row.id}
                          onChange={userStatusChange}
                          isDefaultChecked={activeUsers.includes(row.id)}
                        />
                      </div>
                      <div className="name-block">
                        <p>
                          {(row.first_name + ' ' + row.last_name).substring(
                            0,
                            18,
                          )}
                          {(row.first_name + ' ' + row.last_name).length > 18
                            ? '...'
                            : null}
                        </p>
                        {(row.first_name + ' ' + row.last_name).length > 18 ? (
                          <div className="hover-body-for-files">
                            {row.first_name + ' ' + row.last_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="id-block">
                        <p>{row.employee_id}</p>
                      </div>
                      <div className="role-block">
                        <p>{ROLES[row.role]}</p>
                      </div>
                    </div>
                  )
                })
              )}
            </div>
          </div>
          <div className="button-row">
            <ButtonElement
              text={'OK'}
              functionToTrigger={submitUsers}
              buttonType={'submit'}
            />
          </div>
        </div>
      </div>
    </section>
  )
}

function getBaseStyle(variableColor, userListDataLength) {
  return css({
    width: 0,
    height: 0,
    '.search__input': {
      fontSize: '12px',
      '::placeholder': {
        color: '#B3B3B3',
        fontWeight: 600,
        fontSize: '12px',
      },
    },
    '.form__title': {
      maxHeight: '33px',
      overflow: 'hidden',
      whiteSpace: 'normal',
    },
    '.background-holder': {
      width: 0,
      height: 30,
      '.background': {
        position: 'fixed',
        right: 0,
        top: 0,
        width: '100vw',
        height: '100vh',
      },
    },
    '.alert-holder': {
      width: 0,
      height: 0,
      '.alert-body': {
        zIndex: 1,
        padding: 40,
        paddingTop: 15,
        paddingBottom: 40,
        position: 'fixed',
        right: 'calc(50vw - 250px)',
        top: 'calc(50vh - 200px)',
        width: 510,
        background: 'white',
        webkitBoxShadow: '-1px 7px 20px 10px rgba(0,0,0,0.1)',
        mozBoxShadow: '-1px 7px 20px 10px rgba(0,0,0,0.1)',
        boxShadow: '-1px 7px 20px 10px rgba(0,0,0,0.1)',
        '.button-row': {
          height: 0,
          marginTop: 20,
        },
        '.close-button-row': {
          display: 'flex',
          justifyContent: 'right',
          width: '100%',
        },
        '.checkbox-block': {
          paddingLeft: 10,
          width: '15%',
        },
        '.name-block': {
          width: '35%',
          position: 'relative',
          '.hover-body-for-files': {
            position: 'absolute',
            left: -20,
            bottom: 'calc(100% + 5px)',
            display: 'none',
            border: '1px solid #999',
            backgroundColor: 'white',
            padding: 5,
          },
          ':hover': {
            '.hover-body-for-files': {
              display: 'block',
            },
          },
        },
        '.id-block': {
          width: '25%',
        },
        '.role-block': {
          width: '25%',
        },
        '.table-header': {
          cursor: 'pointer',
          marginTop: 10,
          fontSize: 13,
          fontWeight: 'bold',
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '2px solid #E5E5E5',
          div: {
            display: 'flex',
            justifyContent: 'left',
            gap: '5px',
          },
        },
        '.search-row': {
          marginTop: 25,
          width: '100%',
          display: 'flex',
          justifyContent: 'left',
          input: {
            height: 30,
          },
          '.search-row-buttons': {
            height: 30,
            width: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: variableColor,
            cursor: 'pointer',
          },
          '.clear-button': {
            color: variableColor,
            ':hover': {
              backgroundColor: variableColor,
              color: 'white',
            },
          },
          '.search-button': {
            color: 'white',
            backgroundColor: variableColor,
            ':hover': {
              backgroundColor: 'transparent',
              color: variableColor,
            },
          },
        },
        '.arrow-for-checkbox': {
          height: 30,
          width: 40,
          cursor: 'pointer',
        },
        '.table-body': {
          overflowY: userListDataLength > 5 ? 'scroll' : 'hidden',
          overflowX: 'hidden',
          height: 180,
          width: '100%',
          '.table-row': {
            fontSize: 13,
            display: 'flex',
            paddingTop: 10,
            justifyContent: 'space-between',
            div: {
              textAlign: 'left',
            },
          },
        },
      },
    },
  })
}
