//Allows to have Advanced filters selected as null and will put them as a key param with no value

export const objToStringParams = (params: {
  [key: string]: string | number | null
}): string => {
  let stringParams = ''

  Object.entries(params).forEach((param) => {
    if (param[1] === null) {
      stringParams += param[0] + '&'
    } else {
      stringParams +=
        param[0] + '=' + (param[1] ? encodeURIComponent(param[1]) : '') + '&'
    }
  })

  return stringParams.slice(0, -1)
}
