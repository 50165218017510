import { useMemo, useCallback, MouseEvent, MouseEventHandler } from 'react'
import { useAngularServices } from '@components'
import { TransitionOptions } from '@/react/types'

import { useDeepObjectDiff } from './useDeepObjectDiff'

interface LinkProps {
  onClick: MouseEventHandler<any>
  href?: string
}

/**
 * A hook to create a link to a state.
 *
 * This hook returns link (anchor tag) props for a given state reference.
 * The resulting props can be spread onto an anchor tag.
 *
 * The props returned from this hook are:
 *
 * - `href`: the browser URL of the referenced state
 * - `onClick`: a mouse event handler that will active the referenced state
 *
 * Example:
 * ```jsx
 * function HomeLink() {
 *   const sref = useSref('home');
 *   return <a {...sref}>Home</a>
 * }
 * ```
 *
 * Example:
 * ```jsx
 * function UserLink({ userId, username }) {
 *   const sref = useSref('users.user', { userId: userId });
 *   return <a {...sref}>{username}</a>
 * }
 * ```
 *
 * The `onClick` handler falls back to native browser behavior (does not initiate a state transition) when:
 *
 * - the user Ctrl+Click / Alt+Click / Meta+Click / Shift+Click
 * - the underlying tag (e.g.: anchor tag) has a 'target' attribute, such as `<a target="_blank">Open in new window</a>`
 * - preventDefault has been called on the event, e.g.: `<a onClick={e => e.preventDefault()}>no-op</a>`
 *
 * @param stateName The name of the state to link to
 * @param params Any parameter values
 * @param options Transition options used when the onClick handler fires.
 */
export function useSref(
  stateName: string,
  params: object = {},
  options: TransitionOptions = {},
): LinkProps {
  const { $state } = useAngularServices()

  // memoize the params object until the nested values actually change so they can be used as deps
  const paramsMemo = useMemo(() => params, [useDeepObjectDiff(params)])

  const optionsMemo = useMemo(() => ({ inherit: true, ...options }), [options])
  // Update href when the target StateDeclaration changes (in case the the state definition itself changes)
  // This is necessary to handle things like future states
  const href = useMemo(() => {
    return $state.href(stateName, paramsMemo, optionsMemo)
  }, [$state, stateName, paramsMemo, optionsMemo])

  const onClick = useCallback(
    (e: MouseEvent) => {
      const targetAttr = (e.target as any)?.attributes?.target
      const modifierKey =
        e.button >= 1 || e.ctrlKey || e.metaKey || e.shiftKey || e.altKey
      if (!e.defaultPrevented && targetAttr == null && !modifierKey) {
        e.preventDefault()
        $state.go(stateName, paramsMemo, optionsMemo)
      }
    },
    [$state, stateName, paramsMemo, optionsMemo],
  )

  return { onClick, href }
}
