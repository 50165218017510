import arrowIcon from '@/assets/icons/arrow-icon.svg'
import { UISref } from '@/react/components/UISref'
import { css } from '@emotion/react'
import classNames from 'classnames'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

export const TableElement = ({ job, index }) => {
  const { training } = job

  return (
    <UISref
      to={'trainings.topics'}
      params={{
        jobId: job.id,
      }}
    >
      <div
        className={classNames('table__element', {
          dark: index % 2 !== 0,
        })}
        css={baseStyle}
      >
        <p className="element__title">{training.name}</p>
        <div className="left__block">
          <div style={{ width: 29, height: 29 }}>
            <CircularProgressbar
              value={job.status}
              maxValue={100}
              text={`${job.status}%`}
            />
          </div>

          <img className="icon" src={arrowIcon} alt="view icon" />
        </div>
      </div>
    </UISref>
  )
}

const baseStyle = css({
  display: 'flex',
  gap: '100px',
  minHeight: '40px',
  borderStyle: 'solid none none',
  borderColor: '#EEEFF3',
  borderWidth: '1px',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  '.left__block': {
    display: 'flex',
    gap: '53px',
    marginRight: '25px',
  },
  '@media(max-width: 800px)': {
    gap: '60px',
    '.left__block': {
      gap: '30px',
      marginRight: '10px',
    },
  },
  '.element__title': {
    color: '#68696D',
    fontSize: '12px',
    fontFamily: 'Open Sans',
    letterSspacing: '0.12px',
    marginLeft: '18px',
    marginBottom: 0,
  },
  '.CircularProgressbar-text': {
    fontSize: '29px',
    fill: 'black',
  },
  '.icon': {
    cursor: 'pointer',
  },
})
