import { validateFileName } from '@/utils'

import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'ProcessFlowFormCtrl',
    function (
      $scope,
      $state,
      $stateParams,
      Api,
      app,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('ProcessFlowFormCtrl')

      $scope.titleFlowCreate = pluralize.singular(app.app_process_flow_display)

      $scope.process_flow = {
        file: {},
        name: '',
        client: CurrentUser.getClientId(),
      }
      let fileChanged = false
      var pdfDetected = 0
      var isClickable = false

      $scope.dzConfig = {
        parallelUploads: 1,
        acceptedFiles: 'application/pdf',
        url: '#',
        maxFiles: 1,
        maxFilesize: 100,
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        init: function () {
          //For the backfilled mockfiles
          //https://github.com/enyo/dropzone/issues/279
          this.on('addedfile', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
        },
      }

      $scope.dzAddedFile = function (file) {
        var isPdf = file.type === 'application/pdf'
        var errors = validateFileName(
          file.name.substring(0, file.name.lastIndexOf('.')),
        )
        if (isPdf && errors === false) {
          $scope.process_flow.file.file = file
          $scope.process_flow.name = $scope.process_flow.name
            ? $scope.process_flow.name
            : file.name.substring(0, file.name.lastIndexOf('.')) || file.name
          pdfDetected = 1
          fileChanged = true
          isClickable = true
        } else {
          pdfDetected = -1
          fileChanged = false
          isClickable = false
          document.querySelectorAll('.dz-error-mark')[0].style.opacity = '1'
          var errorDisplay = document.querySelectorAll('.dz-error-message')[0]
          errorDisplay.style.opacity = '1'
          if (!isPdf) errors.push('PDF documents only please.')
          $(errorDisplay).text(errors.join('\n'))
        }

        $scope.$apply(function () {
          $scope.isClickable = isClickable
        })
      }

      $scope.dzRemovedFile = function (file) {
        fileChanged = !fileChanged
        pdfDetected -= 1

        if (this.files.length == 0) {
          isClickable = false
        } else {
          isClickable = true
        }

        $scope.$apply(function () {
          $scope.isClickable = isClickable
        })
      }

      function create(data) {
        Api.ProcessFlows.post(data, () => {
          $('.dz-progress').css('display', 'none')
          $scope.isClickable = true
          $state.go('app.process_flows.list')
        })
      }

      $scope.save = function () {
        var fileNameErrors = validateFileName($scope.process_flow.name)
        if (fileChanged && pdfDetected >= 0 && fileNameErrors === false) {
          $scope.isClickable = false
          $('.dz-progress').css('display', 'block')
          Api.uploadFile($scope.process_flow.file.file, {}, (resp) => {
            return create({
              ...$scope.process_flow,
              pdf: resp.id,
              application: app.id,
            })
          })
        } else {
          if (fileNameErrors.length > 0) {
            Notification.danger(fileNameErrors.join('\n'))
          } else Notification.danger('No PDF Attached.')
          $('.dz-progress').css('display', 'none')
        }
      }
    },
  )
