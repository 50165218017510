import * as React from 'react'
import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'

export const VerticalTextedSwitch = ({
  textOne,
  valueOne,
  textTwo,
  valueTwo,
  valueCallback,
  height = 50,
  width = 'fit-content',
  fontSize = 12,
  defaultValue = valueOne,
}) => {
  const [selectedOption, setsSelectedOption] =
    React.useState<string>(defaultValue)

  const style = css({
    height: height,
    '.texted-switch': {
      cursor: 'pointer',
      ...TextInputStyle,
      height: height / 2,
      width: width,
      fontSize: fontSize,
      paddingTop: 2,
      paddingBottom: 2,
      paddingRight: 4,
      paddingLeft: 4,
      lineHeight: '18px',
    },
    '.active': {
      backgroundColor: 'rgba(55,128,205, 0.2)',
    },
  })

  return (
    <div css={style}>
      <div
        className={
          'texted-switch' + (selectedOption === valueOne ? ' active' : '')
        }
        onClick={() => {
          setsSelectedOption(valueOne)
          valueCallback(valueOne)
        }}
      >
        {textOne}
      </div>
      <div
        className={
          'texted-switch' + (selectedOption === valueTwo ? ' active' : '')
        }
        onClick={() => {
          setsSelectedOption(valueTwo)
          valueCallback(valueTwo)
        }}
      >
        {textTwo}
      </div>
    </div>
  )
}
