// eslint-disable-next-line
// @ts-ignore
import appModule from '@appModule'
import { createContext, FC, ReactChildren, useContext } from 'react'
import { react2angular } from 'react2angular'
import createSharedContext from 'react2angular-shared-context'
import {
  ApiService,
  UIRouterState,
  NotificationService,
  GeneralSettings,
} from '@/react/types'

// @TODO: type angular services properly
type AngularServices = {
  Notification: NotificationService
  CurrentUser: {
    any: any
    getClientId: () => string | number
    getId: () => string | number
    getClientSettings(): any
    getInstance(): any
    setToken(token: string): any
    getRole(): string
    setClientSettings(settings: GeneralSettings): void
    getClientCompany(): any
  }
  $state: UIRouterState
  $rootScope: any
  Api: ApiService
  uiPermissionService: any
  Auth: any
  Configuration: any
}

const defaultValue = {
  Notification: null,
  CurrentUser: null,
  $state: null,
  $rootScope: null,
  Api: null,
  uiPermissionService: null,
  Auth: null,
  Configuration: null,
}

const ServicesContext = createContext<AngularServices>(defaultValue)

type Props = {
  children: ReactChildren
} & AngularServices

const ReactAngularContext: FC<Props> = ({
  children,
  NotificationReact,
  CurrentUser,
  $rootScope,
  $state,
  Api,
  uiPermissionService,
  Auth,
  Configuration,
}: Props) => (
  <ServicesContext.Provider
    value={{
      Notification: NotificationReact,
      CurrentUser,
      $state,
      $rootScope,
      Api,
      uiPermissionService,
      Auth,
      Configuration,
    }}
  >
    {children}
  </ServicesContext.Provider>
)

const sharedContext = createSharedContext(ReactAngularContext)

// eslint-disable-next-line
appModule.component(
  'reactAngularContext',
  react2angular(
    sharedContext.component,
    [],
    [
      'NotificationReact',
      'CurrentUser',
      '$state',
      '$rootScope',
      'Api',
      'uiPermissionService',
      'Auth',
      'Configuration',
    ],
  ),
)

// Every React screen should be wrapped into this wrapper like so:
//
// import { ReactWrapper } from '@components'
// import { DemoComponent } from './DemoComponent'
//
// export default ReactWrapper(DemoComponent, 'reactDemoComponent')

export const ReactWrapper = (Component: FC, componentName: string) =>
  // eslint-disable-next-line
  appModule.component(
    componentName,
    react2angular(sharedContext.use(Component), [], []),
  )

export const useAngularServices = () => useContext(ServicesContext)
