import { createAnswerTemplate } from '@screens/MobileForms/CreateEditPages/components'

export function genericTextValueUpdate(
  value,
  possibleGroupData,
  formMemory,
  fieldData,
  setFormMemory,
) {
  if (possibleGroupData) {
    formMemory[fieldData.id][possibleGroupData]['touched'] = true
    formMemory[fieldData.id][possibleGroupData].localAnswer.value = value
    formMemory[fieldData.id][possibleGroupData].localAnswer.text = value
    setFormMemory(formMemory)
  } else {
    formMemory[fieldData.id]['touched'] = true
    formMemory[fieldData.id].localAnswer.value = value
    formMemory[fieldData.id].localAnswer.text = value
    setFormMemory(formMemory)
  }
}

function checkIsOptionDeleted(
  junction: { fieldoption: string; deleted: boolean }[],
  id: string,
): boolean {
  return junction.some((option) => option.fieldoption === id && !option.deleted)
}

// @TODO: rewrite without input modification
export function getStartingValue(
  possibleGroupData,
  formMemory,
  fieldData,
  templateName,
  defValue: any = '',
  clearOriginal = false,
) {
  let dataToReturn

  const field = formMemory[fieldData.id]

  if (possibleGroupData) {
    if (!field[possibleGroupData]) {
      const localAnswer = createAnswerTemplate(field.set_id_0.id)
      field[possibleGroupData] = {
        ...field.set_id_0,
        localAnswer,
      }
      if (templateName === 'multi_answers') {
        dataToReturn = localAnswer[templateName].filter((option) =>
          checkIsOptionDeleted(localAnswer.multi_answers_junction, option.id),
        )
      } else {
        dataToReturn = localAnswer[templateName]
      }
    } else {
      if (templateName === 'multi_answers') {
        dataToReturn = field[possibleGroupData].localAnswer[
          templateName
        ].filter((option) =>
          checkIsOptionDeleted(
            field[possibleGroupData].localAnswer.multi_answers_junction,
            option.id,
          ),
        )
      } else {
        dataToReturn = field[possibleGroupData].localAnswer[templateName]
      }
      if (clearOriginal) {
        field[possibleGroupData].localAnswer[templateName] = []
      }
    }
  } else {
    if (templateName === 'multi_answers') {
      dataToReturn = field.localAnswer[templateName].filter((option) =>
        checkIsOptionDeleted(
          field.localAnswer.multi_answers_junction,
          option.id,
        ),
      )
    } else {
      dataToReturn = field.localAnswer[templateName]
    }
    if (clearOriginal) {
      field.localAnswer[templateName] = []
    }
  }
  return dataToReturn || defValue
}

export function putErrorSet(
  errorSet,
  possibleGroupData,
  formMemory,
  fieldData,
  setFormMemory,
) {
  if (possibleGroupData) {
    formMemory[fieldData.id][possibleGroupData].errorStatusSet = errorSet
    setFormMemory(formMemory)
  } else {
    formMemory[fieldData.id].errorStatusSet = errorSet
    setFormMemory(formMemory)
  }
}
