angular
  .module('ccs')
  .controller(
    'CompanyLogoCtrl',
    function ($scope, $state, $location, Api, CurrentUser, Notification, $log) {
      $log.debug('CompanyLogoCtrl')

      $scope.newFile = null
      $scope.isAnotherFileUploaded = false
      $scope.getClientCompany = CurrentUser.getClientCompany
      $scope.isFileValid = false

      $scope.dzConfig = {
        parallelUploads: 1,
        maxFiles: 1,
        maxFileSize: 30,
        autoProcessQueue: false,
        addRemoveLinks: true,
        acceptedFiles: 'image/png, image/jpeg, .jpg, .jpeg, .png',
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        init: function () {
          var dz = this

          this.on('addedfile', function () {
            if (this.files[1] != null) {
              $scope.isAnotherFileUploaded = true
              this.removeFile(this.files[0])
              $scope.$apply(function () {
                $scope.isFileValid = false
              })
            }
          })

          this.on('removedfile', function () {
            if ($scope.isAnotherFileUploaded == false) {
              this.removeAllFiles()
              setTimeout(function () {
                $scope.$apply(function () {
                  $scope.newFile = ''
                  $scope.isFileValid = false
                })
              }, 10)
            }
          })
        },
      }

      $scope.dzAddedFile = function (file) {
        if (!file) return

        if (file.type === 'image/png' || file.type === 'image/jpeg') {
          $scope.newFile = file
          $scope.$apply(function () {
            $scope.isFileValid = true
          })
        } else {
          document.querySelectorAll('.dz-error-mark')[0].style.opacity = '1'
          var errorDisplay = document.querySelectorAll('.dz-error-message')[0]
          errorDisplay.style.opacity = '1'
          $(errorDisplay).text("You can't upload files of this type.")
          $('.dz-progress').css('display', 'none')
          $scope.$apply(function () {
            $scope.isFileValid = false
          })
        }
      }

      $scope.dzRemovedFile = function (file) {
        $scope.newFile = ''
        $scope.isFileValid = false
      }

      $scope.submit = function () {
        if ($scope.newFile) {
          $('.dz-progress').css('display', 'block')
          Api.uploadImage($scope.newFile, {}, function (data) {
            Api.Companies.patch(
              { logo: data.id, id: CurrentUser.getClientCompany().id },
              function (resp) {
                if (resp) {
                  CurrentUser.setClientCompany(resp)
                  Notification.success('Logo successfully updated.')

                  $scope.isAnotherFileUploaded = false
                  $('.dz-progress').css('display', 'none')
                  $state.go('admin.company.logo', {}, { reload: true })
                } else {
                  Notification.warning(
                    'Unable to save logo. Please try again later.',
                  )
                  $scope.$apply(function () {
                    $scope.isFileValid = false
                  })
                }
              },
            )
          })
        } else {
          Notification.warning('No Image Selected.')
          $scope.isAnotherFileUploaded = false
          $('.dz-progress').css('display', 'none')

          $scope.$apply(function () {
            $scope.isFileValid = false
          })
        }
      }
    },
  )
