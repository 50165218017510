angular.module('ccs').directive('photoGrid', photoGrid)

function photoGrid() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/photo_grid.html',
    scope: {
      photos: '=',
      answer: '=',
    },
    link: function ($scope) {
      $scope.renderCreator = function (obj) {
        if (obj.user != null) {
          return obj.user['first_name'] + ' ' + obj.user['last_name']
        }
        if (obj.external_contact != null) {
          return (
            obj.external_contact['first_name'] +
            ' ' +
            obj.external_contact['last_name']
          )
        }
      }

      $scope.hasCreator = (obj) =>
        obj !== undefined && obj.user != null && obj.external_contact != null

      $scope.setScalePhoto = function (el) {
        if (!el.target.style['transform']) return
        $(el.target).css(
          'transform',
          'scale(1.03)' + el.target.style['transform'],
        )
      }

      $scope.removeScalePhoto = function (el) {
        if (!el.target.style['transform']) return
        var transformStr = el.target.style['transform']
        var searchStr = 'scale(1.03) '.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
        transformStr = transformStr.replace(new RegExp(searchStr, 'gi'), '')
        $(el.target).css('-ms-transform', transformStr)
        $(el.target).css('-webkit-transform', transformStr)
        $(el.target).css('-moz-transform', transformStr)
        $(el.target).css('transform', transformStr)
      }
    },
    controller: function ($scope, $uibModal, filterService) {
      // for photos, notes and corrective actions
      $scope.getIsVisible = filterService.getIsVisibleObservationItem

      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo

        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      $scope.removePhoto = function (photo) {
        if (photo) {
          if (photo.file_name) {
            const index = $scope.answer.photos
              .map(function (e) {
                return e.file_name
              })
              .indexOf(photo.file_name)
            setTimeout(function () {
              $scope.answer.photos.splice(index, 1)
              $scope.$apply()
            }, 0)
          }
        }
      }
    },
  }
}
