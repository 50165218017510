import * as React from 'react'
import { css } from '@emotion/react'
import deleteCross from '/src/assets/icons/X Gray.svg'

import moment from 'moment'
import { useAngularServices } from '@/react/components'
import { TextInputStyle } from '@screens/components'

export function SingleSignature({ signatureLink }) {
  const [isDeleted, setIsDeleted] = React.useState(false)
  if (isDeleted) return null

  const { CurrentUser } = useAngularServices()
  const user = CurrentUser.getInstance()

  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )
  const textareaRef = React.useRef(null)

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(rows * lineHeight)
    }
  }

  return (
    <div css={baseStyle}>
      <img src={signatureLink.url} alt="" />
      <textarea
        ref={textareaRef}
        style={{ height: `${textareaHeight}px` }}
        className="form__textarea"
        onInput={adjustTextareaHeight}
        defaultValue={signatureLink.printed_name}
        onChange={(value) => {
          signatureLink.printed_name = value.target.value
          signatureLink.fieldmeta = {
            printed_name: {
              last_updated: moment(new Date()).format(
                'YYYY-MM-DDTHH:mm:ss.SSSZ',
              ),
              last_updated_by_user_id: user.id,
            },
          }
        }}
      />
      <div
        className="delete-button"
        onClick={() => {
          signatureLink.deleted = true
          setIsDeleted(true)
        }}
      >
        <img src={deleteCross} />
      </div>
    </div>
  )
}

const baseStyle = css({
  width: '24%',
  position: 'relative',
  maxHeight: 300,
  marginRight: '1%',
  display: 'flex',
  flexDirection: 'column',
  '.delete-button': {
    cursor: 'pointer',
    position: 'absolute',
    right: 10,
    top: 10,
    borderRadius: '50%',
    backgroundColor: 'rgb(101,101,101)',
    color: 'white',
    width: 25,
    height: 25,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      filter: 'brightness(1.5)',
      width: 15,
      height: 15,
    },
  },
  input: {
    width: '100%',
    ...TextInputStyle,
  },
  '.form__textarea': {
    width: '100%',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    minHeight: '30px',
    maxWidth: '100%',
    resize: 'vertical',
  },
})
