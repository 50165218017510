angular
  .module('ccs')
  .controller(
    'EmployeesAssignCtrl',
    function ($scope, Api, $state, $stateParams, $log) {
      $log.debug('EmployeesAssignCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.trainings = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name'
      $scope.maxSize = 10 //Number of pager buttons to show

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.userID = $stateParams.user
      $scope.userSetID = $stateParams.userSet

      $scope.pageSize = 20

      function getTrainingsListQuery() {
        let q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          user: $scope.userID,
          user_set: $scope.userSetID,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order
        if ($scope.order && $scope.order === 'is_active')
          q.is_active = $scope.reverse ? false : true

        return q
      }

      function getTrainingsList() {
        Api.get(
          `assign_user_set/training_set`,
          getTrainingsListQuery(),
          (resp) => {
            $scope.totalItems = resp.count
            $scope.trainings = resp
          },
        )
      }

      getTrainingsList()

      $scope.toggleAll = () => {
        Api.post(
          `assign_user/toggle_all_trainings/?deleted=False`,
          {
            active: $scope.active,
            user: $scope.userID ? $scope.userID : null,
            user_set: $scope.userSetID ? $scope.userSetID : null,
            search: $scope.search,
          },
          () => {
            getTrainingsList()
          },
        )
      }

      $scope.toggleActive = (training) => {
        Api.JobAssign.post(
          {
            users: $scope.userSetID
              ? $scope.trainings.users
              : [parseInt($scope.userID)], //required
            active: training.assigned, //required,
            trainings: training.trainings ? training.trainings : [training.id], //required
          },
          (resp) => {
            setTimeout(getTrainingsList(), 100)
          },
        )
      }

      $scope.goBack = () => {
        $state.go('trainings.assign.trainings_list', {
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }

      $scope.changePage = function () {
        getTrainingsList()
      }
    },
  )
