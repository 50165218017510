import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { useMemo, useState } from 'react'

export const TrainingSwitch = () => {
  const { stateService } = useRouter()
  const checked =
    stateService.current.name === 'trainings.assign.employee_groups_list'

  const { CurrentUser } = useAngularServices()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const baseStyle = getBaseStyle(variableColor)

  return (
    <div css={baseStyle} className="switch__wrapper">
      <h4 className="page__text">Trainings</h4>
      <label className="switch">
        <input
          className="projectCheckbox"
          type="checkbox"
          checked={checked}
          onChange={() => {
            stateService.go(
              checked
                ? 'trainings.assign.job_trainings_groups_list'
                : 'trainings.assign.employee_groups_list',
            )
          }}
        />
        <span className="slider round" />
      </label>
      <h4 className="page__text">Employees</h4>
    </div>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '.slider': {
      border: `1px solid ${variableColor} !important`,
      backgroundColor: `${variableColor} !important`,
      boxShadow: 0,
    },
    '.switch': {
      margin: 0,
      boxShadow: 0,
    },
    '.page__text': {
      fontSize: '12px',
      fontWeight: 700,
      margin: 0,
    },
  })
