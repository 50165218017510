import React from 'react'
import { css } from '@emotion/react'

interface Props {
  title: string
}

export const SettingTitle: React.FC<Props> = (props) => {
  const { title } = props

  return (
    <div className="title-wrapper" css={baseStyle}>
      <div className="title">{title}</div>
    </div>
  )
}

const baseStyle = css({
  width: '100%',
  borderBottom: '1px solid #C5C5C5',
  marginBottom: '14px',
  '.title': {
    fontSize: '14px',
    fontWeight: 600,
    color: '#666',
  },
})
