import { BinDeleteIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { Field } from 'formik'
import { Draggable } from 'react-beautiful-dnd'
import manageIcon from '@/assets/icons/manage-icon.svg'
import classNames from 'classnames'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { useAngularServices } from '@/react/components'
import { useState } from 'react'
import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'

export const MultiOption = ({
  option,
  fIndex,
  oIndex,
  handleDelete,
  isCorrectAnswer,
  handleCheckboxChange,
}) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)
  return (
    <Draggable key={option.id} draggableId={'' + option.id} index={oIndex}>
      {(provided) => (
        <div
          className="option__wrapper"
          css={baseStyle}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <img
            src={manageIcon}
            alt="manage-icon"
            className="option__manage"
            {...provided.dragHandleProps}
          />
          <label className="checkbox-container">
            <Field
              type="checkbox"
              checked={option?.answer}
              name={`training_fields[${fIndex}].options[${oIndex}].answer`}
              onChange={() => {
                handleCheckboxChange(oIndex)
              }}
            />
            <span
              className={classNames('checkmark', {
                error: !isCorrectAnswer,
              })}
            >
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
          <Field
            type="text"
            as={FormikAutoExpandedTextArea}
            name={`training_fields[${fIndex}].options[${oIndex}].name`}
            className={classNames('option__input', {
              error: !option?.name,
            })}
            value={option?.name}
          />

          <div className="delete__wrapper">
            {isDeleteModalOpen ? (
              <ConfirmationModal
                action={'Delete'}
                handleClick={() => {
                  handleDelete(oIndex)
                  setDeleteModalOpen(false)
                }}
                handleClose={() => {
                  setDeleteModalOpen(false)
                }}
                isScrollDisabled={false}
              />
            ) : null}
            <BinDeleteIcon
              color={variableColor}
              height="22px"
              width="17px"
              onClick={() => {
                setDeleteModalOpen(true)
              }}
            />
          </div>
        </div>
      )}
    </Draggable>
  )
}

const baseStyle = css({
  display: 'flex',
  gap: '10px',
  alignItems: 'flex-start',
  paddingLeft: '76px',
  paddingBottom: '10px',
  '.option__manage': {
    marginTop: '10px',
  },
  '.option__input': {
    ...TextInputStyle,
    minWidth: '599px',
    width: '654px',
    background: '#fff',
    height: '32px',
    fontSize: '12px',

    '::placeholder': {
      color: '#B1B1B1',
    },
  },
  '.weight__input': {
    ...TextInputStyle,
    width: '73px',
    background: '#FAFAFA',
    height: '32px',
    fontSize: '12px',
    textAlign: 'center',

    '::placeholder': {
      color: '#B1B1B1',
    },
  },
  '.delete__wrapper': {
    position: 'relative',
    marginTop: '5px',
  },
})
