import * as React from 'react'
import { default as ReactSelect } from 'react-select'
import {
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@/react/components'
import {
  getStartingValue,
  NoteElement,
} from '@screens/MobileForms/CreateEditPages/components'
import { getTranslatedString } from '@/utils'
import { css } from '@emotion/react'

export const OptionsFields = ({
  fieldData,
  formMemory,
  setFormMemory,
  possibleGroupData,
  onChange,
}) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [defOptionsForMulti, setDefOptionsForMulti] = React.useState(
    getDefOptions(
      getStartingValue(
        possibleGroupData,
        formMemory,
        fieldData,
        'multi_answers',
      ),
      true,
    ),
  )

  const [error, setError] = React.useState(false)

  const optionsById = {}
  const options = fieldData.answer_options.map((option) => {
    if (fieldData.type === 'options_multi') {
      optionsById[option.name] = {
        label: getTranslatedString(option.name),
        value: option.id,
      }
      return { label: getTranslatedString(option.name), value: option.id }
    } else {
      optionsById[option.name] = {
        label: getTranslatedString(option.name),
        value: option.name,
      }
      return { label: getTranslatedString(option.name), value: option.name }
    }
  })

  function onchange(value) {
    if (fieldData.type === 'options_multi') {
      setError(!value.length && fieldData.required)
      multiValueUpdate(
        value,
        possibleGroupData,
        formMemory,
        fieldData,
        setFormMemory,
      )
    } else {
      onChange({
        value: value ? value.value : '',
        possibleGroupData,
        id: fieldData.id,
      })
      setError(!value && fieldData.required)
    }
  }

  async function startingFiles() {
    const startingValue = getStartingValue(
      possibleGroupData,
      formMemory,
      fieldData,
      'multi_answers',
    )
    multiValueUpdate(
      startingValue,
      possibleGroupData,
      formMemory,
      fieldData,
      setFormMemory,
      true,
      setDefOptionsForMulti,
    )
  }

  React.useEffect(() => {
    startingFiles()
  }, [])

  const startingData =
    fieldData.type === 'options_multi'
      ? defOptionsForMulti
      : Object.keys(optionsById).length
      ? optionsById[
          getStartingValue(possibleGroupData, formMemory, fieldData, 'text')
        ]
      : null

  React.useEffect(() => {
    if (fieldData.type === 'options_multi') {
      if (fieldData.required && startingData.length === 0) {
        setError(true)
      }
    } else {
      if (fieldData.required && !startingData) {
        setError(true)
      }
    }
  }, [])

  const baseStyle = getBaseStyle(fieldData.type === 'options_multi')

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="label-holder">
        <label css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      <div className="select-holder">
        <ReactSelect
          options={options}
          onChange={onchange}
          styles={{
            ...dropdownOptionStyles(
              variableColor,
              fieldData.type === 'options_multi' ? 'auto' : '30px',
            ),
            control: (provided, state) => ({
              ...provided,
              border: error && '1px solid #C80404',
            }),
          }}
          isClearable={fieldData.type !== 'options_multi'}
          defaultValue={startingData}
          theme={(theme) => dropdownFiledTheme(theme, variableColor)}
          isMulti={fieldData.type === 'options_multi'}
          closeMenuOnSelect={fieldData.type !== 'options_multi'}
          blurInputOnSelect={fieldData.type !== 'options_multi'}
          noOptionsMessage={() => null}
        />
      </div>
    </section>
  )
}

export function multiValueUpdate(
  value,
  possibleGroupData,
  formMemory,
  fieldData,
  setFormMemory,
  startingSet = false,
) {
  const idsToSet = startingSet
    ? value.map((option) => {
        return option.id
      })
    : value.map((option) => {
        return option.value
      })
  if (possibleGroupData) {
    formMemory[fieldData.id][possibleGroupData]['touched'] = true
    formMemory[fieldData.id][possibleGroupData].localAnswer.multi_answers =
      idsToSet
    setFormMemory(formMemory)
  } else {
    formMemory[fieldData.id]['touched'] = true
    formMemory[fieldData.id].localAnswer.multi_answers = idsToSet
    setFormMemory(formMemory)
  }
}
function getDefOptions(value, startingSet) {
  if (startingSet) {
    const defOptions = value.map((option) => {
      return { label: option.name, value: option.id }
    })
    return defOptions
  }
  return []
}

function getBaseStyle(isMulti) {
  return css({
    marginBottom: 15,
    position: 'static',
    '.select-holder': {
      zIndex: 20,
      position: 'static',
      width: 400,
      maxWidth: '100%',
    },
  })
}
