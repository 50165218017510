import * as React from 'react'
import moment from 'moment'
import { css, SerializedStyles } from '@emotion/react'
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { createTheme } from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { ThemeProvider } from '@mui/material'

type props = {
  possibleVariableColor?: string
  ohChange: (evt?: moment.Moment, value?: moment.Moment) => void
  defDate?: string | moment.Moment
  width?: string
  minDate?: null | moment.Moment
  maxDate?: null | moment.Moment
  error?: boolean
}

export const DateInputField = ({
  possibleVariableColor,
  ohChange,
  defDate = '',
  width = '300px',
  minDate = null,
  maxDate = null,
  error = false,
}: props) => {
  const [date, setDate] = React.useState(
    typeof defDate === 'string' ? moment(defDate) : defDate,
  )
  const [cleared, setCleared] = React.useState<boolean>(false)

  React.useEffect(() => {
    if (cleared) {
      const timeout = setTimeout(() => {
        setCleared(false)
      }, 500)

      return () => clearTimeout(timeout)
    }
    return () => {}
  }, [cleared])

  const defaultTheme = createTheme()

  const newTheme = createTheme({
    ...defaultTheme,
    components: {
      MuiPickersCalendarHeader: {
        styleOverrides: {
          label: {
            fontSize: 15,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            fontSize: 15,
          },
        },
      },
      MuiDayCalendar: {
        styleOverrides: {
          weekDayLabel: {
            fontSize: 15,
          },
        },
      },
    },
  })

  const baseStyle: SerializedStyles = css({
    width: width,
    border: error ? '1px solid #C80404' : '',
    '.MuiInputBase-root': {
      borderRadius: 0,
      height: 34,
      borderColor: '#e5e6e7',
      borderStyle: 'solid',
      borderWidth: 1,
      fontSize: 12,
      '.MuiOutlinedInput-notchedOutline': {
        border: 'none',
      },
    },
  })

  return (
    <div css={baseStyle}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={newTheme}>
          <DesktopDatePicker
            sx={{
              width: '100%',
            }}
            slotProps={{
              field: { clearable: true, onClear: () => setCleared(true) },
              textField: { size: 'small' },
            }}
            value={date}
            onChange={(value) => {
              ohChange(value)
              setDate(date)
            }}
            minDate={minDate}
            maxDate={maxDate}
          />
        </ThemeProvider>
      </LocalizationProvider>
    </div>
  )
}
