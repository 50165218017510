import async from 'async'

angular
  .module('ccs')
  .controller(
    'FileCabinetCtrl',
    function (
      $scope,
      $rootScope,
      Api,
      ngDialog,
      app,
      uiPermissionService,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('FileCabinetCtrl')

      $scope.cabinets = []
      $scope.app = app
      $scope.userIs = CurrentUser.is

      //UI Permissions
      $scope.permissionCreateCabinet =
        uiPermissionService.getPermissionCreateCabinet()
      $scope.permissionCreateCabinetFile =
        uiPermissionService.getPermissionCreateCabinetFile()
      $scope.permissionDeleteCabinet =
        uiPermissionService.getPermissionDeleteCabinet()
      $scope.permissionDeleteCabinetFile =
        uiPermissionService.getPermissionDeleteCabinetFile()
      $scope.permissionEditCabinetFile =
        uiPermissionService.getPermissionEditCabinetFile()

      function getCabinets() {
        $scope.cabinets = []
        Api.Cabinets.get(
          {
            client: CurrentUser.getClientId(),
            page_size: 1000,
            application: app.id,
            order: 'name',
          },
          function (resp) {
            resp.results.forEach(function (c) {
              c.order = 'title'
              c.reverse = false
              c.currentPage = 1

              //begin header construction for sortable table header
              c.headers = [{ key: 'title', display: 'Name', sortable: true }] //for the sortable table header
              if (c.has_person) {
                c.headers.push({
                  key: 'person',
                  display: 'Person',
                  sortable: true,
                })
              }
              if (c.has_contractor) {
                c.headers.push({
                  key: 'contractor',
                  display: 'Contractor',
                  sortable: true,
                })
              }
              if (c.has_expiration_date) {
                c.headers.push({
                  key: 'expiration_date',
                  display: 'Expiration Date',
                  sortable: true,
                })
              }
              if (c.has_project) {
                c.headers.push({
                  key: 'project',
                  display: app.project,
                  sortable: true,
                })
              }
              if ($scope.permissionEditCabinetFile) {
                c.headers.push({
                  key: 'is_active',
                  display: 'Inactive/Active',
                  sortable: true,
                })
              }

              c.headers.push({
                key: 'edit',
                display: 'Edit',
                centered: true,
                hidden: !$scope.permissionEditCabinetFile,
              })
              c.headers.push({
                key: 'download',
                display: 'Download',
                centered: true,
              })
              c.headers.push({ key: 'share', display: 'Share', centered: true })
              c.headers.push({
                key: 'delete',
                display: 'Delete',
                centered: true,
                hidden: !$scope.permissionDeleteCabinetFile,
              })

              //end header construction for the sortable table header

              $scope.cabinets.push(c)
              Api.CabinetFiles.get(
                { cabinet: c.id, order: c.order },
                function (resp) {
                  c.data = {
                    total: resp.count,
                    items: resp.results,
                  }
                  c.query = ''
                  c.changePage = function () {
                    //negate the order to reverse it if reverse is set to true
                    if (c.reverse == true) {
                      var order = '-' + c.order
                    } else {
                      var order = c.order
                    }
                    Api.CabinetFiles.get(
                      {
                        cabinet: c.id,
                        page: c.currentPage,
                        order: order,
                        search_fields: 'name',
                        search: c.executedquery,
                      },
                      function (resp) {
                        c.data = {
                          total: resp.count,
                          items: resp.results,
                        }
                      },
                    )
                  }
                  c.search = function (query) {
                    //when the user clicks search, store the fact that the search has
                    //been executed  as an executedquery.  this prevents a user from
                    //typing a search in the box and then changing pages and having
                    //the query be honored
                    c.executedquery = c.query
                    Api.CabinetFiles.get(
                      {
                        cabinet: c.id,
                        order: c.order,
                        search_fields: 'name',
                        search: c.executedquery,
                      },
                      function (resp) {
                        c.currentPage = 1
                        c.data = {
                          total: resp.count,
                          items: resp.results,
                        }
                      },
                    )
                  }
                },
              )
            })
          },
        )
      }

      getCabinets()

      $scope.addFile = function (_cabinet) {
        ngDialog.open({
          template: 'app/views/cabinet_file_form.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          controller: 'FileCabinetFileCtrl',
          resolve: {
            cabinet: function cabinet() {
              return _cabinet
            },
            file: function file() {
              return null
            },
          },
        })
      }

      $scope.onKeydownSearch = function (event, cabinet) {
        if (!event || !cabinet) {
          return
        }

        var keyCodeEnter = 13
        if (event.keyCode == keyCodeEnter) {
          $scope.onSaveSearch(cabinet)
        }
      }

      $scope.onClearSearch = function (cabinet) {
        if (!cabinet) {
          return
        }

        cabinet.query = ''
        $scope.onSaveSearch(cabinet)
      }

      $scope.onSaveSearch = function (cabinet) {
        if (!cabinet) {
          return
        }

        cabinet.search(cabinet.query)
      }

      $scope.edit = function (data) {
        ngDialog.open({
          template: 'app/views/cabinet_file_form.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          controller: 'FileCabinetFileCtrl',
          resolve: {
            cabinet: function cabinet() {
              return angular.copy(data.cabinet)
            },
            file: function file() {
              return angular.copy(data.file)
            },
          },
        })
      }

      $scope.toggleActive = function (file) {
        Api.CabinetFiles.patch(
          { id: file.id, is_active: file.is_active },
          function (resp) {
            if (!resp) {
              Notification.danger(
                'Unable to toggle File. Please try again later.',
              )
              file.is_active = !file.is_active
            }
          },
        )
      }

      $scope.deleteCabinet = (cabinet) => {
        Api.Cabinets.patch(
          { ...cabinet, deleted: true, is_active: false },
          () => {
            async.each(
              cabinet.data.items,
              (f, cb) => {
                Api.CabinetFiles.patch(
                  {
                    ...f,
                    deleted: true,
                    is_active: false,
                    file: f.file.id,
                    project: f.project ? f.project.id : null,
                  },
                  () => {
                    cb(null)
                  },
                )
              },
              (err) => {
                getCabinets()
              },
            )
          },
        )
      }

      $scope.delete = function (file, cabinet) {
        Api.CabinetFiles.patch(
          { id: file.id, deleted: 'True', is_active: false },
          (resp) => {
            Notification.success('File deleted!')
            cabinet.data.items = cabinet.data.items.filter(function (f) {
              return f.id !== file.id
            })
            --cabinet.data.total
          },
        )
      }

      $scope.add = function () {
        ngDialog.open({
          template: 'app/views/cabinet_form.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.cabinet = {
              has_employee: false,
              has_expiration_date: false,
              application: app.id,
              client: CurrentUser.getClientId(),
            }
            $scope.create = function () {
              Api.Cabinets.post($scope.cabinet, function (resp) {
                if (resp) {
                  resp.data = {
                    items: [],
                    total: 0,
                  }
                  $scope.cabinets.push(resp)
                  $scope.closeThisDialog()
                }
                getCabinets()
              })
            }
          },
        })
      }

      $rootScope.$on('updateCabinetsBroadcast', function (event, data) {
        setTimeout(function () {
          var cabinet = $scope.cabinets.find(
            (cabinet) => cabinet.id === data.cabinet,
          )
          Api.CabinetFiles.get(
            { cabinet: data.cabinet, order: data.order },
            function (resp) {
              cabinet.data = {
                total: resp.count,
                items: resp.results,
              }
            },
          )
          $scope.$apply()
        }, 0)
        // getCabinets();
      })
    },
  )
