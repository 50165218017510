import * as React from 'react'
import { css } from '@emotion/react'
import { parseDate } from '@/utils/parseDate'
import { fullUsername } from '@/utils/fullUsername'
import { ImageSection, deletedFieldsFiltering } from '../../../components'

import { ApplicationConfig, ReportField } from '@/react/types'

type Props = {
  questionFullData: ReportField
  app: ApplicationConfig
}

export function QuestionField({ questionFullData, app }: Props) {
  const {
    question,
    answer: answerBeforeChanges,
    answeredFieldOrder,
  } = questionFullData
  const [answer, setAnswer] = React.useState({})

  React.useEffect(() => {
    deletedFieldsFiltering(answerBeforeChanges, setAnswer)
  }, [])

  if (!answer.answer) return null

  const questionHeaderStyle = css({
    marginTop: 0,
    display: 'inline',
    width:
      answer.answer !== 'n/a' && answer.answer !== 'yes'
        ? 'calc(100% - 150px)'
        : '100%',
    '.question-name': {
      whiteSpace: 'pre',
    },
  })

  const questionHeader = (
    <h4 className="question-header" css={questionHeaderStyle}>
      {answeredFieldOrder}. {question?.project_name || ''} (
      {question.category.name}){' - '}
      <span
        className="danger-html"
        dangerouslySetInnerHTML={{
          __html: question.name,
        }}
      />
    </h4>
  )

  const severityDisplay = {
    1: app.observation_low_severity,
    2: app.observation_medium_severity,
    3: app.observation_high_severity,
  }

  if (answer.answer === 'n/a')
    return (
      <div css={questionStyle}>
        <div className="sub-field severity-sub-field">{questionHeader}</div>
      </div>
    )

  const severityLevelElements = {
    1: (
      <span className="severity-low severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
    2: (
      <span className="severity-med severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
    3: (
      <span className="severity-high severity-text">
        {severityDisplay[answer.severity || 1].toUpperCase()}
      </span>
    ),
  }

  return (
    <div css={questionStyle}>
      <div className="sub-field severity-sub-field">
        {questionHeader}
        {answer.answer !== 'n/a' && answer.answer !== 'yes' ? (
          <div className="severity-block">
            {severityLevelElements[answer.severity || 1]}
          </div>
        ) : null}
      </div>
      <div className="base-for-sub-field">
        {answer.notes.length > 0 ? (
          <div className="sub-field">
            <label className="sub-field-header">
              {app.observation_note_header}:
            </label>
            <div className="multiple-answer-body">
              {Object.values(answer.notes).map((note, idx) => (
                <div className="single-answer" key={idx}>
                  {' '}
                  <span>
                    {' ' +
                      parseDate(note.date_created) +
                      ' - ' +
                      fullUsername(
                        note.user ? note.user : note.external_contact,
                      ) +
                      ' - '}
                  </span>
                  <span className="view__text">{note.text}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        {answer.corrective_actions.length ? (
          <div className="sub-field">
            <label className="sub-field-header">
              {app.observation_corrective_action_header}:{' '}
            </label>
            <div className="multiple-answer-body">
              {Object.values(answer.corrective_actions).map(
                (correctiveAction, idx) => (
                  <div key={idx} className="single-answer">
                    <span>
                      {' ' +
                        parseDate(correctiveAction.date_created) +
                        ' - ' +
                        fullUsername(
                          correctiveAction.user
                            ? correctiveAction.user
                            : correctiveAction.external_contact,
                        ) +
                        ' - '}
                    </span>
                    <span className="view__text">{correctiveAction.text}</span>
                  </div>
                ),
              )}
            </div>
          </div>
        ) : null}
        <div className="sub-field-row">
          {answer.answer_party_observed_answer_list.length ? (
            <div className="sub-field left-field">
              <label className="sub-field-header">
                {app.observation_party_observed}
                {': '}
              </label>
              <div className="multiple-answer-body">
                {answer.answer_party_observed_answer_list.map((po) => {
                  if (!po.deleted) {
                    return (
                      <div className="single-answer">
                        <span> {po.party_observed.name}</span>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          ) : null}
          {answer.reference ? (
            <div className="sub-field">
              <label className="sub-field-header">
                {app.observation_reference}:
              </label>
              <span> {parseDate(answer.date_created)} </span>
              <span className="view__text">{answer.reference}</span>
            </div>
          ) : null}
        </div>
        {answer?.internal_assignees?.length ||
        answer?.external_assignees?.length ? (
          <div className="sub-field sub-field-with-multiples">
            <label>Assigned To: </label>
            <div className="multiple-answer-body">
              {answer.external_assignees.map((assignee, idx) => {
                return (
                  <div key={idx} className="single-answer">
                    <span key={idx} className="date">
                      {parseDate(assignee.date_created)}{' '}
                      {assignee.external_contact.first_name
                        ? assignee.external_contact.first_name + ' '
                        : null}
                      {assignee.external_contact.last_name
                        ? assignee.external_contact.last_name
                        : null}
                      {!assignee.external_contact.first_name &&
                      !assignee.external_contact.last_name &&
                      !assignee.external_contact.company_name
                        ? null
                        : '; '}
                      {assignee.external_contact.company_name}
                      {!assignee.external_contact.first_name &&
                      !assignee.external_contact.last_name &&
                      !assignee.external_contact.company_name
                        ? assignee.external_contact.email
                        : null}
                    </span>
                  </div>
                )
              })}
              {answer.internal_assignees.map((assignee, idx) => {
                return (
                  <div key={idx} className="single-answer">
                    <span key={idx} className="date">
                      {parseDate(assignee.date_created)}{' '}
                      {fullUsername(assignee.full_assignee)}
                      {'; '}
                      {assignee.full_assignee.company_name}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}
      </div>
      {answer.photos[0] ? <ImageSection images={answer.photos} /> : null}
    </div>
  )
}

const questionStyle = css({
  hr: {
    paddingTop: 4,
    paddingBottom: 5,
  },
  '.sub-field-with-multiples': {
    label: {
      verticalAlign: 'top',
    },
  },
  '.multiple-answer-body': {
    display: 'inline-block',
    width: '75%',
    paddingLeft: '5px',
    '.single-answer': {
      width: '100%',
    },
  },
  '.severity-sub-field': {
    display: 'flex',
    fontWeight: 600,
    '.severity-block': {
      display: 'inline-block',
      width: '150px',
      textAlign: 'right',
      verticalAlign: 'top',
    },
  },
  '.sub-field-header': {
    color: 'black !important',
    verticalAlign: 'top',
  },
  '.severity-text': {
    paddingLeft: 0,
  },
  '.view__text': {
    display: 'contents',
    whiteSpace: 'pre-wrap',
  },
  '.sub-field-row': {
    verticalAlign: 'top',
    '.sub-field': {
      verticalAlign: 'top',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
      width: '48%',
      display: 'inline-block',
    },
    '.left-field': {
      marginRight: '4%',
    },
    '@media (max-width: 650px)': {
      '.sub-field': {
        width: '100%',
      },
      '.left-field': {
        marginRight: 0,
      },
    },
  },
  paddingTop: '30px',
  paddingBottom: '30px',
  borderBottomStyle: 'solid',
  borderBottomWidth: '1px',
  borderBottomColor: '#eeeeee',
})
