angular
  .module('ccs')
  .controller(
    'ObservationsDeletedCtrl',
    function (
      $scope,
      $state,
      ngDialog,
      Api,
      app,
      $stateParams,
      $timeout,
      $log,
    ) {
      $log.debug('ObservationsDeletedCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20
      $scope.totalItems = $scope.pageNumber * $scope.pageSize

      $scope.observations = []
      $scope.breadcrumbs = [{ title: app.name }]
      $scope.reverse = true
      $scope.order = 'date_created'
      $scope.app = app
      $scope.isRequestSent = false
      $scope.deletedObservationsCount = 0

      $scope.tableHeaders = [
        { key: 'selected', display: '', sortable: false, hidden: true },
        {
          key: 'project__name',
          display: app.project + ' Name',
          sortable: true,
        },
        { key: 'category__name', display: app.category, sortable: true },
        { key: 'user__first_name', display: 'Full Name', sortable: true },
        { key: 'user__company_name', display: 'Company', sortable: true },
        { key: 'date_created', display: 'Date Observed', sortable: true },
        { key: 'view', display: 'View', centered: true },
        { key: 'restore', display: 'Restore', centered: true },
      ]

      $scope.changePage = function () {
        getDeletedObservations()
        $state.transitionTo(
          'app.observations.deleted_observations',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            table: true,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      function getObservationQuery() {
        let query = {
          deleted: 'True',
          application: app.id,
          page: $scope.pageNumber,
          role: true,
          table: true,
          counter: true,
          search: $scope.searchExecuted ? $scope.search : null,
          tz_offset: new Date().getTimezoneOffset(),
        }

        if ($scope.partnerFilter)
          query.client = $scope.getClientFilterFromPartnerFilter()
        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return query
      }

      $scope.changePage()

      function getDeletedObservations() {
        Api.Observations.get(getObservationQuery(), function (resp) {
          $scope.observations = resp.results.map((observation) => {
            return observation
          })
          $scope.totalItems = resp.count
        })
      }

      $scope.checks = {}

      $scope.showUnsyncError = function () {
        $scope.error =
          'Attention: You may not edit a observation that has not fully synchronized with the server. Please try again later.'
        $timeout(function () {
          $scope.error = null
        }, 3000)
      }

      $scope.restore = function (id) {
        ngDialog.open({
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          template: 'app/views/restore_dialog.html',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.save = function () {
              Api.Observations.patch(
                { id: id, deleted: false },
                function (resp) {
                  if (resp) {
                    getDeletedObservations()
                    $scope.closeThisDialog()
                  }
                },
              )
            }

            $scope.cancel = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.goToObservationsPage = function () {
        $state.go(
          'app.observations.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { reload: 'app.observations.deleted_observations' },
        )
      }
    },
  )
