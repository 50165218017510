import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'BatchReportCtrl',
    function ($scope, Api, app, $stateParams, $state, $log) {
      $log.debug('BatchReportCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.app = app
      $scope.isMapHidden = true
      $scope.observationState = false
      const batch_report = $stateParams.batch_report

      // @TODO: that logical condition is nasty, refactor ASAP
      function isBatchReportSynchronised() {
        Api.BatchReports.byID(batch_report, function (resp) {
          // checking, if answers of observation are not synchronised
          var answers_is_synchronised = true // true, by default
          $scope.synchronised = resp.synchronised && answers_is_synchronised
          $scope.synchronised && ($scope.error = null)
        })
      }

      function waitForBatchReportSync() {
        var interval = setInterval(function () {
          // destroy interval
          if ($scope.destroy) {
            clearInterval(interval)
            return
          }

          if ($scope.synchronised) {
            getBatchReportByID()
            clearInterval(interval)
          } else {
            isBatchReportSynchronised()
          }
        }, 5000)
      }

      $scope.$on('$destroy', function () {
        $scope.destroy = true
      })

      $scope.getDisplayDate = function (date) {
        const d = new Date(date)
        return `${d.getUTCFullYear()}/${d.getMonth() + 1}/${d.getDate()}`
      }

      $scope.questions = []
      $scope.answers = []
      $scope.yesList = []
      $scope.noList = []
      $scope.naList = []
      $scope.prList = []
      $scope.paList = []
      $scope.clsList = []
      $scope.allAnswers = []

      $scope.ANSWERS = [
        { id: 'yes', name: $scope.app.observation_yes },
        { id: 'no', name: $scope.app.observation_follow_up_required },
        { id: 'n/a', name: 'N/A' },
        { id: 'cls', name: $scope.app.observation_issue_resolved },
        { id: 'pr', name: $scope.app.observations_pending_review },
        { id: 'pa', name: $scope.app.observations_pending_approval },
      ]

      $scope.ANSWER_DISPLAY = {
        yes: $scope.app.observation_yes,
        no: $scope.app.observation_follow_up_required,
        'n/a': 'N/A',
        cls: $scope.app.observation_issue_resolved,
        pr: $scope.app.observations_pending_review,
        pa: $scope.app.observations_pending_approval,
      }

      $scope.SEVERITY_DISPLAY = {
        1: $scope.app.observation_low_severity,
        2: $scope.app.observation_medium_severity,
        3: $scope.app.observation_high_severity,
      }

      $scope.SEVERITIES = [
        { id: 1, name: $scope.app.observation_low_severity },
        { id: 2, name: $scope.app.observation_medium_severity },
        { id: 3, name: $scope.app.observation_high_severity },
      ]

      $scope.showActions = function (answer) {
        if (!answer.answer) return false
        return answer.answer !== 'n/a'
      }

      $scope.followUpRequired = (answer) => {
        if (answer.answer === 'yes' || answer.answer === 'n/a') return false
        return !!answer.answer
      }

      function getBatchReportByID() {
        Api.BatchReports.byID(batch_report, function (resp) {
          // checking, if answers of observation are not synchronised
          var answers_is_synchronised = true // true, by default
          if (resp.message) {
            $scope.error = resp.message
            return
          }
          // if batch report or answers of observation are not synchronised. Run the interval
          $scope.synchronised = !!(resp.synchronised && answers_is_synchronised)
          $scope.error = !$scope.synchronised
            ? 'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.'
            : null
          $scope.synchronised === false && waitForBatchReportSync()

          $scope.observation = resp
          $scope.observation.general_observation_notes = []
          $scope.app = resp.application
          $scope.app.observation = pluralize.singular(
            $scope.app.observations_display,
          )
          $scope.app.category = pluralize.singular(
            $scope.app.observations_categories,
          )
          $scope.app.project = pluralize.singular($scope.app.projects_display)

          $scope.report_settings = resp.client.report_settings
          $scope.currentTheme = $scope.report_settings.theme
          $scope.observation.observations_detail.map((observation_item) => {
            observation_item.observation_notes.map((observation_note) => {
              observation_note.category_name = observation_item.category.name
            })

            $scope.observation.general_observation_notes =
              $scope.observation.general_observation_notes.concat(
                observation_item.observation_notes,
              )
          })

          $scope.observation.observation_notes =
            $scope.observation.general_observation_notes

          resp.questions.map((q) => {
            if (q.answers && q.answers.length > 0) {
              q.answers.map((a) => {
                if (!a.copy_sequence_number) {
                  q.answer = a
                  $scope.questions.push(q)
                }
                if (a.copy_sequence_number) {
                  let copy_question = angular.copy(q)
                  copy_question.answer = a
                  $scope.questions.push(copy_question)
                }
              })
            } else {
              q.answer = {
                answer: 'n/a',
                corrective_actions: [],
                notes: [],
                photos: [],
                order: q.order,
              }
              $scope.questions.push(q)
            }
          })
          $scope.questions.map((q) => {
            if (q.answer?.answer) {
              q.answer.order = q.order
              if (q.answer.answer === 'yes') {
                $scope.yesList.push(q)
              } else if (q.answer.answer === 'no') {
                $scope.noList.push(q)
              } else if (q.answer.answer === 'cls') {
                $scope.clsList.push(q)
              } else if (q.answer.answer === 'pa') {
                $scope.paList.push(q)
              } else if (q.answer.answer === 'pr') {
                $scope.prList.push(q)
              } else if (q.answer.answer === 'n/a') {
                $scope.naList.push(q)
              }

              const lettersArray = [
                'A',
                'B',
                'C',
                'D',
                'E',
                'F',
                'G',
                'H',
                'I',
                'J',
                'K',
                'L',
                'M',
                'N',
                'P',
                'Q',
                'R',
                'S',
                'T',
                'U',
                'V',
                'W',
                'X',
                'Y',
                'Z',
              ]
              let lettersCount = 0

              for (let i = 0; i < q.answer.photos.length; i++) {
                if (q.answer.photos[i].deleted) {
                  lettersCount = lettersCount > 0 ? lettersCount - 1 : 0
                  continue
                }
                q.answer.photos[i].lettersCount = lettersCount
                q.answer.photos[i].letter = lettersArray[lettersCount]
                lettersCount++
              }
              for (let i = 0; i < q.answer.photos.length; i++) {
                if (q.answer.photos[i].deleted) {
                  q.answer.photos.splice(i, 1)
                }
              }

              const all_assignees = []

              if (q.answer.external_assignees) {
                q.answer.external_assignees.forEach(function (b) {
                  const first = b.external_contact.first_name || ''
                  const last = b.external_contact.last_name || ''
                  const assignee = {
                    date_created: b.date_created,
                    company_name: b.external_contact.company_name,
                    full_name: first + ' ' + last,
                    email: b.external_contact.email,
                  }
                  if (!b.deleted) {
                    all_assignees.push(assignee)
                  }
                })
              }

              if (q.answer.internal_assignees) {
                q.answer.internal_assignees.forEach(function (b) {
                  var full_name =
                    b.full_assignee.first_name + ' ' + b.full_assignee.last_name
                  var assignee = {
                    date_created: b.date_created,
                    company_name: b.full_assignee.company_name,
                    full_name: full_name,
                    email: '',
                  }
                  if (!b.deleted) {
                    all_assignees.push(assignee)
                  }
                })
              }

              //https://stackoverflow.com/questions/10123953/how-to-sort-an-array-by-a-date-property
              all_assignees.sort(
                (a, b) => new Date(b.date_created) - new Date(a.date_created),
              )

              q.answer.all_assignees = all_assignees
            } else {
              q.answer = {
                answer: 'n/a',
                corrective_actions: [],
                notes: [],
                photos: [],
                order: q.order,
              }
              $scope.naList.push(q)
            }

            return q
          })
          $scope.questions = $scope.questions.sort(orderByCopySequenceNumber)

          // allAnswers is for template 4 only.
          if ($scope.report_settings.view_na_questions === true) {
            $scope.allAnswers = $scope.questions
          } else {
            $scope.allAnswers = $scope.questions.filter(
              (q) => q.answer.answer != 'n/a',
            )
          }

          var compareDateUpdated = function (qOne, qTwo) {
            if (qOne.date_updated < qTwo.date_updated) {
              return -1
            }
            if (qOne.date_updated > qTwo.date_updated) {
              return 1
            }
            return 0
          }

          var compareCategoryId = function (qOne, qTwo) {
            if (qOne.category.id < qTwo.category.id) {
              return -1
            }
            if (qOne.category.id > qTwo.category.id) {
              return 1
            }
            return 0
          }

          $scope.allAnswers = $scope.allAnswers.sort(compareCategoryId)
          $scope.answersByCategories = {}
          const categories = [
            ...new Set($scope.allAnswers.map((item) => item.category.id)),
          ]

          categories.forEach(function (category) {
            $scope.answersByCategories[category] = $scope.allAnswers
              .filter((item) => item.category.id === category)
              .sort((a, b) => a.observation - b.observation)
          })

          $scope.allAnswers.map(function (item) {
            if (item.answer.photos) {
              for (var j = 0; j < item.answer.photos.length; j++) {
                if (item.answer.photos[j].lat && item.answer.photos[j].lon) {
                  $scope.isMapHidden = false
                }
              }
            }
          })

          for (var i = 0; i < categories.length; i++) {
            i === 0 &&
              ($scope.answersByCategories[categories[i]].indexStart = 0)
            if (i > 0) {
              $scope.answersByCategories[categories[i]].indexStart =
                $scope.answersByCategories[categories[i - 1]].length +
                $scope.answersByCategories[categories[i - 1]].indexStart
            }
          }

          $scope.noList = $scope.noList.sort(compareDateUpdated)
          $scope.prList = $scope.prList.sort(compareDateUpdated)
          $scope.paList = $scope.paList.sort(compareDateUpdated)
          $scope.clsList = $scope.clsList.sort(compareDateUpdated)
          $scope.yesList = $scope.yesList.sort(compareDateUpdated)
          $scope.naList = $scope.naList.sort(compareDateUpdated)
        })
      }

      getBatchReportByID()

      $scope.goBack = function () {
        $state.go(
          'app.batch_reports.list',
          {
            app: app.id,
            pageNumber: $scope.previousPageNumber,
            reverse: $scope.previousReverse,
            order: $scope.previousOrder,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }

      $scope.print = function () {
        window.print()
      }
    },
  )

function orderByCopySequenceNumber(qOne, qTwo) {
  if (qOne.id === qTwo.id) {
    if (qOne.answer.copy_sequence_number < qTwo.answer.copy_sequence_number) {
      return -1
    }
    if (qOne.answer.copy_sequence_number > qTwo.answer.copy_sequence_number) {
      return 1
    }
    return 0
  }
}
