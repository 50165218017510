import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'
import { useAngularServices } from '@components/ReactAngularContext'
import { CheckIcon } from '@/react/componentAssets'
import { useState } from 'react'

type Props = {
  onChange?: (additionalParams?: any, value?: boolean) => void
  isDefaultChecked: boolean
  additionalStyle?: SerializedStyles
  additionalParams?: any
  isFake?: boolean
  checkedForFake?: boolean
  color?: string
  isRound?: boolean
  reversedColor?: boolean
}

//Todo change props and async logic (reload)
export const CustomCheckbox: React.FC<Props> = ({
  onChange = () => {},
  isDefaultChecked,
  additionalStyle,
  additionalParams,
  isFake = false,
  color,
  isRound = false,
  reversedColor = false,
}: Props) => {
  const { CurrentUser } = useAngularServices()
  const [reload, setReload] = useState(false)

  const variableColor: string =
    CurrentUser.getClientSettings().web_primary_color

  const baseStyle = getBaseStyle(color ? color : variableColor, reversedColor)

  React.useEffect(() => {
    setReload(true)
  }, [isDefaultChecked])

  if (reload) {
    setTimeout(() => {
      setReload(false)
    })
    return null
  }

  return (
    <label css={[baseStyle, additionalStyle]}>
      {!isFake ? (
        <input
          type="checkbox"
          defaultChecked={isDefaultChecked}
          onChange={(value) => {
            onChange(additionalParams, value.target.checked)
          }}
        />
      ) : (
        <input
          type="checkbox"
          checked={isDefaultChecked}
          onChange={() => null}
        />
      )}
      {!isRound ? (
        <span className="checkmark">
          <CheckIcon
            height={reversedColor ? '14px' : '20px'}
            color={reversedColor ? 'white' : variableColor}
          />
        </span>
      ) : (
        <span className="checkmark-round">
          <div className="round-check" />
        </span>
      )}
    </label>
  )
}

function getBaseStyle(
  variableColor: string,
  reversedColor: boolean = false,
): SerializedStyles {
  return css({
    margin: 0,
    marginRight: '10px',
    display: 'block',
    position: 'relative',
    cursor: 'pointer',
    height: 20,
    width: 20,
    borderRadius: 0,
    WebkitUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
    userSelect: 'none',
    input: {
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      height: 0,
      width: 0,
    },
    '.checkmark': {
      height: '20px',
      width: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#FFF',
      svg: {
        width: '70%',
        display: 'none',
      },
    },
    ':hover input ~ .checkmark': {
      backgroundColor: '##E6E6E6',
    },
    'input:checked ~ .checkmark': {
      backgroundColor: reversedColor ? variableColor : '#E6E6E6',
      svg: {
        height: reversedColor ? '14px' : '22px',
        width: reversedColor ? '14px' : '22px',
        display: 'block',
      },
    },
    '.checkmark-round': {
      borderRadius: '50%',
      height: '100%',
      width: '20px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'solid 1px #B1B1B1',
      backgroundColor: '#E6E6E6',
      '.round-check': {
        width: '60%',
        height: '60%',
        borderRadius: '50%',
        display: 'none',
        backgroundColor: variableColor,
      },
    },
    ':hover input ~ .checkmark-round': {
      backgroundColor: '#FAFAFA',
    },
    'input:checked ~ .checkmark-round': {
      '.round-check': {
        display: 'block',
      },
    },
  })
}
