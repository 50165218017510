import * as React from 'react'
import { css } from '@emotion/react'
import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

import uploadIcon from '/src/assets/icons/svg-gray-icons/upload-icon-gray.svg'
import closeIcon from '/src/assets/icons/X Gray.svg'

type Props = {
  dropMessage: ReactJSXElement
  hoverMessage: ReactJSXElement
  addCallback?: any
  removeCallback?: any
  fileStorage: any
  fileSetStorage: any
  additionalStyles?: object
  accept?: string
  imageDisplay?: boolean
}

export const DropZone = ({
  dropMessage = <p />,
  hoverMessage = <p />,
  addCallback,
  removeCallback,
  fileStorage,
  fileSetStorage,
  additionalStyles = {},
  accept = 'text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  imageDisplay = false,
}: Props) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    addCallback()
    fileSetStorage(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept,
  })

  function deleteFile() {
    removeCallback()
    fileSetStorage(null)
  }

  return (
    <div css={[fileUploadBase, additionalStyles]}>
      <div className="border-spread">
        <div css={fileDisplayBase()}>
          {fileStorage ? (
            imageDisplay ? (
              <div className={'file-display'} css={fileDisplay(true)}>
                <div
                  className="single-alert-row"
                  css={css({
                    justifyContent: 'right',
                  })}
                >
                  <img
                    src={closeIcon}
                    alt="close"
                    css={css({
                      height: 10,
                      cursor: 'pointer',
                    })}
                    onClick={() => {
                      deleteFile()
                    }}
                  />
                </div>
                <div className="image-holder">
                  <img
                    src={fileStorage.image || URL.createObjectURL(fileStorage)}
                  ></img>
                </div>
              </div>
            ) : (
              <div className={'file-display'} css={fileDisplay()}>
                <div className="file-name">{fileStorage.name}</div>
                <div
                  className="delete-button"
                  onClick={() => {
                    deleteFile()
                  }}
                >
                  Remove File
                </div>
              </div>
            )
          ) : null}
        </div>
        <form
          encType="multipart/form-data"
          css={dropzoneBase}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {fileStorage ? (
            ''
          ) : (
            <div className={'prop-name'}>
              <img src={uploadIcon} alt="UPLOAD" className="upload-icon" />
              {isDragActive ? dropMessage : hoverMessage}
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const fileUploadBase = css({
  marginTop: '20px',
  width: '98%',
  backgroundColor: '#F7F7F7',
  borderColor: 'rgba(229,230,231,0.5)',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '0',
  '.border-spread': {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
})

const fileDisplayBase = () =>
  css({
    position: 'absolute',
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    left: 0,
  })

const dropzoneBase = css({
  zIndex: 100,
  width: '100%',
  height: '100%',
  backgroundImage: 'none',
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  borderRadius: '0',
  '.prop-name': {
    width: '60%',
    textAlign: 'center',
    fontSize: '30px',
    '.upload-icon': {
      width: '30%',
    },
  },
})

const fileDisplay = (image: boolean = false) => {
  return css({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    zIndex: 200,
    width: image ? 200 : 140,
    position: 'absolute',
    backgroundColor: 'white',
    borderColor: '#e5e6e7',
    borderStyle: 'solid',
    borderWidth: '1px',
    padding: '10px',
    '.single-alert-row': {
      width: '100%',
      display: 'flex',
    },
    '.image-holder': {
      paddingLeft: 15,
      paddingRight: 15,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      maxHeight: 85,
      img: {
        maxWidth: '100%',
        maxHeight: 85,
      },
    },
    '.file-name': {
      padding: '5px',
      backgroundColor: '#e5e6e7',
      width: '100%',
      height: '75%',
    },
    '.delete-button': {
      cursor: 'pointer',
      borderRadius: '2px',
      border: '1px solid #eee',
      textDecoration: 'none',
      display: 'block',
      padding: '4px 5px',
      textAlign: 'center',
      color: '#aaa',
    },
    '.delete-button:hover': {
      color: '#676a6c',
    },
  })
}
