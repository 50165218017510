import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'ToolboxViewCtrl',
    function (
      $scope,
      $stateParams,
      $uibModal,
      Api,
      app,
      $state,
      PreviousState,
      $log,
    ) {
      $log.debug('ToolboxViewCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse == 'true'
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.prevousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.toolboxTitle = pluralize.singular(app.toolboxes)

      $scope.breadcrumbs = [
        { title: app.toolboxes },
        { title: app.name },
        {
          title: 'View ' + app.toolboxes,
          url: 'app.toolboxes.list({app: ' + app.id + ' })',
        },
      ]
      $scope.app = app
      $scope.toolbox = []

      $scope.print = function () {
        window.print()
      }

      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo

        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      $scope.removePhoto = function (deadPhoto) {
        // Remove the photo
        $scope.toolbox.photos = $scope.toolbox.photos.filter((photo) => {
          return photo.id !== deadPhoto.id
        })

        // Patch the toolbox
        Api.ToolboxTalks.patch(
          {
            id: $scope.toolbox.id,
            photos: $scope.toolbox.photos.map((photo) => photo.id),
          },
          (resp) => {},
        )

        // Erase the photo (currently not implemented in API)
      }

      $scope.addPhoto = function (input) {
        // Upload the image, add it to the photos for the toolbox talk, update the API with new photo list
        Api.uploadImage(input.files[0], {}, function (response) {
          $scope.toolbox.photos.push(response)

          Api.ToolboxTalks.patch(
            {
              id: $scope.toolbox.id,
              photos: $scope.toolbox.photos.map((photo) => photo.id),
            },
            (resp) => {},
          )

          input.value = null
        })
      }

      $scope.viewSignature = function (signature) {
        if (signature.isEditing) {
          return
        }

        var $scp = this.$new(true)
        $scp.signature = signature

        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_signature.html',
          size: 'var',
          scope: $scp,
        })
      }

      $scope.updateSignatureName = function (signature, evt) {
        evt.preventDefault()
        evt.stopPropagation()

        // Patch the toolbox
        Api.Signatures.patch(
          {
            id: signature.id,
            printed_name: signature.printed_name,
          },
          (resp) => {},
        )

        // Stop the editing
        signature.isEditing = false
      }

      $scope.editSignatureName = function (signature, evt) {
        signature.isEditing = true
        $scope.editingSignatureWithName = signature.printed_name
      }

      $scope.cancelSignatureNameEdit = function (signature, evt) {
        evt.preventDefault()
        evt.stopPropagation()

        signature.printed_name = $scope.editingSignatureWithName
        $scope.editingSignatureWithName = ''
        signature.isEditing = false
      }

      $scope.removeSignature = function (deadSignature) {
        // Remove the signature
        $scope.toolbox.signatures = $scope.toolbox.signatures.filter(
          (signature) => {
            return signature.id !== deadSignature.signature
          },
        )

        // Patch the toolbox
        Api.ToolboxTalks.patch(
          {
            id: $scope.toolbox.id,
            signatures: $scope.toolbox.signatures.map(
              (signature) => signature.id,
            ),
          },
          (resp) => {},
        )

        // Erase the signature (currently not implemented in API)
      }

      function isToolboxSynchronised() {
        Api.ToolboxTalks.byID($stateParams.toolbox, function (resp) {
          $scope.synchronised = resp.synchronised
          $scope.synchronised && ($scope.error = null)
        })
      }

      function waitForToolboxSync() {
        var interval = setInterval(function () {
          // destroy interval
          if ($scope.destroy) {
            clearInterval(interval)
            return
          }

          if ($scope.synchronised) {
            getToolboxTalksDataById()
            clearInterval(interval)
          } else {
            isToolboxSynchronised()
          }
        }, 1000)
      }

      $scope.$on('$destroy', function () {
        $scope.destroy = true
      })

      function getToolboxTalksDataById() {
        Api.ToolboxTalks.byIDWithoutDeletedParam(
          $stateParams.toolbox,
          function (resp) {
            $scope.toolbox = resp
            $scope.toolbox.signatures.map((signature) => {
              signature.isEditing = false
              return signature
            })

            $scope.synchronised = resp.synchronised
            $scope.error = !$scope.synchronised
              ? 'Attention: All items have not completely synced from the mobile app. There may be missing information on this page.'
              : null
            $scope.synchronised === false && waitForToolboxSync()

            Api.ToolboxTopics.byIDWithoutDeletedParam(
              $scope.toolbox.topic,
              function (resp) {
                $scope.toolbox.topic = resp
              },
            )

            Api.Projects.byID($scope.toolbox.project, function (resp) {
              $scope.toolbox.project = resp
            })

            Api.Users.byID($scope.toolbox.user, function (resp) {
              $scope.toolbox.user = resp
            })
          },
        )
      }

      getToolboxTalksDataById()

      Api.GeneralSettings.get({}, function (resp) {
        if (resp.results.length) {
          $scope.settings = resp.results[0]
        }
      })

      $scope.goBack = function () {
        const newState =
          PreviousState.Name == '' || PreviousState.Name == 'app.toolboxes.list'
            ? 'app.toolboxes.list'
            : 'app.toolboxes.deleted'

        $state.go(
          newState,
          {
            app: app.id,
            pageNumber: $scope.previousPageNumber,
            reverse: $scope.previousReverse,
            order: $scope.previousOrder,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }
    },
  )
