import * as React from 'react'
import { css } from '@emotion/react'

import paperClip from '/src/assets/icons/Icons_Paperclip.svg'

import { useAngularServices } from '@/react/components'
import { PopUp, VariousTable } from '@screens/SafetyDataSheets/components'
import { DownloadIcon, RenewIcon } from '@/react/componentAssets'

export function ArchiveTableRow({ result }: any) {
  const { Api } = useAngularServices()
  const [isRowVisible, setIsRowVisible] = React.useState(true)
  const [activePopup, setActivePopup] = React.useState(false)

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  async function unArchiveSDS(id: string) {
    try {
      await Api.SDSArchiveAction(id, { archive: false })
    } finally {
      setIsRowVisible(false)
    }
  }

  let fileElement = (
    <td className={'subdownload centerIcons'}>
      <div css={centerTDItems}>
        <DownloadIcon height={'20px'} color={'gray'} />
      </div>
    </td>
  )

  if (result.pdf) {
    fileElement = (
      <td className={'subdownload centerIcons'}>
        <div css={centerTDItems}>
          <a href={result.pdf.file}>
            <DownloadIcon height={'20px'} color={variableColor} />
          </a>
        </div>
      </td>
    )
  }

  if (isRowVisible) {
    return (
      <tr key={result.id} id={result.id} css={rowHeader}>
        <td className={'subnameColumn startingColumn'} css={nameField}>
          {result.pdf && (
            <span className={'pin-place ' + (result.pdf ? 'active-pin' : '')} />
          )}
          {nameCropper(result.name)}
        </td>
        <td className={'submanufacturer'}>{result.manufacturer}</td>
        <td className={'subissue'}>{result.issue_date}</td>
        <td className={'subreview'}>{result.review_date}</td>
        <td className={'subhazard centerText'}>
          <VariousTable element={result.hazards} />
        </td>
        <td className={'subtoxic centerText'}>{result.toxic_chemical}</td>
        <td className={'subphysicalHazard centerText'}>
          <VariousTable element={result.whmis_physical_hazards} />
        </td>
        <td className={'subhealthHazard centerText'}>
          <VariousTable element={result.whmis_health_hazards} />
        </td>
        <td className={'subhealth centerText'}>
          {result.health ? result.health.charAt(0) : ''}
        </td>
        <td className={'subflammability centerText'}>
          {result.flammability ? result.flammability.charAt(0) : ''}
        </td>
        <td className={'subPH centerText'}>
          {result.physical_hazards ? result.physical_hazards.charAt(0) : ''}
        </td>
        <td className={'subPPE centerText'}>
          {result.ppe ? result.ppe.charAt(0) : ''}
        </td>
        <td className={'subEditDate centerText'}>
          {new Date(result.date_updated).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })}
        </td>
        <td className="subNote">{result.archive_notes || ''}</td>
        {fileElement}
        <td className={'subarchive'}>
          <div css={centerTDItems}>
            <RenewIcon
              height={'20px'}
              color={variableColor}
              onClick={() => setActivePopup(true)}
            />
          </div>
        </td>
        <td>
          {activePopup ? (
            <PopUp
              functionToTrigger={unArchiveSDS}
              id={result.id}
              setState={setActivePopup}
              popUpText={'Are you sure you want to restore this item?'}
              popUpHeader={'RESTORE CONFIRMATION'}
            />
          ) : (
            ''
          )}
        </td>
      </tr>
    )
  } else {
    return <tr css={hiddenTable}></tr>
  }
}

function nameCropper(name: string) {
  if (name.length <= 30) return name
  const [firstWord, secondWord] = name.split(' ')
  if (!secondWord) return name.slice(30) + '...'

  const trimmedFirstLine =
    firstWord.length > 30 ? firstWord.slice(30) + '... ' : firstWord + ' '
  const trimmedSecondLine =
    secondWord.length > 30 ? secondWord.slice(30) + '...' : secondWord

  return (
    <span>
      {trimmedFirstLine}
      <br />
      {trimmedSecondLine}
    </span>
  )
}

const hiddenTable = css({
  display: 'none',
})
const rowHeader = css({
  borderTop: '1px solid rgb(229, 230, 231)',
  borderBottom: '1px solid rgb(229, 230, 231)',
  '.subnameColumn': {
    borderRightStyle: 'solid',
    borderColor: '#e7eaec',
  },
  '.centerText': {
    textAlign: 'center',
  },
})
const centerTDItems = css({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
})
const popUpBase = css({
  width: 0,
})
const nameField = css({
  display: 'flex',
  alignItems: 'center',
  '.pin-place': {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  '.active-pin': {
    backgroundImage: 'url(' + paperClip + ')',
  },
})
