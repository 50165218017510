import * as React from 'react'
import { DateInputField } from '@/react/components'
import moment from 'moment'
import { getTranslatedString } from '@/utils'
import { NoteElement } from '@screens/MobileForms/CreateEditPages/components'
import { css } from '@emotion/react'

export const DateField = ({
  fieldData,
  possibleGroupData,
  onChange,
  formMemory,
}) => {
  const [error, setError] = React.useState<boolean>(false)
  const [date, setDate] = React.useState<string | null>(
    formMemory[fieldData.id]?.localAnswer?.date
      ? moment(formMemory[fieldData.id].localAnswer.date, 'MM/DD/yyyy').format(
          'MM/DD/yyyy',
        )
      : formMemory[fieldData.id][possibleGroupData]?.localAnswer?.date
      ? formMemory[fieldData.id][possibleGroupData]?.localAnswer?.date
      : '',
  )

  React.useEffect(() => {
    if ((date === '' || date === '' || date === null) && fieldData.required) {
      setError(true)
    }
  }, [])

  React.useEffect(() => {
    setError(date === '' && fieldData.required)
    onChange({
      value:
        date === '' || date === null
          ? null
          : moment(date, ['yyyy-MM-DD', 'MM/DD/yyyy']).format('MM/DD/yyyy'),
      possibleGroupData,
      id: fieldData.id,
      fieldType: 'date',
    })
  }, [date])

  const baseStyle = getBaseStyle()

  function getBaseStyle() {
    return css({
      marginBottom: 15,
      input: {
        border: error ? '1px solid #C80404' : '',
      },
    })
  }

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="label-holder">
        <label css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      <DateInputField
        possibleVariableColor={error ? 'red' : null}
        defDate={date}
        ohChange={(value) => {
          setDate(value)
        }}
      />
    </section>
  )
}
