import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { UISref } from '@/react/components/UISref'
import classNames from 'classnames'
import moment from 'moment'
import React, { useState } from 'react'

export const DeletedRow = ({
  index,
  item,
  selectedIds,
  handleChecked,
  variableColor,
  isAllChecked,
}) => {
  const { topic, user, project, date_created } = item

  const dateCompleted = moment(date_created).format('MM/DD/YYYY')
  const [hoveredField, setHoveredField] = useState(null)

  return (
    <>
      <tr
        className={classNames('table__row', {
          dark: index % 2 === 0,
        })}
        key={item.id}
      >
        <td className="checkbox__td">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={selectedIds.includes(item.id)}
              onChange={() => {
                handleChecked(item)
              }}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
        </td>
        <UISref
          to={'app.toolboxes.view'}
          params={{
            toolbox: item.id,
            previousSearch: 'fromDeleted',
          }}
        >
          <td
            className="topic__td link__cell"
            onMouseOver={() => {
              setHoveredField({
                field__name: 'topic__fullname',
                text: topic.name,
                max__length: 48,
              })
            }}
            onMouseOut={() => {
              setHoveredField(null)
            }}
          >
            <p className="text__styles topic">{topic.name}</p>
          </td>
        </UISref>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name__fullname',
              text: `${user?.first_name} ${user?.last_name}`,
              max__length: 30,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="text__styles name">{`${user?.first_name} ${user?.last_name}`}</p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company__fullname',
              text: user.company_name,
              max__length: 29,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="text__styles company">{user.company_name}</p>
        </td>
        <td
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project__fullname',
              text: project.name,
              max__length: 33,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <p className="text__styles project">{project.name}</p>
        </td>
        <td>{dateCompleted}</td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
