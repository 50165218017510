angular
  .module('ccs')
  .directive('validatetomaximumcharacterlimit', validateToMaximumCharacterLimit)

function isNormalInteger(str) {
  return /^\+?(0|[1-9]\d*)$/.test(str)
}

function validateToMaximumCharacterLimit() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      maximum: '@',
      minimum: '@',
      zipValue: '@',
    },
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          if (scope.zipValue) {
            if (isNormalInteger(text) && parseInt(text) > 2147483647) {
              element.addClass('border-red')
              element.style.border = '1px solid red !important'
            } else {
              element.removeClass('border-red')
              element.style.borderColor = null
            }
          }
          if (scope.maximum && scope.minimum) {
            if (text.length > scope.maximum || text.length < scope.minimum) {
              element.addClass('border-red')
            } else {
              element.removeClass('border-red')
            }
          } else if (scope.maximum) {
            if (text.length > scope.maximum) {
              element.addClass('border-red')
            } else {
              element.removeClass('border-red')
            }
          } else if (scope.minimum) {
            if (text.length < scope.minimum) {
              element.addClass('border-red')
            } else {
              element.removeClass('border-red')
            }
          }
          ngModelCtrl.$setViewValue(text.toString())
          ngModelCtrl.$render()
          return text
        }

        element.removeClass('border-red')
        return undefined
      }

      element.bind('blur', function () {
        setTimeout(function () {
          scope.$apply(attr)
        }, 0)
      })
      ngModelCtrl.$parsers.push(fromUser)
    },
  }
}
