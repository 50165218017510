import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { useEffect, useState } from 'react'
import Linkify from 'react-linkify'

export const ShareCompanyNews = () => {
  const { Api, Notification } = useAngularServices()

  const { stateService } = useRouter()
  const [information, setInformation] = useState(null)
  const [isInactive, setInactive] = useState(false)
  const [errorInfo, setErrorInfo] = useState(null)

  const linkifyDecorator = (href, text, key) => (
    <a href={href} key={key} target="_blank" rel="noopener noreferrer">
      {text}
    </a>
  )

  useEffect(() => {
    const getNewsInfo = async () => {
      try {
        const key = stateService?.params?.key
        const { data } = await Api.get(`shares/news/${key}`, {
          deleted: false,
        })

        setInformation(data)
      } catch (error) {
        setInactive(true)
        Notification.clearNotification()

        setErrorInfo(error?.data)
        const divElement = document.querySelector('div.ng-scope')

        if (divElement) {
          divElement.style.backgroundColor =
            error?.data?.client_settings?.general_settings?.colors
              ?.secondary_color || '#3980CE'
          divElement.style.height = '100vh'
        }
      }
    }

    getNewsInfo()
  }, [])

  return (
    <div css={styles}>
      {!isInactive ? (
        <section className="news__section">
          <div className="header__wrapper">
            <h2 className="header">
              {information?.client?.id
                ? 'Company'
                : information?.application_name}{' '}
              News
            </h2>
          </div>
          <main className="main__info">
            {information?.images?.map((img) => (
              <div className="image__wrapper">
                <img className="image" src={img?.image} alt="" />
              </div>
            ))}

            <h3 className="title">{information?.title}</h3>
          </main>
          <p className="description">
            <Linkify componentDecorator={linkifyDecorator}>
              {information?.description}
            </Linkify>
          </p>
        </section>
      ) : (
        <div className="delete__wrapper">
          <p className="delete__message">{errorInfo?.error_message}</p>
        </div>
      )}
    </div>
  )
}

const styles = css({
  paddingBottom: '150px',
  '.news__section': {
    background: '#fff',
    color: 'black',
    borderRadius: '10px',
  },
  '.delete__wrapper': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    borderRadius: '10px',
    height: '244px',
    gap: '50px',
  },
  '.delete__message': {
    color: '#717070',
    textAlign: 'center',
    fontSize: '16px',
    fontWeight: 400,
    paddingInline: '70px',
  },
  '.header__wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '25px',
    paddingBottom: '25px',
    borderBottom: '1px solid #EEEFF3',
    width: '100%',
  },
  '.header': {
    fontWeight: 300,
    margin: 0,
    fontSize: '32px',
    color: '#717070',
  },
  '.main__info': {
    paddingTop: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  '.image__wrapper': {
    display: 'flex',
    justifyContent: 'center',
  },
  '.image': {
    maxHeight: '300px',
    maxWidth: '600px',
  },
  '.title': {
    fontSize: '24px',
    fontWeight: 300,
    color: '#717070',
    margin: 0,
    textAlign: 'center',
  },
  '.description': {
    marginTop: '40px',
    paddingBottom: '40px',
    paddingInline: '48px',
    fontSize: '16px',
    fontWeight: 400,
    color: '#717070',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  '@media(max-width: 600px)': {
    '.header__wrapper': {
      paddingBottom: '30px',
    },
    '.header': {
      fontSize: '24px',
      paddingInline: '15px',
    },
    '.title': {
      fontSize: '20px',
      paddingInline: '15px',
    },
    '.main__info': {
      paddingTop: '25px',
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    '.description': {
      fontSize: '14px',
      paddingInline: '15px',
    },
    '.image': {
      maxWidth: '300px',
    },
  },
})
