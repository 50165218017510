import { CommonPageHeader, PageSwitcher, Search } from '@/react/components'
import { css } from '@emotion/react'
import React, { useState } from 'react'
import { MobileFormsNavBar } from '../commonComponents/MobileFormNavBar'
import { DraftTable } from './DraftTable'
import { useRouter } from '@/react/hooks'

export const DraftListBase = () => {
  const { stateService } = useRouter()
  const [search, setSearch] = useState(stateService.params.search)

  const [page, setPage] = useState(1)
  const [listCount, setListCount] = useState(20)

  const PAGE_LENGTH = 20

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Mobile Form Reports'} />
      <MobileFormsNavBar />
      <div className="draft__wrapper">
        <div className="search__block">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
          />
        </div>
        <div className="table__container">
          <DraftTable
            search={search}
            page={page}
            listCount={listCount}
            setListCount={setListCount}
          />
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={PAGE_LENGTH}
            listCount={listCount}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  '.draft__wrapper': {
    backgroundColor: 'white',
    paddingInline: '24px',
  },
  '.search__block': {
    paddingTop: '20px',
  },
  '.page-switcher-base': {
    paddingBottom: '50px',
  },
  '@media(max-width: 1327px)': {
    '.full__name': {
      display: 'none',
    },
  },
  '@media(max-width: 1430px)': {
    '.table__container': {
      overflow: 'scroll',
    },
  },
  '@media(min-width: 1340px)': {
    '.draft__wrapper': {
      width: '1140px',
    },
  },
})
