angular.module('ccs').directive('userfilter', userFilter)

function userFilter(Api) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/user_filter.html',
    link: function ($scope) {
      function getUsers(search = '') {
        Api.Users.get({ search }, (resp) => {
          $scope.users = resp.results
        })
      }

      $scope.selected = () => {
        $scope.pageNumber = 1
        $scope.changePage()
      }

      $scope.refresh = getUsers
    },
  }
}
