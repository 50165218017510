//this will pad an integer with zeroes
angular.module('ccs').filter('numberFixedLen', function () {
  return function (n, len) {
    let num = parseInt(n, 10)
    len = parseInt(len, 10)
    if (isNaN(num) || isNaN(len)) {
      return n
    }
    num = '' + num
    while (num.length < len) {
      num = '0' + num
    }
    return num
  }
})
