import * as React from 'react'

import { TableRow } from './TableRow'

export function TableList({ rows }: any) {
  if (rows) {
    return (
      <tbody>
        {rows.map((result) => (
          <TableRow key={result.id} result={result} />
        ))}
      </tbody>
    )
  } else {
    return (
      <tbody>
        <tr>
          <td>No data</td>
        </tr>
      </tbody>
    )
  }
}
