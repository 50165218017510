angular.module('ccs').directive('issuetypefilter', issueTypeFilter)

function issueTypeFilter() {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/issue_type_filter.html',
    link: function ($scope) {
      $scope.types = {
        open: 'Open',
        closed: 'Closed',
        any: 'Any',
      }

      $scope.type = 'any'

      $scope.selected = () => {
        $scope.pageNumber = 1
        $scope.changePage()
      }
    },
  }
}
