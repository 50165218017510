export const INIT_ROLES = [
  { label: 'Administrator', value: 'client_admin', selected: false },
  { label: 'Supervisor', value: 'supervisor', selected: false },
  { label: 'Employee', value: 'client_user', selected: false },
  { label: 'Field Employee', value: 'read_only', selected: false },
  {
    label: 'Training Manager',
    value: 'trainee_manager',
    selected: false,
  },
  { label: 'Trainee', value: 'trainee', selected: false },
  { label: 'Recipient', value: 'recipient', selected: false },
]
