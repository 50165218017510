//Note: this is not real security, it only hides elements from the web UI.
//Items in here should be enforced by Django integration testing.

angular.module('ccs').service('uiPermissionService', function (CurrentUser) {
  function allow(...roles) {
    return () => {
      const currentUserRole = CurrentUser.getRole()
      return roles.includes(currentUserRole)
    }
  }

  return {
    getShowAddNews: allow('client_admin'),
    //Add Folder button on File cabinet view
    getPermissionCreateCabinet: allow('client_admin', 'supervisor'),
    getPermissionCreateCabinetFile: allow('client_admin', 'supervisor'),
    //The ability to create(upload) new Procedures
    getPermissionCreateProcedure: allow('client_admin'),
    //The ability to create new process flows
    getPermissionCreateProcessFlow: allow('client_admin'),
    //Delete Folder button on File cabinet view
    getPermissionDeleteCabinet: allow('client_admin', 'supervisor'),
    getPermissionDeleteCabinetFile: allow('client_admin', 'supervisor'),
    getPermissionDeleteProcedure: allow('client_admin', 'supervisor'),
    getPermissionDeleteProcessFlow: allow('client_admin', 'supervisor'),
    getPermissionEditCabinetFile: allow('client_admin', 'supervisor'),
    // Assign projects to a procedure
    getPermissionEditProcedure: allow('client_admin', 'supervisor'),
    // Assign projects to a process flow
    getPermissionEditProcessFlow: allow('client_admin', 'supervisor'),
    // toggle all projects to all procedures
    getPermissionToggleAllProjectsToAllProcedures: allow('client_admin'),
    // toggle all projects to all process flows
    getPermissionToggleAllProjectsToAllProcessFlows: allow('client_admin'),
    // Show the Administration node on the navigation menu.
    getPermissionViewAdministration,
    // Show Mobile Forms on the navigation menu.
    getPermissionViewCompanyNews: allow(
      'client_admin',
      'client_user',
      'supervisor',
    ),
    //Show the File Cabinet node on the navigation menu.
    getPermissionViewFileCabinet: allow(
      'client_admin',
      'client_user',
      'supervisor',
    ),
    //Show the Mobile Apps node on the navigation menu.
    getPermissionViewMobileApps: allow(
      'client_admin',
      'client_user',
      'supervisor',
    ),
    //Show Mobile Forms on the navigation menu.
    getPermissionViewMobileForms: allow(
      'client_admin',
      'client_user',
      'supervisor',
    ),
    // Assign projects to a procedure
    getPermissionViewProcedures: allow(
      'client_admin',
      'client_user',
      'supervisor',
    ),
    // Show Process Flows on the navigation menu.
    getPermissionViewProcessFlows: allow(
      'client_admin',
      'client_user',
      'supervisor',
    ),
    // Show the Risk Dashboard node on the navigation menu.
    getPermissionViewRiskDashboard: allow('client_admin', 'supervisor'),
    // Show the training in the navigation menu.
    getPermissionViewTraining: allow(
      'client_admin',
      'client_user',
      'read_only',
      'supervisor',
      'trainee',
      'trainee_manager',
    ),
    // Show the training program in the navigation menu.
    getPermissionViewTrainingProgram: allow(
      'client_admin',
      'client_user',
      'read_only',
      'supervisor',
      'trainee',
      'trainee_manager',
    ),
    getShowDeleteNews: allow('client_admin'),
    getShowEditNews: allow('client_admin'),
    getShowHideNews: allow('client_admin'),
    getShowLessons: allow('client_admin', 'client_user', 'supervisor'),
    getPermissionDownloadUserData: allow('client_admin'),
    // Show the "Download Report Data" button in the Report List page.
    getPermissionDownloadReportDataOrCreateForm: allow(
      'client_admin',
      'supervisor',
    ),
    // Show the "Categories" button in navigation menu.
    getPermissionViewCategories: allow(
      'client_admin',
      'supervisor',
      'trainee_manager',
    ),
    // Show the "Questions" button in navigation menu.
    getPermissionViewQuestions: allow(
      'client_admin',
      'supervisor',
      'trainee_manager',
    ),
    // Show the "Assign Toolbox Talk to Project" button in navigation menu.
    getPermissionAssignToolboxTalkToProject: allow(
      'client_admin',
      'supervisor',
      'trainee_manager',
    ),
    // Show the "Create Toolbox Talk" button in navigation menu.
    getPermissionCreateToolboxTalk: allow('client_admin', 'supervisor'),
    getPermissionToSeeToolboxTalk: allow(
      'client_admin',
      'supervisor',
      'client_user',
    ),
    // Show the "Add training" and "+ Bulk Upload CSV"  in the training documentation page.
    getPermissionAddNewTrainingAndUploadCSV: allow(
      'client_admin',
      'trainee_manager',
    ),
    // Show the Form Groups in the navigation menu.
    getPermissionViewFormGroups: allow('client_admin'),
    // Show the Category Groups in the navigation menu.
    getPermissionViewCategoryGroups: allow('client_admin', 'trainee_manager'),
    // Show the Toolbox Talk Groups in the navigation menu.
    getPermissionViewToolboxTalkGroups: allow(
      'client_admin',
      'trainee_manager',
    ),
    // Show the MyQuestions in the navigation menu.
    getPermissionViewMyQuestions: allow('client_admin', 'trainee_manager'),
    // Show the "Add Custom Category" in the Custom Category page.
    getPermissionAddCustomCategory: allow(
      'client_admin',
      'client_user',
      'trainee_manager',
    ),
    getPermissionViewDeleteCustomCategory: allow(
      'client_admin',
      'client_user',
      'trainee_manager',
    ),
    // The ability to edit/delete mobile form
    getPermissionEditDeleteForm: allow(
      'client_admin',
      'client_user',
      'trainee_manager',
    ),
    // The ability to edit/delete ToolboxTalk
    getPermissionEditDeleteToolboxTalk: allow(
      'client_admin',
      'client_user',
      'trainee_manager',
    ),
    getPermissionEditOrDeletTrainingDocumentation: allow(
      'client_admin',
      'trainee_manager',
    ),
    // Toggle All projects to a process flow
    getPermissionToToggleAllProcessFlow: allow('client_admin'),
    getPermissionAssignTraining: allow('client_admin', 'trainee_manager'),
    getPermissionToManageTraining: allow('client_admin', 'trainee_manager'),
  }

  // @TODO: there is no sense to rewrite it in declarative way we did for other permissions
  // though it can be done if needed.
  function getPermissionViewAdministration() {
    const role = CurrentUser.getRole()
    const customPermissions = CurrentUser.getCustomPermissions()

    if (role === 'admin') {
      return false
    } else if (role === 'client_admin') {
      return true
    } else if (role === 'client_user') {
      return false
    } else if (role === 'read_only') {
      return false
    } else if (
      role === 'supervisor' &&
      customPermissions.some((p) => p.content_type === 'user')
    ) {
      return true
    } else if (role === 'trainee') {
      return false
    } else if (role === 'trainee_manager') {
      return false
    } else {
      return false
    }
  }
})
