import { useRef } from 'react'
import { isEqual } from 'lodash'

/** @internal Internal hook to support deep diffing in hook dependencies */
export function useDeepObjectDiff(obj: object): number {
  const ref = useRef(obj)
  const version = useRef(1)
  const deepEqual = obj === ref.current || isEqual(obj, ref.current)
  ref.current = obj
  if (!deepEqual) {
    version.current++
  }
  return version.current
}
