import { resolvers } from '../resolvers'

/** @ngInject */
export function appsCompanyNewsConfig($stateProvider) {
  $stateProvider
    .state('app.company_news', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
      title: 'Company News Administration',
    })
    .state('app.company_news.list', {
      controller: 'CompanyNewsListCtrl',
      templateUrl: 'app/views/news_list.html',
      url: '/company_news?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
}
