import complete from '../assets/icons/complete.png'
import copyicon from '../assets/icons/copyicon.png'
import ho from '../assets/icons/ho.png'
import pdf from '../assets/icons/pdf.png'
import ppt from '../assets/icons/ppt.png'
import quiz from '../assets/icons/quiz.png'
import quiz_empty from '../assets/icons/quiz_empty.png'
import selectArrows from '../assets/icons/select-arrows.png'
import video from '../assets/icons/video.png'

export const icons = {
  complete: complete,
  copyicon: copyicon,
  ho: ho,
  pdf: pdf,
  ppt: ppt,
  quiz: quiz,
  quiz_empty: quiz_empty,
  'select-arrows': selectArrows,
  video: video,
}
