import { css } from '@emotion/react'
import { CheckIcon } from './CheckIcon'
import { XIcon } from './XIcon'
import { useAngularServices } from '../components'
import { TextInputStyle } from '@screens/components'
import { useState } from 'react'

export const TableInput = ({ defaultValue, handleSubmit, handleClose }) => {
  const { CurrentUser } = useAngularServices()

  const [value, setValue] = useState(defaultValue)

  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const styles = getStyles(variableColor, value)

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <div css={styles} className="input__wrapper">
      <input
        className="input"
        value={value}
        onChange={handleChange}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit(value)
            handleClose()
          }
        }}
      />
      <div
        key="clear"
        className="search-row-buttons clear-button"
        onClick={() => {
          handleClose()
        }}
      >
        <XIcon height={16} />
      </div>
      <div
        key="search"
        className="search-row-buttons search-button"
        onClick={() => {
          if (!value) {
            return
          }
          handleSubmit(value)
          handleClose()
        }}
      >
        <CheckIcon height={'12.3px'} width={16} />
      </div>
    </div>
  )
}

const getStyles = (variableColor: string, value: string) =>
  css({
    display: 'flex',
    alignItems: 'center',
    height: '40px',
    '.input': {
      ...TextInputStyle,
      width: '250px',
      height: '25px',
      color: '#000',
    },
    '.search-row-buttons': {
      height: 25,
      width: 25,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: variableColor,
      cursor: 'pointer',
    },
    '.clear-button': {
      color: variableColor,
      ':hover': {
        backgroundColor: variableColor,
        color: 'white',
      },
    },
    '.search-button': {
      color: 'white',
      backgroundColor: !value ? '#888888' : variableColor,
      borderColor: !value ? '#888888' : variableColor,
      cursor: !value ? 'default' : 'pointer',
      svg: {
        cursor: !value ? 'default' : 'pointer',
      },
      ':hover': {
        backgroundColor: !value ? '#888888' : 'transparent',
        color: !value ? 'white' : variableColor,
      },
    },
  })
