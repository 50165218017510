angular.module('ccs').service('filterService', function (CurrentUser) {
  return {
    getIsVisibleObservationItem: getIsVisibleObservationItem,
    getNotDeletedItems: getNotDeletedItems,
    getSyncAnswer: getSyncAnswer,
  }

  /**
   * for photos, notes and corrective actions
   * @param answerUserId: number
   * @param item: object
   * @returns boolean
   */
  function getIsVisibleObservationItem(answerUserId, item) {
    if (!item) {
      return false
    }

    // new note or corrective action
    if (!item.user || !item.id) {
      return true
    }

    // external contact
    if (!item.user && item.id) {
      if (item.external_contact) {
        return true
      }
      if (item.image) {
        return true
      } // new photo
    }

    var isVisible = false

    const currentUserId = CurrentUser.getId()

    if (answerUserId && answerUserId === item.user.id) {
      // we are checking, who created observation/answer and who added photo, note or corrective action
      // if it's the same person, then it is originator
      // for example: user1 created observation and user1 added corrective action, so this user1 is originator
      isVisible = true
    } else if (currentUserId !== item.user.id && item.is_visible) {
      // (IMPORTANT) user clicked on the button "Save and finish later" on the MOBILE
      // on the WEB, we are checking, who is registered on the site (current user) and who added photo, note or corrective action AND if user clicked on the button "Save and finish later" on the mobile
      // if it's the same person, we need to show this item ONLY to this user
      // for example:
      // on the Mobile, user2 added "corrective action" and clicked on the button "Save and finish later"
      // on the WEB, user2 is registered on the site (current user) and we need to show this item ONLY to this user
      isVisible = true
    } else if (currentUserId === item.user.id) {
      // we are checking, who is registered on the site (current user) and who added photo, note or corrective action
      // if it's the same person, we need to show this item to this user
      // for example: user3 is registered on the site (current user) and user3 added corrective action, so we need to show this item to this user
      isVisible = true
    }

    return isVisible
  }

  /**
   * get not deleted items from array
   * @param array: array
   * @returns array
   */
  function getNotDeletedItems(array) {
    var filteredArray = []

    if (!Array.isArray(array)) {
      return filteredArray
    }

    array.forEach(function (item) {
      if (!item.deleted) {
        filteredArray.push(item)
      }
    })

    return filteredArray
  }

  /**
   * get result --> Is the answer synchronised?
   * @param array: array
   * @returns boolean
   */
  function getSyncAnswer(array) {
    var synchronised = true // true by default --> the answer is synchronised

    if (!Array.isArray(array)) {
      return false
    }

    // if array is empty, so the answer is synchronised
    if (array.length == 0) {
      return synchronised
    }

    array.forEach(function (item) {
      if (!item.synchronised) {
        synchronised = false
      }
    })

    return synchronised
  }
})
