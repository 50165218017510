import * as React from 'react'
import { css } from '@emotion/react'
import moment from 'moment'

import { updateQueryParams } from '@/utils/updateQueryParams'
import {
  addOrRemoveDaysFormDate,
  getFirstAndLastWeekDate,
} from '@components/commonDateFormatting'
import { CalendarDatePicker } from '@components/CalendarDatePicker'
import { TextInputStyle } from '@screens/components'

type Props = {
  date: string
  dateCallback: any
  pageCallback: any
  setReload: any
}

export const DateSwitcher = ({
  dateCallback,
  pageCallback,
  date,
  setReload,
}: Props) => {
  const [calendarActive, setCalendarActive] = React.useState<boolean>(false)

  const splitDate = date.split(',')
  const beforeWeek = getFirstAndLastWeekDate(
    addOrRemoveDaysFormDate(new Date(splitDate[0]), 5, true),
  )
  const nextWeek = getFirstAndLastWeekDate(
    addOrRemoveDaysFormDate(new Date(splitDate[1]), 5),
  )

  const month = moment(new Date(splitDate[0])).add(3, 'd').month()
  const year = moment(new Date(splitDate[0])).add(3, 'd').year()

  function onDateSwitch(date) {
    dateCallback(date)
    pageCallback(1)
    setReload(true)
    updateQueryParams({ period: date, pageNumber: 1 }, true)
  }

  function onChangeFromCalender(weekNumber) {
    if (weekNumber !== moment(new Date(splitDate[0])).add(3, 'd').week()) {
      onDateSwitch(
        getFirstAndLastWeekDate(
          new Date(moment(0).week(weekNumber).year(year)),
        ),
      )
    }
    setCalendarActive(false)
  }

  return (
    <section css={baseStyle}>
      <div
        className="arrow-button"
        onClick={() => {
          onDateSwitch(beforeWeek)
        }}
      >
        <div className="fa fa-chevron-left" />
      </div>
      <div
        className="current-week"
        onClick={() => {
          setCalendarActive(true)
        }}
      >
        {moment(splitDate[0]).format('MM/DD/YYYY')}
        {' - '}
        {moment(splitDate[1]).format('MM/DD/YYYY')}
      </div>
      <div
        className="arrow-button"
        onClick={() => {
          onDateSwitch(nextWeek)
        }}
      >
        <div className="fa fa-chevron-right" />
      </div>
      {!calendarActive ? null : (
        <div
          className="background"
          onClick={() => {
            setCalendarActive(false)
          }}
        />
      )}
      <div className="date-picker-holder">
        {!calendarActive ? null : (
          <CalendarDatePicker
            month={month}
            year={year}
            selectNumber={moment(new Date(splitDate[0])).add(3, 'd').week()}
            callback={onChangeFromCalender}
            top="37px"
          />
        )}
      </div>
    </section>
  )
}

const baseStyle = css({
  width: 'fit-content',
  display: 'flex',
  '.arrow-button': {
    height: 30,
    width: 30,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    div: {
      fontSize: 12,
      height: 12,
    },
    ...TextInputStyle,
  },
  '.current-week': {
    ...TextInputStyle,
    width: '183px',
    height: 30,
    paddingLeft: 10,
    paddingRight: 10,
    marginLeft: 10,
    marginRight: 10,
    lineHeight: '15px',
  },
  '.date-picker-holder': {
    height: 0,
    width: 0,
    position: 'relative',
  },
  '.background': {
    position: 'fixed',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
    zIndex: 500,
  },
})
