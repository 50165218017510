import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const AddFileIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      viewBox="0 0 34 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <path
        d="M24.6589 31.9999C28.9565 31.9999 32.4404 28.5161 32.4404 24.2185C32.4404 19.9209 28.9565 16.437 24.6589 16.437C20.3613 16.437 16.8774 19.9209 16.8774 24.2185C16.8774 28.5161 20.3613 31.9999 24.6589 31.9999Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M17.1131 22.2222C17.3838 21.1986 17.8608 20.241 18.5148 19.4083C19.1687 18.5756 19.9859 17.8853 20.9162 17.3797C21.8465 16.8741 22.8702 16.5638 23.9247 16.468C24.9791 16.3721 26.042 16.4927 27.0482 16.8223V4.24621C27.0482 3.38526 26.7062 2.55957 26.0975 1.95079C25.4887 1.34201 24.663 1 23.802 1H4.23835C3.38553 1.002 2.56775 1.33953 1.96178 1.93961C1.35581 2.5397 1.01032 3.35415 1 4.20691V23.7863C1.00208 24.6445 1.34392 25.467 1.95078 26.0739C2.55764 26.6807 3.38012 27.0226 4.23835 27.0247H17.3961C16.7942 25.4945 16.6951 23.8124 17.1131 22.2222V22.2222Z"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M24.6587 20.7837V27.6534"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M21.2158 24.2185H28.0934"
        stroke="currentcolor"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
      <path
        d="M14 23C12.6061 23 11.4242 22.4933 10.4545 21.48C9.48485 20.4667 9 19.2317 9 17.775V7.8C9 6.755 9.35606 5.86042 10.0682 5.11625C10.7803 4.37208 11.6364 4 12.6364 4C13.6364 4 14.4924 4.37208 15.2045 5.11625C15.9167 5.86042 16.2727 6.755 16.2727 7.8V16.825C16.2727 17.49 16.053 18.0521 15.6136 18.5112C15.1742 18.9704 14.6364 19.2 14 19.2C13.3636 19.2 12.8258 18.9704 12.3864 18.5112C11.947 18.0521 11.7273 17.49 11.7273 16.825V7.8H13.0909V16.825C13.0909 17.0942 13.1782 17.3199 13.3527 17.5023C13.5273 17.6847 13.743 17.7756 14 17.775C14.2576 17.775 14.4736 17.6838 14.6482 17.5014C14.8227 17.319 14.9097 17.0935 14.9091 16.825V7.8C14.9091 7.135 14.6894 6.57292 14.25 6.11375C13.8106 5.65458 13.2727 5.425 12.6364 5.425C12 5.425 11.4621 5.65458 11.0227 6.11375C10.5833 6.57292 10.3636 7.135 10.3636 7.8V17.775C10.3636 18.82 10.7197 19.7146 11.4318 20.4587C12.1439 21.2029 13 21.575 14 21.575C15 21.575 15.8561 21.2029 16.5682 20.4587C17.2803 19.7146 17.6364 18.82 17.6364 17.775V7.8H19V17.775C19 19.2317 18.5152 20.4667 17.5455 21.48C16.5758 22.4933 15.3939 23 14 23Z"
        fill="currentcolor"
      />
    </svg>
  )
}
