import * as React from 'react'
import { SingleQuestion } from './index'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import {
  ApplicationConfig,
  GenericOption,
  ReportAnswer,
  ReportQuestion,
} from '@/react/types'
import { Dispatch, SetStateAction } from 'react'

type SingleIdQuestionProps = {
  questionInfo: ReportQuestion
  ANSWER: ReportAnswer
  setPartyObservedOptions: Dispatch<SetStateAction<GenericOption[]>>
  partyObservedOptions: GenericOption[]
  globalPartyObserved: GenericOption[]
  app: ApplicationConfig
  saveButtonPressed: number
  memoryState: {
    [key: string]: ReportAnswer[]
  }
  setMemoryState: Dispatch<
    SetStateAction<{ [key: string]: ReportAnswer[] } | null>
  >
  index: number
}

export function SingleIdQuestionsElement({
  questionInfo,
  memoryState,
  setMemoryState,
  ANSWER,
  globalPartyObserved,
  partyObservedOptions,
  setPartyObservedOptions,
  app,
  saveButtonPressed,
}: SingleIdQuestionProps) {
  const answersLink = memoryState[questionInfo.id + '-' + questionInfo.category]
  const [answersToRender, setAnswersToRender] = React.useState(answersLink)
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const observationID = stateService.params.observation

  if (!answersToRender) return null

  return (
    <div>
      {answersToRender.map((singleAnswer, idx) => {
        if (!answersLink[singleAnswer.copy_sequence_number]) {
          console.error('Missing answer link, error info:', {
            answersLink_base: answersLink,
            Single_answerData: singleAnswer,
            Question_Info: questionInfo,
          })
        } else {
          return (
            <SingleQuestion
              globalPartyObserved={globalPartyObserved}
              partyObservedOptions={partyObservedOptions}
              setPartyObservedOptions={setPartyObservedOptions}
              key={idx}
              questionInfo={questionInfo}
              memoryState={memoryState}
              setMemoryState={setMemoryState}
              copyField={copyField}
              app={app}
              saveButtonPressed={saveButtonPressed}
              index={singleAnswer.copy_sequence_number}
            />
          )
        }
      })}
    </div>
  )

  async function copyField() {
    const { data: newAnswer } = await Api.post(`answers`, {
      observation: parseInt(observationID),
      question: questionInfo.id,
    })
    answersLink.push({
      ...ANSWER,
      copy_sequence_number: answersLink.length,
      question: questionInfo.id,
      id: newAnswer.id,
    })
    setAnswersToRender([...answersLink])
  }
}
