import { ReactWrapper } from '@components'
import { CostCodesCreate } from './CostCodesCreate'
import { CostCodesList } from './CostCodesList'
import { CostCodesBulk } from './CostCodesBulk'

export const ViewCostCodesCreate = ReactWrapper(
  CostCodesCreate,
  'createCostCodes',
)
export const ViewCostCodesList = ReactWrapper(
  CostCodesList,
  'listCostCodes',
)
export const ViewCostCodesBulk = ReactWrapper(
  CostCodesBulk,
  'bulkCostCodes',
)
