import {
  CommonPageHeader,
  CustomCheckbox,
  FloatingWindowHorizontalNavigation,
  PageSwitcher,
  Search,
  TableHeaders,
  TableHighlight,
  useAngularServices,
} from '@/react/components'
import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { getAdminNavigationData } from '@screens/Admin/utils/commonVariables'
import { DataWrap, GenericClient, PaginatedGet } from '@/react/types'
import { UISref } from '@components/UISref'
import { ClientTableRow } from '@screens/Admin/CliensTable/components/ClientTableRow'

export const ClientsTable = () => {
  const [search, setSearch] = useState('')
  const [order, setOrder] = useState('company__name')
  const [page, setPage] = useState(1)
  const [userList, setUserList] = useState<GenericClient[]>([])
  const [count, setCount] = useState(20)

  const { Api } = useAngularServices()

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'user',
      name: 'User',
      type: 'user__username',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
          paddingLeft: 10,
        },
      },
    },
    {
      className: 'full_name',
      name: 'Full name',
      type: 'user__first_name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
        },
      },
    },
    {
      className: 'company_name',
      name: 'Company name',
      type: 'company__name',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
        },
      },
    },
    {
      className: 'user_amount',
      name: '# of users',
      type: 'user_amount',
      filterHeader: false,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
        },
      },
    },
    {
      className: 'email',
      name: 'Email',
      type: 'user__email',
      filterHeader: true,
      additionalStyles: {
        textAlign: 'left',
        width: 'auto',
        '.header-base': {
          justifyContent: 'left',
        },
      },
    },
    {
      className: 'active',
      name: 'Active',
      type: 'active',
      filterHeader: false,
      additionalStyles: {
        minWidth: 30,
        Width: 30,
      },
    },
  ]

  const checkField = async (id: number) => {
    await Api.post(`clients/${id}/toggle_active`, {})
  }

  useEffect(() => {
    const getTemplates = async () => {
      const { data }: DataWrap<PaginatedGet<GenericClient[]>> = await Api.get(
        'clients',
        {
          page,
          search,
          order,
        },
      )
      setUserList(data?.results)
      setCount(data?.count)
    }
    getTemplates()
  }, [page, search, order])

  return (
    <div className="manage__assessments" css={baseStyle}>
      <CommonPageHeader headerText={'Accounts'} />
      <FloatingWindowHorizontalNavigation
        navSettings={getAdminNavigationData('manage_client')}
      />
      <section className="manage__section">
        <div className="search__wrapper">
          <Search
            searchCallback={setSearch}
            search={search}
            pageCallback={setPage}
          />
        </div>
        <table>
          <TableHeaders
            headers={TABLE_HEADER_SETTINGS}
            callback={setOrder}
            popUpLocation={false}
            updateUrl={false}
            order={order}
            setOrder={setOrder}
          />
          <tbody>
            {userList.map((user, idx) => {
              return (
                <ClientTableRow user={user} idx={idx} checkField={checkField} />
              )
            })}
          </tbody>
        </table>
        <div className="switch__wrapper">
          <PageSwitcher
            pageLength={20}
            listCount={count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </section>
    </div>
  )
}

const baseStyle = css({
  paddingBottom: '200px',
  table: {
    width: '100%',
  },
  '.manage__section': {
    background: '#fff',
    paddingInline: '20px',
    paddingBottom: '10px',
    width: '925px',
  },
  '.search__wrapper': {
    paddingTop: '20px',
    paddingBottom: '20px',
  },
  '#search': {
    width: '264px',
  },
  '.switch__wrapper': {
    paddingBottom: '1px',
  },
  thead: {
    borderBottom: '2px solid rgb(198, 198, 198)',
  },
  tbody: {
    tr: {
      height: 40,
      borderBottom: '1px solid rgb(231, 234, 236)',
      '.username__td': {
        paddingLeft: 15,
      },
      td: {
        paddingLeft: 5,
      },
    },
  },
  '.active__td': {
    paddingTop: 8,
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
  },

  '@media(max-width: 1150px)': {
    '.manage__section': {
      width: '100%',
      overflowX: 'scroll',
    },
  },
})
