import { NavBar } from '../commonComponents/Navbar'
import { CommonPageHeader, useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { CreateEditBase } from './CreateEditBase'

export const CreatePage = () => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const addTrainingToServer = async (
    dataToAdd,
    dateTrained,
    dateExpires,
    attachmentId,
  ) => {
    const clientId = CurrentUser.getId()
    await Api.postV2('trainings/documentation', {
      ...dataToAdd,
      client: clientId,
      date_trained: dateTrained,
      date_training_expires: dateExpires,
      attachment: attachmentId,
    })
    stateService.go('trainings.documentation')
  }

  return (
    <section>
      <CommonPageHeader headerText={'Training Documentation'} />
      <NavBar></NavBar>
      <CreateEditBase handleSubmit={addTrainingToServer} />
    </section>
  )
}
