import React, { FC, KeyboardEvent, useCallback } from 'react'
import { TextInputStyle } from '@screens/components'
import { css, SerializedStyles } from '@emotion/react'

interface Props {
  className?: string
  onValueChange: (value: number) => void
  style?: SerializedStyles
  value: number
  currency?: string
}

const VALID_FIRST = /^[1-9]{1}$/
const VALID_NEXT = /^[0-9]{1}$/
const DELETE_KEY_CODE = 8

export const CurrencyInput: FC<Props> = ({
  className = '',
  onValueChange,
  style = {},
  value,
  currency = '$',
}) => {
  const valueAbsTrunc = Math.trunc(Math.abs(value))
  if (
    value !== valueAbsTrunc ||
    !Number.isFinite(value) ||
    Number.isNaN(value)
  ) {
    console.error(`invalid value property`)
  }
  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>): void => {
      const { key, keyCode, currentTarget } = e
      const {
        selectionStart,
        selectionEnd,
        value: currentValue,
      } = currentTarget

      if (
        (value === 0 && !VALID_FIRST.test(key)) ||
        (value !== 0 && !VALID_NEXT.test(key) && keyCode !== DELETE_KEY_CODE)
      ) {
        return
      }

      if (keyCode === DELETE_KEY_CODE) {
        // Check if the user has highlighted the entire value and wants to remove it
        if (selectionStart === 0 && selectionEnd === currentValue.length) {
          onValueChange(0)
          return
        }

        // Handle backspace in other cases
        const valueString = value.toString()
        const nextValueString = valueString.slice(0, -1)
        const nextValue =
          nextValueString === '' ? 0 : Number.parseInt(nextValueString, 10)
        onValueChange(nextValue)
        return
      }

      const valueString = value.toString()
      const nextValueString = value === 0 ? key : `${valueString}${key}`
      const nextValue = Number.parseInt(nextValueString, 10)
      onValueChange(nextValue)
    },
    [onValueChange, value, currency],
  )

  const handleChange = useCallback(() => {
    // DUMMY TO AVOID REACT WARNING
  }, [])
  const possibleCurrency = {
    $: (value / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    }),
    '€': (value / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'EUR',
    }),
    '₣': (value / 100)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'CHF',
      })
      .replace('CHF', '₣'),
    '£': (value / 100).toLocaleString('en-US', {
      style: 'currency',
      currency: 'GBP',
    }),
    C$: (value / 100)
      .toLocaleString('en-US', {
        style: 'currency',
        currency: 'CAD',
      })
      .replace('CA$', 'C$'),
  }
  const valueDisplay = possibleCurrency[currency]

  return (
    <input
      className={className}
      inputMode="numeric"
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      css={css({ ...style, ...TextInputStyle })}
      value={valueDisplay}
    />
  )
}
