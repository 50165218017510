import * as React from 'react'
import { default as ReactSelect } from 'react-select'
import { css } from '@emotion/react'
import {
  ButtonElement,
  dropdownFiledTheme,
  dropdownOptionStyles,
  useAngularServices,
} from '@/react/components'
import { sortBy } from 'lodash'

import closeIcon from '/src/assets/icons/X Gray.svg'
import { useRouter } from '@/react/hooks'

export const CreateActivityPopup = ({
  setPopUp,
  callback,
  costCodes,
  editCallback,
  currentCostCode,
  edit = false,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [projectData, setProjectData] = React.useState<object[]>([])
  const [costCodesPerProject, setCostCodesPerProject] = React.useState<{}>({})
  const [selectedProject, setSelectedProject] = React.useState(null)
  const [selectedCostCode, setSelectedCostCode] = React.useState(null)

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const costCodesIds = costCodes.map((costCode) => {
    return costCode.id
  })

  async function fetchProjects() {
    const { data } = await Api.get('projects', {
      is_active: true,
      client: CurrentUser.getClientId(),
      page_size: 1000,
      activity_dropdown: true,
      app: stateService.params.app,
    })
    const projectOptions = []
    data.results.forEach((project) => {
      projectOptions.push({
        value: project.id,
        label: (project.number ? project.number + ' ' : '') + project.name,
      })
    })
    setProjectData(projectOptions)
  }

  async function fetchAndSaveCostCodes(project) {
    const { data } = await Api.get('cost_codes', {
      projects: project,
      is_active: true,
      page_length: 1000,
    })
    costCodesPerProject[project] = []

    const withCostCodes = []
    const noCostCodes = []

    data.results.forEach((cc) => {
      if (cc.costcode_code) {
        withCostCodes.push(cc)
      } else {
        noCostCodes.push(cc)
      }
    })

    const combinedList = [
      ...withCostCodes.sort(compareCostCode),
      ...noCostCodes.sort(compareNames),
    ]

    combinedList.forEach((cc) => {
      if (!costCodesIds.includes(cc.id)) {
        costCodesPerProject[project].push({
          label: cc.costcode_code + ' ' + cc.name,
          value: cc,
        })
      }
    })
    setCostCodesPerProject(costCodesPerProject)
    setSelectedProject(project)
  }

  React.useEffect(() => {
    fetchProjects()
  }, [])

  if (projectData.length === 0) return null

  return (
    <section css={baseStyle}>
      <div
        className="background"
        onClick={() => {
          setPopUp(null)
        }}
      />
      <div className="alert-holder">
        <div
          className="single-alert-row"
          css={css({
            justifyContent: 'right',
          })}
        >
          <img
            src={closeIcon}
            alt="close"
            css={css({
              height: 25,
              cursor: 'pointer',
            })}
            onClick={() => {
              setPopUp(null)
            }}
          />
        </div>
        <div className="header-row">
          <h3>Activity</h3>
        </div>
        <label>Project:</label>
        <ReactSelect
          options={projectData}
          onChange={(value) => {
            fetchAndSaveCostCodes(value.value)
          }}
          styles={dropdownOptionStyles(variableColor)}
          theme={(theme) => dropdownFiledTheme(theme, variableColor)}
        />
        <label className="second-dropdown">Costcode:</label>
        <ReactSelect
          options={selectedProject ? costCodesPerProject[selectedProject] : []}
          onChange={(value) => {
            setSelectedCostCode(value.value)
          }}
          styles={dropdownOptionStyles(variableColor)}
          theme={(theme) => dropdownFiledTheme(theme, variableColor)}
        />
        <div className="button-row">
          <ButtonElement
            height={'31px'}
            width={'91px'}
            text={'Save'}
            fontSize={'12px'}
            disabled={!(selectedProject && selectedCostCode)}
            buttonType={'submit'}
            functionToTrigger={() => {
              if (edit) {
                editCallback(selectedCostCode, currentCostCode)
              } else {
                callback(selectedCostCode)
              }
            }}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  display: 'flex',
  position: 'absolute',
  right: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  '.background': {
    position: 'fixed',
    width: '100%',
    height: '100%',
    right: 0,
    top: 0,
    zIndex: 500,
  },
  '.alert-holder': {
    position: 'fixed',
    boxShadow: '0 0 1px rgb(0 0 0 / 10%), 0 2px 4px rgb(0 0 0 / 20%)',
    padding: 20,
    width: 400,
    zIndex: 1000,
    '.error-text': {
      marginTop: 10,
      color: 'red',
    },
    backgroundColor: 'white',
    '.header-row': {
      textAlign: 'center',
      marginBottom: 15,
    },
    '.single-alert-row': {
      width: '100%',
      display: 'flex',
      marginBottom: 10,
    },
    '.second-dropdown': {
      marginTop: 20,
    },
    '.button-row': {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'right',
    },
  },
})

function compareCostCode(a, b) {
  if (a.costcode_code < b.costcode_code) {
    return -1
  }
  if (a.costcode_code > b.costcode_code) {
    return 1
  }
  return 0
}
function compareNames(a, b) {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}
