import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { changeBackground, generateUUID } from '@/utils'
import { parseDate } from '@/utils/parseDate'

import { Header } from './components'
import { ImageWithPreview } from '@screens/Observations/components'

const answerFields = {
  no: <span className="no answer-field">FOLLOW UP REQUIRED</span>,
  pr: <span className="pr answer-field">PENDING REVIEW</span>,
  pa: <span className="pa answer-field">PENDING APPROVAL</span>,
  cls: <span className="cls answer-field">ISSUE RESOLVED</span>,
  yes: <span className="yes answer-field">YES</span>,
}

export function AssignedExternal() {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  const [pageData, setPageData] = React.useState(null)
  const [noteInputList, setNoteInputList] = React.useState([])
  const [assignInputList, setAssignInputList] = React.useState([])
  const [loadingPhotosList, setLoadingPhotosList] = React.useState([])
  const [failedLoadingPhotosList, setFailedLoadingPhotosList] = React.useState(
    [],
  )
  const [photosList, setPhotosList] = React.useState([])
  const [photoValidation, setPhotoValidation] = React.useState<boolean>(false)
  const [noteValidation, setNoteValidation] = React.useState<boolean>(false)
  const [assignValidation, setAssignValidation] = React.useState<boolean>(false)
  const [loadingValidation, setLoadingValidation] = React.useState([])
  const [isButtonDisabled, setButtonDisabled] = React.useState(false)

  const [notes, setNotes] = React.useState([])
  const [assign, setAssign] = React.useState([])
  const [addedPhotos, setAddedPhotos] = React.useState([])

  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [companyName, setCompanyName] = React.useState('')

  const firstNameRef = React.useRef(null)
  const lastNameRef = React.useRef(null)
  const emailRef = React.useRef(null)
  const compNameRef = React.useRef(null)

  React.useEffect(async () => {
    const fetchData = async () => {
      const { data: pageDataGet } = await Api.get(
        'shares/answer/' + stateService.params.key,
        {
          key: stateService.params.ehash,
        },
      )
      CurrentUser.setClientSettings(
        pageDataGet.observation.client.general_settings,
      )
      const changingAnswer = {
        ...pageDataGet,
        answer_party_observed_answer_list: [],
        corrective_actions: [],
        notes: [],
      }
      pageDataGet.answer_party_observed_answer_list.forEach((po) => {
        if (!po.deleted)
          changingAnswer.answer_party_observed_answer_list.push(po)
      })
      pageDataGet.corrective_actions.forEach((ca) => {
        if (!ca.deleted) changingAnswer.corrective_actions.push(ca)
      })
      pageDataGet.notes.forEach((note) => {
        if (!note.deleted) changingAnswer.notes.push(note)
      })
      setPageData(changingAnswer)
    }
    fetchData()

    let failedRefChecks = 0

    setTimeout(() => {
      const fieldChecker = setInterval(() => {
        if (firstNameRef && lastNameRef && emailRef && compNameRef) {
          if (failedRefChecks === 5) {
            clearInterval(fieldChecker)
          } else if (firstNameRef.current === null) {
            failedRefChecks += 1
          } else {
            setFirstName(firstNameRef.current.value.trimStart())
            setLastName(lastNameRef.current.value.trimStart())
            setEmail(emailRef.current.value.trimStart())
            setCompanyName(compNameRef.current.value.trimStart())
            firstNameRef.current.value = firstNameRef.current.value.trimStart()
            lastNameRef.current.value = lastNameRef.current.value.trimStart()
            emailRef.current.value = emailRef.current.value.trimStart()
            compNameRef.current.value = compNameRef.current.value.trimStart()
          }
        }
      }, 1000)
    }, 1000)
  }, [])

  async function onSubmit() {
    setButtonDisabled(true)
    const sendData = sendDataCombination(pageData, {
      first_name: firstName.trim(),
      last_name: lastName.trim(),
      email: email.trim(),
      company_name: companyName.trim(),
      notes,
      assign,
      addedPhotos,
    })
    const sendDataResponse = await Api.post(
      'shares/answer/' + pageData.client_object_key,
      sendData,
    )
    if (sendDataResponse.data) {
      stateService.go(stateService.current.name, stateService.params, {
        reload: true,
        inherit: false,
        notify: true,
      })
    }
  }

  initializeListener()

  if (!pageData) return null

  const app = pageData.observation.application

  const severityLevelElements = {
    1: (
      <span className="severity-low severity-text">
        {app.observation_low_severity.toUpperCase()}
      </span>
    ),
    2: (
      <span className="severity-med severity-text">
        {app.observation_medium_severity.toUpperCase()}
      </span>
    ),
    3: (
      <span className="severity-high severity-text">
        {app.observation_high_severity.toUpperCase()}
      </span>
    ),
  }

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const baseStyle = getBaseStyle(variableColor)

  changeBackground(variableColor)

  return (
    <div css={baseStyle}>
      <Header headerData={pageData.observation} />
      <section className="staticFields">
        <div className="question-and-answer">
          <div className="question-field sub-field">
            <label>Question:</label>
            <span
              dangerouslySetInnerHTML={{ __html: pageData.question.name }}
            />
          </div>
          <div className="answer-field sub-field">
            {answerFields[pageData.answer]}
          </div>
        </div>
        <div className="severity-field sub-field">
          <label>Severity: </label>
          {severityLevelElements[pageData.severity]}
        </div>
        {pageData.answer_party_observed_answer_list.length ? (
          <div className="sub-field sub-field-with-multiples">
            <label className="sub-field-header">
              {app.observation_party_observed}
              {': '}
            </label>
            <div className="multiple-answer-body">
              {pageData.answer_party_observed_answer_list.map((po) => (
                <div className="single-answer">
                  <span> {po.party_observed.name}</span>
                </div>
              ))}
            </div>
          </div>
        ) : null}
        <div className="reference-field subfield">
          <label>Reference: </label>
          <span>{pageData.reference}</span>
        </div>
        {pageData.external_assignees.length ? (
          <div className="sub-field sub-field-with-multiples">
            <label>Assigned To: </label>
            <div className="multiple-answer-body">
              {pageData.external_assignees.map((assignee, idx) => {
                return (
                  <div key={idx} className="single-answer">
                    <span key={idx} className="date">
                      {parseDate(assignee.date_created)}{' '}
                      {assignee.external_contact.first_name
                        ? assignee.external_contact.first_name + ' '
                        : null}
                      {assignee.external_contact.last_name
                        ? assignee.external_contact.last_name
                        : null}
                      {', '}
                      {assignee.external_contact.company_name}
                    </span>
                  </div>
                )
              })}
              {pageData.internal_assignees.map((assignee, idx) => {
                return (
                  <div key={idx} className="single-answer">
                    <span key={idx} className="date">
                      {parseDate(assignee.date_created)}{' '}
                      {assignee.full_assignee.first_name
                        ? assignee.full_assignee.first_name + ' '
                        : null}
                      {assignee.full_assignee.last_name
                        ? assignee.full_assignee.last_name
                        : null}
                      {', '}
                      {assignee.full_assignee.company_name}
                    </span>
                  </div>
                )
              })}
            </div>
          </div>
        ) : null}
      </section>
      <section className="image-section">
        <div className="image-header">
          <label>Photos: </label>
          <label className="file-input">
            {' + Add Photo'}
            <input
              type="file"
              onChange={(value) => {
                addPhoto(value)
              }}
              accept="image/x-png,image/png,image/jpeg"
            />
          </label>
        </div>
        <div className="image-base">
          {pageData.photos.map((photo, idx) => (
            <ImageWithPreview key={idx} photo={photo} photoIdx={idx} />
          ))}
          {photosList}
          {failedLoadingPhotosList}
          {loadingPhotosList}
        </div>
      </section>
      <section className="note-section editable-section">
        <div className="note-header">
          <label>Notes: </label>
          <span
            className="add-field-button"
            onClick={() => {
              addNoteInput()
            }}
          >
            + Add Note
          </span>
        </div>
        <div className="notes-base">
          {pageData.notes.map((note) => (
            <div key={note.id} className="single-note">
              {note.date_created && (
                <span className="date">{parseDate(note.date_created)} - </span>
              )}
              <span className="date">
                {note.user
                  ? note.user.first_name
                  : note?.external_contact?.first_name}{' '}
                {note.user
                  ? note.user.last_name
                  : note?.external_contact?.last_name}{' '}
              </span>
              {note.date_created && <span>{note.text}</span>}
            </div>
          ))}
          {noteInputList}
        </div>
      </section>
      <section className="corrective-actions-section editable-section">
        <div className="corrective-actions--header">
          <label>Corrective Actions : </label>
          <span
            className="add-field-button"
            onClick={() => {
              addAssignInput()
            }}
          >
            + Add Corrective Action
          </span>
        </div>
        <div className="corrective-actions--base">
          {pageData.corrective_actions.map((correctiveAction) => (
            <div key={correctiveAction.id} className="corrective-action-note">
              {correctiveAction.date_created && (
                <span className="date">
                  {parseDate(correctiveAction.date_created)}
                  {' - '}
                </span>
              )}
              <span className="date">
                {correctiveAction.user
                  ? correctiveAction.user.first_name
                  : correctiveAction?.external_contact?.first_name}{' '}
                {correctiveAction.user
                  ? correctiveAction.user.last_name
                  : correctiveAction?.external_contact?.last_name}{' '}
              </span>
              {correctiveAction.date_created && (
                <span>{correctiveAction.text}</span>
              )}
            </div>
          ))}
          {assignInputList}
        </div>
      </section>
      <section className="required-information">
        <label>Required fields:</label>
        <div className="inputs-row">
          <input
            type="text"
            className="required-field"
            name="first_name"
            ref={firstNameRef}
            onChange={(el) => {
              setFirstName(el.target.value.trimStart())
              firstNameRef.current.value = el.target.value.trimStart()
            }}
            placeholder="First Name"
          />
          <input
            className="required-field middle-field"
            type="text"
            name="last_name"
            ref={lastNameRef}
            onChange={(el) => {
              setLastName(el.target.value.trimStart())
              lastNameRef.current.value = el.target.value.trimStart()
            }}
            placeholder="Last Name"
          />
          <input
            className="required-field"
            type="text"
            name="email"
            ref={emailRef}
            onChange={(el) => {
              setEmail(el.target.value.trimStart())
              emailRef.current.value = el.target.value.trimStart()
            }}
            placeholder="Email"
          />
        </div>
        <div className="company-input-row">
          <input
            className="required-field company-field"
            type="text"
            name="company_name"
            ref={compNameRef}
            onChange={(el) => {
              setCompanyName(el.target.value.trimStart())
              compNameRef.current.value = el.target.value.trimStart()
            }}
            placeholder="Company Name"
          />
        </div>
        <div className="button-row">
          <button
            onClick={onSubmit}
            className="submit-button btn"
            type="submit"
            disabled={
              !(noteValidation || assignValidation || photoValidation) ||
              ![!!firstName, !!lastName, !!companyName, !!email].every(
                (v) => v === true,
              ) ||
              !loadingValidation.every((v) => v === true) ||
              isButtonDisabled
            }
          >
            Submit for Approval
          </button>
        </div>
      </section>
    </div>
  )

  function initializeListener() {
    //detects when offline and cancels file validation on loading screens
    window.addEventListener('offline', () => {
      const replacementArray = []
      for (let i = 0; i < loadingPhotosList.length; i++) {
        const id = generateUUID() + i
        replacementArray.push(
          <div key={i} id={id} className="failed-loading-screen">
            <div
              className="remove-button"
              onClick={() => {
                removeSingleFailedLoadingScreen(id)
              }}
            >
              Remove
            </div>
            <div className="fail-screen">
              <span className="danger-text">
                Image upload failed: Stronger network connection required.
              </span>
            </div>
          </div>,
        )
      }
      setLoadingValidation([])
      setLoadingPhotosList([])
      setFailedLoadingPhotosList(replacementArray)
    })
  }

  function removeSingleFailedLoadingScreen(id) {
    document.getElementById(id).style.display = 'none'
  }

  async function addPhoto(value) {
    addLoadingScreen()
    const loadValidationArray = [...loadingValidation]
    const loadingScreenId = loadingValidation.length
    loadValidationArray[loadingScreenId] = false
    setLoadingValidation(loadValidationArray)

    const blob = new Blob([value.target.files[0]], {
      type: value.target.files[0].type,
    })
    const fileOfBlob = new File([blob], generateUUID())
    const { data: fileResponse } = await Api.uploadImage(fileOfBlob, {})

    addedPhotos.push(fileResponse.id)
    addPhotoBlock(fileResponse)
    setPhotoValidation(true)
    loadingPhotosList.pop()
    loadValidationArray[loadingScreenId] = true
    setLoadingPhotosList(loadingPhotosList)
  }

  function newFieldsChange(type, id, value) {
    if (type === 'notes') {
      notes[id] = value
      let validation = false
      notes.forEach((note) => {
        if (note.length) validation = true
      })
      setNoteValidation(validation)
    } else if (type === 'assign') {
      assign[id] = value
      let validation = false
      assign.forEach((assigning) => {
        if (assigning.length) validation = true
      })
      setAssignValidation(validation)
    }
  }

  function addNoteInput() {
    setNoteInputList([
      ...noteInputList,
      <input
        className="note-input"
        key={noteInputList.length}
        onChange={(value) => {
          newFieldsChange('notes', noteInputList.length, value.target.value)
        }}
      />,
    ])
  }

  function addAssignInput() {
    setAssignInputList([
      ...assignInputList,
      <input
        className="assing-input"
        key={assignInputList.length}
        onChange={(value) => {
          newFieldsChange('assign', assignInputList.length, value.target.value)
        }}
      />,
    ])
  }

  function addLoadingScreen() {
    setLoadingPhotosList([
      ...loadingPhotosList,
      <div className="loading-screen">
        <div className="fa fa-spinner loading-animation fa-spin spinner variable-color-text" />
      </div>,
    ])
  }

  function addPhotoBlock(photo) {
    if (stateService.current.name === 'assigned.answer') {
      photo.user = null
    }

    setPhotosList([
      ...photosList,
      <ImageWithPreview
        key={photosList.length}
        photo={photo}
        photoIdx={photosList.length}
      />,
    ])
  }
}

function sendDataCombination(initialData, formData) {
  const result = { ...initialData }
  result.photos = []
  result.all_assignees = []
  initialData.photos.forEach((photo) => {
    result.photos.push(photo.id)
  })
  formData.addedPhotos.forEach((photo) => {
    result.photos.push(photo)
  })
  formData.notes.forEach((note) => {
    result.notes.push({ text: note })
  })
  formData.assign.forEach((assign) => {
    result.corrective_actions.push({ text: assign })
  })
  initialData.external_assignees.forEach((assignee) => {
    result.all_assignees.push({
      full_name:
        assignee.external_contact.first_name +
        ' ' +
        assignee.external_contact.last_name,
      email: assignee.external_contact.email,
      company_name: assignee.external_contact.company_name,
      date_created: assignee.external_contact.date_created,
    })
  })
  initialData.internal_assignees.forEach((assignee) => {
    result.all_assignees.push({
      full_name:
        assignee?.external_contact?.first_name +
        ' ' +
        assignee?.external_contact?.last_name,
      email: assignee?.external_contact?.email,
      company_name: assignee?.external_contact?.company_name,
      date_created: assignee?.external_contact?.date_created,
    })
  })
  result.external_contact = {
    first_name: formData.first_name,
    last_name: formData.last_name,
    company_name: formData.company_name,
    email: formData.email,
  }
  result.question = initialData.question.id

  return result
}

function getBaseStyle(variableColor) {
  return css({
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    padding: '20px',
    '.staticFields': {
      paddingLeft: '20px',
      paddingRight: '20px',
      paddingTop: '20px',
      '.question-field': {
        width: 'calc(100% - 140px)',
        display: 'inline-block',
        span: {
          paddingLeft: '5px',
        },
        '@media (max-width: 650px)': {
          width: '100%',
        },
      },
      '.answer-field': {
        width: '140px',
        textAlign: 'right',
        display: 'inline-block',
        fontWeight: 'bold',
        '@media (max-width: 650px)': {
          width: '100%',
          textAlign: 'left',
        },
      },
      '.severity-text': {
        fontWeight: 'bold',
      },
      '.no': {
        color: 'red !important',
      },
      '.pr': {
        color: 'orange !important',
      },
      '.pa': {
        color: '#ffdb57 !important',
      },
      '.cls': {
        color: 'blue !important',
      },
      '.yes': {
        color: 'green !important',
      },
      '.sub-field': {
        pageBreakInside: 'avoid',
        pageBreakBefore: 'auto',
        pageBreakAfter: 'auto',
        breakInside: 'avoid',
        breakBefore: 'auto',
        breakAfter: 'auto',
        label: {},
      },
      '.sub-field-with-multiples': {
        label: {
          verticalAlign: 'top',
        },
      },
      '.multiple-answer-body': {
        display: 'inline-block',
        width: '75%',
        paddingLeft: '5px',
        '.single-answer': {
          width: '100%',
        },
      },
      '.party-observed-field': {
        width: '50%',
        paddingRight: '20px',
        display: 'inline-block',
        label: {
          width: '110px',
          verticalAlign: 'top',
        },
        span: {
          paddingLeft: '5px',
        },
        '.multiple-answer-body': {
          display: 'inline-block',
          width: 'calc(100% - 110px)',
        },
        '@media (max-width: 650px)': {
          width: '100%',
        },
      },
      '.reference-field': {
        width: '50%',
        display: 'inline-block',
        span: {
          paddingLeft: '5px',
        },
        '@media (max-width: 650px)': {
          width: '100%',
        },
      },
    },
    '.editable-section': {
      marginTop: '20px',
      paddingLeft: '20px',
      '.add-field-button': {
        color: variableColor + ' !important',
        paddingLeft: '10px',
        cursor: 'pointer',
        fontWeight: 'bold',
      },
      '.single-note': {
        marginBottom: '5px',
      },
      '.corrective-action-note': {
        marginBottom: '5px',
      },
      '.date': {
        color: variableColor,
      },
      input: {
        width: '80%',
        backgroundColor: '#FFFFFF',
        backgroundImage: 'none',
        border: '1px solid #e5e6e7',
        borderRadius: 0,
        padding: '6px 12px',
        transition:
          'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        fontSize: '14px',
        '@media (max-width: 650px)': {
          width: '100%',
        },
      },
    },
    '.image-section': {
      marginTop: '20px',
      paddingLeft: '20px',
      '.file-input': {
        cursor: 'pointer',
        color: variableColor + ' !important',
        marginLeft: '10px',
        input: { display: 'none' },
      },
      '.image-base': {
        '.singleImage': {
          marginRight: '20px',
          verticalAlign: 'top',
        },
        '.loading-screen': {
          paddingRight: '2%',
          backgroundColor: 'rgba(250,250,250,0.51)',
          width: '28%',
          height: '150px',
          display: 'inline-block',
          textAlign: 'center',
          '.loading-animation': {
            marginTop: '30%',
          },
        },
        '.failed-loading-screen': {
          width: '30%',
          height: '150px',
          display: 'inline-block',
          textAlign: 'center',
          paddingRight: '10px',
          '.remove-button': {
            textAlign: 'left',
            cursor: 'pointer',
            color: 'blue',
            textDecoration: 'underline',
            height: '10%',
          },
          '.fail-screen': {
            backgroundColor: 'rgba(250,250,250,0.51)',
            height: '90%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '.danger-text': {
              color: '#d54a43',
            },
          },
        },
      },
    },
    '.required-information': {
      marginTop: '20px',
      paddingLeft: '20px',
      paddingRight: '20px',
      input: {
        backgroundColor: '#FFFFFF',
        backgroundImage: 'none',
        border: '1px solid #e5e6e7',
        borderRadius: 0,
        padding: '6px 12px',
        transition:
          'border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s',
        fontSize: '14px',
      },
      '.inputs-row': {
        marginBottom: '20px',
        input: {
          width: '32%',
          '@media (max-width: 650px)': {
            width: '100%',
            marginBottom: '10px',
          },
        },
        '.middle-field': {
          marginRight: '2%',
          marginLeft: '2%',
          '@media (max-width: 650px)': {
            marginRight: '0',
            marginLeft: '0',
          },
        },
      },
      '.company-input-row': {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        '.company-field': {
          width: '400px',
          '@media (max-width: 650px)': {
            width: '100%',
          },
        },
      },
      '.button-row': {
        marginTop: '20px',
        display: 'flex',
        width: '100%',
        '.submit-button': {
          justifyContent: 'center',
          marginRight: 'auto',
          marginLeft: 'auto',
          backgroundColor: variableColor + ' !important',
          width: '240px',
          color: 'white',
          maxWidth: '100%',
          '@media (max-width: 650px)': {
            width: '100%',
          },
        },
      },
    },
  })
}
