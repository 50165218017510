import { ButtonElement, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { MenuItem, Select } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import classNames from 'classnames'

export const AddEditTrainingModal = ({
  setModalVisible,
  isEdit,
  startingData,
  setRefresh,
}) => {
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [textareaHeight, setTextareaHeight] = useState('30px')
  const textAreaRef = useRef()

  const [training, setTraining] = useState({
    name: '',
    expiration_duration: '',
    expiration_time_unit: 'select__placeholder',
    trade: '',
  })

  const modalRef = useRef()

  const handleChange = (e) => {
    setTraining({
      ...training,
      [e.target.name]: e.target.value,
    })
  }

  const baseStyle = getBaseStyle(isEdit)

  useEffect(() => {
    if (isEdit) {
      setTraining({
        name: startingData.name,
        expiration_duration: startingData.expiration_duration,
        expiration_time_unit: !startingData.expiration_time_unit
          ? 'select__placeholder'
          : startingData.expiration_time_unit,
        trade: startingData.trade,
      })
    }
  }, [startingData])

  const handleOutsideClick = (e) => {
    if (
      modalRef.current &&
      !modalRef.current.contains(e.target) &&
      !e.target.classList.contains('MuiMenuItem-root') &&
      !e.target.classList.contains('MuiSelect-root') &&
      !e.target.classList.contains('MuiList-root') &&
      !e.target.classList.contains('MuiBackdrop-root')
    ) {
      setModalVisible(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])

  const handleSubmit = async () => {
    if (!isEdit) {
      setButtonDisabled(true)
      const { data } = await Api.post('trainings', {
        ...training,
      })

      if (
        training.expiration_duration &&
        training.expiration_time_unit !== 'select__placeholder'
      ) {
        await Api.put(`trainings/${data.id}/set_expiration/`, {
          expiration_duration: training.expiration_duration,
          expiration_time_unit: training.expiration_time_unit,
        })
      }
    } else {
      setButtonDisabled(true)
      await Api.patch(`trainings/${startingData.id}`, {
        ...training,
        expiration_duration: training.expiration_duration
          ? training.expiration_duration
          : startingData.expiration_duration,
      })

      if (training.expiration_duration) {
        await Api.put(`trainings/${startingData.id}/set_expiration/`, {
          expiration_duration: training.expiration_duration,
          expiration_time_unit: training.expiration_time_unit,
        })
      }
    }
    setModalVisible(false)
    setRefresh(true)
  }

  const adjustTextareaHeight = () => {
    if (textAreaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textAreaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(`${rows * lineHeight}px`)
    }
  }

  const isDropdownRequired =
    training.expiration_duration &&
    training.expiration_time_unit === 'select__placeholder'

  useEffect(() => {
    if (textAreaRef?.current) {
      textAreaRef?.current?.addEventListener('focus', adjustTextareaHeight)
    }
  }, [])
  return (
    <div css={baseStyle} ref={modalRef}>
      <div className="fields__wrapper">
        <img
          className="modal__close"
          src={closeIcon}
          alt="close"
          onClick={() => {
            setModalVisible(false)
          }}
        />
        <div className="input__wrapper">
          <label className="input__label first name">Training Name: *</label>
          <textarea
            className={classNames('input first', {
              error: !training.name,
            })}
            name="name"
            placeholder="Enter Name"
            value={training.name}
            onChange={handleChange}
            onInput={adjustTextareaHeight}
            ref={textAreaRef}
            autoFocus
            style={{ height: `${textareaHeight}`, resize: 'vertical' }}
            onFocus={adjustTextareaHeight}
            onScroll={adjustTextareaHeight}
            onMouseEnter={adjustTextareaHeight}
          />
        </div>
        <div className="inputs__wrapper">
          <label className="input__label">Training Expires In:</label>
          <div className="inputs__container">
            <input
              className="input small"
              type="number"
              name="expiration_duration"
              placeholder="###"
              value={training.expiration_duration}
              onChange={handleChange}
            />
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="expiration_time_unit"
              value={training.expiration_time_unit}
              label="Duration"
              onChange={handleChange}
              placeholder="Duration"
              MenuProps={{
                sx: {
                  '&& .Mui-selected': {
                    backgroundColor: variableColor,
                    color: '#fff',
                    '& p': {
                      color: '#fff',
                    },
                  },
                },
              }}
              sx={{
                width: '223px',
                height: '30px',
                borderRadius: '0px',
                fontSize: '12px',
                '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline':
                  {
                    border: isDropdownRequired
                      ? '1px solid #C80404'
                      : '1px solid #CCC',
                  },
                '&.MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':
                  {
                    paddingLeft: '10px',
                  },
                '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline':
                  {
                    border: isDropdownRequired
                      ? '1px solid #C80404'
                      : '1px solid #CCC',
                  },
                fieldset: {
                  border: isDropdownRequired
                    ? '1px solid #C80404'
                    : '1px solid #CCC',
                },
              }}
            >
              <MenuItem
                value="select__placeholder"
                disabled
                sx={{
                  color: '#B3B3B3',
                  fontSize: '12px',
                  borderTop: '1px solid #E5E6E7',
                  borderBottom: '1px solid #E5E6E7',
                }}
              >
                <p
                  className="select__placeholder"
                  css={{
                    margin: 0,
                  }}
                >
                  Duration
                </p>
              </MenuItem>
              <MenuItem
                value={'days'}
                sx={{
                  fontWeight: '600',
                  color: '#676A6C',
                  fontSize: '12px',
                }}
              >
                Day
              </MenuItem>
              <MenuItem
                value={'months'}
                sx={{
                  borderTop: '1px solid #E5E6E7',
                  borderBottom: '1px solid #E5E6E7',
                  fontWeight: '600',
                  color: '#676A6C',
                  fontSize: '12px',
                }}
              >
                Month
              </MenuItem>
              <MenuItem
                value={'years'}
                sx={{
                  borderTop: '1px solid #E5E6E7',
                  borderBottom: '1px solid #E5E6E7',
                  fontWeight: '600',
                  color: '#676A6C',
                  fontSize: '12px',
                }}
              >
                Year
              </MenuItem>
            </Select>
          </div>
        </div>

        <div className="input__wrapper">
          <label className="input__label">Trade:</label>
          <input
            className="input trade"
            type="text"
            name="trade"
            placeholder="Enter Trade"
            onChange={handleChange}
            value={training.trade}
          />
        </div>
      </div>

      <div className="button__wrapper">
        <ButtonElement
          buttonType="submit"
          functionToTrigger={handleSubmit}
          text={isEdit ? 'Save' : 'Create'}
          disabled={!training.name || isButtonDisabled || isDropdownRequired}
        />
      </div>
    </div>
  )
}

function getBaseStyle(isEdit) {
  return css({
    background: '#fff',
    position: 'absolute',
    paddingInline: '30px',
    boxShadow: '0px 4px 7px 0px rgba(0, 0, 0, 0.25)',
    top: isEdit ? '-40px' : '130px',
    left: '170px',
    zIndex: 1,
    '@media(max-width: 800px)': {
      left: 0,
    },
    '.input__wrapper': {
      display: 'flex',
      flexDirection: 'column',
    },
    '.fields__wrapper': {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
      position: 'relative',
    },
    '.modal__close': {
      height: '12px',
      width: '12px',
      position: 'absolute',
      top: '10px',
      left: '311px',
      cursor: 'pointer',
    },
    '.input__label': {
      fontSize: '12px',
      letterSpacing: '-0.12px',
      color: '#575757',
    },
    '.name': {
      marginTop: '34px',
    },
    '.first': {
      textAlign: 'center',
      '&::placeholder': {
        textAlign: 'center',
      },
    },
    '.input': {
      width: '301px',
      height: '30px',
      border: '1px solid #C6C6C6',
      color: '#000',
      outline: 'none',
      padding: '5px',
      '&::placeholder': {
        color: '#B3B3B3',
        fontSize: '12px',
      },
    },
    '.trade': {
      paddingLeft: '10px',
    },
    '.error': {
      borderColor: '#C80404',
    },
    '.inputs__container': {
      display: 'flex',
      gap: '10px',
    },
    '.small': {
      width: '68px',
      paddingLeft: '10px',
    },
    '.button__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '30px',
      paddingBottom: '25px',
    },
    '.select__placeholder': {
      color: '#B3B3B3',
      margin: 0,
    },
    legend: {
      width: '0.01px',
    },
  })
}
