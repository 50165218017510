import { BinDeleteIcon } from '@/react/componentAssets'
import { AddNoteIcon } from '@/react/componentAssets/AddNoteIcon'
import { useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { Field, FieldArray, useFormikContext } from 'formik'
import ReactSelect from 'react-select'
import { TextInputStyle } from '@screens/components'
import { NestedFieldOption } from './NestedFieldOption'
import classNames from 'classnames'
import { AddNoteModal } from './AddNoteModal'
import { EditNoteIcon } from '@/react/componentAssets/EditNoteIcon'
import { useState } from 'react'
import { generateUUID } from '@/utils'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import manageIcon from '@/assets/icons/manage-icon.svg'
import { byOrder } from '@/utils/sortsAndFilter'

export const NestedField = ({
  field,
  questionIndex,
  optionIndex,
  fieldIndex,
  handleDelete,
}) => {
  const { CurrentUser } = useAngularServices()
  const [isNoteModalOpen, setNoteModalOpen] = useState(false)
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false)

  const { setFieldValue, setFieldTouched } = useFormikContext()

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const customSelectStyles = {
    control: (provided, state) => ({
      display: 'flex',
      width: '210px',
      minHeight: '32px',
      height: '32px',
      border: field_type ? '1px solid #e5e6e7' : '1px solid #C80404',
      background: '#FAFAFA',
      borderRadius: '0',
      outline: 'none',
      boxShadow: 'none',
      '&:hover': {
        cursor: 'pointer',
        border: '1px solid #e5e6e7',
      },
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '12px',
      color: '#B1B1B1',
    }),
    singleValue: (provided) => ({
      ...provided,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: 'none',
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: '12px',
      borderBottom: '1px solid #E5E6E7',
      background: state.isSelected
        ? variableColor
        : state.options.indexOf(state.data) % 2 === 1
        ? '#FAFAFA'
        : 'none',
      '&:hover': {
        background: variableColor,
      },
    }),
  }
  const selectOptions = [
    { value: 'options', label: 'Options' },
    { value: 'text', label: 'Text' },
  ]

  const addFieldOption = () => {
    if (field.options.length) {
      setFieldValue(
        `questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}].options`,
        [...field.options, { name: '', weight: '', id: generateUUID() }],
      )
    } else {
      setFieldValue(
        `questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}].options`,
        [{ name: '', weight: '', id: generateUUID() }],
      )
    }
  }

  const { name, field_type } = field

  return (
    <Draggable key={field.id} draggableId={'' + field.id} index={fieldIndex}>
      {(provided) => (
        <div
          css={baseStyle}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div className="nested__field__wrapper">
            <img
              src={manageIcon}
              alt="manage-icon"
              className="option__manage"
              {...provided.dragHandleProps}
            />
            <Field
              type="text"
              as={FormikAutoExpandedTextArea}
              name={`questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}].name`}
              className={classNames('field__input', {
                error: !name,
              })}
              placeholder="Enter Nested Field"
              value={name}
            />
            <ReactSelect
              options={selectOptions}
              value={field_type}
              name={`questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}].field_type`}
              onChange={(selectOption) =>
                setFieldValue(
                  `questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}]`,
                  {
                    ...field,
                    field_type: selectOption,
                    options: [],
                    note: '',
                  },
                )
              }
              styles={customSelectStyles}
            />
            {isNoteModalOpen ? (
              <AddNoteModal
                noteValue={field?.note}
                name={`questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}].note`}
                handleClose={() => {
                  setNoteModalOpen(false)
                }}
              />
            ) : null}
            <div className="icons__wrapper">
              {!field.note ? (
                <AddNoteIcon
                  color={variableColor}
                  onClick={() => {
                    setNoteModalOpen(true)
                  }}
                />
              ) : (
                <EditNoteIcon
                  color={variableColor}
                  onClick={() => {
                    setNoteModalOpen(true)
                  }}
                />
              )}
              <div className="delete__wrapper">
                {isDeleteModalOpen ? (
                  <ConfirmationModal
                    action={'Delete'}
                    handleClick={() => {
                      handleDelete(fieldIndex)
                      setFieldTouched('any', true)
                      setDeleteModalOpen(false)
                    }}
                    handleClose={() => {
                      setDeleteModalOpen(false)
                    }}
                  />
                ) : null}
                <BinDeleteIcon
                  color={variableColor}
                  height="22px"
                  width="17px"
                  onClick={() => {
                    setDeleteModalOpen(true)
                  }}
                />
              </div>
            </div>
          </div>
          {field.field_type.value === 'options' && (
            <p className="button" onClick={addFieldOption}>
              + Option
            </p>
          )}
          {field?.options?.length ? (
            <FieldArray
              name={`questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}].options`}
              render={(arrayHelpers) => {
                async function handleOnDragEnd(result) {
                  if (!result.destination) return

                  const items = Array.from(field.options)
                  const [reorderedItem] = items.splice(result.source.index, 1)
                  items.splice(result.destination.index, 0, reorderedItem)

                  await setFieldValue(
                    `questionsList[${questionIndex}].nested_options[${optionIndex}].nested_fields[${fieldIndex}].options`,
                    items,
                  )
                }

                return (
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="options">
                      {(provided) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          className="nested__field__options"
                        >
                          {field.options
                            .sort(byOrder)
                            .map((option, fieldOptionIndex) => (
                              <NestedFieldOption
                                key={option.id}
                                option={option}
                                questionIndex={questionIndex}
                                optionIndex={optionIndex}
                                fieldIndex={fieldIndex}
                                fieldOptionIndex={fieldOptionIndex}
                                handleDelete={arrayHelpers.remove}
                              />
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                )
              }}
            />
          ) : null}
        </div>
      )}
    </Draggable>
  )
}

const baseStyle = css({
  '.option__manage': {
    marginTop: '10px',
  },
  '.nested__field__wrapper': {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '16px',
    paddingTop: '10px',
    paddingLeft: '180px',
    position: 'relative',
    width: '1171px',
  },
  '.field__input': {
    ...TextInputStyle,
    width: '602px',
    background: '#FAFAFA',
    fontSize: '12px',
    height: '30px',
    '::placeholder': {
      color: '#B1B1B1',
    },
  },
  '.button': {
    paddingTop: '10px !important',
    paddingBottom: '10px !important',
    marginLeft: '257px !important',
  },
  '.modal__wrapper': {
    right: '9px !important',
  },
  '.nested__field__options': {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  '.icons__wrapper': {
    marginLeft: '11px',
    marginTop: '5px',
    display: 'flex',
    gap: '38px',
  },
  '.delete__wrapper': {
    position: 'relative',
  },
})
