import * as React from 'react'
import { getStartingValue } from '@screens/MobileForms/CreateEditPages/components'

export const useCreateSignatureAndSketchesElements = ({
  possibleGroupData,
  formMemory,
  setFormMemory,
  fieldData,
  createSingleElement,
  elementList,
  setElementList,
}) => {
  React.useEffect(() => {
    const startingValue = getStartingValue(
      possibleGroupData,
      formMemory,
      fieldData,
      fieldData.type,
    )
    const field = possibleGroupData
      ? formMemory[fieldData.id][possibleGroupData]
      : formMemory[fieldData.id]

    field.touched = true
    field.localAnswer[fieldData.type] = []
    setFormMemory(formMemory)

    const newElements = []
    if (startingValue instanceof Array) {
      startingValue.forEach((value) => {
        if (!value.deleted) {
          newElements.push(createSingleElement(value, value.printed_name))
        }
      })
    }
    setElementList([...elementList, ...newElements])
  }, [])
}
