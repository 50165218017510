import * as React from 'react'
import { css } from '@emotion/react'

import { useAngularServices } from '@/react/components'
import { WildCardsAndNotes } from '@screens/Observations/ViewTemplates/components'

export function Summery({ headerData, lists }: any) {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const baseStyle = getBaseStyle()

  return (
    <section css={baseStyle}>
      {headerData.observation_notes &&
      checkNoteAmount(headerData.observation_notes) ? (
        <WildCardsAndNotes
          headerData={headerData}
          tableStyle={false}
          variableColor={variableColor}
          wildcard={false}
        />
      ) : null}
      {headerData.observation_wildcards &&
      checkNoteAmount(headerData.observation_wildcards) ? (
        <WildCardsAndNotes
          headerData={headerData}
          tableStyle={false}
          variableColor={variableColor}
          wildcard={true}
        />
      ) : null}
    </section>
  )
}

function getBaseStyle() {
  return css({
    '.view__text': {
      display: 'contents',
      whiteSpace: 'pre-wrap',
    },
    '.prepend-time-display': {
      fontWeight: 'bold',
    },
    '.variable-color-text': {
      fontWeight: 'bold',
    },
  })
}

function checkNoteAmount(noteArray) {
  let count = 0
  noteArray.forEach((note) => {
    if (!note.deleted) count += 1
  })
  return count > 0
}

function getBatchProjectNameForNote(id, observationArray) {
  let returnString
  observationArray.forEach((observation) => {
    if (observation.id === id) returnString = observation.project.name
  })
  return returnString
}
