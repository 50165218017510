import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormDraftReportsCtrl',
    function (
      $scope,
      $state,
      Api,
      ngDialog,
      app,
      $stateParams,
      $timeout,
      $log,
    ) {
      $log.debug('FormDraftReportsCtrl')

      $scope.reports = []
      $scope.titlesApp = app
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'date_created'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.maxSize = 10 //Number of pager buttons to show

      //pageSize Used in the view
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.totalItems = $scope.pageNumber * $scope.pageSize

      $scope.titleSingularForm = pluralize.singular(app.app_mobile_form_display)

      $scope.breadcrumbs = [
        {
          title: 'Mobile Apps',
        },
        {
          title: app.name,
        },
      ]

      $scope.changePage = function () {
        $state.transitionTo(
          'app.forms.reports',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
        getDraftReports()
      }

      $scope.tableHeaders = [
        { key: 'report__title', display: 'Report Title', sortable: true },
        {
          key: 'form__name',
          display: $scope.titleSingularForm,
          sortable: true,
        },
        { key: 'user__first_name', display: 'Full Name', sortable: true },
        { key: 'company_name', display: 'Company', sortable: true },
        { key: 'project__name', display: 'Project Name', sortable: true },
        { key: 'date_created', display: 'Date Created', sortable: true },
        { key: 'view', display: 'View', centered: true },
        { key: 'resend', display: 'Resend Link', centered: true },
      ]

      $scope.breadcrumbs = [
        {
          title: 'Mobile Apps',
        },
        {
          title: app.name,
        },
      ]

      $scope.showUnsyncError = function () {
        $scope.error =
          'Attention: You may not edit a report that has not fully synchronized with the server. Please try again later.'
        $timeout(function () {
          $scope.error = null
        }, 3000)
      }

      $scope.resendLink = function (report_client_key, email) {
        ngDialog.open({
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          templateUrl: 'app/views/save_as_draft-or-submit-report_dialog.html',
          width: 300,
          /** @ngInject */
          controller: function controller($scope) {
            const key = report_client_key
            $scope.message = 'Click Ok to resend the draft link to '
            $scope.email = "'" + email + "'"

            $scope.save = function () {
              Api.post(
                `external_reports/${key}/resend_link`,
                {},
                () => {
                  getDraftReports()
                  $scope.cancel()
                },
                (err) => {
                  $scope.error = err.message
                },
              )
            }

            $scope.cancel = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      function getCurrentDate() {
        const dateObj = new Date()
        const month = dateObj.getUTCMonth() + 1 //months from 1-12
        const day = dateObj.getUTCDate()
        const year = dateObj.getUTCFullYear()

        return month + '/' + day + '/' + year
      }

      function getDraftReports() {
        Api.Reports.get(reportQuery(), function (resp) {
          $scope.reports = resp.results
          $scope.totalItems = resp.count
          $scope.reports.map(function (item) {
            item.form.report_title = item.form.name

            for (var i = 0; i < item.fields.length; i++) {
              item.form.report_title =
                item.fields[i].type === 'report_title' && item.fields[i].text
                  ? item.fields[i].text
                  : item.form.name + ' - ' + getCurrentDate()
            }
          })
        })
      }

      function reportQuery() {
        var query = {
          is_draft: true,
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.searchExecuted ? $scope.search : null,
          table: true,
          role: true,
          tz_offset: new Date().getTimezoneOffset(),
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return query
      }

      getDraftReports()

      $scope.goToReportsPage = function () {
        $state.go(
          'app.forms.reports',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { reload: true },
        )
      }
    },
  )
