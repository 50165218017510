import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height?: string | number
  width?: string | number
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: () => void
}

export const CheckIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      width="16"
      height="13"
      viewBox="0 0 16 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <path
        d="M5.13004 8.86099L13.5964 0.394618C13.8595 0.131539 14.1943 0 14.6009 0C15.0075 0 15.3423 0.131539 15.6054 0.394618C15.8685 0.657697 16 0.992525 16 1.3991C16 1.80568 15.8685 2.14051 15.6054 2.40359L6.13453 11.8744C5.84753 12.1614 5.51271 12.3049 5.13004 12.3049C4.74738 12.3049 4.41256 12.1614 4.12556 11.8744L0.394618 8.1435C0.131539 7.88042 0 7.54559 0 7.13901C0 6.73244 0.131539 6.39761 0.394618 6.13453C0.657697 5.87145 0.992526 5.73991 1.3991 5.73991C1.80568 5.73991 2.14051 5.87145 2.40359 6.13453L5.13004 8.86099Z"
        style={{ fill: 'currentcolor' }}
      />
    </svg>
  )
}
