import * as React from 'react'
import { css } from '@emotion/react'

import { useRouter } from '@/react/hooks'
import {
  useAngularServices,
  TableHeaders,
  Search,
  PageSwitcher,
} from '@/react/components'

import { TableList } from './components'
import { singleHeaderTypes } from '@screens/components'
import { singleSDSInterface } from '@screens/SafetyDataSheets/components/types'
import { parseInt } from 'lodash'
import { UISref } from '@/react/components/UISref'
import paperClip from '/src/assets/icons/Icons_Paperclip.svg'
import classNames from 'classnames'

const PAGE_LENGTH = 20

interface List {
  count: number
  next: string
  previous: string
  results: singleSDSInterface[]
}

// @ts-ignore
export function Table() {
  const { Api, uiPermissionService, $rootScope } = useAngularServices()
  const adminStatus = uiPermissionService.getPermissionViewAdministration()

  const { stateService } = useRouter()
  const variableProjectsName = $rootScope.mobile_apps.filter((obj) => {
    return obj.id === Number(stateService.params.app)
  })[0].projects

  const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
    {
      className: 'manufacturer',
      name: 'Manufacturer',
      listLocation: 'app.safety_data_sheets_list',
      type: 'manufacturer',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: '36px',
        },
      },
    },
    {
      className: 'issue',
      name: 'Issue',
      listLocation: 'app.safety_data_sheets_list',
      type: 'issue_date',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'review_date',
      name: 'Review',
      listLocation: 'app.safety_data_sheets_list',
      type: 'review_date',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'hazards',
      name: '311/312 Hazard',
      listLocation: 'app.safety_data_sheets_list',
      type: 'hazards',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'toxic',
      name: '313 Toxic Chemical',
      listLocation: 'app.safety_data_sheets_list',
      type: 'toxic_chemical',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'physicalHazard',
      name: 'WHMIS Physical Hazard',
      listLocation: 'app.safety_data_sheets_list',
      type: 'whmis_physical_hazards',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'healthlHazard',
      name: 'WHMIS Health Hazard',
      listLocation: 'app.safety_data_sheets_list',
      type: 'whmis_health_hazards',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'health',
      name: 'Health',
      listLocation: 'app.safety_data_sheets_list',
      type: 'health',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'flammability',
      name: 'Flammability',
      listLocation: 'app.safety_data_sheets_list',
      type: 'flammability',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'PH',
      name: 'Physical hazards',
      listLocation: 'app.safety_data_sheets_list',
      type: 'physical_hazards',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'PPE',
      name: 'PPE',
      listLocation: 'app.safety_data_sheets_list',
      type: 'ppe',
      filterHeader: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'projects',
      name: variableProjectsName,
      filterHeader: false,
      adminOnly: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'download',
      name: 'Download',
      filterHeader: false,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'share',
      name: 'Share',
      filterHeader: false,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
    {
      className: 'archive',
      name: 'Archive',
      filterHeader: false,
      adminOnly: true,
      additionalStyles: {
        p: {
          margin: 0,
        },
        th: {
          height: 36,
        },
      },
    },
  ]

  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [order, setOrder] = React.useState(stateService.params.order || 'name')
  const [search, setSearch] = React.useState(stateService.params.search || '')
  const application = stateService.params.app
  const [userBrowser, setUserBrowser] = React.useState('')

  const [list, setList] = React.useState<List>({
    count: 0,
    next: '',
    previous: '',
    results: [],
  })

  async function fetchData() {
    const { data } = await Api.SDSForm.get({
      page,
      order,
      deleted: false,
      page_size: PAGE_LENGTH,
      application,
      search,
    })
    setList(data)
  }

  React.useEffect(() => {
    fetchData()
  }, [page, order, search])

  function nameCropper(name: string) {
    if (name.length <= 30) return name
    const [firstWord, secondWord] = name.split(' ')
    if (!secondWord) return name.slice(30) + '...'

    const trimmedFirstLine =
      firstWord.length > 30 ? firstWord.slice(30) + '... ' : firstWord + ' '
    const trimmedSecondLine =
      secondWord.length > 30 ? secondWord.slice(30) + '...' : secondWord

    return (
      <span>
        {trimmedFirstLine}
        <br />
        {trimmedSecondLine}
      </span>
    )
  }

  const sdsNames = React.useMemo(() => {
    return list.results.map((sds) => ({
      id: sds.id,
      name: sds.name,
      pdf: sds.pdf,
    }))
  }, [list])

  React.useEffect(() => {
    const userAgent = window.navigator.userAgent

    if (userAgent.includes('Chrome')) {
      setUserBrowser('Chrome')
    } else if (userAgent.includes('Safari')) {
      setUserBrowser('Safari')
    }
  }, [])

  const baseStyle = getBaseStyle(userBrowser)

  return (
    <div css={baseStyle}>
      <Search
        search={search}
        searchCallback={setSearch}
        pageCallback={setPage}
      />
      <div className="whole__table">
        <div className="locked__column">
          <p className="locked__title">SDS</p>
          {sdsNames.map((sds, index) => (
            <div
              className={classNames('sds__column', {
                dark: index % 2 !== 0,
              })}
            >
              <span className={'pin-place ' + (sds.pdf ? 'active-pin' : '')} />
              {adminStatus ? (
                <UISref
                  to="app.safety_data_sheets_edit"
                  params={{ key: sds.id }}
                >
                  <a className="ccs-link">{nameCropper(sds.name)}</a>
                </UISref>
              ) : (
                <div className="ccs-link">{nameCropper(sds.name)}</div>
              )}
            </div>
          ))}
        </div>
        <div className="table-base">
          <table className={'dual-color-table'}>
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              popUpLocation={true}
              callback={setOrder}
            />
            <TableList rows={list.results} />
          </table>
        </div>
      </div>
      <PageSwitcher
        pageLength={PAGE_LENGTH}
        listCount={list.count}
        currentPage={page}
        callback={setPage}
      />
    </div>
  )
}

function getBaseStyle(userBrowser: string) {
  return css({
    overflow: 'hidden',
    backgroundColor: 'white',
    paddingTop: '20px',
    width: '100%',
    marginTop: '1px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingLeft: '25px',
    paddingRight: '25px',
    paddingBottom: '20px',
    '.locked__title': {
      margin: '0',
      marginTop: '20px',
      paddingBottom: userBrowser === 'Safari' ? '16px' : '17px',
      borderBottom: '2px solid rgb(198, 198, 198)',
      fontWeight: 650,
      paddingLeft: '15px',
    },
    '.sds__column': {
      display: 'flex',
      alignItems: 'center',
      height: '37px',
      borderBottom: '1px solid rgb(229, 230, 231)',
      verticalAlign: 'middle',
      paddingLeft: '15px',
    },
    '.dark': {
      background: '#F2F3F5',
    },
    '.whole__table': {
      display: 'flex',
    },
    '.locked__column': {
      width: '20%',
    },
    '.pin-place': {
      height: '20px',
    },
    '.active-pin': {
      width: '20px',
      backgroundImage: 'url(' + paperClip + ')',
    },
    '.table-base': {
      overflowX: 'scroll',
      width: '80%',
    },
    table: {
      minWidth: '100%',
    },
    thead: {
      borderBottom: '2px solid rgb(198, 198, 198)',
    },
    th: {
      textAlign: 'center',
      height: '40px',
      paddingRight: '5px',
      paddingLeft: '5px',
      whiteSpace: 'normal',
      width: '20px',
    },
    tr: {
      backgroundColor: 'white',
      height: '37px',
      whiteSpace: 'nowrap',
    },
    td: {
      whiteSpace: 'nowrap',
      paddingRight: '10px',
      paddingLeft: '10px',
      height: '37px',
      maxHeight: '37px',
      width: 'auto',
      '.various-block': {
        position: 'relative',
        span: {
          zIndex: 100,
        },
        '.hover-block': {
          zIndex: 1000,
          display: 'none',
          padding: '10px',
          borderBottomStyle: 'solid',
          borderStyle: 'solid',
          borderColor: '#e7eaec',
          position: 'absolute',
          left: 0,
          top: 0,
          backgroundColor: 'white',
        },
      },
      '.various-block:hover': {
        '.hover-block': {
          display: 'block',
        },
      },
    },
    '.manufacturer': {
      textAlign: 'left',
    },
    '.startingColumn': {
      backgroundColor: 'inherit',
      left: 0,
      textAlign: 'left',
      paddingLeft: '10px',
      position: 'sticky',
      justifyContent: 'left',
      zIndex: 7000,
    },
    '@media(max-width: 1000px)': {
      '.locked__column': {
        width: '150px',
      },
      '.sds__column': {
        height: '65px',
      },
      td: {
        height: '65px',
      },
      '.active-pin': {
        height: '20px',
        width: '30px',
      },
    },
  })
}
