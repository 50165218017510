import { UISref } from '@/react/components/UISref'
import { css } from '@emotion/react'
import classNames from 'classnames'
import viewIcon from '@/assets/icons/view-icon.svg'
import draftIcon from '@/assets/icons/draft-icon.svg'
import deleteIcon from '@/assets/icons/delete-nav-icon.svg'
import downloadIcon from '@/assets/icons/download-nav-icon.svg'
import submitIcon from '@/assets/icons/submit-nav-icon.svg'
import { useRouter } from '@/react/hooks'
import { useAngularServices } from '@/react/components'
import { useEffect } from 'react'

export const MobileFormsNavBar = () => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const role = CurrentUser.getRole()

  useEffect(() => {
    if (
      role === 'read_only' ||
      role === 'trainee' ||
      role === 'trainee_manager'
    ) {
      stateService.go('trainings.documentation')
    }
  }, [])

  return (
    <div>
      <div className="tab-button-list" css={tabButtonList}>
        <UISref to={'app.forms.reports'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'app.forms.reports' ||
                stateService.current.name === 'app.forms.reports_edit',
            })}
          >
            <img className="nav__icon" src={viewIcon} alt="view nav__icon" />
            <p>View</p>
          </a>
        </UISref>
        <UISref to={'app.forms.add_report'}>
          <a
            className={classNames('tab', {
              active:
                stateService.current.name === 'app.forms.add_report' ||
                stateService.current.name === 'app.forms.reports_create',
            })}
          >
            <img className="nav__icon" src={submitIcon} alt="view nav__icon" />
            <p>Submit</p>
          </a>
        </UISref>
        {role !== 'client_user' && role !== 'supervisor' && (
          <UISref to={'app.forms.download_report_data'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name ===
                    'app.forms.download_report_data' ||
                  stateService.current.name === 'app.forms.reports_export_list',
              })}
            >
              <img
                className="nav__icon"
                src={downloadIcon}
                alt="view nav__icon"
              />
              <p>Download</p>
            </a>
          </UISref>
        )}
        {role !== 'client_user' && role !== 'supervisor' && (
          <UISref to={'app.forms.deleted_reports'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name === 'app.forms.deleted_reports',
              })}
            >
              <img
                className="nav__icon"
                src={deleteIcon}
                alt="view nav__icon"
              />
              <p>Deleted</p>
            </a>
          </UISref>
        )}

        {role !== 'client_user' && role !== 'supervisor' && (
          <UISref to={'app.forms.draft_reports_list'}>
            <a
              className={classNames('tab', {
                active:
                  stateService.current.name === 'app.forms.draft_reports_list',
              })}
            >
              <img className="nav__icon" src={draftIcon} alt="view nav__icon" />
              <p>Draft</p>
            </a>
          </UISref>
        )}
      </div>
    </div>
  )
}

const tabButtonList = css({
  width: '100%',
  marginTop: '20px',
  display: 'flex',
  '@media(max-width: 800px)': {
    '.tab': {
      p: {
        display: 'none',
      },
      '.nav__icon': {
        marginInline: '17px !important',
      },
    },
    '.active': {
      p: {
        display: 'flex',
      },
      '.nav__icon': {
        marginLeft: '25px !important',
        marginRight: '10px !important',
      },
    },
  },
  '.tab': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    height: '45px',
    backgroundColor: '#E6E6E6',
    color: 'inherit',
    margin: 0,
    borderRight: '1px solid #D3D3D3',
    '.nav__icon': {
      marginLeft: '25px',
      marginRight: '10px',
      width: '15px',
      height: '15px',
    },
    p: {
      marginLeft: 0,
      marginRight: '25px',
      marginBottom: 0,
    },
  },
  '.active': {
    backgroundColor: 'white',
  },
  '.upload__nav__icon': {
    width: '11px',
    height: '11px',
    marginLeft: '10px',
  },
})
