import * as React from 'react'
import { css } from '@emotion/react'
import { percentageShadeBlendColor } from '@/utils/colorAdjustment'

import {
  useAngularServices,
  CommonPageHeader,
  ButtonElement,
  DropZone,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { parseInt } from 'lodash'
import { PartyObservedNavBar } from '../components/PartyObservedNavBar'

export const PartyObservedBulk = () => {
  const { Api, Notification, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const [file, setFile] = React.useState(null)
  const [errorLog, setErrorLog] = React.useState([])
  const [messageLog, setMessageLog] = React.useState([])
  const userSettings = CurrentUser.getClientSettings()
  const [POVariableName] = React.useState(userSettings.party_observed_language)
  const [buttonDisabled, setButtonDisabled] = React.useState(false)
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  function fileAddCallback(file) {
    setErrorLog([])
    setMessageLog([])
    setButtonDisabled(false)
  }
  function fileRemoveCallback() {
    setErrorLog([])
    setMessageLog([])
    setButtonDisabled(false)
  }

  async function sendFile() {
    const errorMessagesArray = []
    const messagesArray = []
    try {
      await Api.uploadBulkPartyObserved(file)

      stateService.go('admin.party_observed_list', {})
    } catch (error) {
      setButtonDisabled(true)
      Notification.clearNotification()
      if (error.data.length === 1) {
        errorMessagesArray.push(<div>{error.data[0]}</div>)
        setErrorLog(errorMessagesArray)
        return
      }
      error.data.message.forEach((message) => {
        if (message.error) {
          errorMessagesArray.push(
            <div>
              <span className="glyphicon glyphicon-warning-sign ccs-link" />{' '}
              Row: {message.row}: {message.error}
            </div>,
          )
        } else if (parseInt(message.message.substring(0, 1)) !== 0) {
          messagesArray.push(
            <div>
              <span className="glyphicon glyphicon-warning-sign ccs-link" />{' '}
              {message.message}
            </div>,
          )
        }
      })
      setErrorLog([...errorMessagesArray])
      setMessageLog([...messagesArray])
    }
  }

  return (
    <section>
      <CommonPageHeader headerText={POVariableName} />
      <PartyObservedNavBar />
      <section css={baseStyle}>
        <div className="example-row">
          Examples:{' '}
          <a
            download
            href="/assets/party_observed_bulk_upload_example.xlsx"
            target="_self"
            css={css({
              color: variableColor,
              ':hover': {
                color: percentageShadeBlendColor(-0.4, variableColor),
              },
            })}
          >
            xlsx,
          </a>
          <a
            download
            href="/assets/party_observed_bulk_upload_example.csv"
            target="_self"
            css={css({
              color: variableColor,
              ':hover': {
                color: percentageShadeBlendColor(-0.4, variableColor),
              },
            })}
          >
            csv
          </a>
        </div>
        <DropZone
          fileSetStorage={setFile}
          fileStorage={file}
          addCallback={fileAddCallback}
          removeCallback={fileRemoveCallback}
          dropMessage={<p>Drop the file here ...</p>}
          hoverMessage={
            <p>
              {' '}
              <b>Choose a file</b> or drag it here.
            </p>
          }
          additionalStyles={css({ width: 800, height: 350 })}
        />
        <div className="danger-info">
          Please keep the bulk upload under 2000 records. 255 Characters Max for
          each item.
        </div>
        {messageLog.length ? (
          <div className="message-box">
            <label>Summary:</label>
            {messageLog}
          </div>
        ) : null}
        {errorLog.length ? (
          <div className="error-box">
            <label>Errors found in file:</label>
            {errorLog}
          </div>
        ) : null}
        <div className="button-row">
          <ButtonElement
            text={'Cancel'}
            functionToTrigger={() => {
              stateService.go('admin.party_observed_list', {})
            }}
            width={'auto'}
          />
          <ButtonElement
            text={'Upload'}
            functionToTrigger={sendFile}
            disabled={!file || buttonDisabled}
            buttonType={'submit'}
            width={'auto'}
            additionalStyles={css({ marginLeft: '15px' })}
          />
        </div>
      </section>
    </section>
  )
}

const baseStyle = css({
  width: 'fit-content',
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '100%',
  backgroundColor: 'white',
  padding: 40,
  marginLeft: 1,
  '.example-row': {
    textAlign: 'right',
  },
  '.danger-info': {
    color: 'red',
    marginTop: 10,
    marginBottom: 10,
  },
  '.button-row': {
    textAlign: 'right',
  },
  '.error-box': {
    label: {
      marginTop: 5,
    },
  },
})
