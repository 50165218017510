angular
  .module('ccs')
  .directive('showhidecompletedtrainings', showHideCompletedTrainings)

function showHideCompletedTrainings($log) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/show_hide_completed_trainings.html',
    link: function ($scope) {
      $scope.status = 100
      $scope.completed = true
      $scope.handleShowHide = () => {
        $scope.status = !$scope.status
        $scope.completed = !$scope.completed
        try {
          $scope.showHideCompletedTrainings()
        } catch (e) {
          $log.warn('showHideCompletedTrainings() not implemented!')
        }
      }
    },
  }
}
