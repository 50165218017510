import * as React from 'react'
import { css } from '@emotion/react'
import { getTranslatedString } from '@/utils'
import { SignatureAndSketch, useAngularServices } from '@/react/components'
import { useCreateSignatureAndSketchesElements } from './hooks'
import {
  SingleFile,
  NoteElement,
  getStartingValue,
} from '@screens/MobileForms/CreateEditPages/components'
import { useRouter } from '@/react/hooks'
import { AddSignatureIcon } from '@/react/componentAssets'
import { without } from 'lodash'

export const SignaturesAndSketchesFields = ({
  fieldData,
  formMemory,
  setFormMemory,
  possibleGroupData,
  sketchOrSignatureTextMemory,
  addSketchOrSignatureText,
  onChange,
}) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const isSignature = fieldData.type === 'signatures'

  const [isCanvasVisible, setIsCanvasVisible] = React.useState<boolean>(false)
  const [elementList, setElementList] = React.useState([])
  const [error, setError] = React.useState(false)
  const [isEmpty, setEmpty] = React.useState(false)

  function signatureOrSketchTextCallback(value, id, typingStart = false) {
    addSketchOrSignatureText(
      { id: id, printed_name: value },
      isSignature,
      typingStart,
    )

    setError(false)
  }

  function removeFunction(id) {
    const field = possibleGroupData
      ? formMemory[fieldData.id][possibleGroupData]
      : formMemory[fieldData.id]

    field.touched = true
    field.localAnswer[fieldData.type] = without(
      field.localAnswer[fieldData.type],
      id,
    )
    setFormMemory(formMemory)
    addSketchOrSignatureText(
      { id: id, deleted: true },
      isSignature,
      sketchOrSignatureTextMemory,
    )
    if (fieldData.required) {
      const filesFromMemory = getStartingValue(
        possibleGroupData,
        formMemory,
        fieldData,
        fieldData.type,
        [],
      )
      setEmpty(!filesFromMemory.length)
    }
  }

  const addUploadableElement = async (value) => {
    setEmpty(false)
    setError(false)
    const { data } = await Api.uploadImage(value, {})
    const signatureSketchResp = await Api.post(fieldData.type, {
      image: data.id,
      user:
        stateService.current.name === 'assigned.external_form'
          ? null
          : data.user.id,
    })

    addSketchOrSignatureText(
      { id: signatureSketchResp.data.id },
      isSignature,
      sketchOrSignatureTextMemory,
    )

    onChange({
      value: signatureSketchResp.data.id,
      possibleGroupData,
      id: fieldData.id,
      fieldType: fieldData.type,
    })

    const singleFileElement = (
      <SingleFile
        fileUrl={data.image}
        removeFunction={removeFunction}
        id={signatureSketchResp.data.id}
        fileName=""
        signatureOrSketch
        signatureOrSketchTextCallback={signatureOrSketchTextCallback}
        key={data.id}
        isSignature={isSignature}
      />
    )

    setElementList([...elementList, singleFileElement])
  }

  const createSingleElement = (value, possibleName: string = '') => {
    setError(false)

    onChange({
      value: value.id,
      possibleGroupData,
      id: fieldData.id,
      fieldType: fieldData.type,
    })

    return (
      <SingleFile
        fileUrl={value.image.image}
        removeFunction={removeFunction}
        id={value.id}
        fileName={possibleName}
        signatureOrSketch
        signatureOrSketchTextCallback={signatureOrSketchTextCallback}
        key={value.image.id}
        isSignature={isSignature}
      />
    )
  }

  useCreateSignatureAndSketchesElements({
    possibleGroupData,
    formMemory,
    setFormMemory,
    fieldData,
    createSingleElement,
    elementList,
    setElementList,
  })

  React.useEffect(() => {
    setEmpty(fieldData.required && elementList.length === 0)
  }, [elementList])

  const baseStyle = getBaseStyle(variableColor, isEmpty)

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div>
        <div className="label-holder">
          <label css={css({ color: !error ? 'inherit' : 'red' })}>
            {getTranslatedString(fieldData.name)}
            {fieldData.required ? ' *' : null}
          </label>
          {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
          {isCanvasVisible && (
            <SignatureAndSketch
              hide={() => setIsCanvasVisible(false)}
              onSubmit={addUploadableElement}
              key="canvas"
            />
          )}
        </div>
        {isSignature ? (
          <AddSignatureIcon
            height={'auto'}
            width={'30px'}
            color={isEmpty ? '#C80404' : variableColor}
            onClick={() => {
              setIsCanvasVisible(true)
            }}
          />
        ) : (
          <h3
            className="add-signature"
            onClick={() => {
              setIsCanvasVisible(true)
            }}
          >
            + Add {fieldData.type}
          </h3>
        )}
      </div>
      <div className="signature-holder">{elementList}</div>
    </section>
  )
}

function getBaseStyle(variableColor, isEmpty) {
  return css({
    marginBottom: 15,
    '.signature-holder': {
      display: 'flex',
      flexWrap: 'wrap',
    },
    '.add-signature': {
      color: isEmpty ? '#C80404' : variableColor,
      cursor: 'pointer',
    },
  })
}
