angular.module('ccs').directive('divisionfilter', divisionFilter)

function divisionFilter(Api) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/division_filter.html',
    link: function ($scope) {
      function getDivisions(search = '') {
        const payload = {
          search,
          page_size: 100,
        }
        Api.get('users/divisions', payload, (resp) => {
          $scope.divisions = resp.results
        })
      }

      getDivisions()

      $scope.selected = () => {
        $scope.pageNumber = 1
        $scope.changePage()
      }

      $scope.refresh = (search) => {
        getDivisions(search)
      }
    },
  }
}
