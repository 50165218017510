import * as React from 'react'
import {
  useAngularServices,
  ButtonElement,
  CommonPageHeader,
  TextInput,
} from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import pluralize from 'pluralize'

import {
  SignatureElement,
  NotesElement,
  fetchData,
  sendForm,
  Wildcard,
  PartyObservedField,
  Category,
} from './components'
import { UISref } from '@components/UISref'
import {
  ApplicationConfig,
  GeneralSettings,
  GenericOption,
  NoteType,
  ObservationBody,
  ReportAnswer,
  ReportQuestion,
  ReportSettings,
  SignatureType,
} from '@/react/types'
import { difference, isEqual } from 'lodash'
import { TextInputStyle } from '@screens/components'
import moment from 'moment/moment'
import { ObservationNavBar } from '@screens/Observations/components/ObservationNavBar'

const ANSWER: ReportAnswer = {
  answer: 'n/a',
  deleted: false,
  party_observed: [],
  severity: 1,
  answer_party_observed_answer_list: [],
  copy_sequence_number: 0,
  edited: false,
}

export function CreateEditBase() {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const { stateService } = useRouter()

  const observationID = stateService.params.observation
  const appID = stateService.params.app
  const userId = CurrentUser.getId()

  const [baseObservationData, setBaseObservationData] =
    React.useState<ObservationBody | null>(null)
  const [questionData, setQuestionData] = React.useState<
    ReportQuestion[] | null
  >(null)
  const [settings, setSettings] = React.useState<{
    generalSettings: GeneralSettings
    reportSettings: ReportSettings
  } | null>(null)
  const [wildcard, setWildcard] = React.useState<string>('')
  const [wildcardId, setWildcardId] = React.useState<string | number>('')
  const [memoryState, setMemoryState] = React.useState<{
    [key: string]: ReportAnswer[]
  } | null>(null)
  const [signatures, setSignatures] = React.useState<SignatureType[]>([])
  const [notes, setNotes] = React.useState<NoteType[]>([])
  const [partyObservedOptions, setPartyObservedOptions] = React.useState<
    GenericOption[]
  >([])
  const [globalPartyObserved, setGlobalPartyObserved] = React.useState<
    GenericOption[]
  >([])
  const [disableButton, setDisableButton] = React.useState<boolean>(false)
  const [saveButtonPressed, setSaveButtonPressed] = React.useState<number>(0)

  const setGlobalPO = (POs: GenericOption[]) => {
    if (!questionData) return
    setGlobalPartyObserved(POs)
  }

  React.useEffect(() => {
    const requestParams = {
      deleted: '',
      observation: observationID,
      page_size: 1000,
      application: appID,
    }
    fetchData(
      Api,
      requestParams,
      observationID,
      userId,
      setPartyObservedOptions,
      setBaseObservationData,
      setQuestionData,
      setNotes,
      setSignatures,
      setMemoryState,
      setSettings,
      ANSWER,
      setWildcard,
      setWildcardId,
    )
  }, [appID, observationID])

  if (!memoryState || !baseObservationData || !questionData) return null

  const app: ApplicationConfig = baseObservationData?.application

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={app.observations_display} />
      <ObservationNavBar />
      <section className="page-wrap">
        <section className="header-section">
          <div className="header-part">
            <label>Title</label>
            <TextInput
              valueCallback={(value: string) => {
                baseObservationData.title = value
              }}
              defaultValue={baseObservationData.title}
              placeholder={'Optional'}
              height={'34px'}
              waitTime={1}
              textLimit={250}
            />
          </div>
          <div className="header-part">
            <label>Date Performed</label>
            <div
              css={css({
                ...TextInputStyle,
                backgroundColor: '#E6E6E6',
                height: 34,
                width: 185,
              })}
            >
              {moment(
                baseObservationData.date_performed ||
                  baseObservationData.date_created,
              ).format('MM/DD/YYYY')}
            </div>
          </div>
        </section>
        <hr />
        <div className="notes-wildcard-row">
          <NotesElement
            notesVariableName={app.observation_note_header}
            observationVariableName={app.observations_display}
            notes={notes}
            observationID={observationID}
            width={settings?.reportSettings.wildcard_active ? '30%' : '45%'}
          />
          <PartyObservedField
            options={partyObservedOptions}
            setOptions={setPartyObservedOptions}
            setGlobalPO={setGlobalPO}
            partyObservedVariableFiled={app.observation_party_observed}
            width={settings?.reportSettings.wildcard_active ? '30%' : '45%'}
          />
          {settings?.reportSettings.wildcard_active && (
            <Wildcard
              wildcardHeader={settings.reportSettings.wildcard_display}
              wildcard={wildcard}
              setWildcard={setWildcard}
              required={settings.reportSettings.wildcard_required}
              width={'30%'}
            />
          )}
        </div>
        <hr />
        {baseObservationData?.categories.map((category, idx) => (
          <Category
            key={category.id}
            category={category}
            questions={questionData}
            globalPartyObserved={globalPartyObserved}
            partyObservedOptions={partyObservedOptions}
            setPartyObservedOptions={setPartyObservedOptions}
            memoryState={memoryState}
            setMemoryState={setMemoryState}
            ANSWER={ANSWER}
            app={app}
            saveButtonPressed={saveButtonPressed}
            index={idx}
          />
        ))}
        <SignatureElement signatures={signatures} />
        <div className="bottom-buttons">
          <UISref
            to="app.observations.list"
            params={{
              pageNumber: stateService.params.previousPageNumber,
              search: stateService.params.previousSearch,
              order: stateService.params.previousOrder,
              reverse: stateService.params.previousReverse,
            }}
          >
            <a>
              <ButtonElement text={'Back'} functionToTrigger={() => {}} />
            </a>
          </UISref>
          <ButtonElement
            text={'Save'}
            functionToTrigger={() => {
              sendForm(
                memoryState,
                Api,
                signatures,
                notes,
                observationID,
                stateService,
                Notification,
                setDisableButton,
                wildcard,
                wildcardId,
                baseObservationData,
              )
              setSaveButtonPressed(saveButtonPressed + 1)
            }}
            disabled={
              disableButton ||
              (wildcard.length === 0 &&
                settings?.reportSettings.wildcard_required)
            }
            debounceTimeout={500}
            buttonType={'submit'}
          />
        </div>
      </section>
    </section>
  )
}

const baseStyle = css({
  paddingBottom: '100px',
  '.notes-wildcard-row': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,
  },
  '.page-wrap': {
    margin: '0px 10px 60px 0px',
    backgroundColor: 'white',
    padding: '15px 20px 20px 20px',
    '.header-section': {
      padding: 10,
      display: 'flex',
      justifyContent: 'space-between',
      '.header-part': {
        width: '45%',
      },
    },
  },
  '.bottom-buttons': {
    a: {
      width: '6%',
    },
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
})
