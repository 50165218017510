import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const DownloadIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="12 12 58 58"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <g id="icons">
        <path
          className="cls-1"
          style={{ fill: 'currentColor' }}
          d="M52.13,35.37a1.24,1.24,0,0,0-1.76,0L42.5,43.23v-27a1.25,1.25,0,0,0-2.5,0v27l-7.87-7.86a1.24,1.24,0,1,0-1.76,1.76l10,10a1.23,1.23,0,0,0,1.76,0l10-10A1.24,1.24,0,0,0,52.13,35.37Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'currentColor' }}
          d="M58.25,57.5h-34A9.26,9.26,0,0,1,15,48.25v-17a1.25,1.25,0,0,1,2.5,0v17A6.76,6.76,0,0,0,24.25,55h34A6.76,6.76,0,0,0,65,48.25v-17a1.25,1.25,0,0,1,2.5,0v17A9.26,9.26,0,0,1,58.25,57.5Z"
        />
      </g>
    </svg>
  )
}
