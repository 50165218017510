import * as React from 'react'
import { css } from '@emotion/react'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import pluralize from 'pluralize'
import { parseDate } from '@/utils/parseDate'

import { ImageWithPreview } from '@screens/Observations/components'
import { changeBackground } from '@/utils'
import {
  DataWrap,
  NoteType,
  ObservationBody,
  ReportCorrectiveAction,
  ReportPhoto,
  ReportQuestion,
} from '@/react/types'

export type SingleAnswer = {
  id: number
  corrective_actions: ReportCorrectiveAction[]
  notes: NoteType[]
  observation: ObservationBody
  photos: ReportPhoto[]
  question: ReportQuestion
  synchronised: Array<{
    user: number
    synchronised: boolean
  }>
  answer_party_observed_answer_list: Array<{
    id: number
    party_observed: {
      id: number
      name: string
      deleted: boolean
      is_active: boolean
      date_created: string
      date_updated: string
      fieldmeta: any
      client: number
      user: any
    }
    deleted: boolean
    fieldmeta: any
    answer: number
  }>
  answer: string
  reference: string
  severity: number
  copy_sequence_number: number
  category: number
  user: number
}

const answerFields = {
  no: <div className="no answer-field">FOLLOW UP REQUIRED</div>,
  pr: <div className="pr answer-field">PENDING REVIEW</div>,
  pa: <div className="pa answer-field">PENDING APPROVAL</div>,
  cls: <div className="cls answer-field">ISSUE RESOLVED</div>,
  yes: <div className="yes answer-field">YES</div>,
}

export function AnswerReadOnly() {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const [pageData, setPageData] = React.useState<SingleAnswer>(null)

  console.log(pageData)

  React.useEffect(() => {
    const fetchData = async () => {
      const { data: pageDataGet }: DataWrap<SingleAnswer> = await Api.get(
        'shares/answer/' + stateService.params.key,
        {
          deleted: false,
        },
      )
      const changingAnswer = {
        ...pageDataGet,
        answer_party_observed_answer_list: [],
        corrective_actions: [],
        notes: [],
      }
      pageDataGet.answer_party_observed_answer_list.forEach((po) => {
        if (!po.deleted)
          changingAnswer.answer_party_observed_answer_list.push(po)
      })
      pageDataGet.corrective_actions.forEach((ca) => {
        if (!ca.deleted) changingAnswer.corrective_actions.push(ca)
      })
      pageDataGet.notes.forEach((note) => {
        if (!note.deleted) changingAnswer.notes.push(note)
      })
      changeBackground(
        changingAnswer.observation.client.general_settings.colors
          .secondary_color,
      )
      setPageData(changingAnswer)
    }
    fetchData()
  }, [])

  if (pageData) {
    const app = pageData.observation.application

    const severityLevelElements = {
      1: <div className="severity-text">{app.observation_low_severity}</div>,
      2: <div className="severity-text">{app.observation_medium_severity}</div>,
      3: <div className="severity-text">{app.observation_high_severity}</div>,
    }

    return (
      <section css={baseStyle}>
        <div className="observation-header">
          <h2>{pluralize.singular(app.observations_display)} Report</h2>
        </div>
        <section className="header-section">
          <div className="header-part">
            <label>{pluralize.singular(app.projects_display)} Name:</label>
            <div>{pageData.observation.project.name}</div>
          </div>
          <div className="header-part">
            <label>Performed By:</label>
            <div>
              {pageData.observation.user.first_name}{' '}
              {pageData.observation.user.last_name}
            </div>
          </div>
          <div className="header-part categories">
            <label>{pluralize.singular(app.observations_categories)}:</label>
            <div>
              {pageData.observation.categories.length === 1
                ? pageData.observation.categories[0].name
                : 'Various'}
            </div>
          </div>
          <div className="header-part">
            <label>{pluralize.singular(app.projects_display)} Address:</label>
            <div>{pageData.observation.project.address}</div>
          </div>
          <div className="header-part">
            <label>Submitted Date:</label>
            <div>
              {new Date(pageData.observation.date_created).toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                },
              )}
            </div>
          </div>
        </section>
        <section className="staticFields">
          <div className="question-and-answer">
            <div className="question-field sub-field">
              <label>Question:</label>
              <div
                dangerouslySetInnerHTML={{ __html: pageData.question.name }}
              />
            </div>
            <div className="answer-field sub-field">
              {answerFields[pageData.answer]}
            </div>
          </div>
          <div className="severity-field sub-field">
            <label>Severity: </label>
            {severityLevelElements[pageData.severity]}
          </div>
          <div className="reference-field sub-field">
            <label>Reference: </label>
            <div>{pageData.reference}</div>
          </div>
          <div className="note-field sub-field">
            <label>Notes: </label>
            <div className="multiple-answer-body">
              {pageData.notes.map((note, idx) => {
                if (!note.deleted) {
                  return (
                    <div key={idx} className="single-answer">
                      <div>
                        {parseDate(note.date_created)} {note.text}
                      </div>
                    </div>
                  )
                }
              })}
            </div>
          </div>
          {pageData.external_assignees.length ||
          pageData.internal_assignees.length ? (
            <div className="assign-field sub-field">
              <label>Assigned To:</label>
              <div className="multiple-answer-body">
                {pageData.internal_assignees.map((assignee, idx) => {
                  return (
                    <div key={idx} className="single-answer">
                      {parseDate(assignee.date_created)}{' '}
                      {assignee.full_assignee.first_name}{' '}
                      {assignee.full_assignee.last_name}{' '}
                      {assignee.full_assignee.company_name}{' '}
                    </div>
                  )
                })}
                {pageData.external_assignees.map((assignee, idx) => {
                  return (
                    <div key={idx} className="single-answer">
                      {parseDate(assignee.date_created)}{' '}
                      {assignee.external_contact.first_name}{' '}
                      {assignee.external_contact.last_name}{' '}
                      {assignee.external_contact.company_name}{' '}
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
          {pageData.corrective_actions.length ? (
            <div className="corrective-action-field sub-field">
              <label>Corrective Actions: </label>
              <div className="multiple-answer-body">
                {pageData.corrective_actions.map((action, idx) => {
                  if (!action.deleted) {
                    return (
                      <div key={idx} className="single-answer">
                        <div>
                          {parseDate(action.date_created)} {action.text}
                        </div>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          ) : null}
          {pageData.answer_party_observed_answer_list.length ? (
            <div className="sub-field left-field">
              <label className="sub-field-header">
                {app.observation_party_observed}
                {': '}
              </label>
              <div className="multiple-answer-body">
                {pageData.answer_party_observed_answer_list.map((po) => (
                  <div className="single-answer">
                    <span> {po.party_observed.name}</span>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {pageData.external_assignees.length ? (
            <div className="sub-field sub-field-with-multiples">
              <label>Assigned To: </label>
              <div className="multiple-answer-body">
                {pageData.external_assignees.map((assignee, idx) => {
                  return (
                    <div key={idx} className="single-answer">
                      <div key={idx} className="date">
                        {parseDate(assignee.date_created)}{' '}
                        {assignee.external_contact.first_name
                          ? assignee.external_contact.first_name + ' '
                          : null}
                        {assignee.external_contact.last_name
                          ? assignee.external_contact.last_name
                          : null}
                        {', '}
                        {assignee.external_contact.company_name}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          ) : null}
        </section>
        <section className="image-section">
          <div className="image-header">
            <label>Photos: </label>
          </div>
          <div className="image-base">
            {pageData.photos.map((photo, idx) => (
              <ImageWithPreview key={idx} photo={photo} photoIdx={idx} />
            ))}
          </div>
        </section>
      </section>
    )
  } else {
    return null
  }
}

const baseStyle = css({
  backgroundColor: '#ffffff',
  borderRadius: '20px',
  padding: '20px',

  '.observation-header': {
    textAlign: 'center',
    paddingTop: '20px',
    paddingBottom: '20px',
    borderTop: '1px solid #eeeeee',
    borderBottom: '1px solid #eeeeee',
    h2: {
      margin: 0,
    },
  },
  '.header-section': {
    padding: '20px',
    borderBottom: '1px solid #eeeeee',
    '.header-part': {
      verticalAlign: 'top',
      display: 'inline-block',
      width: '25%',
      marginRight: '15%',
      marginTop: '20px',
      label: {
        fontStyle: 'italic',
        fontWeight: 'normal',
      },
      div: {
        fontWeight: 'bold',
      },
    },
    '.categories': {
      marginRight: '0',
      width: '20%',
    },
  },
  '.staticFields': {
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '20px',
    '.question-field': {
      width: 'calc(100% - 140px)',
      display: 'inline-block',
      label: {
        textDecoration: 'underline',
      },
      '@media (max-width: 650px)': {
        width: '100%',
      },
    },
    '.sub-field': {
      marginBottom: '20px',
      pageBreakInside: 'avoid',
      pageBreakBefore: 'auto',
      pageBreakAfter: 'auto',
      breakInside: 'avoid',
      breakBefore: 'auto',
      breakAfter: 'auto',
      label: {
        textDecoration: 'underline',
      },
    },
    '.answer-field': {
      width: '140px',
      textAlign: 'right',
      display: 'inline-block',
      verticalAlign: 'top',
      fontWeight: 'bold',
      '@media (max-width: 650px)': {
        width: '100%',
        textAlign: 'left',
      },
    },
    '.no': {
      color: 'red !important',
    },
    '.pr': {
      color: 'orange !important',
    },
    '.pa': {
      color: '#ffdb57 !important',
    },
    '.cls': {
      color: 'blue !important',
    },
    '.yes': {
      color: 'green !important',
    },
    '.sub-field-with-multiples': {
      label: {
        verticalAlign: 'top',
      },
    },
    '.multiple-answer-body': {
      display: 'inline-block',
      width: '100%',
      '.single-answer': {
        width: '100%',
      },
    },
    '.severity-field': {
      width: '50%',
      paddingRight: '20px',
      display: 'inline-block',
    },
    '.reference-field': {
      width: '50%',
      display: 'inline-block',
      '@media (max-width: 650px)': {
        width: '100%',
      },
    },
  },
  '.image-section': {
    marginTop: '10px',
    paddingLeft: '20px',
    paddingBottom: '20px',
    borderBottom: '1px solid #eeeeee',
    label: {
      verticalAlign: 'top',
      textDecoration: 'underline',
    },
    '.image-base': {
      '.singleImage': {
        marginRight: '20px',
      },
      '.loading-screen': {
        width: '30%',
        height: '150px',
        backgroundColor: 'gray',
        display: 'inline-block',
      },
    },
  },
})
