export * from './download'
export * from './fileValidation'
export * from './generalUtilities'
export * from './isCorrectFile'
export * from './parseDate'
export * from './colorAdjustment'
export * from './updateQueryParams'
export * from './elementChecks'
export * from './fullUsername'
export * from './translationConnected'

// @TODO: Replace with native object rest
export const _extends = Object.assign
