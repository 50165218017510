angular
  .module('ccs')
  .controller(
    'ObservationsCtrl',
    function (
      $scope,
      $state,
      ngDialog,
      Api,
      app,
      BatchReportService,
      $stateParams,
      $timeout,
      CurrentUser,
      $log,
    ) {
      $log.debug('ObservationsCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20
      $scope.totalItems = $scope.pageNumber * $scope.pageSize
      $scope.userIs = CurrentUser.is
      $scope.isClientOrAdmin = CurrentUser.isClientOrAdmin

      $scope.finalize_ids = []

      $scope.observations = []
      $scope.reverse = true
      $scope.order = 'date_created'
      $scope.app = app
      $scope.isRequestSent = false
      $scope.deletedObservationsCount = 0

      if (CurrentUser.is('client_admin')) {
        $scope.tableHeaders = [
          { key: 'selected', display: '', sortable: false, hidden: true },
          {
            key: 'project__name',
            display: app.project + ' Name',
            sortable: true,
          },
          { key: 'category__name', display: app.category, sortable: true },
          { key: 'user__first_name', display: 'Full Name', sortable: true },
          { key: 'user__company_name', display: 'Company', sortable: true },
          { key: 'date_created', display: 'Date Observed', sortable: true },
          { key: 'view', display: 'View', centered: true },
          { key: 'edit', display: 'Edit', edit: true, centered: true },
          { key: 'email', display: 'Share', centered: true },
          { key: 'delete', display: 'Delete', centered: true },
        ]
      } else {
        $scope.tableHeaders = [
          { key: 'selected', display: '', sortable: false, hidden: true },
          {
            key: 'project__name',
            display: app.project + ' Name',
            sortable: true,
          },
          { key: 'category__name', display: app.category, sortable: true },
          { key: 'user__first_name', display: 'Full Name', sortable: true },
          { key: 'user__company_name', display: 'Company', sortable: true },
          { key: 'date_created', display: 'Date Observed', sortable: true },
          { key: 'view', display: 'View', centered: true },
          { key: 'edit', display: 'Edit', edit: true, centered: true },
          { key: 'email', display: 'Share', centered: true },
        ]
      }

      $scope.changePage = function () {
        getObservations()
        $state.transitionTo(
          'app.observations.list',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
      }

      function getObservationQuery() {
        let query = {
          application: app.id,
          page: $scope.pageNumber,
          role: true,
          search: $scope.searchExecuted ? $scope.search : null,
          tz_offset: new Date().getTimezoneOffset(),
          table: true,
        }

        if ($scope.partnerFilter)
          query.client = $scope.getClientFilterFromPartnerFilter()
        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return query
      }

      function getObservations() {
        Api.Observations.get(getObservationQuery(), function (resp) {
          $scope.observations = resp.results.map((observation) => {
            return observation
          })
          $scope.totalItems = resp.count
        })
      }

      getDeletedObservationsCount()

      $scope.changePage()

      $scope.checks = {}

      $scope.showUnsyncError = function () {
        $scope.error =
          'Attention: You may not edit a observation that has not fully synchronized with the server. Please try again later.'
        $timeout(function () {
          $scope.error = null
        }, 3000)
      }

      $scope.batchReport = function () {
        BatchReportService.clear()
        $scope.tableHeaders[0]['hidden'] = false
      }

      $scope.finalizeBatchReport = function () {
        $state.go('app.batch_reports.finalize', {
          f_id: $scope.finalize_ids.join(','),
        })
      }

      $scope.getChecked = function (obs) {
        return BatchReportService.isPresent(obs.id)
      }

      $scope.changeChecked = function (obs) {
        BatchReportService.changeChecked(obs.id)
        if ($scope.finalize_ids.includes(obs.id)) {
          $scope.finalize_ids.splice($scope.finalize_ids.indexOf(obs.id), 1)
        } else {
          $scope.finalize_ids.push(obs.id)
        }
      }

      $scope.add = function () {
        ngDialog.open({
          template: 'app/views/observation_create_form.html',
          className: 'ngdialog-theme-default custom-content',
          scope: $scope,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.projects = []

            //Infinite Scroll Begin
            $scope.infiniteScroll = {}
            $scope.infiniteScroll.numToAdd = 20
            $scope.infiniteScroll.currentProjectItems = 20
            $scope.infiniteScroll.currentCategoryItems = 20

            $scope.addMoreProjects = function () {
              $scope.infiniteScroll.currentProjectItems +=
                $scope.infiniteScroll.numToAdd
            }

            $scope.addMoreCategories = function () {
              $scope.infiniteScroll.currentCategoryItems +=
                $scope.infiniteScroll.numToAdd
            }
            //Infinite Scroll End

            $scope.observation = {
              application: app.id,
            }

            function getProjects(page) {
              var pageNumber = page || 1
              Api.Projects.get(
                {
                  client: CurrentUser.getClientId(),
                  page: pageNumber,
                  is_active: 'True',
                  assigned: true,
                },
                function (resp) {
                  $scope.projects = $scope.projects.concat(resp.results)
                  if (resp.next) {
                    getProjects(pageNumber + 1)
                  }
                },
              )
            }

            function getAppProject(projectID, cb) {
              Api.AppProjects.get(
                {
                  app: app.id,
                  project: projectID,
                },
                (resp) => {
                  cb(resp.results.length ? resp.results[0] : null)
                },
              )
            }

            function getCategories() {
              if (!$scope.observation.project) return
              getAppProject($scope.observation.project, (appProject) => {
                if (!appProject) return
                getAllCategories(appProject)
              })
            }

            function getAllCategories(appProject, page) {
              var pageNumber = page || 1
              Api.Categories.get(
                {
                  for_app_project: appProject.id,
                  is_active: 'True',
                  order: 'name',
                  page_size: 250,
                  page: pageNumber,
                },
                (resp) => {
                  $scope.categories = $scope.categories.concat(resp.results)
                  if (resp.next) {
                    getAllCategories(appProject, pageNumber + 1)
                  }
                },
              )
            }

            getProjects()

            $scope.create = function () {
              // observation created from the web, should be synchronised: true
              $scope.observation.synchronised = true
              $scope.isRequestSent = true

              Api.Observations.post($scope.observation, function (resp) {
                if (resp) {
                  $scope.closeThisDialog()
                  $state.go('app.observations.edit', {
                    observation: resp.id,
                    app: app.id,
                  })
                  $scope.isRequestSent = false
                  getDeletedObservationsCount()
                }
              })
            }

            $scope.$watch('observation.project', (newVal, oldVal) => {
              $scope.categories = []
              $scope.observation.category = null
              if (newVal) {
                getCategories()
              }
            })
          },
        })
      }

      $scope.delete = function (observation_id) {
        ngDialog.open({
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          template: 'app/views/delete_dialog.html',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.save = function () {
              Api.Observations.delete({ id: observation_id }, function (resp) {
                getObservations()
                getDeletedObservationsCount()
                $scope.closeThisDialog()
              })
            }

            $scope.cancel = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      function getDeletedObservationsCount() {
        Api.Observations.get(
          {
            application: app.id,
            role: true,
            counter: true,
            search: $scope.searchExecuted ? $scope.search : null,
            tz_offset: new Date().getTimezoneOffset(),
            deleted: 'True',
          },
          function (resp) {
            $scope.deletedObservationsCount = resp.count
          },
        )
      }

      $scope.goToDeletedObservationsPage = function () {
        $state.go(
          'app.observations.deleted_observations',
          {
            app: app.id,
            pageNumber: 1,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { reload: 'app.observations.list' },
        )
      }
    },
  )
