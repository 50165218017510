import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string | number
  width?: string | number
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const XIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <path
        d="M15.664 14.0488C15.7705 14.155 15.855 14.2812 15.9127 14.4201C15.9703 14.559 16 14.7079 16 14.8583C16 15.0087 15.9703 15.1576 15.9127 15.2966C15.855 15.4355 15.7705 15.5616 15.664 15.6678C15.4484 15.8807 15.1577 16 14.8547 16C14.5518 16 14.2611 15.8807 14.0455 15.6678L8 9.6205L1.9545 15.6678C1.73891 15.8807 1.44818 16 1.14526 16C0.842341 16 0.551611 15.8807 0.336022 15.6678C0.229514 15.5616 0.145007 15.4355 0.0873445 15.2966C0.0296823 15.1576 0 15.0087 0 14.8583C0 14.7079 0.0296823 14.559 0.0873445 14.4201C0.145007 14.2812 0.229514 14.155 0.336022 14.0488L6.38152 8.00154L0.336022 1.95426C0.121398 1.73957 0.000823323 1.44839 0.000823323 1.14478C0.000823323 0.994443 0.0304252 0.845581 0.0879386 0.70669C0.145452 0.567799 0.229751 0.4416 0.336022 0.335298C0.442293 0.228995 0.568455 0.144672 0.707304 0.0871411C0.846154 0.0296107 0.994972 0 1.14526 0C1.44879 0 1.73988 0.12061 1.9545 0.335298L8 6.38259L14.0455 0.335298C14.2601 0.12061 14.5512 0 14.8547 0C15.1583 0 15.4494 0.12061 15.664 0.335298C15.8786 0.549985 15.9992 0.841163 15.9992 1.14478C15.9992 1.44839 15.8786 1.73957 15.664 1.95426L9.61848 8.00154L15.664 14.0488Z"
        style={{ fill: 'currentcolor' }}
      />
    </svg>
  )
}
