import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { useEffect, useRef, useState } from 'react'
import { AutoExpandTextArea } from '@/react/components/AutoExpandTextArea'

export const CreateEditResultType = ({
  handleClose,
  handleClick,
  editData = null,
  resultTypes,
}) => {
  const popupRef = useRef()
  const [name, setName] = useState('')

  useEffect(() => {
    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        handleClose()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (editData) {
      setName(editData?.name)
    }
  }, [])

  const isTypeAlreadyExist = resultTypes
    ?.map((type) => type?.name)
    .includes(name)

  return (
    <div css={baseStyle} ref={popupRef}>
      <img
        className="popup__cross"
        src={closeIcon}
        alt="close"
        onClick={() => {
          handleClose()
        }}
      />
      <div className="popup__title">Result Type</div>
      <AutoExpandTextArea
        className="popup__input"
        name=""
        value={name}
        handleChange={(e) => {
          setName(e.target.value)
        }}
        placeholder=""
      />
      <div className="symbols-remains">{255 - (name?.length || 0)}</div>
      <p className="popup__error">
        {isTypeAlreadyExist &&
          !editData &&
          'Cannot create duplicate result types'}
      </p>
      <ButtonElement
        text={!editData ? 'Create' : 'Save'}
        buttonType="submit"
        disabled={!name || isTypeAlreadyExist}
        additionalStyles={css({ marginBottom: '11px' })}
        functionToTrigger={() => {
          if (!editData) {
            handleClick(name)
          } else {
            handleClick(name, editData?.id)
          }
        }}
      />
    </div>
  )
}

const baseStyle = css({
  position: 'absolute',
  background: '#FFF',
  boxShadow: '0px 7px 29px 0px rgba(0, 0, 0, 0.18)',
  zIndex: 2,
  width: '227px',
  left: '290px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '@media(max-width: 1150px)': {
    left: '80px',
  },
  '.popup__cross': {
    width: '12px',
    height: '12px',
    display: 'flex',
    alignSelf: 'flex-end',
    marginRight: '7px',
    marginTop: '7px',
    cursor: 'pointer',
  },
  '.popup__input': {
    height: '25px',
    width: '189px',
    outline: 'none',
    border: '1px solid #E6E6E6',
    fontSize: '12px',
    fontWeight: 400,
  },
  '.symbols-remains': {
    display: 'flex',
    justifyContent: 'flex-end',
    fontSize: '11px',
    color: '#B1B1B1',
    width: '100%',
    paddingRight: '20px',
  },
  '.popup__title': {
    color: 'grey',
    textAlign: 'center',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    letterSpacing: '-0.14px',
    marginBottom: '11px',
    paddingInline: '20px',
  },
  '.popup__error': {
    fontSize: '10px',
    color: '#BD2828',
    marginBottom: '10px',
    minHeight: '10px',
  },
})
