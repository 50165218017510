import React, { useEffect, useState } from 'react'
import {
  ButtonElement,
  CommonPageHeader,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { css } from '@emotion/react'
import { useRouter } from '@/react/hooks'
import { singleHeaderTypes } from '../components'

interface permission {
  id: number
  module: string
  service_account: number
  type: string
}

const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
  {
    className: '',
    name: 'Modules',
    listLocation: '',
    type: '',
    filterHeader: true,
    additionalStyles: {
      width: '25%',
      textAlign: 'left',
      paddingLeft: '10px',
    },
  },
  {
    className: '',
    name: 'None',
    listLocation: '',
    type: '',
    filterHeader: false,
    additionalStyles: { width: '25%', textAlign: 'center' },
  },
  {
    className: '',
    name: 'Read Only',
    listLocation: '',
    type: '',
    filterHeader: false,
    additionalStyles: { width: '25%', textAlign: 'center' },
  },
  {
    className: '',
    name: 'Read/Write',
    listLocation: '',
    type: '',
    filterHeader: false,
    additionalStyles: { width: '25%', textAlign: 'center' },
  },
]

export function ServiceAccounts() {
  const { stateService } = useRouter()
  const { Api } = useAngularServices()
  const [serviceAccountName, setServiceAccountName] = useState('')
  const [projectsPermission, setProjectsPermission] = useState(null)
  const [costCodePermission, setCostCodePermission] = useState(null)
  const [disabledButton, setDisabledButton] = useState(false)
  const isEditMode = stateService.params.id

  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  useEffect(() => {
    if (isEditMode) {
      Api.ServiceAccounts.byID(stateService.params.id, (resp) => {
        setServiceAccountName(resp.name)
      })

      Api.ServiceAccountPermissions.get({ page_size: 5000 }, (resp) => {
        const [projects, costCode] = resp?.results.filter(
          (el) => +stateService.params.id === el.service_account,
        )
        setProjectsPermission(projects)
        setCostCodePermission(costCode)
      })
    }
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceAccountName(event.target.value)
  }

  const projectPermissionHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setProjectsPermission((prevState) => ({
      ...prevState,
      type: event.target.defaultValue,
    }))
  }
  const costCodePermissionHandler = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setCostCodePermission((prevState) => ({
      ...prevState,
      type: event.target.defaultValue,
    }))
  }

  const submitHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisabledButton(true)
    event.preventDefault()

    if (isEditMode) {
      Api.ServiceAccounts.patch(
        {
          name: serviceAccountName,
          id: stateService.params.id,
          refresh_client_secret: false,
        },
        function () {
          stateService.go('admin.service_accounts', {}, { reload: true })
        },
      )

      Api.ServiceAccountPermissions.patch(projectsPermission, function () {})
      Api.ServiceAccountPermissions.patch(costCodePermission, function () {})
    } else {
      Api.ServiceAccounts.post({ name: serviceAccountName }, function (resp) {
        stateService.go('admin.service_accounts_view', {
          id: resp?.id,
          isShowSecret: true,
        })
      })
    }
  }

  const baseStyle = getBaseStyle(variableColor)

  return (
    <>
      <CommonPageHeader
        headerText={`${isEditMode ? 'Edit' : 'Add'} Service Accounts`}
      />
      <section
        css={baseStyle}
        className={isEditMode ? 'col-md-12' : 'col-md-6'}
      >
        <form name="serviceAccountForm" onSubmit={submitHandler}>
          <div className="row">
            <div className="form-group">
              <label>Service Account Name</label>
              <input
                className="form-control"
                type="text"
                value={serviceAccountName}
                onChange={handleChange}
              />
            </div>
            {isEditMode && (
              <div className="permission-wrapper">
                <div className="permission-title">
                  Service Account Permissions
                </div>
                <table className="dual-color-table">
                  <TableHeaders
                    headers={TABLE_HEADER_SETTINGS}
                    popUpLocation={false}
                  />
                  <tbody>
                    <tr className="table-row">
                      <td>Projects</td>
                      <td>
                        <input
                          name="projects"
                          value="none"
                          checked={projectsPermission?.type === 'none'}
                          onChange={projectPermissionHandler}
                          type="radio"
                        />
                      </td>
                      <td>
                        <input
                          name="projects"
                          value="read"
                          checked={projectsPermission?.type === 'read'}
                          onChange={projectPermissionHandler}
                          type="radio"
                        />
                      </td>
                      <td>
                        <input
                          name="projects"
                          value="read_write"
                          checked={projectsPermission?.type === 'read_write'}
                          onChange={projectPermissionHandler}
                          type="radio"
                        />
                      </td>
                    </tr>
                    <tr className="table-row">
                      <td>Cost Code</td>
                      <td>
                        <input
                          name="costCode"
                          checked={costCodePermission?.type === 'none'}
                          type="radio"
                          onChange={costCodePermissionHandler}
                          value="none"
                        />
                      </td>
                      <td>
                        <input
                          name="costCode"
                          value="read"
                          checked={costCodePermission?.type === 'read'}
                          onChange={costCodePermissionHandler}
                          type="radio"
                        />
                      </td>
                      <td>
                        <input
                          name="costCode"
                          value="read_write"
                          checked={costCodePermission?.type === 'read_write'}
                          onChange={costCodePermissionHandler}
                          type="radio"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            <ButtonElement
              text="Save"
              buttonType="submit"
              disabled={!serviceAccountName || disabledButton}
            />
          </div>
        </form>
      </section>
    </>
  )
}

function getBaseStyle(variableColor) {
  return css({
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    marginTop: '15px',
    form: {
      padding: '20px',
      button: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '40px',
        marginLeft: 'auto',
      },
    },
    '.permission-wrapper': {
      marginTop: '40px',
      marginBottom: '50px',
    },
    '.permission-title': {
      fontSize: '15px',
      fontWeight: 600,
      color: '#676A6C',
      marginBottom: '10px',
    },
    '.dual-color-table': {
      width: '100%',

      thead: {
        backgroundColor: '#F3F3F4',
        fontSize: '15px',
        fontWeight: 700,
        borderBottom: '2px solid rgba(103, 106, 108, 0.11)',
        height: '50px',

        '.header-base': {
          p: {
            marginBottom: 0,
          },
        },
      },
      '.table-row': {
        height: '55px',
        fontSize: '15px',
        fontWeight: 600,

        td: {
          textAlign: 'center',
        },
        'td:first-child': {
          textAlign: 'left',
          paddingLeft: '10px',
        },
      },
    },
    'input[type="radio"]': {
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none',
      padding: '4px',
      width: '30px',
      height: '30px',
      backgroundClip: 'content-box',
      border: '2px solid #676a6c',
      backgroundColor: 'inherit',
      borderRadius: '50%',
      outline: 'none',
    },
    'input[type="radio"]:checked': {
      backgroundColor: variableColor,
    },
    'input[type="text"]': {
      maxWidth: '540px',
    },
  })
}
