import { UniversalAlert } from '@/react/components'
import * as React from 'react'

export const printPageWithFirefoxWarning = (setPopUp) => {
  const isFirefox = typeof InstallTrigger !== 'undefined'
  if (!isFirefox) {
    print()
  } else {
    setPopUp(
      <UniversalAlert
        text={'Print to PDF on Firefox may result in large files sizes.'}
        setAlert={setPopUp}
        submitButtonText={'OK'}
        optionalFunction={print}
      />,
    )
  }
}
