angular.module('ccs').directive('projectfilter', projectFilter)

function projectFilter(Api) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/project_filter.html',
    link: function ($scope) {
      function getProjects(search = '') {
        Api.Projects.get(
          { search, is_active: 'True', page_size: 200 },
          (resp) => {
            $scope.projects = resp.results
          },
        )
      }

      $scope.selected = () => {
        $scope.pageNumber = 1
        $scope.changePage()
      }

      $scope.refreshProjects = (search) => {
        getProjects(search)
      }
    },
  }
}
