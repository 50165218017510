import {
  CommonPageHeader,
  PageSwitcher,
  Search,
  useAngularServices,
} from '@/react/components'
import React, { useEffect, useState } from 'react'
import { MobileFormsNavBar } from '../commonComponents/MobileFormNavBar'
import { css } from '@emotion/react'
import { DeletedTable } from './DeletedTable'
import { useRouter } from '@/react/hooks'

export const ReportDeleteListBase = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [listCount, setListCount] = useState(1)
  const { CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [isAllChecked, setAllChecked] = useState(false)

  const PAGE_LENGTH = 20

  useEffect(() => {
    if (CurrentUser.getRole() !== 'client_admin') {
      stateService.go('app.forms.reports')
    }
  }, [])

  return (
    <section css={baseStyle}>
      <CommonPageHeader headerText={'Mobile Form Reports'} />
      <MobileFormsNavBar />
      <div className="deleted__wrapper">
        <div className="search__block">
          <Search
            search={search}
            searchCallback={setSearch}
            pageCallback={setPage}
            updateUrl={false}
            extraFunction={() => {
              setAllChecked(false)
            }}
          />
        </div>
        <div className="table__container">
          <DeletedTable
            search={search}
            page={page}
            listCount={listCount}
            setListCount={setListCount}
            setPage={setPage}
            isAllChecked={isAllChecked}
            setAllChecked={setAllChecked}
          />
        </div>
        <div className="page-switcher-base">
          <PageSwitcher
            pageLength={PAGE_LENGTH}
            listCount={listCount}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  height: '1320px',
  '.deleted__wrapper': {
    backgroundColor: 'white',
    paddingInline: '24px',
  },
  '.search__block': {
    paddingTop: '20px',
  },
  '.page-switcher-base': {
    paddingBottom: '20px',
  },
  '@media(max-width: 815px)': {
    '.restore__popup': {
      left: '80px !important',
    },
  },
  '@media(min-width: 1350px)': {
    '.deleted__wrapper': {
      width: '1140px',
    },
  },
})
