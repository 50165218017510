import { css } from '@emotion/react'

export const EnvolopeIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="19"
      viewBox="0 0 27 19"
      fill="none"
    >
      <path
        d="M25.5556 2.88889V16.1111C25.5556 16.6121 25.3565 17.0925 25.0023 17.4468C24.6481 17.801 24.1676 18 23.6667 18H2.88889C2.38792 18 1.90748 17.801 1.55324 17.4468C1.19901 17.0925 1 16.6121 1 16.1111V2.88889M25.5556 2.88889C25.5556 2.38792 25.3565 1.90748 25.0023 1.55324C24.6481 1.19901 24.1676 1 23.6667 1H2.88889C2.38792 1 1.90748 1.19901 1.55324 1.55324C1.19901 1.90748 1 2.38792 1 2.88889M25.5556 2.88889L14.3526 10.6447C14.0367 10.8632 13.6618 10.9803 13.2778 10.9803C12.8937 10.9803 12.5188 10.8632 12.203 10.6447L1 2.88889"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  )
}
