import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const VoidIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.6 19.56"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <path
        style={{ fill: 'currentcolor' }}
        d="M12.15,10.66a4.45,4.45,0,1,0,4.45,4.45A4.45,4.45,0,0,0,12.15,10.66Zm2.57,6.17-.85.85L12.15,16l-1.71,1.72-.86-.85,1.72-1.72L9.58,13.4l.86-.86,1.71,1.72,1.72-1.72.85.86L13,15.11ZM12.15,9.05V0H0V15.11H6.09A6.07,6.07,0,0,1,12.15,9.05ZM2.9,3H9.25V4.45H2.9Zm0,2.85H9.25V7.3H2.9Zm3.26,4.28H2.9V8.71H6.16Z"
      />
    </svg>
  )
}
