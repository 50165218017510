import {
  ButtonElement,
  CommonPageHeader,
  PageSwitcher,
  Search,
  TableHeaders,
  useAngularServices,
} from '@/react/components'
import { AssessmentNavBar } from '../components/AssessmentNavBar'
import React, { useEffect, useState } from 'react'
import { css } from '@emotion/react'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import actionIcon from '@/assets/icons/action-icon.svg'
import closeIcon from '@/assets/icons/close-icon.svg'
import { CompletedRow } from './components/CompletedRow'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { DownloadInfoPopUp } from '@/react/components/DownloadInfoPopUp'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { checkIsSafari } from '@/utils/checkIsSafari'

export const CompletedAssessment = () => {
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [order, setOrder] = useState('-date_created')
  const [isDownloadModalVisible, setDownloadModalVisible] = useState(false)
  const [isAllChecked, setAllChecked] = useState(false)
  const [isDeleteClicked, setDeleteClicked] = useState(false)
  const [isActionsHovered, setActionsHovered] = useState(false)
  const [isActionsClicked, setActionsClicked] = useState(false)
  const [isChecked, setChecked] = useState(false)
  const [assessmentsList, setAssessmentsList] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [refresh, setRefresh] = useState(false)

  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const isActionsAvailable = selectedIds?.length >= 1 || isAllChecked

  const downloadFile = (fileUrl: string, fileName: string) => {
    const anchor = document.createElement('a')
    anchor.href = fileUrl
    anchor.download = fileName
    anchor.target = '_blank'
    anchor.click()
  }

  const handleExport = async () => {
    setDownloadModalVisible(true)

    const { data: fileResponse } = await Api.getWithoutDeletedParam(
      'assessment/assessments/export_xls',
      {
        ids: isAllChecked ? 'all' : selectedIds.join(','),
        search,
        deleted: false,
      },
    )

    if (fileResponse) {
      setTimeout(async () => {
        try {
          const { data: fileResponseURL } = await Api.getWithoutDeletedParamV2(
            `file_upload/${fileResponse?.xls_id}`,
            {},
          )

          downloadFile(fileResponseURL.file, 'Assessment Download')
        } catch {
          // Notification.warning('Failed to get file url for download')
        }
      }, fileResponse.time * 10000)
    }

    setActionsClicked(false)
    setSelectedIds([])
  }

  const handleDelete = async () => {
    await Api.delete(`assessment/assessments/bulk_delete`, {
      ids: isAllChecked ? 'all' : selectedIds,
      is_draft: false,
      search,
    })

    setPage(1)
    setRefresh(true)
    setAllChecked(false)
    setActionsClicked(false)
    setDeleteClicked(false)
    setSelectedIds([])
  }

  const handleCheckedAll = () => {
    const selectedItems = isAllChecked
      ? []
      : assessmentsList?.results.map((assesment) => assesment?.id) || []

    setAllChecked(!isAllChecked)
    setSelectedIds(selectedItems)
  }

  const handleChecked = (id) => {
    if (selectedIds?.includes(id)) {
      setAllChecked(false)
      setSelectedIds(selectedIds?.filter((sId) => sId !== id))
    } else {
      setSelectedIds([...selectedIds, id])
    }
  }

  const TABLE_HEADER_SETTINGS = [
    {
      className: 'table__header',
      name: '',
      type: 'checkbox',
      additionalStyles: {
        height: '30px',
        width: '60px',
        minWidth: '60px',
        paddingLeft: '15px',
        textAlign: 'left',
        paddingRight: '10px',
        borderBottom: '1px solid #B3B3B3',
        paddingBottom: '5px',
        p: {
          width: '0px',
        },
        '.header-base': {
          width: '0px',
          margin: 0,
          padding: 0,
        },
      },
      additionalInnerElement: (
        <div className="header-actions">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={isAllChecked}
              onChange={handleCheckedAll}
            />
            <span className="checkmark">
              <TrainingsCheckmark color={variableColor} />
            </span>
          </label>
          <img
            className="action"
            src={actionIcon}
            alt=""
            onClick={() => {
              setActionsClicked(!isActionsClicked)
            }}
            onMouseEnter={() => setActionsHovered(true)}
            onMouseLeave={() => setActionsHovered(false)}
          />
          {isActionsHovered && (
            <div className="actions__dropdown hover">
              <div
                className="hover__text"
                onClick={() => setActionsClicked(!isActionsClicked)}
              >
                Actions
              </div>
            </div>
          )}
          {isActionsClicked && (
            <div className="actions__dropdown">
              {isActionsAvailable ? (
                <>
                  <div
                    className="action__text"
                    onClick={() => {
                      setDeleteClicked(true)
                      setActionsClicked(false)
                    }}
                  >
                    Delete
                  </div>
                  <div className="action__text dark" onClick={handleExport}>
                    Export XLS
                  </div>
                </>
              ) : null}
            </div>
          )}
          {isDownloadModalVisible ? (
            <DownloadInfoPopUp setPopUpVisible={setDownloadModalVisible} />
          ) : null}
        </div>
      ),
    },
    {
      className: 'project',
      name: 'Assessment',
      type: 'template__name',
      additionalStyles: {
        height: '30px',
        textAlign: 'left',
        paddingLeft: '14px',
        maxWidth: '186px',
        minWidth: '186px',
        borderBottom: '1px solid #B3B3B3',
        paddingLeft: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: 'fit-content',
        },
      },
    },
    {
      className: 'templates',
      name: 'Result',
      type: 'annotated_grade',
      additionalStyles: {
        height: '30px',
        textAlign: 'center',
        width: '135px',
        minWidth: '135px',
        borderBottom: '1px solid #B3B3B3',
        paddingLeft: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
        },
      },
    },
    {
      className: 'templates',
      name: 'Name',
      type: 'external_contact_full_name',
      additionalStyles: {
        height: '30px',
        textAlign: 'center',
        width: '146px',
        minWidth: '146px',
        borderBottom: '1px solid #B3B3B3',
        paddingLeft: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-start',
        },
      },
    },
    {
      className: 'templates',
      name: 'Email',
      type: 'external_contact__email',
      additionalStyles: {
        height: '30px',
        textAlign: 'center',
        width: '177px',
        minWidth: '177px',
        borderBottom: '1px solid #B3B3B3',
        paddingLeft: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-start',
        },
      },
    },
    {
      className: 'templates',
      name: 'Company',
      type: 'external_contact__company_name',
      additionalStyles: {
        height: '30px',
        textAlign: 'center',
        width: '180px',
        minWidth: '180px',
        borderBottom: '1px solid #B3B3B3',
        paddingLeft: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-start',
        },
      },
    },
    {
      className: 'templates',
      name: 'Completed',
      type: 'date_completed',
      additionalStyles: {
        height: '30px',
        textAlign: 'center',
        width: '100px',
        minWidth: '100px',
        borderBottom: '1px solid #B3B3B3',
        paddingLeft: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'flex-start',
        },
      },
    },
    {
      className: 'templates',
      name: 'Public',
      type: 'is_sharing_allowed',
      additionalStyles: {
        height: '30px',
        textAlign: 'center',
        width: '95px',
        minWidth: '95px',
        borderBottom: '1px solid #B3B3B3',
        padding: 0,
        p: {
          width: 'fit-content',
          margin: '0 !important',
        },
        '.header-base': {
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        },
      },
    },
  ]

  useEffect(() => {
    const getAssessmentsList = async () => {
      const { data } = await Api.get('assessment/assessments', {
        order,
        page,
        search,
        is_draft: false,
      })

      setAssessmentsList(data)
      setRefresh(false)
    }
    getAssessmentsList()
  }, [order, page, search, refresh])

  const isSafari = checkIsSafari()

  const styles = getStyles(variableColor, isSafari)

  return (
    <section css={styles}>
      <CommonPageHeader headerText="Assessments" />
      <AssessmentNavBar />
      <div className="content__wrapper">
        {isDeleteClicked ? (
          <ConfirmationModal
            handleClick={handleDelete}
            handleClose={() => {
              setDeleteClicked(false)
            }}
            action="Delete"
            title={
              !isAllChecked
                ? `${selectedIds.length} Assessments`
                : `${assessmentsList?.count} Assessments`
            }
          />
        ) : null}
        <div className="search__wrapper">
          <Search
            searchCallback={setSearch}
            search={search}
            pageCallback={setPage}
            extraFunction={() => {
              setAllChecked(false)
            }}
          />
        </div>
        <div className="table__wrapper">
          <table className="completed__table">
            <TableHeaders
              headers={TABLE_HEADER_SETTINGS}
              callback={setOrder}
              popUpLocation={false}
              updateUrl={false}
              order={order}
              setOrder={setOrder}
            />
            <tbody>
              {assessmentsList?.results?.map((assessment, index) => (
                <CompletedRow
                  key={assessment?.id}
                  isAllChecked={isAllChecked}
                  assessment={assessment}
                  index={index}
                  handleChecked={handleChecked}
                  selectedIds={selectedIds}
                  setRefresh={setRefresh}
                  search={search}
                />
              ))}
            </tbody>
          </table>
        </div>
        <div className="switch__wrapper">
          <PageSwitcher
            pageLength={20}
            listCount={assessmentsList?.count}
            currentPage={page}
            callback={setPage}
            updateUrl={false}
          />
        </div>
      </div>
    </section>
  )
}

const getStyles = (variableColor, isSafari) =>
  css({
    paddingBottom: '250px',
    '.content__wrapper': {
      background: '#fff',
      paddingRight: '25px',
      width: 'fit-content',
      paddingBottom: '20px',
      marginLeft: '5px',
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.public__td': {
      textAlign: 'center',
    },
    '.checkmark__td': {
      paddingLeft: '15px',
    },
    '.link__cell': {
      display: 'flex',
      alignItems: 'center',
      color: '#3980CE',
      cursor: 'pointer',
    },
    '.page-switcher-base': {
      marginBottom: '50px',
    },
    '.header-actions': {
      display: 'flex',
      justifyContent: 'center',
      gap: '10px',
      position: 'relative',
    },
    '.action': {
      marginTop: '3px',
      cursor: 'pointer',
      marginLeft: '3px',
    },
    '.actions__dropdown': {
      position: 'absolute',
      zIndex: 1,
      top: '20px',
      left: '-7px',
      textAlign: 'center',
      color: '#68696D',
      background: 'white',
      boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
    },
    '.hover': {
      top: '25px',
      left: '7px',
    },
    '.action__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '83px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '.hover__text': {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      justifyContent: 'center',
      width: '58px',
      height: '24px',
      color: '#68696D',
      fontSize: '12px',
      fontWeight: 400,
    },
    '#search': {
      width: '264px',
    },
    '.checkbox-container': {
      display: 'inline-block',
      position: 'relative',
      cursor: 'pointer',
      paddingLeft: '15px',
      margin: 0,
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.search__wrapper': {
      paddingTop: '20px',
      paddingLeft: '23px',
      paddingBottom: '15px',
    },
    '.completed__table': {
      marginLeft: '25px',
    },
    td: {
      height: '40px',
      paddingRight: '10px',
    },
    '.dark': {
      background: '#F2F3F5',
    },
    '.link__td': {
      color: '#3980CE',
      cursor: 'pointer',
      maxWidth: '186px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.name__td': {
      maxWidth: '146px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.email__td': {
      maxWidth: '177px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.company__td': {
      maxWidth: '180px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.result__td': {
      maxWidth: '130px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      marginTop: '30px',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '500px',
    },
    '.company': {
      left: '730px',
    },
    '.name': {
      left: '350px',
    },
    '.email': {
      left: '570px',
    },
    '.result': {
      left: '280px',
    },
    '.modal__wrapper': {
      right: 'auto',
      left: '350px',
      top: '350px',
    },
    '.switch__wrapper': {
      paddingLeft: '15px',
    },
    '@media(max-width:1300px)': {
      '.content__wrapper': {
        width: '100%',
      },
      '.full__name': {
        display: 'none',
      },
      '.modal__wrapper': {
        left: '150px',
      },
      '.info__modal': {
        left: '100px',
      },
      '.table__wrapper': {
        overflowX: 'scroll',
      },
    },
  })
