import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormAssignCtrl',
    function ($scope, Api, app, $stateParams, $state, CurrentUser, $log) {
      $log.debug('FormAssignCtrl')

      $scope.titleFormAssign = pluralize.singular(app.app_mobile_form_display)
      $scope.projects = []
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search

      //pageSize Used in the view
      $scope.pageSize = 20
      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.totalItems = $scope.pageNumber * $scope.pageSize
      $scope.app = app

      $scope.tableHeaders = [
        { key: 'name', display: app.project + ' Name', sortable: true },
        {
          key: 'edit',
          display: 'Assign ' + app.app_mobile_form_display,
          centered: true,
        },
      ]

      function projectQuery() {
        var query = {
          client: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          search: $scope.searchExecuted ? $scope.search : null,
          is_active: 'True',
          search_fields: 'name',
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return query
      }

      function getProjects() {
        Api.Projects.get(projectQuery(), function (resp) {
          $scope.totalItems = resp.count
          var projects = resp.results
          var projectIds = resp.results.map(function (project) {
            return project.id
          })

          Api.AppProjects.get(
            {
              projects: projectIds.join(),
              app: app.id,
            },
            function (resp) {
              $scope.projects = projects.map(function (project) {
                project.forms = []

                resp.results.forEach(function (appProject) {
                  if (appProject.project.id == project.id) {
                    project.forms = appProject.active_mobile_forms
                    project.appProject = appProject
                  }
                })

                return project
              })
            },
          )
        })
      }

      $scope.changePage = function () {
        //update the URL query parameters without reloading the controller
        $state.transitionTo(
          'app.forms.assign',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          {
            notify: false,
          },
        )

        //get the projects
        getProjects()
      }

      $scope.changePage()
    },
  )
