import * as React from 'react'
import { useAngularServices } from '@/react/components'
import classNames from 'classnames'
import moment from 'moment'
import { TrainingsCheckmark } from '@/react/componentAssets/TrainingsCheckmark'
import { BinDeleteIcon } from '@/react/componentAssets'
import { EditForPartyObserved } from './EditForPartyObserved'
import Highlighter from 'react-highlight-words'
import { PartyRow } from './PartyRow'

export const TableBodyPartyObserved = ({ page, search, order, setCount }) => {
  const { Api, CurrentUser, Notification } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [list, setList] = React.useState([])
  const [refresh, setRefresh] = React.useState(false)

  React.useEffect(() => {
    const fetchData = async () => {
      const { data } = await Api.get('answer_party_observed', {
        page: page,
        deleted: false,
        search: search,
        order: order,
        has_user: false,
        user: null,
      })

      setCount(data.count)
      setList(data.results)
      setRefresh(false)
    }
    fetchData()
  }, [page, search, order, refresh])

  async function handleChecked(id: number, value: boolean) {
    try {
      await Api.patch('answer_party_observed/' + id, { is_active: value })

      const newData = list.map((party) => {
        if (party.id === id) {
          party.is_active = value
        }
        return party
      })

      setList(newData)
    } catch {
      Notification.error('Failed to change activity status')
    }
  }

  async function handleDelete(id: number) {
    try {
      await Api.patch('answer_party_observed/' + id, { deleted: true })
      setList(list.filter((party) => party.id !== id))
    } catch {
      Notification.error('Failed to delete party observed')
    }
  }

  return (
    <tbody>
      {list.map((party, idx) => (
        <PartyRow
          party={party}
          idx={idx}
          handleChecked={handleChecked}
          handleDelete={handleDelete}
          search={search}
          variableColor={variableColor}
          setRefresh={setRefresh}
        />
      ))}
    </tbody>
  )
}
