export const getBase64Image = async (Api, id: string | number) => {
  if (!id) return
  const { data: response } = await Api.getWithoutDeletedParam(
    'image_upload/download',
    {
      id: id,
    },
  )
  return 'data:' + 'image/jpeg' + ';base64, ' + response.content
}
