import * as React from 'react'
import { css } from '@emotion/react'

import { singleHeaderTypes } from '@screens/components'
import { useRouter } from '@/react/hooks'
import { parseInt } from 'lodash'
import { TableBodyPartyObserved } from './../components'
import {
  useAngularServices,
  TableHeaders,
  Search,
  PageSwitcher,
} from '@/react/components'
import { hexToRGBA } from '@/utils/generalUtilities.js'
import { checkIsSafari } from '@/utils/checkIsSafari'

const TABLE_HEADER_SETTINGS: singleHeaderTypes[] = [
  {
    className: 'party_observed',
    name: '',
    listLocation: 'admin.party_observed_list',
    type: 'name',
    filterHeader: true,
    additionalStyles: {
      borderBottom: '1.5px solid #B3B3B3',
      height: '30px',
      textAlign: 'left',
      width: '384px',
      maxWidth: '384px',
      minWidth: '384px',
      paddingLeft: '12px',
    },
  },
  {
    className: 'date_created',
    name: 'Created',
    listLocation: 'admin.party_observed_list',
    type: 'date_created',
    filterHeader: true,
    additionalStyles: {
      borderBottom: '1.5px solid #B3B3B3',
      height: '30px',
      textAlign: 'left',
      width: '90px',
      maxWidth: '90px',
      minWidth: '90px',
    },
  },
  {
    className: 'date_updated',
    name: 'Updated',
    listLocation: 'admin.party_observed_list',
    type: 'date_updated',
    filterHeader: true,
    additionalStyles: {
      borderBottom: '1.5px solid #B3B3B3',
      height: '30px',
      textAlign: 'left',
      width: '90px',
      maxWidth: '90px',
      minWidth: '90px',
    },
  },
  {
    className: 'activity',
    name: 'Active',
    listLocation: 'admin.party_observed_list',
    type: 'is_active',
    filterHeader: true,
    additionalStyles: {
      borderBottom: '1.5px solid #B3B3B3',
      height: '30px',
      textAlign: 'left',
      width: '70px',
      maxWidth: '70px',
      minWidth: '70px',
    },
  },
  {
    className: 'delete',
    name: 'Delete',
    listLocation: 'admin.party_observed_list',
    type: 'delete',
    filterHeader: false,
    additionalStyles: {
      borderBottom: '1.5px solid #B3B3B3',
      height: '30px',
      textAlign: 'left',
      width: '70px',
      maxWidth: '70px',
      minWidth: '70px',
    },
  },
]

const PAGE_LENGTH = 20

export const ReloadableElements = ({ POVariableName }) => {
  const { stateService } = useRouter()
  const { CurrentUser } = useAngularServices()

  const [count, setCount] = React.useState<number>(0)
  const [page, setPage] = React.useState(
    parseInt(stateService.params.pageNumber) || 1,
  )
  const [order, setOrder] = React.useState(stateService.params.order || 'name')
  const [search, setSearch] = React.useState(stateService.params.search || '')

  TABLE_HEADER_SETTINGS[0].name = POVariableName

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const isSafari = checkIsSafari()
  const styles = getStyles(variableColor, isSafari)

  return (
    <section css={styles}>
      <div className="search-base">
        <Search
          search={search}
          searchCallback={setSearch}
          pageCallback={setPage}
        />
      </div>
      <table>
        <TableHeaders
          headers={TABLE_HEADER_SETTINGS}
          popUpLocation={false}
          callback={setOrder}
        />
        <TableBodyPartyObserved
          order={order}
          search={search}
          page={page}
          setCount={setCount}
        />
      </table>
      <div className="page-switcher-base">
        <PageSwitcher
          pageLength={PAGE_LENGTH}
          listCount={count}
          currentPage={page}
          callback={setPage}
        />
      </div>
    </section>
  )
}

const getStyles = (variableColor, isSafari) =>
  css({
    display: 'flex',
    flexDirection: 'column',
    width: 'fit-content',
    backgroundColor: 'white',
    paddingBottom: '20px',
    marginLeft: 1,
    table: {
      margin: '0px 25px',
    },
    '#search': {
      width: '264px',
    },
    '.search-base': {
      paddingTop: '20px',
      paddingBottom: '15px',
      paddingLeft: '25px',
    },
    '.page-switcher-base': {
      marginLeft: '20px',
    },
    tr: {
      height: '30px',
    },
    '.table__row': {
      color: '#575757',
    },
    '.dark': {
      background: '#F2F3F5',
      borderBottom: '1px solid #E7EAEC',
      borderTop: '1px solid #E7EAEC',
    },
    '.link__cell': {
      paddingLeft: '12px',
      color: '#3980CE',
      cursor: 'pointer',
      maxWidth: '380px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      position: 'relative',
    },
    '.full__name': {
      display: isSafari ? 'none' : 'block',
      position: 'absolute',
      zIndex: 1,
      border: '1px solid #D3D3D3',
      boxShadow: '2px 4px 7px 0px rgba(0, 0, 0, 0.15)',
      background: 'white',
      left: '153px',
      padding: '4px 11px',
      maxWidth: '500px',
    },
    '.checkbox-container': {
      padding: 0,
      margin: 0,
      marginLeft: '16px',
      width: '22px',
      height: '22px',
      input: {
        left: '4px',
      },
    },
    '.checkmark': {
      height: '22px',
      width: '22px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: '1px solid #E6E6E6',
      background: '#fff',
      svg: {
        display: 'none',
      },
    },
    '.checkmark::after': {
      display: 'none !important',
    },
    'input:checked ~ .checkmark': {
      img: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
      svg: {
        height: '22px',
        width: '22px',
        display: 'block',
        background: 'white',
      },
    },
    '.highlighted__item': {
      backgroundColor: hexToRGBA(variableColor),
    },
    '.delete__cell': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '37px',

      svg: {
        cursor: 'pointer',
      },
    },
    '@media(max-width: 1000px)': {
      width: '100%',
      overflowX: 'scroll',
      '.full__name': {
        display: 'none',
      },
    },
  })
