import * as React from 'react'
import { css } from '@emotion/react'
import { getTranslatedString } from '@/utils'
import { getStartingValue } from '@screens/MobileForms/CreateEditPages/components'

export const ReportTitleField = ({
  fieldData,
  formMemory,
  possibleGroupData,
  onChange,
}) => {
  const [textareaHeight, setTextareaHeight] = React.useState<string | number>(
    '35',
  )
  const textareaRef = React.useRef(null)
  const [title, setTitle] = React.useState(
    getStartingValue(possibleGroupData, formMemory, fieldData, 'text'),
  )

  function callback(value) {
    onChange({ value, possibleGroupData, id: fieldData.id })

    setTitle(value)
  }

  const baseStyle = getBaseStyle

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 15
      const minRows = 1

      const rows = Math.max(
        minRows,
        Math.ceil(textareaRef.current.scrollHeight / lineHeight),
      )
      setTextareaHeight(rows * lineHeight)
    }
  }

  React.useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus()
      adjustTextareaHeight()
    }
  }, [])

  return (
    <section css={baseStyle}>
      <div>
        <label css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
        </label>
      </div>
      <textarea
        className="form__textarea"
        onFocus={adjustTextareaHeight}
        onInput={adjustTextareaHeight}
        ref={textareaRef}
        onChange={(e) => {
          callback(e.target.value)
        }}
        value={title}
        style={{ height: `${textareaHeight}px` }}
      ></textarea>
    </section>
  )
}

const getBaseStyle = css({
  marginBottom: 15,
  width: '100%',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  input: {
    textAlign: 'center',
    paddingLeft: '0px !important',
  },
  '@media(max-width: 340px)': {
    input: {
      fontSize: '9px !important',
    },
  },
  '.form__textarea': {
    width: '400px',
    textAlign: 'center',
    backgroundColor: '#FFFFFF',
    outline: 'none',
    backgroundImage: 'none',
    border: '1px solid #e5e6e7',
    borderRadius: 0,
    paddingLeft: '6px',
    paddingRight: '6px',
    paddingTop: '6px',
    paddingBottom: '6px',
    fontSize: '14px',
    minHeight: '30px',
    maxWidth: '100%',
    resize: 'vertical',
  },
})
