import * as React from 'react'
import { css } from '@emotion/react'
import { ReportBase } from './components'
import { TranslationDropdown } from '@screens/TranslationDropdown/TranslationDropdown'
import { useAngularServices } from '@/react/components'

export const ExternalForm = () => {
  const { Notification } = useAngularServices()

  const [logoForExternals, setLogoForExternals] = React.useState('')
  const [error, setError] = React.useState<string | null>(null)

  if (error) {
    Notification.error(error)
    return null
  }

  return (
    <section
      css={css({
        paddingBottom: 50,
        overflow: 'hidden',
      })}
    >
      <div
        css={css({
          marginTop: -40,
          height: 180,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          img: {
            height: 77,
            maxWidth: '100%',
          },
          '@media(max-width:800px)': {
            flexDirection: 'column',
            marginTop: 0,
            justifyContent: 'flex-start',
            gap: '15px',

            img: {
              marginTop: '18px',
            },
          },
        })}
      >
        <div css={css({ width: 140 })} />
        {!logoForExternals ? (
          <div />
        ) : (
          <img src={logoForExternals} alt="logo" />
        )}
        <TranslationDropdown />
      </div>
      <section
        css={css({
          paddingBottom: 200,
          backgroundColor: '#ffffff',
          borderRadius: '20px',
          padding: '20px',
          marginLeft: 15,
        })}
      >
        <ReportBase
          setLogoForExternals={setLogoForExternals}
          setError={setError}
        />
      </section>
    </section>
  )
}
