export function updateQueryParams(newParams, shouldModify) {
  let newParamsObj = null
  if (shouldModify) {
    const searchParamsString = new URLSearchParams(window.location.search)
    const searchParamsObject = Object.fromEntries(searchParamsString.entries())
    newParamsObj = { ...searchParamsObject, ...newParams }
  } else {
    newParamsObj = newParams
  }

  const newUrl =
    window.location.origin +
    window.location.pathname +
    '?' +
    new URLSearchParams(newParamsObj).toString()
  history.pushState({ path: newUrl }, '', newUrl)
}
