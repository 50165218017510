export const TextInputStyle = {
  backgroundColor: '#FFFFFF',
  outline: 'none',
  backgroundImage: 'none',
  borderColor: '#e5e6e7',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderRadius: '0',
  paddingLeft: '6px',
  paddingRight: '6px',
  paddingTop: '6px',
  paddingBottom: '6px',
  fontSize: '14px',
}

export const DocumentStyles = {
  '.report-title': {
    textAlign: 'center',
    fontSize: '24px',
    fontWeight: 700,
    marginBottom: '45px',
  },
  '.icon': {
    marginTop: '45px',
    textAlign: 'center',
    img: {
      maxHeight: '64px',
    },
  },
  '.container': {
    maxWidth: '1065px',
    margin: '0 auto',
    '.field': {
      fontWeight: 700,
      fontSize: '14px',
      marginRight: '5px',
    },
    '.value': {
      fontSize: '14px',
    },
    '.job-info-wrapper': {
      padding: '8px 20px',
      display: 'flex',
    },
    '.col': {
      display: 'flex',
      flexDirection: 'column',
      gap: '6px',
      width: '50%',
      '.info-row': {
        display: 'flex',
      },
    },
    '.comments-wrapper': {
      padding: '8px 20px',
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      '.info-row': {
        display: 'flex',
        flexDirection: 'column',
        gap: '6px',
      },
    },
    '.standard-table': {
      table: {
        fontSize: '14px',
        marginBottom: '8px',
        width: '100%',
        'td, th': {
          border: '1px solid #000',
          padding: '5px 20px',
        },
        '.table-center-text': {
          textAlign: 'center',
        },
        '.table-right-text': {
          textAlign: 'right',
        },
      },
    },
    '.crew-hours-wrapper': {
      padding: '8px 0 13px 0',
      table: {
        fontSize: '14px',
        marginBottom: '8px',
        border: '1px solid #000',
        width: '100%',
        'td, th': {
          border: '1px solid #000',
          padding: '5px 20px',
        },
        '.tac': {
          textAlign: 'center',
        },
        '.tar': {
          textAlign: 'right',
        },
      },
      '.sum-wrapper': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0 20px',
      },
    },
    '.prepared-wrapper': {
      padding: '18px 0 63px 0',
      '.signature-wrapper': {
        display: 'flex',
        justifyContent: 'center',
        img: {
          width: '220px',
          maxHeight: '110px',
        },
      },
      '.printed-name': {
        borderTop: '1px solid #000',
        paddingTop: '8px',
        margin: '0 auto',
        width: '220px',
        textAlign: 'center',
        fontSize: '14px',
        fontWeight: 700,
        '@media print': {
          borderTop: '2px solid #000 !important',
        },
      },
    },
    '.pageBreakInsideAvoid': {
      pageBreakInside: 'avoid',
    },
    '.capitalize::first-letter': {
      textTransform: 'capitalize',
    },
  },
}
