import {
  ButtonElement,
  CommonPageHeader,
  useAngularServices,
} from '@/react/components'
import { AssessmentNavBar } from '../components/AssessmentNavBar'
import { css } from '@emotion/react'
import { TextInputStyle } from '@screens/components'
import { TemplateQuestion } from './components/TemplateQuestion'
import { useEffect, useState } from 'react'
import { Formik, Field, FieldArray, ErrorMessage } from 'formik'
import classNames from 'classnames'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'
import { generateUUID } from '@/utils'
import * as Yup from 'yup'
import { FormikAutoExpandedTextArea } from '@/react/components/FormikAutoExpandedTextArea'
import { useRouter } from '@/react/hooks'
import {
  AssessmentData,
  Template,
  FieldGet,
  SingleSection,
  AssessmentQuestion,
} from './components/types'
import { DataWrap } from '@/react/types'
import { cloneDeep } from 'lodash'

export const CreateAssessment = () => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()
  const [initialValues, setInitialValues] = useState<AssessmentData | null>(
    null,
  )
  const [isButtonDisabled, setButtonDisabled] = useState(false)
  const [scoreRanges, setScoreRanges] = useState([])

  const selectOptions = [
    { value: 'header', label: 'Header' },
    { value: 'nested_option', label: 'Nested Option' },
    { value: 'options', label: 'Options' },
    { value: 'subheader', label: 'Subheader' },
    { value: 'text', label: 'Text' },
  ]

  useEffect(() => {
    const templateId: string = stateService?.params?.assessmentTemplateId

    const getScoreRanges = async () => {
      const {
        data: { results: sections },
      }: { data: { results: SingleSection[] } } = await Api.get(
        `assessment/sections`,
        {
          template: templateId,
          order: 'order',
        },
      )

      const nonDefaultSections = sections?.filter(
        (section) => section?.parent_section,
      )

      const newScoreRanges = await Promise.all(
        nonDefaultSections.map(async (section) => {
          const { data: score_ranges } = await Api.get(
            'assessment/score_ranges',
            {
              deleted: false,
              section: section?.id,
              order: '-high_score',
            },
          )

          return {
            section_name: section?.name,
            inconclusive_description: section?.inconclusive_description,
            minimum_question: section?.minimum_question,
            score_ranges: score_ranges?.results,
          }
        }),
      )

      setScoreRanges((prevScoreRanges) => [
        ...prevScoreRanges,
        ...newScoreRanges,
      ])
    }

    const getInitialValues = async () => {
      const { data: template }: { data: Template } = await Api.get(
        `assessment/templates/${templateId}`,
        {},
      )

      let fields: FieldGet[] = []

      const {
        data: { results: sections },
      }: { data: { results: SingleSection[] } } = await Api.get(
        `assessment/sections`,
        {
          template: template?.id,
          order: 'order',
        },
      )

      for (const section of sections) {
        if (section.parent_section) {
          fields.push({
            id: section.id,
            name: section.name,
            order: section.order,
            required: section.required,
            date_created: section.date_created,
            date_updated: section.date_updated,
            section: section.parent_section,
            note: '',
            options: [],
            nested_option: null,
            type:
              template.default_section.id === section.parent_section
                ? 'header'
                : 'subheader',
          })
        }

        let page = 1
        let hasNextPage = true

        while (hasNextPage) {
          const { data: fieldGet } = await Api.get(`assessment/fields`, {
            section: section.id,
            order: 'order',
            page: page,
          })

          fields = [...fields, ...fieldGet?.results]

          if (fieldGet?.next) {
            page += 1
          } else {
            hasNextPage = false
          }
        }
      }

      const formattedQuestions: Array<AssessmentQuestion> = fields
        .map((question) => {
          if (question?.type === 'nested_option') {
            const formattedOptions = question?.options?.map((op) => {
              const nestedFields = fields?.filter(
                (q) => q?.nested_option === op?.id,
              )

              return {
                ...op,
                nested_fields: nestedFields.map((field) => ({
                  ...field,
                  belongs_to_option: op.order,
                  field_type:
                    field?.type === 'text'
                      ? { value: 'text', label: 'Text' }
                      : { value: 'options', label: 'Options' },
                })),
              }
            })

            return {
              order: question?.order,
              id: question?.id,
              nested_option: question?.nested_option,
              question_text: question?.name,
              note: question?.note || '',
              required: question?.required,
              field_type: selectOptions.find(
                (op) => op?.value === question?.type,
              ),
              nested_options: formattedOptions,
            }
          } else {
            return {
              order: question?.order,
              id: question?.id,
              options: question?.options || [],
              nested_option: question?.nested_option,
              question_text: question?.name,
              note: question?.note || '',
              required: question?.required,
              field_type: selectOptions.find(
                (op) => op?.value === question?.type,
              ),
            }
          }
        })
        .filter((question) => !question?.nested_option)

      setInitialValues({
        template_name: template?.name,
        questionsList: formattedQuestions,
      })
    }

    if (templateId) {
      getInitialValues()
      getScoreRanges()
    } else {
      setInitialValues({
        template_name: '',
        questionsList: [
          {
            order: 1,
            required: false,
            question_text: '',
            field_type: null,
            note: '',
            id: generateUUID(),
          },
        ],
      })
    }
  }, [])

  const optionSchema = Yup.object().shape({
    name: Yup.string().required(),
    weight: Yup.string().required(),
    id: Yup.string(),
  })

  const nestedFieldSchema = Yup.object().shape({
    name: Yup.string().required(),
    field_type: Yup.object()
      .shape({
        value: Yup.string()
          .oneOf(['header', 'nested_option', 'options', 'subheader', 'text'])
          .required(),
        label: Yup.string().required(),
      })
      .required(),
    options: Yup.array().of(optionSchema),
    note: Yup.string(),
  })

  const nestedOptionSchema = Yup.object().shape({
    name: Yup.string().required(),
    weight: Yup.string().required(),
    nested_fields: Yup.array().of(nestedFieldSchema),
  })

  const questionSchema = Yup.object().shape({
    order: Yup.number().required(),
    required: Yup.boolean().required(),
    question_text: Yup.string().required(),
    field_type: Yup.object()
      .shape({
        value: Yup.string()
          .oneOf(['header', 'nested_option', 'options', 'subheader', 'text'])
          .required(),
        label: Yup.string().required(),
      })
      .required(),
    note: Yup.string(),
    id: Yup.string().required(),
    options: Yup.array().of(optionSchema),
    nested_options: Yup.array().of(nestedOptionSchema),
  })

  const validationSchema = Yup.object().shape({
    template_name: Yup.string().required(),
    questionsList: Yup.array().of(questionSchema),
  })

  const handleCreateEdit = async (values) => {
    setButtonDisabled(true)

    let templateData = {}

    if (stateService?.params?.assessmentTemplateId) {
      const { data: templateDataResp }: DataWrap<Template> = await Api.patch(
        `assessment/templates/${stateService.params.assessmentTemplateId}`,
        {
          name: values?.template_name,
        },
      )

      templateData = templateDataResp
    } else {
      const { data: templateDataResp }: DataWrap<Template> = await Api.post(
        'assessment/templates',
        {
          name: values?.template_name,
        },
      )

      templateData = templateDataResp
    }

    const questions = values?.questionsList.map((question) => {
      const questionType = question?.field_type?.value
      switch (questionType) {
        case 'options':
          const updatedOptions = question.options.map(
            ({ id, ...option }) => option,
          )
          return {
            name: question?.question_text,
            order: question.order,
            type: questionType,
            required: question?.required,
            nested_option: question?.nested_option,
            note: question?.note,
            section: templateData?.default_section?.id,
            options: updatedOptions,
          }
        case 'nested_option':
          const { nested_options } = question
          const updatedNestedOptions = nested_options?.map((nestedOpt) => {
            const { nested_fields: nestedFields, ...restNestedOpt } = nestedOpt
            const updatedNestedFields = nestedFields.map(
              ({ id, ...nestedField }) => nestedField,
            )
            return {
              ...restNestedOpt,
              nested_fields: updatedNestedFields,
            }
          })

          return {
            name: question?.question_text,
            order: question.order,
            type: questionType,
            required: question?.required,
            nested_option: question?.nested_option,
            note: question?.note,
            section: templateData?.default_section?.id,
            options: updatedNestedOptions,
          }
        default:
          return {
            name: question?.question_text,
            order: question.order,
            type: questionType,
            required: question?.required,
            nested_option: question?.nested_option,
            note: question?.note,
            section: templateData?.default_section?.id,
          }
      }
    })

    let currentSection = cloneDeep(templateData.default_section.id)
    let currentSubSection = cloneDeep(templateData.default_section.id)
    let lastSectionFullHeader = true

    for (const question of questions) {
      if (question.type === 'header') {
        const { data: sectionData } = await Api.post('assessment/sections', {
          name: question.name,
          order: question.order,
          required: question?.required,
          template: templateData.id,
          parent_section: templateData.default_section.id,
        })
        currentSection = sectionData.id
        lastSectionFullHeader = true

        // logic migration of score ranges to new version of template
        const { data: newResultTypes } = await Api.get(
          'assessment/result_types',
          {
            template: templateData?.id, // new ids of existing result types
          },
        )

        const previousSectionScoring = scoreRanges?.find(
          (range) => range?.section_name === sectionData?.name, // matching new section with previous one by name from GET sections
        )

        if (previousSectionScoring?.section_name) {
          await Api.patch(`assessment/sections/${sectionData?.id}`, {
            minimum_question: previousSectionScoring?.minimum_question,
            inconclusive_description:
              previousSectionScoring?.inconclusive_description,
            required: previousSectionScoring?.required,
            template: templateData?.id,
          })
        }

        if (previousSectionScoring?.score_ranges?.length) {
          await Api.post(
            'assessment/score_ranges/bulk_update_create',
            previousSectionScoring?.score_ranges?.map((range) => {
              const {
                id,
                previous_version,
                result_type_info,
                result_type,
                ...rest
              } = range // only required and new fields are sent

              const newResultTypeId = newResultTypes?.results?.find(
                (type) => type?.previous_version === range?.result_type,
              )?.id

              return {
                ...rest,
                result_type: newResultTypeId,
                section: sectionData?.id,
              }
            }),
          )
        }
      } else {
        const { data } = await Api.post('assessment/fields', {
          ...question,
          section: lastSectionFullHeader ? currentSection : currentSubSection,
        })

        const nestedFields = question?.options
          ?.filter((op) => op?.nested_fields?.length > 0)
          ?.map((op) => op.nested_fields)
          ?.flat()

        if (nestedFields?.length) {
          nestedFields.map(async (field, index) => {
            const { belongs_to_option } = field

            const opId = data?.options.find(
              (o) => o?.order === belongs_to_option,
            )?.id

            await Api.post('assessment/fields', {
              ...field,
              nested_option: opId,
              section: templateData?.default_section?.id,
              type: field?.field_type?.value,
              section: currentSection,
            })
          })
        }
      }
    }

    await Api.put(`assessment/templates/${templateData?.id}/activate/`, {})

    stateService.go('assessment.assessment_manage')
  }

  return (
    <section className="create__assessment" css={baseStyle}>
      <CommonPageHeader headerText={'Assessments'} />
      <AssessmentNavBar />
      {initialValues && initialValues?.questionsList?.length && (
        <Formik
          initialValues={initialValues}
          onSubmit={handleCreateEdit}
          validationSchema={validationSchema}
        >
          {({ values, setFieldValue, isValid = false, touched }) => {
            const isEdit = stateService?.params?.assessmentTemplateId
            const shouldDisableButton =
              !isValid || Object.keys(touched).length === 0
            return (
              <main className="create__assessment__wrapper">
                <div className="header__block">
                  <h4 className="page__title">
                    {isEdit ? 'Edit' : 'Create'} Template
                  </h4>
                  <ButtonElement
                    buttonType="submit"
                    text={isEdit ? 'Save' : 'Create'}
                    functionToTrigger={() => {
                      handleCreateEdit(values)
                    }}
                    disabled={shouldDisableButton || isButtonDisabled}
                  />
                </div>
                <div className="form__wrapper">
                  <div className="name__field">
                    <Field
                      type="text"
                      as={FormikAutoExpandedTextArea}
                      name="template_name"
                      className={classNames('name__input', {
                        error: !values.template_name,
                      })}
                      placeholder="Template Name"
                      value={values.template_name}
                    />
                    <ErrorMessage name="templateName" component="div" />
                  </div>
                  <FieldArray
                    name="questionsList"
                    render={(arrayHelpers) => {
                      async function handleOnDragEnd(result) {
                        if (!result.destination) return

                        const items = Array.from(values.questionsList)
                        const [reorderedItem] = items.splice(
                          result.source.index,
                          1,
                        )
                        items.splice(result.destination.index, 0, reorderedItem)
                        items.forEach((item, idx) => {
                          item.order = idx + 1
                        })

                        await setFieldValue('questionsList', items)
                      }

                      return (
                        <div className="questions__holder">
                          <div className="headers" css={headerStyles}>
                            <p className="header__label order">Order</p>
                            <p className="header__label required">Req.</p>
                            <p className="header__label assessment">
                              Assessment Field
                            </p>
                            <p className="header__label select">Field Type</p>
                            <p className="header__label notes">Note</p>
                            <p className="header__label delete">Delete</p>
                          </div>
                          <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="questions">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                >
                                  {values.questionsList.map(
                                    (question, index) => (
                                      <TemplateQuestion
                                        key={index}
                                        question={question}
                                        index={index}
                                        handleDelete={arrayHelpers.remove}
                                        setButtonDisabled={setButtonDisabled}
                                      />
                                    ),
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                          <div className="button__wrapper">
                            <ButtonElement
                              type="button"
                              functionToTrigger={() => {
                                arrayHelpers.push({
                                  order: values.questionsList.length + 1,
                                  required: false,
                                  question_text: '',
                                  field_type: null,
                                  id: generateUUID(),
                                })
                              }}
                              text="+ New Field"
                            />
                          </div>
                        </div>
                      )
                    }}
                  />
                </div>
              </main>
            )
          }}
        </Formik>
      )}
    </section>
  )
}

const headerStyles = css({
  display: 'flex',
  marginTop: '21px',
  marginLeft: '55px',
  '.header__label': {
    fontSize: '12px',
    color: '#676A6C',
    margin: 0,
    fontWeight: 700,
  },
  '.order': {
    marginRight: '25px',
    width: '35px',
  },
  '.required': {
    marginRight: '25px',
    width: '25px',
  },
  '.assessment': {
    marginRight: '20px',
    minWidth: '641px',
  },
  '.select': {
    marginRight: '20px',
    minWidth: '210px',
  },
  '.notes': {
    minWidth: '35px',
    marginRight: '20px',
  },
  '.delete': {
    minWidth: '35px',
    marginRight: '10px',
  },
})

const baseStyle = css({
  paddingBottom: '300px',
  '.create__assessment__wrapper': {
    background: '#fff',
    width: '1175px',
    marginLeft: '5px',
  },
  '.header__block': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingInline: '14px',
    borderBottom: '1px solid #EEEFF3',
  },
  '.page__title': {
    fontSize: '12px',
    color: '#686A6C',
    margin: 0,
  },
  '.name__field': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: '20px',
  },
  '.name__input': {
    ...TextInputStyle,
    width: '537px',
    textAlign: 'center',
    fontSize: '12px',
    height: '32px',

    '::placeholder': {
      fontSize: '12px',
      color: '#B1B1B1',
    },
  },
  '.button__wrapper': {
    paddingTop: '20px',
    paddingLeft: '163px',
    paddingBottom: '17px',
  },
  '.error': {
    borderColor: '#C80404 !important',
  },
  '@media(max-width: 1400px)': {
    '.create__assessment__wrapper': {
      width: '100%',
      overflowX: 'scroll',
      paddingBottom: '130px',
    },
    '.header__block': {
      width: '1175px',
    },
    '.name__field': {
      width: '1175px',
    },
  },
})
