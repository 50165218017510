type Props = {
  color: string
  width: string
  height: string
}

export const AnalyticsIcon = ({
  color,
  width = '20',
  height = '20',
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5 3.125C17.5 2.2962 17.1708 1.50134 16.5847 0.915291C15.9987 0.32924 15.2038 0 14.375 0H3.125C2.2962 0 1.50134 0.32924 0.915291 0.915291C0.32924 1.50134 0 2.2962 0 3.125V14.375C0 15.2038 0.32924 15.9987 0.915291 16.5847C1.50134 17.1708 2.2962 17.5 3.125 17.5H8.75V16.25H3.125C2.62772 16.25 2.15081 16.0525 1.79917 15.7008C1.44754 15.3492 1.25 14.8723 1.25 14.375V5H16.25V7.835C16.63 8.05441 16.9456 8.37 17.165 8.75004C17.3845 9.13007 17.5 9.56117 17.5 10V3.125ZM3.125 1.25H14.375C14.8723 1.25 15.3492 1.44754 15.7008 1.79917C16.0525 2.15081 16.25 2.62772 16.25 3.125V3.75H1.25V3.125C1.25 2.62772 1.44754 2.15081 1.79917 1.79917C2.15081 1.44754 2.62772 1.25 3.125 1.25ZM15 8.75C14.6685 8.75 14.3505 8.8817 14.1161 9.11612C13.8817 9.35054 13.75 9.66848 13.75 10V18.75C13.75 19.0815 13.8817 19.3995 14.1161 19.6339C14.3505 19.8683 14.6685 20 15 20C15.3315 20 15.6495 19.8683 15.8839 19.6339C16.1183 19.3995 16.25 19.0815 16.25 18.75V10C16.25 9.66848 16.1183 9.35054 15.8839 9.11612C15.6495 8.8817 15.3315 8.75 15 8.75ZM11.25 13.75C10.9185 13.75 10.6005 13.8817 10.3661 14.1161C10.1317 14.3505 10 14.6685 10 15V18.75C10 19.0815 10.1317 19.3995 10.3661 19.6339C10.6005 19.8683 10.9185 20 11.25 20C11.5815 20 11.8995 19.8683 12.1339 19.6339C12.3683 19.3995 12.5 19.0815 12.5 18.75V15C12.5 14.6685 12.3683 14.3505 12.1339 14.1161C11.8995 13.8817 11.5815 13.75 11.25 13.75ZM17.5 12.5C17.5 12.1685 17.6317 11.8505 17.8661 11.6161C18.1005 11.3817 18.4185 11.25 18.75 11.25C19.0815 11.25 19.3995 11.3817 19.6339 11.6161C19.8683 11.8505 20 12.1685 20 12.5V18.75C20 19.0815 19.8683 19.3995 19.6339 19.6339C19.3995 19.8683 19.0815 20 18.75 20C18.4185 20 18.1005 19.8683 17.8661 19.6339C17.6317 19.3995 17.5 19.0815 17.5 18.75V12.5Z"
        fill={color}
      />
    </svg>
  )
}
