import * as React from 'react'
import { css } from '@emotion/react'
import { useDropzone } from 'react-dropzone'
import { useCallback } from 'react'
import { LinearProgress } from '@mui/material'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'
import closeIcon from '@/assets/icons/background-x.svg'
import { FileIcon } from '@/react/componentAssets/FileIcon'
import { BulkUploadIcon } from '@/react/componentAssets/BulkUploadIcon'

type Props = {
  dropMessage: ReactJSXElement
  hoverMessage: ReactJSXElement
  addCallback?: any
  removeCallback?: any
  fileStorage: any
  fileSetStorage: any
  additionalStyles?: object
  accept?: string
  isLoading: boolean
  type: string
  fileError?: string
}

export const ContentDropZone = ({
  addCallback,
  removeCallback,
  fileStorage,
  fileSetStorage,
  additionalStyles = {},
  accept = 'application/pdf',
  isLoading,
  type,
  fileError,
}: Props) => {
  const onDrop = useCallback((acceptedFiles) => {
    addCallback(acceptedFiles[0])
    fileSetStorage(acceptedFiles[0])
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: accept,
  })

  function deleteFile() {
    removeCallback()
    fileSetStorage(null)
  }

  return (
    <div css={[fileUploadBase, additionalStyles]}>
      {fileStorage ? (
        <div css={fileDisplay}>
          <img
            src={closeIcon}
            className="close__icon"
            alt="X"
            onClick={() => {
              deleteFile()
            }}
          />
          <div className="file-name">
            <FileIcon className="file-icon" />
            <p className="file__text">{fileStorage?.name}</p>
          </div>
          {isLoading && (
            <LinearProgress sx={{ height: '9px', borderRadius: '10px' }} />
          )}
          {fileError && <p className="error__message">{fileError}</p>}
        </div>
      ) : null}
      <form
        encType="multipart/form-data"
        css={dropzoneBase}
        {...getRootProps()}
      >
        <input {...getInputProps()} disabled={type === 'placeholder'} />
        {fileStorage ? (
          ''
        ) : (
          <div className={'prop-name'}>
            <BulkUploadIcon className="upload-icon" width="94" height="77" />
            <div className="prop-info">
              <p className="prop-message">
                Click to choose a file or drag it here
              </p>
              {(() => {
                switch (type) {
                  case 'video':
                    return (
                      <>
                        <p className="prop-sub-message">
                          Max File Size: 750 MB
                        </p>
                        <p className="prop-file-type">
                          Content Types: .MOV, .MP4, .OGG, .WEBM
                        </p>
                      </>
                    )
                  case 'pdf':
                    return (
                      <>
                        <p className="prop-sub-message">
                          Max File Size: 100 MB
                        </p>
                        <p className="prop-file-type">Content Type: .PDF</p>
                      </>
                    )
                  case 'ppt':
                    return (
                      <>
                        <p className="prop-sub-message">Max File Size: 25 MB</p>
                        <p className="prop-file-type">
                          Content Types: .PPT, .PPTX
                        </p>
                      </>
                    )
                  default:
                    return
                }
              })()}
            </div>
          </div>
        )}
      </form>
    </div>
  )
}

const fileUploadBase = css({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0px',
  width: '301px',
  height: '225px',
  backgroundColor: '#F7F7F7',
  '.border-spread': {
    width: '312px',
    height: '312px',
  },
})

const dropzoneBase = css({
  zIndex: 100,
  backgroundImage: 'none',
  border: 'none',
  '.prop-info': {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '.prop-name': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    marginTop: '20px',
  },
  '.prop-message': {
    color: '#B3B3B3',
    fontSize: '24px',
    textAlign: 'center',
    margin: 0,
  },
  '.prop-sub-message': {
    fontSize: '12px',
    color: '#B3B3B3',
    fontWeight: 600,
    margin: 0,
  },
  '.prop-file-type': {
    margin: 0,
    color: '#B3B3B3',
    fontWeight: 600,
    fontSize: '8px',
  },
})

const fileDisplay = css({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  zIndex: 200,
  width: '227px',
  height: '116px',
  backgroundColor: 'white',
  bottom: '135px',
  left: '80px',
  borderColor: '#e5e6e7',
  borderStyle: 'solid',
  borderWidth: '1px',
  padding: '10px',
  '.close__icon': {
    cursor: 'pointer',
    width: '12px',
    alignSelf: 'flex-end',
    height: '12px',
  },
  '.file-name': {
    padding: '5px',
    backgroundColor: '#e5e6e7',
    height: '58px',
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  '.file-icon': {
    height: '25px',
    width: '25px',
  },
  '.file__text': {
    maxWidth: '135px',
    color: '#565656',
    fontSize: '12px',
    margin: 0,
    maxHeight: '50px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '.delete-button': {
    cursor: 'pointer',
    borderRadius: '2px',
    border: '1px solid #eee',
    textDecoration: 'none',
    display: 'block',
    padding: '4px 5px',
    textAlign: 'center',
    color: '#aaa',
  },
  '.delete-button:hover': {
    color: '#676a6c',
  },
  '.error__message': {
    fontSize: '10px',
    color: '#C80404',
    margin: 0,
  },
})
