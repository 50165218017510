angular
  .module('ccs')
  .controller(
    'ProcoreRedirectCtrl',
    function ($state, $location, Procore, CurrentUser, Notification, $log) {
      $log.debug('ProcoreRedirectCtrl')

      const code = $location.search().code

      if (!code) {
        Notification.warning(
          'Did not receive correct response from Procore. Please try again.',
        )
        return $state.go('admin.procore', {}, {})
      }

      Procore.getAuthToken(code, (resp) => {
        if (resp.error) {
          Notification.warning(
            'Unable to connect with Procore. Please try again.',
          )
          return $state.go('admin.procore', {}, {})
        }

        if (resp.token) {
          CurrentUser.setProcoreAccessToken(resp.token)
        }

        $state.go('admin.procore', {}, { refresh: true })
      })
    },
  )
