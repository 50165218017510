angular
  .module('ccs')
  .controller(
    'CompanyNewsFormCtrl',
    function (
      $scope,
      $stateParams,
      $state,
      Api,
      app,
      news,
      $uibModal,
      metadataService,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('CompanyNewsFormCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.metadataService = metadataService
      $scope.disableBtn = false

      $scope.news = news
        ? {
            ...news,
            client: news.client.id,
            start_date: new Date(news.start_date),
            end_date: new Date(news.end_date),
          }
        : {
            application: app.id,
            images: [],
            videos: [],
            client: CurrentUser.getClientId(),
            start_date: new Date(),
            end_date: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
          }
      const ALL_PROJECTS_ID = 0
      $scope.app = app

      $scope.deletedPhoto

      function formatDateForSave(date) {
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      $scope.breadcrumbs = [
        {
          title: 'Company News',
          url: 'app.company_news.list({app: ' + app.id + '})',
        },
      ]

      $scope.startPopup = {
        opened: false,
      }

      $scope.endPopup = {
        opened: false,
      }

      $scope.startOptions = {
        formatYear: 'yy',
        maxDate: function () {
          return $scope.news.end_date
        },
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.endOptions = {
        formatYear: 'yy',
        minDate: function () {
          return $scope.news.start_date
        },
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.openStart = function () {
        $scope.startPopup.opened = true
      }

      $scope.openEnd = function () {
        $scope.endPopup.opened = true
      }

      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo

        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'lg',
          scope: $scp,
        })
      }

      function ids(obj) {
        return obj.id
      }

      function redirect() {
        $state.go(
          'app.company_news.list',
          {
            app: app.id,
            pageNumber: $scope.previousPageNumber,
            reverse: $scope.previousReverse,
            order: $scope.previousOrder,
            search: $scope.previousSearch,
          },
          { reload: true },
        )
      }

      function updateHandler(resp) {
        if (resp) {
          var action = $scope.news.id ? 'Updated!' : 'Created!'
          Notification.success('Company News Successfully ' + action)
          redirect()
        }
      }

      function errorHandler(resp) {
        if ($scope.news.projects.length === 0 || resp) {
          const message =
            $scope.news.projects.length === 0 ||
            (resp.non_field_errors &&
              resp.non_field_errors[0] ===
                '"projects" cannot be empty if not "all_projects" is false.')
              ? 'Please assign news item to at least one project before saving.'
              : resp.title[0]
          Notification.danger(message)
          $scope.disableBtn = false
        }
      }

      Api.Projects.get({ page_size: 1000, is_active: 'True' }, function (resp) {
        $scope.projects = [
          { id: ALL_PROJECTS_ID, name: 'All ' + app.projects },
        ].concat(resp.results)
      })

      $scope.$watch('news.projects', function (newValue, oldValue) {
        if (newValue == oldValue) return

        if (newValue.filter((p) => p.id == ALL_PROJECTS_ID).length) {
          if (newValue.length > 1) {
            $scope.news.projects = newValue.filter(
              (p) => p.id == ALL_PROJECTS_ID,
            )
          }
        }
      })

      $scope.$watch('news.start_date', function (newValue, oldValue) {
        if (newValue === oldValue) return

        if (newValue > $scope.news.end_date) {
          $scope.start_error = 'Start Date cannot be after End Date.'
          $scope.end_error = null
        } else {
          $scope.end_error = null
          $scope.start_error = null
        }
      })

      $scope.$watch('news.end_date', function (newValue, oldValue) {
        if (newValue === oldValue) return

        if (newValue < $scope.news.start_date) {
          $scope.end_error = 'End Date cannot be before Start Date.'
          $scope.start_error = null
        } else {
          $scope.end_error = null
          $scope.start_error = null
        }
      })

      $scope.switchDateCurrent = function () {
        $scope.news.start_date = new Date()
      }

      $scope.submit = function () {
        if ($scope.start_error || $scope.end_error) return
        $scope.disableBtn = true

        function companyNewsRequest() {
          const videos = $scope.news.videos.map(ids)
          const images = $scope.news.images.map(ids)
          const start_date = formatDateForSave($scope.news.start_date)
          const end_date = formatDateForSave($scope.news.end_date)
          let projects = $scope.news.projects.map(ids)

          if (projects.filter((p) => p == ALL_PROJECTS_ID).length) {
            $scope.news.all_projects = true
            projects = []
          }

          const news = {
            ...$scope.news,
            videos,
            images,
            projects,
            start_date,
            end_date,
          }

          if ($scope.news.id) {
            Api.CompanyNews.patch(news, updateHandler, errorHandler)
          } else {
            Api.CompanyNews.post(news, updateHandler, errorHandler)
          }
        }

        // Delete photo before save
        if ($scope.deletedPhoto && $scope.deletedPhoto.id) {
          Api.removeImage(
            $scope.deletedPhoto.id,
            function (resp) {
              companyNewsRequest()
            },
            function (error) {
              Notification.danger(error)
            },
          )
        } else {
          companyNewsRequest()
        }
      }

      $scope.addPhoto = function (input) {
        if ($scope.news.images.length < 1) {
          Api.uploadImage(input.files[0], {}, function (response) {
            $scope.news.images.push(response)
            input.value = null
          })
        } else {
          input.value = null
          $scope.$apply(function () {
            Notification.danger('News items are limited to 1 picture.')
          })
        }
      }

      $scope.addVideo = function (input) {
        Api.uploadFile(input.files[0], {}, function (response) {
          $scope.news.videos.push(response)
          input.value = null
        })
      }

      $scope.removePhoto = function (photoID) {
        $scope.news.images = $scope.news.images.filter(function (p) {
          if (p.id == photoID) {
            $scope.deletedPhoto = p
          }
          return p.id !== photoID
        })
      }

      $scope.removeVideo = function (videoID) {
        $scope.news.videos = $scope.news.videos.filter((f) => f.id !== videoID)
      }
    },
  )
