/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { ReactWrapper } from '@components'
import { SettingsPage } from './SettingsPage'
import { CreateEditPage, ExternalForm } from './CreateEditPages'
import { DraftListBase } from './DraftList'
import { ReportDeleteListBase } from './ReportDeleteList'
import { ExportListBase } from './ExportList'
import { DownloadReportDataBase } from './DownloadReportData'
import { MobileFormList } from './MobileFormList'
import { AddMobileReport } from './AddMobileReport'

export const settingsPageComponent = ReactWrapper(SettingsPage, 'settingsPage')
export const createEditPageComponent = ReactWrapper(
  CreateEditPage,
  'createEditPage',
)
export const createEditPageExternalComponent = ReactWrapper(
  ExternalForm,
  'createEditPageExternal',
)
export const mobileFormDraftListComponent = ReactWrapper(
  DraftListBase,
  'mobileFormDraftList',
)
export const mobileFormDeleteListComponent = ReactWrapper(
  ReportDeleteListBase,
  'mobileFormDeleteList',
)
export const mobileFormExportListComponent = ReactWrapper(
  ExportListBase,
  'mobileFormExportList',
)
export const mobileFormListComponent = ReactWrapper(
  MobileFormList,
  'mobileFormListComponent',
)
export const mobileFormDownloadDataComponent = ReactWrapper(
  DownloadReportDataBase,
  'mobileFormDownloadData',
)
export const addMobileReportsComponent = ReactWrapper(
  AddMobileReport,
  'addMobileReportsComponent',
)
