import * as React from 'react'
import { NumberInput } from '@/react/components'
import { getStartingValue, NoteElement } from './index'
import { getTranslatedString } from '@/utils'
import { css } from '@emotion/react'

export const NumberField = ({
  fieldData,
  formMemory,
  possibleGroupData,
  onChange,
}) => {
  const [error, setError] = React.useState(false)

  function callback(value: string) {
    onChange({
      value,
      possibleGroupData,
      id: fieldData.id,
    })
    setError(false)

    setError(!value && fieldData.required)
  }

  React.useEffect(() => {}, [])

  const startingData = getStartingValue(
    possibleGroupData,
    formMemory,
    fieldData,
    'value',
  )

  React.useEffect(() => {
    if (fieldData.required && !startingData) {
      setError(true)
    }
  }, [])

  const baseStyle = getBaseStyle()

  const uniqueId =
    fieldData.id + (possibleGroupData ? '_' + possibleGroupData : '_set_id_0')

  return (
    <section id={uniqueId} css={baseStyle}>
      <div className="label-holder">
        <label css={css({ color: 'inherit' })}>
          {getTranslatedString(fieldData.name)}
          {fieldData.required ? ' *' : null}
        </label>
        {fieldData.note ? <NoteElement noteText={fieldData.note} /> : null}
      </div>
      <NumberInput
        width={'400px'}
        valueCallback={callback}
        defaultValue={startingData}
        height={30}
        waitTime={200}
        error={error}
      />
    </section>
  )
}

function getBaseStyle() {
  return css({
    marginBottom: 15,
  })
}
