import * as React from 'react'
import { css } from '@emotion/react'

import { getCostCodesNavigationSettings } from '../components'
import {
  FloatingWindowHorizontalNavigation,
  CommonPageHeader,
} from '@/react/components'
import { ReloadableElements } from './components'
import { CostCodesNavBar } from '../components/CostCodesNavBar'

export const CostCodesList = () => {
  return (
    <section>
      <CommonPageHeader headerText="Cost Code" />
      <div
        className="codes__wrapper"
        css={{
          marginLeft: '5px',
        }}
      >
        <CostCodesNavBar />
        <ReloadableElements />
      </div>
      <div css={bottomMargin} />
    </section>
  )
}

const bottomMargin = css({
  height: 100,
})
