export function getFirstAndLastWeekDate(date) {
  const firstDayNotFormatted =
    date - new Date((date.getDay() - 1) * 86400 * 1000)
  const firstDay = formatDateYMD(firstDayNotFormatted)

  const lastDay = formatDateYMD(
    new Date(firstDayNotFormatted + 6 * 86400 * 1000),
  )

  return firstDay + ',' + lastDay
}

export function formatDateYMD(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}
export function formatDateMTextDYYYY(date) {
  const formattedDate = date.toLocaleDateString(
    {},
    { timeZone: 'UTC', month: 'long', day: '2-digit', year: 'numeric' },
  )
  const sp = formattedDate.split(' ')
  return `${sp[1]} ${sp[0]}, ${sp[2]}`
}

export function addOrRemoveDaysFormDate(date, days, minus = false) {
  if (minus) return new Date(date.getTime() - days * 86400 * 1000)
  return new Date(date.getTime() + days * 86400 * 1000)
}

export function getHoursAndMinutes(seconds: number): string {
  if (!seconds) return ''
  seconds = Number(seconds)
  const h = Math.trunc(seconds / 3600)
  const m = Math.trunc((seconds % 3600) / 60)
  const s = Math.trunc((seconds % 3600) % 60)

  if (m === 0) {
    return h + 'h'
  } else {
    return h + 'h ' + m + 'm'
  }
}
