import Papa from 'papaparse'
import moment from 'moment'

angular
  .module('ccs')
  .controller(
    'TrainingImportCtrl',
    function ($scope, Api, $state, $stateParams, Notification, $log) {
      $log.debug('TrainingImportCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'

      $scope.newFile = null

      $scope.dzConfig = {
        acceptedFiles: '.csv',
        parallelUploads: 1,
        maxFiles: 1,
        maxFileSize: 30,
        autoProcessQueue: false,
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        init: function () {
          this.on('addedfile', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
        },
      }

      function checkSize(line_number, size, value, message) {
        if (value.length > size) {
          $scope.$apply(function () {
            $scope.errors.push({ line: line_number, message: message })
          })
        }
      }

      function checkNotNull(line_number, value, message) {
        if (value.length == 0) {
          $scope.$apply(function () {
            $scope.errors.push({ line: line_number, message: message })
          })
        }
      }

      function checkDateFormat(line_number, date, message, allow_null) {
        if (allow_null == true && date == '') {
          return true
        }

        if (!moment(date, 'M/D/YYYY', true).isValid()) {
          $scope.$apply(function () {
            $scope.errors.push({ line: line_number, message: message })
          })
          return false
        } else {
          return true
        }
      }

      $scope.good_file = false

      $scope.submit = function () {
        $('.dz-progress').css('display', 'block')
        Api.uploadBulkTraining(
          $scope.file_to_upload,
          function (resp) {
            Notification.success('Successfully imported training CSV!')

            $('.dz-progress').css('display', 'none')
            $state.go('trainings.documentation', {
              pageNumber: $scope.previousPageNumber,
              order: $scope.previousOrder,
              reverse: $scope.previousReverse,
            })
          },
          function (error) {
            $('.dz-progress').css('display', 'none')
            error.message.forEach((singleError) => {
              Notification.clearNotification()
              if (singleError.error) {
                $scope.errors.push({
                  line: singleError.row,
                  message:
                    typeof singleError.error === 'object'
                      ? Object.values(singleError.error)[0][0]
                      : singleError.error,
                })
              } else if (singleError.message) {
                $scope.fileSummary = singleError.message
              }
            })
          },
        )
      }

      $scope.dzAddedFile = function (file) {
        $scope.$apply(function () {
          $scope.fileSummary = ''
          $scope.errors = []
          $scope.good_file = false
          $scope.file_to_upload = null
        })

        var pieces = file.name.split(/[.]+/) //can't do MIME type detection
        if (pieces[pieces.length - 1] == 'csv') {
          $scope.newFile = file // for uploading it

          Papa.parse(file, {
            header: true,
            error: function (error, file) {
              $scope.$apply(function () {
                $scope.errors.push({
                  line: i,
                  message: 'Unknown read error.  Use sample file as template.',
                })
              })
            },
            complete: function (results, file) {
              // check file headers
              if (
                JSON.stringify(results.meta.fields) !==
                JSON.stringify([
                  '* NAME',
                  'EMPLOYEE ID',
                  '* COMPANY',
                  '* TRAINING TOPIC',
                  '* DATE TRAINED',
                  'DATE EXPIRING',
                  '* TRAINED BY',
                  'TRADE',
                ])
              ) {
                $scope.$apply(function () {
                  $scope.errors.push({
                    line: 0,
                    message:
                      'Cannot validate file. Please use the correct column headers in row one from the example document.',
                  })
                })
                return
              }
              for (var i = 0; i < results.data.length; i++) {
                var line = results.data[i]
                var line_number = i + 2

                for (var key in results.data[i]) {
                  if (!key && !results.data[i][key]) {
                    delete results.data[i][key]
                  }
                }

                if (Object.keys(results.data[i]).length > 8) {
                  $scope.$apply(function () {
                    $scope.errors.push({
                      line: line_number,
                      message: 'Too many columns.',
                    })
                  })
                } else if (Object.keys(results.data[i]).length == 1) {
                  //then it's a blank line, probably at the end of the file
                  //remove it because we don't want to have to remind people over and over
                  //again not to have these.
                  results.data.splice(i, 1)
                } else if (Object.keys(results.data[i]).length < 8) {
                  $scope.$apply(function () {
                    $scope.errors.push({
                      line: line_number,
                      message: 'Not enough columns.',
                    })
                  })
                } else {
                  checkSize(line_number, 255, line['* NAME'], 'Name too long.')
                  checkSize(
                    line_number,
                    255,
                    line['EMPLOYEE ID'],
                    'Eployee ID too long.',
                  )
                  checkNotNull(
                    line_number,
                    line['* NAME'],
                    'Name cannot be blank.',
                  )
                  checkSize(
                    line_number,
                    255,
                    line['* COMPANY'],
                    'Company too long.',
                  )
                  checkNotNull(
                    line_number,
                    line['* COMPANY'],
                    'Company cannot be blank.',
                  )
                  checkSize(
                    line_number,
                    255,
                    line['* TRAINING TOPIC'],
                    'Training topic too long.',
                  )
                  checkNotNull(
                    line_number,
                    line['* TRAINING TOPIC'],
                    'Training topic cannot be blank.',
                  )
                  var date_trained_good = checkDateFormat(
                    line_number,
                    line['* DATE TRAINED'],
                    'Invalid date trained, use (MM/DD/YYYY).',
                    false,
                  )
                  var date_expiring_good = checkDateFormat(
                    line_number,
                    line['DATE EXPIRING'],
                    'Invalid date expiring, use (MM/DD/YYYY).',
                    true,
                  )
                  checkSize(
                    line_number,
                    255,
                    line['* TRAINED BY'],
                    'Trained by too long.',
                  )
                  checkNotNull(
                    line_number,
                    line['* TRAINED BY'],
                    'Trained by cannot be blank.',
                  )
                  checkSize(line_number, 255, line['TRADE'], 'Trade too long.')

                  if (
                    date_trained_good == true &&
                    date_expiring_good == true &&
                    line['DATE EXPIRING'] !== ''
                  ) {
                    var trained = moment(line['* DATE TRAINED'], 'MM/DD/YYYY')
                    var expiring = moment(line['DATE EXPIRING'], 'MM/DD/YYYY')
                    if (expiring < trained) {
                      $scope.$apply(function () {
                        $scope.errors.push({
                          line: line_number,
                          message:
                            'Expiration date must be after training date.',
                        })
                      })
                    }
                  }
                }
              }
              if ($scope.errors.length == 0) {
                $scope.$apply(function () {
                  $scope.good_file = true
                  $scope.file_to_upload = file
                })
              }
            },
          })
        } else {
          $log.error('Incorrect file type: ' + pieces[pieces.length - 1])
        }
      }
    },
  )
