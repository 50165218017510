angular
  .module('ccs')
  .controller(
    'ProjectToolboxListCtrl',
    function (
      $scope,
      Api,
      app,
      $stateParams,
      $state,
      uiPermissionService,
      CurrentUser,
      $log,
    ) {
      $log.debug('ProjectToolboxListCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.pageSize = 20

      //Initializing the total items to page size * page number to resolve a known issue with ui pagination.
      //See https://github.com/angular-ui/bootstrap/issues/5858
      //And https://stackoverflow.com/questions/27911740/ui-bootstrap-pagination-resetting-current-page-on-initialization
      //totalItems really assigned during the callback when items are retrieved from the API.
      $scope.data = { items: [], total: $scope.pageNumber * $scope.pageSize }

      $scope.app = app
      $scope.userIs = CurrentUser.is
      $scope.permissionEditDeleteToolboxTalk =
        uiPermissionService.getPermissionEditDeleteToolboxTalk()

      function query() {
        let q = {
          application: app.id,
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      if (CurrentUser.is('client_admin')) {
        $scope.tableHeaders = [
          { key: 'name', display: app.toolbox + ' Name', sortable: true },
          { key: 'edit', display: 'Edit', sortable: false, centered: true },
          {
            key: 'projects',
            display: $scope.app.projects_display,
            sortable: false,
            centered: true,
          },
          { key: 'delete', display: 'Delete', sortable: false, centered: true },
        ]
      } else if ($scope.permissionEditDeleteToolboxTalk) {
        $scope.tableHeaders = [
          { key: 'name', display: app.toolbox + ' Name', sortable: true },
          {
            key: 'projects',
            display: $scope.app.projects_display,
            sortable: false,
            centered: true,
          },
          { key: 'delete', display: 'Delete', sortable: false, centered: true },
        ]
      } else {
        $scope.tableHeaders = [
          { key: 'name', display: app.toolbox + ' Name', sortable: true },
          {
            key: 'projects',
            display: $scope.app.projects_display,
            sortable: false,
            centered: true,
          },
        ]
      }

      function get() {
        Api.ToolboxTopics.get(query(), function (resp) {
          $scope.data.items = resp.results
          $scope.data.total = resp.count
        })

        $state.transitionTo(
          'app.toolboxes.project_to_toolbox',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          {
            notify: false,
          },
        )
      }

      $scope.changePage = get

      get()

      $scope.deleteToolboxTopic = (topic) => {
        Api.ToolboxTopics.delete(topic, (resp) => {
          get()
        })
      }
    },
  )
