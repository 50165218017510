angular.module('ccs').directive('limitTo', limitTo)

function limitTo() {
  return {
    require: 'ngModel',
    restrict: 'A',
    scope: {
      min: '=',
      max: '=',
    },
    link: function (scope, element, attr, ngModelCtrl) {
      function fromUser(text) {
        if (text) {
          var transformedInput = text.slice(0, scope.max)
          if (transformedInput !== text) {
            ngModelCtrl.$setViewValue(transformedInput)
            ngModelCtrl.$render()
          }
          return transformedInput
        }
      }

      ngModelCtrl.$parsers.push(fromUser)
    },
  }
}
