import * as React from 'react'
import { css } from '@emotion/react'
import { CommonPageHeader, useAngularServices } from '@/react/components'
import { ReportBase } from './components'
import { useRouter } from '@/react/hooks'

export const CreateEditPage = () => {
  const { Notification } = useAngularServices()
  const { stateService } = useRouter()
  const [error, setError] = React.useState<string | null>(null)

  if (error) {
    Notification.error(error)
  }

  return (
    <section css={css({ paddingBottom: 200 })}>
      <CommonPageHeader
        headerText={
          stateService.current.name === 'app.forms.reports_edit'
            ? 'Edit Report'
            : 'Mobile Form Reports'
        }
      />
      <ReportBase setError={setError} />
    </section>
  )
}
