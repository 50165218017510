export const encodeString = (
  string: string | boolean,
  advancedFiltersEncode = true,
): string | boolean => {
  if (typeof string === 'boolean') return string
  const initialChars: string[] = (string + '').split('')
  const encodedChars: string[] = []
  initialChars.forEach((char) => {
    const encodedChar = encodeURIComponent(char)
    if (encodedChar.length === 1) {
      encodedChars.push(encodeSpecific(encodedChar, advancedFiltersEncode))
    } else {
      encodedChars.push(encodedChar)
    }
  })
  return encodedChars.join('')
}

const encodeSpecific = (char: string, advancedFiltersEncode = true): string => {
  return char
    .replace(/%/g, '%25')
    .replace(/␣/g, '%20')
    .replace(/!/g, '%21')
    .replace(/"/g, '%22')
    .replace(/#/g, '%23')
    .replace(/\$/g, '%24')
    .replace(/&/g, '%26')
    .replace(/'/g, '%27')
    .replace(/\*/g, advancedFiltersEncode ? '%5C%2A' : '%2A')
    .replace(/\\/g, advancedFiltersEncode ? '%5C%5C' : '%5C')
}
