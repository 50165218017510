/** @ngInject */
export function assignedConfig($stateProvider) {
  $stateProvider.state('assigned', {
    templateUrl: 'app/views/share/base.html',
    abstract: true,
    data: { specialClass: 'body-share' },
    controller: function ($scope, CurrentCompanyLogo) {
      $scope.logo_img = ''
      var logoInterval = setInterval(function () {
        if (CurrentCompanyLogo.getLogo()) {
          $scope.logo_img = CurrentCompanyLogo.getLogo()
          clearInterval(logoInterval)
          setTimeout(function () {
            $scope.$apply()
          }, 0)
        }
      }, 500)
      setTimeout(function () {
        clearInterval(logoInterval)
      }, 120000)
    },
  })
}
