import React, { useState } from 'react'
import { CheckIcon, ShareIcon } from '@/react/componentAssets'
import { ThinCheckmark } from '@/react/componentAssets/ThinCheckmark'
import { useAngularServices } from '@/react/components'
import { UISref } from '@/react/components/UISref'
import classNames from 'classnames'
import Highlighter from 'react-highlight-words'
import { useRouter } from '@/react/hooks'
import moment from 'moment'

export const ToolboxRow = ({
  index,
  toolbox,
  search,
  handleChecked,
  selectedIds,
  page,
}) => {
  const { topic, user, project, date_created, client_object_key } = toolbox
  const { stateService } = useRouter()
  const { Api, CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const [hoveredField, setHoveredField] = useState(null)
  const [isLinkCopied, setLinkCopied] = useState(false)

  const handleCopyLink = () => {
    const currentURL = window.location.href
    const urlObject = new URL(currentURL)
    const host = urlObject.host

    navigator.clipboard.writeText(
      `https://${host}/share/toolbox/${client_object_key}`,
    )

    setLinkCopied(true)
  }

  return (
    <>
      <tr
        className={classNames('toolbox__row', {
          dark: index % 2 === 0,
        })}
        key={index}
      >
        <td className="checkbox__td">
          <label className="checkbox-container">
            <input
              type="checkbox"
              checked={selectedIds.includes(toolbox?.id)}
              onChange={() => {
                handleChecked(toolbox?.id)
              }}
            />
            <span className="checkmark">
              <ThinCheckmark color={variableColor} />
            </span>
          </label>
        </td>
        <td
          className="text__cell topic__cell"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'topic',
              text: topic?.name,
              max__length: 47,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <UISref
            to={'app.toolboxes.view'}
            params={{
              toolbox: toolbox?.id,
              previousPageNumber: page,
              previousSearch: search,
            }}
          >
            <Highlighter
              highlightClassName="highlighted__text"
              searchWords={[search]}
              autoEscape={true}
              textToHighlight={topic?.name}
            />
          </UISref>
        </td>
        <td
          className="text__cell"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'name',
              text: `${user?.first_name}  ${user?.last_name}`,
              max__length: 23,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <Highlighter
            highlightClassName="highlighted__text"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={
              user?.first_name ? `${user?.first_name}  ${user?.last_name}` : ''
            }
          />
        </td>
        <td
          className="text__cell"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'company',
              text: user?.company_name,
              max__length: 27,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <Highlighter
            highlightClassName="highlighted__text"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={user?.company_name}
          />
        </td>
        <td
          className="text__cell"
          onMouseOver={() => {
            setHoveredField({
              field__name: 'project',
              text: project?.name,
              max__length: 24,
            })
          }}
          onMouseOut={() => {
            setHoveredField(null)
          }}
        >
          <Highlighter
            highlightClassName="highlighted__text"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={project?.name}
          />
        </td>
        <td className="text__cell">
          <Highlighter
            highlightClassName="highlighted__text"
            searchWords={[search]}
            autoEscape={true}
            textToHighlight={moment(date_created).format('MM/DD/YYYY')}
          />
        </td>
        <td
          className="share__td"
          onClick={handleCopyLink}
          onMouseLeave={() => {
            setLinkCopied(false)
          }}
        >
          <ShareIcon color={variableColor} width="30px" height="30px" />

          {isLinkCopied && (
            <div className="sucess__copy">
              <CheckIcon width="11" height="8" color="green" />
              <p className="copy__message">URL copied to clipboard</p>
            </div>
          )}
        </td>
      </tr>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </>
  )
}
