import { BinDeleteIcon, EditIcon, ViewIcon } from '@/react/componentAssets'
import { ConfirmationModal } from '@/react/componentAssets/ConfirmationModal'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useState } from 'react'
import Highlighter from 'react-highlight-words'

export const TemplateRow = ({
  template,
  index,
  search,
  setRefresh,
  isSupervisor,
  page,
}) => {
  const { name, client, id } = template
  const { Api, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()
  const [isDeleteModalVisible, setDeleteModalVisible] = useState(false)
  const [hoveredField, setHoveredField] = useState({})

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const handleDelete = async () => {
    await Api.delete(`toolbox_topics/${id}`, {})
    setRefresh(true)
    setDeleteModalVisible(false)
  }

  return (
    <tr
      className={classNames('table__row', {
        dark: index % 2 === 0,
      })}
    >
      <td
        className="text__cell"
        onMouseOver={() => {
          setHoveredField({
            field_name: 'title__fullname',
            text: name,
            max_length: 88,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={name}
        />
        {hoveredField?.text?.length > hoveredField?.max_length && (
          <div className={`full__name ${hoveredField?.field_name}`}>
            {hoveredField?.text}
          </div>
        )}
      </td>
      {!isSupervisor && (
        <td className="icon__td">
          <EditIcon
            color={variableColor}
            additionalStyles={{ cursor: 'pointer' }}
            onClick={() => {
              stateService.go('app.toolboxes.edit', {
                toolbox: id,
                previousSearch: 'fromTemplates',
                previousPageNumber: page,
              })
            }}
          />
        </td>
      )}
      <td className="icon__td">
        <ViewIcon
          color={variableColor}
          additionalStyles={{ cursor: 'pointer' }}
          onClick={() => {
            stateService.go('app.toolboxes.toolbox_templates_view', {
              toolbox: id,
              previousPageNumber: page,
            })
          }}
        />
      </td>
      {!isSupervisor && (
        <td className="icon__td" style={{ position: 'relative' }}>
          <BinDeleteIcon
            color={client ? variableColor : 'grey'}
            additionalStyles={{ cursor: client ? 'pointer' : 'auto' }}
            onClick={() => {
              if (client) {
                setDeleteModalVisible(!isDeleteModalVisible)
              }
            }}
          />
          {isDeleteModalVisible ? (
            <ConfirmationModal
              action={'Delete'}
              title={name}
              handleClick={handleDelete}
              handleClose={() => {
                setDeleteModalVisible(false)
              }}
            />
          ) : null}
        </td>
      )}
    </tr>
  )
}
