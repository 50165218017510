import { resolvers } from '../resolvers'

/** @ngInject */
export function appsProcessFlowsConfig($stateProvider) {
  $stateProvider
    .state('app.process_flows', {
      abstract: true,
      templateUrl: 'app/views/common/uiview.html',
    })
    .state('app.process_flows.list', {
      controller: 'ProcessFlowListCtrl',
      templateUrl: 'app/views/process_flows_list.html',
      url: '/process-flows?pageNumber&order&reverse&search',
      resolve: resolvers.appResolver,
    })
    .state('app.process_flows.assign', {
      controller: 'ProcessFlowAssignCtrl',
      templateUrl: 'app/views/process_flows_assign.html',
      url: '/process-flows/:processFlow/assign?previousPageNumber&previousOrder&previousReverse&previousSearch?pageNumber?search',
      resolve: resolvers.appResolver,
    })
    .state('app.process_flows.new', {
      controller: 'ProcessFlowFormCtrl',
      templateUrl: 'app/views/process_flows_form.html',
      url: '/process-flows/new',
      resolve: { ...resolvers.appResolver, ...resolvers.writeOnlyViewResolver },
    })
    .state('app.process_flows.view', {
      controller: 'ProcessFlowViewCtrl',
      templateUrl: 'app/views/procedure_view.html',
      url: '/procedures/view',
      resolve: resolvers.appResolver,
    })
}
