import async from 'async'
import { random } from 'lodash'

import {
  trimFileName,
  validateFileName,
  _extends,
  isCorrectFile,
} from '@/utils'

import pluralize from 'pluralize'
import moment from 'moment'

angular
  .module('ccs')
  .controller(
    'ExternalFormCtrl',
    function (
      $scope,
      Notification,
      $stateParams,
      $state,
      Api,
      ngDialog,
      $uibModal,
      filterService,
      CurrentUser,
      CurrentCompanyLogo,
      $timeout,
      VariableThemeColor,
      $log,
    ) {
      $log.debug('ExternalFormCtrl')

      let clientObjectKey = $stateParams.client_object_key
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse == 'true'
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : '-date_created'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null
      $scope.backTo = $stateParams.backTo ? $stateParams.backTo : 'reports'
      $scope.filterService = filterService
      $scope.external_contact = {}
      $scope.error = null
      $scope.disabled = false
      $scope.isOpen = false
      $scope.isNotEmpty = false
      $scope.showPhotoLoader = false
      $scope.showPhotoLoaderCount = []
      $scope.showSignatureLoader = false
      $scope.showSignatureLoaderCount = []
      $scope.showSketchLoader = false
      $scope.showSketchLoaderCount = []
      $scope.in_progress = false
      $scope.isSubmitedReport =
        !!$stateParams.key || !!Object.keys($scope.external_contact).length
      CurrentCompanyLogo.reset()

      $scope.popup = {
        opened: false,
      }

      $scope.areFilesInvalid = false
      $scope.loadingDeletedFiles = {}
      $scope.loadingDeletedPhotos = {}

      $scope.retryFiles = {}

      $scope.dateOptions = {
        formatYear: 'yy',
        startingDay: 1,
        datepickerMode: 'day',
      }

      $scope.signatureSketchKeys = {
        signature: 'signature',
        sketch: 'sketch',
      }

      $scope.selectedItems = {}

      $scope.deletedFiles = {}

      $scope.deletedPhotos = {}

      $scope.setDeletedPhotos = function (obj, key) {
        // key = photos | signatures | sketches
        if (obj && key) {
          if (!$scope.deletedPhotos[key]) {
            $scope.deletedPhotos[key] = []
          }
          $scope.deletedPhotos[key].push(obj)
        }
      }

      $scope.setDeletedFiles = function (obj, key = 'files') {
        // key = files
        if (obj && key) {
          if (!$scope.deletedFiles[key]) {
            $scope.deletedFiles[key] = []
          }
          $scope.deletedFiles[key].push(obj)
        }
      }

      $scope.selectedMultiAnswers = []
      $scope.setSelectedMultiAnswers = function (field) {
        var obj = {
          reportfield: field.answer.id,
          fieldoption: field.answer.selectedOption.id,
          deleted: field.answer.selectedOption.deleted,
          field: field.id,
          set_id: field.answer.set_id,
        }

        if (!$scope.selectedMultiAnswers.length) {
          $scope.selectedMultiAnswers.push(obj)
          return
        }

        var spliced = false

        for (var i = 0; i < $scope.selectedMultiAnswers.length; i++) {
          var option = $scope.selectedMultiAnswers[i]
          if (
            option.field == obj.field &&
            option.set_id == obj.set_id &&
            option.fieldoption == obj.fieldoption
          ) {
            $scope.selectedMultiAnswers.splice(i, 1)
            spliced = true
          }
        }

        if (!spliced) {
          $scope.selectedMultiAnswers.push(obj)
        }

        delete field.answer.selectedOption
      }

      $scope.displayTime = (dateString) => {
        return moment(dateString).format('hh:mm A')
      }

      $scope.displayDate = (dateString) => {
        const date = new Date(dateString)
        return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
      }

      $scope.open = function () {
        $scope.popup.opened = true
      }

      $scope.hstep = 1
      $scope.mstep = 1

      $scope.viewPhoto = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo
        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      $scope.removePhoto = function (photo, field) {
        for (var j = 0; j < field.answer.photos.length; j++) {
          if (field.answer.photos[j].id == photo.id) {
            if (!photo.state) {
              $scope.setDeletedPhotos(field.answer.photos[j], 'photos')
            } else if (photo.state === 'uploadingFile') {
              $scope.loadingDeletedPhotos[photo.onLoadDeleteId] = true
            } else {
            }
            field.answer.photos.splice(j, 1)
          }
        }
      }

      $scope.addPhoto = function (input) {
        if (!input.value || !input.files.length) return
        if (
          input.files[0].type == 'image/png' ||
          input.files[0].type == 'image/jpeg'
        ) {
          let onLoadDeleteId = random(1, 100000)
          let indexToSwitch = renderFiles(
            'photos',
            {
              name: input.files[0].name,
              state: 'uploadingFile',
              onLoadDeleteId: onLoadDeleteId,
            },
            input.attributes['data-path'].value,
            input.id,
          )

          Api.uploadImage(
            input.files[0],
            { onLoadDeleteId },
            function (resp) {
              if (!$scope.loadingDeletedPhotos[resp.in_loading_delete_id]) {
                renderFiles(
                  'photos',
                  resp,
                  input.attributes['data-path'].value,
                  input.id,
                  indexToSwitch,
                )
              }
              input.value = null
            },
            function (error) {
              let errorText
              if (error) {
                errorText = error
              } else {
                errorText =
                  'Image upload failed: Stronger network connection required.'
              }
              renderFiles(
                'photos',
                {
                  name: input.files[0].name,
                  state: 'loadingFailed',
                  onLoadDeleteId: onLoadDeleteId,
                  error: errorText,
                },
                input.attributes['data-path'].value,
                input.id,
                indexToSwitch,
              )
            },
          )
        } else {
          Notification.danger(
            'Invalid image file format. Please select a png or jpg file.',
          )
          setTimeout(function () {
            $scope.$apply()
          }, 0)
        }
      }

      $scope.trimFileName = trimFileName

      const FILE_SIZE_LIMIT = 50 * 1024 * 1024

      $scope.addFile = function (input) {
        if (!input.value || !input.files.length) return
        $scope.areFilesInvalid = true
        for (const [key, file] of Object.entries(input.files)) {
          if (Number(key) < 10) {
            loadAndCheckLogic(file)
          }
        }

        function loadAndCheckLogic(file) {
          if (isCorrectFile(file.type)) {
            var errors = validateFileName(
              file.name.substring(0, file.name.lastIndexOf('.')),
            )
            if (errors.length > 0) {
              Notification.danger(errors.join('\n'))
              setTimeout(function () {
                $scope.$apply()
              }, 0)
            } else if (file.size < FILE_SIZE_LIMIT) {
              let onLoadDeleteId = random(1, 100000)
              let indexToSwitch = renderFiles(
                'files',
                {
                  name: file.name,
                  state: 'uploadingFile',
                  onLoadDeleteId: onLoadDeleteId,
                },
                input.attributes['data-path'].value,
                input.id,
              )
              Api.uploadFile(
                file,
                { onLoadDeleteId },
                (response) => {
                  if (
                    !$scope.loadingDeletedFiles[response.in_loading_delete_id]
                  ) {
                    renderFiles(
                      'files',
                      response,
                      input.attributes['data-path'].value,
                      input.id,
                      indexToSwitch,
                    )
                    input.value = null
                  }
                  if (typeof $scope.close === 'function') $scope.close()
                },
                (error) => {
                  const retryFileId = '' + input.id + file.lastModified
                  $scope.retryFiles[retryFileId] = file
                  Notification.danger(error[0])
                  renderFiles(
                    'files',
                    {
                      name: file.name,
                      state: 'uploadFailed',
                      indexToRetry: indexToSwitch,
                      retryFileId: retryFileId,
                      path: input.attributes['data-path'].value,
                      inputId: input.id,
                      onLoadDeleteId: onLoadDeleteId,
                    },
                    input.attributes['data-path'].value,
                    input.id,
                    indexToSwitch,
                  )
                },
              )
            } else if (file.size > FILE_SIZE_LIMIT) {
              renderFiles(
                'files',
                {
                  name: file.name,
                  state: 'failedDueSize',
                },
                input.attributes['data-path'].value,
                input.id,
              )
              setTimeout(function () {
                $scope.$apply()
              }, 0)
            }
          } else {
            Notification.danger('Invalid file file format.')
            setTimeout(function () {
              $scope.$apply()
            }, 0)
          }
        }
      }

      function renderFiles(fieldType, resp, pathData, inputId, indexToPlaceIn) {
        var fields = []
        var path = pathData.split('_')
        let indexOfPlacement
        if (indexToPlaceIn) indexOfPlacement = indexToPlaceIn

        if (path[0] === 'headers' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.headers[path[1]].fields
        }
        if (path[0] === 'others' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.others[0].fields
        }

        fields.forEach(function (field) {
          if (path[2] === 'group') {
            if (field.group) {
              field.group.fields.forEach(function (group_field) {
                if (
                  path[4] !== 'answer-options' &&
                  (group_field.id == inputId ||
                    group_field.tempId == inputId) &&
                  group_field.answer.set_id == parseInt(path[3])
                ) {
                  const groupFieldDirection = group_field.answer[fieldType]
                  if (indexToPlaceIn || indexToPlaceIn === 0) {
                    groupFieldDirection[indexToPlaceIn] = resp
                  } else {
                    indexOfPlacement = groupFieldDirection.length
                    groupFieldDirection.push(resp)
                  }
                }
                if (
                  path[4] === 'answer-options' &&
                  group_field.answer.set_id == parseInt(path[3]) &&
                  group_field.type === 'nested_option'
                ) {
                  var nested_field =
                    group_field.answer_options[path[5]].nested_fields[path[7]]
                  const nestedFieldDirection = nested_field.answer[fieldType]
                  if (indexToPlaceIn || indexToPlaceIn === 0) {
                    nestedFieldDirection[indexToPlaceIn] = resp
                  } else {
                    indexOfPlacement = nestedFieldDirection.length
                    nested_field && nestedFieldDirection.push(resp)
                  }
                }
              })
            }
          } else if (
            path[2] === 'answer-options' &&
            field.type === 'nested_option'
          ) {
            if (field.answer_options) {
              var nested_field =
                field.answer_options[path[3]].nested_fields[path[5]]

              if (nested_field?.id == inputId) {
                const nestedFieldDirection = nested_field.answer[fieldType]

                if (indexToPlaceIn || indexToPlaceIn === 0) {
                  nestedFieldDirection[indexToPlaceIn] = resp
                } else {
                  indexOfPlacement = nestedFieldDirection.length
                  nested_field && nestedFieldDirection.push(resp)
                }
              }
            }
          } else {
            if (field.id == inputId) {
              const fieldDirection = field.answer[fieldType]
              if (indexToPlaceIn || indexToPlaceIn === 0) {
                fieldDirection[indexToPlaceIn] = resp
              } else {
                indexOfPlacement = fieldDirection.length
                fieldDirection.push(resp)
              }
            }
          }
        })
        $timeout(() => {
          $scope.areFilesInvalid = checkFilesStatus('one-file')
        }, 500)
        return indexOfPlacement
      }

      $scope.retryFileAdd = function (
        file,
        indexToSwitch,
        path,
        inputId,
        retryFileId,
      ) {
        let onLoadDeleteId = random(1, 100000)
        renderFiles(
          'files',
          {
            name: file.name,
            state: 'uploadingFile',
            onLoadDeleteId: onLoadDeleteId,
          },
          path,
          inputId,
          indexToSwitch,
        )
        Api.uploadFile(
          file,
          { onLoadDeleteId },
          (response) => {
            renderFiles('files', response, path, inputId, indexToSwitch)
          },
          (error) => {
            renderFiles(
              'files',
              {
                name: file.name,
                state: 'uploadFailed',
                indexToRetry: indexToSwitch,
                retryFileId: retryFileId,
                path: path,
                inputId: inputId,
              },
              path,
              inputId,
              indexToSwitch,
            )
          },
        )
      }

      $scope.removeFile = function (file, field) {
        for (var j = 0; j < field.answer.files.length; j++) {
          if (field.answer.files[j].id == file.id) {
            if (!file.state) {
              $scope.setDeletedFiles(field.answer.files[j], 'files')
            } else if (file.state === 'uploadingFile') {
              $scope.loadingDeletedFiles[file.onLoadDeleteId] = true
            }
            field.answer.files.splice(j, 1)
            $timeout(() => {
              $scope.areFilesInvalid = checkFilesStatus('one-file')
            }, 500)
          }
        }
        setTimeout(function () {
          $scope.$apply()
        }, 0)
      }

      $scope.showDeleteAlert = function (
        title,
        message,
        deleteFileData,
        fieldData,
        isExternal,
      ) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content download-alert-width',
          templateUrl: 'app/views/alert_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.title = title
            $scope.message = message
            $scope.textAlignment = 'center'
            $scope.dualOption = true
            $scope.deleteFileData = deleteFileData
            $scope.fieldData = fieldData
            $scope.isExternalReport = isExternal
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }

      function checkFilesStatus(checkedElementClass) {
        return [...document.getElementsByClassName(checkedElementClass)].some(
          (file) => file.dataset.isInState,
        )
      }

      $scope.print = function () {
        window.print()
      }

      $scope.showCurrentPhotoLoader = function (input, file_name) {
        var fields = []
        var path = input.attributes['data-path'].value.split('_')

        if (path[0] === 'headers' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.headers[path[1]].fields
        }

        if (path[0] == 'others' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.others[0].fields
        }

        fields.forEach(function (field) {
          if (path[2] === 'group') {
            if (field.group) {
              field.group.fields.forEach(function (group_field) {
                if (
                  path[4] !== 'answer-options' &&
                  (group_field.id == input.id ||
                    group_field.tempId == input.id) &&
                  group_field.answer.set_id == parseInt(path[3])
                ) {
                  group_field.answer.uploading_images.push({
                    error: null,
                    show_error: false,
                    show_photo_loader: true,
                    file_name: file_name.toLowerCase(),
                  })
                }
                if (
                  path[4] === 'answer-options' &&
                  group_field.answer.set_id == parseInt(path[3]) &&
                  group_field.type == 'nested_option'
                ) {
                  var nested_field =
                    group_field.answer_options[path[5]].nested_fields[path[7]]
                  if (nested_field) {
                    nested_field.answer.uploading_images.push({
                      error: null,
                      show_error: false,
                      show_photo_loader: true,
                      file_name: file_name.toLowerCase(),
                    })
                  }
                }
              })
            }
          } else if (
            path[2] === 'answer-options' &&
            field.type === 'nested_option'
          ) {
            if (field.answer_options) {
              var nested_field =
                field.answer_options[path[3]].nested_fields[path[5]]
              if (nested_field) {
                nested_field.answer.uploading_images.push({
                  error: null,
                  show_error: false,
                  show_photo_loader: true,
                  file_name: file_name.toLowerCase(),
                })
              }
            }
          } else {
            if (field.id == input.id) {
              field.answer.uploading_images.push({
                error: null,
                show_error: false,
                show_photo_loader: true,
                file_name: file_name.toLowerCase(),
              })
            }
          }
        })
      }

      $scope.hideCurrentPhotoLoader = function (input) {
        var fields = []
        var path = input.attributes['data-path'].value.split('_')

        if (path[0] === 'headers' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.headers[path[1]].fields
        }

        if (path[0] == 'others' && path[1] >= 0) {
          fields = $scope.report.form.collapsedSections.others[0].fields
        }

        fields.forEach(function (field) {
          if (path[2] === 'group') {
            if (field.group) {
              field.group.fields.forEach(function (group_field) {
                if (
                  path[4] !== 'answer-options' &&
                  (group_field.id == input.id ||
                    group_field.tempId == input.id) &&
                  group_field.answer.set_id == parseInt(path[3])
                ) {
                  group_field.answer.uploading_images.pop()
                  group_field.answer.uploading_images.push({
                    error:
                      'Image upload failed: Stronger network connection required.',
                    show_error: true,
                    show_photo_loader: false,
                  })
                }
                if (
                  path[4] === 'answer-options' &&
                  group_field.answer.set_id == parseInt(path[3]) &&
                  group_field.type == 'nested_option'
                ) {
                  var nested_field =
                    group_field.answer_options[path[5]].nested_fields[path[7]]
                  if (nested_field) {
                    nested_field.answer.uploading_images.pop()
                    nested_field.answer.uploading_images.push({
                      error:
                        'Image upload failed: Stronger network connection required.',
                      show_error: true,
                      show_photo_loader: false,
                    })
                  }
                }
              })
            }
          } else if (
            path[2] === 'answer-options' &&
            field.type === 'nested_option'
          ) {
            if (field.answer_options) {
              var nested_field =
                field.answer_options[path[3]].nested_fields[path[5]]
              if (nested_field) {
                nested_field.answer.uploading_images.pop()
                nested_field.answer.uploading_images.push({
                  error:
                    'Image upload failed: Stronger network connection required.',
                  show_error: true,
                  show_photo_loader: false,
                })
              }
            }
          } else {
            if (field.id == input.id) {
              field.answer.uploading_images.pop()
              field.answer.uploading_images.push({
                error:
                  'Image upload failed: Stronger network connection required.',
                show_error: true,
                show_photo_loader: false,
              })
            }
          }
        })
      }

      $scope.addSignatureSketchDialog = function (fieldData, key) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content',
          scope: $scope,
          size: 'md',
          animation: true,
          bindToController: true,
          templateUrl: 'app/views/add_signature_sketch_dialog.html',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.saveEvent = function (imgData) {
              if (
                !key ||
                !$scope.signatureSketchKeys ||
                !fieldData ||
                !imgData
              ) {
                $scope.close()
                return false
              }

              if (key == $scope.signatureSketchKeys.signature) {
                $scope.addSignatureOrSketch(fieldData, imgData, 'Signatures')
              } else if (key == $scope.signatureSketchKeys.sketch) {
                $scope.addSignatureOrSketch(fieldData, imgData, 'Sketches')
              }

              $scope.close()
            }

            $scope.clearEvent = function () {
              // when the canvas is cleared
            }
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }

      $scope.addSignatureOrSketch = function (fieldData, imgData, typeOfField) {
        if (!fieldData || !imgData) return

        var fieldId = fieldData.id
          ? fieldData.id || null
          : fieldData.tempId || null

        if (
          imgData.type &&
          (imgData.type == 'image/png' || imgData.type == 'image/jpeg')
        ) {
          let onLoadDeleteId = random(1, 100000)
          let indexToSwitch = renderFiles(
            typeOfField.toLowerCase(),
            {
              state: 'uploadingFile',
              onLoadDeleteId: onLoadDeleteId,
            },
            fieldData.path,
            fieldId,
          )

          Api.uploadImage(
            imgData,
            {},
            function (resp) {
              Api[typeOfField].post(
                {
                  user: CurrentUser.getId(),
                  image: resp.id,
                },
                (resp) => {
                  renderFiles(
                    typeOfField.toLowerCase(),
                    resp,
                    fieldData.path,
                    fieldId,
                    indexToSwitch,
                  )
                },
              )
            },
            function (error) {
              let errorText
              if (error) {
                errorText = error
              } else {
                errorText =
                  'Image upload failed: Stronger network connection required.'
              }
              renderFiles(
                typeOfField.toLowerCase(),
                {
                  state: 'loadingFailed',
                  onLoadDeleteId: onLoadDeleteId,
                  error: errorText,
                },
                fieldData.path,
                fieldId,
                indexToSwitch,
              )
            },
          )
        } else {
          Notification.danger(
            'Invalid image file format. Please select a png or jpg file.',
          )
          setTimeout(function () {
            $scope.$apply()
          }, 0)
        }
      }

      $scope.removeSignature = function (signature, field) {
        if (signature) {
          for (var j = 0; j < field.answer.signatures.length; j++) {
            if (field.answer.signatures[j].id == signature.id) {
              if (signature.state !== 'loadingFailed') {
                $scope.setDeletedPhotos(
                  field.answer.signatures[j],
                  'signatures',
                )
              }
              field.answer.signatures.splice(j, 1)
            }
          }
        }
      }

      $scope.removeSketch = function (sketch, field) {
        if (sketch) {
          for (var j = 0; j < field.answer.sketches.length; j++) {
            if (field.answer.sketches[j].id == sketch.id) {
              if (sketch.state !== 'loadingFailed') {
                $scope.setDeletedPhotos(field.answer.sketches[j], 'sketches')
              }
              field.answer.sketches.splice(j, 1)
            }
          }
        }
      }

      $scope.setRadioFieldAnswer = function (radio_option, field) {
        field.answer.text = radio_option.name
      }

      $scope.showAlertDialog = function (title, message, text_alignment) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content',
          templateUrl: 'app/views/alert_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.title = title
            $scope.message = message
            $scope.textAlignment = text_alignment
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }

      $scope.showNote = function (message) {
        $scope.showAlertDialog('NOTE', message, 'left')
      }

      $scope.selectedNestedOption = {}
      $scope.hideLines = false

      $scope.setNestedOption = function (field) {
        field.answer.chosen_answer = field.answer.option.id
        field.answer.text = field.answer.option.name
        field.hideLines = field.answer.text
          ? (field.hideLines = true)
          : (field.hideLines = false)

        field.answer_options.forEach(function (answer_option) {
          answer_option.nested_fields.forEach(function (nested_field) {
            nested_field.answer.set_id = field.answer.set_id
          })
        })
      }

      $scope.getFormError = function (form) {
        $scope.submitted = true

        $timeout(() => {
          $scope.error = null
        }, 10000)

        for (let i = 0; i < form.fields.length; ++i) {
          const field = form.fields[i]
          if (
            field.type == 'image_reference' ||
            field.type == 'header' ||
            field.type == 'paragraph'
          ) {
            continue
          }
          if (
            field.type === 'files' &&
            field.required &&
            !field.answer.files.length > 0
          ) {
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please add a File to required fields',
              'center',
            )
            return 'Please add a Files to required fields'
          }
          if (field.type === 'date' && field.required && !field.answer.date) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (field.type === 'time' && field.required && !field.answer.time) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (
            field.type === 'signatures' &&
            field.required &&
            !field.answer.signatures.length > 0
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (
            field.type === 'sketches' &&
            field.required &&
            !field.answer.sketches.length > 0
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (
            field.type === 'photos' &&
            field.required &&
            !field.answer.photos.length > 0
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (
            field.type === 'options_multi' &&
            field.required &&
            !$scope.selectedItems[field.answer.set_id][field.id].length > 0
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (
            ![
              'options_multi',
              'date',
              'time',
              'signatures',
              'sketches',
              'photos',
              'files',
            ].includes(field.type) &&
            field.required &&
            !field.answer.text
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (
            ![
              'options_multi',
              'date',
              'time',
              'signatures',
              'sketches',
              'photos',
              'files',
            ].includes(field.type) &&
            field.required &&
            !field.answer.text
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'Please answer all required fields before saving.',
              'center',
            )
            return 'Please answer all required fields before saving.'
          }
          if (
            field.type === 'number' &&
            field.answer.text &&
            field.answer.text.length > 0 &&
            !field.answer.text.match(/^[0-9, .-]+$/)
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'You must enter a numerical value for number field.',
              'center',
            )
            return 'You must enter a numerical value for number field.'
          }
          if (
            field.type === 'currency' &&
            field.answer.text &&
            field.answer.text.length > 0 &&
            !field.answer.text.match(/^[0-9,.$]+$/)
          ) {
            $scope.disabled = false
            $scope.showAlertDialog(
              'ATTENTION!',
              'You must enter a numerical value for currency field.',
              'center',
            )
            return 'You must enter a numerical value for currency field.'
          }
        }
        return null
      }

      $scope.isMobileFormChanged = function (newForm) {
        var count = 0
        newForm.collapsedSections.others.map(function (other) {
          other.fields.map(function (field) {
            if (
              !!isAnswerValid(field.answer) === true &&
              field.type !== 'report_title'
            ) {
              count++
            }
            if (field.type === 'nested_option') {
              field.answer_options.map(function (answer_option) {
                if (answer_option.nested_fields) {
                  answer_option.nested_fields.map(function (nested_field) {
                    if (!!isAnswerValid(nested_field.answer) === true) {
                      count++
                    }
                  })
                }
              })
            }
            if (field.group) {
              if (field.type === 'nested_option') {
                field.answer_options.map(function (answer_option) {
                  if (answer_option.nested_fields) {
                    answer_option.nested_fields.map(function (nested_field) {
                      if (!!isAnswerValid(nested_field.answer) === true) {
                        count++
                      }
                    })
                  }
                })
              }
              field.group.fields.map(function (item) {
                if (!!isAnswerValid(item.answer) === true) {
                  count++
                }
                if (item.type === 'nested_option') {
                  item.answer_options.map(function (answer_option) {
                    if (answer_option.nested_fields) {
                      answer_option.nested_fields.map(function (nested_field) {
                        if (!!isAnswerValid(nested_field.answer) === true) {
                          count++
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        })
        newForm.collapsedSections.headers.map(function (header) {
          header.fields.map(function (field) {
            if (!!isAnswerValid(field.answer) === true) {
              count++
            }
            if (field.type === 'nested_option') {
              field.answer_options.map(function (answer_option) {
                if (answer_option.nested_fields) {
                  answer_option.nested_fields.map(function (nested_field) {
                    if (!!isAnswerValid(nested_field.answer) === true) {
                      count++
                    }
                  })
                }
              })
            }
            if (field.group) {
              if (field.type === 'nested_option') {
                field.answer_options.map(function (answer_option) {
                  if (answer_option.nested_fields) {
                    answer_option.nested_fields.map(function (nested_field) {
                      if (!!isAnswerValid(nested_field.answer) === true) {
                        count++
                      }
                    })
                  }
                })
              }
              field.group.fields.map(function (item) {
                if (!!isAnswerValid(item.answer) === true) {
                  count++
                }
                if (item.type === 'nested_option') {
                  item.answer_options.map(function (answer_option) {
                    if (answer_option.nested_fields) {
                      answer_option.nested_fields.map(function (nested_field) {
                        if (!!isAnswerValid(nested_field.answer) === true) {
                          count++
                        }
                      })
                    }
                  })
                }
              })
            }
          })
        })
        return !!count
      }

      $scope.$watch(
        'report.form',
        function (newValue) {
          if ($scope.report && $scope.report.form) {
            $scope.isNotEmpty =
              $scope.isMobileFormChanged(newValue) ||
              (newValue.fields[0].answer.text &&
                newValue.fields[0].answer.text !== $scope.defaultTitle)
          }
        },
        true,
      )

      $scope.$watch(
        'error',
        function (newErrorValue) {
          if (newErrorValue) {
            $scope.in_progress = false
          }
        },
        true,
      )

      function removePhotoRequest() {
        const promiseList = []

        if (
          Object.keys($scope.deletedPhotos).length !== 0 &&
          $scope.deletedPhotos.constructor === Object
        ) {
          for (let key in $scope.deletedPhotos) {
            $scope.deletedPhotos[key].forEach((data) => {
              const requestFn = getRequestFn(key)
              if (requestFn) {
                promiseList.push(requestFn(data.client_object_key))
              }
            })
          }
        }
        if (
          Object.keys($scope.deletedFiles).length !== 0 &&
          $scope.deletedFiles.constructor === Object
        ) {
          for (let key in $scope.deletedFiles) {
            $scope.deletedFiles[key].forEach((data) => {
              promiseList.push(Api.removeExternalFile(data.client_object_key))
            })
          }
        }
        $scope.deletedFiles = {}
        $scope.deletedPhotos = {}
        Promise.all(promiseList)
          .then(() => {
            $scope._save($scope.goBack)
          })
          .finally(() => {
            $scope.disabledBtn = false
          })
      }

      function getRequestFn(key) {
        switch (key) {
          case 'photos':
            return Api.removeExternalImage
          case 'signatures':
            return Api.removeExternalSignature
          case 'sketches':
            return Api.removeExternalSketch
          case 'files':
            return Api.removeExternalFile
        }
      }

      $scope.saveReport = function (save_as_draft) {
        $scope.in_progress = true
        $scope.error = null

        var defaultCodeBeforeReturn = function () {
          $scope.in_progress = false
          $scope.saveAsDraftOrSubmitModalInstance.close()
        }

        if (!$scope.isNotEmpty) {
          defaultCodeBeforeReturn()
          return
        }
        $scope.report.form.fields[0].answer.text = $scope.report.form.fields[0]
          .answer.text
          ? $scope.report.form.fields[0].answer.text
          : $scope.defaultTitle

        // When saving a Draft, required fields are optional. They only needs to be answered when the user hits submit
        if (!save_as_draft) {
          $scope.disabled = true
          $scope.report.form.collapsedSections.others.map(function (other) {
            if ($scope.error) return
            !$scope.error && ($scope.error = $scope.getFormError(other))

            other.fields.map(function (field) {
              if ($scope.error) return
              if (field.group) {
                !$scope.error &&
                  ($scope.error = $scope.getFormError(field.group))
              }
            })
          })

          if ($scope.error) {
            defaultCodeBeforeReturn()
            return
          }
          $scope.report.form.collapsedSections.headers.map(function (header) {
            if ($scope.error) return
            !$scope.error && ($scope.error = $scope.getFormError(header))

            header.fields.map(function (field) {
              if ($scope.error) return
              if (field.group) {
                !$scope.error &&
                  ($scope.error = $scope.getFormError(field.group))
              }
            })
          })
        }

        if (!$scope.error && $scope.report.id) {
          removePhotoRequest()
        }

        defaultCodeBeforeReturn()
      }

      function redirectToView() {
        $stateParams.key = $scope.external_contact.key
        $state.go(
          'assigned.external_form',
          {
            client_object_key: clientObjectKey,
            app_id: $stateParams.app_id,
            application_bundle_id: $stateParams.application_bundle_id,
            key: $scope.external_contact.key,
          },
          { reload: true },
        )

        $scope.isSubmitedReport = true
        $scope.in_progress = false
      }

      function getNestedFields(field) {
        field.answer_options.map(function (answer_option) {
          if (answer_option.nested_fields) {
            $scope.fieldsForRequests.push(answer_option.nested_fields)
          }
        })
      }

      function checkCollapsedSections(callback) {
        $scope.fieldsForRequests = []
        $scope.report.form.collapsedSections.headers.map(function (header) {
          $scope.fieldsForRequests.push(header.fields)
          header.fields.map(function (field) {
            if (field.type === 'nested_option') {
              getNestedFields(field)
            }
            if (field.group) {
              if (field.type === 'nested_option') {
                getNestedFields(field)
              }
              field.group.fields.map(function (item) {
                if (item.type === 'nested_option') {
                  getNestedFields(item)
                }
              })

              $scope.fieldsForRequests.push(field.group.fields)
            }
          })
        })

        $scope.report.form.collapsedSections.others.map(function (other) {
          $scope.fieldsForRequests.push(other.fields)
          other.fields.map(function (field) {
            if (field.type === 'nested_option') {
              getNestedFields(field)
            }
            if (field.group) {
              if (field.type === 'nested_option') {
                getNestedFields(field)
              }
              field.group.fields.map(function (item) {
                if (item.type === 'nested_option') {
                  getNestedFields(item)
                }
              })

              $scope.fieldsForRequests.push(field.group.fields)
            }
          })
        })
        $scope.fieldsForRequests = $scope.fieldsForRequests
          ?.flat(1)
          ?.filter((i) => i?.answer)

        $scope.fieldsForRequests.length &&
          $scope.saveFieldsRequests($scope.fieldsForRequests)
        callback && callback()
      }

      function createExternalContact(clientKey) {
        $scope.error = null
        Api.post(
          `external_reports/${clientKey}/external_contact`,
          $scope.external_contact,
          (resp) => {
            $scope.external_contact = resp.external_contact
            setTimeout(function () {
              checkCollapsedSections()
              $scope.$apply()
            }, 0)
          },
          (err) => {
            $scope.error = err.message
            $scope.in_progress = false
          },
        )
      }

      function updateExternalContact() {
        $scope.error = null
        Api.put(
          `external_reports/${$stateParams.client_object_key}/external_contact/`,
          {
            company_name: $scope.external_contact.company_name,
            email: $scope.external_contact.email,
            first_name: $scope.external_contact.first_name,
            id: $scope.external_contact.id,
            last_name: $scope.external_contact.last_name,
          },
          (resp) => {
            if (resp) {
              $scope.external_contact = resp.external_contact
              setTimeout(function () {
                if ($scope.report.is_draft) {
                  getExternalReportData()
                  $scope.deletedPhotos = {}
                  $scope.deletedFiles = {}
                  redirectToView()
                } else {
                  $state.go(
                    'share.reports',
                    {
                      key: clientObjectKey,
                    },
                    { reload: true },
                  )
                }
              }, 0)
            }
          },
          (err) => {
            $scope.error = err.message
            $scope.in_progress = false
          },
        )
      }

      $scope.saveAsDraftOrSubmitReportDialog = function (save_as_draft) {
        $scope.saveAsDraftOrSubmitModalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content',
          templateUrl: 'app/views/save_as_draft-or-submit-report_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.message = save_as_draft
              ? 'You are saving a draft of this report. Your email will receive a link to this draft page. Please confirm your email address: '
              : 'You are submitting a finalized report. You will no longer be able to make changes after you submit. Please confirm your email address:  '
            $scope.email = $scope.external_contact.email
            $scope.save = function () {
              $scope.disabled = true
              $scope.close()
              $scope.report.is_draft = save_as_draft
              $scope.saveReport(save_as_draft)
            }

            $scope.cancel = function () {
              $scope.close()
            }
          },
        })

        $scope.close = function () {
          $scope.saveAsDraftOrSubmitModalInstance.close()
        }
      }

      $scope._save = function () {
        $scope.error = null
        if (
          $scope.external_contact &&
          $scope.external_contact.key &&
          $scope.report.id &&
          $scope.report.fields.length > 0
        ) {
          checkCollapsedSections()
        } else {
          createExternalReport()
        }
      }

      function createExternalReport() {
        Api.post(
          `external_reports`,
          {
            application: $scope.appId,
            form: $scope.report.form.id,
            project: $scope.report.project.id,
            user: $scope.report.user.id,
            synchronised: true,
            is_external: true,
            is_draft: $scope.report.is_draft,
          },
          (resp) => {
            if (resp) {
              $scope.report.id = resp.id
              clientObjectKey = $stateParams.client_object_key =
                resp.client_object_key
              createExternalContact(clientObjectKey, $scope.report.id)
            }
          },
          (err) => {
            $scope.error = err.message
            $scope.in_progress = false
          },
        )
      }

      // create/update junction for multiple select
      $scope.createUpdateReportFieldToFieldOption = function (reportField) {
        $scope.selectedMultiAnswers.forEach(function (item) {
          if (
            reportField.field == item.field &&
            reportField.set_id == item.set_id
          ) {
            item.reportfield = reportField.id

            var create = {
              reportfield: item.reportfield,
              fieldoption: item.fieldoption,
            }

            var update = {
              deleted: item.deleted,
            }

            Api.createReportFieldToFieldOption(
              create,
              function (resp) {
                Api.updateReportFieldToFieldOption(
                  resp.id,
                  update,
                  function (resp2) {},
                  function () {},
                )
              },
              function () {
                getExternalReportData()
              },
            )
          }
        })
      }

      function finalizeSaveFields() {
        if (
          $scope.external_contact &&
          $scope.external_contact.key &&
          $scope.report.id
        ) {
          updateExternalContact()
        }
      }

      $scope.saveFieldsRequests = function (fields) {
        $scope.external_contact = angular.copy($scope.external_contact)
        let answers_to_update = []
        async.each(
          fields,
          function (field, callback) {
            if (field.answer) {
              $scope.patchItems = []
              $scope.itemType = ''
              if (field.type === 'date' || field.type === 'time') {
                delete field.answer.text
              }
              if (field.type === 'signatures' && field.answer.signatures) {
                delete field.answer.text
                $scope.patchItems = field.answer.signatures
                $scope.itemType = 'signatures'
              }
              if (field.type === 'sketches' && field.answer.sketches) {
                delete field.answer.text
                $scope.patchItems = field.answer.sketches
                $scope.itemType = 'sketches'
              }
              async.each(
                $scope.patchItems,
                function (item, cb2) {
                  if ($scope.itemType === 'signatures') {
                    delete field.answer.text
                    Api.Signatures.patch(
                      {
                        id: item.id,
                        printed_name: item.printed_name,
                      },
                      (resp) => {
                        cb2()
                      },
                      (err) => {
                        $scope.error = err.message
                        $scope.in_progress = false
                        callback(null)
                      },
                    )
                  } else if ($scope.itemType === 'sketches') {
                    delete field.answer.text
                    Api.Sketches.patch(
                      {
                        id: item.id,
                        printed_name: item.printed_name,
                      },
                      (resp) => {
                        cb2()
                      },
                      (err) => {
                        $scope.error = err.message
                        $scope.in_progress = false
                        callback(null)
                      },
                    )
                  }
                },
                function (err) {
                  var answer = _extends({}, field.answer, {
                    signatures: !field.answer.signatures
                      ? []
                      : field.answer.signatures.map(function (s) {
                          delete field.answer.text
                          return s.id
                        }),
                    photos: !field.answer.photos
                      ? []
                      : field.answer.photos.map(function (p) {
                          delete field.answer.text
                          return p.id
                        }),
                    files: !field.answer.files
                      ? []
                      : field.answer.files.map(function (f) {
                          delete field.answer.text
                          return f.id
                        }),
                    multi_answers: !field.answer.multi_answers
                      ? []
                      : field.answer.multi_answers.map(function (p) {
                          delete field.answer.text
                          if (p.id) return p.id
                          else return p
                        }),
                    sketches: !field.answer.sketches
                      ? []
                      : field.answer.sketches.map(function (p) {
                          delete field.answer.text
                          return p.id
                        }),
                    date: field.answer.date
                      ? field.answer.date.getMonth() +
                        1 +
                        '/' +
                        field.answer.date.getDate() +
                        '/' +
                        field.answer.date.getFullYear()
                      : field.answer.date,
                    time: field.answer.time
                      ? field.answer.time.getHours() +
                        ':' +
                        field.answer.time.getMinutes() +
                        ':00'
                      : field.answer.time,
                  })
                  if (field.answer.id) {
                    answers_to_update.push(answer)
                    callback(null)
                  } else {
                    // @TODO: investigate why field and report ids should be overwritten here
                    Api.ReportFields.post(
                      {
                        ...answer,
                        field: field.id,
                        report: $scope.report.id,
                      },
                      function (resp) {
                        $scope.createUpdateReportFieldToFieldOption(resp)
                        callback(null)
                      },
                      function (err) {
                        $scope.error = err.message
                        $scope.in_progress = false
                        callback(null)
                      },
                    )
                  }
                },
              )
            }
          },
          function (err) {
            if (err) {
              $scope.error = err
            } else {
              Api.patch(
                `external_reports/${$stateParams.client_object_key}`,
                {
                  fields: answers_to_update,
                  synchronised: true,
                  is_external: true,
                  is_draft: $scope.report.is_draft,
                },
                (resp) => {
                  if (resp) {
                    clientObjectKey = $stateParams.client_object_key =
                      resp.client_object_key
                    $scope.createUpdateReportFieldToFieldOption(resp)
                    finalizeSaveFields()
                  }
                },
                (err) => {
                  $scope.error = err.message
                  $scope.in_progress = false
                },
              )
            }
          },
        )
      }

      var compareFieldBySetId = function (qOne, qTwo) {
        if (qOne.answer.set_id < qTwo.answer.set_id) {
          return -1
        }
        if (qOne.answer.set_id > qTwo.answer.set_id) {
          return 1
        }
        return 0
      }

      $scope.synchronised

      function getExternalReportData() {
        $scope.in_progress = false
        Api.getWithoutDeletedParam(
          `external_reports/${clientObjectKey}`,
          { from_official_client: 1 },
          (resp) => {
            if (resp.message) {
              $scope.error = resp.message
              $scope.report = null
              setTimeout(function () {
                $scope.$apply()
              }, 0)
              return
            }
            VariableThemeColor.mainColorSet(
              resp.project.client.general_settings.colors.secondary_color,
              true,
            )

            $scope.appProjectDisplay = pluralize.singular(
              resp.application.projects_display,
            )

            $scope.synchronised = resp.synchronised
            $scope.appId = resp.application.id
            CurrentCompanyLogo.setLogo(resp.logo)

            if (resp.external_contacts && resp.external_contacts.length > 0) {
              resp.external_contacts.map(function (external_user) {
                if (external_user.external_contact.key == $stateParams.key) {
                  $scope.isSubmitedReport = true
                  $scope.external_contact = external_user.external_contact
                }
              })
            }

            var group_sets = {},
              pointer_group_sets
            $scope.origin_group = {}
            var origin_group_id, origin_pointer_group

            var answers = {}
            if (!$stateParams.key) {
              resp.fields = []
              resp.external_contacts = []
              resp.externals = []
            }

            for (var i = 0; i < resp.fields.length; i++) {
              answers[resp.fields[i].field] =
                answers[resp.fields[i].field] || {}
              answers[resp.fields[i].field][resp.fields[i].set_id] =
                resp.fields[i]
            }

            $scope.report = _extends({}, resp, {
              form: _extends({}, resp.form, {
                fields: resp.form.fields.map(function (field) {
                  field.isCollapsed = false
                  field.hideLines = false
                  field.limitTo = 0
                  field.answer = {
                    photos: [],
                    files: [],
                    signatures: [],
                    sketches: [],
                    multi_answers: [],
                    multi_answers_junction: [],
                    set_id: 0,
                    field: field.id,
                    text: '',
                    show_photo_loader: false,
                    show_error: false,
                    uploading_images: [],
                    chosen_answer: null,
                    option: {},
                    time: null,
                    date: null,
                  }
                  if (field.type === 'report_title') {
                    field.required = false
                  }
                  if (field.type === 'options_multi') {
                    $scope.selectedItems[field.answer.set_id] =
                      $scope.selectedItems[field.answer.set_id] || {}
                    $scope.selectedItems[field.answer.set_id][field.id] = []
                  }

                  if (field.group) {
                    origin_group_id = field.group
                    if (!$scope.origin_group[origin_group_id]) {
                      $scope.origin_group[origin_group_id] = {
                        group: {
                          title: field.group_info.name,
                          fields: [],
                        },
                      }
                      origin_pointer_group = $scope.origin_group[
                        origin_group_id
                      ].group.fields = []
                    }
                    origin_pointer_group.push(field)
                  }

                  field.answer.dateOpen = false

                  if (field.type === 'nested_option') {
                    field.answer_options.map(function (answer_option) {
                      answer_option.isAnswer = false
                      answer_option.isSelected = false

                      if (answer_option.field == field.answer.field) {
                        answer_option.isAnswer = true
                      }

                      if (answer_option.id == field.answer.chosen_answer) {
                        field.answer.option = answer_option
                      }

                      answer_option.nested_fields.map(function (option) {
                        if (option.type === 'options_multi') {
                          $scope.selectedItems[field.answer.set_id] =
                            $scope.selectedItems[field.answer.set_id] || {}
                          $scope.selectedItems[field.answer.set_id][option.id] =
                            []
                        }
                        option.isCollapsed = false
                        option.limitTo = 0
                        option.answer = {
                          photos: [],
                          files: [],
                          signatures: [],
                          sketches: [],
                          multi_answers: [],
                          multi_answers_junction: [],
                          set_id: 0,
                          field: option.id,
                          text: '',
                          show_photo_loader: false,
                          show_error: false,
                          chosen_answer: null,
                          option: {},
                          uploading_images: [],
                          time: null,
                          date: null,
                        }
                        option.answer.dateOpen = false
                      })
                    })
                  }

                  var set_id

                  if (field.group) {
                    group_id = field.group
                    if (!group_sets[group_id]) {
                      group_sets[group_id] = {
                        fields: [],
                      }
                      pointer_group_sets = group_sets[group_id].fields = []
                    }
                  }

                  if (field.group && field.type === 'nested_option') {
                    var answersBySetId = answers[field.id]

                    if (!answersBySetId) {
                      return field
                    }

                    for (var setId in answersBySetId) {
                      var answer = answersBySetId[setId]

                      var copy = angular.copy(field)

                      copy.answer_options.map(function (answer_option) {
                        if (!answer_option.nested_fields.length) {
                          answer_option.isAnswer = true
                        }

                        if (answer_option.id == answer.chosen_answer) {
                          copy.answer.chosen_answer = answer.chosen_answer
                          copy.answer.option = answer_option
                          answer.option = answer_option
                        }

                        answer_option.nested_fields.map(function (option) {
                          resp.fields.forEach(function (nested_answer) {
                            if (
                              nested_answer.field === option.id &&
                              nested_answer.set_id === parseInt(setId)
                            ) {
                              option.answer = nested_answer
                              isAnswerValid(nested_answer) &&
                                (answer_option.isAnswer = true)
                              copy.hideLines = true

                              if (
                                option.type === 'time' &&
                                option.answer.time
                              ) {
                                var currentDate = new Date()
                                var splitTime = option.answer.time.split(':')
                                currentDate.setHours(splitTime[0])
                                currentDate.setMinutes(splitTime[1])
                                option.answer.time = currentDate
                              }
                              if (
                                option.type === 'date' &&
                                option.answer.date
                              ) {
                                option.answer.date = new Date(
                                  option.answer.date,
                                )
                              }
                              if (
                                option.type === 'address' &&
                                option.answer.map_snapshot
                              ) {
                                option.answer.map_snapshot_info =
                                  option.answer.map_snapshot
                                option.answer.map_snapshot =
                                  option.answer.map_snapshot.id
                              }
                              if (
                                option.type === 'options_multi' &&
                                option.answer.multi_answers.length > 0
                              ) {
                                $scope.selectedItems[parseInt(setId)] =
                                  $scope.selectedItems[parseInt(setId)] || {}
                                $scope.selectedItems[parseInt(setId)][
                                  option.id
                                ] = []
                                option.answer_options.forEach(function (item) {
                                  item.isSelected = false
                                  option.answer.multi_answers_junction.forEach(
                                    function (selectedItem) {
                                      if (
                                        item.name ===
                                          selectedItem.fieldoption_obj.name &&
                                        !selectedItem.deleted
                                      ) {
                                        item.isSelected = true
                                        $scope.selectedItems[parseInt(setId)][
                                          option.id
                                        ].push(item)
                                      }
                                    },
                                  )
                                })
                              }
                            }
                          })
                        })
                      })

                      pointer_group_sets.push(copy)
                      pointer_group_sets[pointer_group_sets.length - 1].answer =
                        answer
                      pointer_group_sets[pointer_group_sets.length - 1].set_id =
                        answer.set_id

                      pointer_group_sets =
                        pointer_group_sets.sort(compareFieldBySetId)
                    }
                  }

                  resp.fields.forEach(function (answer) {
                    answer.uploading_images = []

                    if (field.group) {
                      if (
                        answer.field === field.id &&
                        field.type !== 'nested_option'
                      ) {
                        field.hideLines = true
                        var field_group = angular.copy(field)
                        pointer_group_sets.push(field_group)
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer = answer
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].set_id = answer.set_id
                        pointer_group_sets[
                          pointer_group_sets.length - 1
                        ].answer.set_id = answer.set_id
                        if (
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .type === 'time' &&
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .answer.time
                        ) {
                          var currentDate = new Date()
                          var splitTime =
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.time.split(':')
                          currentDate.setHours(splitTime[0])
                          currentDate.setMinutes(splitTime[1])
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].answer.time = currentDate
                        }
                        if (
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .type === 'date' &&
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .answer.date
                        ) {
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].answer.date = new Date(
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.date,
                          )
                        }
                        if (
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .type === 'address' &&
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .answer.map_snapshot
                        ) {
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].answer.map_snapshot_info =
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.map_snapshot
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].answer.map_snapshot =
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.map_snapshot.id
                        }
                        if (
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .type === 'options_multi' &&
                          pointer_group_sets[pointer_group_sets.length - 1]
                            .answer.multi_answers.length > 0
                        ) {
                          $scope.selectedItems[
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.set_id
                          ] =
                            $scope.selectedItems[
                              pointer_group_sets[pointer_group_sets.length - 1]
                                .answer.set_id
                            ] || {}
                          $scope.selectedItems[
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.set_id
                          ][
                            pointer_group_sets[pointer_group_sets.length - 1].id
                          ] = []
                          pointer_group_sets[
                            pointer_group_sets.length - 1
                          ].answer_options.forEach(function (item) {
                            item.isSelected = false
                            pointer_group_sets[
                              pointer_group_sets.length - 1
                            ].answer.multi_answers_junction.forEach(function (
                              selectedItem,
                            ) {
                              if (
                                item.name ===
                                  selectedItem.fieldoption_obj.name &&
                                !selectedItem.deleted
                              ) {
                                item.isSelected = true
                                $scope.selectedItems[
                                  pointer_group_sets[
                                    pointer_group_sets.length - 1
                                  ].answer.set_id
                                ][
                                  pointer_group_sets[
                                    pointer_group_sets.length - 1
                                  ].id
                                ].push(item)
                              }
                            })
                          })
                        }
                        set_id = answer.set_id
                        pointer_group_sets =
                          pointer_group_sets.sort(compareFieldBySetId)
                      }

                      return field
                    }

                    if (field.type !== 'nested_option' && !field.group) {
                      if (answer.field === field.id) {
                        field.answer = answer
                        field.hideLines = true
                        if (field.type === 'time' && field.answer.time) {
                          var currentDate = new Date()
                          var splitTime = field.answer.time.split(':')
                          currentDate.setHours(splitTime[0])
                          currentDate.setMinutes(splitTime[1])
                          field.answer.time = currentDate
                        }
                        if (field.type === 'date' && field.answer.date) {
                          field.answer.date = new Date(field.answer.date)
                        }
                        if (
                          field.type === 'address' &&
                          field.answer.map_snapshot
                        ) {
                          field.answer.map_snapshot_info =
                            field.answer.map_snapshot
                          field.answer.map_snapshot =
                            field.answer.map_snapshot.id
                        }
                        if (
                          field.type === 'options_multi' &&
                          field.answer.multi_answers.length > 0
                        ) {
                          $scope.selectedItems[field.answer.set_id] =
                            $scope.selectedItems[field.answer.set_id] || {}
                          $scope.selectedItems[field.answer.set_id][field.id] =
                            []
                          field.answer_options.forEach(function (item) {
                            item.isSelected = false
                            field.answer.multi_answers_junction.forEach(
                              function (selectedItem) {
                                if (
                                  item.name ===
                                    selectedItem.fieldoption_obj.name &&
                                  !selectedItem.deleted
                                ) {
                                  item.isSelected = true
                                  $scope.selectedItems[field.answer.set_id][
                                    field.id
                                  ].push(item)
                                }
                              },
                            )
                          })
                        }
                      }

                      return field
                    }

                    if (field.type === 'nested_option' && !field.group) {
                      field.answer_options.map(function (answer_option) {
                        if (!answer_option.nested_fields.length) {
                          resp.fields.forEach(function (
                            answer_for_nested_option,
                          ) {
                            if (answer_for_nested_option.field === field.id) {
                              answer_option.isAnswer = true
                              field.answer = answer_for_nested_option
                            }
                          })
                        }

                        if (answer_option.id == field.answer.chosen_answer) {
                          field.answer.option = answer_option
                        }

                        answer_option.nested_fields.map(function (option) {
                          resp.fields.forEach(function (nested_answer) {
                            if (nested_answer.field === field.id) {
                              field.answer = nested_answer
                            }
                            if (nested_answer.field === option.id) {
                              option.answer = nested_answer
                              field.hideLines = true
                              isAnswerValid(nested_answer) &&
                                (answer_option.isAnswer = true)
                              if (
                                option.type === 'time' &&
                                option.answer.time
                              ) {
                                if (!option.answer.currentDate) {
                                  var currentDate = new Date()
                                  var splitTime = option.answer.time.split(':')
                                  currentDate.setHours(splitTime[0])
                                  currentDate.setMinutes(splitTime[1])
                                  option.answer.time = currentDate
                                  option.answer.currentDate = true
                                }
                              }
                              if (
                                option.type === 'date' &&
                                option.answer.date
                              ) {
                                option.answer.date = new Date(
                                  option.answer.date,
                                )
                              }
                              if (
                                option.type === 'address' &&
                                option.answer.map_snapshot
                              ) {
                                option.answer.map_snapshot_info =
                                  option.answer.map_snapshot
                                option.answer.map_snapshot =
                                  option.answer.map_snapshot.id
                              }
                              if (
                                option.type === 'options_multi' &&
                                option.answer.multi_answers.length > 0
                              ) {
                                $scope.selectedItems[field.answer.set_id][
                                  option.id
                                ] = []
                                option.answer_options.forEach(function (item) {
                                  item.isSelected = false
                                  option.answer.multi_answers_junction.forEach(
                                    function (selectedItem) {
                                      if (
                                        item.name ===
                                          selectedItem.fieldoption_obj.name &&
                                        !selectedItem.deleted
                                      ) {
                                        item.isSelected = true
                                        $scope.selectedItems[
                                          field.answer.set_id
                                        ][option.id].push(item)
                                      }
                                    },
                                  )
                                })
                              }
                            }
                          })
                        })
                      })
                    }
                  })

                  return field
                }),
              }),
            })

            $scope.report.is_draft = resp.is_draft
            var group_with_all_sets = {}
            for (var k in group_sets) {
              var current_set_id = 0
              if (!group_with_all_sets[k]) {
                group_with_all_sets[k] = {
                  fields: [],
                }
                pointer_group_sets = group_with_all_sets[k].fields =
                  angular.copy($scope.origin_group[k].group.fields)
              }

              if (!group_sets[k].fields.length > 0) {
                Array.prototype.push.apply(
                  group_with_all_sets[k].fields,
                  group_sets[k].fields,
                )
              } else {
                group_sets[k].fields.map(function (field) {
                  if (current_set_id !== field.answer.set_id) {
                    var copied_origin_group = angular.copy(
                      $scope.origin_group[k].group.fields,
                    )
                    copied_origin_group.map(function (copy) {
                      copy.set_id = current_set_id + 1
                      copy.answer.set_id = current_set_id + 1
                    })
                    Array.prototype.push.apply(
                      group_with_all_sets[k].fields,
                      copied_origin_group,
                    )
                  }
                  current_set_id = field.answer.set_id
                })
              }
            }

            $scope.report.form.collapsedSections = {
              headers: [],
              others: [],
            }
            $scope.report.form.collapsedSections.others[0] = {}
            $scope.report.form.collapsedSections.others[0].fields = []

            var pointer =
              ($scope.report.form.collapsedSections.others[0].fields = [])
            var path = ''

            var group_obj = {}
            var pointer_group
            var group_id = null
            for (var i = 0; i < $scope.report.form.fields.length; i++) {
              var field = $scope.report.form.fields[i]
              if (
                $scope.report.form.fields[i] &&
                $scope.report.form.fields[i].type &&
                $scope.report.form.fields[i].type == 'header'
              ) {
                $scope.report.form.collapsedSections.headers.push(
                  $scope.report.form.fields[i],
                )
                var index =
                  $scope.report.form.collapsedSections.headers.length - 1
                pointer = $scope.report.form.collapsedSections.headers[
                  index
                ].fields = []
                path = 'headers_' + index
                continue
              }

              if (field.group) {
                group_id = field.group
                if (!group_obj[group_id]) {
                  pointer.push(field)
                  group_obj[group_id] = {
                    group: {
                      title: field.group_info.name,
                      fields: [],
                    },
                  }
                  pointer_group = group_obj[group_id].group.fields = []
                }
                if (group_sets[group_id]) {
                  group_with_all_sets[group_id].fields.map(function (item) {
                    for (
                      var k = 0;
                      k < group_sets[group_id].fields.length;
                      k++
                    ) {
                      if (
                        item.answer.field ===
                          group_sets[group_id].fields[k].id &&
                        item.answer.set_id ===
                          group_sets[group_id].fields[k].answer.set_id
                      ) {
                        item.answer = group_sets[group_id].fields[k].answer
                        item.hideLines =
                          group_sets[group_id].fields[k].hideLines
                        item.answer_options.length > 0 &&
                          (item.answer_options =
                            group_sets[group_id].fields[k].answer_options)
                      }
                    }
                  })
                  group_obj[group_id] = {
                    group: {
                      title: field.group_info.name,
                      fields: group_with_all_sets[group_id].fields,
                    },
                  }
                } else {
                  pointer_group.push(field)
                }
                group_obj[group_id].group.fields.map(function (field) {
                  field.path = path
                    ? path + '_group_' + field.answer.set_id
                    : 'others_' + i + '_group_' + field.answer.set_id
                  if (field.type === 'nested_option') {
                    field.answer_options.map(function (answer_option, i) {
                      answer_option.path = field.path + '_answer-options_' + i
                      answer_option.nested_fields.map(function (
                        nested_field,
                        i,
                      ) {
                        nested_field.path =
                          answer_option.path + '_nested-fields_' + i
                      })
                    })
                  }
                })
                pointer[pointer.length - 1] = group_obj[group_id]
              } else {
                pointer.push(field)
              }

              pointer[pointer.length - 1].path = path || 'others_' + i

              if (field.type === 'nested_option' && !field.group) {
                field.answer_options.map(function (answer_option, i) {
                  var group_str = answer_option.group
                    ? '_group_' + answer_option.answer.set_id
                    : ''
                  answer_option.path =
                    pointer[pointer.length - 1].path +
                    '_answer-options_' +
                    i +
                    group_str
                  answer_option.nested_fields.map(function (nested_field, i) {
                    nested_field.path =
                      answer_option.path + '_nested-fields_' + i + group_str
                  })
                })
              }

              const dateObj = new Date()
              const month = dateObj.getUTCMonth() + 1 //months from 1-12
              const day = dateObj.getUTCDate()
              const year = dateObj.getUTCFullYear()

              $scope.defaultTitle =
                $scope.report.form.name + ' - ' + month + '/' + day + '/' + year
              $scope.report.form.fields[0].answer.text = $scope.report.form
                .fields[0].answer.text
                ? $scope.report.form.fields[0].answer.text
                : $scope.defaultTitle
            }
          },
        )
      }

      function formatDate(date) {
        if (!date) return null
        return (
          date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear()
        )
      }

      function isAnswerValid(answer) {
        if (!answer) return
        return (
          answer.text ||
          answer.date ||
          answer.time ||
          answer.signatures.length > 0 ||
          answer.sketches.length > 0 ||
          answer.photos.length > 0 ||
          answer.files.length > 0 ||
          answer.multi_answers.length > 0
        )
      }

      $scope.copyGroupFields = function (field) {
        var group = field.group.fields
        var basic_group = []
        var field_set_id = group[group.length - 1].answer.set_id
        group.map(function (item) {
          if (item.answer.set_id === field_set_id) {
            return basic_group.push(item)
          } else {
            return false
          }
        })
        var copied_group = angular.copy(basic_group)
        var last_order = group[group.length - 1].order
        var last_set_id = field_set_id + 1

        copied_group.forEach(function (group_item) {
          if (group_item.type === 'nested_option') {
            clearCopiedField(group_item, '', true)
            group_item.hideLines = false
            group_item.answer_options.map(function (answer_option) {
              var answer_option_path = answer_option.path.split('_')
              answer_option_path[3] = last_set_id
              // answer_option_path = answer_option_path.join('_');
              // clearCopiedField(answer_option, answer_option_path, true);
              if (answer_option.nested_fields) {
                answer_option.nested_fields.map(function (nested_field) {
                  var nested_field_path = nested_field.path.split('_')
                  nested_field_path[3] = last_set_id
                  nested_field_path = nested_field_path.join('_')
                  nested_field.isCollapsed = false
                  nested_field.limitTo = 0
                  nested_field.answer = {
                    photos: [],
                    files: [],
                    signatures: [],
                    sketches: [],
                    field: nested_field.id,
                    multi_answers: [],
                    multi_answers_junction: [],
                    set_id: last_set_id,
                    show_photo_loader: false,
                    show_error: false,
                    chosen_answer: null,
                    option: {},
                    report: $scope.report.id,
                    uploading_images: [],
                  }
                  nested_field.answer.dateOpen = false
                  clearCopiedField(nested_field, nested_field_path, true)
                })
              }
            })
          } else {
            if (group.length === 1) {
              var field_path = group[0].path.split('_')
            } else {
              var field_path = group[group.length - 2].path.split('_')
            }
            field_path[field_path.length - 1] = last_set_id
            field_path = field_path.join('_')
            group_item.type !== 'nested_option' &&
              clearCopiedField(group_item, field_path, false)
          }
        })

        function clearCopiedField(copied_field, path, isNested) {
          copied_field.answer.text = ''
          copied_field.answer.photos = []
          copied_field.answer.files = []
          copied_field.answer.signatures = []
          copied_field.answer.sketches = []
          copied_field.answer.multi_answers = []
          copied_field.answer.multi_answers_junction = []
          copied_field.answer.uploading_images = []
          copied_field.answer.date_created = null
          copied_field.answer.date_updated = null
          copied_field.answer.date = null
          copied_field.answer.chosen_answer = null
          copied_field.answer.option = {}
          copied_field.answer.time = null
          copied_field.path = path
          last_order += 1
          copied_field.answer.set_id = last_set_id
          copied_field.answer.set_id = last_set_id
          copied_field.set_id = last_set_id

          copied_field.answer.id = null
          if (isNested === false) {
            copied_field.answer.id = null
          }
          if (copied_field.type === 'options_multi') {
            $scope.selectedItems[copied_field.answer.set_id] =
              $scope.selectedItems[copied_field.answer.set_id] || {}
            $scope.selectedItems[copied_field.answer.set_id][copied_field.id] =
              []
            copied_field.answer_options.map(function (item) {
              item.isSelected = false
            })
          }
          if (copied_field.type === 'address') {
            copied_field.answer.map_snapshot_info = {}
            copied_field.answer.map_snapshot = null
          }
        }

        Array.prototype.push.apply(group, copied_group)
      }

      getExternalReportData()
    },
  )
