import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormReportExportListCtrl',
    function (
      $scope,
      $state,
      $q,
      Api,
      ngDialog,
      app,
      $stateParams,
      FileSaver,
      $compile,
      $window,
      $uibModal,
      $log,
    ) {
      $log.debug('FormReportExportListCtrl')

      $scope.reports = []

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : '-date_created'
      $scope.startDate = $stateParams.startDate
      $scope.endDate = $stateParams.endDate
      $scope.formId = $stateParams.formId
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.disableDownloadBtn = true
      $scope.appMobileFormDisplay = pluralize.singular(
        app.app_mobile_form_display,
      )
      $scope.appProjectDisplay = pluralize.singular(app.projects_display)

      $scope.tableHeaders = [
        { key: 'selected', display: '' },
        {
          key: 'form__name',
          display: $scope.appMobileFormDisplay + ' Name',
          sortable: true,
        },
        { key: 'user__first_name', display: 'Full Name', sortable: true },
        { key: 'company_name', display: 'Company', sortable: true },
        {
          key: 'project__name',
          display: $scope.appProjectDisplay + ' Name',
          sortable: true,
        },
        { key: 'date_created', display: 'Date Created', sortable: true },
        { key: 'view', display: 'View', centered: true },
      ]

      $scope.changePage = function () {
        $state.transitionTo(
          'app.forms.reports_export_list',
          {
            app: app.id,
            formId: $scope.selectedForm,
            startDate: $scope.startDate,
            endDate: $scope.endDate,
            order: $scope.order,
            reverse: $scope.reverse,
            page: $scope.pageNumber,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          { notify: false },
        )
        Api.put(
          'reports/select/?form=' + $scope.formId + reportQuery(),
          { selected: $scope.selected },
          function (resp) {
            $scope.selected = []
            $scope.reports = resp.results
            $scope.totalItems = resp.count
            isSelectedItem()
          },
        )
      }

      function isSelectedItem() {
        $scope.selected = []
        $scope.allReportsToSelect = JSON.parse(
          $window.localStorage.getItem('allReportsToSelect'),
        )

        if ($scope.searchExecuted) {
          $scope.reports.map(function (report) {
            for (const property in $scope.allReportsToSelect) {
              if (property == report.id) {
                $scope.selected.push({
                  selected: $scope.allReportsToSelect[property].selected,
                  id: report.id,
                })
              }
            }
          })
        } else {
          for (const property in $scope.allReportsToSelect) {
            if ($scope.allReportsToSelect[property].selected) {
              $scope.selected.push({
                selected: $scope.allReportsToSelect[property].selected,
                id: $scope.allReportsToSelect[property].id,
              })
            }
          }
        }

        $window.localStorage.setItem(
          'allReportsToSelect',
          JSON.stringify($scope.allReportsToSelect),
        )
        return $scope.selected.length > 0
      }

      $scope.selectAll = function () {
        $scope.selected = []
        $scope.toogleAllReports = !$scope.toogleAllReports
        $scope.allReportsToSelect = JSON.parse(
          $window.localStorage.getItem('allReportsToSelect'),
        )
        setTimeout(function () {
          if ($scope.searchExecuted) {
            $scope.reports.map(function (report) {
              $scope.selected.push({
                selected: $scope.toogleAllReports,
                id: report.id,
              })
              for (const property in $scope.allReportsToSelect) {
                if (property == report.id) {
                  $scope.allReportsToSelect[property].selected =
                    $scope.toogleAllReports
                }
              }
            })
          } else {
            for (const property in $scope.allReportsToSelect) {
              $scope.selected.push({
                selected: $scope.toogleAllReports,
                id: $scope.allReportsToSelect[property].id,
              })
              $scope.allReportsToSelect[property].selected =
                $scope.toogleAllReports
            }
          }

          $scope.disableDownloadBtn = !$scope.selected.some(function (item) {
            return item.selected == true
          })

          $window.localStorage.setItem(
            'allReportsToSelect',
            JSON.stringify($scope.allReportsToSelect),
          )
          $scope.$apply()
        }, 0)
      }

      $scope.toogleAllReports = false

      $scope.selectAllReports = function () {
        var interval = setInterval(function () {
          if ($('.table-responsive')) {
            var firstTH = $(
              '.table-responsive > .table > thead  th.disabled',
            )[0]
            firstTH.classList.add('outline-none')
            firstTH.classList.remove('disabled')

            firstTH.innerHTML =
              "<label class='control control-checkbox control-checkbox-selectAll'><input id='selectAll' type='checkbox'/><div class='control_indicator control_indicator-selectAll'></div></label>"
            document
              .querySelector('.control-checkbox-selectAll')
              .addEventListener('mousedown', function () {
                $scope.selectAll($scope.toogleAllReports)
              })
            clearInterval(interval)
          }
        }, 100)
      }

      $scope.pageSize = 20
      $scope.totalItems = $scope.pageNumber * $scope.pageSize

      function reportQuery() {
        var query = {
          application: app.id,
          start_date: $scope.startDate,
          end_date: $scope.endDate,
          table: true,
          form: $scope.formId,
          page: $scope.pageNumber,
          tz_offset: new Date().getTimezoneOffset(),
          search: $scope.searchExecuted ? $scope.search : null,
          deleted: 'false',
        }

        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order

        var query_string = ''

        for (var key in query) {
          if (query[key]) query_string += '&' + key + '=' + query[key]
        }
        return query_string
      }

      $scope.getReports = function () {
        $scope.disableDownloadBtn = true
        Api.put(
          'reports/select/?form=' + $scope.formId + reportQuery(),
          {},
          function (resp) {
            if (resp) {
              $scope.disableDownloadBtn = false
              $scope.reports = resp.results
              $scope.allReportsToSelect = {}
              resp.selected.map(function (item) {
                return ($scope.allReportsToSelect[item.id] = {
                  selected: item.selected,
                  id: item.id,
                })
              })
              $window.localStorage.setItem(
                'allReportsToSelect',
                JSON.stringify($scope.allReportsToSelect),
              )
              $scope.totalItems = resp.count
              $scope.selectAllReports()
            }
          },
        )
      }

      $scope.sendReportIdToExport = function (checked) {
        $scope.allReportsToSelect = JSON.parse(
          $window.localStorage.getItem('allReportsToSelect'),
        )
        $scope.allReportsToSelect[checked.id].selected = checked.selected
        $window.localStorage.setItem(
          'allReportsToSelect',
          JSON.stringify($scope.allReportsToSelect),
        )

        $scope.disableDownloadBtn = true
        if (isSelectedItem()) {
          $scope.toogleAllReports = false
          $('#selectAll').prop('checked', false)
        }

        Api.put(
          'reports/select/?form=' + $scope.formId + reportQuery(),
          { selected: $scope.selected },
          function (resp) {
            if (resp) {
              $scope.reports = resp.results
              $scope.totalItems = resp.count
              $scope.disableDownloadBtn = false
            }
          },
        )
      }

      $scope.getReports()

      function clickEvent(element) {
        var evt = element.ownerDocument.createEvent('MouseEvents')
        evt.initMouseEvent(
          'click',
          true,
          true,
          element.ownerDocument.defaultView,
          1,
          0,
          0,
          0,
          0,
          false,
          false,
          false,
          false,
          0,
          null,
        )
        element.dispatchEvent(evt)
      }

      $scope.downloadData = function () {
        $scope.showAlertDialog(
          '',
          'Your Reports download is in process. Please do not refresh or exit the app until your download has completed.',
          'center',
        )
        $scope.disableDownloadBtn = true
        $scope.exportData = ''
        if ($scope.toogleAllReports) {
          Api.ExportAllReports.get(reportQuery(), function (resp) {
            if (resp) {
              $scope.getFileUrl(resp)
            }
          })
        } else {
          Api.put(
            'reports/export/?form=' + $scope.formId + reportQuery(),
            { selected: $scope.selected },
            function (resp) {
              if (resp) {
                $scope.getFileUrl(resp)
              }
            },
          )
        }
      }

      $scope.getFileUrl = function (resp) {
        $scope.disableDownloadBtn = true
        const timeout = resp.time * 1000 + 1000
        setTimeout(function () {
          Api.ExportXlsFile.get({ xls_id: resp.xls_id }, function (resp) {
            if (resp && resp.file_url) {
              $scope.downloadFile(resp)
              $scope.close()
            }
          })
        }, timeout)
      }

      $scope.downloadFile = function (resp) {
        $scope.exportData = resp.file_url
        if ($scope.exportData) {
          var a = document.createElement('a')
          a.href = $scope.exportData
          clickEvent(a)
          $scope.disableDownloadBtn = false
        }
      }

      $scope.showAlertDialog = function (title, message, text_alignment) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content download-alert-width',
          templateUrl: 'app/views/alert_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.title = title
            $scope.message = message
            $scope.textAlignment = text_alignment
            $scope.textWeight = 'regular'
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }
    },
  )
