import * as React from 'react'
import { css } from '@emotion/react'
import classNames from 'classnames'
import Eye from '@/assets/icons/eye-icon.svg'
import CrossedEye from '@/assets/icons/crossed-eye-icon.svg'
import Logo from '@/assets/images/rtrs-logo.png'
import { useState, useEffect } from 'react'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { redirectLogic, handleSubmit, handleValidation } from '../../components'

export const SetupPasswordPage = () => {
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [isPasswordVisibable, setPasswordVisability] = useState(false)
  const [isConfirmPasswordVisibable, setConfirmPasswordVisability] =
    useState(false)
  const [validation, setValidation] = useState({})
  const [isPasswordsEqual, setPasswordsEqual] = useState(true)
  const [canWeRender, setCanWeRender] = useState(false)
  const [isTokenValid, setTokenValid] = useState(true)

  const baseStyle = getBaseStyles()

  const { Api, Notification, CurrentUser } = useAngularServices()
  const { stateService } = useRouter()

  const handleChangeValue = (inputValue: string) => {
    const validation = handleValidation(inputValue)
    setValidation(validation)
  }

  useEffect(() => {
    redirectLogic(CurrentUser, stateService, Api, setCanWeRender)
  }, [])

  useEffect(() => {
    if (stateService.current.name !== 'reset') {
      const getTokenStatus = async () => {
        const token = stateService.params.token

        try {
          await Api.getWithoutDeletedParam(`invites/${token}`, {})
        } catch (error) {
          Notification.clearNotification()
          setTokenValid(false)

          stateService.go('request')
        }
      }
      getTokenStatus()
    }
  }, [])

  if (!canWeRender) return null

  return (
    <div css={baseStyle}>
      {!isTokenValid && (
        <div className="error-banner">
          This link has expired. Please contact your administrator for a new
          link.
        </div>
      )}
      {canWeRender && (
        <div className="login__wrapper">
          <div className="login__body">
            <div className="login__logo">
              <img className="logo" src={Logo} alt="" />
            </div>
            <div className="login__row">
              <h1 className="login__title">Setup Password</h1>
              <div className="login__inputs-row">
                <div className="login__password-row">
                  <input
                    type={isPasswordVisibable ? 'text' : 'password'}
                    className="login__input"
                    placeholder="Password"
                    disabled={!isTokenValid}
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value)
                      handleChangeValue(e.target.value)
                    }}
                  />
                  <img
                    src={isPasswordVisibable ? Eye : CrossedEye}
                    alt="eye"
                    onClick={() => {
                      setPasswordVisability(!isPasswordVisibable)
                    }}
                  />
                </div>
                <div className="login__password-row">
                  <input
                    type={isConfirmPasswordVisibable ? 'text' : 'password'}
                    disabled={!isTokenValid}
                    className={classNames('login__input', {
                      input__error: !isPasswordsEqual,
                    })}
                    placeholder="Confirm Password"
                    value={confirmPassword}
                    onChange={(e) => {
                      setConfirmPassword(e.target.value)
                      setPasswordsEqual(true)
                    }}
                  />
                  <img
                    src={isConfirmPasswordVisibable ? Eye : CrossedEye}
                    alt="eye"
                    onClick={() => {
                      setConfirmPasswordVisability(!isConfirmPasswordVisibable)
                    }}
                  />
                </div>
                {!isPasswordsEqual && (
                  <p className="login__error">Passwords do not match</p>
                )}
              </div>
              <div className="login__requirements">
                <p className="requirements__title">Password must contain:</p>
                <div className="requirements__row">
                  <div className="requirements__field">
                    <div
                      className={classNames('circle', {
                        valid__circle: validation.lengthTest,
                      })}
                    ></div>
                    <p
                      className={classNames('field__title', {
                        valid__title: validation.lengthTest,
                      })}
                    >
                      A length of 8 or more characters
                    </p>
                  </div>
                  <div className="requirements__field">
                    <div
                      className={classNames('circle', {
                        valid__circle: validation.upperCaseTest,
                      })}
                    ></div>
                    <p
                      className={classNames('field__title', {
                        valid__title: validation.upperCaseTest,
                      })}
                    >
                      1 uppercase character
                    </p>
                  </div>
                  <div className="requirements__field">
                    <div
                      className={classNames('circle', {
                        valid__circle: validation.lowerCaseTest,
                      })}
                    ></div>
                    <p
                      className={classNames('field__title', {
                        valid__title: validation.lowerCaseTest,
                      })}
                    >
                      1 lowercase character
                    </p>
                  </div>
                  <div className="requirements__field">
                    <div
                      className={classNames('circle', {
                        valid__circle: validation.numericCharacterTest,
                      })}
                    ></div>
                    <p
                      className={classNames('field__title', {
                        valid__title: validation.numericCharacterTest,
                      })}
                    >
                      1 numeric character
                    </p>
                  </div>
                  <div className="requirements__field">
                    <div
                      className={classNames('circle', {
                        valid__circle: validation.specialCharacterTest,
                      })}
                    ></div>
                    <p
                      className={classNames('field__title', {
                        valid__title: validation.specialCharacterTest,
                      })}
                    >
                      1 special character
                    </p>
                  </div>
                </div>
              </div>
              <div
                className={classNames('login__button', {
                  disabled: !isTokenValid,
                })}
                onClick={() => {
                  if (isTokenValid) {
                    handleSubmit(
                      stateService,
                      Notification,
                      Api,
                      password,
                      confirmPassword,
                      setPasswordsEqual,
                    )
                  }
                }}
              >
                Save Password
              </div>
              <p className="login__copyright">© Copyright 2023</p>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

function getBaseStyles() {
  return css({
    '.login__wrapper': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'white',
      width: '100%',
      height: '115vh',
      backgroundImage: `url(../assets/images/login-background.png)`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      paddingBottom: '20vh',
    },
    '@media (min-height: 1010px)': {
      '.login__wrapper': {
        paddingBottom: '32vh',
        paddingTop: '15vh',
      },
    },
    '.login__body': {
      boxSizing: 'border-box',
      padding: '27px 0px 27px 0px',
      marginTop: '100px',
      width: '542px',
      background: 'rgba(217, 217, 217, 0.8)',
      borderRadius: '20px',
      position: 'relative',
    },
    '.login__logo': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.logo': {
      width: '204px',
      height: '204px',
    },
    '.login__row': {
      width: '333px',
      margin: '0 auto',
    },
    '.login__title': {
      fontWeight: 600,
      fontSize: '16px',
      lineHeight: '18px',
      textAlign: 'center',
      color: '#515253',
      margin: 0,
    },
    '.login__inputs-row': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      rowGap: '17px',
      marginTop: '30px',
    },
    '.login__input': {
      boxSizing: 'border-box',
      width: '100%',
      background: '#ffffff',
      borderRadius: '25px',
      fontFamily: 'Sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      display: 'flex',
      alignItems: 'center',
      height: '50px',
      padding: '0px 79px 0px 25px',
      color: '#515253',
      border: 'none',
      '&::placeholder': {
        color: '#999999',
      },
      '&:focus, &:active': {
        border: 'none',
        outline: 'none',
      },
    },
    '.input__error': {
      border: '1px solid #FF0505',
    },
    '.login__password-row': {
      position: 'relative',
      width: '100%',
      img: {
        cursor: 'pointer',
        position: 'absolute',
        right: '16px',
        top: '15px',
      },
    },
    '.login__error': {
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '11px',
      margin: 0,

      color: '#FF0505',
    },
    '.login__button': {
      width: '100%',
      margin: '0 auto',
      marginTop: '23px',
      height: '50px',
      background: '#2167ae',
      borderRadius: '25px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      color: '#FFFFFF',
      cursor: 'pointer',
    },
    '.disabled': {
      background: 'grey',
      cursor: 'default',
    },
    '.requirements__row': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '5px',
    },
    '.requirements__title': {
      fontWeight: 600,
      fontSize: '12px',
      lineHeight: '13px',
      letterSpacing: '-0.01em',
      margin: 0,
      marginTop: '17px',
      marginBottom: '15px',
      padding: 0,
      color: '#676A6C',
    },
    '.requirements__field': {
      display: 'flex',
      columnGap: '8px',
      fontDisplay: 'row',
      marginBottom: '2px',
    },
    '.circle': {
      border: '0.5px solid #2167AE',
      height: '12px',
      width: '12px',
      borderRadius: '50%',
    },
    '.valid__circle': {
      transition: 'all .4s ease',
      background: '#2167AE',
    },
    '.field__title': {
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '13px',
      color: '#676A6C',
      margin: 0,
      padding: 0,
    },
    '.valid__title': {
      transition: 'all .4s ease',
      textDecorationLine: 'line-through',
    },
    '.login__copyright': {
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '10px',
      lineHeight: '11px',
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '45px',
      color: '#68696D',
      width: '303',
    },
    '.error-banner': {
      position: 'absolute',
      width: '100%',
      fontSize: '12px',
      background: '#D4B4B4',
      color: '#BD2828',
      paddingLeft: '35px',
      paddingTop: '12px',
      paddingBottom: '12px',
      zIndex: 1,
    },
  })
}
