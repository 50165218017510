import * as React from 'react'
import { css } from '@emotion/react'
import { getTranslatedString } from '@/utils'
import { FieldSelector } from '../index'
import { useAngularServices } from '@components/index'
import { percentageShadeBlendColor } from '@/utils'

export const HeaderField = ({
  fieldData,
  formMemory,
  setFormMemory,
  possibleGroupData,
  addSketchOrSignatureText,
  promisesToDeleteFiles,
  firstFieldToError,
  onChange,
}) => {
  const { CurrentUser } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const [isHeaderOpen, setIsHeaderOpen] = React.useState(false)

  const baseStyle = getBaseStyle(variableColor)
  const idsOfFieldsInside = []

  fieldData.fields.forEach((field) => {
    if (field.type === 'group') {
      field.group_list.forEach((group_field) => {
        idsOfFieldsInside.push(group_field.id)
      })
    } else {
      idsOfFieldsInside.push(field.id)
    }
  })

  React.useEffect(() => {
    if (firstFieldToError !== null) {
      if (firstFieldToError.idsThatErrored) {
        if (
          idsOfFieldsInside.some(
            (r) => firstFieldToError.idsThatErrored.indexOf(r) >= 0,
          )
        ) {
          setIsHeaderOpen(true)
          setTimeout(() => {
            if (
              document.getElementById(firstFieldToError.idOfFirstErroredField)
            ) {
              document
                .getElementById(firstFieldToError.idOfFirstErroredField)
                .scrollIntoView()
            }
          }, 100)
        }
      }
    }
  }, [firstFieldToError])

  return (
    <section css={baseStyle}>
      <div
        className="ibox-title toggle-title header-opening"
        onClick={() => {
          setIsHeaderOpen(!isHeaderOpen)
        }}
      >
        <h5>{getTranslatedString(fieldData.name)}</h5>
        <div className="ibox-tools">
          {isHeaderOpen ? (
            <i className="fa fa-chevron-up" />
          ) : (
            <i className="fa fa-chevron-down" />
          )}
        </div>
      </div>
      <div css={css({ display: isHeaderOpen ? 'block' : 'none' })}>
        {fieldData.fields.map((field, idx) => {
          return (
            <FieldSelector
              fieldData={field}
              key={idx}
              formMemory={formMemory}
              possibleGroupData={possibleGroupData}
              setFormMemory={setFormMemory}
              addSketchOrSignatureText={addSketchOrSignatureText}
              promisesToDeleteFiles={promisesToDeleteFiles}
              onChange={onChange}
            />
          )
        })}
      </div>
    </section>
  )
}

function getBaseStyle(variableColor) {
  return css({
    '.header-opening': {
      display: 'flex',
      width: '100%',
      backgroundColor: percentageShadeBlendColor(0.8, variableColor),
      textAlign: 'center',
      border: 'none',
      padding: '15px',
      minHeight: 30,
      marginBottom: 30,
    },
    '.ibox-tools': {
      width: 21,
      marginLeft: -21,
    },
    h5: {
      display: 'block',
      width: '100%',
      textAlign: 'center',
      margin: 0,
      paddingLeft: 30,
      paddingRight: 30,
    },
  })
}
