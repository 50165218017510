angular
  .module('ccs')
  .controller(
    'CompanyBillingCtrl',
    function (
      $scope,
      Notification,
      Api,
      $countries,
      $async,
      CurrentUser,
      $log,
    ) {
      $log.debug('CompanyBillingCtrl')

      $scope.countries = $countries
      $scope.user = CurrentUser.getInstance()

      $scope.submit = $async(async function () {
        const user = $scope.user
        try {
          await Api.Users.patch({
            id: user.id,
            town: user.town,
            company_name: user.company_name,
            state: user.state,
            phone: user.phone,
            country: user.country,
            street: user.street,
            zip: user.zip,
          })
          Notification.success('Sucessfully updated Company Info')
        } catch (err) {
          if (err?.data?.company_name?.[0] === 'This field may not be blank.') {
            Notification.danger('Company Name required.')
          }
        }
      })
    },
  )
