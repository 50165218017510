import * as React from 'react'
import { useAngularServices } from '@/react/components'
import { SetupPasswordPage } from './Login/components/SetupPasswordPage'
import { ZurichSetupPasswordPage } from './ZurichLogin/components/ZurichSetupPasswordPage'

export const ForgotPasswordRedirect = () => {
  const { Configuration } = useAngularServices()

  if (
    Configuration.appEnv === 'zurich-dev' ||
    Configuration.appEnv === 'zurich-production' ||
    Configuration.appEnv === 'zurich-master'
  )
    return <ZurichSetupPasswordPage />

  return <SetupPasswordPage />
}
