angular.module('ccs').directive('toggleall', toggleAll)

function toggleAll($log) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/toggle_all.html',
    link: function ($scope) {
      $scope.$parent.$parent.$parent.$parent.active = false
      $scope.handleToggle = () => {
        $scope.$parent.$parent.$parent.$parent.active = !$scope.$parent.$parent.$parent.$parent.active
        try {
          $scope.toggleAll()
        } catch (e) {
          $log.warn('toggleAll() not implemented!')
        }
      }
    },
  }
}
