import { EditIcon } from '@/react/componentAssets'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import Highlighter from 'react-highlight-words'

export const TrainingRow = ({ training, index, search }) => {
  const { CurrentUser, $rootScope } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const [hoveredField, setHoveredField] = useState(null)

  return (
    <tr
      className={classNames('training__row', {
        dark: index % 2 === 0,
      })}
    >
      <td
        className="name__cell"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: training?.name,
            max__length: 112,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={training?.name}
        />
      </td>
      <td>
        <div
          className="edit__cell"
          onClick={() => {
            stateService.go('trainings.assign.employee_assign', {
              training: training?.id,
            })
          }}
        >
          <EditIcon color={variableColor} height="17px" />
          <p className="projects__count">{training.active_users_count}</p>
        </div>
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
