angular
  .module('ccs')
  .controller(
    'ProjectBulkUploadCtrl',
    function (
      $scope,
      Api,
      $stateParams,
      $state,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('ProjectBulkUploadCtrl')

      $scope.procedure = {
        file: {},
        name: '',
        client: CurrentUser.getClientId(),
      }
      $scope.fileErrors = []
      $scope.fileSummary = ''
      $scope.startsWithLineErrors = []
      $scope.getClientSettings = CurrentUser.getClientSettings

      let fileChanged = false
      $scope.validFileFormatDetected = 0
      var isClickable = false
      $scope.acceptedFilesFormats =
        'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx, .csv, text/csv'

      $scope.dzConfig = {
        parallelUploads: 1,
        acceptedFiles: $scope.acceptedFilesFormats,
        url: '#',
        maxFiles: 1,
        maxFilesize: 100,
        autoProcessQueue: false,
        addRemoveLinks: true,
        maxfilesexceeded: function maxfilesexceeded(file) {
          this.removeAllFiles()
          this.addFile(file)
        },
        init: function () {
          //For the backfilled mockfiles
          //https://github.com/enyo/dropzone/issues/279
          this.on('addedfile', function () {
            if (this.files[1] != null) {
              this.removeFile(this.files[0])
            }
          })
        },
      }

      $scope.dzAddedFile = function (file) {
        $scope.fileErrors = []
        $scope.startsWithLineErrors = []
        if (
          $scope.acceptedFilesFormats.indexOf(file.type) !== -1 ||
          file.type === $scope.acceptedFilesFormats ||
          file.name.split('.').pop() === $scope.acceptedFilesFormats
        ) {
          $scope.procedure.file.file = file
          $scope.validFileFormatDetected = 1
          fileChanged = true
          isClickable = true
        } else {
          $scope.validFileFormatDetected = -1
          fileChanged = false
          isClickable = false
          document.querySelectorAll('.dz-error-mark')[0].style.opacity = '1'
          var errorDisplay = document.querySelectorAll('.dz-error-message')[0]
          errorDisplay.style.opacity = '1'
          $(errorDisplay).text('CSV or XLS documents only please.')
        }

        $scope.$apply(function () {
          $scope.isClickable = isClickable
        })
      }

      $scope.dzRemovedFile = function (file) {
        fileChanged = !fileChanged
        $scope.validFileFormatDetected -= 1

        if (this.files.length == 0) {
          isClickable = false
        } else {
          isClickable = true
        }

        $scope.$apply(function () {
          $scope.isClickable = isClickable
        })
        $scope.fileErrors = []
        $scope.startsWithLineErrors = []
      }

      $scope.parseErrors = function (errors) {
        var temp = angular.copy(errors)
        for (let i = 0; i < temp.length; i++) {
          var line = temp[i]
          if (typeof line.error === 'object') {
            for (var error in line.error) {
              $scope.fileErrors.push({
                row: line.row,
                error: error + ' - ' + line.error[error].join(''),
              })
            }
            continue
          }
          $scope.fileErrors.push(line)
        }
        return $scope.fileErrors
      }

      $scope.submit = function () {
        if (fileChanged && $scope.validFileFormatDetected >= 0) {
          $('.dz-progress').css('display', 'block')
          Api.uploadBulkProjects(
            $scope.procedure.file.file,
            (resp) => {
              if (resp && resp.message) {
                Notification.success(resp.message)
                $('.dz-progress').css('display', 'none')
                $state.go('admin.projects.list')
              }
            },
            (error) => {
              if (error) {
                if (error.message) {
                  $scope.parseErrors(error.message)
                  $scope.fileSummary = error.message.filter(
                    (i) => i.message,
                  )[0].message
                }
                if (
                  error[0] ===
                  'Please do not put more than 2000 projects in one file.'
                ) {
                  setTimeout(function () {
                    $scope.$apply(function () {
                      $scope.isClickable = false
                    })
                  }, 0)
                  Notification.fileError(error[0])
                } else if (
                  error[0] ===
                  'Cannot validate file. Please use the correct column headers in row one from the example document.'
                ) {
                  $scope.startsWithLineErrors.push(error[0])
                } else {
                  if (error.length > 0) {
                    error.map(function (err) {
                      if (err.startsWith('Line')) {
                        $scope.startsWithLineErrors.push(err)
                      } else {
                        Notification.fileError(err)
                      }
                    })
                  }
                }
                Notification.clearNotification()
              }

              $('.dz-progress').css('display', 'none')
            },
          )
        } else {
          $scope.disabledBtn = false
          Notification.warning('No Attached.')
          $('.dz-progress').css('display', 'none')
        }
      }
    },
  )
