require('bootstrap')

// The ordering of styling imports matters.
import 'nvd3/build/nv.d3.css'
import 'animate.css/animate.css'
import 'dropzone/downloads/css/dropzone.css'
import './switchery/switchery.css'
import 'ui-select/dist/select.css'
import 'ng-dialog/css/ngDialog.min.css'
import 'ng-dialog/css/ngDialog-theme-default.css'
import './less/variables.less'
import './less/badgets_labels.less'
import './less/base.less'
import './less/buttons.less'
import './less/chat.less'
import './less/clients.less'
import './less/css-checkbox.less'
import './less/custom.less'
import './less/canvas-paint.less'
import './less/dropzone.less'
import './less/flexible-modal.less'
import './less/landing.less'
import './less/md-skin.less'
import './less/media.less'
import './less/metismenu.less'
import './less/mixins.less'
import './less/navigation.less'
import './less/observation-printable.less'
import './less/orange-theme-2.less'
import './less/orange-theme.less'
import './less/original-theme.less'
import './less/pages.less'
import './less/rtl.less'
import './less/sidebar.less'
import './less/sortable-table-header.less'
import './less/spinners.less'
import './less/style.less'
import './less/theme-config.less'
import './less/top_navigation.less'
import './less/typography.less'
import './less/elements.less'

// AngularJS part of the application
import './webpack.templates.js'

import './routes'
import './controllers'
import './services'
import './filters'
import './directives'

// React part of the application
import '../react'
