import async from 'async'
import pluralize from 'pluralize'
import { _extends, generateUUID } from '@/utils'
import { cloneDeep } from 'lodash'
import { clearOptionCopies } from './utils/createEditTemplateRelated'

angular
  .module('ccs')
  .controller(
    'FormCreateCtrl',
    function (
      $scope,
      Api,
      $state,
      app,
      ngDialog,
      $uibModal,
      CurrentUser,
      Notification,
      $log,
    ) {
      $log.debug('FormCreateCtrl')

      $scope.titleCreateForm = app.app_mobile_form_display
      $scope.titleSingularForm = pluralize.singular(app.app_mobile_form_display)
      $scope.form = {
        fields: [],
        isLengthValid: true,
      }
      $scope.app = app

      $scope.FIELD_TYPES = {
        address: 'Address',
        currency: 'Currency($)',
        date: 'Date',
        dimension: 'Dimension',
        files: 'File',
        header: 'Header',
        image_reference: 'Image Reference',
        mass: 'Mass',
        nested_option: 'Nested Option',
        number: 'Number',
        options: 'Options(Single Select)',
        options_multi: 'Options(Multi Select)',
        // options_: 'Options',
        paragraph: 'Paragraph',
        photos: 'Photos',
        radio: 'Radio Button',
        // header: 'Header',
        signatures: 'Signatures',
        sketches: 'Sketch',
        temperature: 'Temperature',
        text: 'Text',
        time: 'Time',
      }

      $scope.NESTED_FIELD_TYPES = {
        address: 'Address',
        currency: 'Currency($)',
        date: 'Date',
        dimension: 'Dimension',
        files: 'File',
        image_reference: 'Image Reference',
        mass: 'Mass',
        number: 'Number',
        options: 'Options(Single Select)',
        options_multi: 'Options(Multi Select)',
        paragraph: 'Paragraph',
        photos: 'Photos',
        radio: 'Radio Button',
        signatures: 'Signatures',
        sketches: 'Sketch',
        temperature: 'Temperature',
        text: 'Text',
        time: 'Time',
      }

      $scope.GROUP_SETTING = {
        NA: 'NA',
        add_group: '+ Create New',
      }

      $scope.GROUPS_SETTING_INFO = {
        NA: {},
        add_group: {},
      }

      $scope.allowableFields = ['field']
      $scope.allowableOptions = ['option']
      $scope.allowableNestedOptions = ['nested_option']
      $scope.allowableNestedOptionsAnswers = ['nested_option_answer']
      $scope.allowableNestedOptionFields = ['nested_option_field']
      $scope.saving = false

      // Reference:
      //   https://github.com/marceljuenemann/angular-drag-and-drop-lists/issues/273#issuecomment-250359846
      $(document).on('mouseup', 'input:text, textarea', function (e) {
        $(this).blur()
        $(this).focus()
      })

      $scope.addField = function () {
        $scope.form.fields.push({
          type: 'text',
          order: $scope.form.fields.length,
          changed: false,
          options: [],
          objType: 'field',
          required: false,
          tempId: $scope.form.fields.length,
          group: 'NA',
          group_info: {},
          isHeaderCollapse: false,
          headerClass: '',
          collapsed_class: '',
          isLengthValid: true,
        })
      }

      function reopenCollapsedHeader(field, startIndex) {
        $('.collapsed_header_' + field.order).show()
        field.isHeaderCollapse = false

        for (var j = startIndex; j < $scope.form.fields.length; j++) {
          $scope.form.fields[j].collapsed_class = false

          if ($scope.form.fields[j].type === 'header') {
            $scope.form.fields[j].collapsed_class = false
            break
          }
        }
      }

      $scope.setCollapsedClass = function (field) {
        var header_order = field.type === 'header' ? field.order : null
        field.collapsed_class = false
        var startIndex = header_order === null ? 0 : field.order + 1
        field.headerClass = 'header_' + header_order

        if (field.type === 'header') {
          for (var i = startIndex; i < $scope.form.fields.length; i++) {
            $scope.form.fields[i].collapsed_class =
              $scope.form.fields[i].type !== 'header' &&
              'collapsed_header_' + header_order

            if ($scope.form.fields[i].type === 'header') {
              $scope.form.fields[i].collapsed_class = false
              break
            }
          }
        } else {
          reopenCollapsedHeader(field, startIndex)
        }

        setTimeout(function () {
          $scope.$apply()
        }, 0)
      }

      $scope.collapsedHeader = function (isHeaderCollapse, field, reset) {
        const elements = $('.collapsed_header_' + field.order)

        if (reset) {
          field.isHeaderCollapse && $(elements).addClass('collapsed_header')
          !field.isHeaderCollapse && $(elements).removeClass('collapsed_header')
          return
        }

        field.isHeaderCollapse = !field.isHeaderCollapse
        field.isHeaderCollapse && $(elements).addClass('collapsed_header')
        !field.isHeaderCollapse && $(elements).removeClass('collapsed_header')
      }

      $scope.addTitleField = function () {
        $scope.form.fields.push({
          type: 'report_title',
          options: [],
          required: false,
          tempId: 0,
          order: 0,
          changed: false,
          group: 'NA',
          group_info: {},
          name: 'Report Title',
          isLengthValid: true,
        })
      }

      $scope.removeOptions = function (field, isNested) {
        if (!isNested) {
          if (field.options.length) {
            field.options = []
          }
        } else {
          if (field.answer_options.length) {
            field.answer_options = []
          }
        }
      }

      $scope.addRadioButtons = function (field) {
        if (field.type === 'radio') {
          $scope.removeOptions(field, false)
          if (field.type === 'radio' && !field.options.length) {
            field.options = [
              {
                name: 'Yes',
                field: field.tempId,
                answer: false,
                isLengthValid: true,
                tempId: field.options.length + 1,
                objType: 'option',
              },
              {
                name: 'No',
                field: field.tempId,
                answer: true,
                isLengthValid: true,
                tempId: field.options.length + 1,
                objType: 'option',
              },
            ]
          }
        }
      }

      $scope.addNestedRadioButtons = function (field) {
        if (field.type === 'radio') {
          $scope.removeOptions(field, true)
          if (field.type === 'radio' && !field.answer_options.length) {
            field.answer_options = [
              {
                name: 'Yes',
                field: field.tempId,
                answer: false,
                isLengthValid: true,
                tempId: field.answer_options.length + 1,
                objType: 'nested_option_answer',
              },
              {
                name: 'No',
                field: field.tempId,
                answer: true,
                isLengthValid: true,
                tempId: field.answer_options.length + 1,
                objType: 'nested_option_answer',
              },
            ]
          }
        }
      }

      $scope.addOption = function (field) {
        field.options.push({
          name: '',
          isLengthValid: true,
          nested_fields: [],
          tempId: field.options.length + 1,
          // objType: 'option'
          objType: field.type === 'nested_option' ? 'nested_option' : 'option',
        })
      }

      $scope.addNestedOption = function (field) {
        field.answer_options.push({
          name: '',
          isLengthValid: true,
          tempId: field.answer_options.length + 1,
          objType: 'nested_option_answer',
        })
      }

      $scope.addNestedOptionFields = function (option) {
        option.nested_fields.push({
          type: 'text',
          order: option.nested_fields.length + 1,
          changed: false,
          answer_options: [],
          objType: 'nested_option_field',
          required: false,
          tempId: option.nested_fields.length + 1,
          group: 'NA',
          group_info: {},
          isHeaderCollapse: false,
          isLengthValid: true,
        })
      }

      $scope.removeOption = function (field, option) {
        field.options = field.options.filter(function (o) {
          return o.tempId != option.tempId
        })
        field.options = field.options.map(function (o, i) {
          return _extends({}, o, {
            tempId: i + 1,
          })
        })
      }

      $scope.removeNestedOption = function (field, option) {
        field.answer_options = field.answer_options.filter(function (o) {
          return o.tempId != option.tempId
        })
        field.answer_options = field.answer_options.map(function (o, i) {
          return _extends({}, o, {
            tempId: i + 1,
          })
        })
      }

      $scope.removeField = function (fieldToRemove) {
        $scope.form.fields = $scope.form.fields.filter(function (field) {
          return fieldToRemove.tempId != field.tempId
        })

        $scope.form.fields = $scope.form.fields.map(function (field, i) {
          reopenCollapsedHeader(fieldToRemove, fieldToRemove.order)
          return _extends({}, field, {
            tempId: i + 1,
            order: i,
          })
        })
      }

      $scope.removeNestedField = function (option, fieldToRemove) {
        option.nested_fields = option.nested_fields.filter(function (field) {
          return fieldToRemove.tempId != field.tempId
        })

        option.nested_fields = option.nested_fields.map(function (field, i) {
          return _extends({}, field, {
            tempId: i + 1,
          })
        })
      }

      $scope.setOrder = function (index, order, event) {
        if (event.keyCode == 13) {
          if (!index || !order) {
            return
          }

          order += 1
          if (index < order) {
            $scope.form.fields = [].concat(
              $scope.form.fields.slice(0, index),
              $scope.form.fields.slice(index + 1, order),
              $scope.form.fields[index],
              $scope.form.fields.slice(order, $scope.form.fields.length),
            )
            for (var i = 0; i < $scope.form.fields.length; i++) {
              $scope.form.fields[i].order = i
              if ($scope.form.fields[i].type === 'header') {
                $scope.setCollapsedClass($scope.form.fields[i])
              }
            }
            setTimeout(function () {
              $scope.$apply(function () {
                for (var i = 0; i < $scope.form.fields.length; i++) {
                  if ($scope.form.fields[i].type === 'header') {
                    $scope.collapsedHeader(
                      $scope.form.fields[i].isHeaderCollapse,
                      $scope.form.fields[i],
                      'reset',
                    )
                  }
                }
              })
            }, 500)
            return
          }

          $scope.form.fields = [].concat(
            $scope.form.fields.slice(0, order - 1),
            $scope.form.fields[index],
            $scope.form.fields.slice(order - 1, index),
            $scope.form.fields.slice(index + 1, $scope.form.fields.length),
          )
          for (var i = 0; i < $scope.form.fields.length; i++) {
            $scope.form.fields[i].order = i
            if ($scope.form.fields[i].type === 'header') {
              $scope.setCollapsedClass($scope.form.fields[i])
            }
          }

          setTimeout(function () {
            $scope.$apply(function () {
              for (var i = 0; i < $scope.form.fields.length; i++) {
                if ($scope.form.fields[i].type === 'header') {
                  $scope.collapsedHeader(
                    $scope.form.fields[i].isHeaderCollapse,
                    $scope.form.fields[i],
                    'reset',
                  )
                }
              }
            })
          }, 100)
        }
      }

      $scope.handleInputBlur = function (index, field, event) {
        ;(field.order == 0 || !field.order || event.type == 'blur') &&
          (field.order = index)
      }

      $scope.validateGroupName = function (group_name) {
        for (var item in $scope.GROUP_SETTING) {
          if ($scope.GROUP_SETTING[item] === group_name) {
            $scope.error =
              'You may not have multiple groups with the same name.'
            return $scope.error
          }
        }
        return ($scope.error = null)
      }

      $scope.addNewMobileFormGroup = function (field) {
        field.group_info = $scope.GROUPS_SETTING_INFO[field.group]

        if (field.group === 'add_group' || field.group.k === 'add_group') {
          var modalInstance = $uibModal.open({
            ariaLabelledBy: 'modal-title',
            ariaDescribedBy: 'modal-body',
            windowClass: 'custom-content',
            templateUrl: 'app/views/add_new_mobile-form-group_dialog.html',
            scope: $scope,
            bindToController: true,
            /** @ngInject */
            controller: function controller($scope) {
              $scope.group = {
                name: null,
                form: null,
              }
              $scope.disableSaveBtn = false
              $scope.save = function () {
                if ($scope.validateGroupName($scope.group.name)) return
                $scope.disableSaveBtn = true
                Api.Groups.post($scope.group, function (resp) {
                  $scope.error = $scope.validateGroupName(resp.name)
                  $scope.GROUP_SETTING[resp.id] = resp.name
                  $scope.GROUPS_SETTING_INFO[resp.id] = resp
                  field.group = resp.id
                  field.group_info = resp
                  $scope.disableSaveBtn = false
                  $scope.close()
                })
              }
            },
          })

          $scope.close = function () {
            modalInstance.close()
          }
        }
      }

      $scope.editMobileFormGroup = function (field, field_group) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content',
          templateUrl: 'app/views/add_new_mobile-form-group_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.disableSaveBtn = false
            $scope.group = {
              name: $scope.GROUP_SETTING[field_group],
              form: null,
            }

            $scope.save = function () {
              if ($scope.validateGroupName($scope.group.name)) return
              $scope.disableSaveBtn = true
              Api.patch('groups/' + field_group, $scope.group, function (resp) {
                $scope.error = $scope.validateGroupName(resp.name)
                delete $scope.GROUP_SETTING[field_group]
                delete $scope.GROUPS_SETTING_INFO[field_group]
                $scope.GROUP_SETTING[resp.id] = resp.name
                $scope.GROUPS_SETTING_INFO[resp.id] = resp
                field.group = resp.id
                field.group_info = resp
                $scope.disableSaveBtn = false

                $scope.form.fields.map(function (item) {
                  item.group_info.id == field_group && (item.group_info = resp)
                  item.group == field_group && (item.group = resp.id)
                  return item
                })

                $scope.close()
              })
            }
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }

      $scope.removeMobileFormGroup = function (field) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content',
          templateUrl: 'app/views/remove_mobile-form-group_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.disableSaveBtn = false
            $scope.message = 'Are you sure you want to delete this group?'
            let group = field.group
            $scope.remove = function () {
              Api.Groups.delete({ id: parseInt(group) }, (resp) => {
                delete $scope.GROUP_SETTING[field.group]
                delete $scope.GROUPS_SETTING_INFO[field.group]
                $scope.form.fields.forEach(function (item) {
                  if (item.group == group) {
                    item.group = null
                    item.group_info = {}
                  }
                })
                $scope.close()
              })
            }
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }

      $scope.addEditMobileFormNote = function (field) {
        var modalInstance = $uibModal.open({
          ariaLabelledBy: 'modal-title',
          ariaDescribedBy: 'modal-body',
          windowClass: 'custom-content',
          templateUrl: 'app/views/add_edit_mobile-form-note_dialog.html',
          scope: $scope,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.note = {
              text: field.note,
            }

            $scope.save = function () {
              field.note = $scope.note.text
              $scope.close()
            }

            $scope.delete = function () {
              field.note = null
              $scope.note.text = null
              $scope.close()
            }
          },
        })

        $scope.close = function () {
          modalInstance.close()
        }
      }

      $scope.titleHovered = false

      $scope.isTitleHovered = function (field) {
        if (field.type === 'report_title') {
          $scope.titleHovered = true
        }
      }

      $scope.isTitleLeave = function (field) {
        if (field.type === 'report_title') {
          $scope.titleHovered = false
        }
      }

      $scope.openUploadImg = function (field_id) {
        $('#' + field_id).click()
      }

      $scope.viewImageReference = function (photo) {
        var $scp = this.$new(true)
        $scp.photo = photo
        $uibModal.open({
          animation: false,
          templateUrl: 'app/views/toolbox_view_photo.html',
          size: 'md',
          scope: $scp,
        })
      }

      $scope.removeImageReference = function (
        field,
        nested_tempId,
        nested_option,
      ) {
        for (var i = 0; i < $scope.form.fields.length; i++) {
          if (
            field.type !== 'nested_option' &&
            field.order == $scope.form.fields[i].order
          ) {
            $scope.form.fields[i].image_reference_info = {}
            $scope.form.fields[i].image_reference = null
          }

          if (
            field.type === 'nested_option' &&
            field.options.length > 0 &&
            field.tempId == $scope.form.fields[i].tempId
          ) {
            field.options.forEach(function (nested_options) {
              if (nested_option == nested_options.tempId) {
                nested_options.nested_fields.forEach(function (nested_field) {
                  if (nested_field.tempId == nested_tempId) {
                    nested_field.image_reference_info = {}
                    nested_field.image_reference = null
                  }
                })
              }
            })
          }
        }
      }

      function validateImage(input) {
        var allowedExtension = ['jpeg', 'jpg', 'png']
        var fileExtension = input.value.split('.').pop().toLowerCase()

        if (allowedExtension.indexOf(fileExtension) === -1) {
          return 'Allowed extensions are : *.' + allowedExtension.join(', *.')
        }

        return null
      }

      $scope.addImageReference = function (input) {
        var inputArrayData = input.id.split('-')
        $scope.$apply(function () {
          $scope.error = validateImage(input)
        })

        if (!input.value || !input.files.length || $scope.error) return

        Api.uploadImage(input.files[0], {}, function (resp) {
          $scope.form.fields.forEach(function (field) {
            if (
              inputArrayData[1] == 'field' &&
              field.type !== 'nested_option' &&
              field.order == parseInt(inputArrayData[0])
            ) {
              field.image_reference_info = resp
              field.image_reference = resp.id
            }
            if (
              inputArrayData[1] == 'nested_field' &&
              field.order == parseInt(inputArrayData[2]) &&
              field.type === 'nested_option' &&
              field.options.length > 0
            ) {
              field.options.forEach(function (nested_options) {
                if (nested_options.tempId == inputArrayData[3]) {
                  nested_options.nested_fields.forEach(function (nested_field) {
                    if (
                      nested_field.tempId == parseInt(inputArrayData[0]) &&
                      field.order == parseInt(inputArrayData[2])
                    ) {
                      nested_field.image_reference_info = resp
                      nested_field.image_reference = resp.id
                    }
                  })
                }
              })
            }
          })
          input.value = null
        })
      }

      $scope.isNameLengthValid = function (field, name) {
        if (name && name.length > 0) {
          field.isLengthValid = name.length > 255 ? false : true
        }
      }

      function countSameGroups(form) {
        $scope.countFields = {}
        form.fields.forEach(function (i) {
          $scope.countFields[i.group] = ($scope.countFields[i.group] || 0) + 1
        })
      }

      $scope.getFormError = function (form) {
        if (!form.name) return 'Form must have a name.'
        if (form.name.length > 255) {
          form.isLengthValid = false
          return 'Ensure the form name have no more than 255 characters.'
        }
        if (!form.fields.length) return 'Form must have at least one field.'

        form.fields.map(function (field) {
          if (!field.group) return (field.group = 'NA')
        })
        countSameGroups(form)
        $scope.groupAmount = {}

        let count = 0
        $scope.currentId = 'NA'
        for (let i = 0; i < form.fields.length; ++i) {
          const field = form.fields[i]
          if ($scope.currentId != field.group) {
            $scope.groupAmount[$scope.currentId] = count
            if (
              $scope.currentId !== 'NA' &&
              $scope.groupAmount[$scope.currentId] !==
                $scope.countFields[$scope.currentId]
            ) {
              return 'You may not have any N/A or different grouping set between two of the same groupings.'
            }
            $scope.currentId = field.group
            count = 1
          } else {
            count++
          }

          if (
            field.type === 'radio' &&
            !(field.options[0].name && field.options[1].name)
          )
            return 'All radio options must have a name'

          if (!field.name) return 'All fields must have a name'
          if (field.name.length > 255 && field.type !== 'paragraph') {
            field.isLengthValid = false
            return 'Ensure all fields have no more than 255 characters.'
          }
          if (field.type === 'report_title' && !field.name)
            return 'You must enter a Report Title'

          if (field.type === 'image_reference' && !field.image_reference) {
            return 'Upload an reference image, please.'
          }
          if (field.type === 'options_multi' || field.type === 'options') {
            //Filter out the options that don't have a name and assign to master field.options list
            var filteredOptionList = field.options.filter(function (
              option,
              index,
            ) {
              if (index >= 1) {
                return option.name && option.name.length > 0
              } else {
                return option
              }
            })
            field.options = filteredOptionList
            if (
              field.type === 'options' &&
              field.options.filter(function (option) {
                return !!option.name
              }).length != field.options.length
            ) {
              return 'All options must have a name'
            }
            if (
              field.type === 'options_multi' &&
              field.options.filter(function (option) {
                return !!option.name
              }).length != field.options.length
            ) {
              return 'All options must have a name'
            }
            if (field.type === 'options' && !field.options.length) {
              return 'An options field must have at least one option.'
            }
            if (field.type === 'options_multi' && !field.options.length) {
              return 'An options field must have at least one option.'
            }
            if (
              field.type === 'options' &&
              field.options.filter(function (option) {
                if (option.name.length > 255) {
                  option.isLengthValid = false
                }
                return option.name.length > 255
              }).length > 0
            ) {
              return 'Ensure all options have no more than 255 characters.'
            }
            if (
              field.type === 'options_multi' &&
              field.options.filter(function (option) {
                if (option.name.length > 255) {
                  option.isLengthValid = false
                }
                return option.name.length > 255
              }).length > 0
            ) {
              return 'Ensure all options have no more than 255 characters.'
            }
          }
          if (field.type === 'nested_option' && !field.options.length) {
            return 'A nested field must have at least one option.'
          }
        }

        for (let i = 0; i < form.fields.length; ++i) {
          const field = form.fields[i]
          if (field.type === 'nested_option' && field.options.length > 0) {
            var nested_error = ''
            field.options.forEach(function (nested_option) {
              if (!nested_option.name)
                return (nested_error = 'All options must have a name')

              if (nested_option.name.length > 255) {
                nested_option.isLengthValid = false
                return (nested_error =
                  'Ensure all nested options have no more than 255 characters.')
              }

              if (nested_option.nested_fields.length) {
                nested_option.nested_fields.forEach(function (nested_field) {
                  if (!nested_field.name)
                    return (nested_error = 'All fields must have a name')
                  if (nested_field.name.length > 255) {
                    nested_field.isLengthValid = false
                    return (nested_error =
                      'Ensure all nested fields have no more than 255 characters.')
                  }

                  if (
                    nested_field.type === 'image_reference' &&
                    !nested_field.image_reference
                  ) {
                    return (nested_error = 'Upload an reference image, please.')
                  }
                  if (
                    nested_field.type === 'options_multi' ||
                    nested_field.type === 'options'
                  ) {
                    //Filter out the options that don't have a name and assign to master field.options list
                    var filteredOptionList = nested_field.answer_options.filter(
                      function (option, index) {
                        if (index >= 1) {
                          return option.name && option.name.length > 0
                        } else {
                          return option
                        }
                      },
                    )
                    nested_field.answer_options = filteredOptionList
                    if (
                      nested_field.type === 'options' &&
                      nested_field.answer_options.filter(function (option) {
                        return !!option.name
                      }).length != nested_field.answer_options.length
                    ) {
                      return (nested_error = 'All options must have a name')
                    }
                    if (
                      nested_field.type === 'options_multi' &&
                      nested_field.answer_options.filter(function (option) {
                        return !!option.name
                      }).length != nested_field.answer_options.length
                    ) {
                      return (nested_error = 'All options must have a name')
                    }
                    if (
                      nested_field.type === 'options' &&
                      !nested_field.answer_options.length
                    ) {
                      return (nested_error =
                        'An options field must have at least one option.')
                    }
                    if (
                      nested_field.type === 'options_multi' &&
                      !nested_field.answer_options.length
                    ) {
                      return (nested_error =
                        'An options field must have at least one option.')
                    }
                    if (
                      nested_field.type === 'options' &&
                      nested_field.answer_options.filter(function (option) {
                        if (option.name.length > 255) {
                          option.isLengthValid = false
                        }
                        return option.name.length > 255
                      }).length > 0
                    ) {
                      return (nested_error =
                        'Ensure all options have no more than 255 characters.')
                    }
                    if (
                      nested_field.type === 'options_multi' &&
                      nested_field.answer_options.filter(function (option) {
                        if (option.name.length > 255) {
                          option.isLengthValid = false
                        }
                        return option.name.length > 255
                      }).length > 0
                    ) {
                      return (nested_error =
                        'Ensure all options have no more than 255 characters.')
                    }
                  }
                })
              }
            })
            return nested_error
          }
        }

        return null
      }

      $scope.save = function () {
        $scope.error = $scope.getFormError($scope.form)
        $scope.saving = false
        if (!$scope.error) {
          $scope.saving = true
          _save()
        }
      }

      function updateGroup(key, mobileFormId, cb) {
        Api.Groups.patch(
          {
            id: $scope.currentKey,
            form: mobileFormId,
          },
          function () {
            cb()
          },
        )
      }

      $scope.formUUID = generateUUID()

      function _save() {
        $scope.saving = true
        const form_to_post = cloneDeep($scope.form)
        delete form_to_post.fields
        //Don't post the fields as part of the form.  The serializer doesn't process them and for large forms it causes the
        //Payload size to go over 128KB which gets blocked by the Azure WAF
        //The get sent to the API one-by-one after the form
        Api.MobileForms.post(
          _extends({}, form_to_post, {
            application: app.id,
            client: CurrentUser.getClientId(),
            web_creation_key: $scope.formUUID,
          }),
          function (resp) {
            const mobileFormId = resp.id

            async.each(
              Object.keys($scope.GROUP_SETTING),
              function (key, cb) {
                if (key === 'NA' || key === 'add_group') return cb()
                $scope.currentKey = key
                updateGroup($scope.currentKey, mobileFormId, cb)
              },
              function (err) {
                err && $log.error(err)

                async.eachOf(
                  $scope.form.fields,
                  function (field, i, callback) {
                    if (field.type === 'image_reference') {
                      field.image_reference = field.image_reference_info.id
                    }
                    field.group === 'NA' && (field.group = null)
                    field.group === 'add_group' && (field.group = null)
                    Api.Fields.post(
                      _extends({}, field, {
                        // order: i,
                        form: mobileFormId,
                      }),
                      function (resp) {
                        const fieldId = resp.id
                        if (field.options.length > 0) {
                          async.eachOf(
                            clearOptionCopies(field.options),
                            function (option, i, innerCallback) {
                              Api.FieldOptions.post(
                                _extends({}, option, {
                                  order: i,
                                  field: fieldId,
                                }),
                                function (resp) {
                                  if (
                                    option.nested_fields &&
                                    option.nested_fields.length > 0
                                  ) {
                                    async.eachOf(
                                      option.nested_fields,
                                      function (
                                        nested_field,
                                        i,
                                        nestedCallback,
                                      ) {
                                        if (
                                          nested_field.type ===
                                          'image_reference'
                                        ) {
                                          nested_field.image_reference =
                                            nested_field.image_reference_info.id
                                        }
                                        field.group &&
                                          (nested_field.group = field.group)
                                        nested_field.group === 'NA' &&
                                          (nested_field.group = null)
                                        nested_field.group === 'add_group' &&
                                          (nested_field.group = null)
                                        Api.Fields.post(
                                          _extends({}, nested_field, {
                                            order: i,
                                            form: mobileFormId,
                                            nested_option: resp.id,
                                          }),
                                          function (resp) {
                                            const fieldId = resp.id

                                            if (
                                              nested_field.answer_options
                                                .length > 0
                                            ) {
                                              async.eachOf(
                                                clearOptionCopies(
                                                  nested_field.answer_options,
                                                ),
                                                function (
                                                  option,
                                                  i,
                                                  innerNestedCallback,
                                                ) {
                                                  Api.FieldOptions.post(
                                                    _extends({}, option, {
                                                      order: i,
                                                      field: fieldId,
                                                    }),
                                                    function () {
                                                      return innerNestedCallback(
                                                        null,
                                                      )
                                                    },
                                                    function (error) {
                                                      $scope.saving = false
                                                      Notification.danger(error)
                                                    },
                                                  )
                                                },
                                                function (err) {
                                                  nestedCallback(err)
                                                },
                                              )
                                            } else {
                                              nestedCallback(null)
                                            }
                                          },
                                          function (error) {
                                            $scope.saving = false
                                            Notification.danger(error)
                                          },
                                        )
                                      },
                                      function (err) {
                                        innerCallback(err)
                                      },
                                    )
                                  } else {
                                    return innerCallback(null)
                                  }
                                },
                                function (error) {
                                  $scope.saving = false
                                  Notification.danger(error)
                                },
                              )
                            },
                            function (err) {
                              callback(err)
                            },
                          )
                        } else {
                          callback(null)
                        }
                      },
                      function (error) {
                        $scope.saving = false
                        Notification.danger(error)
                      },
                    )
                  },
                  function () {
                    $scope.goBack()
                  },
                )
              },
            )
          },
          function (error) {
            $scope.saving = false
            Notification.danger(error)
          },
        )
      }

      $scope.goBack = function () {
        $state.go('app.forms.list', {
          app: app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }
    },
  )
