angular
  .module('ccs')
  .controller('ObservationDashboard', function ($scope, Api, $log) {
    $log.debug('ObservationDashboard')

    $scope.app = null
    $scope.project = null
    $scope.user = null
    $scope.observations = []
    $scope.startDate = null
    $scope.endDate = null

    function observationQuery(page) {
      let q = {
        after_date: $scope.startDate,
        before_date: $scope.endDate,
        order: 'date_created',
        division: $scope.division,
        page_size: 50,
        page,
      }

      q.category = $scope.category ? $scope.category.id : null
      q.application = $scope.app ? $scope.app.id : null
      q.project = $scope.project ? $scope.project.id : null
      q.user = $scope.user ? $scope.user.id : null

      return q
    }

    function getObservations(cb, observations, page) {
      observations = observations || []
      page = page || 1

      Api.Observations.get(observationQuery(page), (resp) => {
        observations = observations.concat(resp.results)
        if (resp.next) return getObservations(cb, observations, ++page)
        return cb(observations)
      })
    }

    function setObservationScope(observations) {
      $scope.observations = observations
    }

    getObservations(setObservationScope)

    $scope.changePage = () => {
      getObservations(setObservationScope, [], 1)
    }
  })
