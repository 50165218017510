import * as React from 'react'
import { ButtonElement } from '@/react/components'
import { css } from '@emotion/react'
import closeIcon from '/src/assets/icons/X Gray.svg'
import { Dispatch, SetStateAction } from 'react'

type Props = {
  setAlert: Dispatch<SetStateAction<Element | null>>
  text: string
  submitButtonText: string
  optionalFunction?: () => void
}

export const UniversalAlert = ({
  setAlert,
  text,
  submitButtonText,
  optionalFunction = () => {},
}: Props) => {
  return (
    <section css={baseStyle} className="no-print">
      <div className="bg-holder">
        <div
          className="background"
          onClick={() => {
            setAlert([])
          }}
        />
      </div>
      <div className="alert">
        <div
          className="close-row"
          css={css({
            justifyContent: 'right',
          })}
        >
          <img
            src={closeIcon}
            alt="close"
            css={css({
              height: 25,
              cursor: 'pointer',
            })}
            onClick={() => {
              setAlert([])
            }}
          />
        </div>
        <div
          className="single-alert-row"
          css={css({
            textAlign: 'center',
            justifyContent: 'center',
            paddingBottom: 20,
            fontSize: 16,
            fontWeight: 600,
          })}
        >
          {text}
        </div>
        <div
          className="single-alert-row"
          css={css({ justifyContent: 'right' })}
        >
          <ButtonElement
            buttonType={'submit'}
            text={submitButtonText}
            functionToTrigger={() => {
              optionalFunction()
              setAlert([])
            }}
            width={'40%'}
            fontSize={'16px'}
          />
        </div>
      </div>
    </section>
  )
}

const baseStyle = css({
  position: 'fixed',
  display: 'flex',
  right: 0,
  top: 0,
  width: '100vw',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1000,
  '.alert': {
    position: 'static',
    backgroundColor: 'white',
    height: 'fit-content',
    width: 300,
    zIndex: 1000,
    '.single-alert-row': {
      justifyContent: 'center',
      width: '100%',
      display: 'flex',
    },
    '.close-row': {
      justifyContent: 'rigth',
      width: '100%',
      display: 'flex',
    },
  },
  '.bg-holder': {
    width: 0,
    height: 0,
    '.background': {
      position: 'fixed',
      display: 'flex',
      right: 0,
      top: 0,
      width: '100vw',
      height: '100vh',
      zIndex: 400,
    },
  },
})
