import * as React from 'react'
import { css } from '@emotion/react'
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace'

type Props = {
  columns: { object: ReactJSXElement; width: string }[]
}

export const TableRow: React.FC<Props> = ({
  columns,
  additionalStyles,
}: Props) => {
  if (!columns.length) return null
  return (
    <tr css={[rowHeader, rowStyle, additionalStyles]}>
      {columns.map((col: { object: ReactJSXElement }, idx: number) => {
        return (
          <td
            css={[tdStyle, css({ width: col.width || 'auto' })]}
            key={idx}
            className="centerText"
          >
            {col.object}
          </td>
        )
      })}
    </tr>
  )
}
const rowHeader = css({
  borderBottomStyle: 'solid',
  borderTopStyle: 'solid',
  borderColor: '#e7eaec',
  '.column': {
    borderRightStyle: 'solid',
    borderColor: '#e7eaec',
  },
  '.centerText': {
    textAlign: 'center',
  },
})

const rowStyle = css({
  backgroundColor: 'white',
  whiteSpace: 'nowrap',
})
const tdStyle = css({
  whiteSpace: 'nowrap',
  paddingRight: '10px',
  paddingLeft: '10px',
  maxHeight: '37px',
  border: 'none',
})
