angular
  .module('ccs')
  .controller(
    'CategoryGroupsCtrl',
    function (
      $scope,
      $state,
      $stateParams,
      Api,
      ngDialog,
      app,
      $uibModal,
      CurrentUser,
      $log,
    ) {
      $log.debug('CategoryGroupsCtrl')

      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1
      $scope.reverse = $stateParams.reverse
        ? !!JSON.parse(String($stateParams.reverse).toLowerCase())
        : false
      $scope.order = $stateParams.order ? $stateParams.order : 'name'
      $scope.search = $stateParams.search ? $stateParams.search : null
      $scope.searchExecuted = !!$scope.search
      $scope.app = app
      $scope.isClientOrAdmin = CurrentUser.isClientOrAdmin

      $scope.tableHeaders = [
        { key: 'name', display: 'Group Name', sortable: true },
        { key: 'edit', display: 'Edit/View ' + app.categories, centered: true },
        {
          key: 'is_default',
          display: 'Default',
          sortable: true,
          centered: true,
          glyph: 'glyphicon-question-sign',
        },
        { key: 'delete', display: 'Delete', centered: true },
      ]

      $scope.changePage = function () {
        Api.CategorySets.get(
          {
            application: app.id,
            client: CurrentUser.getClientId(),
            page: $scope.pageNumber,
            search: $scope.search,
            order: $scope.reverse ? '-' + $scope.order : $scope.order,
          },
          function (resp) {
            $scope.data = {
              items: resp.results,
              total: resp.count,
            }
          },
        )

        $state.transitionTo(
          'app.categories.groups',
          {
            app: app.id,
            pageNumber: $scope.pageNumber,
            order: $scope.order,
            reverse: $scope.reverse,
            search: $scope.searchExecuted ? $scope.search : null,
          },
          {
            notify: false,
          },
        )
      }

      $scope.changePage()

      $scope.del = (set) => {
        Api.CategorySets.delete(set, (resp) => {
          $scope.changePage()
        })
      }

      $scope.add = function () {
        ngDialog.open({
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          template: 'app/views/category_group_form.html',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.group = {
              application: app.id,
              client: CurrentUser.getClientId(),
            }

            $scope.create = function () {
              Api.CategorySets.post($scope.group, function (resp) {
                $scope.data.items.push(resp)
                $scope.closeThisDialog()
              })
            }
          },
        })
      }

      $scope.updateCategoryName = function (categoryEditing) {
        categoryEditing.isEditing = false
        Api.CategorySets.patch({
          id: categoryEditing.id,
          name: categoryEditing.name,
        })
      }

      $scope.editCategoryName = function (cagtegory) {
        cagtegory.isEditing = true
        $scope.editingCategoryWithName = cagtegory.name
      }

      $scope.cancelCategoryNameEdit = function (category) {
        category.name = $scope.editingCategoryWithName
        category.isEditing = false
        $scope.editingCategoryWithName = ''
      }

      $scope.update = function (obj) {
        Api.CategorySets.patch({ ...obj }, function (resp) {
          // success
        })
      }

      $scope.glyphClick = function (header) {
        if (header.key == 'is_default') {
          $scope.modal_title = 'DEFAULT GROUP'
          $scope.modal_text =
            'A default group will automatically turn on for all existing and future ' +
            app.projects.toLowerCase() +
            '.'
          $scope.informational_only = true
          var modalInstance = $uibModal.open({
            templateUrl: 'app/views/common/flexible_modal.html',
            scope: $scope,
            bindToController: true,
            /** @ngInject */
            controller: function controller($scope) {
              $scope.dismiss = function () {
                modalInstance.close()
              }
            },
          })
        }
      }

      $scope.changeChecked = function (obj) {
        $scope.already_has_default_group = false
        $scope.intended_state = obj.is_default
        // Check if there is a default already
        if (obj.is_default == false) {
          // Then it's being disabled.
          $scope.modal_title = 'GROUP CONFIRMATION'
          $scope.modal_text =
            'Are you sure you want to deactivate this default group? It will no longer be turned on for new ' +
            app.projects.toLowerCase() +
            ' by default.'
          $scope.informational_only = false
          // Re-check it so it still shows as checked in the modal
          obj.is_default = true
        } else {
          // Then it's being enabled

          // First, check to see if there is default group already.
          for (var i = 0; i < $scope.data.items.length; i++) {
            if ($scope.data.items[i].is_default == true) {
              if ($scope.data.items[i].id != obj.id) {
                $scope.already_has_default_group = true
                //Uncheck it so that it doesn't show as checked behind the modal
                obj.is_default = false
                break
              }
            }
          }

          // Configure the flexible modal based on whether or not there is a default group already
          if ($scope.already_has_default_group == true) {
            $scope.modal_title = 'GROUP NOTIFICATION'
            $scope.modal_text =
              'You may only have one default group active at a time. Please disable the other group first.'
            $scope.informational_only = true
          } else {
            $scope.modal_title = 'GROUP CONFIRMATION'
            $scope.modal_text =
              'Are you sure you want to make this group active for all existing and future ' +
              app.projects.toLowerCase() +
              '?'
            $scope.informational_only = false
          }
        }

        var modalInstance = $uibModal.open({
          templateUrl: 'app/views/common/flexible_modal.html',
          scope: $scope,
          backdrop: true,
          bindToController: true,
          /** @ngInject */
          controller: function controller($scope) {
            $scope.dismiss = function () {
              // Revert the value of the box that was checked
              if ($scope.already_has_default_group == true) {
                // Don't do anything.  We already unchecked it when we made this determination.
              } else {
                // Then the user is dismissing the modal because they don't actually
                // want the group to be active.  Revert the check on the UI.
                obj.is_default = !$scope.intended_state
              }
              modalInstance.close()
            }

            $scope.save = function () {
              obj.is_default = $scope.intended_state
              $scope.update({
                id: obj.id,
                is_default: obj.is_default,
              })
              modalInstance.close()
            }
          },
          resolve: {
            obj: function () {
              return obj
            },
          },
        })

        modalInstance.result.catch(function () {
          obj.is_default = !$scope.intended_state
        })
      }
    },
  )
