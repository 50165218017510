import pluralize from 'pluralize'

angular
  .module('ccs')
  .controller(
    'FormAssignSetCtrl',
    function ($scope, Api, $stateParams, app, $state, CurrentUser, $log) {
      $log.debug('FormAssignSetCtrl')

      $scope.titleAssignMobileToMobiles = app.app_mobile_form_display
      $scope.titleMobileForm = pluralize.singular(app.app_mobile_form_display)
      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.mobileForms = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.formSet = {}
      $scope.reverse = false
      $scope.order = 'name'
      $scope.app = app
      $scope.breadcrumbs = [
        {
          title: app.name,
        },
        {
          title: $scope.titleMobileForm + ' Groups',
          url: 'app.forms.custom',
        },
      ]

      $scope.tableHeaders = [
        {
          key: 'name',
          display: $scope.titleMobileForm + ' Name',
          sortable: true,
        },
        { key: 'active_for_group', display: 'Inactive/Active', sortable: true, toggleAllBtn: true },
      ]

      var formSetId = $stateParams.formSet

      function getFormSets() {
        Api.FormSets.byID(formSetId, function (resp) {
          $scope.formSet = resp
          getMobileForms()
        })
      }

      getFormSets()

      function mobileFormQuery() {
        var query = {
          application: app.id,
          client: CurrentUser.getClientId(),
          client_id: CurrentUser.getClientId(),
          page: $scope.pageNumber,
          search: $scope.search,
          is_active: 'True',
          annotate_active_form_group: formSetId,
        }
        if ($scope.order)
          query.order = $scope.reverse ? '-' + $scope.order : $scope.order
        return query
      }

      function getMobileForms() {
        Api.MobileForms.get(mobileFormQuery(), function (resp) {
          $scope.totalItems = resp.count
          $scope.mobileForms = resp.results.map(function (mobileForm) {
            mobileForm.active_for_group =
              $scope.formSet.mobile_forms.indexOf(mobileForm.id) > -1
            return mobileForm
          })
        })
      }

      $scope.changePage = () => {
        getMobileForms()
      }

      $scope.toggleAll = () => {
        Api.post(
          `client_form_sets/${$scope.formSet.id}/assign_all_mobile_forms`,
          {
            id: $scope.formSet.id,
            application: app.id,
            on: $scope.active,
            search: $scope.search,
          },
          () => {
            getFormSets()
          },
        )
      }

      $scope.goBack = () => {
        $state.go('app.forms.custom', {
          app: app.id,
          pageNumber: $scope.previousPageNumber,
          order: $scope.previousOrder,
          reverse: $scope.previousReverse,
          search: $scope.previousSearch,
        })
      }

      $scope.toggleActive = function (mobileForm) {
        if (mobileForm.active_for_group) {
          $scope.formSet.mobile_forms.push(mobileForm.id)
        } else {
          $scope.formSet.mobile_forms = $scope.formSet.mobile_forms.filter(
            function (mf) {
              return mf != mobileForm.id
            },
          )
        }

        Api.FormSets.patch(
          {
            id: $scope.formSet.id,
            mobile_forms: $scope.formSet.mobile_forms,
          },
          function (resp) {},
        )
      }
    },
  )
