import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { css } from '@emotion/react'
import { useMemo, useState } from 'react'

export const AssignSwitch = () => {
  const { stateService } = useRouter()
  const checked =
    stateService.current.name === 'app.toolboxes.toolbox_to_project'

  const { $rootScope, CurrentUser } = useAngularServices()

  const configurableNamesList = useMemo(() => {
    return $rootScope.mobile_apps.find(
      (obj) => obj.id === Number(stateService.params.app),
    )
  }, [$rootScope])

  const variableColor = CurrentUser.getClientSettings().web_primary_color

  const baseStyle = getBaseStyle(variableColor)

  return (
    <div css={baseStyle} className="switch__wrapper">
      <h4 className="page__text">Templates</h4>
      <label className="switch">
        <input
          className="projectCheckbox"
          type="checkbox"
          checked={checked}
          onChange={() => {
            stateService.go(
              checked
                ? 'app.toolboxes.project_to_toolbox'
                : 'app.toolboxes.toolbox_to_project',
            )
          }}
        />
        <span className="slider round" />
      </label>
      <h4 className="page__text">{configurableNamesList.projects_display}</h4>
    </div>
  )
}

const getBaseStyle = (variableColor) =>
  css({
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '.slider': {
      border: `1px solid ${variableColor} !important`,
      backgroundColor: `${variableColor} !important`,
    },
    '.switch': {
      margin: 0,
    },
    '.page__text': {
      fontSize: '12px',
      fontWeight: 700,
      margin: 0,
    },
  })
