angular
  .module('ccs')
  .controller(
    'ProjectEditUsersCtrl',
    function ($scope, Api, $stateParams, CurrentUser, $log) {
      $log.debug('ProjectEditUsersCtrl')

      $scope.data = { items: [], total: 0 }
      $scope.pageNumber = 1
      $scope.reverse = false
      $scope.order = 'first_name'

      $scope.tableHeaders = [
        { key: 'first_name', display: 'Full Name', sortable: true },
        { key: 'username', display: 'Username', sortable: true },
        { key: 'is_active', display: 'Assign', sortable: true, toggleAllBtn: true },
      ]
      $scope.getclientSettings = CurrentUser.getClientSettings

      function query() {
        let q = {
          page: $scope.pageNumber,
          search: $scope.search,
          project_id: $stateParams.projectID,
          name_search_only: true,
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      function get() {
        Api.Projects.byID($stateParams.projectID, function (resp) {
          $scope.project = resp
          $scope.breadcrumbs = [
            { title: 'Administration' },
            {
              title: CurrentUser.getClientSettings().project_language,
              url: 'admin.projects.list',
            },
            { title: resp.name },
          ]

          Api.Users.get(query(), function (response) {
            response.results = response.results.map(function (u) {
              u.has_project = $scope.project.users.indexOf(u.id) >= 0
              return u
            })
            $scope.data = {
              items: response.results,
              total: response.count,
            }
          })
        })
      }

      get()

      $scope.changePage = function () {
        get()
      }

      $scope.toggleAll = () => {
        Api.post(
          `projects/${$stateParams.projectID}/assign_all_users`,
          {
            id: $stateParams.projectID,
            on: $scope.active,
            search: $scope.search,
          },
          () => {
            get()
          },
        )
      }

      $scope.toggleActive = function (user) {
        var project = $scope.project
        var data = { id: project.id }

        if (user.has_project) {
          project.users.push(user.id)
        } else {
          project.users = project.users.filter(function (u) {
            return u != user.id
          })
        }

        data.users = project.users
        Api.Projects.patch(data, function (resp) {})
      }

      $scope.$watch('q', function (newValue, oldValue) {
        if (oldValue === /** @type {boolean} */ newValue) return

        fetchAll({ username: newValue })
      })
    },
  )
