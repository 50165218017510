angular
  .module('ccs')
  .directive('selectprocorecompany', selectProcoreCompany)
  .directive('selectprocoreproject', selectProcoreProject)
  .directive('procoreprojecttable', procoreProjectTable)

function selectProcoreCompany(Procore) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/procore_select_company.html',
    scope: {
      company: '=',
    },
    link: function ($scope) {
      $scope.companies = []

      function getCompanies() {
        Procore.getCompanies((resp) => {
          $scope.companies = resp
        })
      }

      getCompanies()
    },
  }
}

function selectProcoreProject(Procore) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/procore_select_project.html',
    scope: {
      company: '=',
      project: '=',
    },
    link: function ($scope) {
      $scope.projects = []

      function getProjects() {
        Procore.getProjects($scope.company.id, (resp) => {
          $scope.projects = resp
        })
      }

      getProjects()
    },
  }
}

function procoreProjectTable(Procore) {
  return {
    restrict: 'E',
    templateUrl: 'app/views/common/procore_project_table.html',
    scope: {
      company: '=',
    },
    link: function ($scope) {
      function getProjects() {
        Procore.getProjects($scope.company.id, (resp) => {
          $scope.projects = resp.map((p) => {
            p.selected = false
            return p
          })
        })
      }

      getProjects()

      $scope.submit = () => {
        $scope.$parent.importProjects($scope.projects.filter((p) => p.selected))
      }
    },
  }
}
