import { css } from '@emotion/react'
import React from 'react'

export const RightArrowIcon = ({ color, onClick }) => {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      css={[css({ color: color })]}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M0.477498 19.9999C0.383333 20.0001 0.291227 19.9724 0.212803 19.9203C0.13438 19.8681 0.073153 19.794 0.0368486 19.7071C0.000544231 19.6202 -0.00921117 19.5245 0.00881437 19.4321C0.0268399 19.3396 0.071838 19.2546 0.138131 19.1878L9.33535 9.97909L0.138131 0.793313C0.0572055 0.702279 0.0142026 0.583718 0.0179506 0.461972C0.0216986 0.340226 0.0719137 0.224535 0.158285 0.138651C0.244657 0.0527672 0.360631 0.0032086 0.482396 0.000150412C0.604161 -0.00290777 0.722477 0.0407665 0.813051 0.122206L10.3458 9.65498C10.3902 9.69925 10.4254 9.75185 10.4495 9.80976C10.4735 9.86767 10.4859 9.92975 10.4859 9.99244C10.4859 10.0551 10.4735 10.1172 10.4495 10.1751C10.4254 10.233 10.3902 10.2856 10.3458 10.3299L0.813051 19.8627C0.769287 19.907 0.716993 19.9421 0.659323 19.9656C0.601652 19.9892 0.5398 20.0009 0.477498 19.9999Z"
        style={{ fill: 'currentcolor' }}
      />
    </svg>
  )
}
