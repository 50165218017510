import { EditIcon } from '@/react/componentAssets'
import { useAngularServices } from '@/react/components'
import { useRouter } from '@/react/hooks'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import Highlighter from 'react-highlight-words'

export const EmployeeRow = ({ employee, index, search }) => {
  const { CurrentUser, $rootScope } = useAngularServices()
  const variableColor = CurrentUser.getClientSettings().web_primary_color
  const { stateService } = useRouter()
  const [hoveredField, setHoveredField] = useState(null)

  const { name, active_jobs_count, first_name, last_name } = employee
  const isGroup = employee.hasOwnProperty('users')

  return (
    <tr
      className={classNames(isGroup ? 'group__row' : 'project__row', {
        dark: index % 2 === 0 && !isGroup,
        light: index % 2 === 0 && isGroup,
      })}
    >
      <td
        className="name__cell"
        onMouseOver={() => {
          setHoveredField({
            field__name: 'name__fullname',
            text: name || `${first_name} ${last_name}`,
            max__length: 112,
          })
        }}
        onMouseOut={() => {
          setHoveredField(null)
        }}
      >
        <Highlighter
          highlightClassName="highlighted__text"
          searchWords={[search]}
          autoEscape={true}
          textToHighlight={name || `${first_name} ${last_name}`}
        />
      </td>
      <td>
        <div
          className="edit__cell"
          onClick={() => {
            stateService.go(
              'trainings.assign.training_assign',
              !isGroup
                ? {
                    user: employee?.id,
                  }
                : {
                    userSet: employee?.id,
                  },
            )
          }}
        >
          <EditIcon color={variableColor} height="17px" />
          <p className="projects__count">{active_jobs_count}</p>
        </div>
      </td>
      {hoveredField?.text?.length > hoveredField?.max__length && (
        <div className={`full__name ${hoveredField?.field__name}`}>
          {hoveredField?.text}
        </div>
      )}
    </tr>
  )
}
