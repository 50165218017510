import { css } from '@emotion/react'
import React from 'react'

export const DownArrowIcon = ({ color, onClick }) => {
  return (
    <svg
      width="20"
      height="11"
      viewBox="0 0 20 11"
      css={[css({ color: color })]}
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M5.47302e-05 0.477498C-0.000114922 0.383333 0.0276102 0.291227 0.079734 0.212803C0.131858 0.13438 0.206044 0.073153 0.29293 0.0368486C0.379816 0.000544231 0.47551 -0.00921117 0.567934 0.00881437C0.660358 0.0268399 0.74537 0.071838 0.812246 0.138131L10.0209 9.33535L19.2067 0.138131C19.2977 0.0572055 19.4163 0.0142026 19.538 0.0179506C19.6598 0.0216986 19.7755 0.0719137 19.8613 0.158285C19.9472 0.244657 19.9968 0.360631 19.9998 0.482396C20.0029 0.604161 19.9592 0.722477 19.8778 0.813051L10.345 10.3458C10.3007 10.3902 10.2481 10.4254 10.1902 10.4495C10.1323 10.4735 10.0703 10.4859 10.0076 10.4859C9.94487 10.4859 9.88279 10.4735 9.82488 10.4495C9.76697 10.4254 9.71438 10.3902 9.6701 10.3458L0.137327 0.813051C0.0929734 0.769287 0.0579435 0.716993 0.034351 0.659323C0.0107584 0.601652 -0.000908005 0.5398 5.47302e-05 0.477498Z"
        style={{ fill: 'currentcolor' }}
      />
    </svg>
  )
}
