import * as React from 'react'
import { css, SerializedStyles } from '@emotion/react'

type Props = {
  height: string
  width?: string
  color?: string
  additionalStyles?: SerializedStyles
  onClick?: any
}

export const ShareIcon = ({
  height = '20px',
  width,
  color,
  onClick = () => {},
  additionalStyles,
}: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="12 12 58 58"
      css={[
        css({ height: height, width: width ? width : 'auto', color: color }),
        additionalStyles,
      ]}
      onClick={onClick}
    >
      <g id="icons">
        <path
          className="cls-1"
          style={{ fill: 'currentcolor' }}
          d="M67.4,25.8a1.24,1.24,0,0,0-.67-.68,1.36,1.36,0,0,0-.48-.09h-10a1.25,1.25,0,0,0,0,2.5h7L45.37,45.4a1.24,1.24,0,0,0,0,1.76,1.23,1.23,0,0,0,1.76,0L65,29.3v7a1.25,1.25,0,0,0,2.5,0v-10A1.15,1.15,0,0,0,67.4,25.8Z"
        />
        <path
          className="cls-1"
          style={{ fill: 'currentcolor' }}
          d="M58.25,57.5h-34A9.26,9.26,0,0,1,15,48.25v-14A9.26,9.26,0,0,1,24.25,25h22a1.25,1.25,0,0,1,0,2.5h-22a6.76,6.76,0,0,0-6.75,6.75v14A6.76,6.76,0,0,0,24.25,55h34A6.76,6.76,0,0,0,65,48.25v-2a1.25,1.25,0,0,1,2.5,0v2A9.26,9.26,0,0,1,58.25,57.5Z"
        />
      </g>
    </svg>
  )
}
