import * as React from 'react'
import { useAngularServices, getFilterParam } from '@/react/components'
import { useRouter } from '@/react/hooks'
import { TimeSheetCustomTableRow } from '@screens/Timecard/Timesheet/components/TimeSheetCustomTableRow'
import { encodeString } from '@/utils/additionalEncodeLogic'

export const TimeSheetTableBody = ({
  page,
  search,
  order,
  period,
  setCount,
  advancedFilters,
  voidPage = false,
  reloadCloseAll,
  voidedCount,
  setVoidedCount,
}) => {
  const { Api } = useAngularServices()
  const { stateService } = useRouter()

  const [list, setList] = React.useState<object[]>([])

  let _isMounted = false

  React.useEffect(() => {
    // componentWillMount
    _isMounted = true
    // componentWillUnmount
    return () => {
      // Your code here
      _isMounted = false
    }
  }, [])

  async function fetchData() {
    const params = {
      page: page,
      order: order,
      period: period,
      deleted: voidPage,
      application: stateService.params.app,
    }
    if (search) params.search = search

    let stringParams = ''

    Object.entries(params).forEach((param) => {
      stringParams += param[0] + '=' + encodeString(param[1], false) + '&'
    })

    Object.entries(advancedFilters).forEach((param) => {
      if (param[1].type && param[1].string) {
        stringParams +=
          getFilterParam(
            param[0],
            param[1].type,
            param[1].string,
            param[1].secondType,
            param[1].secondString,
          ) + '&'
      }
    })

    const { data } = await Api.getWithoutDeletedParam(
      'timesheets',
      stringParams.slice(0, -1),
    )

    if (_isMounted) {
      setList(data.results)
      setCount(data.count)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [page, search, order, advancedFilters, period])

  return (
    <tbody>
      {list.map((row, idx) => (
        <TimeSheetCustomTableRow
          key={idx}
          idx={idx}
          startingRowData={row}
          voidPage={voidPage}
          reloadCloseAll={reloadCloseAll}
          voidedCount={voidedCount}
          setVoidedCount={setVoidedCount}
        />
      ))}
    </tbody>
  )
}
