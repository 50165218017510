import FileSaver from 'file-saver'

export function downloadFileFromUrl(url) {
  const a = document.createElement('a')
  a.href = url
  a.style = 'display: none'
  a.download = ''

  document.body.appendChild(a)
  a.click()
  a.remove()
}

export function saveBase64AsFile(base64, fileName, mimeType) {
  const binary = window.atob(base64)
  const len = binary.length
  const bytes = new Uint8Array(len)
  for (let i = 0; i < len; i++) {
    bytes[i] = binary.charCodeAt(i)
  }
  const file = new File([bytes.buffer], fileName, { type: mimeType })
  FileSaver.saveAs(file)
}

export function trimFileName(filename, numbersAtStart) {
  if (filename.length > 12) {
    return (
      filename.substring(0, numbersAtStart) + '...' + filename.split('.').pop()
    )
  } else {
    return filename
  }
}
