export async function deletedFieldsFiltering(answerBeforeChanges, setAnswer) {
  const changingAnswer = {
    ...answerBeforeChanges,
    answer_party_observed_answer_list: [],
    corrective_actions: [],
    notes: [],
  }
  answerBeforeChanges?.answer_party_observed_answer_list?.forEach((po) => {
    if (!po?.deleted) changingAnswer?.answer_party_observed_answer_list.push(po)
  })
  answerBeforeChanges?.corrective_actions?.forEach((ca) => {
    if (!ca?.deleted) changingAnswer?.corrective_actions.push(ca)
  })
  answerBeforeChanges?.notes?.forEach((note) => {
    if (!note?.deleted) changingAnswer?.notes.push(note)
  })
  setAnswer(changingAnswer)
}
