angular
  .module('ccs')
  .controller(
    'EmployeeGroupListCtrl',
    function ($scope, Api, $state, $stateParams, ngDialog, CurrentUser, $log) {
      $log.debug('EmployeeGroupListCtrl')

      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.previousReverse = $stateParams.previousReverse
        ? $stateParams.previousReverse
        : false
      $scope.previousOrder = $stateParams.previousOrder
        ? $stateParams.previousOrder
        : 'name'
      $scope.previousSearch = $stateParams.previousSearch
        ? $stateParams.previousSearch
        : null

      $scope.employeesList = []
      $scope.pageNumber = 1
      $scope.totalItems = 0
      $scope.reverse = false
      $scope.order = 'name'
      $scope.maxSize = 10 //Number of pager buttons to show
      $scope.pageNumber = $stateParams.pageNumber ? $stateParams.pageNumber : 1

      $scope.pageSize = 20

      function query() {
        let q = {
          page: $scope.pageNumber,
          page_size: $scope.pageSize,
          search: $scope.search,
          is_active: 'True',
        }

        if ($scope.order)
          q.order = $scope.reverse ? '-' + $scope.order : $scope.order

        return q
      }

      var getEmployeesGroupList = function getProcedures() {
        Api.EmployeesGroupList.get(query(), function (resp) {
          $scope.employeesGroupList = resp.results.map(function (group) {
            group.isEditing = false
            return group
          })
          $scope.totalItems = resp.count
        })
      }

      getEmployeesGroupList()

      $scope.changePage = function () {
        getEmployeesGroupList()
      }

      $scope.addNewEmployeeGroup = function () {
        // var $scp = this.$new(true);
        ngDialog.open({
          template: 'app/views/add_new_employee_group_dialog.html',
          scope: $scope,
          className: 'ngdialog-theme-default custom-content',
          /** @ngInject */
          controller: function controller($scope) {
            $scope.disabledButton = false
            $scope.employeeGroup = {
              name: null,
            }

            $scope.save = function () {
              $scope.disabledButton = true
              Api.EmployeesGroupList.post(
                {
                  name: $scope.employeeGroup.name,
                  client: CurrentUser.getClientId(),
                },
                function (resp) {
                  $scope.closeThisDialog()
                  getEmployeesGroupList()
                },
              )
            }

            $scope.close = function () {
              $scope.closeThisDialog()
            }
          },
        })
      }

      $scope.updateEmployeeGroupName = function (groupEditing) {
        groupEditing.isEditing = false
        Api.patch(`user_sets/${groupEditing.id}/?deleted=False`, {
          name: groupEditing.name,
        })
      }

      $scope.editEmployeeGroupName = function (group) {
        group.isEditing = true
        $scope.editingEmployeeGroupWithName = group.name
      }

      $scope.cancelEmployeeGroupNameEdit = function (group) {
        group.name = $scope.editingEmployeeGroupWithName
        group.isEditing = false
        $scope.editingEmployeeGroupWithName = ''
      }

      $scope.delete = function (employee) {
        Api.EmployeesGroupList.delete(employee, (resp) => {
          getEmployeesGroupList()
        })
      }
    },
  )
