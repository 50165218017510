import React, { useEffect, useState } from 'react'
import moment from 'moment'
import pluralize from 'pluralize'
import { CommonPageHeader, useAngularServices } from '@/react/components'
import { css } from '@emotion/react'
import { SectionHeader } from './SectionHeader'
import { ReportButtons } from './ReportButtons'
import { useRouter } from '@/react/hooks'
import { DocumentStyles } from '@screens/components'

export const ViewReport = () => {
  const { stateService } = useRouter()
  const { $rootScope, Api, CurrentUser } = useAngularServices()
  const [reportData, setReportData] = useState({})

  const app = $rootScope.mobile_apps.find((obj) => {
    return obj.id === Number(stateService.params.app)
  })
  const appProject: string = pluralize.singular(app.projects_display)

  useEffect(() => {
    Api.TimecardDailyReports.byID(stateService.params.id, function (resp) {
      if (resp) {
        setReportData(resp)
      }
    })
  }, [])

  const formatHoursWorked = (time: string) => {
    const splittedTime = time?.split(':')

    if (splittedTime[1] === '00') {
      return moment(time, 'HHmmss').format('H')
    }

    const newTime = splittedTime.map((el, idx) => {
      if (idx === 1) {
        return String(Math.round(Number(el) / 0.6))
      }
      return el
    })

    return moment(newTime.join(':'), 'HHmmss').format('H.mm')
  }

  return (
    <>
      <CommonPageHeader headerText="View Daily Report" />
      <div css={baseStyle} className="row white-bg">
        <div className="container">
          <div className="icon">
            <img src={CurrentUser?.getClientCompany()?.logo_url} />
          </div>
          <h2 className="report-title">DAILY REPORT</h2>
          <SectionHeader title={`${appProject} Information`} />
          <div className="job-info-wrapper">
            <div className="col">
              <div className="info-row">
                <div className="field">{`${appProject} Number`}:</div>
                <div className="value">{reportData?.project?.number}</div>
              </div>
              <div className="info-row">
                <div className="field">{appProject} Name:</div>
                <div className="value">{reportData?.project?.name}</div>
              </div>
              <div className="info-row">
                <div className="field">Address:</div>
                <div className="value">{reportData?.project?.address}</div>
              </div>
            </div>
            <div className="col">
              <div className="info-row">
                <div className="field">Date:</div>
                <div className="value">{reportData?.date}</div>
              </div>
              <div className="info-row">
                <div className="field">Customer:</div>
                <div className="value">{reportData?.project?.client_name}</div>
              </div>
              <div className="info-row">
                <div className="field">Prepared By:</div>
                <div className="value">
                  {reportData?.user?.first_name} {reportData?.user?.last_name}
                </div>
              </div>
            </div>
          </div>
          <SectionHeader title="Comments" />
          <div className="comments-wrapper">
            <div className="info-row">
              <div className="field">Weather Description:</div>
              <div className="value dont-cut-spaces capitalize">
                {reportData?.weather}
              </div>
            </div>
            <div className="info-row">
              <div className="field">Description of Work:</div>
              <div className="value dont-cut-spaces capitalize">
                {reportData?.work}
              </div>
            </div>
          </div>
          <SectionHeader title="Crews & Hours Worked" />
          <div className="crew-hours-wrapper">
            <table className="table-wrapper">
              <thead>
                <tr>
                  <th className="tal">Employee Name</th>
                  <th className="tac">Employee Classification</th>
                  <th className="tar">Hours Worked:</th>
                </tr>
              </thead>
              <tbody>
                {reportData?.crews?.length
                  ? reportData.crews.map((crew, idx) => (
                      <tr key={idx}>
                        <td className="tal">
                          {crew.user.first_name} {crew.user.last_name}
                        </td>
                        <td className="tac">{crew.crew_category.name}</td>
                        <td className="tar">
                          {formatHoursWorked(crew.hours_worked)}
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>
            <div className="sum-wrapper">
              <div className="field">
                Total Number of Workers: {reportData?.total_workers}
              </div>
              <div className="field">
                Total Hours: {reportData?.total_hours}
              </div>
            </div>
          </div>
          <div className="pageBreakInsideAvoid">
            <SectionHeader title="Prepared By" />
            <div className="prepared-wrapper">
              <div className="signature-wrapper">
                <img src={reportData.signature?.image} />
              </div>
              <div className="printed-name">{reportData?.user_name}</div>
            </div>
          </div>
          <ReportButtons />
        </div>
      </div>
    </>
  )
}

const baseStyle = css({
  ...DocumentStyles,
})
