import async from 'async'

import { _extends } from '@/utils'

angular
  .module('ccs')
  .controller(
    'QuizEditCtrl',
    function ($scope, Api, $state, $stateParams, $log) {
      $log.debug('QuizEditCtrl')

      //For for restoring pagination
      $scope.previousPageNumber = $stateParams.previousPageNumber
        ? $stateParams.previousPageNumber
        : 1
      $scope.trainingID = $stateParams.trainingID
      // $scope.app = app;

      $scope.contentId = $stateParams.contentId
      $scope.topicId = $stateParams.topicId

      $scope.disabled_btn = false

      Api.TopicContent.byID($scope.contentId, (resp) => {
        $scope.original = resp
        $scope.form = {
          ...resp,
          id: null,
          fields: resp.fields.map((field) => {
            return {
              ...field,
              id: null,
              tempId: field.order,
              objType: 'field',
              order: null,
              options: field.answer_options.map((option) => {
                return {
                  ...option,
                  id: null,
                  tempId: option.order,
                  objType: 'option',
                  order: null,
                }
              }),
            }
          }),
        }
      })

      $scope.FIELD_TYPES = {
        multi: 'Multiple Choice',
        true_false: 'True/False',
      }

      $scope.allowableFields = ['field']
      $scope.allowableOptions = ['option']

      $scope.clearOptionsFields = function (type, i) {
        // var optionsArr = $scope.form.fields[i].options;
        if (type === 'multi') {
          // optionsArr.forEach(function(option) {
          //   option.name = '';
          // });
          $scope.form.fields[i].options = [
            {
              name: '',
              answer: false,
              field: $scope.form.fields[i].tempId,
              tempId: 0,
              objType: 'option',
            },
          ]
        } else if (type === 'true_false') {
          $scope.form.fields[i].options = [
            {
              name: 'True',
              field: $scope.form.fields[i].tempId,
              answer: false,
              tempId: 0,
              objType: 'option',
            },
            {
              name: 'False',
              field: $scope.form.fields[i].tempId,
              answer: true,
              tempId: 1,
              objType: 'option',
            },
          ]
        }
      }

      $scope.addField = function () {
        $scope.form.fields.push({
          type: 'multi',
          name: '',
          options: [],
          objType: 'field',
          required: true,
          tempId: $scope.form.fields.length + 1,
        })
      }

      $scope.addOption = function (field) {
        field.options.push({
          name: '',
          answer: false,
          field: '',
          tempId: field.options.length + 1,
          objType: 'option',
        })
      }

      $scope.addRadioOption = function (field) {
        if (field.type === 'true_false' && !field.options.length) {
          field.options = [
            {
              name: 'True',
              field: field.tempId,
              answer: false,
              tempId: field.options.length + 1,
              objType: 'option',
            },
            {
              name: 'False',
              field: field.tempId,
              answer: true,
              tempId: field.options.length + 1,
              objType: 'option',
            },
          ]
        }
      }

      $scope.setAnswer = function (current_option, field) {
        current_option.field = field.fieldId

        field.options.forEach(function (option, k) {
          if (
            current_option.name === option.name &&
            current_option.tempId === option.tempId
          ) {
            option.answer = true
          } else {
            option.answer = false
          }
        })
      }

      $scope.removeOption = function (field, option) {
        field.options = field.options.filter(function (o) {
          return o.tempId != option.tempId
        })
        field.options = field.options.map(function (o, i) {
          return _extends({}, o, {
            tempId: i + 1,
          })
        })
      }

      $scope.removeField = function (fieldToRemove) {
        $scope.form.fields = $scope.form.fields.filter(function (field) {
          return fieldToRemove.tempId != field.tempId
        })

        $scope.form.fields = $scope.form.fields.map(function (field, i) {
          return _extends({}, field, {
            tempId: i + 1,
          })
        })
      }

      $scope.getFormError = function (form) {
        if (!form.name) return 'Form must have a name.'
        if (!(form.quiz_passing_score >= 0))
          return 'Form must have a passing score.'
        if (!form.fields.length) return 'Form must have at least one field.'

        //Filter out form fields that don't have a name

        for (var i = 0; i < form.fields.length; ++i) {
          const field = form.fields[i]
          if (!field.name) return 'All fields must have a name'
          if (field.type === 'multi' && !field.options.length) {
            return 'A multiple choice field must have at least one option.'
          }

          if (
            field.type === 'multi' &&
            field.options.filter(function (option) {
              return !!option.name
            }).length != field.options.length
          ) {
            return 'All options must have a name'
          }

          if (
            field.type === 'multi' &&
            field.options.filter(function (option) {
              return option.answer == true
            }).length <= 0
          ) {
            return 'You should select one correct options'
          }
          if (
            field.type === 'multi' &&
            field.options.filter(function (option) {
              return option.answer == true
            }).length > 1
          ) {
            return 'A multiple choice field must have only one correct option'
          }
        }

        return null
      }

      $scope.save = function () {
        $scope.error = $scope.getFormError($scope.form)
        if (!$scope.error) _save()
      }

      $scope.goBack = function () {
        $state.go(
          'trainings.topic',
          {
            trainingID: $scope.trainingID,
            pageNumber: $scope.previousPageNumber,
          },
          { reload: true },
        )
      }

      function _save() {
        $scope.disabled_btn = true
        Api.TopicContent.delete({ id: $scope.contentId }, function (resp) {
          Api.TopicContent.post(
            {
              topic: $scope.topicId,
              name: $scope.form.name,
              quiz_name: $scope.form.name,
              content_type: 'quiz',
              completion_code: null,
              description: null,
              quiz_passing_score: $scope.form.quiz_passing_score,
              order: $stateParams.topicOrder,
            },
            function (resp) {
              var fieldId = resp.id
              async.eachOf(
                $scope.form.fields,
                function (field, i, callback) {
                  Api.TrainingQuizFields.post(
                    _extends({}, field, {
                      order: i,
                      content: fieldId,
                    }),
                    function (resp) {
                      var fieldOptionId = resp.id

                      if (field.options.length) {
                        async.eachOf(
                          field.options,
                          function (option, i, innerCallback) {
                            Api.TrainingQuizFieldOptions.post(
                              _extends({}, option, {
                                order: i,
                                field: fieldOptionId,
                              }),
                              function (resp) {
                                return innerCallback(null)
                              },
                            )
                          },
                          function (err) {
                            callback(err)
                          },
                        )
                      } else {
                        callback(null)
                      }
                    },
                  )
                },
                function (err) {
                  $scope.disabled_btn = false
                  $scope.goBack()
                },
              )
            },
          )
        })
      }
    },
  )
