import { makeLoadResolver, resolvers } from '../resolvers'

/** @ngInject */
export function appsToolboxesConfig($stateProvider) {
  $stateProvider.state('app.toolboxes', {
    abstract: true,
    data: { pageTitle: 'Toolbox Talks' },
    templateUrl: 'app/views/common/uiview.html',
  })
  // .state('app.toolboxes.deleted', {
  //   controller: 'ToolboxesDeletedCtrl',
  //   templateUrl: 'app/views/deleted_toolboxes.html',
  //   url: '/toolboxes/deleted?pageNumber&order&reverse&search',
  //   resolve: {
  //     ...resolvers.appResolver,
  //     ...resolvers.PreviousStateResolver,
  //   },
  // })
  // .state('app.toolboxes.group_assign', {
  //   url: '/toolboxes/groups/:group/assign?previousPageNumber&previousOrder&previousReverse&previousSearch',
  //   templateUrl: 'app/views/toolbox_group_assign.html',
  //   controller: 'ToolboxGroupAssignCtrl',
  //   resolve: {
  //     ...resolvers.appResolver,
  //     ...makeLoadResolver('permissionViewToolboxTalkGroups'),
  //   },
  // })
}
